import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
// import phoneCss from '../styles/Phone.css'
import '../../styles/Phone.css'
import React, { useRef, useState} from "react";
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface IPhone{
    phoneNumber:string | undefined
    isValidPhoneInput:boolean
    changeValidPhoneInput:(flag:boolean) => void
    changePhoneValue:(value:string) => void
    inputClassName?:string| undefined,
    isFocus?: boolean,
}

export const getCountries = () => {
    //@ts-ignore
    return window._sharedData.config.availablePhoneCountries
}

function Phone({phoneNumber,changePhoneValue,isValidPhoneInput,changeValidPhoneInput,inputClassName,isFocus}:IPhone) {
    type classValidType = 'is-valid'| 'is-invalid' | ''
    const [classValid,setClassValid] = useState<classValidType>('')
    // const [countryNumber,setCountryNumber] = useState<string | undefined>('+7')
    const phoneRef = useRef<any>()
    const isAut = useTypedSelector(state => state.UserReducer.isAuth)

    const checkForCuntryNumber = (phoneNumber:string,countryNumber:string) => {
        const phoneNumberArray = phoneNumber.split('')
        return countryNumber.split('').every((cuntryNum,index) => {
            return cuntryNum === phoneNumberArray[index]
        })
    }

    const onChange = (status:boolean, phoneNumber:string) => {
        const countryNumber = '+'+phoneRef.current.selectedCountryData.dialCode

        changeValidPhoneInput(status)
        if( phoneNumber.includes('+') )
            changePhoneValue(phoneNumber)
        else if(checkForCuntryNumber(phoneNumber,countryNumber?.replace('+',''))){
            changePhoneValue('+'+phoneNumber)
        }
        else
            changePhoneValue(countryNumber+phoneNumber)
    }

    const getPreferredCountries = () => {
        const CISmap = new Map([
            ['RU', 'RU'], 
            ['KZ', 'KZ'],
            ['BY', 'BY'],
            ['UA', 'UA'],
          ]) 
        //@ts-ignore
        let locale = ''
        if(isAut){
            //@ts-ignore
            locale = window._sharedData.user.settings.region
        }
        else{
            //@ts-ignore
            locale = window._sharedData.config.locale.region 
        }
        if(CISmap.get(locale)){
            const countryArray = [locale,'RU', 'UA', 'BY', 'KZ'];
            //@ts-ignore
            let unique = [...new Set(countryArray)];
            return  unique
        } 
        else {
            const countryArray = [locale,'US'];
            //@ts-ignore
            let unique = [...new Set(countryArray)];
            return unique
        }
    }

    const onBlur = () => {
        if(!isValidPhoneInput) setClassValid('is-invalid')
        else setClassValid('is-valid')
    }
    return (
            <IntlTelInput
                ref={phoneRef}
                autoFocus={isFocus}
                preferredCountries={getPreferredCountries()}
                onlyCountries = {getCountries()}
                onPhoneNumberChange={onChange}
                onPhoneNumberBlur={onBlur}
                nationalMode={false} 
                inputClassName={  inputClassName ? `${inputClassName} ${classValid}` :'form-control form-control-solid h-auto pt-6 pr-6 pb-6 font-size-h5 ' + classValid}
            />
    )
}

const areEqual = (prevProps: any, nextProps: any) => {
    if (prevProps.phoneNumber === nextProps.phoneNumber) {
        return true
    }
    return false
}

export default React.memo(Phone, areEqual)