import React, { useContext, useEffect, useRef, useState } from "react"
import { TickerContext } from "../../Context"
import { UseActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { IError } from "../../models/EventContentModel"
import { PlaneTypes } from "../../models/User"
import UserService from "../../Services/UserService"
import { DeviseIsApplicationOnly, getPercent } from "../../Utills/ClearFunctions"
import { Alert, themeAlertEnum } from "../Alerts"

function FavouritesButton() {
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const User = useTypedSelector(state => state.UserReducer.User)
    const {currentTickerState, companyInformation, tradingInfo, setTradingInfo} = useContext(TickerContext)
    const [inFavourites, setInFavourites] = useState<boolean>(companyInformation?.statusInfo?.inFavourites)
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)
    const FavouritesCards = useTypedSelector(state => state.FavouritesCard.FavouritesCards)
    const {setFavouritesCards} = UseActions()

    const getFavouritesObject = ():any => {
        let [percent, isUp] = getPercent(currentTickerState.price as number, Number(currentTickerState.oldPrice))
        if(!percent || percent === Infinity){
            percent = false
        }
        return {
            star:false,
            fill:false,
            src:currentTickerState.src,
            cardName:currentTickerState.brand,
            price:currentTickerState.price,
            percent:percent && percent,
            ticket:currentTickerState.ticket,
            isUp:isUp,
            oldPrice:currentTickerState.oldPrice,
            currency:currentTickerState.currency
        }
    }
    
    useEffect(() => {
        setInFavourites(companyInformation?.statusInfo?.inFavourites)
    }, [companyInformation?.statusInfo])

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            starRef.current.classList.add('disableStar')
        }
    },[])

    const [FavouritesError, setFavouritesError] = useState<IError>({
        isError: false,
        textError: ''
    })

    const closeCardError = () => {
        setFavouritesError({ isError: false, textError: '' })
    }

    const showCardError = (text: string) => {
        setFavouritesError({ isError: true, textError: text })
    }

    const handleCardError = (response: any) => {
        if (response.data.status === "failed") {
            showCardError(response.data.error.message)
            return true
        }
        else {
            closeCardError()
            return false
        }
    }

    const setFavouritesServerStateSuccess = async (inFavourites: boolean) => {
        const ticker = companyInformation?.ticker
        
        if (inFavourites) {
            const response: any = await UserService.addFavourite(ticker)
            setFavouritesCards([...FavouritesCards, getFavouritesObject()])
            handleCardError(response)
        } else {
            const response: any = await UserService.removeFavourite(ticker)
            setFavouritesCards(deleleteFavourotesTicker(FavouritesCards))
            handleCardError(response)
        }
    }


    const deleleteFavourotesTicker = (favouriesCards: Array<any>) => {
        return favouriesCards.filter((card, index) => {
            return card.ticket !== currentTickerState.ticket
        })
    }

    const setFavouritesServerStateError = async () => {
        const ticker = companyInformation?.ticker
        if (!inFavourites) {
            setFavouritesCards([...FavouritesCards, getFavouritesObject()])
            const response: any = await UserService.addFavourite(ticker)
            handleCardError(response)
        } else {
            setFavouritesCards(deleleteFavourotesTicker(FavouritesCards))
            const response: any = await UserService.removeFavourite(ticker)
            handleCardError(response)
        }
    }

    const setFavouritesUIStateSuccess = () => {
        if (!inFavourites) {
            setInFavourites(true)
            return true
        }
        else {
            setInFavourites(false)
            return false
        }
    }

    const setFavouritesUIStateError = () => {
        setInFavourites(false)
    }

    const removeOrAdd = async (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (Plane === PlaneTypes.PRO && User.subscriptionInfo.finishedAt) {
            setFavouritesServerStateSuccess(setFavouritesUIStateSuccess())
        }
        else {
            if (!User.subscriptionInfo.finishedAt || Plane === PlaneTypes.FREE) {
                if (FavouritesCards.length < 5 || inFavourites) {
                    setFavouritesServerStateSuccess(setFavouritesUIStateSuccess())
                    if (inFavourites) {
                        setFavouritesCards(deleleteFavourotesTicker(FavouritesCards))
                    }
                    else {
                        setFavouritesCards([...FavouritesCards, getFavouritesObject()])
                    }
                }
                else {
                    await setFavouritesServerStateError()
                    setFavouritesUIStateError()
                }
            }
            else {
                if (FavouritesCards.length < 15 || inFavourites) {
                    setFavouritesServerStateSuccess(setFavouritesUIStateSuccess())
                    if (inFavourites) {
                        setFavouritesCards(deleleteFavourotesTicker(FavouritesCards))
                    }
                    else {
                        setFavouritesCards([...FavouritesCards, getFavouritesObject()])
                    }
                }
                else {
                    await setFavouritesServerStateError()
                    setFavouritesUIStateError()
                }
            }
        }
    }

    return <>
        <Alert theme={themeAlertEnum.Danger} close={closeCardError} isOpen={FavouritesError.isError} text={FavouritesError.textError}>
            <span>{FavouritesError.textError}</span>
        </Alert>

        <div className="">
            <a ref= {starRef}  href="#" data-ticker="TGTX" data-status="0"
                onClick={removeOrAdd}
                className="btn btn-icon btn-sm btn-white font-weight-bolder js__toggle-favourites">
                {
                    inFavourites
                        ? <i className="fas fa-star text-primary p-0"></i>
                        : <i className="far fa-star text-primary p-0"></i>
                }
            </a>
        </div>
    </>
}
export default FavouritesButton