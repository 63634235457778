import {Modal} from "react-bootstrap";
import React, {useEffect} from "react";
import MessageBody from "./MessageBody";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";


interface IMutedModal {
    show: boolean,
    handleClose: () => void,
    isClickToAI?:boolean
    explanation?:null | {text:string}
}

function MessageModal({show,handleClose,explanation,isClickToAI}: IMutedModal){
    const currentMessage = useTypedSelector(state => state.EventReducer.currentMessage)
    const {t} = useTranslation()
    return(
        <Modal size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5
                    className="modal-title"
                    dangerouslySetInnerHTML = {{__html: `${ currentMessage.CompanyName  }: ${t('EVENT_PRESS_RELEASE')}`  }} />
            </Modal.Header>

            <Modal.Body>
                <div className="modal-body p-4 p-md-8 event-wrapper event-wrapper-translated">
                <MessageBody explanation = {explanation} isClickToAI = {isClickToAI}/>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export  default  MessageModal