import React, { useEffect } from "react"
import { IImage } from "./DescriptionBarLargeImage"
import  '../../styles/StockImage.css'
//991
function StockImage({src}:IImage){
    return <div
        className="symbol StockImage symbol-40 symbol-lg-50 symbol-circle"
         style = {{
            backgroundImage:`url(${src})`,
            backgroundSize: 'cover',
            // width:'100%',
            // height: '100%', 
            backgroundColor:'gray'
            }}>
    </div>
    
}
export default StockImage