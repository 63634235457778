import React, { MutableRefObject, useEffect, useMemo, useRef, useState } from 'react';
import SearhBar from "./SearhBar";
import { ReactComponent as CloseIcon } from '../../image/closeSvg.svg';
import '../../styles/SearchStyle.css'
import debounce from 'lodash.debounce';
import useSelectCards from '../../hooks/useSelectCards';
import { Alert, themeAlertEnum } from '../Alerts';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import {FocusEvent} from 'react'

interface ISearch {
    searchPosition: number,
    searchIsOpen: boolean,
    changeRef: () => void,
    isOpenSearchBar: boolean,
    setOpenSearchBar: (value: boolean) => void
    top?: number
    postTickerClick?: (ticket: string) => void
}

const Search = React.forwardRef<HTMLInputElement, ISearch>(({
    searchPosition,
    setOpenSearchBar,
    isOpenSearchBar,
    searchIsOpen,
    changeRef,
    top,
    postTickerClick
}, searchRef) => {
    const SearchVisability = useRef<any>()
    const SearchContainerVisability = useRef<any>()
    const [inputValue, setInputValue] = useState<string>('')
    const isCrypto = useTypedSelector((state) => state.UserReducer.User.isCrypto);

    const width = 350
    const [translateMobile, setTranslateMobile] = useState<number>(20)
    const [dif, setDif] = useState<number>(0)
    const FavouritesCards = useTypedSelector(state => state.FavouritesCard.FavouritesCards)
    const { t } = useTranslation()
    const {
        Cards,
        makeQuery,
        isLoading,
        isCardError,
        closeCardError,
        setIsTransitionToCompanyWithoutClick
    } = useSelectCards({ IsFavorites: true, postTickerClick })

    useEffect(() => {
        if (searchPosition === 0) return
        if (searchPosition - width <= 0) {
            setTranslateMobile(40)
            setDif(searchPosition - width)
        } else {
            setTranslateMobile(20)
            setDif(0)
        }
    }, [searchPosition])

    useEffect(() => {
        if (isOpenSearchBar) {
            SearchVisability.current.classList.add('quick-search-has-result')
        } else {
            SearchVisability.current.classList.remove('quick-search-has-result')
        }
    }, [isOpenSearchBar])

    const changeInputValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
        //@ts-ignore
        if (!searchRef?.current.value) {
            setInputValue(e.target.value)
            setOpenSearchBar(false)
            return
        }
        setOpenSearchBar(true)
        setInputValue(e.target.value)
        makeQuery(e.target.value)
    }
    const debouncedHandleQuery = useMemo(
        () => debounce(changeInputValue, 300)
        , [FavouritesCards]);

    const handleClose = () => {
        setOpenSearchBar(false)
        changeRef()
    }

    const handleCloseRender = () => {
        //@ts-ignore
        if (searchRef?.current.value === '') {
            return <></>
        } else return <></>
    }

    const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.code === 'Enter') {
            if (Cards.length === 1) {
                setIsTransitionToCompanyWithoutClick(true)
            }
        }
    }

    const computeTopPosition = () => {
        if (top) {
            return top + 'px'
        }
        return '58px'
    }

    const onFocus = (e:FocusEvent) => {
        const inputValue = e.target as HTMLInputElement
        setOpenSearchBar(true)
        setInputValue(inputValue.value)
        makeQuery(inputValue.value)
        // makeQuery(inputValue.value)
    }

    return (
        <div
            className="dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-lg show SearchContainer SearchContainer_disabled"
            style={{
                position: 'absolute',
                top: '0px',
                width: `${width + dif}`,
                transform: ` translate3d(${-(width + dif) + translateMobile}px, ${computeTopPosition()}, 0px)`
            }}
            onClick={(e) => e.stopPropagation()}
            ref={SearchContainerVisability}
            x-placement="bottom-end">

            <Alert theme={themeAlertEnum.Danger} close={closeCardError} isOpen={isCardError.isError}
                text={isCardError.textError}>
                <span>{isCardError.textError}</span>
            </Alert>

            <div ref={SearchVisability} className="quick-search quick-search-dropdown " id="kt_quick_search_dropdown">
                <div onKeyPress={handleKeyDown} className="quick-search-form">
                    <div className="input-group">
                        <div className="input-group-prepend">
                        </div>
                        {/* onFocus={onFocus} */}
                        <input  autoComplete="off" id="search-company" type="text"
                            className="form-control" placeholder={` ${ !isCrypto ? t('STOCKS_SEARCH_HEADER') : t('STOCKS_SEARCH_CRYPTO_HEADER')}`}
                            onChange={debouncedHandleQuery}
                            ref={searchRef}
                        />
                        {
                            // @ts-ignore
                            searchRef?.current.value !== '' &&
                            <div className="input-group-append" onClick={handleClose}>
                                <span className="input-group-text">
                                    <CloseIcon width='15px' height='15px' fill='rgb(211, 211, 219)' />
                                </span>
                            </div>
                        }
                    </div>
                </div>
                <SearhBar Cards={Cards} isLoading={isLoading} />
            </div>
        </div>
    );
})

export default Search;