import React, {useEffect, useRef, useState} from "react"
import '../../styles/TimeLineMore.css'
import {DeviceIsMobile} from "../../Utills/ClearFunctions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { t } from "i18next";

interface ITimeLineMore{
    count:number
    data:any
}

function TimeLineMore({count,data}:ITimeLineMore){
    const accordion = useRef<any>()
    const [isOpen,setIsOpen] = useState<boolean>(false)
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const [childrenElementLength,setChildrenElementLength] = useState<number>(0)

    const timeLineElements = data.map((val:any) => val )

    const handlerClick = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        e.stopPropagation()
        setIsOpen(!isOpen)
    }

    useEffect(() => {
        //@ts-ignore
        const diff = document.querySelector('.timeline-item.align-items-start.mb-4.event-wrapper.event-wrapper-translated.fluctuations').getBoundingClientRect().height + 13
        const timeArray = accordion.current.querySelectorAll('.text-primary.font-weight-bolder.text-nowrap')
        let diffTime = 0
       
        let margin = 0
        if(window.outerHeight){
            if(window.innerWidth < 992){
                margin = 13
            }
            else{
                margin = 15.5
            }
        }
        else{
            if(document.body.clientWidth < 992){
                margin = 13
            }
            else{
                margin = 15.5
            }
        }
        if(timeArray[0])
            diffTime = timeArray[0].getBoundingClientRect().height + margin
        if(isOpen){
            accordion.current.style.height = `${( (count) * diff ) + (timeArray.length * diffTime) }px`
        }
        else{
            setChildrenElementLength(accordion.current.children.length)
            accordion.current.style.height = '0'
        }

    },[isOpen])

    return(
        <>
            <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">
                <div className="timeline-label font-weight-bolder text-muted font-size-lg"></div>
                <div className="timeline-badge py-2"></div>
                <div className="font-size-md timeline-content pl-3">
                    <div className="">
                        <a onClick = {handlerClick} className="js__toggle-hidden-events font-weight-bolder font-size-sm" href="">
                           {t('EVENTS_SHOW_MORE',{count:count})}

                            {
                                isOpen
                                ?<i className="fas fa-chevron-down fa-1x ml-1 text-primary"></i>
                                :<i className="fas fa-1x ml-1 text-primary fa-chevron-up"></i>
                            }
                            
                        </a>
                    </div>
                </div>
            </div>

            <div ref = {accordion} className={'timeLineContainer'} >
                {timeLineElements}
            </div>
            
        </>
    )
}

export default TimeLineMore