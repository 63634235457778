import {useEffect, useState} from "react";
import CommentService from "../Services/CommentService";
import Comment, {IComment} from "../components/Comments/Comment";
import UserService from "../Services/UserService";


function useComment() {
    const [commentData, setCommentData] = useState<Array<IComment>>([] as Array<IComment>)
    const [replyObj, setReplyObj] = useState<{ replyName: string }>({replyName: ''})

    const getCommentsData = async (eventId: string) => {
        const response = await CommentService.get(eventId)

        const commentsDate = response.data.comments.map((comment: any) => {
            return {...comment, initialCountLikes: comment.countLikes}
        })
        getCommentData(commentsDate)
        return response.data.comments
    }

    const deleteComment = async (idComment: string) => {
        const filteredComments = commentData.filter((comment) => {
            return (comment.id !== idComment)
        })
        CommentService.delete(idComment)
        setCommentData(filteredComments)
    }

    //fixme
    const likeComment = async (idComment: string) => {
        setCommentData(commentData.map((comment) => {
            if (comment.id === idComment) {
                if (comment.initialCountLikes === comment.countLikes) {
                    if(comment.isLiked){
                        CommentService.unlike(comment.id)
                        return {...comment, countLikes: comment.countLikes - 1, isLiked:false}
                    }
                    CommentService.like(comment.id)
                    return {...comment, countLikes: comment.countLikes + 1,isLiked:true}
                } else {
                    if(!(comment.isLiked)){
                        CommentService.like(comment.id)
                        return {...comment, countLikes: comment.countLikes + 1,isLiked: true}
                    }

                    CommentService.unlike(comment.id)
                    return {...comment, countLikes: comment.countLikes - 1,isLiked: false}
                }
            } else {
                return {...comment}
            }
        }))
    }

    const replyComment = async (idComment: string) => {

        const targetComment = commentData.find(comment => {
            return comment.id === idComment
        })
        if (!targetComment)
            setReplyObj({replyName: ''})
        else
            setReplyObj({replyName: '@' + targetComment.commentator.username + ', '})

    }

    const addComment = async (eventId: number, text: string) => {
        const response = await CommentService.send(String(eventId), text)
        commentData.push({...response.data.comment, initialCountLikes: response.data.comment.countLikes})
        setCommentData([...commentData])
    }

    const getCommentData = (comments: Array<IComment>) => {
        setCommentData(comments.map((comment) => {
            return comment
        }))

    }
    useEffect(() => {

    }, [])

    return {
        getCommentsData,
        commentData,
        deleteComment,
        likeComment,
        replyComment,
        addComment,
        replyObj
    }
}

export default useComment