import {Modal} from "react-bootstrap";
import React from "react";
import { Trans, useTranslation } from "react-i18next";

interface CommentsModal {
    show: boolean,
    handleClose: () => void
}

function HowToReadCompanyInformationModal({show, handleClose}: CommentsModal) {
    const {t} =useTranslation()

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>

            </Modal.Header>

            <Modal.Body>
               
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_CAP')}</h4>
                    <Trans i18nKey = 'STOCK_MARKETDATA_FAQ_CAP_TEXT'>
                        <div className="p-5"></div>
                    </Trans>
                    
                    {/* Стоимость объекта, рассчитанная на основе текущей рыночной цены.
                    Рассчитывается как:
                    <div className="p-5">
                        Капитализация = Количество акций в обращении * Текущую цену акции (1 лота)
                    </div> */}
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_1D')}</h4>
                    
                    <Trans i18nKey = 'STOCK_MARKETDATA_FAQ_VOLUME_1D_TEXT'>
                        <b></b>
                    </Trans>
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_1D_USD')}</h4>
                    <Trans i18nKey = 'STOCK_MARKETDATA_FAQ_VOLUME_1D_USD_TEXT'>
                        <b></b>
                    </Trans>
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_3M')}</h4>
                    {/* 
                    Объем торгов (<b>в валюте</b>) акции за основную предыдущую торговую сессию, где размещены
                    ценные бумаги (NASDAQ, NYSE и др биржи)
                    <hr/> */}

                    <Trans i18nKey = 'STOCK_MARKETDATA_FAQ_VOLUME_3M_TEXT'>
                        <b></b>
                    </Trans>
                    <hr/>
                    {/* <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_3M')}</h4>
                    Средний дневной объем торгов (<b>в штуках</b>) акций за последние 3 месяца
                    <hr/> */}

                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_3M_USD')}</h4>
                    <Trans i18nKey = 'STOCK_MARKETDATA_FAQ_VOLUME_3M_USD_TEXT'>
                        <b></b>
                    </Trans>
                    <hr/>
                    {/* <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_VOLUME_3M_USD')}</h4>
                    Средний дневной объем торгов (<b>в валюте</b>) акций за последние 3 месяца */}

                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_CLOSE')}</h4>
                    {t('STOCK_MARKETDATA_FAQ_CLOSE_TEXT')}
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_CLOSE_PERCENT')}</h4>
                    {t('STOCK_MARKETDATA_FAQ_CLOSE_PERCENT_TEXT')}
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_52W_MIN')}</h4>
                    {t('STOCK_MARKETDATA_FAQ_52W_MIN_TEXT')}
                    <hr/>
                    <h4 className="font-weight-bolder">{t('STOCK_MARKETDATA_FAQ_52W_MAX')}</h4>
                    {t('STOCK_MARKETDATA_FAQ_52W_MAX_TEXT')}                    
                    <hr/>
                
            </Modal.Body>


        </Modal>
    )
}

export default HowToReadCompanyInformationModal