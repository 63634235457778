import TimeLineDay from "../Time/TimeLineTimeDay"
import TimeLIneTime from '../Time/TimeLIneTime'
import {useTransition} from "../../hooks/useTransition";
import {useLocation, useNavigate} from "react-router-dom";
import {UseActions} from "../../hooks/useActions";
import React, {useEffect, useRef, useState} from "react";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {FilterConstant} from "../../models/FilterEvent";
import CommentsModal from "../Modals/CommentsModal";
import MessageModal from "../Modals/MessageModal";
import {ICompanyInformationCard} from "../Cards/CompanyInformationCard";
import CommentCard from "../Cards/CommentCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ITimeLineNewLink {
    title: string,
    text: string,
    time: string,
    source: string,
    Comment:any,
    isCommentOpen: boolean
    translatedText?: string
    translatedTitle?:string
    CardInformation:ICompanyInformationCard
    id:number,
    countComments?:number,
}

function TimeLineNewLink(props: ITimeLineNewLink) {
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent, setCurrentMessage} = UseActions()

    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const navigate = useNavigate()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)

    const [isShowTransition, showTransition] = useState<boolean>(false)
    const refProps = useRef<any>(props)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const [showRelease, setShowRelease] = useState(false);
    const handleCloseRelease = () => setShowRelease(false);
    const handleShowRelease = () => setShowRelease(true);
    const {transitionToMessagePage} = useTransition()

    useEffect(() => {
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])
    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type: FilterConstant.PRESS_RELEASES,
                    data: {...props, isCommentOpen: false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }

    // const transitionToMessage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    //     transitionToMessagePage(handleShowRelease, props)
    //     e.preventDefault()
    //
    // }
    const {t} = useTranslation()

    const translateText = () => {
        showTransition(true)
    }
    const backToRussia = () => {
        showTransition(false)
    }

    const renderTooltip = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">
                    { props.header }
                    {refProps.current.fullDateTootip}
                </div>
            </Tooltip>
        );


    return (
        <>
            <MessageModal show={showRelease} handleClose={handleCloseRelease}/>

            <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">

                <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <div data-tip={'s'}
                         className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1">{props.time}
                    </div>
                </OverlayTrigger>

                <div className="timeline-badge">
                    <i className="fas fa-link text-success"></i>
                </div>
                <div className="font-size-md timeline-content pl-3">
                    <b>{t('EVENT_NEW_LINK')}: </b>
                    {
                        props.translatedTitle || props.translatedText
                            && <>
                                {
                                    (!isShowTransition)
                                        ? <span
                                            className="event--translated-text"
                                            dangerouslySetInnerHTML = {{__html: `${ props.title || ''  }  ` }}>
                                        </span>
                                        : (<>
                                            <span
                                                className="event--translated-text"
                                                dangerouslySetInnerHTML = {{__html: `${ props.translatedTitle || ''  }  ` }}>
                                            </span>
                                        </>)
                                }
                            </>

                    }


                    <>
                        <a target="_blank" href={props.source}>  {props.source.split('/')[2]} </a>
                    </>

                    <div className="d-flex align-items-center">
                        <div className="mt-1 font-size-sm">
                            <a target="_blank" href={props.source}> {t('STOCK_PRESS_RELEASES_SOURCE')}
                        </a>

                            {
                                (!isShowTransition)
                                    ?<> | <a  onClick={translateText} className="js__original-event event" href="javascript:;">
                                         {t('EVENT_ORIGINAL')}
                                    </a>
                                    </>
                                    : <>| <a onClick={backToRussia} className="js__translate-event event" href="javascript:;">
                                        {t('EVENT_TRANSLATE')}

                                    </a>
                                    </>
                            }
                        </div>
                        {
                            props.Comment
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeLineNewLink