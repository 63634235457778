import ListAvdventages from "./ListAvdventages";
import React, { useCallback, useEffect, useRef, useState } from "react";
import Pricetab from "./Pricetab";
import '../../styles/ProSubscriptions.css'
import { checkForSendYEvent, deleteLoginStyle, getPlaneString } from '../../Utills/ClearFunctions'
import FreeAlert from "./FreeAlert";
import FooterMobile from "./FooterMobile";
import { useNavigate } from "react-router-dom";
import PayButtonMobile from "./PayButtonMobile";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { DeviceTypeEnum } from "../../models/Subscriptions";
import { PlaneTypes } from "../../models/User";
import { ORIGIN } from "../../models/Domen";
import { Modal } from "react-bootstrap";
import '../../styles/SubscriptionModal.css'
import SubscriptionService from "../../Services/SubscriptionService";
import ClosedPurchaise from "./ClosedPurchaise";
import { Alert, themeAlertEnum } from "../Alerts";
import WaitingPurchaise from "./WaitingPurchaise";
import '../../styles/WaitingPurchaise.css'
import SuccessPurchaise from "./SuccessPurchaise";
import LiteAndroidButton from "./LiteAndroidButton";
import { useTranslation } from "react-i18next";
import DesctopSubscription from './DesctopSubscription'
import { IDiscountInfo } from "./DesktopSubscription";
import SpecialRuMobileWaitingPayment from './SpecialRuMobileWaitingPayment'
import { t } from "i18next";
import IphoneAlert from './IphoneAlert'
import SubChart from "../../image/subChart.png"
import sub_push_1 from "../../image/sub_push_1.png"
import sub_push_2 from "../../image/sub_push_3.png"
import sub_tooltip from "../../image/sub_tooltip.png"
import { checkForRU, sharedCheckForCIS } from "../../hooks/useSelectSubscriptions";

interface IMobileSubscription {
    isAppleRewiew: boolean
}

export interface IfreePriceButtonState {
    partOne: string,
    partTwo: string
}

export const getNumberPrice = (value: string) => {
    const priceCharacters = value.split('')
    return parseFloat(priceCharacters.filter((value: any) => {
        if (value === ' ' || value === ' ') return false
        if (value === ',' || value === '.') return false
        return !isNaN(value)
    }).join(''))

}
const getNumber = (stringValue: string) => {
    const splittedString = stringValue.replace(/\s/g, '')
    const value = parseFloat(splittedString.match(/[\d,.]+/)![0].replace(/,/g, '.'))
    return value
}
export const getMountPrice = (isAvailableTrial: boolean, cardPlane: PlaneTypes, PriceTabs: any) => {
    let mountPrice = 0
    if (cardPlane === PlaneTypes.PRO || cardPlane === PlaneTypes.LITE) {
        if (cardPlane === PlaneTypes.PRO) {
            if (isAvailableTrial) {
                mountPrice = getNumberPrice(PriceTabs[1].price)
            }
            else {
                mountPrice = getNumberPrice(PriceTabs[0].price)
            }
        }
        else {
            mountPrice = getNumberPrice(PriceTabs[0].price)
        }
    }
    return mountPrice
}



export interface IPriceTab {
    time: string,
    price: string,
    checked: boolean,
    sku: string,
    initialCost: string,
    discount: number
    currency: string
}
// PriceTab.price
export const getDiscount = (price: string, time: string, mountPrice: number) => {
    let discount
    if (time === `${t('SUBSCRIPTION_PLANS_PERIODS_3M')}`) {
        const currentPrice = getNumberPrice(price)
        discount = (3 * mountPrice - currentPrice)
        discount /= 3 * mountPrice
        discount = parseFloat((discount * 100).toFixed())
    }

    if (time === `${t('SUBSCRIPTION_PLANS_PERIODS_1Y')}`) {
        const currentPrice = getNumberPrice(price)
        discount = (12 * mountPrice - currentPrice)
        discount /= 12 * mountPrice
        discount = parseFloat((discount * 100).toFixed())
    }
    return discount
}
export interface IMountPrice {
    proMount: number | null
    liteMount: number | null
}

function MobileSubscription({ isAppleRewiew }: IMobileSubscription) {
    const deviceType = useTypedSelector(state => state.EventReducer.userAgent)
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const isAvailableTrial = useTypedSelector(state => state.UserReducer.User.subscriptionInfo.isAvailableTrial)
    const finishedAt = useTypedSelector(state => state.UserReducer.User.subscriptionInfo.finishedAt)

    const [isSuccessPurchaise, setIsSuccessPurchaise] = useState<boolean>(false)
    const [isWaitingPurchaise, setisWaitingPurchaise] = useState<boolean>(false)
    const [isPurchaiseClose, setisPurchaiseClose] = useState<boolean>(false)
    const [isFailedAlert, setIsFailedAlert] = useState<boolean>(false)
    const [mountPrice, setMountPrice] = useState<IMountPrice>({ liteMount: null, proMount: null })
    const { t } = useTranslation()

    const removeAlert = useCallback(() => {
        setIsFailedAlert(false)
    }, [isFailedAlert])

    interface IContent {
        Plane: PlaneTypes,
        PriceTabs: Array<IPriceTab>
        current: boolean
    }

    interface IButtonState {
        text: string
    }

    const getNumberForButton = (value: string) => {
        const priceCharacters = value.split('')
        const stringCharacters = priceCharacters.filter((value: any) => {
            if (value === ' ' || value === ' ') return false
            if (value === ',' || value === '.') return true
            return !isNaN(value)
        }).join('')
        return parseFloat(stringCharacters.replace(',', '.'))
    }

    const [freePriceButtonState, setFreePriceButtonState] = useState<IfreePriceButtonState>({
        partOne: '',
        partTwo: ''
    })

    const getCurrency = (currentScu: string, price: string, time?: string): string => {
        if (isAppleRewiew && currentScu === 'ts_monthly_trial') {
            const priceString = `${price} ${t('SUBSCRIPTION_PLANS_PERIODS_1M')}` +
                ` ${t('SUBSCRIPTION_PLANS_AFTER_TRIAL')}`
            setFreePriceButtonState({
                partOne: time as string,
                partTwo: priceString
            })
            return priceString
        }
        if (currentScu === 'ts_monthly_trial') {
            const priceString = t('SUBSCRIPTION_PLANS_THEN_MONTHLY', { price: price })
            setFreePriceButtonState({
                partOne: time as string,
                partTwo: priceString
            })
            return `${t('SUBSCRIPTION_PLANS_PERIODS_FREE')}`
        }
        if (price.includes('RUB')) {
            return price.replace('RUB', '₽')
        }
        return price
    }

    const [discountInfo, setDiscountInfo] = useState<IDiscountInfo | null>(
        null
    )
    const [isTrial, setIsTrial] = useState<boolean>(false)

    const getParams = () => {
        SubscriptionService.getDesctopTariffs().then((response) => {
            setDiscountInfo(response.data.discountInfo)
        })

        const skus = window.location.search.substr(1).split('=')[1]
        if (!skus) return
        const decodeSkus = decodeURIComponent(skus)
        const skusArray = JSON.parse(decodeSkus)
        // setMountPrice
        skusArray.forEach((element: any) => {
            // ts_monthly_lite
            if (element.sku === 'ts_monthly_trial') {
                const isFirstSubscriptionLook = window.localStorage.getItem('isFirstSubscriptionLook')
                if (isFirstSubscriptionLook) {
                    if (isFirstSubscriptionLook === 'true') {
                        window.localStorage.setItem('isFirstSubscriptionLook', 'false')
                        setIsTrial(!sharedCheckForCIS())
                    }
                }
                // setIsTrial(checkForSpecialRegion())
            }
            if (element.sku === 'ts_monthly') {

                setMountPrice((prev) => {
                    return { ...prev, proMount: getNumber(element.price) }
                })
            }
            if (element.sku === 'ts_monthly_lite') {
                setMountPrice((prev) => {
                    return { ...prev, liteMount: getNumber(element.price) }
                })
            }

        });


        const MountPrice = 0
        const filteredContent = content.map((contentObj) => {
            const newPriceTab = contentObj.PriceTabs.map((PriceTab) => {
                for (let i = 0; i < skusArray.length; i++) {
                    // sdsdassd
                    if (PriceTab.sku === skusArray[i].sku) {
                        //₽
                        let price = getCurrency(PriceTab.sku, skusArray[i].price, PriceTab.time)
                        return { ...PriceTab, price: price }
                    }
                }
                return { ...PriceTab }
            })
            return { ...contentObj, PriceTabs: newPriceTab }
        }, [])

        const trialFiltred = filteredContent.map((card) => {
            const mountPrice = getMountPrice(isAvailableTrial, card.Plane, card.PriceTabs)

            const newPriceTab = card.PriceTabs.filter((PriceTab) => {
                if (PriceTab.sku === 'ts_monthly_trial') {
                    if (isAvailableTrial) return true
                    else return false
                }
                return true
            })

            card.PriceTabs = newPriceTab
            card.PriceTabs = card.PriceTabs.map((PriceTab) => {
                let discount = getDiscount(PriceTab.price, PriceTab.time, mountPrice)
                if (!discount) {
                    return { ...PriceTab }
                }
                else {
                    return { ...PriceTab, discount: discount }
                }
            })
            return { ...card }
        })

        trialFiltred[0].PriceTabs[0].checked = true
        setContent(trialFiltred)
        isAppleRewiew && changeToAppleRewiew()
    }

    const [show, setShow] = useState<boolean>(false)
    const numberOfSecond = useRef<number>(0)
    const [isTimerClosed, setIsTimerClosed] = useState<boolean>(false)
    const idTimer = useRef<any>('')


    const handleUICloseSuccesPurchaise = () => {
        setIsSuccessPurchaise(false)
        setisPurchaiseClose(true)
        checkForSendYEvent("subscribed")
        clearInterval(idTimer.current)
    }

    const hanldeError = () => {
        setisWaitingPurchaise(false)
        setIsSuccessPurchaise(false)
        setIsTimerClosed(false)
        setIsFailedAlert(true)
        setShow(false)
    }

    const closeInterval = () => {
        clearInterval(idTimer.current)
    }

    const handleTimer = useCallback(() => {
        numberOfSecond.current += 1
        if (numberOfSecond.current === 30) {
            // setIsSuccessPurchaise(false)
            setIsTimerClosed(true)
            // clearInterval(idTimer.current)
        }
    }, [])

    const successIOS = (transactionId: string) => {
        idTimer.current = setInterval(() => {
            handleTimer()
            SubscriptionService.checkOrderIOS(transactionId).then( // eslint-disable-line
                (response) => {
                    if (response.data.payed === true) {
                        handleUICloseSuccesPurchaise()
                    }
                    if (response.data.status === 'failed') {
                        hanldeError()
                    }
                }
            ), () => {
                hanldeError()
            }
        }, 2000);
    }

    const successAndroid = (orderId: string) => {
        idTimer.current = setInterval(() => {
            handleTimer()
            SubscriptionService.checkOrderAndroid(orderId).then(
                (response) => {
                    if (response.data.payed === true) {
                        handleUICloseSuccesPurchaise()
                    }
                    if (response.data.status === 'failed') {
                        hanldeError()
                    }
                }, () => {
                    hanldeError()
                }
            )
        }, 2000);
    }

    const createAppFuncitons = () => {
        //@ts-ignore
        window.startPurchase = function () {
            setisWaitingPurchaise(true)
            setShow(true)
        }
        //@ts-ignore
        window.successPurchase = (id: string) => {
            setIsSuccessPurchaise(true)
            setisWaitingPurchaise(false)
            if (window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('iPad')) {
                successIOS(id)
            }
            else {
                successAndroid(id)
            }
        }
        //@ts-ignore 
        window.failedPurchase = () => {
            hanldeError()
        }
    }


    const callTaptic = () => {
        setTimeout(() => {
            document.location = `${ORIGIN}/taptic`
        }, 1500)
    }

    useEffect(() => {
        if (isTrial && !window.navigator.userAgent.includes('Android')) {
            callTaptic()
        }
    }, [isTrial])

    useEffect(() => {

        createAppFuncitons()
        deleteLoginStyle()
        getParams()

        if (Plane === PlaneTypes.FREE || finishedAt === null) {
            let isMutate = false
            setContent((prev) => {
                return prev.map((ContentObj) => {
                    const newPriceTabs = ContentObj.PriceTabs.map((PriceTab, index) => {
                        if (index === 0)
                            return { ...PriceTab, checked: true }
                        return { ...PriceTab, checked: false }
                    })
                    ContentObj.PriceTabs = newPriceTabs
                    if (ContentObj.Plane === PlaneTypes.PRO) {
                        isMutate = true
                        return { ...ContentObj, current: true }
                    }
                    else return { ...ContentObj, current: false }
                })
            })
            return
        }

        else if(Plane === PlaneTypes.LITE){
            setContent((prev) => {
                for (let i = 0; i < prev.length; i++) {
                    if (prev[i].Plane === Plane) {
                        prev[i].PriceTabs[0].checked = true
                        prev[i].current = true
                        return [prev[i]]
                    }
                }
                return [...prev]
            })
        }

        setContent((prev) => {
            for (let i = 0; i < prev.length; i++) {
                if (prev[i].Plane === Plane) {
                    prev[i].PriceTabs[0].checked = true
                    return [prev[i]]
                }
            }
            return [...prev]
        })

        return () => {
            clearInterval(idTimer.current)
        }

    }, [])


    const [currentDeviceType, setDeviceType] = useState<DeviceTypeEnum>(DeviceTypeEnum.DESKTOP)
    const navigate = useNavigate()
    const [content, setContent] = useState<Array<IContent>>([
        {
            Plane: PlaneTypes.PRO,
            PriceTabs: [
                {
                    price: `${t('SUBSCRIPTION_PLANS_PERIODS_FREE')}`,
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_7D')}`,
                    checked: true,
                    sku: 'ts_monthly_trial',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB'
                },
                {
                    price: '1 790₽',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_1M')}`,
                    checked: false,
                    sku: 'ts_monthly',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB'
                },
                {
                    price: '4490₽',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_3M')}`,
                    checked: false,
                    sku: 'ts_3months',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB'
                },
                {
                    price: '14990₽',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_1Y')}`,
                    checked: false,
                    sku: 'ts_yearly',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB'
                },
            ],
            current: true,
        },
        {
            Plane: PlaneTypes.LITE,
            PriceTabs: [{
                price: '799₽',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_1M')}`,
                checked: true,
                sku: 'ts_monthly_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB'
            },
            {
                price: '1790₽',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_3M')}`,
                checked: false,
                sku: 'ts_3months_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB',
            },
            {
                price: '6690₽',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_1Y')}`,
                checked: false,
                sku: 'ts_yearly_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB',
            }
            ],
            current: false,
        },
        {
            Plane: PlaneTypes.FREE,
            PriceTabs: [],
            current: false,
        }
    ])

    const [ButtonState, setButtonState] = useState<IButtonState>({
        text: 'начать пробный периуд'
    })

    const changeToAppleRewiew = () => {

        setContent(prev => {
            const Ncontent = prev.map((tabs) => {
                const apllerewiePriceTab = tabs.PriceTabs.map((priceTab) => {
                    if (priceTab.sku === 'ts_monthly' || priceTab.sku === 'ts_monthly_lite') {
                        return { ...priceTab, price: priceTab.price + ` ${t('SUBSCRIPTION_PLANS_EACH_MONTH')}` }
                    }
                    else if (priceTab.sku === 'ts_3months' || priceTab.sku === 'ts_3months_lite') {
                        return { ...priceTab, price: priceTab.price + ` ${t('SUBSCRIPTION_PLANS_EACH_3MONTHS')}` }
                    }
                    else if (priceTab.sku === 'ts_yearly' || priceTab.sku === 'ts_yearly_lite') {
                        return { ...priceTab, price: priceTab.price + ` ${t('SUBSCRIPTION_PLANS_EACH_YEAR')}` }
                    }
                    else return { ...priceTab }
                })

                return { ...tabs, PriceTabs: apllerewiePriceTab }
            })
            return Ncontent
        })
    }
    //ts_yearly
    const ChangePlane = (plane: PlaneTypes) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setContent(content.map((Content) => {
            if (Content.Plane === plane) return { ...Content, current: true }
            else return { ...Content, current: false }
        }))
    }

    const changeChooseTabs = (time: string) => {
        const newContent = content.map((Content) => {
            let priceTab: Array<IPriceTab> = Content.PriceTabs
            if (Content.current) {
                priceTab = Content.PriceTabs.map((tab) => {
                    if (tab.time === time) return { ...tab, checked: true }
                    else return { ...tab, checked: false }
                })
            }
            return { ...Content, PriceTabs: priceTab }
        })
        setContent(newContent)
    }

    const tabElements = () => {
        const tabs = content.map((Content) => {
            if (Content.current) {
                return Content.PriceTabs.map(tab => {
                    return <Pricetab {...{ ...tab, cardPlane: Content.Plane, isTrial: isTrial, mountPrice: mountPrice, isAppleRewiew: isAppleRewiew, discountInfo, clickFunction: changeChooseTabs, freePriceButtonState: freePriceButtonState }} />
                })
            }
        })
        if (isTrial)
            // @ts-ignore
            return tabs[0][0]
        else
            return tabs
    }

    const ButtonSubGroup = () => {
        return content.map((Content) => {
            return <li className="nav-item">
                <a onClick={ChangePlane(Content.Plane)}
                    className={Content.current ? "js__change-tariff nav-link px-4 py-2 active" : "js__change-tariff nav-link px-4 py-2"}
                    data-tariff="lite"
                    data-toggle="tab"
                    href="">
                    {
                        (Content.Plane === PlaneTypes.PRO) &&
                        <span className="nav-icon"><i className="flaticon2-rocket-1"></i></span>
                    }
                    {getPlaneString(Content.Plane)}
                </a>
            </li>
        })
    }

    const getCurrentPlane = (): PlaneTypes => {
        for (let i = 0; i <= content.length; i++) {
            if (content[i].current === true) return content[i].Plane
        }
        return 0
    }

    const getCurrentButtonText = (): string => {
        for (let i = 0; i <= content.length - 1; i++) {
            for (let k = 0; k <= content[i].PriceTabs.length - 1; k++) {
                const tab = content[i].PriceTabs[k]
                const price = tab.price
                if (tab.checked && getCurrentPlane() !== PlaneTypes.LITE) {
                    if (tab.sku !== 'ts_monthly_trial') return `${t('SUBSCRIPTION_PLANS_PAY')} ` + price
                    else return `${t('SUBSCRIPTION_PLANS_TRY')}`
                }
                else if (tab.checked && getCurrentPlane() === PlaneTypes.LITE && content[i].Plane === PlaneTypes.LITE) {
                    return `${t('SUBSCRIPTION_PLANS_PAY')} ` + price
                }
            }
        }
        return ''
    }

    const getCurrentTab = () => content.find((contentObj) => contentObj.current)?.PriceTabs.find((PriceTab) => PriceTab.checked)

    const handleDesctopPayment = async () => {
        setIsRuPayment(true)
        const currentTab = getCurrentTab()
        const response = await SubscriptionService.payDesctop(currentTab?.sku as string)
        window.location.assign(response.data.redirectUrl)
    }

    const getCurrentSCU = (e: React.MouseEvent<HTMLButtonElement>) => {
        const currentTab = getCurrentTab()
        window.location.assign(`${ORIGIN}/buysku?sku=${currentTab?.sku}`)
    }

    const chooseRightFooter = () => {
        if (deviceType === DeviceTypeEnum.ANDROID) {
            return <FooterMobile device={'Google'} />
        }

        else return <FooterMobile device={'iTunes'} />
    }

    const [isRuPayment, setIsRuPayment] = useState<boolean>(false)

    const PayRUHanlder = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleDesctopPayment()
    }

    return (
        <>
            {
                !isRuPayment
                    ?
                    <>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >

                            <Alert theme={themeAlertEnum.Danger} close={removeAlert} isOpen={isFailedAlert} text={'Профиль сохранен'}>
                                <span>{t('SUBSCRIPTION_ERROR')}</span>
                            </Alert>

                            <Modal className="modalPay" style={
                                { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} show={show} >
                                <Modal.Body style={{ padding: '50px' }}>

                                    {
                                        (isWaitingPurchaise || isSuccessPurchaise) && <div className="WaitingPurchaise">
                                            <span className="spinner spinner-lg spinner-primary"></span>
                                            {
                                                isSuccessPurchaise && <SuccessPurchaise
                                                    isTimerClosed={isTimerClosed}
                                                    closeInterval={closeInterval} />
                                            }

                                            {
                                                isWaitingPurchaise && <WaitingPurchaise />
                                            }

                                        </div>
                                    }
                                    {
                                        isPurchaiseClose && <ClosedPurchaise setShow={setShow} />
                                    }
                                </Modal.Body>
                            </Modal>
                        </div>

                        <div className='row mt-n4 ml-n4 justify-content-md-center'>



                            <div className="card card-custom col-12 col-lg-8 p-3 text-center">
                                {/* <div className="d-flex mb-5 justify-content-center"> */}
                                    {
                                        isTrial && <div style={{
                                            backgroundImage: `url("${SubChart}")`,
                                            backgroundSize: 'contain',
                                            backgroundRepeat: 'no-repeat',
                                            maxWidth: '500px',
                                            aspectRatio: '1/1'
                                        }} className="d-flex mb-7 justify-content-center subscriptionImageWrapper">

                                            <img width={278} height={81} className="tootip-image animate__animated animate__fadeInDown tooltip-delay" src={sub_tooltip} />
                                            <img width={330} height={93} className="push-image-1 animate__animated animate__zoomIn push-delay-1" src={sub_push_1} />
                                            <img width={350} height={93} className="push-image-2 animate__animated animate__zoomIn push-delay-2" src={sub_push_2} />
                                        </div>
                                    }
                                {/* </div> */}

                                {
                                    isTrial && <b className="mb-4 h3 font-weight-bolder">{t('SUBSCRIPTION_MOBILEONLY_HEADER')}</b>
                                }

                                <div className="subImagesWrapper">
                                    {/* animate__animated animate__fadeInDown */}
                                </div>
                                {
                                    !isTrial && <div className='row mt-n4 ml-n4 justify-content-md-center'>
                                        <span className="svg-icon svg-icon-primary svg-icon-4x mt-6 mb-2">
                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                                                height="24px" viewBox="0 0 24 24" version="1.1">
                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                    <rect x="0" y="0" width="24" height="24"></rect>
                                                    <path
                                                        d="M12.3740377,19.9389434 L18.2226499,11.1660251 C18.4524142,10.8213786 18.3592838,10.3557266 18.0146373,10.1259623 C17.8914367,10.0438285 17.7466809,10 17.5986122,10 L13,10 L13,4.47708173 C13,4.06286817 12.6642136,3.72708173 12.25,3.72708173 C11.9992351,3.72708173 11.7650616,3.85240758 11.6259623,4.06105658 L5.7773501,12.8339749 C5.54758575,13.1786214 5.64071616,13.6442734 5.98536267,13.8740377 C6.10856331,13.9561715 6.25331908,14 6.40138782,14 L11,14 L11,19.5229183 C11,19.9371318 11.3357864,20.2729183 11.75,20.2729183 C12.0007649,20.2729183 12.2349384,20.1475924 12.3740377,19.9389434 Z"
                                                        fill="#000000"></path>
                                                </g>
                                            </svg>
                                        </span>
                                        <b className="mb-4 h3 font-weight-bolder">{t('SUBSCRIPTION_PLANS')}</b>
                                        <div className="d-flex justify-content-center mb-4">

                                            <ul className="nav nav-light-dark nav-pills">
                                                {ButtonSubGroup()}
                                            </ul>
                                        </div>
                                        <div className="tab-content">
                                            <div className="tab-pane fade active show" id="tab_1" role="tabpanel"
                                                aria-labelledby="tab_1">
                                            </div>
                                        </div>
                                    </div>
                                }
                                <ListAvdventages plane={getCurrentPlane()} />

                                {
                                    (window.navigator.userAgent.includes('Android') && getCurrentPlane() === PlaneTypes.LITE)
                                        ? <></>
                                        : <>
                                            {
                                                getCurrentPlane() !== PlaneTypes.FREE && !isTrial && <b className='ChoosePlane'>{t('SUBSCRIPTION_PLANS_PERIODS')}</b>
                                            }
                                        </>
                                }

                                {
                                    (window.navigator.userAgent.includes('Android') && getCurrentPlane() === PlaneTypes.LITE) ? <></>
                                        : <>  {tabElements()}  </>
                                }

                                {
                                    getCurrentPlane() !== PlaneTypes.FREE
                                        ? <>
                                            {
                                                (window.navigator.userAgent.includes('Android') && getCurrentPlane() === PlaneTypes.LITE)
                                                    ? <LiteAndroidButton />
                                                    : <PayButtonMobile isTrial = {isTrial} text={getCurrentButtonText()} currentTab={getCurrentTab()}
                                                        getCurrentSCU={getCurrentSCU} freePrice={freePriceButtonState} isAppleRewiew={isAppleRewiew} price={
                                                            getNumberForButton(getCurrentTab()?.price as string)
                                                        } />
                                            }
                                        </>
                                        : <>
                                            <FreeAlert />
                                        </>
                                }
                                <IphoneAlert payHandler={PayRUHanlder} />
                                {chooseRightFooter()}
                            </div>
                        </div>
                    </>
                    : <>
                        <SpecialRuMobileWaitingPayment getCurrentTab={getCurrentTab} />
                    </>
            }

        </>
    )
}

export default MobileSubscription


