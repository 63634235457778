import React, { useEffect, useRef } from "react";
import '../../styles/ButtonDisableHover.css'
import { DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";

export interface IListStruct {
    text: string,
    id: number,
    check: boolean,
    classElement:string,
    ClickToItemList:(id:number) => () => void
}

function DropDownElement({classElement, id, text, check,ClickToItemList}: IListStruct) {
    const LabelRef = useRef<HTMLLabelElement>({} as HTMLLabelElement)
    useEffect(() => {
        // if(DeviseIsApplicationOnly()){
    },[])

    return (
        <label ref = {LabelRef} onClick={ClickToItemList(id)}  className="dropdown-item checkbox js__filter-events-type py-2">

            {
                (check)
                    ?<i className={classElement + ' text-primary' + ' ' + 'fa-fw mr-3'}></i>
                    :<i className={classElement  + ' ' + 'fa-fw mr-3'}></i>
            }
            {text}
        </label>
    )
}

export default DropDownElement