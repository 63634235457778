import { UseActions } from '../../hooks/useActions'
import '../../styles/NewEventAlert.css'
import useEvent from "../../Strategy/Events/useEvent";
import {EventTopicEnum} from "../../models/EventContentModel";
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

interface INewEventAlert{
    numberNewEvent:number
    clickHandler:() => void
    currentTopic:EventTopicEnum
}
function NewEventAlert({numberNewEvent,clickHandler,currentTopic}:INewEventAlert){
    const {setNewEvent} = UseActions()
    const {getPeriodRequest} = useEvent()
    const { t} = useTranslation();

    const inputClick = () => {
        getPeriodRequest(currentTopic)
        clickHandler()
    }

    return <>
        <div className={'NewAlertContainer'}>
            <div   className="position-absolute  NewAlertEvent mr-4 mr-lg-8 align-self-start NewAlertFilter">
                <label data-filter="1" className="radio btn btn-sm d-flex flex-shrink-1 align-items-center btn-primary">
                    <input  onClick={inputClick} type="radio" name="filter" value="1" /> 
                        {t('EVENTS_NEW',{count:numberNewEvent})}
                     <b className="ml-2">< b className="fas fa-arrow-up">
                        </b></b>
                </label>
            </div>
        </div>
    </>
}
export default NewEventAlert