import '../../styles/ReportWidget.css'
import ReportBody from "./ReportBody";


function ReportWidget(){

    return(
        <div>
            <div className="reportWidget__card">
                <ReportBody/>
            </div>
        </div>
    )
}

export default  ReportWidget