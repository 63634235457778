import React, { ChangeEvent, useLayoutEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { PlaneTypes } from "../../models/User";
import { giftBudget, successBudget } from "../Badgets";
import { IDiscountInfo } from "./DesktopSubscription";
import { IfreePriceButtonState, IMountPrice } from "./MobileSubscription";

interface IPriceTab {
    time: string,
    price: string,
    checked: boolean,
    clickFunction: (time: string) => void,
    freePriceButtonState?: IfreePriceButtonState
    sku?: string,
    initialCost?: string,
    discount?: number
    currency?: string
    isAppleRewiew?: boolean,
    discountInfo?: IDiscountInfo | null,
    cardPlane?: PlaneTypes,
    loadingTariffs?: boolean,
    isAvailableTrial?: boolean,
    mountPrice?: IMountPrice
    isTrial?:boolean
}

const dotCommaHandler = (res:string) => {
    const fooArr = []
    for(let i = 0; i < res.length; i++){
        if(res[i] === '.') fooArr.push([res[i],i])
    }
    if(fooArr.length > 1){
        let output = res
        while(fooArr.length !== 1){
            //@ts-ignore
            output = output.slice(0, fooArr[0][1] ) + res.slice(fooArr[0][1] + 1,res.length) 
            fooArr.shift() 
        } 
        return output 
    }
    else {
        return res    
    }
}

export const getNumber = (stringValue: string) => {
    let splittedString = stringValue.replace(/[\s]/g, '')
    splittedString = splittedString.replace(/,/g, '.')
    splittedString = dotCommaHandler(splittedString)
    const value = parseFloat(splittedString.match(/[\d,.]+/)![0].replace(/,/g, '.'))
    return value
}

const getNumberPrice = (value: string) => {
    const priceCharacters = value.split('')
    return parseFloat(priceCharacters.filter((value: any) => {
        if (value === ' ' || value === ' ') return false
        if (value === ',' || value === '.') return false
        return !isNaN(value)
    }).join(''))
}

const getMountPrice = (isAvailableTrial: boolean, cardPlane: PlaneTypes, PriceTabs: any) => {
    let mountPrice = 0
    if (cardPlane === PlaneTypes.PRO || cardPlane === PlaneTypes.LITE) {
        if (cardPlane === PlaneTypes.PRO) {
            if (isAvailableTrial) {
                mountPrice = getNumberPrice(PriceTabs[1].price)
            }
            else {
                mountPrice = getNumberPrice(PriceTabs[0].price)
            }
        }
        else {
            mountPrice = getNumberPrice(PriceTabs[0].price)
        }
    }
    return mountPrice
}

const spaceFormat = (r:any) => {
    let out = ''
    for(let i = 0 ; i <= r.length - 1; i++){
        if( /^[0-9.,]$/.test(r[i]) && !(/^[0-9]$/.test(r[i+1])) ||  (/^[0-9.,]$/.test(r[i+1]) && !(/^[0-9]$/.test(r[i]))) ){
            if(r[i] === '.' || r[i] === ',' ){
                out += r[i]
                continue
            }
            if(r[i+1] === '.' || r[i+1] === ',' ){
                out += r[i]
                continue
            }
            out += r[i] + ' '
            continue;
        }
        out += r[i]
    }
    return out
}

function PriceTab({ time, price, cardPlane, mountPrice, discountInfo, checked, isTrial,sku, clickFunction, freePriceButtonState, discount, initialCost, currency, isAppleRewiew }: IPriceTab) {
    const region = useTypedSelector(state => state.UserReducer.User.settings.region)

    const [faceInitialConst, setFaceInitialConst] = useState<null | string>(null)
    const [faceDiscount, setfaceDiscount] = useState<null | number>(null)
    const lastSpace = useRef<null | number>(null)
    const { getPrice } = useFormatCurrency()
    const getLastSpace = (res: string) => {
        const arr = []
        for (let i = 0; i < res.length - 1; i++) {
            //@ts-ignore
            if (res[i] !== ' ' && !isNaN(res[i])) {
                //@ts-ignore
                if (!( /^[0-9]$/.test(res[i + 1])) ) arr.push(i)
            }
        }
        lastSpace.current = arr[arr.length - 1]
    }
    const insertLastSpace = (res: string) => {
        if (!lastSpace.current) return ''
        res.slice(0, lastSpace.current)
        return res.slice(0, lastSpace.current + 1) + ' ' + res.slice(lastSpace.current + 1, res.length)
    }

    useLayoutEffect(() => {
        if (discountInfo === undefined) return
        let mutationPrice: number = 0

        if (cardPlane === PlaneTypes.PRO) {
            mutationPrice = mountPrice!.proMount as number * 2
        }
        else if (cardPlane === PlaneTypes.LITE) {
            mutationPrice = mountPrice!.liteMount as number * 2
        }

        if (!discountInfo) {
            if (sku !== 'ts_monthly_trial') {
                setfaceDiscount(50)
                let formatedNumber = ''
                if (true) {
                    const newPrice = String(getNumber(price) * 2)                      
                    formatedNumber = price.replace(/[\s]/g, '').replace(/[\d,.\s]+/g, newPrice)
                    return setFaceInitialConst(spaceFormat(formatedNumber))
                    getLastSpace(formatedNumber)
                    if(!lastSpace.current)
                        return setFaceInitialConst(formatedNumber)  
                    setFaceInitialConst(insertLastSpace(formatedNumber))
                }
                else {
                    formatedNumber = getPrice(getNumber(price) * 2, currency)
                    setFaceInitialConst(formatedNumber)
                }
            }
        }
    }, [time, mountPrice, cardPlane])
    const changeInput = (time: string) => (e: ChangeEvent<HTMLInputElement>) => {
        clickFunction(time)
    }
    const { t } = useTranslation()
    const chooseGift = () => {
        if (faceDiscount) return giftBudget({ percent: faceDiscount })
        if (time === `${t('SUBSCRIPTION_PLANS_PERIODS_7D')}` && !isTrial ) return successBudget({ text: `${t('SUBSCRIPTION_PLANS_RECOMMEND')}` })
        if (!discount) return <></>
        return giftBudget({ percent: discount })
    }
    const outPutMountlyTrial = (price: string) => {
        return <>
            <div> {price} </div>
            <div>
                <small> {freePriceButtonState?.partTwo} </small>
            </div>
        </>
    }

    const outPutPrice = (price: string) => {
        if (window.navigator.userAgent.includes('Tradesense') && !isAppleRewiew && sku === 'ts_monthly_trial') {
            return outPutMountlyTrial(price)
        }
        else {
            if (faceInitialConst &&
                sku !== 'ts_monthly_trial'
            ) {
                return <>
                    <div> {price} </div>
                    <div>
                        <s>
                            <small> {faceInitialConst} </small>
                        </s>
                    </div>
                </>
            }

            if (
                (initialCost) &&
                sku !== 'ts_monthly_trial' &&
                !window.navigator.userAgent.includes('Tradesense') &&
                price !== initialCost
            ) {

                return <>
                    <div> {price} </div>
                    <div>
                        <s>
                            <small> {faceInitialConst} </small>
                        </s>
                    </div>
                </>
            }
            return price
        }
    }

    const renderTime = () => {
        if (
            window.navigator.userAgent.includes('Tradesense') ||
            sku === 'ts_monthly_trial' || price === initialCost
        ) {
            return <>
                <span className="option-title font-weight-bolder">
                    {time}
                    {chooseGift()}
                </span>
            </>
        }
        else {
            return <>
                <span className="d-flex flex-column option-title font-weight-bolder">
                    {time}
                    <div className="mt-1">
                        {chooseGift()}
                    </div>
                </span>
            </>
        }
    }

    return (
        <div className="row text-left justify-content-md-center">
            <div className="col-lg-6">
                <label className="option cursor-pointer">
                    <span className="option-control">
                        <span className="radio">
                            <input type="radio" onChange={changeInput(time)} checked={checked} name="sku" value="ts_monthly"
                                data-amount="" />
                            <span>

                            </span>
                        </span>
                    </span>
                    <span className="option-label">
                        <span className="option-head">
                            {renderTime()}
                            <span style={{ textAlign: 'end' }} className="option-focus">
                                {outPutPrice(price)}
                            </span>
                        </span>
                    </span>
                </label>
            </div>
        </div>
    )
}

export default PriceTab