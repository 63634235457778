import React from 'react';

function AllStocks(){

    return (
        <div>
            <h1> Все акции </h1>
        </div>
    );
}

export default AllStocks;