import { AppDispatch } from '../..'
import { IProfile } from '../../../models/User'
import { IProfileTranslateObj } from '../../../page/Profile'
import UserService from '../../../Services/UserService'
import { AuthGlobalActionCreators } from '../User/actions'
import {
    ISetAbout,
    ISetFullName,
    ISetProfileUser,
    ISetIsChanged,
    ProfileUserEnum,
    iSetUserName,
    setProfileType,
    ProfileUserState,
    ISave,
    IErrorProfile,
    IError,
    ISetSuccess
} from './types'
import {IUSerInformation} from "../User";

export const userProfileActionCreator = {
    setSuccess:(boolValue:boolean):ISetSuccess => {
        return {
            type:ProfileUserEnum.SET_SUCCESS,
            payload:boolValue,
        }
    },
    setAboutProfile:(inputValue:string):ISetAbout => {
        return { 
            type:ProfileUserEnum.SET_ABOUT,
            payload:inputValue
        }
    },
    setFullNameProfile:(inputValue:string):ISetFullName => {
        return { 
            type:ProfileUserEnum.SET_FULL_NAME,
            payload:inputValue
        }
    },
    setUserNameProfile:(inputValue:string):iSetUserName => {
        return { 
            type:ProfileUserEnum.SET_USER_NAME,
            payload:inputValue
        }
    },
    setisChangedProfile:(flag:boolean):ISetIsChanged => {
        return { 
            type:ProfileUserEnum.SET_IS_CHANGED,
            payload:flag
        }
    },
    setProfileUser:(ProfileObj:setProfileType):ISetProfileUser => {
        return { 
            type:ProfileUserEnum.SET_PROFILE_USER,
            payload:ProfileObj
        }
    },

    setSaveProfile:():ISave => {
        return {
            type:ProfileUserEnum.SAVE,
        }
    },
    setErrorProfile:(error:IError):IErrorProfile => {
        return {
            type:ProfileUserEnum.SET_ERROR,
            payload:error
        }
    },
    saveProfile:(state:ProfileUserState) => async (dispatch:AppDispatch) => {
        const ProfileNetworkObj:Partial<IProfileTranslateObj> = {}
        Object.entries(state).forEach(([key,value]) => {
            const typedKey = key as keyof IProfileTranslateObj
            if(key !== 'isChanged' ) {
                ProfileNetworkObj[typedKey] = value
            }
        })
        const response = await UserService.saveProfile(ProfileNetworkObj)
        if(response.data.status === "failed"){
            dispatch(userProfileActionCreator.setErrorProfile({
                isError:true,
                textError:response.data.error.message                
            })
            )
        }
        else{
            dispatch(userProfileActionCreator.setSaveProfile())
            dispatch(AuthGlobalActionCreators.setProfileUser(ProfileNetworkObj as IUSerInformation))
            dispatch(userProfileActionCreator.setSuccess(true))
        }
    }



}