import React from "react";
import WidgetLoaders from "../components/loaders/WidgetLoaders";
import { currentCompany } from "../redux/reducers/Tiker/types";

export interface ICompanyContext{
    ForecastElements:Array<any>,
    PressReleaseElements:Array<any>,
    ReportingElements:Array<any>
    isLoading:boolean,
    companyInformation:any,
    setTickerEvents:any,
    TickerEvents:any,
    lastRowTickerEventHandler:any,
}

export interface IMessageContext {
    companyName: string,
    setCompanyName: (companyName: string) => void
}

export const CompanyContext = React.createContext<ICompanyContext>({
    ForecastElements: [],
    PressReleaseElements: [<WidgetLoaders/>,<WidgetLoaders/>,<WidgetLoaders/>,<WidgetLoaders/>],
    ReportingElements: [],
    isLoading:true,
    companyInformation:{},
    TickerEvents:[],
    setTickerEvents:() => {},
    lastRowTickerEventHandler:() => {},
});

export  interface ITradingInfo{
    status: any | null,
    startAt: number | null
}

export interface ICompanyIsCrypto{
    isCrypto:boolean
}


export enum IStateMarketEnum{
    POST = 'POST',
    PRE = 'PRE',   
}

export interface IAdditionalPriceInfo{
    currency:string,
    finishedAt:number,
    oldPrice:number,
    price:number,
    state:IStateMarketEnum
}

interface ITickerContext{
    currentTickerState:currentCompany,
    companyInformation:any,
    investApp:string | null,
    SubsctribleHandler:(flag:boolean) => void,
    tradingInfo:ITradingInfo,
    showStatusTooltip:boolean,
    setShowStatusTooltip:(boolValue:boolean) => void,
    setTradingInfo:(traidingInfo:ITradingInfo) => void,
    isCoinsPage:ICompanyIsCrypto,
    additionalPriceInfo:IAdditionalPriceInfo | null,
    currentPrice:number,
}

export const TickerContext =  React.createContext<ITickerContext>({
    currentTickerState:{
           src:'',
            ticket: '',
            cardName: '',
            price: null,
            percent: null,
    },
    tradingInfo:{
        status:null,
        startAt:null
    },
    companyInformation:{},
    investApp:null,
    SubsctribleHandler:(undefined) =>{ },
    showStatusTooltip:false,
    setShowStatusTooltip: (undefined) => {},
    setTradingInfo:(undefined) => {},
    isCoinsPage:{isCrypto:false},
    additionalPriceInfo:null,
    currentPrice:0
});

export const MessageContext = React.createContext<IMessageContext>({
    companyName:'',
    setCompanyName:() => {},
})