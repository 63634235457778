import {useLocation, useNavigate} from "react-router-dom";
import {UseActions} from "./useActions";
import React from "react";
import { useTypedSelector } from "./useTypedSelector";
import {DeviceIsMobile, checkForSendYEvent, gettOrigin, isCloseAnchor} from "../Utills/ClearFunctions";
import {useCheckForPage} from "./useCheckForPage";
import {ORIGIN} from "../models/Domen";
import {IReportCard} from "../components/Cards/ReportCard";
import UserService from "../Services/UserService";
import { sharedCheckForCIS } from "./useSelectSubscriptions";

export function useTransition() {
    const navigate = useNavigate()
    const {setHistoryNumber, setRefresshUser} = UseActions()
    const {SetGlobalUserAuth,SetShowTelegram,SetTelegramIsConnect,SetProfileAlert,logout} = UseActions()
    const showTelegram = useTypedSelector(state => state.EventReducer.showTelegram)
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const User = useTypedSelector(state => state.UserReducer.User)
    const location = useLocation()
    const isCryptoPage = useTypedSelector(state => state.PageReducer.isCryptoPage)

    const {setCurrentMessage} = UseActions()

    const  ApplicationRedirect = (notApplicationUrl:string, applicationRelativeUrl:string) => {
        if(isApplication)
            window.location.replace(ORIGIN + applicationRelativeUrl)
        else
            navigate(notApplicationUrl)
    }
    const {
        checkForProfilePage
    } = useCheckForPage()

    enum CompanyTopics{
        MAIN = "stocks",
        EVENTS = "events",
        COMPANY = "company",
    }

    const telegramOpenHandler = () => {
        if(showTelegram){
            SetTelegramIsConnect(true)
        }
    }



    const Transitions = {
        commentTransition:(callback:(show:boolean)=> void, show:boolean, id:string | number | undefined) => {
            if ( isApplication  ) {
                window.location.replace(ORIGIN + `/events/comments?eventId=${id}`)
            } else callback(!show);
        },
        transitionToMessagePage:(callback:() => void,props: Omit<IReportCard,'Comment'>, isClickToUIApplication?:boolean) => {
            if(isApplication){
                if(isClickToUIApplication){
                    window.location.replace(ORIGIN + `/stocks/SAVE/read-news/${props.id}?isShowAI`)
                }
                else {
                    window.location.replace(ORIGIN + `/stocks/SAVE/read-news/${props.id}`)
                }
            }
            else{
                setCurrentMessage({
                    source:props.source,
                    text:props.text,
                    date:props.date,
                    title:props.title,
                    CompanyName:props.CompanyName,
                    dateFull: props.dateFull,   
                    sourceName:props.sourceName,
                    translatedTitle: props.translatedTitle,
                    translatedText:props.translatedText,
                    key:props.id,
                    ticket:props.CardInformation.ticker,
                })
                callback()
            }
        },
        transitionToComments:(e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            navigate('/events/comments')
        },
        transitionToWaiting: () => {
            navigate('/waiting')
        },
        transitionToTicket:(e:React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            e.preventDefault()
            setHistoryNumber(window.history.state)

            const Domen = window.location.origin
            if(isApplication) {
                window.location.replace(Domen + '/stocks/AAPL')
            }
            else{
                navigate('../stocks/AAPL',{replace:false})
            }
        },
        transitionToTicketTest:(ticket:string) => {
            const getPageName = () => {
                if(!User.isCrypto)
                    return 'stocks'
                else return 'coins'    
            }
            telegramOpenHandler()
            const pagePath = 'stocks'
            if(isApplication) {
                if(window.navigator.userAgent.includes('Android') ){
                    window.location.href = ORIGIN + `/${pagePath}/${ticket}`
                }
                else {
                    window.location.href = ORIGIN + `/${pagePath}/${ticket}`
                }
            }
            else{
                const urlPath = location.pathname.split('/')
                if(urlPath.length>2 && location.pathname.includes(pagePath))
                    navigate(`../${pagePath}/${ticket}`,{replace:true})
                else navigate(`../${pagePath}/${ticket}`,{replace:false})
                return
            }

        },

        transitionToTicketApplication:(e:React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            e.preventDefault()
            setHistoryNumber(window.history.state)
            window.location.assign(`${gettOrigin()}/stocks/AAPL`)

        },
        transitionToMain:(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            navigate('/')
        },
        transitionToMainHandlers:() => {
            navigate('/events')
        },
        transitionToEventMain:() => {
            // alert('events')
            navigate('/events')
        },
        transitionToMainCompany:(e: React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            e.preventDefault()
            navigate(``,{replace: true} )
           
        },
        companyTransition:(e: React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            e.preventDefault()
            if(User.isCrypto)
                ApplicationRedirect('coins','/coins')
            else
                ApplicationRedirect('stocks','/stocks')
        },
        eventTransitionFromSideBar:(e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            navigate('', {replace: false})
        },
        eventTransition:(e: React.MouseEvent<HTMLAnchorElement>) => {
            if( isCloseAnchor(userAgent, location.pathname,isApplication) ){
                e.preventDefault()
                Transitions.transitionToAnchor()
                return
            }
            e.preventDefault()
            if(window.navigator.userAgent.includes('Tradesense'))
                return
            navigate('', {replace: false})
        },

        cancelTransition:(e: React.MouseEvent<HTMLAnchorElement>) => {
            if(User.subscriptionInfo.isActiveAppleSubscription && User.subscriptionInfo.isActiveGoogleSubscription){
                Transitions.profileTransition(e)
                return
            }
            navigate('../finance/cancel-auto-payment', {replace: false})
            e.preventDefault()
        },
        profileTransition:(e: React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            navigate('../profile', {replace: false})
            e.preventDefault()
        },
        transitionCancelToProfile:(e: React.MouseEvent<HTMLAnchorElement>) => {
            SetProfileAlert(true)
            navigate('../profile', {replace: false})
            e.preventDefault()
        },
        transitionToFavorites:(e: React.MouseEvent<HTMLAnchorElement>) => {
            telegramOpenHandler()
            navigate('../favourites', {replace: false})
            e.preventDefault()            
        },
        transitionToMainPageNotAuthUser:() => {
            if(!User.isCrypto){
                SetShowTelegram(true)
                if(isApplication){
                    if (!sharedCheckForCIS()) {
                        window.localStorage.setItem('isRedirectToPayment', 'true')
                    }
                }
            }
            SetGlobalUserAuth(true)
            checkForSendYEvent("favourites_selected")
            ApplicationRedirect('/events','/events')
        },
        transitionToEvents:() => {
            telegramOpenHandler()
            navigate(`${CompanyTopics.EVENTS}`,{replace: true} )
        },
        transitionToCompany:() => {
            telegramOpenHandler()
            navigate(`${CompanyTopics.COMPANY}`,{replace: true} )
        },
        cryptoNavigateWrapper:(url:string) => {
            if(isCryptoPage){
                navigate('/crypto' + url)
                return
            }
            navigate(url)
        },
        transitionToWizard: (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            Transitions.cryptoNavigateWrapper('/')
        },
        transitionToLogIn:() => {
            Transitions.cryptoNavigateWrapper('/login')
        },
        transitionToSupport: (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            navigate('/support')
        },
        transitionToFavouritesPage:() => {
            telegramOpenHandler()
            navigate('/select-favourites')
        },
        transitionToSubscriptionPage: (e: React.MouseEvent<HTMLAnchorElement>) => {
            checkForSendYEvent('subscription_screen')
            telegramOpenHandler()
            e.preventDefault()
            if(User.subscriptionInfo.isActiveAppleSubscription && User.subscriptionInfo.isActiveGoogleSubscription){
                Transitions.profileTransition(e)
                return
            }
            else
                ApplicationRedirect('/finance/subscription','/finance/subscription')
        },
        logOutTransition: () => {
            logout()
        },
        transitionToAnchor:() => {
            window.location.href =  '#close'
        },
        transitionToInvites: () => {
            ApplicationRedirect('profile/invites','/profile/invites')
        },
        transitionToNotification: () => {
            ApplicationRedirect('/profile/notifications','/profile/notifications')
        },
        transitionToMutedPage:(e:React.MouseEvent<HTMLAnchorElement>) =>{
            ApplicationRedirect('../profile/muted-stocks','/profile/muted-stocks')
            e.preventDefault()
        },
        transitionToMutedPageWithoutEvent:() => {
            ApplicationRedirect('../profile/muted-stocks','/profile/muted-stocks')
        },
        transitionToMoversPage:() => {
            ApplicationRedirect('../stocks/movers','/stocks/movers')
        },
        transitionToMarketMapPage:(id:number) => {
            ApplicationRedirect(`/stocks/market-map/${id}`,`/stocks/market-map/${id}`)
        },
        handleCloseSSubscrible: () => {
            setRefresshUser(true)
            window.location.href =  '#closerefresh'
        }
    }

    return (Transitions)

}

