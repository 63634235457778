import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {useEffect, useState} from "react";
import EventTime from "../Time/EventTime";
import CommentsModal from "../Modals/CommentsModal";
import {useTransition} from "../../hooks/useTransition";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import { Trans, useTranslation } from "react-i18next";
import useDate from "../../hooks/useDate";

interface IFluctuation {
    isCommentOpen?:boolean
    from:string,
    to:string,
    Comment:any
    CardInformation:ICompanyInformationCard,
    fromTimestamp:number,
    toTimestamp:number,
    id?:string
    countComments?:number
    mediumTime?:string | number | null

}

function StopEvent(props: IFluctuation) {
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const {getshortTime} = useDate() 
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const {checkForStockPage} = useCheckForPage()

    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.STOP_EVENT,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }

    const {t} =useTranslation()
    
    return (
        <>
            <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.STOP_EVENT} data={{...{...props,isCommentOpen:false}}}/>

                <span className="font-weight-bolder mb-2">
                    <i className="fas fa-ban text-danger mr-2"></i> {t('EVENT_SPB_HALT')}
                </span>

                <span className="py-2">
                    <Trans i18nKey={'EVENT_SPB_HALT_TIME'}>
                        <b>{{from: getshortTime(props.fromTimestamp) }} {{to:getshortTime(props.toTimestamp)}}</b>
                    </Trans>
                </span>

                {
                    props.Comment
                }
            
                { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }

        </>
    )
}

export default StopEvent