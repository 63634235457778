import {actionType,currentCompany,CurrentCompanyEnum} from './types'

const initialState: currentCompany = {
    capitalization:'',
    shareVolume:'',
    logo:'',
    ticker:'',
    brand:'',
}

export default  function CompanyReducer(state:currentCompany = initialState, action:actionType):currentCompany{
    switch (action.type){
        case CurrentCompanyEnum.SET_CAPITALIZATION_AND_SHARED_VOLUME:
            return  {...state,...action.payload}
        default: return state
    }
}
