import React, { useEffect } from "react"
import { IImage } from "./DescriptionBarLargeImage"

function CompanyInformationImage({src}:IImage){

    return <div
        className="symbol symbol-20 symbol-lg-20 symbol-circle"
         style = {{
            backgroundImage:`url(${src})`,
            backgroundSize: 'cover',
            width:'30px',
            height:'30px',
            }}>
    </div>
    
}
export default CompanyInformationImage