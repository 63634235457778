import '../styles/BackDrop.css'
import {useTypedSelector} from "../hooks/useTypedSelector";
import React, {ReactNode, useEffect} from "react";
import {UseActions} from "../hooks/useActions";
import { deviceIsIphoneApplication } from '../Utills/ClearFunctions';

export interface IBackDrop {
    show: boolean
    children?: ReactNode | undefined
}

function BackDrop() {
    const BackDropisOpen = useTypedSelector(state => state.BackDropReducer.BackDropisOpen)
    const children = useTypedSelector(state => state.BackDropReducer.children)
    const header = useTypedSelector(state => state.BackDropReducer.header)
    const {setBackDropIsOpen} = UseActions()

    const closeBackDrop = (e: React.MouseEvent<HTMLButtonElement | HTMLDivElement>) => {
        setBackDropIsOpen(false)
    }
    
    useEffect(() => {
        let root = document.documentElement;
        root.style.setProperty('--animate-duration', "0.5s");
    },[])

    useEffect(() => {
        if(BackDropisOpen && (deviceIsIphoneApplication())){
            document.body.classList.add('no-scroll')
        }
        return () => {
            document.body.classList.remove('no-scroll')
        }
    },[BackDropisOpen])

    return <>
        {
            BackDropisOpen && <>
                <div className={'modalBackdrop'} onClick={closeBackDrop}></div>
                <div className={'BackDrop animate__animated animate__slideInUp animate__faster'}>
                    <div className={'BackDrop__header'}>
                        <h3> {header} </h3>
                        <button type="button" onClick={closeBackDrop} className="btn-close" aria-label="Close"></button>
                    </div>
                    <div className={'BackDrop__hr'}>
                        <hr/>
                    </div>
                    <div className={'BackDrop__content'}>
                        <div className={'BackDrop__whiteSpace'}></div>
                        {children}
                    </div>
                </div>
            </>
        }
    </>
}

export default BackDrop
