export class TextIndicatorNetwork{
    private static instance:TextIndicatorNetwork
    private isPending:boolean = false;
    static isPendingStatic:boolean = false
    private isEmptyRespnose:boolean = false


    public setPending(isPending:boolean){
        this.isPending = isPending
    }

    public setIsEmptyResponse(isEmptyRespnose:boolean){
        this.isEmptyRespnose = isEmptyRespnose
    }

    public getEmptyResponse(){
        return this.isEmptyRespnose
    }
    public getPendig(){
        return this.isPending
    }

    static getObj(){
        if(!TextIndicatorNetwork.instance)
            TextIndicatorNetwork.instance = new TextIndicatorNetwork()
        return TextIndicatorNetwork.instance
    }
    
    static setNewInstance(){
        if(!TextIndicatorNetwork.instance)
            return
        TextIndicatorNetwork.instance = new TextIndicatorNetwork()    
    }


}