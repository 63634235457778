import React, { useState } from "react"
import AlertModal from "../Modals/AlertModal"
import {t} from "i18next";

function TelegramAlert() {
    const [isShowTelegramAlertMessage, setIsShowTelegramAlertMessage] = useState<boolean>(false)

    return <>
            <AlertModal show = {isShowTelegramAlertMessage} handleClose={() => {
                    setIsShowTelegramAlertMessage(false)
        }}/>
        <div className="card card-custom bg-light-warning mt-4 p-3">
        <div>
            <b>{t('TG_BANNER_NEWBOT_TITLE')}</b> {t('TG_BANNER_NEWBOT_MESSAGE_FEED')} <a href = ''  onClick={(e) => {
                e.preventDefault()
                setIsShowTelegramAlertMessage(true)
            }}>{t('TG_BANNER_NEWBOT_LINK_FEED')}</a></div>
        </div>
    </>
}
export default TelegramAlert