import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import {UseActions} from "../../hooks/useActions";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Phone from "../Inputs/Phone";
import GiftModalBody from './GiftModalBody';
import {useTranslation} from "react-i18next";

interface IGiftModal{
    show: boolean,
    handleClose: () => void
}

function GiftModal({show,handleClose}: IGiftModal)  {
    const countInvents = useTypedSelector(state => state.EventReducer.countInvites)
    const {t} =useTranslation()

    return (
        <Modal show={show} onHide={handleClose}>
            
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("INVITES_HAVE_INVITE",{count:countInvents as number})}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
               <GiftModalBody/>
            </Modal.Body>

        </Modal>
    );
}

export default GiftModal;