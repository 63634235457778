import useTelegram from "../../hooks/useTelegram";
import {t} from "i18next";
import {Trans} from "react-i18next";

function TelegramModalBody() {
    const {SetShowTelegram, installTelegram} = useTelegram()
    return (
        <div className="modal-body">
            <div className="mb-5 text-center card card-custom shadow-sm p-10 bg-gray-800 text-white">
                <h2 className="font-weight-bolder mb-4">{t('TG_BANNER_BOT')}</h2>
                <a target="_blank" onClick={installTelegram}
                   className="btn btn-primary font-size-lg font-weight-bolder px-8 py-5 shadow-lg"
                >
                    <i className="fab fa-telegram" aria-hidden="true"></i> {t('TG_BANNER_BOT_TELEGRAM')}
                </a>
            </div>

            <hr className="my-6"/>
            <h3 className="font-weight-bolder">{t('TG_BANNER_LINE_1')}</h3>
            <p className="text-dark-75">{t('TG_BANNER_LINE_2')}</p>
            <div className="d-flex align-items-start flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_3')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_4')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_5'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-grow-1 mt-4">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_6')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_7')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">

                        <Trans i18nKey={'TG_BANNER_LINE_8'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <hr className="my-6"/>
            <div className="d-flex align-items-start flex-grow-1">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_9')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_10')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_11'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-grow-1 mt-4">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_12')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_13')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_14'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-grow-1 mt-4">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_15')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_16')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_17'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-grow-1 mt-4">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_18')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_19')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_20'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-start flex-grow-1 mt-4">
                <div className="d-flex flex-column flex-grow-1">
                    <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_21')}</h4>
                    <p className="text-muted">{t('TG_BANNER_LINE_22')}</p>
                    <div
                        className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                        <Trans i18nKey={'TG_BANNER_LINE_23'}>
                            <div className="font-weight-bolder">
                            </div>
                            <div className={'font-size-sm'}></div>
                        </Trans>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TelegramModalBody