import React, { useEffect, useLayoutEffect, useState } from 'react';
import Stock from "./Stoсk";
import '../styles/SelectFavorites.css'
import Header from "../components/Layout/Header";
import { deleteLoginStyle, gettOrigin } from "../Utills/ClearFunctions";
import { useLocation } from "react-router-dom";
import { useTransition } from "../hooks/useTransition";
import useSelectCards from '../hooks/useSelectCards';
import { Alert, themeAlertEnum } from "../components/Alerts";
import { Trans, useTranslation } from 'react-i18next';
import { useTypedSelector } from '../hooks/useTypedSelector';
import SubChart from "../image/subChart.png"
import sub_push_1 from "../image/sub_push_1.png"
import sub_push_2 from "../image/sub_push_3.png"
import sub_tooltip from "../image/sub_tooltip.png"

export const LIMIT_NUMBER_OF_TICKERS = 3

function SelectFavourites() {
    const [SelectLessNotifiClick, setSelectLessNotifiClick] = useState<boolean>(false)
    const [LessNotify, setLessNotify] = useState<boolean>(false)
    const [Remain, setRemain] = useState<number>(5)
    const [NotifyMoreClick, setNotifyMoreClick] = useState<boolean>(false)
    const [NotifyMore, setNotiyMore] = useState<boolean>(false)
    const location = useLocation()
    const { transitionToFavouritesPage } = useTransition()
    const { t } = useTranslation()
    const { cardTickers, setCardTickers } = useSelectCards({ isSelect: true, IsFavorites: false })
    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto)
    // const isCrypto = true

    const addToCardTickers = (tickerName: string, src?: string) => {
        const { isDelete, DeletedCards } = checkToDeleteCards(tickerName)
        if (isDelete) {
            cardTickerIsFull(DeletedCards)
            setCardTickers(DeletedCards)
        }
        else {
            const tickersWithoutEmptyUrls = DeletedCards.filter((CardTicker) => CardTicker.src !== '')
            if (tickersWithoutEmptyUrls.length >= 5) {
                return
            }
            const newCardTickers = addNewCard(tickerName, src)
            cardTickerIsFull(newCardTickers)
            setCardTickers(newCardTickers)
        }
    }

    useEffect(() => {
        if (!location.pathname.includes('select-favourites'))
            transitionToFavouritesPage()
        deleteLoginStyle()
    }, [])

    useEffect(() => {
        const emptyCard = cardTickers.filter(card => card.src === '')
    }, [cardTickers])

    interface ICardTickers {
        src: string
    }

    useLayoutEffect(() => {
        const remain = cardTickers.filter(card => card.src !== '').length
        setRemain(LIMIT_NUMBER_OF_TICKERS - remain)

        if (remain >= 5) {
            setSelectLessNotifiClick(true)
        }
    }, [cardTickers])

    const cardTickerIsFull = (CardTickers: Array<ICardTickers>) => {
        const tickersWithoutEmptyUrls = CardTickers.filter((CardTicker) => CardTicker.src !== '')
        setRemain(LIMIT_NUMBER_OF_TICKERS - tickersWithoutEmptyUrls.length)
        const length = tickersWithoutEmptyUrls.length
        if (length < LIMIT_NUMBER_OF_TICKERS) {
            setSelectLessNotifiClick(false);
            setNotifyMoreClick(false);
        }
        if (length > 5) {
            setNotifyMoreClick(true);
            setSelectLessNotifiClick(false)
        }
        if (length === LIMIT_NUMBER_OF_TICKERS) setSelectLessNotifiClick(true)
    }

    const checkToDeleteCards = (tickerName: string): { isDelete: false, DeletedCards: Array<ICardTickers> } => {
        let isDelete = false
        const DeletedCards = cardTickers.map((cardTicker) => {
            if (cardTicker.src.includes(tickerName)) {
                isDelete = true
                return { src: '' }
            }
            return { ...cardTicker }
        })

        return { isDelete, DeletedCards }
    }

    const addNewCard = (tickerName: string, src?: string): Array<ICardTickers> => {
        let isFull = true
        let resurce = isCrypto ? 'coins' : 'stocks'
        let cardSrc = `${gettOrigin()}/img/${resurce}/${tickerName}.png`
        if (src)
            cardSrc = src
        const newCardTickers = cardTickers.map((cardTicker) => {
            if (cardTicker.src === '' && isFull) {
                isFull = false
                return { ...cardTicker, src: `${cardSrc}` }
            } else {

                if (cardTicker.src.includes(tickerName)) {
                    isFull = false
                    return { src: '' }
                }
                return { ...cardTicker }
            }
        })
        if (isFull) newCardTickers.push({ src: `${gettOrigin()}/img/stocks/${tickerName}.png` })
        return newCardTickers
    }

    const cardTickersElement = cardTickers.map((cardTicker, index) => {
        if (cardTicker.src === '') {
            return <div className="symbol symbol-30 symbol-circle symbol-light-primary ticker_empty">
                <span className="symbol-label">
                    <i className="fa fa-star-o text-primary"
                        aria-hidden="true">
                    </i>
                </span>
            </div>
        } else {
            return <div className="symbol symbol-30 symbol-circle ticker_full ticker_full_SPCE">
                <img alt='logo' src={cardTicker.src} />
            </div>
        }
    })

    const closeAlert = () => {
        setLessNotify(false)
    }

    return (
        <>
            <div>
                <link rel="preload" href={SubChart} as="image" />
                <link rel="preload" href={sub_push_1} as="image" />
                <link rel="preload" href={sub_push_2} as="image" />
                <link rel="preload" href={sub_tooltip} as="image" />

                <div id="kt_header" className="header header-fixed border-bottomm h-auto ">
                    <Header
                        setNotifyMore={setNotiyMore}
                        NotifyMoreClick={NotifyMoreClick}
                        setLessNotify={setLessNotify}
                        SelectIsFull={SelectLessNotifiClick}
                        isOpenSideMenu={false}
                        setIsOpenSideMenu={() => {
                        }} searchIsOpen={false}
                        setSearchIsOpen={() => {
                        }} profileIsOpen={false} setProfileIsOpen={() => {
                        }} />
                </div>

                <Alert
                    theme={themeAlertEnum.Danger}
                    close={closeAlert}
                    isOpen={LessNotify && !NotifyMoreClick}
                    text={' в избранное для продолжения регистрации. Данный список вы сможете менять в любой момент в сервисе.'}
                >
                    <>
                        {
                            !isCrypto
                                ?
                                <span>
                                    <Trans count={Remain} i18nKey='WIZARD_FAVORITES_ADDMORE'>
                                        <b></b>
                                    </Trans>
                                </span>
                                :
                                <span>
                                    <Trans count={Remain} i18nKey='WIZARD_FAVORITES_CRYPTO_ADDMORE'>
                                        <b></b>
                                    </Trans>
                                </span>
                        }
                    </>
                </Alert>
                <Alert
                    theme={themeAlertEnum.Danger}
                    close={() => { setNotiyMore(false) }}
                    isOpen={(NotifyMore && !LessNotify)}
                    text={' в избранное для продолжения регистрации. Данный список вы сможете менять в любой момент в сервисе.'}
                >
                    <span>
                        {
                            !isCrypto
                                ? <> {t('WIZARD_FAVORITES_MORE_5_ERROR')} </>
                                : <> {t('WIZARD_FAVORITES_CRYPTO_MORE_5_ERROR')} </>
                        }
                    </span>
                </Alert>

                <div className='align-items-center justify-content-between m-auto SelectFavouritesContainer'
                    style={{ maxWidth: '800px' }}>
                    <h3 className="font-weight-bolder SelectFavouritesContainer__header">
                        {t('WIZARD_FAVORITES_WATCHLIST')}
                    </h3>

                    <p className="text-dark-75">

                        <Trans count={LIMIT_NUMBER_OF_TICKERS} i18nKey={`${!isCrypto ? 'WIZARD_FAVORITES_WATCHLIST_ADD' : 'WIZARD_FAVORITES_CRYPTO_WATCHLIST_ADD'}`} >
                            <b>
                            </b>
                        </Trans>

                        &nbsp;&nbsp;<i className="far fa-star font-size-md text-primary"
                            aria-hidden="true"></i>
                    </p>

                    <div className="d-flex flex-lg-fill my-1 mb-8 card card-custom card-sticky">
                        <div className="symbol-group symbol-hover px-6 py-4 scroll-x" id="fav_tickers_block">
                            {cardTickersElement}
                        </div>
                    </div>
                    <Stock addToFavorites={addToCardTickers} isAuth={true} />
                </div>
            </div>
        </>
    );
}

export default React.memo(SelectFavourites);