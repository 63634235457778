import React, { useContext, useEffect } from 'react';
import {Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import DailyResult from '../Widgets/DailyResult/DailyResultWidget';

interface CommentsModal{
    show: boolean,
    handleClose: () => void
}

function MoversModal({show,handleClose}: CommentsModal)  {
    const {t} = useTranslation()

    return (
        <Modal size ='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                {/* <h5 className="modal-title" > Movers </h5> */}
            </Modal.Header>
            <Modal.Body>
                <DailyResult isFull = {true}/>
            </Modal.Body>
        </Modal>
    );
}

export default MoversModal;