import ThirtyElement, {IThirtyElement} from "./ThirtyElement";
import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import EventTime from "../Time/EventTime";
import React, {useEffect, useRef, useState} from "react";
import {useTransition} from "../../hooks/useTransition";
import CommentsModal from "../Modals/CommentsModal";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import useTranslate from "../../hooks/useTranslate";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { useTranslation } from "react-i18next";


interface IThirtyEvents {
    time:string
    data:Array<IThirtyElement>
    isCommentOpen?:boolean
    CardInformation:ICompanyInformationCard
    id?:string
    countComments?:number

}

function ThirtyEvents(props: IThirtyEvents) {
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)
    const language = useTypedSelector(state => state.UserReducer.User.settings.language)
    const [isShowTransition, showTransition] = useState<boolean>(translateEvents)
    const {showEventBody, showTranslateLink} = useTranslate('t')

    useEffect(() => {
        // showTransition(translateEvents)
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {checkForStockPage} = useCheckForPage()
    const prevHeader = useRef<string>('')

    const timeLineElements = props.data.map((dataElement,index) => {
        if(dataElement.header === prevHeader.current){
            if(index === props.data.length - 1){
                prevHeader.current = ''
            }
            return<>
                <ThirtyElement {  ...{...dataElement,isTranslateUserChoice:isShowTransition,isShowHeader:false }  }/>   
            </> 
        }
        else{
            const memoHeader = prevHeader.current
            prevHeader.current = dataElement.header 
            let isHr = (memoHeader !== '' && index !== 0) ? true : false
            if(index === props.data.length - 1){
                prevHeader.current = ''
            }
            return <>
                {/* { (memoHeader !== '' && index !== 0) && <></>} */}
                <ThirtyElement {  ...{...dataElement,isTranslateUserChoice:isShowTransition,isShowHeader:true,isDrawHr:isHr }  }/>
            </>
        }
    })

    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setCurrentEvent(
            {
                type:FilterConstant.MINUTES_EVENT_SUMMARY,
                data:{...props,isCommentOpen:false}
            }
        )
        transitionToComments(e)
    }



    const translateText = () => {
        showTransition(true)
    }
    const backToRussia = () => {
        showTransition(false)
    }

    const {t} = useTranslation()

    const showTransitionHandler = (boolvalue:boolean) => (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        showTransition(boolvalue)
    }


    return (
        <>
            <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.MINUTES_EVENT_SUMMARY} data={{...{...props,isCommentOpen:false}}}/>
           
                <span className="font-weight-bolder mb-2"><i className="fas fa-fire text-danger mr-2 mb-3"></i>{t('EVENT_30M_SUMMARY')}</span>

                {timeLineElements}

                <div className="d-flex align-items-center">
                    <span className="mt-1 font-size-sm">

                        
                        
                        {
                            language === 'ru' &&
                            <>
                                {
                                    translateEvents && <>
                                        {
                                            (!isShowTransition)
                                                ?<> <a  onClick={translateText} className="js__original-event event" href="javascript:;">
                                                    {t('EVENT_ORIGINAL')}
                                                </a>
                                                </>
                                                : <> <a onClick={backToRussia} className="js__translate-event event" href="javascript:;">
                                                    {t('EVENT_TRANSLATE')}

                                                </a>
                                                </>
                                        }
                                    </>
                                }
                            </>
                        }
                        
                        
                        

                    </span>

                    <CommentCard  countComments = {props.countComments} isCommentOpen={isCommentOpen || props.isCommentOpen} callback={setShow} show={show} id={props.id} />

                </div>


                { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }

            {/*</div>*/}
        </>
    )
}

export default ThirtyEvents