import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import DashedSeparator from "../components/Separators/DashedSeparator"
import DailyResultCard from "../components/Widgets/DailyResult/DailyResultCard"
import { IMarketMapList } from "../components/Widgets/MarketMap/MarketMapWidget"
import UserService from "../Services/UserService"

type MarketMapGroups = IMarketMapList & {
    industry: string
}

interface IMarketMap {
    propsId?: number
}

const MarketMap: React.FC<IMarketMap> = ({ propsId }) => {
    const { id } = useParams()
    const [marketMapList, setMarketMapList] = useState<Array<MarketMapGroups> | null>(null)
    const [isLoadingData, setIsLoadingData] = useState<boolean>(true)
    const fetchData = (id: number) => {
        UserService.getMarketData(id).then((data) => {
            setIsLoadingData(false)
            setMarketMapList(Object.values(data.data.groups))
        })
    }

    useEffect(() => {
        if (propsId) {
            fetchData(propsId)
            return
        }
        if (id)
            fetchData(parseInt(id))
    }, [])
    return <>
        <div className="col-12">
            {
                isLoadingData && <div className={'d-flex align-items-center justify-content-center mb-1'}>
                    <div className="spinner spinner-lg spinner-primary mrb-3"></div>
                </div>
            }

            {
                marketMapList && marketMapList.map((marketMapItem) => {
                    return <>
                        <div className="fw-bold fs-5 mt-4">{marketMapItem.industry}</div>
                        <div className="h-3px w-100" style={{ background: `${marketMapItem.color}` }}></div>
                        <div className="card card-custom h-lg-100 mt-4">
                            <div className="card-body p-4">
                                {
                                    marketMapItem.stocks.map((stockItem, index) => {
                                        //@ts-ignore
                                        stockItem.imgSrc = stockItem.logo
                                        return <>
                                            <DailyResultCard {...stockItem} />

                                            {
                                                index !== marketMapItem.stocks.length - 1 && <DashedSeparator />
                                            }

                                        </>
                                    })
                                }
                            </div>
                        </div>
                    </>
                })
            }
        </div>
    </>
}
export default MarketMap