import React from 'react';
import {useParams} from "react-router-dom";
import CompanyDescription from "../components/Layout/CompanyDescription";


function Company()  {
    const {id} = useParams()
    return (
        <div>
           <CompanyDescription/>
        </div>
    );
}

export default Company;