import {allFavouriteCardstEnum,ISetFavouritesCards,IFavouriteCards,IFavouriteCard,ISetAddedInFavourites} from './types'
import {IPeriod} from "../UserEvent/types";

export const FavouritesCardsActionCreators = {
    setFavouritesCards:(payload:Array<IFavouriteCard>):ISetFavouritesCards => {
        const favouritesCard = payload
        return {
            type:allFavouriteCardstEnum.SET_FAVOURITES_CARD,
            payload:favouritesCard,
        }
    },
    setAddedInFavourites:(payload:boolean):ISetAddedInFavourites => {
        return {
            type:allFavouriteCardstEnum.SET_IS_ADDED_IN_FAVOURITES,
            payload:payload,
        }
    },
}