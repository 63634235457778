import {actionType, EventEnum, IEvent} from './types'
import {FilterConstant} from "../../../models/FilterEvent";
import {EventTopicEnum} from "../../../models/EventContentModel";
import i18n from 'i18next';

const initialState: IEvent = {
    searchIsOpen:false,
    isOpen:false,
    IsNumberOpen:false,
    historyNumber:0,
    userAgent:'',
    isOpenMutedMenu:false,
    telegramIsConnecting:false,
    showTelegram:false,
    isApplication:false,
    isProfileAlert:false,
    debugInformation:'',
    currentTopic:EventTopicEnum.TICKER_EVENTS,
    isFirstCurrentTopic:true,
    countInvites:'',
    pullToRequest: null,
    refreshUser:false,
    speciaProfileBackCase:false,
    isTransitionToCompanyWithoutClick:false,
    tickerElementsIsLoading:false,
    lastElementsIsLoading:false,
    applicationMessageData:{
        url:'',
        ticker:'',
        companyName:''
    },
    period:{
        reduction:'',
        full:'',
    },
    currentEvent:{
        type:FilterConstant.ALL_EVENTS,
        data:{}
    },
    currentMessage:{
        source:'',
        text:'',
        title:'',
        date:'',
        ticket:''
    },
    isDarkTheme:false,
    trendsElements: [
        {
            text: '',
            classElement:'fa-fw fas fa-fire-alt text-primary mr-2',
            active:true
        },
        {
            text:'', 
            classElement:'fa-fw fas fa-long-arrow-alt-up text-success mr-2',
            active:false
        },
        {
            text:'',
            classElement:'fa-fw fas fa-long-arrow-alt-down text-danger mr-2',
            active:false
        },
    
    ]
}

export default  function EventReducer(state:IEvent = initialState, action:actionType):IEvent{
    switch (action.type){
        case EventEnum.SET_LAST_ELEMENTS_IS_LOADING:
            return {...state, lastElementsIsLoading:action.payload}
        case EventEnum.SET_TICKER_ELEMENTS_IS_LOADING:
            return {...state, tickerElementsIsLoading:action.payload}
        case EventEnum.SET_ACTIVE_TRENDS:{
            const newTrends = state.trendsElements.map((trendElement,index) => {
                return {...trendElement, active:action.payload[index].active}
            })
            return {...state,trendsElements:newTrends}
        }
        case EventEnum.SET_SEARCH:
            return {...state,searchIsOpen:action.payload}
        case EventEnum.SET_COUNT_EVENTS:
            return {...state,countInvites:action.payload}
        case EventEnum.SET_DARK_THEME:
            return {...state,isDarkTheme:action.payload}
        case EventEnum.SET_SPECIAL_PROFILE_BACK_CASE:
            return {...state,speciaProfileBackCase:action.payload}
        case EventEnum.SET_PULL_REQUEST:
            return {...state,pullToRequest:action.payload}
        case EventEnum.SET_APPLICATION_DATA:
            return {...state,applicationMessageData:action.payload}
        case EventEnum.SET_IS_FIRST_CURRENT_REDUCER:
            return {...state, isFirstCurrentTopic:action.payload}
        case EventEnum.SET_CURRENT_TOPIC:
            return {...state, currentTopic:action.payload}
        case EventEnum.SET_CURRENT_EVENT:
            return {...state, currentEvent:action.payload}
        case EventEnum.SET_CURRENT_MESSAGE:
            return {...state,currentMessage :action.payload}
        case EventEnum.SET_Event:
            return {...state, isOpen:action.payload}
        case EventEnum.SET_NUMBER:
            return {...state,IsNumberOpen:action.payload}
        case EventEnum.SET_NUMBER_HISTORY:
            return {...state,historyNumber:action.payload}
        case EventEnum.SET_USER_AGENT:
            return {...state,userAgent:action.payload}
        case EventEnum.SET_MUTED_MENU:
            return {...state,isOpenMutedMenu:action.payload}
        case EventEnum.SET_TELEGRAM_IS_CONNECT:
            return {...state,telegramIsConnecting:action.payload}
        case EventEnum.SET_SHOW_TELEGRAM:
            return {...state,showTelegram:action.payload}
        case EventEnum.SET_PROFILE_ALERT:
            return {...state, isProfileAlert: action.payload,isOpenMutedMenu:true}
        case EventEnum.SET_DEBUG_INFORMATION:
            return {...state, debugInformation:action.payload}
        case EventEnum.SET_TRENDS:
            return {...state, trendsElements:action.payload}
        case EventEnum.SET_COUNT_INVITES:
            return {...state, countInvites:action.payload}
        case EventEnum.SET_PERIOD:
            return {...state, period:action.payload}
        case EventEnum.SET_APPLICATION:
            return {...state, isApplication:action.payload}
        case EventEnum.SET_REFRES_USER:
            return {...state, refreshUser:action.payload}
        case EventEnum.SET_IS_TRANSITION_TO_COMPANY_WITHOUT_CLICK:{
            return {...state, isTransitionToCompanyWithoutClick:action.payload}
        }
        default: return state
    }

}


