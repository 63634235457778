import {Modal} from "react-bootstrap";
import React, {useState} from "react";
import MutedStockBody from "../MutedStocks/MutedStockBody";
import useGetMutedStocks from "../../hooks/useGetMutedStocks";
import { useTranslation } from "react-i18next";

interface IMutedStocks {
    show: boolean,
    handleClose: () => void
}

function MutedStocksModal({show, handleClose}: IMutedStocks){
    const {t} = useTranslation()
    return(
        <Modal show={show} onHide={handleClose}>
            {/*<div onClick={closeAllMenu}>*/}
                <Modal.Header closeButton>
                    <h5 className="modal-title">{t('PROFILE_MUTED_STOCKS')}</h5>
                </Modal.Header>

                <div className={'modal-body pt-4'}>
                       <MutedStockBody/>
                </div>
            {/*</div>*/}
        </Modal>
    )
}
export default MutedStocksModal