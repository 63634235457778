import { Scrollbars } from 'react-custom-scrollbars';
import React, {useMemo, useState} from "react";
import CompanyListItemLoader from "../loaders/CompanyListItemLoader";
import { useTranslation } from 'react-i18next';

interface ISearchBar{
    Cards:any,
    isLoading:boolean
}

function SearchBar({Cards,isLoading}:ISearchBar){
    const {t} =useTranslation()
    const loaderList = useMemo(() => {
        return [
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
            <CompanyListItemLoader/>,
        ]
    },[])

    return(
            <div className="quick-search-wrapper scroll ps ps--active-y" data-scroll="true" data-height="325" data-mobile-height="200"
                 style={{overflow: 'hidden', height: '200px'}}>
                <Scrollbars autoHide style ={{width:'100%',height:'100%'}}>
                    {
                        isLoading
                            ?<>{loaderList}</>
                            :<>
                                {
                                    Cards.length === 0
                                        ? <> {t('SEARCH_NO_RESULTS')}</>
                                        : <> {Cards} </>
                                }
                            </>
                    }
                </Scrollbars>
                <div className="ps__rail-y" style={{top: '0px', right: '0px', height: '325px'}}>
                    <div className="ps__thumb-y" tabIndex={0} style={{top: '0px', height: '92px'}}></div>
                </div>
            </div>
    )
}

export default SearchBar