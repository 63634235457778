import { useEffect, useMemo, useState } from "react"
import PressReleasesElement from "../components/Widgets/PressReleasesElement"
import WidgetElement from "../components/Widgets/WidgetElement"
import { FilterConstant } from "../models/FilterEvent"
import UserService from "../Services/UserService"
import useDate from "./useDate"
import { v4 as uuidv4 } from "uuid";
import CommentWrappper from "../components/Comments/CommentWrapper"

interface IUseImportantGenerator {
    ticker: string,
}

export enum enumImportant {
    PRESS_RELEAS = "PRESS_RELEAS",
    REPORTING = "REPORTING",
    FORECAST = "FORECAST",
}

function useImportantGenerator({ ticker }: IUseImportantGenerator) {
    const [PressReleaseElements, setPressReleaseElements] = useState<any>([])
    const [ReportingElements, setReportingElements] = useState<any>([])
    const [ForecastElements, setForecastElements] = useState<any>([])
    const [isLoading, setIsLoading] = useState<any>(true)
    const { getCurrentDate, getMomentDate } = useDate()

    const getPressReleaseElements = (data: any, stock: any, priceInfo: any, createdAt: number, id: number) => {
        const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate] = getMomentDate(createdAt)

        const CardInformationObj = {
            ticker: stock.ticker,
            name: stock.brand,
            imgSrc: stock.logo,
            price: stock.priceInfo.price
        }
        const Propsdata = {
            id: id,
            date: fullDate as string,
            time: '',
            title: data.title,
            text: data.description,
            source: data.url,
            isCommentOpen: true,
            CompanyName: stock.brand,
            dateFull: fullDate as string,
            sourceName: data.url.split('/')[2],
            translatedText: data.translatedDescription,
            translatedTitle: data.translatedTitle,
            CardInformation: { ...CardInformationObj }
        }

        return <PressReleasesElement
            {...Propsdata}
            Comment={<CommentWrappper data={Propsdata} isCommentOpen={true} type={FilterConstant.PRESS_RELEASES} countComments={0} id={String(id)} />}
        />
    }

    const getReportEelment = (data: any, stock: any, priceInfo: any, createdAt: number) => {
        const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate] = getMomentDate(createdAt)
        return <WidgetElement
            text={data.text}
            translatedText={data.translatedText}
            classElement={'far fa-money-bill-alt text-warning'}
            date={fullDate as string}
        />
    }

    const getAnaliticElement = (data: any, stock: any, priceInfo: any, createdAt: number, type: FilterConstant) => {
        const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate] = getMomentDate(createdAt)

        return <WidgetElement
            text={data.text}
            translatedText={data.translatedText}
            classElement={type == FilterConstant.TARGET_PRICE ? 'far fa-dot-circle text-danger' : 'fas fa-pen-alt text-warning'}
            date={fullDate as string}
        />
    }

    const getImportantData = async (ticker: string) => {
        const response = await UserService.getImportantEvent(ticker)
        return response.data
    }
    const pressReleasHandler = async () => {
        const data = await getImportantData(ticker)

        const PressRelease: Array<any> = data.news.map((newsElement: any, index: number) => {

            if (index === data.news.length)
                return
            return <div key={uuidv4()}>
                {getPressReleaseElements(newsElement.data, newsElement.stock, newsElement.priceInfo, newsElement.createdAt, newsElement.id)}
                {index !== data.news.length - 1 && <hr />}
            </div>
        })

        const Reporting: Array<any> = data.reports.map((newsElement: any, index: number) => {
            return <div key={uuidv4()}>
                {getReportEelment(newsElement.data, newsElement.stock, newsElement.priceInfo, newsElement.createdAt)}
                {index !== data.reports.length - 1 && <hr />}

            </div>
        })

        const Analitic: Array<any> = data.analystForecasts.map((newsElement: any, index: number) => {
            return <div key={uuidv4()}>
                {getAnaliticElement(newsElement.data, newsElement.stock, newsElement.priceInfo, newsElement.createdAt, newsElement.type)}
                {index !== data.analystForecasts.length - 1 && <hr />}
            </div>
        })

        setReportingElements(Reporting)
        setForecastElements(Analitic)
        setPressReleaseElements(PressRelease)
        setIsLoading(false)
    }

    useEffect(() => {
        if (ticker === '') return
        pressReleasHandler()
    }, [ticker])

    return { PressReleaseElements, ReportingElements, ForecastElements, isLoading }
}

export default useImportantGenerator