import {actionType, allEventEnum, ITrendEvent} from './types'
import {IPeriod} from "../UserEvent/types";

const initialState: ITrendEvent = {
    Elements:[],
    numberNewEvent:0,
    NewElements:[],
    prevEventsFilter:[],
    period:{} as IPeriod,
}

export default function TrendsReducer( state = initialState, action:actionType ) :ITrendEvent {
    
    switch (action.type){
        case allEventEnum.SET_TREND_PERIOD:
            return {...state,period:action.payload}
        case allEventEnum.SET_PREV_EVENT_FILTER:
            return {
                ...state,
                prevEventsFilter: [...action.payload]
            }
        case allEventEnum.SET_TREND_FULL_EVENTS:
            return {
                ...state,
                Elements:[...action.payload]
            }
        case allEventEnum.SET_TREND_ALL_EVENTS:
            const prevKey = state.Elements.map(Element => Element.key)
            const newElements = action.payload.filter( (newElement) => {
                return !prevKey.includes(newElement.key)                
            })

            if(newElements.length === 0 ) {
                state.numberNewEvent = 0
                return state
            }

            state.NewElements = [...newElements]
            state.numberNewEvent = newElements.length
            return state
        case allEventEnum.SET_TREND_NUMBER_NEW_EVENT:
           
            return {
                ...state, 
                numberNewEvent:action.payload,
            }
        case allEventEnum.SET_TREND_NEW_ELEMENTS:
            return { ...state,Elements:[...state.NewElements,...state.Elements],NewElements:[], numberNewEvent:0}    
        default: return state
    }

}
