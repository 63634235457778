export enum FilterConstant{
    PRICE_FLUCTUATIONS = 1,
    YEARLY_MINIMUM_MAXIMUM = 24,
    PRESS_RELEASES = 18,
    FINANCIAL_TWEETS = 10,
    FDA = 21,
    FAA = 23,
    ABSORPTION = 14,
    REPORTING = 8,
    SEC = 22,
    INSIDER_CLOSES = 7,
    ANALYST_FORECASTS = 17,
    TARGET_PRICE = 9,
    MARKET_DATA = 15,
    TWEETS_OF_POPULAR_PEOPLE = 6,
    MEDIA_PUBLICATIONS = 3,
    MINUTES_EVENT_SUMMARY = 4,
    ALL_EVENTS = 20,
    DELETE_ALL = 55,
    TIMELINE_MORE = 100,
    STOP_EVENT = 19,
    NEW_LINK = 11,
}

export interface IListStruct {
    text: string,
    id: number,
    check: boolean,
    classElement:string
}
