import MessageModal from "../Modals/MessageModal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import React, { useRef, useState } from "react";
import {  DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";
import { IReportCard } from "../Cards/ReportCard";
import { v4 as uuidv4 } from 'uuid';
import { useTransition } from "../../hooks/useTransition";
import useTranslate from "../../hooks/useTranslate";

function PressReleasesElement(props: IReportCard) {
    const { transitionToMessagePage } = useTransition()
    const [showMessage, setShowMessage] = useState(false);
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)
    const { showEventBody } = useTranslate(props.title)
    const handleCloseMessage = () => setShowMessage(false);
    const handleShowMessage = () => setShowMessage(true);
    const linkRef = useRef<HTMLAnchorElement | null>(null)

    const transitionToMessage = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        transitionToMessagePage(handleShowMessage, props)
    }
    
    return (
        <div className="align-items-center d-flex" key={uuidv4()}>
            <MessageModal show={showMessage} handleClose={handleCloseMessage} />
            <div className="d-flex flex-column">
                <span className="text-muted font-weight-bolder font-size-sm">
                    <i className="far fa-newspaper text-success"></i> <span className="ml-1">{props.date}</span>
                </span>
                <a
                    ref={linkRef}
                    className={`text-hover-primary text-dark  mb-1 font-size-md font-weight-bolder`}
                    href="https://investors.modernatx.com/news/news-details/2022/IAVI-and-Moderna-Launch-Trial-of-HIV-Vaccine-Antigens-Delivered-Through-mRNA-Technology/default.aspx"
                    onClick={transitionToMessage}
                >
                    {showEventBody(props.translatedTitle, props.title, translateEvents)}
                </a>
            </div>
        </div>
    )
}

export default React.memo(PressReleasesElement)