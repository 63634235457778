import React, { useEffect } from 'react';
import useCompanyCard, { ICompanyCard } from '../../../hooks/useCompanyCard';
import { getPercent } from '../../../Utills/ClearFunctions';

const MarketMapRow: React.FC<ICompanyCard> = (props) => {
    const {
        getPrice
    } = useCompanyCard()

    const outputPrice = () => {
        if(!props.priceInfo.oldPrice || props.priceInfo.oldPrice === props.priceInfo.price)
            return <span className="font-weight-bolder">{getPrice(props.priceInfo.price, props.currency)}</span>
        const [percent, isUp] = getPercent(props.priceInfo.price, props.priceInfo.oldPrice)
        if(!percent) <span className="font-weight-bolder">{props.price}</span>
        else {
            if(isUp)
                return  <span className="text-success font-weight-bolder">{percent.toLocaleString()}%</span>
            else return <span className="text-danger font-weight-bolder">{percent.toLocaleString()}%</span>
        }
    }

    return <div className="d-flex align-items-center mb-1">
        <div className="d-flex flex-grow-1">
            <span className="text-dark text-muted fs-6 font-weight-bolder">{props.ticker}</span>
        </div>
        <div className="" />
        {outputPrice()}
    </div>
}
export default MarketMapRow