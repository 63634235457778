import Search from "../../Search/Search";
import ProfileDropDown from "../../Buttons/ProfileDropDown";
import React, { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { DeviseIsApplicationOnly } from "../../../Utills/ClearFunctions";

interface IBasicHeader {
    handlerGiftClick: () => void,
    searchIsOpen: boolean,
    handlerSearchClick: (e: React.MouseEvent<HTMLDivElement>) => void,
    searchPosition: number,
    handlerProfileClick: () => void,
    profileIsOpen: boolean,
    isStockPage: boolean,
    handlerMenuClick: () => void,
    changeRef: () => void,
    isOpenSearchBar: boolean,
    setOpenSearchBar: (value: boolean) => void
}

const BasicHeaderElements = React.forwardRef<HTMLInputElement, IBasicHeader>(({
    handlerGiftClick,
    handlerMenuClick,
    searchIsOpen,
    handlerSearchClick,
    handlerProfileClick,
    searchPosition,
    profileIsOpen,
    isStockPage,
    children,
    changeRef,
    isOpenSearchBar,
    setOpenSearchBar
},
    searchRef) => {

    
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const User = useTypedSelector(state => state.UserReducer.User)
    const [userImg, setUserImg] = useState<string>(User.avatar)
    const GiftRef = useRef<HTMLAnchorElement | null>(null)
    const SearchRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            if (GiftRef.current) {
                GiftRef.current.classList.remove('btn-outline-light-primary')
                GiftRef.current.classList.remove('btn-hover-light-primary')
            }
            SearchRef!.current!.classList.remove('btn-outline-light-primary')
            SearchRef!.current!.classList.remove('btn-hover-light-primary')
        }
    }, [])
    const invites = <div className="topbar-item">
        <a ref={GiftRef} className="btn btn-icon btn-circle btn-hover-light-primary d-flex align-items-center btn-lg px-2"
            data-toggle="modal" data-target="#invites-modal" onClick={(e) => {
                e.stopPropagation()
                handlerGiftClick()
            }}>
            <div className="symbol">
                <i className="fas fa-gift font-size-lg"></i>

                {
                    User.hasInvites && <i className="js__invites-badge symbol-badge bg-danger "></i>
                }

            </div>
        </a>
    </div>
    return (
        <>
            <div className='topbarStockItems d-flex align-items-center' onClick={(e) => e.stopPropagation()}>

                {
                    !window.location.pathname.includes('profile/invites') && <> {invites} </>
                }

                <div className="dropdown mr-1 d-flex align-items-center" onClick={(e) => {
                    handlerSearchClick(e)
                }}
                    id="kt_quick_search_toggle">
                    <div  className="topbar-item" data-toggle="dropdown" data-offset="10px,10px"
                        aria-expanded="true">
                        <div ref={SearchRef}
                            className="btn btn-icon btn-circle btn-hover-light-primary btn-dropdown btn-lg">
                            <span className="svg-icon svg-icon-xl">
                                <i className="fas fa-search mr-2 ml-2" aria-hidden="true"></i>
                            </span>
                        </div>
                    </div>
                    <div>

                        <Search setOpenSearchBar={setOpenSearchBar} ref={searchRef} changeRef={changeRef} searchIsOpen={searchIsOpen}
                            searchPosition={searchPosition} isOpenSearchBar={isOpenSearchBar} />

                    </div>
                </div>
                {
                    !isApplication && <>
                        <div className="topbar-item profile" onClick={() => {
                            handlerProfileClick()
                        }}>
                            <div className="dropdown dropdown-inline">
                                <div data-toggle="dropdown" className="btn p-1" id="avatar-wrapper">
                                    <img alt="avatar" src={userImg}
                                        className="rounded-circle avatar"
                                        style={{ width: '36px', height: '36px' }}
                                    />
                                </div>
                                {
                                    profileIsOpen && <ProfileDropDown />
                                }
                            </div>
                        </div>

                        <span
                            style={{ cursor: 'pointer' }}
                            className={(!isStockPage) ? "svg-icon svg-icon-xl mt-1l d-flex aign-items-center" : "svg-icon svg-icon-xl mt-1 d-flex aign-items-center"}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                                viewBox="0 0 24 24"
                                className='menuToggle' onClick={(e) => {
                                    handlerMenuClick()
                                }}>

                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                    <rect x="0" y="0" width="24" height="24"></rect>
                                    <path
                                        d="M2 11.5C2 12.3284 2.67157 13 3.5 13H20.5C21.3284 13 22 12.3284 22 11.5V11.5C22 10.6716 21.3284 10 20.5 10H3.5C2.67157 10 2 10.6716 2 11.5V11.5Z"
                                        fill="black"></path>
                                    <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
                                        d="M9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17H20.5C21.3284 17 22 17.6716 22 18.5C22 19.3284 21.3284 20 20.5 20H9.5ZM15.5 6C14.6716 6 14 5.32843 14 4.5C14 3.67157 14.6716 3 15.5 3H20.5C21.3284 3 22 3.67157 22 4.5C22 5.32843 21.3284 6 20.5 6H15.5Z"
                                        fill="black"></path>
                                </g>
                            </svg>
                        </span>
                    </>
                }
            </div>
        </>
    )
})


export default React.memo(BasicHeaderElements)