import {Modal} from "react-bootstrap";
import React, {useEffect, useLayoutEffect, useRef, useState} from "react";
import '../../styles/Cropp.css'
import "cropperjs/dist/cropper.css";
import { t } from "i18next";
import UserService from "../../Services/UserService";
import { IError } from "../../models/EventContentModel";

interface CommentsModal{
    show: boolean,
    handleClose: () => void,
    setIsPromocodeApply: (boolValue:boolean) => void
}

function PromoCodeModal({show,handleClose,setIsPromocodeApply}: CommentsModal){
    const [promoCode,setPromocode] = useState<string>('')    
    const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement)
    const [Error,setError] = useState<IError>({
        isError:false,
        textError:'',
        })

    useLayoutEffect(() => {
        setError({
            isError:false,
            textError:''
        })
        if(show)
            inputRef.current.focus()
        return () => {
            if(!show) setPromocode('')
        }

    },[show])

    const sendInviteCode = async () => {
        const response = await UserService.checkPromo(promoCode)
        if(response.data.status === 'ok'){
            setIsPromocodeApply(true)
            handleClose()
        }
        else{
            const errorMessage = response.data.error.message
            console.log('errorMessage')
            setError({
                isError:true,
                textError:errorMessage
            })
        }
    }

    const chnagePromoCode = (e:React.ChangeEvent<HTMLInputElement>) => {
        setPromocode(e.target.value)
    }

    const sendForm = (e:React.FormEvent) => {
        e.preventDefault()
        sendInviteCode()
    }

    return(
        <Modal  show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <h5 className="modal-title font-weight-bolder">{t('SUBSCRIPTION_PLANS_APPLY_PROMO')}</h5>
            </Modal.Header>
            <Modal.Body>
                    <form onSubmit={sendForm}>
                        <div className="form-group">
                            <input ref = {inputRef} value = {promoCode} onChange = {chnagePromoCode}  placeholder={t('SUBSCRIPTION_PROMO_PLACEHOLDER')} className="form-control"/>
                            {
                                Error.isError && <div className="text-danger"> {Error.textError} </div>
                            } 
                        </div>
                        <button type="submit" className="btn btn-primary">
                            {t('SUBSCRIPTION_PLANS_APPLY_PROMO')}
                        </button>
                    </form>
            </Modal.Body>
        </Modal>
    )
}

export default PromoCodeModal