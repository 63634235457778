import {
    EventEnum,
    IApplicationMessageData,
    ICurrentEvent,
    ICurrentMessage,
    IPeriod,
    ISEtApplicationData,
    ISetCountInvites,
    ISetCurrentTopic,
    ISetInformation,
    ISetIsFirstCurrentReducer,
    ISetPeriod,
    ISetTrends,
    ITrendElement,
    SetApplication,
    SetCurrentEvent,
    SetCurrentMessage,
    SetHistoryNumber,
    SetIsOpen,
    SetIsOpenMutedMenu,
    SetNumber,
    SetProfileAlert,
    SetShowTelegram,
    SetTelegramIsConnect,
    SetUserAgent,
    ISetPullRequest,
    ISetRefresshUser,
    ISetSpecialProfileBackCase,
    ISetDarkTheme,
    ISetCountEvents,
    ISetSearch,
    IIsTransitionToCompanyWithoutClick,
    ISetTickerElementsIsLoading,
    ISetLastElementsIsLoading
} from './types'
import {DeviceTypeEnum} from "../../../models/Subscriptions";
import {AppDispatch} from "../../index";
import {EventTopicEnum} from "../../../models/EventContentModel";

function eraseCookie(name:string) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

export const EventActionCreators = {
    setLastElementsIsLoading:(payload:boolean):ISetLastElementsIsLoading => {
        return{
            type:EventEnum.SET_LAST_ELEMENTS_IS_LOADING,
            payload:payload
        }
    },
    setIsTransitionToCompanyWithoutClick:(payload:boolean):IIsTransitionToCompanyWithoutClick => {
        return{
            type:EventEnum.SET_IS_TRANSITION_TO_COMPANY_WITHOUT_CLICK,
            payload:payload
        }
    },
    setTickerElementsIsLoading:(payload:boolean):ISetTickerElementsIsLoading => {
        return{
            type:EventEnum.SET_TICKER_ELEMENTS_IS_LOADING,
            payload:payload
        }
    },
    setSearchIsOpen:(payload:boolean):ISetSearch => {
        return{
            type:EventEnum.SET_SEARCH,
            payload:payload
        }
    },
    setCountEvents:(payload:number | string):ISetCountEvents => {
        return{
            type:EventEnum.SET_COUNT_EVENTS,
            payload:payload
        }
    },
    setDarkTheme:(payload:boolean):ISetDarkTheme => {
        return{
            type:EventEnum.SET_DARK_THEME,
            payload:payload
        }
    },
    setSpecialProfileBackCase:(payload:boolean):ISetSpecialProfileBackCase => {
        return{
            type:EventEnum.SET_SPECIAL_PROFILE_BACK_CASE,
            payload:payload
        }
    },
    setRefresshUser:(payload:boolean):ISetRefresshUser => {
        return{
            type:EventEnum.SET_REFRES_USER,
            payload:payload
        }
    },
    setPullRequest:(pullRequestValue:PullToRefresh.PullToRefreshInstance):ISetPullRequest => {
        return{
            type:EventEnum.SET_PULL_REQUEST,
            payload:pullRequestValue
        }
    },
    setApplicationMessageData:(value:IApplicationMessageData):ISEtApplicationData => {
        return {
            type:EventEnum.SET_APPLICATION_DATA,
            payload:value
        }
    },
    setCountInvites:(count:number | string):ISetCountInvites => {
        return {
            type:EventEnum.SET_COUNT_INVITES,
            payload:count
        }
    },
    setIsFirstCurrentReducer:( isFirst:boolean ):ISetIsFirstCurrentReducer => {
        
        return {
            type:EventEnum.SET_IS_FIRST_CURRENT_REDUCER,
            payload:isFirst,
        }

    },
    setCurrentTopic:(currentTopic:EventTopicEnum):ISetCurrentTopic => {
        localStorage.setItem('currentTopic',JSON.stringify(currentTopic))
      return{
          type:EventEnum.SET_CURRENT_TOPIC,
          payload:currentTopic
      }
    },
    setPeriod:(PeriodObj:IPeriod):ISetPeriod =>{
        return{
            type:EventEnum.SET_PERIOD,
            payload:PeriodObj,
        }
    },
    SetTrends: (trends: Array<ITrendElement>): ISetTrends => {
        eraseCookie('trends')
        document.cookie = `trends=${encodeURIComponent(JSON.stringify(trends))};path=/`
        return {
            type: EventEnum.SET_TRENDS,
            payload: trends,
        }
    },
    setTrendActiveElements:(trends: Array<ITrendElement>) => {
        document.cookie = `trends=${encodeURIComponent(JSON.stringify(trends))};path=/`
        return {
            type: EventEnum.SET_ACTIVE_TRENDS,
            payload: trends,
        }
    },
    setIsOpen: (isOpen: boolean): SetIsOpen => {
        return {
            type: EventEnum.SET_Event,
            payload: isOpen,
        }
    },
    setCurrentEvent: (event: ICurrentEvent): SetCurrentEvent => {
        return {
            type: EventEnum.SET_CURRENT_EVENT,
            payload: event,
        }
    },

    setCurrentMessage: (MessageObj: ICurrentMessage): SetCurrentMessage => {
        return {
            type: EventEnum.SET_CURRENT_MESSAGE,
            payload: MessageObj,
        }
    },
    SetApplication: (flag: boolean): SetApplication => {
        // alert(true)
        return {
            type: EventEnum.SET_APPLICATION,
            payload: flag
        }
    },
    SetProfileAlert: (flag: boolean): SetProfileAlert => {
        return {
            type: EventEnum.SET_PROFILE_ALERT,
            payload: flag
        }
    },
    setNumber: (isOpen: boolean): SetNumber => {
        return {
            type: EventEnum.SET_NUMBER,
            payload: isOpen
        }
    },
    setHistoryNumber: (HistoryObj: any): SetHistoryNumber => {
        return {
            type: EventEnum.SET_NUMBER_HISTORY,
            payload: HistoryObj.idx
        }
    },
    setUserAgent: (DeviceType: string): SetUserAgent => {
        return {
            type: EventEnum.SET_USER_AGENT,
            payload: DeviceType
        }
    },
    setDeviceType: () => (dispatch: AppDispatch) => {
        const userAgent = window.navigator.userAgent
        if (userAgent.includes(DeviceTypeEnum.TRADE_SENSE)) {
            dispatch(EventActionCreators.SetApplication(true))
        }

        if (userAgent.includes(DeviceTypeEnum.APPLE) || userAgent.includes(DeviceTypeEnum.IPAD)) dispatch(EventActionCreators.setUserAgent(DeviceTypeEnum.APPLE))
        else if (userAgent.includes(DeviceTypeEnum.ANDROID)) dispatch(EventActionCreators.setUserAgent(DeviceTypeEnum.ANDROID))
        else dispatch(EventActionCreators.setUserAgent(DeviceTypeEnum.DESKTOP))

    },
    setDebugInformation:(information:string):ISetInformation => {
        return {
            type: EventEnum.SET_DEBUG_INFORMATION,
            payload: information
        }
    },
    setIsOpenSideMenu: (isOpen: boolean): SetIsOpenMutedMenu => {
        return {
            type: EventEnum.SET_MUTED_MENU,
            payload: isOpen
        }
    },
    SetTelegramIsConnect: (isOpen: boolean): SetTelegramIsConnect => {
        return {
            type: EventEnum.SET_TELEGRAM_IS_CONNECT,
            payload: isOpen
        }
    },

    SetShowTelegram: (isOpen: boolean): SetShowTelegram => {
        return {
            type: EventEnum.SET_SHOW_TELEGRAM,
            payload: isOpen
        }
    }


}