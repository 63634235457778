import { actionType, allFavouriteCardstEnum, IFavouriteCards } from './types'
import { IPeriod } from "../UserEvent/types";
import { gettOrigin } from '../../../Utills/ClearFunctions';
const initialState: IFavouriteCards = {
    FavouritesCards: [
        {
            src: `${gettOrigin()}/img/stocks/MRNA.png`,
            cardName: 'Default',
            percent: 1.5,
            ticket: '$MRNA',
            price: 133,
            star: true,
            fill: false,
        }
    ],
    isAddedInFavourites:false
}

export default function FavouritesCard(state = initialState, action: actionType): IFavouriteCards {
    switch (action.type) {
        case allFavouriteCardstEnum.SET_FAVOURITES_CARD:
            return {...state,FavouritesCards:[...action.payload]}
        case allFavouriteCardstEnum.SET_IS_ADDED_IN_FAVOURITES:    
            return {...state,isAddedInFavourites:action.payload}    
        default: return state
    }
}
