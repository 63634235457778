import '../../styles/CompanyLoader.css'


function WidgetLoaders(){
    return(
        <>
            <div className="card bg-gray-100 border-0 h-80px mb-4 animate"></div>
        </>
    )
}

export default WidgetLoaders