import {FilterConstant} from "../../../models/FilterEvent";
import {EventTopicEnum} from "../../../models/EventContentModel";

export interface ICurrentEvent{
    type:FilterConstant,
    data:any,
}

export interface ITrendElement{
    text:string
    classElement:string
    active:boolean
}

export interface ICurrentMessage{
    title:string,
    text:string,
    source:string,
    date:string,
    ticket?:string,
    isCommentOpen?:boolean,
    CompanyName?:string,
    dateFull?:string,
    sourceName?:string,
    translatedText?: string
    translatedTitle?: string
    key?:number
}
export interface IPeriod{
    reduction:string,
    full:string,
}
export interface IApplicationMessageData{
    url:string,
    ticker:string,
    companyName:string

}
export interface IEvent{
    isOpen:boolean,
    IsNumberOpen:boolean,
    historyNumber:number,
    userAgent:string,
    isOpenMutedMenu :boolean,
    telegramIsConnecting:boolean,
    showTelegram: boolean,
    isApplication:boolean,
    isProfileAlert:boolean,
    currentEvent:ICurrentEvent,
    currentMessage:ICurrentMessage,
    trendsElements: Array<ITrendElement>,
    debugInformation:string,
    period:IPeriod
    currentTopic:EventTopicEnum
    isFirstCurrentTopic:boolean,
    countInvites: number | string,
    applicationMessageData:IApplicationMessageData,
    pullToRequest: PullToRefresh.PullToRefreshInstance | null,
    refreshUser:boolean,
    speciaProfileBackCase:boolean,
    isDarkTheme:boolean,
    searchIsOpen:boolean,
    isTransitionToCompanyWithoutClick:boolean,
    tickerElementsIsLoading:boolean,
    lastElementsIsLoading:boolean,
}

export enum EventEnum{
    SET_PERIOD = "SET_PERIOD",
    SET_Event='SET_ISAUTH',
    SET_NUMBER = 'SET_NUMBER',
    SET_NUMBER_HISTORY = "SET_NUMBER_HISTORY",
    SET_USER_AGENT = "SET_USER_AGENT",
    SET_MUTED_MENU = "SET_MUTED_MENU",
    SET_TELEGRAM_IS_CONNECT = "SET_TELEGRAM_IS_CONNECt",
    SET_SHOW_TELEGRAM = 'SET_SHOW_TELEGRAM',
    SET_APPLICATION = "SET_APPLICATION",
    SET_PROFILE_ALERT ="SET_PROFILE_ALERT",
    SET_CURRENT_EVENT = "SET_CURRENT_EVENT",
    SET_CURRENT_MESSAGE = "SET_CURRENT_MESSAGE",
    SET_TRENDS =  "SET_TRENDS",
    SET_ACTIVE_TRENDS = "SET_ACTIVE_TRENDS",
    SET_DEBUG_INFORMATION = "SET_DEBUG_INFORMATION",
    SET_CURRENT_TOPIC = "SET_CURRENT_TOPIC",
    SET_IS_FIRST_CURRENT_REDUCER = 'SET_IS_FIRST_CURRENT_REDUCER',
    SET_COUNT_INVITES = 'SET_COUNT_INVITES',
    SET_APPLICATION_DATA = 'SET_APPLICATION_DATA',
    SET_PULL_REQUEST = 'SET_PULL_REQUEST',
    SET_REFRES_USER = 'SET_REFRES_USER',
    SET_SPECIAL_PROFILE_BACK_CASE = 'SET_SPECIAL_PROFILE_BACK_CASE',
    SET_DARK_THEME = 'SET_DARK_THEME',
    SET_COUNT_EVENTS = 'SET_COUNT_EVENTS',
    SET_SEARCH = 'SET_SEARCH',
    SET_IS_TRANSITION_TO_COMPANY_WITHOUT_CLICK = 'SET_IS_TRANSITION_TO_COMPANY_WITHOUT_CLICK',
    SET_TICKER_ELEMENTS_IS_LOADING = "SET_TICKER_ELEMENTS_IS_LOADING",
    SET_LAST_ELEMENTS_IS_LOADING = "SET_LAST_ELEMENTS_IS_LOADING"
}

export interface ISetLastElementsIsLoading{
    type:EventEnum.SET_LAST_ELEMENTS_IS_LOADING,
    payload:boolean,
} 

export interface ISetTickerElementsIsLoading{
    type:EventEnum.SET_TICKER_ELEMENTS_IS_LOADING,
    payload:boolean,
} 

export interface IIsTransitionToCompanyWithoutClick{
    type:EventEnum.SET_IS_TRANSITION_TO_COMPANY_WITHOUT_CLICK,
    payload:boolean,
}

export interface ISetSearch{
    type:EventEnum.SET_SEARCH,
    payload:boolean,
}

export interface ISetTrendActiveElements{
    type:EventEnum.SET_ACTIVE_TRENDS,
    payload:Array<ITrendElement>
}

export interface ISetCountEvents{
    type: EventEnum.SET_COUNT_EVENTS,
    payload: number | string
}

export interface ISetDarkTheme{
    type:EventEnum.SET_DARK_THEME,
    payload:boolean,
}

export interface  ISetSpecialProfileBackCase{
    type:EventEnum.SET_SPECIAL_PROFILE_BACK_CASE,
    payload:boolean
}

export interface ISetRefresshUser{
    type:EventEnum.SET_REFRES_USER,
    payload: boolean,
}

export interface ISetPullRequest{
    type: EventEnum.SET_PULL_REQUEST,
    payload:PullToRefresh.PullToRefreshInstance
}

export interface ISEtApplicationData{
    type:EventEnum.SET_APPLICATION_DATA,
    payload:IApplicationMessageData,
}

export interface ISetCountInvites{
        type:EventEnum.SET_COUNT_INVITES,
        payload:number | string,
    }

export interface ISetIsFirstCurrentReducer{
    type: EventEnum.SET_IS_FIRST_CURRENT_REDUCER,
    payload:boolean
}

export interface ISetCurrentTopic{
    type:EventEnum.SET_CURRENT_TOPIC
    payload:EventTopicEnum
}


export interface ISetPeriod{
    type:EventEnum.SET_PERIOD,
    payload:IPeriod
}

export interface  ISetInformation{
    type:EventEnum.SET_DEBUG_INFORMATION,
    payload:string
}

export interface ISetTrends{
    type:EventEnum.SET_TRENDS,
    payload:Array<ITrendElement>
}

export interface SetCurrentMessage{
    type:EventEnum.SET_CURRENT_MESSAGE,
    payload:ICurrentMessage
}

export interface SetCurrentEvent{
    type:EventEnum.SET_CURRENT_EVENT,
    payload:ICurrentEvent
}
export interface SetProfileAlert{
    type:EventEnum.SET_PROFILE_ALERT,
    payload:boolean
}

export interface SetApplication{
    type:EventEnum.SET_APPLICATION,
    payload:boolean
}

export interface SetTelegramIsConnect{
    type: EventEnum.SET_TELEGRAM_IS_CONNECT,
    payload: boolean,
}

export interface SetIsOpenMutedMenu{
    type: EventEnum.SET_MUTED_MENU,
    payload: boolean,
}


export interface  SetIsOpen{
    type: EventEnum.SET_Event,
    payload: boolean,
}


export interface SetNumber{
    type:EventEnum.SET_NUMBER,
    payload:boolean
}

export interface SetShowTelegram{
    type:EventEnum.SET_SHOW_TELEGRAM,
    payload:boolean
}


export interface SetHistoryNumber{
    type:EventEnum.SET_NUMBER_HISTORY,
    payload:number
}

export interface SetUserAgent{
    type:EventEnum.SET_USER_AGENT,
    payload:string,
}

export type actionType = ISetLastElementsIsLoading  | ISetTickerElementsIsLoading | IIsTransitionToCompanyWithoutClick | ISetSearch | ISetTrendActiveElements | ISetCountEvents | ISetSpecialProfileBackCase | ISetDarkTheme  | ISetInformation | ISetRefresshUser | ISetPullRequest | ISEtApplicationData | ISetCountInvites | ISetIsFirstCurrentReducer | ISetCurrentTopic | ISetPeriod | SetIsOpen | ISetTrends | SetCurrentMessage | SetCurrentEvent | SetProfileAlert | SetNumber | SetHistoryNumber | SetUserAgent | SetIsOpenMutedMenu | SetTelegramIsConnect | SetShowTelegram | SetApplication