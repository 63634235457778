import React from "react";
import useTranslate from "../../hooks/useTranslate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
interface IAnalyticElement{
    text:string
    classElement:string
    date:string,
    translatedText:string
}
function WidgetElement({classElement,text,date,translatedText}:IAnalyticElement){
    const {showEventBody} = useTranslate(text)
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)

    return(
        <div className="align-items-center d-flex">
            <div className="d-flex flex-column">
            <span className="text-muted font-weight-bolder font-size-sm">
                <i className={classElement}></i> <span className="ml-1">{date}</span>
            </span>
                <span
                    className="py-2">
                    {showEventBody(translatedText, text, translateEvents)}
                </span>
            </div>
        </div>
    )
}
export default React.memo(WidgetElement)