import { t } from "i18next";
import React, {  FC, useMemo, useState } from "react";
import { UseActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IProfileTranslateObj } from "../../page/Profile";
import UserService from "../../Services/UserService";
import { gettOrigin } from "../../Utills/ClearFunctions";
import { getDarkStyles } from "../../Utills/darkThemeString";
import ChangeLanguage from "./ChangeLanguage";
import NotificationsContainer from "./NotificationsContainer";
import NotificationSwitches from "./NotificationSwitches";
import ProfileFooter from "./ProfileFooter";
import TimeZone from "./TimeZone";

const ProfileInformation:FC = () => {
    const User = useTypedSelector(state => state.UserReducer.User)
    const isDarkTheme = useTypedSelector(state => state.EventReducer.isDarkTheme)
    
    const [notificationsMode, setNotificationsMode] = useState<number>(User?.settings?.notificationsMode)
    const [timezone, setTimezone] = useState<string>(User?.settings?.timezone)
    const region = useTypedSelector(state => state.UserReducer.User?.settings?.region)

    const [translateEvents, setTranslateEvents] = useState<boolean>(User?.settings?.translateEvents)
    const [investmentApp,setInvestmentApp] =  useState<number>(User?.settings?.investmentApp)
    const {setUserSettings,setDarkTheme} = UseActions()

    const changeTimeZone = (timezoneValue: string) => {
        const ProfileNetworkObj = {
            timezone:timezoneValue
        }
        const response = UserService.saveProfile(ProfileNetworkObj)
        setUserSettings(ProfileNetworkObj)
        setTimezone(timezoneValue)
    }

    const changeNotificationMode = (id: number) => { 
        const ProfileNetworkObj = {
            notificationsMode:id
        }
        const response = UserService.saveProfile(ProfileNetworkObj)
        setUserSettings(ProfileNetworkObj)

        setNotificationsMode(id)
    }

    const brokersJSX = useMemo(() => {
        //@ts-ignore
        const brokers = window._sharedData.config.investmentApps
        return brokers.map((broker:any) => {
            return <option key = {broker.id} value= {broker.id} >{broker.name}</option>        
        })
    },[])

    const changeInvestApp = (e:any) => {
        const ProfileNetworkObj:Partial<IProfileTranslateObj> = {
            investmentApp:e.target.value
        }
        const response = UserService.saveProfile(ProfileNetworkObj)
        setUserSettings(ProfileNetworkObj)
        setInvestmentApp(e.target.value)
    }

    const changeTranslateEvents = (translateEvent: boolean) => {
        const ProfileNetworkObj:Partial<IProfileTranslateObj> = {
            translateEvents:translateEvent,
        }
        const response = UserService.saveProfile(ProfileNetworkObj)
        setUserSettings(ProfileNetworkObj)
        setTranslateEvents(translateEvent)
    }

    const changeTheme = (e:React.MouseEvent<HTMLAnchorElement>) => {
        if(!isDarkTheme){
            e.stopPropagation()
            let styleSheet = document.createElement("style")
            styleSheet.innerText = getDarkStyles()
            styleSheet.id = 'DarkTheme'
            document.head.appendChild(styleSheet)
            setDarkTheme(true)
        }
        else{
            setDarkTheme(false)
            const sheets = document.getElementById('DarkTheme')
            sheets?.remove()
        }
    }

    const autoTranslaterJsx = () => {
        //@ts-ignore        
            return <>
                <div className="form-group row align-items-center">
                    <label className="col-lg-2 col-form-label text-lg-right">
                        {t('PROFILE_AUTO_TRANSLATION')}
                    </label>
                    <div className="col-lg-6">
                        <span className="switch switch-sm">
                            <label>
                                <input type="hidden" name="User[translate_events]" value="0"/>

                                <input type="checkbox"
                                        id="user-translate_events"
                                        name="User[translate_events]"
                                        onChange={() => changeTranslateEvents(!translateEvents)}
                                        checked={translateEvents}
                                />
                                <span></span>
                            </label>
                        </span>
                        <div className="font-size-sm">
                            <span className="text-muted">{t('PROFILE_AUTO_TRANSLATION_TEXT')}</span><a
                            href="https://cloud.google.com/translate" target="_blank"><img alt=""
                                                                                            src={`${gettOrigin()}/img/powered-by-google-translate.svg`}
                                                                                            className="ml-1"style={{height: "15px"}}/></a>
                        </div>
                    </div>
                </div>
            </>
    }
    return <>
           <div className="row">
                    <div className="card card-custom col-12 p-6">
                        <div className="form-group row">
                            <label className="col-lg-2 col-form-label text-lg-right">{t('PROFILE_PHONE')}</label>
                            <div className="col-lg-6">
                                <div className="input-group input-group-lg input-group-solid">
                                    <div className="input-group-prepend">
                                                            <span className="input-group-text"><i
                                                                className="fa fa-phone fa-1x" aria-hidden="true"></i></span>
                                    </div>
                                    <input type="text"
                                           className="form-control form-control-lg form-control-solid"
                                           value={User.phone} readOnly={true} placeholder="Phone"/>
                                </div>
                                <span className="form-text text-muted">{t('PROFILE_PHONE_PRIVACY')}</span>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-lg-2"></label>

                            <div className="col-lg-6">
                                <h5 className="font-weight-bolder mb-6">{t('PROFILE_SETTINGS')}</h5>
                            </div>
                        </div>
                        <TimeZone timezone={timezone} changeTimeZone={changeTimeZone}/>
                        <div className="form-group row align-items-center">
                            <label className="col-lg-2 col-form-label text-lg-right">
                                {t('PROFILE_NOTIFICATION_MODE')}
                            </label>

                            <NotificationSwitches
                                notificationsMode={notificationsMode}
                                changeNotificationMode={changeNotificationMode}
                            />
                        </div>
                        <NotificationsContainer
                            autoTranslateElement={
                                autoTranslaterJsx()
                            }
                            investAppElement = {
                                <select onChange={changeInvestApp} value={investmentApp} id="user-investment_app"
                                        className="form-control form-control-lg form-control-solid"
                                        name="User[investment_app]" style = {{appearance:"auto"}}>
                                   {brokersJSX}
                                </select>
                            }
                        />
                        <ChangeLanguage/>
                    </div>
                </div>
                <ProfileFooter/>
    </>
}

export default ProfileInformation