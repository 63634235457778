import React, { useEffect, useMemo, useRef, useState } from 'react';
import '../../styles/FavoritesWidget.css'
import useSelectCards from "../../hooks/useSelectCards";
import CompanyListItemLoader from "../loaders/CompanyListItemLoader";
import { useTranslation } from 'react-i18next';
import EmptyFavourites from './EmptyFavourites'

interface IFavouritesWidget {
    isPage: boolean
}

function FavouritesWidget({ isPage }: IFavouritesWidget) {
    const favoritesContainer = useRef<any>()
    const { t } = useTranslation()
    const { unSelectFavorites, FavouritesCardsJSx, selectFavorites, isShowStar, isLoading } = useSelectCards({ IsFavorites: true,isPage:isPage })

    useEffect(() => {
        if (!isPage) {
            favoritesContainer.current?.classList.add('favoritesContainer')
        } else {
            favoritesContainer.current?.classList.remove('favoritesContainer')
        }
    }, [isPage])


    const loaderList = useMemo(() => {
        return [
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
        ]
    }, [])

    return (
        <div ref={favoritesContainer} className="">
            <div>
                {
                    isPage
                        ? <div className=""
                            onClick={(e: any) => e.stopPropagation()}>

                            {
                                (isPage) ? <div></div> : <>{t('EVENTS_TAB_FAV')}</>
                            }

                            {
                                (!isShowStar)
                                    ? <div className={'text-right mb-4'}><span
                                        className="js__favourites-edit text-primary favourites-edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={selectFavorites}>
                                        <i className="fa fa-1x fa-edit text-primary"></i>
                                        {
                                            isPage && <> {t('FAVORITES_EDIT')} </>
                                        }
                                    </span>
                                    </div>

                                    : <div className={'text-right mb-4'}> <span style={{ cursor: "pointer" }} className="js__favourites-done text-primary favourites-done"
                                        onClick={unSelectFavorites}>
                                        <i className="fa fa-1x fa-check text-primary"></i>

                                        {
                                            isPage && <> {t('FAVORITES_APPLY')}</>
                                        }
                                    </span>
                                    </div>
                            }
                        </div>
                        : <h4 className="font-weight-bolder mb-6 d-flex justify-content-between"
                            onClick={(e: any) => e.stopPropagation()}>

                            {
                                (isPage) ? <div></div> : <> {t('EVENTS_TAB_FAV')}</>
                            }

                            {
                                (!isShowStar)
                                    ? <div className={'text-right mb-4'}><span
                                        className="js__favourites-edit text-primary favourites-edit"
                                        style={{ cursor: "pointer" }}
                                        onClick={selectFavorites}>
                                        <i className="fa fa-1x fa-edit text-primary"></i>
                                        {
                                            isPage && <> {t('EVENTS_TAB_FAV')}</>
                                        }
                                    </span>
                                    </div>

                                    : <div className={'text-right mb-4'}> <span
                                        style={{ cursor: "pointer" }}
                                        className="js__favourites-done text-primary favourites-done"
                                        onClick={unSelectFavorites}>
                                        <i className="fa fa-1x fa-check text-primary"></i>

                                        {
                                            isPage && <> {t('FAVORITES_APPLY')}</>
                                        }
                                    </span >
                                    </div>
                            }
                        </h4>
                }
            </div>

            <div className="card card-custom card_custom" onClick={(e: any) => e.stopPropagation()}>
                {
                    isLoading
                        ? <> {loaderList}</>
                        : <>
                            {
                                FavouritesCardsJSx.length > 0
                                    ? <>
                                        {
                                            FavouritesCardsJSx
                                        }
                                    </>
                                    : <>
                                        <div>
                                            <EmptyFavourites />
                                        </div>
                                    </>
                            }
                        </>
                }
            </div>
        </div>
    );
}

export default React.memo(FavouritesWidget);