import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { IStateMarketEnum, TickerContext } from "../../Context"
import useDate from "../../hooks/useDate"
import useFormatCurrency from "../../hooks/useFormatCurrency"
import { getNumberSign, getPercent } from "../../Utills/ClearFunctions"
import '../../styles/DescriptionPriceCss.css'
interface IDescriptionPrice {
    percent: boolean,
    isAdditionalPercent: boolean,
}

const outputPercent = (percent: number | boolean) => {
    return `(${percent > 0 ? '+' : ''}${percent.toLocaleString()}%)`
}

const DescriptionPrice: React.FC = () => {
    const {
        currentTickerState,
        additionalPriceInfo
    } = useContext(TickerContext)
    const { getPrice } = useFormatCurrency()
    const { getLongTime, getNowTime } = useDate()
    const { t } = useTranslation()

    const [percent, isUp] = getPercent(currentTickerState.price as number, Number(currentTickerState.oldPrice))
    const [additionalPercent, isAdditionalUp] = additionalPriceInfo ?
        getPercent(additionalPriceInfo.price, additionalPriceInfo.oldPrice)
        : [0, 0]
    const getDiffPrice = (price: number, oldPrice: number, currency: string | undefined) => {
        const diffPrice = price - oldPrice
        const prefix = getNumberSign(diffPrice)
        if (diffPrice === 0) return ''
        return prefix + getPrice(diffPrice, currency)
    }

    const getAditionalStateString = () => {
        if (additionalPriceInfo?.state === IStateMarketEnum.POST)
            return `${t("STOCK_AFTER_HOURS")}`
        if (additionalPriceInfo?.state === IStateMarketEnum.PRE)
            return `${t("STOCK_PRE_MARKET")}`
        else return ''
    }

    return <>
        <div className="d-flex align-items-end justify-content-between flex-wrap ">
            {
                currentTickerState.price && <div className="mb-1 mb-lg-0">
                    <div className="flex-row-fluid ">
                        <span className="font-weight-bolder display-4 descriptionPriceWrapper">
                            <span>
                                {getPrice(currentTickerState.price, currentTickerState.currency)}
                            </span>
                            {
                                currentTickerState.oldPrice && <span className={`font-weight-bolder font-size-h5 ${isUp ? "text-success" : 'text-danger'} `}>
                                    &nbsp;
                                    {
                                        getDiffPrice(
                                            Number(currentTickerState.price),
                                            Number(currentTickerState.oldPrice),
                                            currentTickerState.currency)
                                    }
                                    &nbsp;
                                    {percent ? outputPercent(percent) : ''}
                                </span>
                            }

                        </span>
                    </div>
                    <div className="flex-row-fluid">
                        <span className="font-size-xs">
                            {
                                currentTickerState.finishedAt &&
                                    <>
                                        <i className="far fa-clock font-size-sm" aria-hidden="true"></i>
                                        &nbsp;
                                        {
                                            t("STOCK_AT_CLOSE")
                                        }
                                        <span>   {getLongTime(currentTickerState.finishedAt)} </span>
                                    </>
                            }
                        </span>
                    </div>
                </div>
            }
            {
                additionalPriceInfo && <div className="ml-lg-6 ml-sm-6 ml-md-1">
                    <div className="flex-row-fluid">
                        <span className="font-weight-bolder font-size-h3">
                            {getPrice(additionalPriceInfo.price, additionalPriceInfo.currency)}
                            {
                                additionalPriceInfo.oldPrice && <span className={`font-weight-bolder font-size-h5 ${isAdditionalUp ? "text-success" : 'text-danger'} `}>
                                    &nbsp;
                                    {
                                        getDiffPrice(
                                            additionalPriceInfo.price,
                                            additionalPriceInfo.oldPrice,
                                            additionalPriceInfo.currency
                                        )
                                    }
                                    &nbsp;
                                    {
                                        additionalPercent ? outputPercent(additionalPercent) : ''
                                    }
                                </span>
                            }

                        </span>
                    </div>
                    <div className="flex-row-fluid ">
                        <span className="font-size-xs">
                            <i className="far fa-clock font-size-sm" aria-hidden="true"></i>
                            &nbsp;{getAditionalStateString()}&nbsp;
                            {
                                additionalPriceInfo.finishedAt ? <>
                                    {getLongTime(additionalPriceInfo.finishedAt)}
                                </>
                                    : <>
                                        {getNowTime()}
                                    </>
                            }
                        </span>
                    </div>
                </div>
            }

        </div>
    </>
}
export default DescriptionPrice