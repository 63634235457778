// open: 111,
// // Close price, required field
// close: 111,
// // Highest price, required field
// high: 111,
// // Lowest price, required field
// low: 111,
// // volume, optional field
// volume: 12,
// // Turnover, a non-required field, if you need to display the technical indicators 'EMV' and 'AVP', you need to fill this field with data.
// turnover: 1234,
// // Timestamp, millisecond, required fields
// timestamp: 1610188586000

export interface IChartResponse{
    data:Array<IDataChart>,
    status:string,
    eventPoints:Array<any>
    
}

export interface IDataChart{
    open:number,
    close:number,
    high:number,
    low:number,
    timestamp:number,
    volue?:number,
    turnover?:number,
}

export enum AreaPeriodType{
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    THREE_MONTH = '3months',
    SIX_MONTH = '6months',
    YEAR = 'year'
}

export enum ChartGroupTypes{
    MINUTE =  '1m',
    FIVE_MINITES = '5m',
    FIFTEEN_MINUTES = '15m',
    THIRTY_MINUTES = '30m',
    HOUAR = '1h',
}

export enum typeGraphEnum{
    AREA = 'area',
    CANDLE = 'candle_solid' //'candle_solid'|'candle_stroke'|'candle_up_stroke'|'candle_down_stroke'|'ohlc'|'area'
}

export enum circleEnum{
    CIRCLE = 'circle',
    BORDER_CIRCLE = 'borderCircle',
    WRAPPED_CIRCLE = 'wrappedCircle'
} 

export interface IPriceCandleinfornation {
    price:number
    percent:number
    open:number
    close:number
    high:number
    low:number
    date:string
    color:string
}

export interface IPriceAreainfornation{
    price:number,
    date:string,
}


