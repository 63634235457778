import {IKLineDataElements, IspecialTextBounding, specialTextBoundingType} from "../../components/Kline";
import React, {MutableRefObject} from "react";
import {drawTooltip} from "../figures";
import {DeviceIsDesctop} from "../../Utills/ClearFunctions";

export function safariHandleTooltip(e: React.MouseEvent<HTMLDivElement>, elements: IKLineDataElements[], lastTimerId: any, SubsctribleHandler: (flag: boolean) => void,
                                    setShowEventsModal: (eventsArray: Array<boolean>) => void, isOpenEventModal: any, prevClickedEvents: any, specialTextBounding?: MutableRefObject<specialTextBoundingType>): void;
export function safariHandleTooltip(e: React.TouchEvent<HTMLDivElement>, elements: IKLineDataElements[], lastTimerId: any, SubsctribleHandler: (flag: boolean) => void,
                                    setShowEventsModal: (eventsArray: Array<boolean>) => void, isOpenEventModal: any, prevClickedEvents: any, specialTextBounding?: MutableRefObject<specialTextBoundingType>): void;
export function safariHandleTooltip(e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>, elements: IKLineDataElements[], lastTimerId: any, SubsctribleHandler: (flag: boolean) => void,
                                    setShowEventsModal: (eventsArray: Array<boolean>) => void, isOpenEventModal: any, prevClickedEvents: any, specialTextBounding?: MutableRefObject<specialTextBoundingType>) {
    let isFindElement: boolean = false
    
    const showModalEventsDesctopHandler = (element: any) => {
        if (DeviceIsDesctop()) {
            if (!localStorage.getItem('isUserSeeMessage')) {
                localStorage.setItem('isUserSeeMessage', 'true')
            }
        }
        setShowEventsModal(element.events)
    }
    const showModalEventsHandler = (element: any) => {
        if (!element.events[0]) return true
        const currentId = element.events[0].id
        if (prevClickedEvents.current === currentId) {
            if (isOpenEventModal.current) {
                //@ts-ignore
                setShowEventsModal(element.events)
                isOpenEventModal.current = false
                return false
            }
        } else if (prevClickedEvents.cuurent !== currentId && prevClickedEvents.cuurent !== null) {
            prevClickedEvents.current = currentId
            if(isOpenEventModal){
                isOpenEventModal.current = false
            }
        } else
            prevClickedEvents.current = currentId
        if (!isOpenEventModal.current) {
            if(isOpenEventModal){
                
                isOpenEventModal.current = true
            }
        }
        return true
    }

    const canvas = document.querySelectorAll('canvas')[1] //2 - chrome 1 - safary
    const changeCanvas = document.querySelectorAll('canvas')[2] //2 - chrome 1 - safary
    changeCanvas.style.zIndex = '2'

    if (!canvas) return
    const canvasY = canvas.getBoundingClientRect().y + window.scrollY
    const canvasX = canvas.getBoundingClientRect().x

    let diffY = 0
    let diffX = 0

    if (e.type === 'touchstart') {
        const TouchEvent = e as React.TouchEvent<HTMLDivElement>
        diffY = TouchEvent.targetTouches[0].pageY - canvasY
        diffX = TouchEvent.targetTouches[0].pageX - canvasX
    }

    if (e.type === "mousemove") {
        //@ts-ignore
        diffY = e.pageY - canvasY
        //@ts-ignore
        diffX = e.pageX - canvasX
    } else if (e.type === 'click') {
        const MouseEvent = e as React.MouseEvent<HTMLDivElement>
        diffY = MouseEvent.pageY - canvasY
        diffX = MouseEvent.pageX - canvasX
    }
    const context = canvas.getContext('2d')
    if (!context) return;

    const elementDraw = {
        colour: '#05EFFF',
        width: 150,
        height: 100,
        top: diffY,
        left: diffX
    }

    if (e.type === 'mousemove') {
        SubsctribleHandler(false)
        elements.forEach((element) => {
            if (diffY > element.top && diffY < element.top + element.height
                && diffX > element.left && diffX < element.left + element.width) {
                SubsctribleHandler(true)
                const canvas = document.querySelector('#simple_chart > div') as HTMLElement
                canvas.style.setProperty('cursor', 'pointer', 'important');
                canvas!.style!.cursor = 'pointer'
            }
        })
        SubsctribleHandler(false)
        const timerId = setTimeout(() => {
            if (lastTimerId.current !== timerId) return
            if (lastTimerId.current !== timerId) return
            elements.forEach((element) => {
                if (diffY > element.top && diffY < element.top + element.height
                    && diffX > element.left && diffX < element.left + element.width) {
                    SubsctribleHandler(true)
                    // isFindElement = true
                    // document.body.style.cursor = 'pointer'
                    const canvas = document.querySelector('#simple_chart > div') as HTMLElement
                    canvas.style.setProperty('cursor', 'pointer', 'important');
                    canvas!.style!.cursor = 'pointer'
                    drawTooltip(context, element, true)
                    changeCanvas.style.zIndex = '-999'
                    lastTimerId.current = ''
                }
            })
        }, 200)
        lastTimerId.current = timerId
    }

    if (e.type === 'click' || e.type === 'touchstart') {
        SubsctribleHandler(false)
        if (
            window.navigator.userAgent.includes('Android')
            || window.navigator.userAgent.includes('iPhone')
            || window.navigator.userAgent.includes('iPad')) {
            const timerId = setTimeout(() => {
                if (specialTextBounding?.current) {
                    if (specialTextBounding.current.xStart < diffX && specialTextBounding.current.xEnd > diffX) {
                        if (specialTextBounding.current.yStart < diffY && specialTextBounding.current.yEnd > diffY) {
                            setShowEventsModal(specialTextBounding.current.element.events)
                            return
                        }
                    }
                }
                elements.forEach((element) => {
                    if (diffY > element.top && diffY < element.top + element.height
                        && diffX > element.left && diffX < element.left + element.width) {
                        SubsctribleHandler(true)
                        const isDraw = showModalEventsHandler(element)
                        isFindElement = true
                        if (!isDraw) return
                        drawTooltip(context, element, true, specialTextBounding)
                        changeCanvas.style.zIndex = '-999'
                    }
                })
                if (!isFindElement) {
                    if(isOpenEventModal){
                        isOpenEventModal.current = false
                        specialTextBounding!.current = null
                    }
                }
            }, 300)
        } else {
            SubsctribleHandler(false)
            const timerId = setTimeout(() => {
                if (lastTimerId.current !== timerId) return
                if (lastTimerId.current !== timerId) return
                elements.forEach((element) => {
                    if (diffY > element.top && diffY < element.top + element.height
                        && diffX > element.left && diffX < element.left + element.width) {
                        SubsctribleHandler(true)
                        showModalEventsDesctopHandler(element)
                        changeCanvas.style.zIndex = '-999'
                        lastTimerId.current = ''
                    }
                })
            }, 300)
            lastTimerId.current = timerId
        }
    } else {
        elements.forEach((element) => {
            if (diffY > element.top && diffY < element.top + element.height
                && diffX > element.left && diffX < element.left + element.width) {
                drawTooltip(context, element, true,)
            }
        })
    }
}
