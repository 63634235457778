import React from "react"
import DesctopSubscription from "./DesctopSubscription"
import { IPriceTab } from "./MobileSubscription"

interface ISpecialRuMobileWaitingPayment{
    getCurrentTab:() => IPriceTab | undefined
}
function SpecialRuMobileWaitingPayment({getCurrentTab}:ISpecialRuMobileWaitingPayment) {
    return <>
        <div style={{ height: '40px' }} className="d-flex justify-content-center align-items-center">
            <div style = {{width:'2rem'}} className="spinner spinner-lg spinner-primary"></div>
        </div>
        <div className="text-center row justify-content-md-center mb-9">
            <h4 className="font-weight-bolder mb-6">Выполняется покупка </h4>

            <span >
                Сейчас вы будете перенаправлены на страницу оплаты. После оплаты и возврата обратно нажмите
            </span>

            <div className="col-lg-6 col-12 text-center mt-2">
                <button onClick={() => window.location.href = '#closerefresh'} className="btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 mr-2">
                    <span className="js__subscription-selected">
                        &#8592; Вернуться в профиль
                    </span>
                </button>

            </div>
        </div>
        <DesctopSubscription sku={getCurrentTab()?.sku} />
    </>
}
export default SpecialRuMobileWaitingPayment