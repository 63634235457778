import {FilterConstant} from "../models/FilterEvent";
import {getPercent, nFormatter} from "../Utills/ClearFunctions";
import {v4 as uuidv4} from "uuid";
import TimeLineDay from "../components/Time/TimeLineTimeDay";
import TimeLineFluctuation from "../components/TimeLine/TimeLineFluctuation";
import TimeLineRelease from "../components/TimeLine/TimeLineRelease";
import TimeLineElement from "../components/TimeLine/TimeLineElement";
import TimeLineEarly from "../components/TimeLine/TimeLineEarly";
import StopEventTimeLine from "../components/TimeLine/StopEventTimeLine";
import CommentWrappper from "../components/Comments/CommentWrapper";
import TimeLineNewLink from "../components/TimeLine/TimeLineNewLink";
import i18next from "i18next";

function LastEventSwitch(
        type: number | undefined, 
        networkData: any,
        priceInfo: any,
        stock: any,
        time:string,
        currentDay:number,
        prevDay:number,
        nameOfMounth:string,
        optionalDataValue: string | null,
        dateFullPressRelize?:string,
        id?:number,
        countComments?:number,
        fullDateTootip?:string,
        explanation?: null | {text:string}
    ) {
    const CardInformationObj = {
        ticker: stock.ticker,
        name: stock.brand,
        imgSrc: stock.logo,
        ...stock,
        ...priceInfo
    }
    let data:any

    const insertTimeLineElementDate = (data: {header:string,classText:string}) => {
       
        const prosData = {
            ...data,
            explanation:explanation,
            text:networkData.text,
            key:uuidv4(),
            time:time,
            sourceLink:networkData.url,
            sourceName:networkData.username,
            isCommentOpen:true,
            translatedText: networkData.translatedText,
            CardInformation:{...CardInformationObj},
            id:String(id),
            countComments:countComments,
            fullDateTootip:fullDateTootip,

        }
        return insertDate(TimeLineElement, prosData)
    }


    const insertDate = ( Component:any, data:any ) => {
        data.explanation = explanation 
        if(prevDay !== currentDay)
            return(
                <>
                    <TimeLineDay date={`${currentDay +' ' + nameOfMounth}`}/>
                    {
                        <Component 
                            Comment = {
                                    <CommentWrappper 
                                        countComments={countComments}
                                        data = {data}
                                        isCommentOpen = {true}
                                        type = {type}
                                        id = {String(id)}
                                    />
                                } 
                            {...data}
                        />
                    }
                </>
            )
        else
            return <> 
                <Component 
                    Comment = {
                            <CommentWrappper 
                                countComments={countComments}
                                data = {data}
                                isCommentOpen = {true}
                                type = {type}
                                id = {String(id)}
                            />
                        } 
                    {...data}
                />
            </>
    }

    switch (type) {
        case FilterConstant.NEW_LINK:
            // alert(type)
            data = {
                translatedText:networkData.description,
                translatedTitle:networkData.title,
                title: networkData.translatedTitle,
                text: networkData.translatedDescription,
                isCommentOpen: true,
                source: networkData.url,
                CardInformation: {...CardInformationObj},
                id:id,
                countComments:countComments,
                time,
                fullDateTootip,
            }
            return insertDate(TimeLineNewLink,data)

        case FilterConstant.STOP_EVENT:
            data = {
                from:networkData.from,
                to:networkData.to,
                isCommentOpen: true,
                CardInformation: {...CardInformationObj},
                id:id,
                countComments:countComments,
                time,
                fullDateTootip,
                fromTimestamp:networkData.fromTimestamp,
                toTimestamp:networkData.toTimestamp,
            }
            return insertDate(StopEventTimeLine,data)
        case FilterConstant.PRICE_FLUCTUATIONS:
            const [ percent,isUp ] = getPercent(networkData.price, networkData.oldPrice)
             data = {
                ticker: stock.ticker,
                companyName: stock.brand,
                percent: (percent as number).toString(),
                fromPrice: networkData.oldPrice,
                time: time,
                toPrice: networkData.price,
                isUp: isUp as boolean,
                isCommentOpen: true,
                CardInformation: {...CardInformationObj},
                 id:id,
                 countComments:countComments,
                 fullDateTootip:fullDateTootip
            }
            return insertDate(TimeLineFluctuation, data)
        case FilterConstant.YEARLY_MINIMUM_MAXIMUM:
            let indicator:boolean = false
            if (networkData.indicator === 'high')
                indicator = true
            else indicator = false

            data = {
                price:networkData.price,
                time:time,
                isUp:indicator,
                isCommentOpen: true,
                CardInformation: {...CardInformationObj},
                id:id,
                countComments:countComments,
                fullDateTootip
            }

            return insertDate(TimeLineEarly, data)
        case FilterConstant.PRESS_RELEASES:

            data = {
                date:'08:01:06',
                time:time,
                title: networkData.title,
                text: networkData.description,
                source: networkData.url,
                isCommentOpen:true,
                CompanyName:stock.brand,
                dateFull:dateFullPressRelize,
                sourceName:networkData.url.split('/')[2],
                translatedText:networkData.translatedDescription,
                translatedTitle:networkData.translatedTitle,
                CardInformation:{...CardInformationObj},
                id:id,
                countComments:countComments,
                fullDateTootip:fullDateTootip
            }
            return insertDate(TimeLineRelease, data)
        case FilterConstant.TWEETS_OF_POPULAR_PEOPLE:
            data = {
                text:networkData.text,
                header:networkData.displayName,
                key:uuidv4(),
                time:time,
                classText:'fab fa-twitter text-primary',
                sourceLink:networkData.url,
                sourceName:networkData.username,
                isCommentOpen:true,
                translatedText:networkData.translatedText,
                CardInformation:{...CardInformationObj},
                isTwitterOfPopularEvent: true,
                id:id,
                countComments:countComments,
                fullDateTootip:fullDateTootip,
                subscribers: nFormatter(networkData.followersCount),
                date:fullDateTootip,
                name:networkData.displayName,
            }
            
            return insertDate(TimeLineElement, data)
        case FilterConstant.MEDIA_PUBLICATIONS:
            data = {
                text:networkData.title,
                header:networkData.name,
                key:uuidv4(),
                time:time,
                classText:'far fa-newspaper text-success',
                sourceLink:networkData.url,
                sourceName:networkData.name,
                isCommentOpen:true,
                translatedText: networkData.translatedTitle,
                CardInformation:{...CardInformationObj},
                id:id,
                countComments:countComments,
                fullDateTootip,
                fullDate:`(${fullDateTootip})`,
                title: networkData.translatedTitle,
                source:networkData.name,
            }
            return insertDate(TimeLineElement, data)
        case FilterConstant.FINANCIAL_TWEETS:
            data = {
                header:i18next.t('EVENT_FINTWEET'),
                classText:"fas fa-volume-up text-warning"
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.FDA:
            data = {
                header: i18next.t('EVENT_FDA'),
                classText:"fas fa-pills text-warning mr-2"
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.FAA:
            
            data = {
                header:i18next.t('EVENT_FAA'),
                classText:"fas fa-pills text-warning mr-2"
            }
            return insertTimeLineElementDate(data)

        case FilterConstant.ABSORPTION:

            data = {
                header:i18next.t("EVENT_ACQUISITION"),
                classText:"fas fa-bolt text-warning mr-2"
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.REPORTING:
            data = {
                header:i18next.t("EVENT_REPORT"),
                classText:"far fa-money-bill-alt text-warning mr-2"
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.SEC:
            data = {
                header:i18next.t("SEC"),
                classText:"fab fa-wpforms text-warning mr-2"
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.INSIDER_CLOSES:
            data = {
                header:i18next.t("EVENT_INSIDER"),
                classText:'fas fa-handshake text-warning mr-2',
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.ANALYST_FORECASTS:
            data = {
                header:i18next.t("EVENT_FORECAST"),
                classText:'fas fa-pen-alt text-warning',
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.TARGET_PRICE:
            data = {
                header:i18next.t("EVENT_PRICE_TARGET"),
                classText:'far fa-dot-circle text-danger mr-2',
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.MARKET_DATA:
            data = {
                header:i18next.t("EVENT_MARKET_DATA"),
                classText:'fas fa-search text-warning',
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.ALL_EVENTS:
        case FilterConstant.DELETE_ALL:

        default:
            return []

    }
}

export default LastEventSwitch