import React, { ChangeEvent, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import '../styles/Profile.css'
import HeaderProfile from "../components/Profile/HeaderProfile";
import SubscriptionValid from "../components/Profile/SubscriptionValid";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { Alert, themeAlertEnum } from "../components/Alerts";
import { UseActions } from "../hooks/useActions";
import { ORIGIN } from '../models/Domen';
import ProfileInformation from '../components/Profile/ProfileInformation';
import { t } from 'i18next';

export type fileType = null | string | ArrayBuffer

export interface IProfileTranslateObj {
    avatar: fileType,
    username: string,
    fullName: string,
    about: string,
    timezone: string,
    notificationsMode: number,
    translateEvents: boolean,
    investmentApp: number,
    language: string,
}

function Profile() {
    const isProfileAlert = useTypedSelector(state => state.EventReducer.isProfileAlert)
    const User = useTypedSelector(state => state.UserReducer.User)
    const avatarSaveError = useTypedSelector(state => state.UserReducer.avatarSaveError)
    const { SetProfileAlert, setAvatarError } = UseActions()
    const isAuth = useTypedSelector(state => state.UserReducer.isAuth)

    useLayoutEffect(() => {
        if (!isAuth)
            window.location.replace(ORIGIN + '/wizard')
    }, [isAuth])

    const handleCloseProfileAlert = () => {
        SetProfileAlert(false)
    }

    const [UserName, setUserName] = useState<string>(User?.profile?.username)
    const [Name, setName] = useState<string>(User?.profile?.fullName)
    const [About, setAbout] = useState<string>(User?.profile?.about)
    const [notificationsMode, setNotificationsMode] = useState<number>(User?.settings?.notificationsMode)
    const [timezone, setTimezone] = useState<string>(User?.settings?.timezone)

    const [translateEvents, setTranslateEvents] = useState<boolean>(User?.settings?.translateEvents)
    const [investmentApp, setInvestmentApp] = useState<number>(User?.settings?.investmentApp)
    const [imgUrl, setImgUrl] = useState<fileType>(User?.avatar)
    const { saveProfileRequest, setUserProflie, setUserSettings } = UseActions()
    const [isSaveAlert, setIsSaveAlert] = useState<boolean>(false)


    const saveAvatar = async () => {

        const ProfileNetworkObj: IProfileTranslateObj = {
            avatar: imgUrl,
            username: UserName,
            fullName: Name,
            about: About,
            timezone: timezone,
            notificationsMode: notificationsMode,
            translateEvents: translateEvents,
            investmentApp: investmentApp,
            language: User.settings.language
        }
        saveProfileRequest(ProfileNetworkObj)
    }

    const isFirstRender = useRef<boolean>(true)
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false
            return
        }
        saveAvatar()
    }, [imgUrl])



    const removeAlertHandler = () => {
        setIsSaveAlert(false)
    }


    const removeProfileError = useCallback(() => {
        setAvatarError({ isError: false, TextError: '' })
    }, [])

    return (

        <div className="d-flex flex-column-fluid">

            <Alert theme={themeAlertEnum.Danger} close={removeProfileError} isOpen={avatarSaveError.isError} text={avatarSaveError.TextError}>
                <span>{avatarSaveError.TextError}</span>
            </Alert>

            <Alert theme={themeAlertEnum.Success} close={removeAlertHandler} isOpen={isSaveAlert} text={'Профиль сохранен'}>
                <span>{t('PROFILE_SAVED')}</span>
            </Alert>

            <Alert theme={themeAlertEnum.Success} close={handleCloseProfileAlert} isOpen={isProfileAlert} text={`${t('SUBSCRIPTION_AUTOPAY_CANCEL_SUCCESS')}`}>
                <span>{t('PROFILE_SAVED')}</span>
            </Alert>

            <div className="container ContainerWrapper">

                <div className="row">
                    <HeaderProfile imgUrl={imgUrl} setImgUrl={setImgUrl} />
                </div>

                <div className="row">
                    <SubscriptionValid />
                </div>
                <ProfileInformation />
            </div>
        </div>
    );
}

export default Profile;