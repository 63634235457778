import React, { useEffect } from "react"
export interface IImage{
    src:string
}
function DescriptionBarLargeImage({src}:IImage){
    return <div
    className="symbol symbol-50 symbol-circle "
        style = {{
            backgroundImage:`url(${src})`,
            backgroundSize: 'cover',
            width: '50px',
            maxWidth: '50px',
            height: '50px',
        }}>
    </div>
    
}
export default React.memo(DescriptionBarLargeImage)