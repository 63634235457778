import Phone from "../Inputs/Phone";
import React, {useEffect, useState} from "react";
import {UseActions} from "../../hooks/useActions";
import InviteElement from "./InviteElement";
import useInvites from "../../hooks/useInvites";
import {deleteScript, deviceIsIphoneApplication, loadScript} from "../../Utills/ClearFunctions";
import {SITE_KEY} from "../../models/reCaptcha";
import UserService from "../../Services/UserService";
import Captha from "../../page/Login/Captha";
import { CustomErrorInput } from "../Errors";
import { useTranslation } from "react-i18next";
import { REVIEW } from "../../models/Review";

function GiftModalBody(){
    const {setNumber} = UseActions()
    const [phoneNumber,setPhoneNumber] = useState<string>('')
    const [isValidPhoneInput,setIsValidPhoneInput] = useState<boolean>(false)
    const {invites, setInvites} = useInvites()
    const [loading,setLoading] = useState<boolean>(false)
    const [errorMessage,setErrorMessage] = useState<string>('')
    const [isEroor,setisError] = useState<boolean>(false)

    useEffect(() => {
        loadScript(`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`,'Captha')
        return () => {
            deleteScript('Captha')
        }
    },[])

    const changePhoneValue = (value: string) => {
        setPhoneNumber(value)
    }

    const changeValidPhoneInput = (flag: boolean) => {
        setIsValidPhoneInput(flag)
    }

    const elements = invites.map((invite,index) => {
        return <div key = {invite.id}>
            <InviteElement  { ...{...invite,numberEvent:index+1,setInvites} } />
            { index !== invites.length && <hr style = {{margin:'6px 0 6px 0'}}/> }
        </div>
    })

    const sendCode = async (token?: any) => {
        if (isValidPhoneInput) {
            let response = null
            if(!token) {
                response = await UserService.sendInviteWithoutToken(phoneNumber as string)
            }
            else {
                response = await UserService.sendInvites(phoneNumber, token as string)
            }
            if (response.data.status === 'failed') {
                setisError(true)
                setErrorMessage(response.data.error.message)
                setLoading(false)
                return
            }
            setInvites(response.data.invites)
            setLoading(false)
        }
        else{
            setLoading(false)
        }
    }

    const sendInvite = async () => {
        setisError(false)
        setLoading(true)
        try {
                //@ts-ignore
                grecaptcha.ready(function () {
                    //@ts-ignore
                    grecaptcha.execute(SITE_KEY, {action: 'invite'}).then(sendCode);
                });
        }catch(e:any) {
            setLoading(false)
        }
    }

    const submitForm = (e: any) => {
        e.preventDefault()
        sendInvite()
    }
    const {t} =useTranslation()

    return(
        <>
            <div onClick={() => setNumber(false)}>
                {
                    window.navigator.userAgent.includes('iPhone') && window.navigator.userAgent.includes(REVIEW) && window.navigator.userAgent.includes('Tradesense')
                    ?<></>
                    :<>
                        <p className="font-size-lg"> 🙋‍ {t('INVITES_PROMO_TEXT')}</p>

                        <div className="font-size-lg alert alert-custom alert-outline-warning">🎁 {t('INVITES_PROMO_OFFER')}
                        </div>
                        <label className="font-size-h6 font-weight-bolder text-dark pt-5 mb-4 mt-n5" htmlFor="phone">
                            {t('INVITES_SEND_SMS_HEADER')}
                        </label>
                    </>
                }
                


                <div>
                    <form onSubmit={submitForm}>
                        <Phone phoneNumber={phoneNumber}
                               isValidPhoneInput={isValidPhoneInput}
                               changeValidPhoneInput={changeValidPhoneInput}
                               changePhoneValue={changePhoneValue}
                               inputClassName='form-control form-control-solid h-auto py-4'
                               isFocus = {false}
                        />
                        
                        {
                            isEroor && <CustomErrorInput text={errorMessage}/>
                        }
                        
                    </form>
                </div>
                {/* spinner spinner-white spinner-right */}
                <div className="form-group mt-3">
                    <button onClick={sendInvite} type="submit" id="send-invite-button"
                            className={
                                (loading)?"btn btn-primary btn-lg btn-block font-weight-bolder p-4 spinner spinner-white spinner-right "
                                :"btn btn-primary btn-lg btn-block font-weight-bolder p-4"
                            }><i
                        className="fab fa-telegram-plane"></i> {t('INVITES_SEND_INVITE')}
                    </button>
                </div>


                <div className="">
                    <p className="font-size-lg">{t('INVITES_SEND_LINKS')}</p>
                    {elements}
                </div>

                {
                    !deviceIsIphoneApplication() && <Captha/>
                }

            </div>
        </>
    )
}

export default GiftModalBody