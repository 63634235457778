import {actionType,BackDropStateEnum,IBackDropState} from './types'
const initialState: IBackDropState = {
    BackDropisOpen:false,
    children:<> <h1> Hi!!! </h1> </>,
    header:'Header'
}

export default function BackDropReducer(state:IBackDropState = initialState, action:actionType):IBackDropState{
    switch (action.type){
        case BackDropStateEnum.SET_OPEN:
            return  {...state,BackDropisOpen:action.payload}
        case BackDropStateEnum.SET_CHILDREN:
            return  {...state,children:action.payload}
        case BackDropStateEnum.SET_HEADER:
            return  {...state,header:action.payload}
        default: return state
    }
}