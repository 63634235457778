import {Nav} from "react-bootstrap";
import '../../styles/MobileNavBar.css'
import React, {useContext, useRef, useState} from "react";
import '../../styles/eventCompamyVidget.css'
import ReportTab from "../Widgets/ReportTab";
import PressReleasesTab from "../Widgets/PressReleasesTab";
import AnalitickTab from "../Widgets/AnalyticsTab";
import {v4} from "uuid";
import { useEffect } from "react";
import {CompanyContext} from "../../Context";
import { useTranslation } from "react-i18next";
import { DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";

function MobileNavBar() {
    const {ForecastElements,ReportingElements,PressReleaseElements} = useContext(CompanyContext)
    const MobileTabs = useRef<HTMLDivElement>({} as HTMLDivElement)

    useEffect(() => {
        content[0] = {...content[0], element:<PressReleasesTab/>  }
        content[1] = {...content[1], element:<ReportTab/>  }
        content[2] = {...content[2], element:<AnalitickTab />  }
        setContent(content)

    },[ForecastElements,ReportingElements,PressReleaseElements])

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            MobileTabs.current.classList.add('tabDisableHover')
        }
    },[])

    const [content,setContent] = useState([
        {
            eventKey:'pressReleases',
            active:true,
            element:<PressReleasesTab/>,
            key:v4(),
        },
        {
            eventKey:'reportWidgets',
            active:false,
            element:<ReportTab/>,
            key:v4()
        },
        {
            eventKey:'analyticsWidget',
            active:false,
            element:<AnalitickTab/>,
            key:v4()
        }
    ])
    const [activeKey,setActiveKey] = useState<string | null>('pressReleases')
    const elements = content.map((widget) =>
    <div key = {widget.key} className={`widgets__reportWidgets ${!widget.active ? 'widgets__reportWidgets_disabled' :'widgets__reportWidgets'  } `}>
                    {widget.element}
                </div>
    )

    const changeContent = (eventKey:string | null) => {
        setContent(content.map((widget) => {
            if (widget.eventKey === eventKey) return {...widget, active: true}
            return {...widget, active: false}
        }))
        setActiveKey(eventKey)
    }

    const {t} =useTranslation()

    return (
        <>
            <div ref = {MobileTabs} className='nav nav-tabs nav-tabs-line'>
            <Nav
                activeKey={`${activeKey}`}
                onSelect={(selectedKey:any) => changeContent(selectedKey)}
            >
                <Nav.Item>
                    <Nav.Link eventKey="pressReleases">{t('STOCK_PRESS_RELEASES')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="reportWidgets">{t('STOCK_REPORTS')}</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="analyticsWidget">{t('STOCK_FORECASTS')}</Nav.Link>
                </Nav.Item>
            </Nav>
            </div>

            {
                elements
            }
        </>

    )
}

export default React.memo(MobileNavBar)