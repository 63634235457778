import {IListStruct, FilterListEnum, SetFilterList} from './types'
import {getCookie} from "../../../Utills/ClearFunctions";

function eraseCookie(name:string) {
    document.cookie = name+'=; Max-Age=-99999999;';
}

export const FilterActionCreators = {

    setListStruct: (newFilter: Array<IListStruct>): SetFilterList => {
        window.localStorage.setItem('eventTypes', JSON.stringify(newFilter))
        return {
            type: FilterListEnum.SET_FILTER_LIST,
            payload: newFilter,
        }
    },
    setActiveFilterElements:(newFilter: Array<IListStruct>) => {
        window.localStorage.setItem('eventTypes', JSON.stringify(newFilter))
        return {
            type: FilterListEnum.SET_ACTIVE_FILTER_LIST_ELEMENTS,
            payload: newFilter,
        }
    }
}