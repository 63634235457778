import {useTypedSelector} from "./useTypedSelector";
import {useEffect, useState} from "react";
import {currentCompany} from "../redux/reducers/Tiker/types";
import UserService from "../Services/UserService";
import {UseActions} from "./useActions";
import { useCentrifuge } from "../components/Kline/useCentrifuge";
import useDate from "./useDate";
import {IAdditionalPriceInfo, ICompanyIsCrypto, ITradingInfo} from "../Context";
import { getPercent } from "../Utills/ClearFunctions";




function useGetCurrentTicker() {
    const CurrentTicker = useTypedSelector(state => state.TickerReducer)
    const [currentTickerState,setCurrentTickerState] = useState<currentCompany>(CurrentTicker)
    const [additionalPriceInfo, setAdditionalPriceInfo] = useState<IAdditionalPriceInfo | null>(null)
    const [companyInformation,setCompanyInformation] = useState<any>()
    const [investApp, setInvestApp] = useState<string | null>(null)
    const {setCompanyDescriptionInformation} = UseActions()
    const [makeRequest,setMakeRequest] = useState<boolean>(false)
    const [prevTicker,setPrevTicker] = useState(CurrentTicker.ticket)
    const [isSocketOpen,setIsSocketOpen] = useState(false)
    const [isAdditionalPriceOutput, setIsAdditionalPriceOutput] = useState<boolean>(false)
    const [currentPrice,setCurrentPrice] = useState<number>(0)

    const {changeTicker,closeSubscible,continueSubscible} = useCentrifuge(
        {
            setCurrentTickerState:setCurrentTickerState,
            setAdditionalPriceInfo:setAdditionalPriceInfo, 
            isSocketOpen:isSocketOpen,
            isAdditionalPriceOutput:isAdditionalPriceOutput,
            setCurrentPrice
        })
    const [showStatusTooltip,setShowStatusTooltip] = useState<boolean>(false)
    const [isCoinsPage, setIsCoinsPage] = useState<ICompanyIsCrypto>({
        isCrypto:false
    })

    // isCrypto
    const [tradingInfo,setTradingInfo] = useState<ITradingInfo>({
        status:null,
        startAt:null
    })

    const getCompanyInformation = async (ticker:string) => {
        return await UserService.getCompanyInformation(ticker)
    }

    const SubsctribleHandler = (flag:boolean) => {
        if(!flag)
            continueSubscible()
        else
            closeSubscible()
    }
    const setCompanyCapitalization = (data:any,ticker:string) => {
        const capitalization = data?.stock?.marketInfo?.capitalization
        const shareVolume = data?.stock?.marketInfo?.volume
        const logo = data?.stock?.logo
        const brand = data?.stock?.brand
        setInvestApp(data?.stock?.statusInfo?.investmentAppUrl)
        setCompanyDescriptionInformation(capitalization, shareVolume, logo,ticker,brand)
    }

    const getInformation = async (ticker:string) => {
        setIsSocketOpen(false)
        const obj = await getCompanyInformation(ticker)        
        setIsCoinsPage({
            isCrypto: obj.data?.stock?.isCrypto
        })

        setAdditionalPriceInfo(obj.data?.stock?.additionalPriceInfo)
        setShowStatusTooltip(obj.data?.stock?.showStatusTooltip)
        setTradingInfo(obj.data?.stock?.tradingInfo)

        setCurrentTickerState(prev => {
            return {
                ...prev,
                ...obj.data?.stock?.priceInfo
            }
        })

        changeTicker(ticker)
        if (!obj.data?.stock?.additionalPriceInfo) {
            // debugger
            if (!obj.data?.stock?.priceInfo?.finishedAt) {
                //@ts-ignore
                setCurrentPrice(CurrentTicker.price)
                setIsAdditionalPriceOutput(false)
                if (obj.data?.stock?.tradingInfo?.status === null || obj.data?.stock?.tradingInfo?.status === 1) {
                    setIsSocketOpen(true)
                }
                else
                    setIsSocketOpen(false)
            }
        }
        else{

            if(!obj.data?.stock?.additionalPriceInfo.finishedAt){
                //@ts-ignore
                setCurrentPrice(obj.data?.stock?.additionalPriceInfo.price)
                setIsAdditionalPriceOutput(true)
                setIsSocketOpen(true)
            }
        }
        setCompanyInformation(
            {   ...obj.data?.stock,
                hasDataFromStockExchange:obj.data?.stock?.hasDataFromStockExchange
            })
        setCompanyCapitalization(obj.data,ticker)
    }

    useEffect(() => {
        if(!tradingInfo) return 
        if(tradingInfo.status === 1){
            setIsSocketOpen(true)
        }
    },[tradingInfo])

    useEffect(() => {
        if(CurrentTicker.ticket !== prevTicker){
            setPrevTicker(CurrentTicker.ticket)
            setMakeRequest(!makeRequest)
        }
    },[CurrentTicker])

    useEffect(() => {
        let ticker:string= ''
        if(CurrentTicker.isEmpty){
            // @ts-ignore
            if(!window._sharedData) return
            // @ts-ignore
            const sharedObj = window._sharedData
            ticker = sharedObj?.stock?.ticker
            // const percent = 2
            const [percent, isUp] = getPercent(currentTickerState.price as number, Number(currentTickerState.oldPrice))


            const objCard:currentCompany = {
                ticket:ticker,
                cardName:sharedObj?.stock?.brand,
                price:sharedObj?.stock?.priceInfo?.price,
                src:sharedObj?.stock?.logo,
                percent:percent as number,
                oldPrice:sharedObj?.stock?.priceInfo?.oldPrice,
                currency:sharedObj?.stock?.priceInfo?.currency,
            }
            setCurrentTickerState(objCard)
            // @ts-ignore
            setCurrentPrice(objCard.price)

        }
        else{
            setCurrentTickerState(CurrentTicker)
            // @ts-ignore
            setCurrentPrice(CurrentTicker.price)
            ticker = CurrentTicker.ticket as string
        }
        // changeTicker(ticker)

        getInformation(ticker as string)
    },[prevTicker])

    return {
        currentPrice,
        setTradingInfo,
        isCoinsPage, 
        currentTickerState,
        companyInformation, 
        investApp, 
        SubsctribleHandler, 
        tradingInfo, 
        showStatusTooltip, 
        setShowStatusTooltip,
        additionalPriceInfo
    }
}
export default useGetCurrentTicker