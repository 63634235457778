import { useTypedSelector } from "./useTypedSelector";
import { DeviceTypeEnum } from "../models/Subscriptions";
import DesktopSubscription from "../components/Subscription/DesktopSubscription";
import DesktopNotRuSubscription from "../components/Subscription/DesktopNotRuSubscription";


import AppleReview from "../components/Subscription/AppleReview";
import MobileSubscription from "../components/Subscription/MobileSubscription";
import { useEffect } from "react";
import { REVIEW } from "../models/Review";
interface IUseSelectSubscriptions {
    isReView: boolean
}


export const sharedCheckForCIS = (): boolean => {
    // debugger
    //@ts-ignore
    const region = window?._sharedData?.user?.settings?.region
    const CISmap = new Map([
        ['RU', 'RU'],
        ['KZ', 'KZ'],
        ['BY', 'BY'],
        ['UA', 'UA'],
    ])
    if (CISmap.get(region)) return true
    return false
    // return false
}

export const checkForCIS = (region: string): boolean => {
    const CISmap = new Map([
        ['RU', 'RU'],
        ['KZ', 'KZ'],
        ['BY', 'BY'],
        ['UA', 'UA'],
    ])
    if (CISmap.get(region)) return true
    return false
}

export const checkForRU = (): boolean => {
    //@ts-ignore
    const region = window?._sharedData.user?.settings?.region
    const CISmap = new Map([
        ['RU', 'RU'],
    ])
    if (CISmap.get(region)) return true
    return false
}


function useSelectSubscriptions() {
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const deviceType = useTypedSelector(state => state.EventReducer.userAgent)
    const isReView = window.navigator.userAgent.includes(REVIEW) && window.navigator.userAgent.includes('iOS')

    if (!isApplication) {
        if (checkForRU())
            return <DesktopSubscription />
        return <DesktopNotRuSubscription />
    }
    else {
        if (checkForRU() && window.navigator.userAgent.includes('Android') && !isReView) return <DesktopSubscription />
        return <MobileSubscription isAppleRewiew={isReView} />
    }

}
export default useSelectSubscriptions