import { t } from "i18next";
import {useTransition} from "../../hooks/useTransition";
import { gettOrigin } from "../../Utills/ClearFunctions";

function SubscriptionExpirationProfileAlert(){
    const {transitionToSubscriptionPage} = useTransition()

    return(
        <div className="">
            <div className="card-body py-6 px-2">
                <p className="mb-2"><b>⚠️ {t('PROFILE_SUBSCRIPTION_EXPIRED')}</b></p>
                <p>{t('PROFILE_SUBSCRIPTION_EXPIRED_TEXT')}</p>
                <a href={`${gettOrigin()}/finance/subscription`}
                   onClick={transitionToSubscriptionPage}
                   className="btn btn-sm btn-white font-weight-bolder">{t('PROFILE_SUBSCRIPTION_PROLONG')}</a>
            </div>
        </div>
    )
}

export default SubscriptionExpirationProfileAlert