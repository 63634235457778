import React, {MutableRefObject, RefObject, useCallback, useEffect, useRef, useState} from "react";
import '../../styles/CommentStyle.css'
import {useTypedSelector} from "../../hooks/useTypedSelector";
import smoothscroll from 'smoothscroll-polyfill';
import {useLocation, useNavigate} from "react-router-dom";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import {deviceIsAppleApplicationOnly, DeviseIsApplicationOnly, gettOrigin} from "../../Utills/ClearFunctions";
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from "react-dom/server";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import {UseActions} from "../../hooks/useActions";
import { DEFAUL_USER_IMG } from "../../models/User";
import { t } from "i18next";
import { Trans } from "react-i18next";

const smoothStep = (to: number) => {
    const step = (timestamp: any) => {
        if (window.scrollY < to) return
        window.scrollBy(
            0,
            -3,
        );
        requestAnimationFrame(step);
    };
    requestAnimationFrame(step);
}

interface ICommentsFooter {
    text: string,
    setText: (text: string) => void,
    chahgeRef?: () => void
    blurEvent?: () => void
    addComment?: (eventId: number, text: string) => void
    eventId?: string | null,
}


interface ISelf {
    heightWas?: number
    focus?: boolean
    focusPatchApplied?: boolean

}


const CommentsFooter = React.forwardRef<HTMLTextAreaElement, ICommentsFooter>(
    (
        {
            chahgeRef,
            blurEvent,
            text,
            setText,
            addComment,
            eventId
        }: ICommentsFooter, inputRef
    ) => {
        const myRef = useRef<HTMLTextAreaElement | null>(null);
        const location = useLocation()
        const formRef = useRef<HTMLFormElement>({} as HTMLFormElement)
        const {checkUser} = UseActions()

        const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
        const pullToRequest = useTypedSelector(state => state.EventReducer.pullToRequest)
        const [isFocused, setIsFocused] = useState<boolean>(false)
        const [initialPosition, setInitialPosition] = useState<number>(0)
        const [current, setCurrent] = useState<number>(0)
        const [currentTopState, setCurrentTopState] = useState<number>(0)
        const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null)
        const self = useRef<ISelf>({} as ISelf)
        const User = useTypedSelector(state => state.UserReducer.User)
        const {setSpecialProfileBackCase} = UseActions()
        const navigate = useNavigate()
        const countClick = useRef<number>(1)
        const timer = useRef<number | null>(null)
        const {setPullRequest} = UseActions()

        const checkButtonState = () => {
            if(!myRef.current) return false
            return myRef.current.value === ''
        }

        const clickToSpecialProfileTransition = (e:React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault()
            setSpecialProfileBackCase(true)
            navigate('/profile', {replace: false})
        }

        const canUserSendComment = () => {
            // console.log(User.avatar === DEFAUL_USER_IMG)
            if(User.avatar === DEFAUL_USER_IMG || !User.profile.username) return false
            return true
        }
        useEffect(() => {
            if(pullToRequest)
                pullToRequest.destroy()
            if (deviceIsAppleApplicationOnly()) {
                document.body.style.overflow = 'hidden'
                const root = document.getElementById('pull')
                setTargetElement(root as HTMLDivElement)
                root!.style.overflow = 'auto'
                root!.style.position = 'absolute'
                root!.style.height = '100%'
                root!.style.width = '100vw'
            }

        }, [pullToRequest])

        useEffect(() => {
            if (!targetElement) return

        }, [targetElement])

        function onInputClick(event: any) {
            if (countClick.current >= 2) {
                var input = event.target;
                self.current.heightWas = window.innerHeight;

                input.focus();
                return
            }
            // alert('click')
            countClick.current += 1
            self.current.heightWas = window.innerHeight;
            event.preventDefault();
            event.stopPropagation();
            var input = event.target;

            input.style.height = '1px';
            input.style.bottom = '0px';
            input.style.position = 'absolute';
            input.focus();
        }

        function onInputFocus(event: any) {
            countClick.current += 1
            self.current.focus = true;
            var input = event.target;
            input.style.height = '40px';
            input.style.position = null;
            input.style.bottom = null;
        }
        const sendFocus = () => {
            //@ts-ignore
            addComment(eventId, text)
            setTimeout(() => {
                const pull = document.getElementById('pull') as HTMLDivElement
                pull.scrollTop = pull.getBoundingClientRect().height
            },800)
            setText('')
        }

        const userTimeOutInterval = () => {
            if (!window.navigator.userAgent.includes('Tradesense')) return 
            timer.current = window.setInterval(() => {
                checkUser()  
            },5000) 

        }

        useEffect(() => {
            userTimeOutInterval()
            if(!deviceIsAppleApplicationOnly()) return
            if(window.navigator.userAgent.includes('Android')) return

            window.addEventListener('scroll', function (event) {
                //@ts-ignore
                if (self.current.focus) {
                    var newWindowHeight = window.innerHeight;
                    //@ts-ignore
                    var offsetTop = document.getElementsByClassName('comments')[0].offsetTop
                    var style = document.getElementById('root')!.style;
                    style.height = newWindowHeight + 'px';
                    style.top = offsetTop + 'px';
                    self.current.focusPatchApplied = true;
                }
            });

            if (deviceIsAppleApplicationOnly()) {
                const button = document.getElementById('send-comment-button') as HTMLElement
                if(!button) return
                button.addEventListener('focus',sendFocus)
                myRef!.current!.addEventListener('touchend', onInputClick);
                myRef!.current!.addEventListener('mousedown', onInputClick);
                myRef!.current!.addEventListener('focus', onInputFocus, true);

            }
            return () => {
                const pullRequestObj = PullToRefresh.init({
                    mainElement: 'pull',
                    onRefresh() {
                        window.location.reload();
                    },
                    iconArrow: ReactDOMServer.renderToString(
                        <FontAwesomeIcon icon={faSyncAlt} />
                    ),
                    iconRefreshing: ReactDOMServer.renderToString(
                        <FontAwesomeIcon icon={faSyncAlt} spin={true} />
                    ),
                });

                setPullRequest(pullRequestObj)

                if(timer.current) clearInterval(timer.current)
            }

        }, [])

        useEffect(() => {
            if (DeviseIsApplicationOnly()) {
                formRef.current.classList.add('footerMobile')
            }
        }, [userAgent])

        const handleInputClick = (e: any) => {
            e.preventDefault()
            e.stopPropagation()
            //@ts-ignore
            addComment(eventId, text)
            setTimeout(() => {
                const pull = document.getElementById('pull') as HTMLDivElement
                pull.scrollTop = pull.getBoundingClientRect().height + 200
            },800)
            setText('')
        }


        const send = () => {
            //@ts-ignore
            addComment(eventId, text)
        }

        const changeText = (e: any) => {
            setText(e.target.value)
        }

        return (

            <form style={{backgroundColor: 'white'}} onSubmit={handleInputClick} ref={formRef} id="send-comment-form"
                  className="m-0 w-100 p-4"
                  action={`${gettOrigin()}/events/send-comment`}
                  method="post">

                {
                    // @ts-ignore
                    (DeviseIsApplicationOnly()) && <hr/>
                }

                {
                (canUserSendComment()) ? <>
                 
                <input type="hidden" name="_csrf"
                       value="IwZ1MpaKI4S2ET2Qdr3ZTq8CIbNLes98KJkZJ8-nUD5ycxBT8u4V9_skEOMn5-4K9XhO3hQxhE9d11YWo90PSw=="/>
                
                <div className="d-flex align-items-start w-100">
                    <div className="d-md-block d-none">
                
                <span className="symbol symbol-35 symbol-circle mr-3">
                    <span className="symbol-label"
                          style={
                            {backgroundImage: `url(${User.avatar})`}
                          }></span>
                </span>
                    </div>
                    <div className="flex-grow-1 mr-2" data-toggle="view">
                        <input type="hidden" name="clientEventId" value="355731"/>

                        <textarea
                            value={text}
                            onChange={changeText}
                            // ref=  {inputRef}
                            ref={(node) => {
                                myRef.current = node;
                                if (typeof inputRef === 'function') {
                                    inputRef(node);
                                } else if (inputRef) {
                                    //@ts-ignore
                                    (inputRef as MutableRefObject<HTMLTextAreaElement>).current = node;
                                }
                            }}
                            id="comment-text"
                            className="form-control form-control-solid"
                            name="text"

                            placeholder={`${t('COMMENTS_INPUT_PLACEHOLDER')}`}
                            autoComplete="off"
                            style={{
                                height: '40px',
                                overflow: 'hidden',
                                overflowWrap: 'break-word',
                                resize: 'none'
                            }}>

                    </textarea>
                    </div>
                    <div className="font-weight-bolder ml-auto" data-toggle="view" onClick={ (e) => e.stopPropagation()}>
                        {/*disabled=""*/}
                        <button disabled = {checkButtonState()} onClick={handleInputClick} id="send-comment-button" className="btn btn-primary"
                                style={{height: '40px'}}>
                            <span className="d-none d-lg-inline">{t('COMMENTS_BUTTON')}</span>
                            <i className="d-lg-none fab fa-telegram-plane"></i>
                        </button>
                    </div>
                </div>
                
                </>
                :<>
                    <small>
                        <Trans i18nKey={'PROFILE_FILL_INFO'} >
                            <a href="" onClick={clickToSpecialProfileTransition}></a>
                        </Trans>
                    </small>
                </>
            }

        
               


                
            </form>

        )

    })

export default CommentsFooter

