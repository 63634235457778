import { t } from "i18next";
import React from "react";
import { IfreePriceButtonState } from "./MobileSubscription";

interface IPayButton {
    text: string,
    getCurrentSCU: (e: React.MouseEvent<HTMLButtonElement>) => void,
    isAppleRewiew: boolean,
    currentTab: any,
    price?: number,
    freePrice?: IfreePriceButtonState,
    isTrial?: boolean,
}

const replacment = (payText: string) => {
    // replace to pipe function
    return payText.replace(t('SUBSCRIPTION_PLANS_PAY'), '').replace(t('SUBSCRIPTION_PLANS_EACH_MONTH'), '').replace(t('SUBSCRIPTION_PLANS_EACH_3MONTHS'), '').replace(t('SUBSCRIPTION_PLANS_EACH_YEAR'), '')
}

const getFormattedPrice = (payText: string, price: number) => {
    const partOne: string = `${t('SUBSCRIPTION_PLANS_PAY')} ` + replacment(payText)
    let partTwo: string = `${t('SUBSCRIPTION_PLANS_PAY_NEXT')} ` + payText.replace(t('SUBSCRIPTION_PLANS_PAY'), '')
    return { partOne, partTwo }
}

const getRewiewButtontext = (text: IfreePriceButtonState | undefined) => {
    return { partOne: text?.partOne, partTwo: text?.partTwo }
}

function PayButtonMobile({ text, getCurrentSCU, isAppleRewiew, currentTab, price, freePrice, isTrial }: IPayButton) {

    const getRightText = (text: string) => {
        if (currentTab.sku === 'ts_monthly_trial') {
            if (!isAppleRewiew)
                return text
            const { partOne, partTwo } = getRewiewButtontext(freePrice)
            return <> <div>{partOne}</div> <small>{partTwo}</small></>

        }
        if (!isAppleRewiew) return text
        const { partOne, partTwo } = getFormattedPrice(text, price as number)
        return <> <div>{partOne}</div> <small>{partTwo}</small></>
    }
    return (
        <div className="row justify-content-md-center mb-9">
            <div className="col-lg-6 col-12 text-center">

                <button onClick={getCurrentSCU} className="btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 mr-2">
                    <span className="js__subscription-selected">

                        {
                            isTrial ?
                                <>
                                    {t('SUBSCRIPTION_PLANS_CONTINUE')}
                                </>
                                : <>
                                    {getRightText(text)}
                                </>
                        }
                    </span>
                </button>

            </div>
        </div>
    )
}

export default PayButtonMobile