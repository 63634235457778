import React from 'react';
import '../../styles/trendLine.css'
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {EventTopicEnum} from "../../models/EventContentModel";


interface ITrendLine{
    currentTopic:EventTopicEnum
}

function TrendLine({currentTopic}:ITrendLine)  {
    const period = useTypedSelector(state => state.EventReducer.period)

    const allEvent = useTypedSelector(state => state.AllEventsReducer)
    const FavouritesEvent = useTypedSelector(state => state.FavouritesReducer)
    const TrendElementEvent = useTypedSelector(state => state.TrendsReducer)

    const getGard = () => {
        switch (currentTopic) {
            case(EventTopicEnum.LAST_EVENT):
                return ( <>

                </> )

            case(EventTopicEnum.TICKER_EVENTS):
                return (
                    <>
                        {
                            period.full !== '' && <div className="font-weight-bolder text-primary font-size-md  time"><b className="far fa-clock mr-2"></b> {period.full}
                        </div>
                        }
                    </>
                )

            case(EventTopicEnum.TREND):
                return (<>
                    {
                        TrendElementEvent.period.full !== '' && <div className="font-weight-bolder text-primary font-size-md  time"><b className="far fa-clock mr-2"></b> {TrendElementEvent.period.full}
                        </div>
                    }
                </> )
            case(EventTopicEnum.FAVORITES):
                return (<>
                    {
                        FavouritesEvent.period.full !== '' && <div className="font-weight-bolder text-primary font-size-md  time"><b className="far fa-clock mr-2"></b> {FavouritesEvent.period.full}
                        </div>
                    }
                </>)
                break;
            case(EventTopicEnum.ALL_STOCKS):
                return ( <>
                    {
                        allEvent.period.full !== '' && <div className="font-weight-bolder text-primary font-size-md  time"><b className="far fa-clock mr-2"></b> {allEvent.period.full}
                        </div>
                    }
                </> )
        }
    }
    return (

        <>{ getGard() } </>

    );
}

export default TrendLine