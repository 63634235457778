import {Chart, dispose, init, utils, Utils} from 'klinecharts';
import React, {useContext, useEffect, useRef, useState} from "react";
import {generateData} from "./generateDate";
// @ts-ignore
import {safariHandleTooltip} from "../../Strategy/safary/chartLogic";
import {chromeChartLogic} from "../../Strategy/chrome/chartLogic";
import GraphControls from '../Layout/GraphControls';
import {generateChartStyle} from './style';
import {emojiTechnicalIndicatorGenerator} from './texIndicators';
import {generateTemplateCircle} from './Shapes/circle';
import {AreaInformation, CandleInformation} from './PriceInformation';
import '../../styles/chartStyle.css'
import NoChart from './NoChart'
import {
    AreaPeriodType,
    ChartGroupTypes,
    circleEnum,
    IChartResponse,
    IDataChart,
    IPriceAreainfornation,
    IPriceCandleinfornation,
    typeGraphEnum
} from '../../models/Chart';
import useDate from '../../hooks/useDate';
import {generateTemplateBorderCircle} from './Shapes/borderCircle';
import {generateTemplateWrappedCircle} from './Shapes/WrappedCircle';
import {createCircle, createLine} from './Shapes/addShape';
import ChartService from '../../Services/ChartService';
import {AxiosResponse} from 'axios';
import {IElementGraphControl} from '../Layout/GraphControls/Element';
import {useTypedSelector} from '../../hooks/useTypedSelector';
import {TextIndicatorNetwork} from './utils/renderData';
import {useCentrifuge} from './useCentrifuge';
import {TickerContext} from '../../Context';
import {dataHandler} from './textLogic/DataHandler';
import {off} from 'process';
import useTranslate from "../../hooks/useTranslate";
import {deviceIsIphoneApplication, deviceIsMobile, DeviseIsApplicationOnly} from "../../Utills/ClearFunctions";
import {ORIGIN} from "../../models/Domen";
import ChartEventsModal from '../Modals/ChartEventsModal';
import {generateTemplateLine} from './Shapes/line';
import {useTranslation} from 'react-i18next';
import TelegramModalBody from "../Modals/TelegramModalBody";
import {UseActions} from "../../hooks/useActions";
import useBackDropEvents from "../../hooks/useBackDropEvents";
import useTradingView from '../../hooks/useTradingView';
import { useParams } from 'react-router-dom';

export interface IspecialTextBounding {
    xStart: number,
    xEnd: number,
    yStart: number,
    yEnd: number,
    element: any
}

export type specialTextBoundingType = IspecialTextBounding | null

export interface IKLineDataElements {
    width: number,
    height: number,
    top: number,
    left: number,
}

function Kline() {
    const {getChartDate, getChartLineDate, getTimeZone} = useDate()
    const [elements, setElements] = useState<IKLineDataElements[]>([] as IKLineDataElements[])
    const [canvasIsRendered, setCanvasIsRendered] = useState<{ flag: boolean }>({flag: false})
    const canvasRef = useRef<HTMLCanvasElement | null>(null)
    const faceCanvas = useRef<HTMLCanvasElement | null>(null)
    const circleLastId = useRef<string | null>(null)
    const circleBorderLastId = useRef<string | null>(null)
    const wrappedCircleLastId = useRef<string | null>(null)
    const User = useTypedSelector(state => state.UserReducer.User)
    const [timeZone, setTimeZone] = useState<string>(User.settings.timezone)//change to ref
    const language = useRef<string>(User.settings.language)
    const CompanyReducer = useTypedSelector(state => state.CompanyReducer)
    const {currentTickerState, SubsctribleHandler, companyInformation,isCoinsPage,currentPrice} = useContext(TickerContext)
    const [prevTicker, setPrevTicker] = useState<string>('')
    const lastKlineDataObject = useRef<any>()
    const lastCandleHistory = useRef<Array<IDataChart>>([])
    const {checkTextForTranslation} = useTranslate('')
    const {id} = useParams()

    const {setChildren, setBackDropIsOpen,setBackDropHeader} = UseActions()
    // const {currentTickerState, SubsctribleHandler, companyInformation,isCoinsPage} = useContext(TickerContext)
    const {addTraidingViewWidgetChart} = useTradingView()

    const {t} = useTranslation()

    const [state, setState] = useState<any>({
        candleShowType: 'standard',
        candleShowRule: 'always',
        technicalIndicatorShowRule: 'always'
    })
    const [graphtype, setGraphtype] = useState<typeGraphEnum>(typeGraphEnum.AREA)

    const [priceCandleinfornation, setPriceCandleinfornation] = useState<IPriceCandleinfornation>({
        price: 0,
        percent: 0,
        open: 0,
        close: 0,
        high: 0,
        low: 0,
        date: '',
        color: ' '
    })

    const [priceAreainfornation, setPriceAreainfornation] = useState<IPriceAreainfornation>({
        price: 0,
        date: '0'
    })

    const [candleGroupState, setCandleGroupState] = useState<Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>>(
        [
            {
                active: true,
                id: ChartGroupTypes.MINUTE,
                name: `${t('STOCK_CHART_1MIN')}`,

            },
            {
                active: false,
                id: ChartGroupTypes.FIVE_MINITES,
                name: `${t('STOCK_CHART_5MIN')}`,

            },
            {
                active: false,
                id: ChartGroupTypes.FIFTEEN_MINUTES,
                name: `${t('STOCK_CHART_15MIN')}`,

            },
            {
                active: false,
                id: ChartGroupTypes.THIRTY_MINUTES,
                name: `${t('STOCK_CHART_30MIN')}`,
            },
            {
                active: false,
                id: ChartGroupTypes.HOUAR,
                name: `${t('STOCK_CHART_1H')}`,

            }
        ]
    )

    const [areaPeriodState, setAreaPeriodState] = useState<Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>>(
        [
            {
                active: true,
                id: AreaPeriodType.DAY,
                name: `${t('STOCK_CHART_1D')}`,
            },
            {
                active: false,
                id: AreaPeriodType.WEEK,
                name: `${t('STOCK_CHART_7D')}`,
            },
            {
                active: false,
                id: AreaPeriodType.MONTH,
                name: `${t('STOCK_CHART_1M')}`,
            },
            {
                active: false,
                id: AreaPeriodType.THREE_MONTH,
                name: `${t('STOCK_CHART_3M')}`,
            },
            {
                active: false,
                id: AreaPeriodType.SIX_MONTH,
                name: `${t('STOCK_CHART_6M')}`,
            },
            {
                active: false,
                id: AreaPeriodType.YEAR,
                name: `${t('STOCK_CHART_1Y')}`,
            }
        ]
    )

    useEffect(() => {
        if(companyInformation?.hasDataFromStockExchange === false)
            addTraidingViewWidgetChart(id as string, isCoinsPage.isCrypto)
    },[isCoinsPage, currentTickerState.ticket,companyInformation?.hasDataFromStockExchange ])

    function getTooltipOptions(candleShowType: any, candleShowRule: any, technicalIndicatorShowRule: any, graphtype: string) {
        if (graphtype == 'area')
            return {
                candle: {
                    tooltip: {
                        showType: 'none',
                        showRule: 'none',
                        labels: ['price: '],
                        text: {
                            size: 13,
                            family: 'Open Sans',
                            weight: 'normal',
                            color: '#D9D9D9',
                            marginLeft: 8,
                            marginTop: 6,
                            marginRight: 8,
                            marginBottom: 0
                        },
                        values: (kLineData: any) => {
                            const change = (kLineData.close - kLineData.open) / kLineData.open * 100
                            return [
                                {value: kLineData.close.toFixed(2)},
                            ]
                        }
                    }
                },
                technicalIndicator: {
                    tooltip: {
                        showRule: technicalIndicatorShowRule
                    }
                }
            }
        else
            return {
                candle: {
                    tooltip: {
                        showType: 'none',
                        showRule: 'none',
                        labels: ['open：', 'close：', 'percent：', 'high: ', 'low: '],
                        text: {
                            size: 13,
                            family: 'Open Sans',
                            weight: 'normal',
                            color: '#D9D9D9',
                            marginLeft: 8,
                            marginTop: 6,
                            marginRight: 8,
                            marginBottom: 0
                        },
                        values: (kLineData: any) => {
                            const change = (kLineData.close - kLineData.open) / kLineData.open * 100
                            return [
                                {value: kLineData.open.toFixed(2)},
                                {value: kLineData.close.toFixed(2)},
                                {
                                    value: `${change.toFixed(2)}%`,
                                    color: change < 0 ? '#EF5350' : '#26A69A'
                                },
                                {
                                    value: kLineData.high.toFixed(2)
                                },

                                {
                                    value: kLineData.low.toFixed(2)
                                }

                            ]
                        }
                    }
                },
                technicalIndicator: {
                    tooltip: {
                        showRule: technicalIndicatorShowRule
                    }
                }
            }
    }

    const getLowHighPrice = (dataLIst: IDataChart[]) => {
        let high: number = -999999999
        let low: number = 999999999
        dataLIst.forEach((candleData) => {
            if (candleData.close > high) high = candleData.close
            if (candleData.close < low) low = candleData.close
        })
        return {high, low}
    }

    const isUpdateChartPrice = useRef<boolean>(true)

    const updateTimeStamp = (DIFF_TIMESTAMP: number) => {
        const timeDiff = Date.now() - lastKlineDataObject.current.timestamp
        let high = 0
        let low = 0
        if (DIFF_TIMESTAMP < timeDiff) {
            lastKlineDataObject.current.timestamp = Date.now()
            const dataLIst = chartObj.current?.getDataList()
            if (!dataLIst) return false

            high = lastKlineDataObject.current.close
            low = lastKlineDataObject.current.close

            lastKlineDataObject.current.open = lastKlineDataObject.current.close
            lastKlineDataObject.current.low = low
            lastKlineDataObject.current.high = high

            lastCandleHistory.current = [lastKlineDataObject.current]
            isUpdateChartPrice.current = false
            return true
        } else {
            isUpdateChartPrice.current = false
            if (lastCandleHistory.current.length !== 0) {
                const obj = getLowHighPrice(lastCandleHistory.current)
                high = obj.high
                low = obj.low
            } else {
                high = lastKlineDataObject.current.close
                low = lastKlineDataObject.current.close
            }
            lastKlineDataObject.current.high = high
            lastKlineDataObject.current.low = low

            lastCandleHistory.current = [...lastCandleHistory.current, {...lastKlineDataObject.current}]
            return false
        }
    }
    const timerIsStart = useRef<boolean>(false)
    const [rerenderCandle, setRerenderCandle] = useState<{ isRerender: boolean }>({isRerender: false})

    const startTimer = (idCandle: ChartGroupTypes) => {
        let diffTime = 0
        if (idCandle === ChartGroupTypes.MINUTE) {
            diffTime = 1000 * 60
        } else if (idCandle === ChartGroupTypes.FIVE_MINITES) {
            diffTime = 5 * 1000 * 60
        } else if (idCandle === ChartGroupTypes.FIFTEEN_MINUTES) {
            diffTime = 15 * 1000 * 60
        } else if (idCandle === ChartGroupTypes.THIRTY_MINUTES) {
            diffTime = 30 * 1000 * 60
        } else if (idCandle === ChartGroupTypes.HOUAR) {
            diffTime = 60 * 1000 * 60
        }
        timerIsStart.current = true

        setTimeout(() => {
            timerIsStart.current = false
            const mas = chartObj.current?.getDataList()
            //@ts-ignore
            lastKlineDataObject.current = {...mas[mas?.length - 1]}
            // currentPrice
            // lastKlineDataObject.current.close = currentTickerState.price
            lastKlineDataObject.current.close = currentPrice
            updateTimeStamp(diffTime)
            setRerenderCandle({isRerender: false})
        }, diffTime)
    }

    useEffect(() => {
        if (!currentTickerState) return
        if (!lastKlineDataObject.current) return
        const ActiveCandle = candleGroupState.find((areaState) => areaState.active)
        const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
        const ActiveAreaPeriod = ActiveArea?.id as AreaPeriodType
        const idCandle = ActiveCandle?.id as ChartGroupTypes
        const mas = chartObj.current?.getDataList()
        //@ts-ignore
        lastKlineDataObject.current = {...mas[mas?.length - 1]}
        // lastKlineDataObject.current.close = currentTickerState.price
        lastKlineDataObject.current.close = currentPrice
        let flag: boolean = false

        if (graphtype === typeGraphEnum.AREA) {
            if (ActiveAreaPeriod === AreaPeriodType.DAY) {
                updateTimeStamp(1000 * 60)
            } else if (ActiveAreaPeriod === AreaPeriodType.WEEK) {
                updateTimeStamp(15 * 1000 * 60)
            }
        } else {
            if (idCandle === ChartGroupTypes.MINUTE) {
                updateTimeStamp(1000 * 60)
            } else if (idCandle === ChartGroupTypes.FIVE_MINITES) {
                updateTimeStamp(5 * 1000 * 60)
            } else if (idCandle === ChartGroupTypes.FIFTEEN_MINUTES) {
                updateTimeStamp(15 * 1000 * 60)

            } else if (idCandle === ChartGroupTypes.THIRTY_MINUTES) {
                updateTimeStamp(30 * 1000 * 60)
            } else if (idCandle === ChartGroupTypes.HOUAR) {
                updateTimeStamp(60 * 1000 * 60)
            }
        }
        chartObj.current?.updateData(lastKlineDataObject.current)
    }, [currentPrice,currentTickerState, graphtype, CompanyReducer, areaPeriodState, candleGroupState])

    const isFirstChartRendereing = useRef<boolean>(true)

    const createPinIndicator = () => {
        const ActiveCandle = candleGroupState.find((areaState) => areaState.active)
        const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
        const ActiveAreaPeriod = ActiveArea?.id as AreaPeriodType
        const ticker = currentTickerState.ticket as string
        if (!chartObj.current) return

        chartObj.current.removeTechnicalIndicator('candle_pane', ' ')
        chartObj.current.addTechnicalIndicatorTemplate(
            emojiTechnicalIndicatorGenerator(
                setElements,
                graphtype,
                setPriceHtmlInformation,
                chartObj.current,
                eventRef.current,
                timeZone,
                language.current,
                ActiveArea?.id as AreaPeriodType,
                ActiveCandle?.id as ChartGroupTypes,
                currentTickerState.ticket as string,
                getHistoryData,
                checkTextForTranslation,
            )
        )
        chartObj.current.createTechnicalIndicator(' ', true, {id: 'candle_pane', dragEnabled: true,})
    }

    const getHistoryData = (lastTimestamp: number) => {
        removeCircle()
        const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
        const ActiveCandle = candleGroupState.find((areaState) => areaState.active)
        const ActiveAreaPeriod = ActiveArea?.id as AreaPeriodType
        const ticker = currentTickerState.ticket as string
        const ChartCandleActiveGroup = ActiveCandle?.id as ChartGroupTypes
        const netWorkState = TextIndicatorNetwork.getObj()

        if (graphtype === typeGraphEnum.AREA)
            ChartService.getHistotyAreaData(ticker, ActiveAreaPeriod, lastTimestamp / 1000).then((axiosResponse) => {
                if (!chartObj) return
                netWorkState.setPending(false)
                TextIndicatorNetwork.isPendingStatic = true
                if (axiosResponse.data.data.length === 0) {
                    netWorkState.setIsEmptyResponse(true)
                } else {
                    if (!chartObj.current) return

                    const replacedData = axiosResponse.data.data.map((candleData) => {
                        return {...candleData, timestamp: candleData.timestamp * 1000}
                    })
                    chartObj.current.applyMoreData(replacedData, true)
                    eventRef.current = [...axiosResponse.data.eventPoints, ...eventRef.current]
                    createPinIndicator()
                }
            })
        if (graphtype === typeGraphEnum.CANDLE)
            ChartService.getHistotyCandleData(ticker, ChartCandleActiveGroup, lastTimestamp / 1000).then((axiosResponse) => {
                if (!chartObj) return
                netWorkState.setPending(false)
                if (axiosResponse.data.data.length === 0) {
                    netWorkState.setIsEmptyResponse(true)
                } else {
                    if (!chartObj.current) return

                    const replacedData = axiosResponse.data.data.map((candleData) => {
                        return {...candleData, timestamp: candleData.timestamp * 1000}
                    })
                    chartObj.current.applyMoreData(replacedData, true)
                    eventRef.current = [...axiosResponse.data.eventPoints, ...eventRef.current]
                    createPinIndicator()
                }
            })
    }

    useEffect(() => {
        if (canvasIsRendered.flag) {
            const canvas = document.querySelectorAll('canvas')[0] as HTMLCanvasElement
            if (!canvas) return
            const wrapperDiv = document.querySelector('#simple_chart > div > div > div') as HTMLDivElement
            if (window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('Android')) return
            return
        }
    }, [canvasIsRendered])

    const currentChartData = useRef<Array<IDataChart>>([])
    const chartObj = useRef<Chart | null>(null)
    const eventRef = useRef<Array<any>>([])
    //@ts-ignore
    const getPrecision = (value:number):number => {
        const Precisionresult = String(value).match(/[\.\,]\d*/g)
        if(Precisionresult){
                const lengthZero = Precisionresult[0].split('').length
                if(lengthZero > 1){
                    return lengthZero
                    // chart.setPriceVolumePrecision(lengthZero,lengthZero)        
                }
        }
        else return 2
    }

    const setPriceHtmlInformation = (type: typeGraphEnum, kLineData: any) => {
        if(!kLineData) return
        const currentTimeStamp: number = kLineData.timestamp
        const date = getChartDate(currentTimeStamp)

        if (type === typeGraphEnum.CANDLE) {
            const change = (kLineData.close - kLineData.open) / kLineData.open * 100
            const color = change < 0 ? 'red' : 'green'
            const high = kLineData.high
            const low = kLineData.low
            const open = kLineData.open
            const close = kLineData.close

            setPriceCandleinfornation((prev) => {
                return {
                    ...prev,
                    price: kLineData.close,
                    percent: Number(change.toFixed(2)),
                    color: color,
                    close: close,
                    date: date,
                    high: high,
                    low: low,
                    open: open
                }
            })
        } else if (type === typeGraphEnum.AREA) {
            const price = kLineData.close
            setPriceAreainfornation(
                {price: price, date: date}
            )
        }
    }

    const setZoomDay = (chart: Chart) => {
        let zoom = currentChartData.current.length * (-6) / 642
        if (zoom <= 1)
            zoom = currentChartData.current.length * (20) / 18
        chart.zoomAtDataIndex(zoom, currentChartData.current.length - 1)
    }

    const prevZoom = useRef<number>(0)

    const renderChart = (date: Array<IDataChart>) => {
        lastKlineDataObject.current = date[dataHandler.length - 1]
        
        const chart = init('simple_chart');
        

        chartObj.current = chart
        setCanvasIsRendered({flag: true})
        if (!chart) return

        const {generatedDataList, getLastTimeStamp} = generateData()
        const kLineDataList = generatedDataList()
        chart.clearData()
        chart.applyNewData(date);
        chart.setTimezone(getTimeZone())



        chart.zoomAtDataIndex(30, currentChartData.current.length - 1)

        chart.setStyleOptions(generateChartStyle(graphtype))
        chart.setLeftMinVisibleBarCount(2)
        const {candleShowType, candleShowRule, technicalIndicatorShowRule} = state
        chart.setStyleOptions(getTooltipOptions(
            candleShowType, candleShowRule, technicalIndicatorShowRule, graphtype
        ))
        const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
        const ActiveCandle = candleGroupState.find((areaState) => areaState.active)

        chart.removeTechnicalIndicator('candle_pane', ' ')
        chart.addTechnicalIndicatorTemplate(
            emojiTechnicalIndicatorGenerator(
                setElements,
                graphtype,
                setPriceHtmlInformation,
                chartObj.current,
                eventRef.current,
                timeZone,
                language.current,
                ActiveArea?.id as AreaPeriodType,
                ActiveCandle?.id as ChartGroupTypes,
                currentTickerState.ticket as string,
                getHistoryData,
                checkTextForTranslation
            )
        )
        const precision = getPrecision(date[date.length - 1].close)
        setPriceHtmlInformation(graphtype, chart.getDataList()[chart.getDataList().length - 1])
        chart.createTechnicalIndicator({name:' ',precision:precision}, true, {id: 'candle_pane', dragEnabled: true})

        chart.setPaneOptions({
            id: 'pane_1',
            height: 300,
            dragEnabled: true,
        });
        
        chart.setPriceVolumePrecision(precision, precision)
        if (graphtype === typeGraphEnum.AREA) {
            const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
            if (ActiveArea?.id === AreaPeriodType.DAY)
                setZoomDay(chart)
            if (ActiveArea?.id === AreaPeriodType.WEEK)
                chart.zoomAtDataIndex(-4, currentChartData.current.length - 1)
            if (ActiveArea?.id === AreaPeriodType.MONTH)
                chart.zoomAtDataIndex(-4, currentChartData.current.length - 1)
            if (ActiveArea?.id === AreaPeriodType.THREE_MONTH)
                chart.zoomAtDataIndex(0, currentChartData.current.length - 1)
            if (ActiveArea?.id === AreaPeriodType.SIX_MONTH)
                chart.zoomAtDataIndex(-1, currentChartData.current.length - 1)
            if (ActiveArea?.id === AreaPeriodType.YEAR)
                chart.zoomAtDataIndex(-6, currentChartData.current.length - 1)
        }
        if (graphtype === typeGraphEnum.CANDLE) {
            chart.zoomAtDataIndex(20, currentChartData.current.length - 1)
        }
        chart.setZoomEnabled(true)
        chart.setOffsetRightSpace(0)
        chart.addShapeTemplate(generateTemplateCircle())
        chart.addShapeTemplate(generateTemplateLine())
        chart.addShapeTemplate(generateTemplateBorderCircle())
        chart.addShapeTemplate(generateTemplateWrappedCircle())
        chart.subscribeAction('zoom', (e) => {
            if (!faceCanvas.current) return
            const context = faceCanvas.current.getContext('2d')

            if (!context) return;
            //@ts-ignore
            context.reset()
        })

        chart.subscribeAction('pane_drag', (e) => {
            if (!faceCanvas.current) return
            const context = faceCanvas.current.getContext('2d')
            if (!context) return;
            //@ts-ignore
            context.reset()
        })

        chart.subscribeAction('crosshair', (e) => {
            if (!isUpdateChartPrice.current) {
                isUpdateChartPrice.current = true
                return
            }
            if (circleLastId.current)
                chart.removeShape(circleLastId.current)

            if (circleBorderLastId.current)
                chart.removeShape(circleBorderLastId.current)

            if (wrappedCircleLastId.current)
                chart.removeShape(wrappedCircleLastId.current)

            setPriceHtmlInformation(graphtype, e.kLineData)
            if (graphtype === typeGraphEnum.CANDLE) return
            try {
                const wrappedLastId = chart.createShape(
                    createCircle(e.dataIndex, e.kLineData.close, circleEnum.WRAPPED_CIRCLE),
                    'candle_pane',
                )

                const borderLastId = chart.createShape(
                    createCircle(e.dataIndex, e.kLineData.close, circleEnum.BORDER_CIRCLE),
                    'candle_pane',
                )

                const lastIdShape = chart.createShape(
                    createCircle(e.dataIndex, e.kLineData.close, circleEnum.CIRCLE),
                    'candle_pane',
                );

                wrappedCircleLastId.current = wrappedLastId
                circleLastId.current = lastIdShape
                circleBorderLastId.current = borderLastId
            } catch (e) {
                console.log(e)
            }
        })
    }

    const [isLoading, setIsLoading] = useState<boolean>(true)

    const getDate = async (graphtype: typeGraphEnum): Promise<Array<IDataChart>> => {
        let response: AxiosResponse<IChartResponse, any>
        setIsLoading(true)

        if (graphtype === typeGraphEnum.AREA) {
            const ActiveArea = areaPeriodState.find((areaState) => areaState.active)
            response = await ChartService.getAreaData(currentTickerState.ticket as string, ActiveArea?.id as AreaPeriodType)
        } else {
            const ActiveCandle = candleGroupState.find((areaState) => areaState.active)
            response = await ChartService.getCandleData(currentTickerState.ticket as string, ActiveCandle?.id as ChartGroupTypes)
        }
        setIsLoading(false)

        const replacedData = response.data.data.map((candleData) => {
            return {...candleData, timestamp: candleData.timestamp * 1000}
        })
        currentChartData.current = replacedData
        eventRef.current = response.data.eventPoints
        return replacedData
    }

    const createChart = async (graphtype: typeGraphEnum) => {
        const date = await getDate(graphtype)        
        renderChart(date)
    }

    const isFirstRender = useRef<boolean>(true)

    useEffect(() => {
        lastCandleHistory.current = []
        if (currentTickerState.ticket === prevTicker) {
            setPrevTicker(currentTickerState.ticket)
            return
        }

        TextIndicatorNetwork.setNewInstance()

        if (!currentTickerState.ticket) return
        createChart(graphtype)

        return () => {
            try {
                if (!dispose) return
                dispose('simple_chart')
            } catch (e: any) {

            }
        }
    }, [graphtype, currentTickerState.ticket, areaPeriodState, candleGroupState]);

    const useRefTimer = useRef<ReturnType<typeof setTimeout> | null>(null)

    const lastTimerId = useRef<string>('')
    const onLongTouch = () => {
        if (deviceIsIphoneApplication())
            document.location = `${ORIGIN}/taptic`
    }

    const [showEventsModal, setShowEventsModal] = useState<boolean>(false)

    const events = useRef<Array<any>>([])
    const closeEventsModal = () => {
        isOpenEventModalRef.current = false
        setShowEventsModal(false)
        events.current = []
    }

    const {openBackDrop} = useBackDropEvents({
        handleClose: closeEventsModal,
        events: events.current
    })

    const openEventsModal = (eventsArray: Array<boolean>) => {
        events.current = eventsArray
        if(DeviseIsApplicationOnly()) openBackDrop(eventsArray)
        else setShowEventsModal(true)
    }

    const isOpenEventModalRef = useRef<boolean>(false)
    const prevClickedEvents = useRef<number | null>(null)
    const specialTextBounding = useRef<specialTextBoundingType>(null)

    const removeCircle = () => {
        if (!chartObj) return
        if (!circleLastId.current) return
        if (!circleBorderLastId.current) return
        if (!wrappedCircleLastId.current) return

        chartObj.current?.removeShape(circleLastId.current)
        chartObj.current?.removeShape(circleBorderLastId.current)
        chartObj.current?.removeShape(wrappedCircleLastId.current)
    }

    const getClassName = () => {
        if (companyInformation?.hasDataFromStockExchange) {
            return 'visible'
        } else {
            if (companyInformation?.hasDataFromStockExchange === false) {
                return 'd-none'
            } else {
                return 'invisible'
            }
        }
    }

    const MultitachRef = useRef<string | undefined>(undefined)
    const MultitachLineRef = useRef<string | undefined>(undefined)
    return <>
        <div className='card card-custom mt-2 p-4'>

            {
                companyInformation?.hasDataFromStockExchange ? <> </>
                    : <>
                        {
                            companyInformation?.hasDataFromStockExchange === false &&
                            // <NoChart/>
                            <div id ="tradingview"></div> 
                        }
                    </>
            }

            <div className={getClassName()}>

                {
                    !DeviseIsApplicationOnly() && <ChartEventsModal show={showEventsModal} handleClose={closeEventsModal} events={events.current}/>
                }

                {
                    isLoading && <div className={'loaderContainer'}>
                        <div className="spinner spinner-lg spinner-primary mr-15"></div>
                    </div>
                }

                <GraphControls
                    areaPeriodState={areaPeriodState}
                    setGraphtype={setGraphtype}
                    graphtype={graphtype}
                    candleGroupState={candleGroupState}
                    setAreaPeriodState={setAreaPeriodState}
                    setCandleGroupState={setCandleGroupState}
                />
                {
                    graphtype === typeGraphEnum.CANDLE
                        ? <CandleInformation {...priceCandleinfornation} />
                        : <AreaInformation {...priceAreainfornation} />
                }

                <div onMouseLeave={e => {
                    removeCircle()
                }

                }
                     onMouseMove={(e) => {
                         if (window.navigator.userAgent.includes('Android')) return
                         if (window.navigator.userAgent.includes('iPhone')) return
                         if (window.navigator.userAgent.includes('Macintosh'))
                             safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents)
                         else
                             safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents)
                     }}

                     onTouchStart={(e) => {
                         const touches = e.changedTouches
                         e.stopPropagation()
                         if (!useRefTimer.current) {
                             useRefTimer.current = setTimeout(onLongTouch, 800);
                         }
                         if (window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('Macintosh'))
                             safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents, specialTextBounding)
                         else
                             safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents, specialTextBounding)
                     }

                     }
                     onTouchEnd={() => {

                         if (MultitachRef.current) {
                             if (chartObj.current)
                                 chartObj.current.removeShape(MultitachRef.current)
                         }
                         if (MultitachLineRef.current) {
                             if (chartObj.current)
                                 chartObj.current.removeShape(MultitachLineRef.current)
                         }

                         if (useRefTimer.current) {
                             clearTimeout(useRefTimer.current);
                             useRefTimer.current = null;
                         }
                     }}
                     onClick={
                         (e: React.MouseEvent<HTMLDivElement>) => {
                             if (window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('Macintosh'))
                                 safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents)
                             else
                                 safariHandleTooltip(e, elements, lastTimerId, SubsctribleHandler, openEventsModal, isOpenEventModalRef, prevClickedEvents)
                         }
                     } id="simple_chart" style={{height: '400px'}}/>
            </div>
        </div>
    </>


}

export default React.memo(Kline)