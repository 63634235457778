import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {FC, useEffect, useState} from "react";
import EventTime from "../Time/EventTime";
import {useTransition} from "../../hooks/useTransition";
import CommentsModal from "../Modals/CommentsModal";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import useTranslate from "../../hooks/useTranslate";
import { useTranslation } from "react-i18next";

interface IFAAEvent {
    url:string
    license:string,
    vehicle:string,
    location:string,
    isNew:boolean,
    Comment:any
    isCommentOpen?:boolean
    CardInformation:ICompanyInformationCard
    id?:string
    countComments?:number
}

const FAAEvent:FC<IFAAEvent> = (props) => {
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])

    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.TWEETS_OF_POPULAR_PEOPLE,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)

        } else setShow(!show);
    }
    const [isShowTransition, showTransition] = useState<boolean>(false)
    const translateText = () => {
        showTransition(true)
    }
    const backToRussia = () => {
        showTransition(false)
    }
    const {checkForStockPage} = useCheckForPage()
    const {t} = useTranslation()

    return (
        <>

            <span className="font-weight-bolder mb-2">
                <i className="fas fa-rocket text-warning mr-2"></i>
                {t('EVENT_FAA')}
            </span>

            <span className="p-2 event--translated-text">
                <strong>{t('EVENT_FAA_LICENSE_UPD')}</strong>
                <br/>
                {t('EVENT_FAA_VEHICLE')}: {props.vehicle}
                <br/>
                {t('EVENT_FAA_LOCATION')} {props.location}
                <br/>
                {t('EVENT_FAA_DOCUMENT')} <a target={'_blank'} href={props.url}>
                    {props.license}
                </a>
            </span>

            <div className="d-flex align-items-end">
                {
                    props.Comment
                }
            </div>

            { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }


            {/*</div>*/}
        </>
    )
}

export default FAAEvent