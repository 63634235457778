import {useTypedSelector} from "../../hooks/useTypedSelector";
import {DeviceTypeEnum} from "../../models/Subscriptions";
import {Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import { gettOrigin } from "../../Utills/ClearFunctions";
import { useTranslation } from "react-i18next";
import useGetStoreImg from '../../hooks/useGetStoreImg'
import RU_AppStore from '../../image/RU_AppStore.svg'
import EN_AppStore from '../../image/EN_AppStore.png'
import EN_GooglePlay from '../../image/EN_GooglePlay.png'
import RU_GooglePlay from '../../image/RU_GooglePlay.png'
import '../../styles/InstalModal.css'


interface IInstallMobileBrowserModal{
    show:boolean,
    handleClose: () => void,
}

function InstallMobileBrowserModal({show,handleClose}: IInstallMobileBrowserModal) {
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const language = useTypedSelector(state => state.UserReducer.User.settings.language)
    const {getImg} = useGetStoreImg({isModalBrowser:true})
    const [id,setId] = useState<string>('installModal')
    const {t} = useTranslation()

    useEffect(() => {
        if(!show) return
    },[])

    

    useEffect(() => {
        if(!show){
            setTimeout(() => {
                const sheets = document.getElementById(id)
                sheets?.remove()
            },350)
        }
        else{
            let styles = ` 
                    .modal-dialog.modal-lg{
                        position: fixed;
                        bottom: 0;
                    }
                `
            let styleSheet = document.createElement("style")
            styleSheet.innerText = styles

            const id = 'installModal'
            styleSheet.id = id
            document.head.appendChild(styleSheet)

        }
    },[show])
    return <>
{/* show */}
        <Modal size ='lg' show={show} onHide={handleClose}>

            <Modal.Header closeButton>

                <div className="symbol shadow-sm">
                    <img src={`${gettOrigin()}/pwa/icon-96.png?v2`} alt="image"/>
                </div>
                <h2 className="ml-3 mb-0 font-weight-bolder">Tradesense</h2>


            </Modal.Header>

            <Modal.Body>

                <div className="d-flex align-items-center">

                    <div className="d-flex flex-column">

                        {
                            userAgent === DeviceTypeEnum.ANDROID && <span className="font-weight-bold">{t('BANNER_MOBILE_APP',{device:'Android',store:'Google Play'})}</span>
                        }

                        {
                            userAgent === DeviceTypeEnum.APPLE && <span className="font-weight-bold">{t('BANNER_MOBILE_APP',{device:'iPhone',store:'App Store'})}</span>
                        }
                    </div>
                    &nbsp;
                    <div className="d-flex flex-column">
                       {getImg()}
                    </div>      
                </div>

            </Modal.Body>

        </Modal>

    </>
}
export default  InstallMobileBrowserModal