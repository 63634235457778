import {useEffect} from "react";
import '../../styles/Errors.css'
interface IInvalidCodeError{
    text:string
}

export const ErrorInput = ({text}:IInvalidCodeError) => {


    return <div className="invalid-feedback">{text}</div>
}


export const CustomErrorInput = ({text}:IInvalidCodeError) => {
    return <div className="invalidError">{text}</div>
}



export const InvalidCodeError = () => {
    return <div className="invalid-feedback">Код должен содержать 6 цифр.</div>
}