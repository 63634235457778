import { Helmet } from "react-helmet"
import { useLocation } from "react-router-dom"
import { useTypedSelector } from "./useTypedSelector"
import React from "react"
import { useTranslation } from "react-i18next"

export default function useMetaTag(){
    const isAut = useTypedSelector(state => state.UserReducer.isAuth)
    const location = useLocation()
    const CurrentTicker = useTypedSelector(state => state.TickerReducer)
    const {t} = useTranslation()

    const getMetaTags = () => {
        if(!isAut) return <Helmet>
                <meta name="description" content="Сервис анализирует новости, твиты лидеров мнения, отчётность, инсайдерскую торговлю, изменение прогнозов цены акции и сопоставляет их с биржевыми данными, отправляя вам только самые важные уведомления."/>
                <meta name="Keywords" content="новости акций, новости биржи, ценные бумаги, биржа ценных бумаг, купить акции, стоимость акций компаний, купить etf, купить облигации, офз, купить валюты, курс валют, курс доллара, курс евро,
                индивидуальный инвестиционный счет, открыть иис онлайн, брокерский счет, открыть брокерский счет онлайн, онлайн инвестиции, новости компаний, отчетность тесла, отчетность компаний, virgin galactic, акции тесла, акции tesla, тесла акции, акции virgin galactic"/>

                <meta property="og:title" content="Tradesense – моментальные уведомления о ценовых изменениях акций с авто-поиском причин в открытых источниках данных" />
                <meta property="og:description" content="Сервис анализирует новости, твиты лидеров мнения, отчётность, инсайдерскую торговлю, изменение прогнозов цены акции и сопоставляет их с биржевыми данными, отправляя вам только самые важные уведомления." />
                <meta property="og:site_name" content="Tradesense" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://tradesense.me/" />

                <meta name="twitter:title" content="Tradesense – моментальные уведомления о ценовых изменениях акций с авто-поиском причин в открытых источниках данных" />
                <meta name="twitter:description" content="Сервис анализирует новости, твиты лидеров мнения, отчётность, инсайдерскую торговлю, изменение прогнозов цены акции и сопоставляет их с биржевыми данными, отправляя вам только самые важные уведомления." />
                <meta name="twitter:url" content="https://tradesense.me/" />
        
        </Helmet>
    }


    const getEventTitle = () => {
        return t('PAGE_TITLE_EVENTS')
    }
    const getLogInTitle = () => {
        return t('PAGE_TITLE_LOGIN')
    }

    const getTittle = () => {

        const urlPath = location.pathname
        switch(urlPath){
            case '/events':{
                return <Helmet> <title>{getEventTitle()}</title> </Helmet>
            }
            case '/':{
                if(isAut)
                    return <Helmet> <title>{getEventTitle()}</title> </Helmet>
                else 
                    return <Helmet>
                        <title>{getLogInTitle()}</title>
                    </Helmet> 
            }
            case '/stocks':{
                return <Helmet> <title>{t('PAGE_TITLE_COMPANIES')}</title> </Helmet>
            }
            case '/profile':{
                return <Helmet> <title>{t('PAGE_TITLE_PROFILE')}</title> </Helmet>
            }
            case '/finance/subscription':{
                return <Helmet> <title>{t('PAGE_TITLE_SUBSCRIPTION')}</title> </Helmet>
            }
            case '/stocks/SPCE':{
                return <Helmet> <title>{getEventTitle()}</title> </Helmet>
            }
            case '/profile/user':{
                return <Helmet> <title>{t('PAGE_TITLE_EDIT_PROFILE')}</title> </Helmet>
            }
            default: return <Helmet>
                <title>{getLogInTitle()}</title>
            </Helmet>
        }

    }
    return {getMetaTags, getTittle}
}