import EventTime from "../Time/EventTime";
import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {useEffect, useLayoutEffect, useState} from "react";
import {useTransition} from "../../hooks/useTransition";
import CommentsModal from "../Modals/CommentsModal";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import useTranslate from "../../hooks/useTranslate";
import useDate from "../../hooks/useDate";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface IPublication {
    source:string,
    time:string,
    title:string,
    translatedTitle:string,
    text:string,
    Comment:any,
    sourceLink:string,
    fullDate:string,
    translatedText?: string,
    isCommentOpen?:boolean,
    CardInformation:ICompanyInformationCard,
    id?:string
    countComments?:number
    mediumTime?:string | number | null,
    createdAtEvent?:number,
}

function Publication(props:IPublication) {
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent} = UseActions()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isShowTransition, showTransition] = useState<boolean>(false)
    const {showEventBody,showTranslateLink,isTranslate,isTranslateTitle,checkTextForTranslation} = useTranslate(props.text)
    const {getTimeAgo,getMediumTime} = useDate()
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)

    useEffect(() => {
        showTransition(translateEvents)
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])

    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.MEDIA_PUBLICATIONS,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }
    const translateText = () => {
        showTransition(true)
    }
    const backToRussia = () => {
        showTransition(false)
    }

    const getTime = () => {
        const timeAgo = getTimeAgo(props.createdAtEvent)
        if(timeAgo === ''){
            return props.fullDate
        }
        return `(${timeAgo}, ${getMediumTime(props.createdAtEvent as number)})`
    }
    const {t} = useTranslation()

    const {checkForStockPage} = useCheckForPage()

    const showTransitionHandler = (boolvalue:boolean) => (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        showTransition(boolvalue)
    }
    
    return (
        <>
                <span className="font-weight-bolder mb-2">
                    <i className="far fa-newspaper text-success mr-2"></i> {t('EVENT_MEDIA_MENTION')} → {props.source} {getTime()}
                </span>
                <span className="p-2 event--translated-text">

                <span className="font-weight-bolder">
                    {showEventBody(props.translatedTitle,props.title,isShowTransition)}
                </span>
                    {/* {
                        (!isShowTransition && checkTextForTranslation(props.title))
                            ? <span
                                className="font-weight-bolder"
                                dangerouslySetInnerHTML = {{__html: `${ props.title || ''  }` }} />
                            :(<span
                                className="font-weight-bolder"
                                dangerouslySetInnerHTML = {{__html: `${ props.translatedTitle || ''  }` }} /> )
                    } */}
                    &nbsp;


                    {showEventBody(props.translatedText,props.text,isShowTransition)}

                 {/* {
                    (!isShowTransition && checkTextForTranslation(props.text))
                        ? <span dangerouslySetInnerHTML = {{__html: `${ props.text || ''  }` }} />
                        :(<span dangerouslySetInnerHTML = {{__html: `${ props.translatedText || ''  }` }} /> )
                } */}
                </span>
                <div className="d-flex align-items-center">

                    <span className="mt-1 font-size-sm">
                        <a target="_blank"
                           href={props.sourceLink}>{props.source}</a>
                        <span>

                        {
                        showTranslateLink(isShowTransition,props.translatedText, props.text,
                            showTransitionHandler)
                        }
                            {/* {
                                isTranslate && <> | {
                                        (!isShowTransition)
                                            ? <a className="" style={{cursor: "pointer"}}
                                                 onClick={translateText}> {t('EVENT_ORIGINAL')}</a>
                                            : <a className="" style={{cursor: "pointer"}} onClick={backToRussia}>{t('EVENT_TRANSLATE')}
                                            </a>
                                        }
                                </>
                            } */}
                        </span>
                    </span>
                    {   
                        props.Comment
                    }   
                </div>
              
                { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }
               
            {/*</div>*/}
        </>
    )
}

export default Publication