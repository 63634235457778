import i18next from "i18next";
import { useTranslation } from "react-i18next";
import {useTransition} from "../../hooks/useTransition";
import { gettOrigin } from "../../Utills/ClearFunctions";

function TimeLineFreeElement({hiddenCounts}:{hiddenCounts:number}) {
    const {transitionToSubscriptionPage} = useTransition()
    const {t} = useTranslation()

    return (
        <>
            <div className="timeline-item align-items-start mb-4" style={{filter: "blur(3px)"}}>
                <div className="timeline-label font-weight-bolder text-muted font-size-lg">14:55</div>
                <div className="timeline-badge"><i className="far fa-newspaper text-success"></i></div>
                <div className="font-size-md timeline-content pl-3"><b>The Financial Times: </b>{t('LOCKED_TIMELINE_MEDIA')}
                 <a href="https://on.ft.com/3l44a9F" className="font-weight-bolder"
                                       target="_blank">{t('EVENT_SOURCE')}</a></div>
            </div>
            <div className="timeline-item align-items-start mb-4" style={{filter: 'blur(3px)'}}>
                <div className="timeline-label font-weight-bolder text-muted font-size-lg">11:50</div>
                <div className="timeline-badge"><i className="fab fa-twitter text-primary"></i></div>
                <div className="font-size-md timeline-content pl-3"><b>Bloomberg: </b>{t('LOCKED_TIMELINE_TWEET')}
                </div>
            </div>
            <div className="timeline-item align-items-start mb-4" style={{filter: 'blur(3px)'}}>
                <div className="timeline-label font-weight-bolder text-muted font-size-lg">11:21</div>
                <div className="timeline-badge"><i className="fas fa-volume-up text-warning"></i></div>
                <div className="font-size-md timeline-content pl-3"><b>`${i18next.t('EVENT_FINTWEET')}`: </b>
                    {t('LOCKED_TIMELINE_FINTWEET')}
                </div>
            </div>

            <div className="timeline-item align-items-start mb-4">
                <div className="timeline-label font-weight-bolder text-muted font-size-lg"></div>
                <div className="font-size-md timeline-content pl-3">
                    <div className="alert alert-light">
                        <i className="fas fa-info-circle font-size-sm"></i>
                        <b>
                        &nbsp; { t("LOCKED_EVENTS_HIDDEN", {count:hiddenCounts,countString:hiddenCounts.toLocaleString()}) } 
                        {/* событие было скрыто */}
                        </b>
                        <p>
                            {t('LOCKED_EVENTS_24H')}
                        </p>
                        <a data-pjax="0"
                           href={`${gettOrigin()}/finance/subscription`
}                           onClick={transitionToSubscriptionPage}
                           className="btn btn-primary btn-sm font-weight-bolder">{t('LOCKED_TRENDS_COMPANIES_BUTTON')}</a>
                    </div>
                </div>
            </div>

        </>
    )
}

export default TimeLineFreeElement