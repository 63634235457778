import { FC, useCallback } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useTransition } from "../../hooks/useTransition";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { deviceIsAppleApplicationOnly, DeviceIsMobile, DeviceIsMobileBrowser, DeviseIsApplicationOnly, gettOrigin } from "../../Utills/ClearFunctions";
import CompanyListItemLoader from "../loaders/CompanyListItemLoader";

const SubscriptionButtonTransition:FC = () => {
    const {transitionToSubscriptionPage} = useTransition()
    const User = useTypedSelector(state => state.UserReducer.User)
    const { t } = useTranslation();

    const chooseRightText = useCallback(() => {
        const isActiveAppleSubscription = User.subscriptionInfo.isActiveAppleSubscription
        const isActiveGoogleSubscription = User.subscriptionInfo.isActiveGoogleSubscription
        const isMobilePayment = isActiveAppleSubscription || isActiveGoogleSubscription    
            if(!DeviseIsApplicationOnly() || DeviceIsMobileBrowser()){
                
                    if(isMobilePayment){
                        return <>
                            <p className="font-size-sm text-muted mb-2">
                            {/* PROFILE_SUBSCRIPTION_AUTOPAY_ALERT */}
                                {t('PROFILE_SUBSCRIPTION_AUTOPAY_ALERT')}
                            </p>
                        </>
                    }
                    if(!isMobilePayment && User.subscriptionInfo.autoPaymentInfo!== null){
                        return <>
                            <p className="font-size-sm text-muted mb-2">
                                <Trans  i18nKey = 'PROFILE_SUBSCRIPTION_WEB_AUTOPAY_ALERT'>
                                    <a href="/profile/subscription"></a>
                                </Trans>
                            </p>

                            <a href={`${gettOrigin()}/finance/subscription`}
                                className="btn btn-sm btn-primary font-weight-bolder" onClick={transitionToSubscriptionPage}>
                                    {t('PROFILE_SUBSCRIPTION_PROLONG')}
                            </a>
                            
                        </>
                    }
                    else {
                        return <>
                        <p className="font-size-sm text-muted mb-2">
                            {t('PROFILE_SUBSCRIPTION_NO_AUTOPAY_ALERT')}
                            </p>

                            <a href={`${gettOrigin()}/finance/subscription`}
                                className="btn btn-sm btn-primary font-weight-bolder" onClick={transitionToSubscriptionPage}>
                                    {t('PROFILE_SUBSCRIPTION_PROLONG')}
                            </a>


                        </>
                    }
                }
                else{
                
                    if(isMobilePayment)
                        return <>
                            <p className="font-size-sm text-muted mb-2">
                            <Trans  i18nKey = 'PROFILE_SUBSCRIPTION_MOBILE_AUTOPAY_ALERT'>
                                <a href="/manage-subscription">настройках системы.</a>
                            </Trans>
                                {/* Вы можете управлять параметрами своей подписки в <a href="/manage-subscription">настройках системы.</a> */}
                            </p>
                        </>

                    if(!isMobilePayment && User.subscriptionInfo.autoPaymentInfo !== null){
                        return <>
                            <p className="font-size-sm text-muted mb-2">
                                {t('PROFILE_SUBSCRIPTION_WEB_ALERT')}
                                {/* Вы можете управлять параметрами своей подписки на сайте {window.location.hostname} */}
                            </p>
                        </>
                    }
                    else{
                        return <>
                            <p className="font-size-sm text-muted mb-2">
                                {t('PROFILE_SUBSCRIPTION_NO_AUTOPAY_ALERT')}
                                {/* Вы можете продлить подписку заранее, чтобы пользоваться всеми преимуществами сервиса без перерыва. */}
                            </p>

                            <a href={`${gettOrigin()}/finance/subscription`}
                                className="btn btn-sm btn-primary font-weight-bolder" onClick={transitionToSubscriptionPage}>
                                    {t('PROFILE_SUBSCRIPTION_PROLONG')}
                            </a>
                        </>
                    }
                }
        
    },[User])

    return <>  
    
    {/* <p className="font-size-sm text-muted mb-2">
                            Вы можете <Link to="/profile/subscription">управлять</Link> своей подпиской или продлить ее заранее.
    </p> */}
        { chooseRightText() }
     </>
}

export default SubscriptionButtonTransition