import {useTypedSelector} from "./useTypedSelector";
import React, {useEffect, useState} from "react";
import { t } from "i18next";

function useTranslate(text:string){
    const [isTranslate,setIsTranslate] = useState<boolean>(false)
    const [isTranslateTitle, setIsTranslateTitle] = useState<boolean>(false)
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)

    useEffect(() => {
        if(!text) {
            setIsTranslate(false)
            return
        }
        setIsTranslate(true)
    },[])
    const checkForTitleTranslate = (text:string) => {
        if(!text){
            setIsTranslateTitle(false)
            return
        }
        setIsTranslateTitle(translateEvents)
    }

    const checkTextForTranslation = (text:string):boolean => {
        if(!text) return false
        return translateEvents
    }


    const showEventBody = (translatedText:string | undefined,text:string, isShowTransition:boolean) => {
        if(isShowTransition){
            return (<span dangerouslySetInnerHTML = {{__html: `${ translatedText || text || ''  }` }} /> )
        }
        else{
            return <span dangerouslySetInnerHTML = {{__html: `${ text || translatedText || ''  }` }} />
        }
    }

    const showTranslateLink = (isShowTransition:boolean, translatedText:string | undefined,text:string, 
        showTransitionHandler: (boolValue:boolean) => (e:React.MouseEvent<HTMLAnchorElement>) => void) => {
            if( !(translatedText && text)){
                return <></>
            }
            if(isShowTransition){
                return<> | <a className="" style={{cursor: "pointer"}}
                            onClick={showTransitionHandler(false)}> {t('EVENT_ORIGINAL')}</a> </>
            }
            else{
                return <> | <a className="" style={{cursor: "pointer"}}
                            onClick={showTransitionHandler(true)}> {t('EVENT_TRANSLATE')}</a> </>
            }
    }


    return {showEventBody, showTranslateLink, isTranslate, isTranslateTitle, checkTextForTranslation, checkForTitleTranslate}

}
export default  useTranslate