import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useDate from "../../hooks/useDate";
import {useTransition} from "../../hooks/useTransition";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { gettOrigin } from "../../Utills/ClearFunctions";

function SubscriptionExpirationEventPageAlert(){
    const {transitionToSubscriptionPage} = useTransition()
    const User = useTypedSelector(state => state.UserReducer.User)
    const {getTimeIsOver} = useDate()
    const [isShowLocal, setIsShowLocal] = useState<boolean>(false)
    const [optionalDataValue, setOptionalDataValue] = useState<string | null>(null)
    const [timeIsOver,setTimeIsOver] = useState<boolean>(false)
    const [isCloseSub,setIsCloseSub] = useState<boolean>(false)
    const {t} = useTranslation()

    useEffect(() => {
        const {numberOfDay,timeIsCloseOver,timeIsOver} = getTimeIsOver(User.subscriptionInfo.finishedAt)
        setIsCloseSub(timeIsOver)
        if(timeIsCloseOver){
            setIsShowLocal(true)
            setOptionalDataValue(numberOfDay)
        }
        else
            setIsShowLocal(false)
    },[])

    const getProgressBar = (numberOfDay:number) => {
        if(numberOfDay < 2)
            return <div className="progress mt-2" style= {{height: '5px'}} >
                <div className="progress-bar bg-danger" role="progressbar" style={{width: `${(numberOfDay * 100 )/ 5}%`}}></div>
            </div>
        else{
           return  <div className="progress mt-2" style= {{height: '5px'}} >
                <div className="progress-bar bg-warning" role="progressbar" style={{width: `${(numberOfDay * 100 )/ 5}%`}}></div>
        </div>
        }    
    }
    return (
        <>
            {
                isShowLocal && <div className="alert alert-custom alert-light-danger fade show mt-2 font-size-sm" role="alert">
                   ⚠️&nbsp;<div className="alert-text">
                            {
                                !isCloseSub ? // change - 'close'
                                    <>
                                        <b>{t('SUBSCRIPTION_EXPIRED_DAYS',{count:Number(optionalDataValue)})}</b>
                                        {getProgressBar(Number(optionalDataValue))}
                                        <div className="mt-2">
                                            {t('SUBSCRIPTION_BANNER_BEFORE_END1')}
                                        </div>

                                        <div className="mt-2">
                                            {t('SUBSCRIPTION_BANNER_BEFORE_END2')}
                                        </div>
                                    </>
                                : <>
                                    <b>
                                        {t('SUBSCRIPTION_EXPIRED')}
                                    </b>

                                    <div className="mt-2">
                                        {t('SUBSCRIPTION_BANNER_AFTER_END1')}
                                    </div>

                                    <div className="mt-2">
                                        {t('SUBSCRIPTION_BANNER_AFTER_END2')}
                                    </div>
                                  </>
                            }
                         
                        <a href={`${gettOrigin()}/finance/subscription`} onClick={transitionToSubscriptionPage} data-pjax="0"><b>{t('PROFILE_SUBSCRIPTION_PROLONG')}</b></a>
                    </div>
                </div>
            }
            
        </>   
    )
}
export default SubscriptionExpirationEventPageAlert