import useEvent from "../../Strategy/Events/useEvent";
import {useTransition} from "../../hooks/useTransition";
import React, {useMemo} from "react";
import {t} from "i18next";
import {gettOrigin} from "../../Utills/ClearFunctions";

interface CommentsModal {
    show: boolean,
    handleClose: () => void,
    events: Array<any>
}

function useChartModal({ show, handleClose, events }: CommentsModal){
    const { replaceDataEvent } = useEvent()
    const { transitionToSubscriptionPage } = useTransition()
    const elements = useMemo(() => {

        if(!show) return <></>
        if (!events[0]) return <>
            <div className="modal-body pt-2">
                <p className="pt-4">{t('LOCKED_EVENTS_24H')}</p>

                <a href={`${gettOrigin()}/finance/subscription`}
                   className="btn btn-sm btn-primary font-weight-bolder" onClick={transitionToSubscriptionPage}>
                    {t('PROFILE_SUBSCRIPTION_PROLONG')}
                </a>
            </div>
        </>

        return replaceDataEvent({ events }, true)
    }, [show])

  return {elements}
}
export default useChartModal