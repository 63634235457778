// ClosedPurchaise
import { t } from "i18next";
import { FC } from "react";
import { useTransition } from "../../hooks/useTransition";
import '../../styles/ClosedPurchaise.css'

interface IClosedPurchase{
    setShow:(flag:boolean) => void
}

const ClosedPurchaise:FC<IClosedPurchase> = ({setShow}) => {
    const {handleCloseSSubscrible} = useTransition()
    
    return <>
        <div className = {'ClosePurchaiseWrapper'}>
            
            <b className="WaitingPurchaise__text">
                {t('SUBSCRIPTION_SUBSCRIBED')}
            </b>
            
            <button 
                onClick = { () => {
                    setShow(false)
                    handleCloseSSubscrible()
                }}
                className="btn btn-sm btn-primary font-weight-bolder">
                    {t('SUBSCRIPTION_SUBSCRIBED_OK')}
            </button>

        </div>
    </>
}

export default ClosedPurchaise