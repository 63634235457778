import React from "react"
import { Trans, useTranslation } from "react-i18next"
import useGetStoreImg from "../../hooks/useGetStoreImg"
import useTranslate from "../../hooks/useTranslate"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { PlaneTypes } from "../../models/User"
import ListAvdventages from "./ListAvdventages"

const DesktopNotRuSubscription: React.FC = () => {
    const { getImg } = useGetStoreImg({ isModalBrowser: false })
    const { t } = useTranslation()
    const isCrypto = useTypedSelector((state) => state.UserReducer.User.isCrypto);

    const androidClick = () => {
       window.open('https://play.google.com/store/apps/details?id=com.Tradesense.TS&utm_campaign=web_banner_plans')
    }

    const appleClick = () => {
        window.open('https://apps.apple.com/app/apple-store/id1583932120?ct=web_banner_plans')
     }
 

    return <>
        <div className="row mt-n4 ml-n4 justify-content-md-center">
            <div className="card card-custom col-12 col-lg-8 p-3 text-center">
                <div className="row mt-n4 ml-n4 justify-content-md-center">
                    <span className="mt-8 mb-2">
                        <i className="flaticon2-rocket-1 text-primary font-size-h1"></i>
                    </span><b className="mb-4 h3 font-weight-bolder">{t('SUBSCRIPTION_MOBILEONLY_HEADER')}</b>

                    <div className="tab-content"><div className="tab-pane fade active show" id="tab_1" role="tabpanel" aria-labelledby="tab_1"></div></div>
                </div>
                <div className="d-flex text-left justify-content-md-center">
                    <div className="col-lg-6 p-2">
                        <div className="mb-2"><span className="text-primary font-weight-bolder mr-1">✓</span>
                            {
                                !isCrypto
                                    ? <> {t('SUBSCRIPTION_PRO_FEATURE_1')} </>
                                    : <> {t('SUBSCRIPTION_PRO_FEATURE_CRYPTO_1')} </>
                            }
                        </div>
                        <div className="mb-2"><span className="text-primary font-weight-bolder mr-1">✓</span>
                            <Trans i18nKey='SUBSCRIPTION_PRO_FEATURE_2'>
                                <span>
                                    <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                                    <i className="ml-1 fas fa-mobile-alt text-primary fa-1x mr-1" aria-hidden="true"></i>
                                </span>
                            </Trans>

                            {/* <span>
                                <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                                <i className="ml-1 fas fa-mobile-alt text-primary fa-1x mr-1" aria-hidden="true"></i>
                                </span> */}
                        </div>
                        <div className="mb-2"><span className="text-primary font-weight-bolder mr-1">✓</span> {t('SUBSCRIPTION_PRO_FEATURE_3')}</div>
                        <div className="mb-2"><span className="text-primary font-weight-bolder mr-1">✓</span>
                            {
                                !isCrypto
                                    ? <>{t('SUBSCRIPTION_PRO_FEATURE_4')}</>
                                    : <>{t('SUBSCRIPTION_PRO_FEATURE_CRYPTO_4')}</>
                            }
                        </div>
                    </div>
                </div>
                <div className="row text-left justify-content-md-center">
                    <div className="col-lg-6">
                        <label className="option cursor-pointer">
                            <span className="option-control"><span className="radio">
                                <input type="radio" checked={true} name="sku" data-amount="" value="ts_monthly" /><span></span></span></span>
                            <span className="option-label"><span className="option-head"><span className="option-title font-weight-bolder">{t('SUBSCRIPTION_MOBILEONLY_7DAYS')}</span><span className="option-focus" style={{ textAlign: 'end' }}><div>{t('SUBSCRIPTION_MOBILEONLY_PRICE')}</div></span></span></span></label></div></div>

                <div className="row justify-content-md-center mb-4">
                    <div className="col-lg-6 col-12 text-center">
                        <div className="mt-4 mb-4">{t('SUBSCRIPTION_MOBILEONLY_SUBHEADER')}</div>
                            <button onClick = {appleClick} className="btn btn-dark btn-lg btn-block font-weight-bolder font-size-h6 mr-2">
                                <span className="js__subscription-selected">
                                    <i className="fab fa-app-store-ios"></i> Download on the App Store
                                </span>
                            </button>

                            <button onClick={androidClick} className="btn btn-dark btn-lg btn-block font-weight-bolder font-size-h6 mr-2">
                                <span className="js__subscription-selected">
                                    <i className="fab fa-google-play"></i> Get it on Google Play
                                </span>
                            </button>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center text-muted font-size-xs">
                        <div><i className="fas fa-info-circle fa-1x text-warning"></i>  {t('SUBSCRIPTION_MOBILEONLY_HELPER')}  </div>
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default DesktopNotRuSubscription