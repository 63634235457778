
export interface IPageInformation{
    isCryptoPage:boolean
}

export enum PageEnum{
    SET_IS_CRYPTO_PAGE = "SET_IS_CRYPTO_PAGE",
}

export interface ISetIsPageCrypto{
    type:PageEnum.SET_IS_CRYPTO_PAGE,
    payload:boolean
}

export type actionType = ISetIsPageCrypto 