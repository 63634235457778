import React from 'react';

interface AuxProps {
    children: React.ReactNode[];
    type:string
}

function ColorText({children,type}: AuxProps) {
    return (
        <>
            <div className={type}>
                {children}
            </div>
        </>
    )
}

export default ColorText