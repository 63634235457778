import React, {useEffect, useRef, useState} from 'react';
import {Modal} from "react-bootstrap";
import SingleInformationEventCard from "../Cards/SingleInformationEventCard";
import CommentsFooter from "./CommentsFooter";
import '../../styles/CommentStyle.css'
import {useTypedSelector} from "../../hooks/useTypedSelector";
import SingleInformationCardWithoutComments from "../Cards/SingleInformationCardWithoutComments";
import {FilterConstant} from "../../models/FilterEvent";
import useGetEvents from "../../hooks/useGetEvents";
import Event from "../Cards/Event";
import TimeLineDay from "../Time/TimeLineTimeDay";
import useComment from "../../hooks/useComment";
import Comment from "../Comments/Comment";
import { useTranslation } from 'react-i18next';

interface CommentsModal {
    show: boolean,
    handleClose: () => void,
    type?:FilterConstant,
    data?:any
}

function CommentsModal({show, handleClose,type,data}: CommentsModal) {

    const Card = useGetEvents(type,data)
    const [eventId,setEventId] = useState<string | null>(data.id)
    const {
        getCommentsData,
        commentData,
        deleteComment,
        likeComment,
        addComment,
        replyComment,
        replyObj
    } = useComment()

    const inputRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement)
    const [text,setText] = useState('')
    const [numberOfComments, setNumberOfComments] = useState<number | null>(null)
    const [commentsIsUpload, setCommentsIsEUpload] = useState<boolean>(false)


    const Comments = commentData.map((comment) => {
        return <Comment {...{...comment, likeComment, deleteComment, replyComment}  }/>
    })

    useEffect(() => {
        if(Object.values(inputRef.current).length === 0 ) return
        setText(replyObj.replyName)
        inputRef.current.focus()
    },[replyObj])


    useEffect(() => {
        if(show) {
            getCommentsData(data.id).then((commentList: Array<any>) => {
            })
        }
    },[show])

    const {t} = useTranslation()


    return (
        <Modal size='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">{t('COMMENTS_MODAL_HEADER')}</h5>
            </Modal.Header>

            <Modal.Body>

                <div className="font-weight-bolder text-muted font-size-md mb-2">
                    {
                        data && <>
                            {data.time}
                        </>
                    }
                </div>


                <Event  eventId={'1'} canEditEvents = {false}>
                    {/*@ts-ignore*/}
                    {Card}
                </Event>
                <div className="font-size-sm text-muted my-3">{t('COMMENTS_NOT_ADVICE')}</div>
                <hr style={{border: '0px solid rgba(0, 0, 0, 0.1)'}}/>

                {
                    Comments.length !== 0
                        ? <> { Comments } </>
                        : <h4 className="font-size-h4 commentSection">
                            {t('COMMENTS_NO_COMMENTS')}
                          </h4>
                }
                
                <div className="comments"></div>
                
            </Modal.Body>


            <Modal.Footer>
                <CommentsFooter
                    ref={inputRef}
                    text={text}
                    setText={setText}
                    eventId = {eventId}
                    addComment = {addComment}
                />
            </Modal.Footer>
        </Modal>
    );
}

export default CommentsModal;

