import React, { useEffect, useRef, useState } from "react";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { SUBSCRIBE } from "../../../models/Subscriptions";
import { gettOrigin } from "../../../Utills/ClearFunctions";
import BackImage from '../../Image/BackImage'
interface ICompanyName {
    id: string | undefined
}

interface ICurrentTickerSharedData {
    logo: string,
    brand: string,
}

export const CompanyName = React.memo(({ id }: ICompanyName) => {
    const CurrentTicker = useTypedSelector(state => state.CompanyReducer)
    const applicationMessageData = useTypedSelector(state => state.EventReducer.applicationMessageData)
    const CurrentTickerSharedData = useRef<ICurrentTickerSharedData>({ logo: '', brand: '' })

    if (!CurrentTicker.logo) {
        //@ts-ignore
        const sharedObj = window._sharedData

        if (!sharedObj.stock) {
            CurrentTickerSharedData.current.brand = ''
        }
        else {
            CurrentTickerSharedData.current.brand = sharedObj.stock.brand
        }
    }
    const tickerFilter = (id:string | undefined) => {
        if(id === SUBSCRIBE) return 'AAPL'
        else return id
    }

    return <div className="topbar-item ticketElement">

        {
            window.location.pathname.includes('read-news')
                ? <>
                    <span className="font-weight-bolder font-size-lg">{applicationMessageData.companyName} </span>
                    <span className="text-muted font-weight-bolder font-size-sm">{applicationMessageData.ticker}</span>
                </>
                : <>
                    <span className="font-weight-bolder font-size-lg">{
                        CurrentTicker.brand
                            ? CurrentTicker.brand
                            : CurrentTickerSharedData.current.brand
                    } </span>
                    <span className="text-muted font-weight-bolder font-size-sm">{`${ tickerFilter(id) }`}</span>
                </>
        }

    </div>
})
interface IStockPageBackAndLogo {
    handleBackButton: () => void
}

export const StockPageLogo = React.memo(({ handleBackButton }: IStockPageBackAndLogo) => {
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const CurrentTicker = useTypedSelector(state => state.CompanyReducer)
    const TickerReducer = useTypedSelector(state => state.TickerReducer)
    const applicationMessageData = useTypedSelector(state => state.EventReducer.applicationMessageData)
    const CurrentTickerSharedData = useRef<ICurrentTickerSharedData>({ logo: '', brand: '' })
    if (!CurrentTicker.logo) {
        //@ts-ignore
        const sharedObj = window._sharedData
        if (!sharedObj.stock) {
            CurrentTickerSharedData.current.logo = ''
        }
        else {
            CurrentTickerSharedData.current.logo = sharedObj.stock.logo
        }
    }

    const insertSrc = () => {
        if (window.location.pathname.includes('read-news')) {
            return applicationMessageData.url
        } else {
            if (!CurrentTicker.logo) {
                return CurrentTickerSharedData.current.logo
            } else {
                return CurrentTicker.logo
            }
        }
    }

    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                {isApplication
                    ? <a href="javascript:window.location.href = '#close'">
                        <div className="symbol symbol-30 symbol-circle d-flex align-items-center topbarCompanyIcon">
                        <BackImage 
                                src = {insertSrc()}
                            />
                        </div>
                    </a>
                    : <a onClick={handleBackButton} href={`${gettOrigin()}`} >
                        <div className="symbol symbol-30 symbol-circle d-flex align-items-center topbarCompanyIcon">
                            <BackImage 
                                src = {window.location.pathname.includes('read-news')
                                ? applicationMessageData.url
                                : CurrentTicker.logo}
                            
                            />
                        </div>
                    </a>
                }
            </div>

        </>
    )
})

export const StockPageBackButton = React.memo(({ handleBackButton }: IStockPageBackAndLogo) => {
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    return (
        <>
            <div onClick={(e) => e.stopPropagation()}>
                {isApplication
                    ? <a href="javascript:window.location.href = '#close'" className='svgBackContainer'>
                        <i className="fas fa-chevron-left text-primary mr-4 d-block h-auto svgBack"></i>
                    </a>
                    : <a onClick={handleBackButton} href={`${gettOrigin()}`} >
                        <div style={{ cursor: "pointer" }} className='svgBackContainer'>
                            <i className="fas fa-chevron-left text-primary mr-4 d-block h-auto svgBack"></i>
                        </div>
                    </a>
                }
            </div>
        </>
    )
})
