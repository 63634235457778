interface IGiftBudget{
    percent:number
}
export function giftBudget({percent}:IGiftBudget) {
    return  <span className="ml-1 label label-light-muted font-weight-bolder label-pill label-inline">
                                                <i className="fas fa-gift fa-1x mr-2 text-primary"
                                                   aria-hidden="true"> </i>-{percent}%
           </span>
}

interface ISuccessBudget{
    text:string
}

export function successBudget({text}:ISuccessBudget){
    return (
        <span className="ml-1 label label-light-success label-pill
        label-inline font-weight-bolder">{text}</span>
    )
}