import React, {ChangeEvent, useEffect, useState} from "react";
import ReactTooltip from "react-tooltip";
import '../../styles/NotificatinSwitch.css'
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import { setSwitchRowClass } from "../Modals/NotificationBody";


interface IListNotification {
    name: string
    icon: string
    tooltip: string
    checkTelegram: boolean,
    checkApp: boolean,
    replaceSwitchTelegram: (name: string) => void,
    replaceSwitchApp: (name: string) => void,
    isMobileSwitchesNull:boolean,
    isTelgramSwitchesNull:boolean
}

function NotificationSwitch({
                                name,
                                icon,
                                tooltip,
                                checkApp,
                                checkTelegram,
                                isMobileSwitchesNull,
                                replaceSwitchApp,
                                replaceSwitchTelegram,
                                isTelgramSwitchesNull
                            }: IListNotification) {

    const changeTelegramHandler = (name: string) => (e: any) => replaceSwitchTelegram(name)
    const changeAppHandler = (name: string) => (e: any) => replaceSwitchApp(name)
    const [isDesktop,setDesktop] = useState<boolean>(false)


    useEffect(() => {
        if(window.screen.availWidth < 991.98) setDesktop(false)
        else setDesktop(true)
    },[])

    const renderTooltip = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">{tooltip}</div>
            </Tooltip>
        );


    return (
        <div className="row py-2">
            <div className= {`${setSwitchRowClass(isMobileSwitchesNull,isTelgramSwitchesNull)} px-2`} >
                <i className={icon + ' mr-1 ' + 'text-primary fa-fw mr-0'} aria-hidden="true"></i>
                {name}
                <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <i className="fas fa-info-circle fa-1x text-muted ml-1" data-toggle="tooltip"></i>
                </OverlayTrigger>

            </div>
            <div className="col-3 text-center d-flex justify-content-center">

                {
                    !isTelgramSwitchesNull && <span className="switch switch-sm">
                        <label><input onChange={changeTelegramHandler(name)} checked={checkTelegram}
                                    className="js__notification-types" type="checkbox" data-source="1"
                                    data-type="1"
                                    name=""/><span></span></label>
                    </span>
                }
                
            </div>
            


            {
                !isMobileSwitchesNull  && <div className="col-3 text-center d-flex justify-content-center">
                <span className="switch switch-sm">
                    <label><input onChange={changeAppHandler(name)} checked={checkApp} className="js__notification-types"
                                  type="checkbox" data-source="2"
                                  data-type="1"
                                  name=""/><span></span></label>
                </span>
            </div>
            }
            



        </div>
    )
}

export default React.memo(NotificationSwitch)