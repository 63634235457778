import React, {useState} from "react";
import {FilterConstant, IListStruct} from "../../models/FilterEvent";
import DropDownElement from "./DropDownElement";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {UseActions} from "../../hooks/useActions";
import {useNavigate} from "react-router-dom";
import NotificationModal from "../Modals/NotificationModal";
import {useTransition} from "../../hooks/useTransition";
import {DeviceIsMobile, gettOrigin} from "../../Utills/ClearFunctions";
import {RootState} from "../../redux";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';


function ListDropDown() {
    const navigate = useNavigate()
    const listStruct = useTypedSelector(state => state.Filter.filters)
    const {setListStruct} = UseActions()
    const {transitionToNotification} = useTransition()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const tickerElementsIsLoading = useTypedSelector(state => state.EventReducer.tickerElementsIsLoading)
    const lastElementsIsLoading = useTypedSelector(state => state.EventReducer.lastElementsIsLoading)
    const favouritesElementIsLoading = useTypedSelector(state => state.FavouritesReducer.favouritesElementIsLoading)

    const loadingAllEvents = useTypedSelector(state => state.AllEventsReducer.loadingAllEvents)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const selectAllEvents = ():Array<IListStruct> => {
        return listStruct.map(list => {
           return {...list,check:true}
        })
    }
    const deleteAllEvents = () => {
        return listStruct.map(list => {
            return {...list,check:false}
        })
    }

//refactor
    const ClickToItemList = (id: number) => () => {
        if(loadingAllEvents || tickerElementsIsLoading || lastElementsIsLoading || favouritesElementIsLoading) return
        let newStruct:Array<IListStruct> = []
        if (id === FilterConstant.ALL_EVENTS ){ newStruct = selectAllEvents()}
        else if (id === FilterConstant.DELETE_ALL){ newStruct = deleteAllEvents()}
        if(newStruct.length === 0) {
            for (let i = 0; i < listStruct.length; i++) {
                const list = listStruct[i]
                if (id === list.id) newStruct.push({...list, check: !list.check})
                else newStruct.push({...list})
            }
        }

        let filterStruct = newStruct.filter(list => {
            const flag = (list.id !== FilterConstant.ALL_EVENTS && list.id !== FilterConstant.DELETE_ALL)
            return flag
        })

        const allTrue = filterStruct.every(list => list.check)

        const clearAllElement = {
            text: t('FEED_FILTER_CLEAR'),
            id: FilterConstant.DELETE_ALL,
            check: false,
            classElement: 'fa fa-times'
        }
        const checkAllElements = {
            text: t('FEED_FILTER_SELECT_ALL'),
            id: FilterConstant.ALL_EVENTS,
            check: false,
            classElement: 'fa fa-check'
        }

        if (allTrue) setListStruct([...filterStruct, ...[clearAllElement]])
        else setListStruct([...filterStruct, ...[checkAllElements]])

    }


    const NotificationHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        if (DeviceIsMobile(userAgent))
          transitionToNotification()
        else
            handleShow()
        e.preventDefault()
    }


    const DropDownElements = listStruct.map(list => <DropDownElement
            key={uuidv4()}
            id={list.id}
            text={list.text}
            check={list.check}
            classElement={list.classElement}
            ClickToItemList={ClickToItemList}
        />
    )
    const {t} = useTranslation()

    return (
        <>
            <input className="d-none" type="checkbox" name="types[]" value="0"/>
            <div className="font-weight-bolder px-5 py-2">{t('FEED_FILTER_HEADER')}</div>

            {
                DropDownElements
            }

            <div className="dropdown-divider"></div>
            <a href={`${gettOrigin()}/profile?action=show-notification-settings`}
               onClick={NotificationHandler}
               className="dropdown-item checkbox text-primary py-2">
                <i className="far fa-bell fa-fw mr-3 text-primary"></i> {t('FEED_FILTER_NOTIFICATIONS')} 
            </a>
            <NotificationModal show={show} handleClose={handleClose}/>
        </>
    )
}

export default ListDropDown