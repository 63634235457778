import {useTypedSelector} from "./useTypedSelector";
import {useEffect, useState} from "react";
import UserService from "../Services/UserService";

function useGetCompany(){
    const CurrentTicker = useTypedSelector(state => state.TickerReducer)
    const CurrentCompany = useTypedSelector(state => state.CompanyReducer)
    const [prevTicker,setPrevTicker] = useState(CurrentTicker.ticket)

    const [companyInformation,setCompanyInformation] = useState<any>()

    const getInformation = async (ticker:string) => {
        const response = await UserService.getCompanyDescription(ticker)
        setCompanyInformation(response.data?.company)
    }
    useEffect(() => {
        if(CurrentCompany.ticker === '') return
        getInformation(CurrentCompany.ticker)
    },[CurrentCompany])

    return {companyInformation}
}
export default useGetCompany