export interface IAuth{
    isAuth:boolean
}

export enum AuthEnum{
    SET_ISAUTH='SET_ISAUTH'
}

export interface  SetUserIsAuth{
    type: AuthEnum.SET_ISAUTH,
    payload: boolean,
}

export type actionType = SetUserIsAuth