import React, { useContext, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useTransition } from "../../hooks/useTransition";
import { gettOrigin } from "../../Utills/ClearFunctions";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const FreeDaysBody: React.FC = () => {
    const { transitionToSubscriptionPage } = useTransition()
    const { t } = useTranslation()
    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto)
    const trialFeatureSttring = isCrypto ? '_CRYPTO' : ''

    return <>
            <i className="fas fa-bolt fa-1x text-white mr-2"></i>
            <b>
                {t('TRIAL_BANNER_HEADER')}
            </b>
            <div className="mt-2">
                <span className="text-white font-weight-bolder mr-1">✓</span>
                
                {t('TRIAL_FEATURE' + trialFeatureSttring + '_1')}
            </div>
            <div className="mt-2">
                <span className="text-white font-weight-bolder mr-1">✓</span>
                {t('TRIAL_FEATURE' + '_2')}
            </div>
            <div className="mt-2 mb-1">
                <span className="text-white font-weight-bolder mr-1">✓</span>
                {t('TRIAL_FEATURE' + trialFeatureSttring + '_3')}
            </div>
            <a href={`${gettOrigin()}/finance/subscription`}
                onClick={transitionToSubscriptionPage}
                data-pjax="0"
                className="btn btn-light-primary btn-hover-warning btn-sm mt-2"><b>{t('TRIAL_TRY_FOR_FREE')}</b></a>
    </>
}
export default FreeDaysBody