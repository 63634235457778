/* eslint-disable */
import Timeline from "../components/TimeLine/Timeline";
import "../styles/TickerInformation.css";
import React, { useContext, useEffect } from "react";
import { isFreePlane } from "../Utills/ClearFunctions";
import FreeDaysAlert from "../components/Free/FreeDaysAlert";
import { useTypedSelector } from "../hooks/useTypedSelector";
import SubscriptionExpirationEventPageAlert from "../components/Free/SubscriptionExpirationEventPageAlert";
import Kline from "../components/Kline";
import { useParams} from "react-router-dom";
import { TickerContext } from "../Context";
import useTradingView from "../hooks/useTradingView";

function TickerInformation() {
  const User = useTypedSelector((state) => state.UserReducer.User);
  const Plane = useTypedSelector((state) => state.UserReducer.User.Plane);
  const {id} = useParams()
  const {isCoinsPage, companyInformation} = useContext(TickerContext)
  const {addTraidingViewWidgetChart} = useTradingView()

  useEffect(() => {
    if(companyInformation === undefined ) return
    if(!companyInformation.hasDataFromStockExchange || id === 'META'){
      addTraidingViewWidgetChart(id,isCoinsPage.isCrypto)
    }      
  },[isCoinsPage,id,companyInformation])

  return (
    <>
      <div className="graphContainer">
        <div className="graphContainer__graph">
          <SubscriptionExpirationEventPageAlert />
          {isFreePlane(Plane, User?.subscriptionInfo?.finishedAt) &&
            User?.subscriptionInfo?.isAvailableTrial && <FreeDaysAlert />}
          <div style={{minHeight:'400px'}}>
            {
              companyInformation && <>
                  {
                    id === 'META' || !companyInformation.hasDataFromStockExchange ? <> 
                      <div className="tradingview-widget-container mt-4" >
                        <div id ="tradingview"></div> 
                      </div>
                    </>
                    :  <Kline/> 
                  }
              </>
            }

          </div>
          <div></div>
        </div>
        <div className="graphContainer__timeline">
          <Timeline />
        </div>
      </div>
    </>
  );
}

export default TickerInformation;
