import React, { useEffect } from "react"
export interface IImage{
    src:string
}
function CompanyInformationImage({src}:IImage){

    return <div
        className="symbol symbol-20 symbol-lg-20 symbol-circle"
         style = {{
            backgroundImage:`url(${src})`,
            backgroundSize: 'cover',
            width:'100%',
            height:'100%',
            }}>
    </div>
    
}
export default CompanyInformationImage