import {actionType, allEventEnum, IAllEvent} from './types'
import {IPeriod} from "../UserEvent/types";

const initialState: IAllEvent = {
    Elements:[],
    numberNewEvent:0,
    NewElements:[],
    prevFilter:[],
    period:{} as IPeriod,
    loadingAllEvents:false
}

export default function AllEventsReducer( state = initialState, action:actionType ) :IAllEvent {
    
    switch (action.type){
        case allEventEnum.SET_LOADING_ALL_EVENTS:
            return {...state, loadingAllEvents:action.payload}
        case allEventEnum.SET_ALL_PERIOD:
            return {...state, period:action.payload}
        case allEventEnum.DELETE_EVENT:
            const deletedElements = state.Elements.filter((Element) => {
                return action.payload != Element.key
            })
            return {
                ...state,
                Elements:deletedElements,
            }
        case allEventEnum.SET_ALL_RPEV_FILTER:
            return {...state, prevFilter:action.payload}
        case allEventEnum.SET_FULL_EVENTS:
            return {
                ...state,
                Elements:[...action.payload],
                NewElements:[],
                numberNewEvent:0
            }
        case allEventEnum.SET_ALL_EVENTS:
            const prevKey = state.Elements.map(Element => Element.key)
            const newElements = action.payload.filter( (newElement) => {
                return !prevKey.includes(newElement.key)                
            })

            if(newElements.length === 0 ) {
                state.numberNewEvent = 0
                return {...state}
            }

            state.NewElements = [...newElements]
            state.numberNewEvent = newElements.length
            return {...state}
        case allEventEnum.SET_NUMBER_NEW_EVENT:
            return {
                ...state, 
                numberNewEvent:action.payload
            }
        case allEventEnum.SET_NEW_ELEMENTS:
            return { ...state,Elements:[...state.NewElements,...state.Elements],NewElements:[], numberNewEvent:0}    
        default: return state
    }

}
