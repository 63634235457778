import { useTranslation } from "react-i18next";
import {useTransition} from "../../hooks/useTransition";
import { gettOrigin } from "../../Utills/ClearFunctions";

function NotificationFreeText() {
    const {transitionToSubscriptionPage} = useTransition()
    const {t} =useTranslation()

    return (
        <span className="mt-2">
            <i className="fa fa-lock"></i>
            <a href={`${gettOrigin()}/finance/subscription`}
               onClick={transitionToSubscriptionPage}
               className="ml-2">
                {t('PROFILE_ONLY_WITH_PRO')}
            </a>

        </span>
    )
}
export default NotificationFreeText