import {Modal} from "react-bootstrap";
import React from "react";
import useTelegram from "../../hooks/useTelegram";
import {t} from "i18next";
import {Trans} from "react-i18next";

function AlertModalBody() {
    const {SetShowTelegram, installTelegram} = useTelegram()
    return (
        <div className="modal-body">
            <h3 className="font-weight-bolder">{t('TG_BANNER_NEWBOT_TITLE')}</h3>
            <p className="card card-custom text-dark-75 bg-light-warning p-4">
                {t('TG_BANNER_NEWBOT_MESSAGE')}
            </p>
            <div className="mb-5 text-center card card-custom shadow-sm p-10 bg-gray-800 text-white">
                <h2 className="font-weight-bolder mb-4">{t('TG_BANNER_BOT')}</h2>
                <a target="_blank" onClick={installTelegram}
                   className="btn btn-primary font-size-lg font-weight-bolder px-8 py-5 shadow-lg"
                >
                    <i className="fab fa-telegram" aria-hidden="true"></i> {t('TG_BANNER_BOT_TELEGRAM')}
                </a>
            </div>

        </div>
    )
}

export default AlertModalBody