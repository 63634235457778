import React, { FC, useEffect, useState } from "react";
import { FilterConstant } from "../../models/FilterEvent";
import CommentCard from "../Cards/CommentCard";
import CommentsModal from "../Modals/CommentsModal";

interface ICommentWrappper{
    type:number | undefined,
    isCommentOpen:boolean,
    countComments?:number,
    data:any
    id?:string
}

const CommentWrappper:FC<ICommentWrappper> = ({data, countComments,isCommentOpen,type,id}) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    // const handleShow = () => setShow(true);

    return <>

        <CommentsModal
            show={show} 
            handleClose={handleClose} 
            type={type} 
            data={{...{...data,isCommentOpen:false}}}
        />

        <CommentCard 
            countComments = {countComments} 
            isCommentOpen={isCommentOpen || isCommentOpen} 
            callback={setShow} 
            show={show} 
            id={id} 
        />

    </>
}

export default React.memo(CommentWrappper)