import { debounce } from "lodash"
import { useEffect, useMemo, useState } from "react"
import UserService from "../Services/UserService"
import { UseActions } from "./useActions"
import { useTypedSelector } from "./useTypedSelector"

function useSaveProfileHook(){
    const User = useTypedSelector(state => state.UserReducer.User)
    const isChanged = useTypedSelector(state => state.Profile.isChanged)
    const [changeUserNameRequesting,setChangeUserNameRequesting] = useState<boolean>(false)
    const [UserName, setUserName] = useState<string>(User.profile.username)
    const [userNameError,setUserNameError] = useState<{isError:boolean,TextError:string}>({isError:false,TextError:''})
    const [changeNameRequesting,setChangeNameRequesting] = useState<boolean>(false)
    const [Name, setName] = useState<string>(User.profile.fullName)
    const [NameError,setNameError] = useState<{isError:boolean,TextError:string}>({isError:false,TextError:''})
    const [About, setAbout] = useState<string>(User.profile.about)
    const [aboutError,setAboutError] = useState<{isError:boolean,TextError:string}>({isError:false,TextError:''})        
    const {
        saveProfileRequest,
        setUserProflie,
        setUserSettings,
        setDarkTheme,
        
    } = UseActions()

    const {
        setAboutProfile,
        setProfileUser,
        setisChangedProfile,
        setUserNameProfile,
        setFullNameProfile
    } = UseActions()

    const changeName = async (e: React.ChangeEvent<HTMLInputElement>) => {
        checkForChange()
        setFullNameProfile(e.target.value)
        setName(e.target.value)
    }


    const changeUserName = async (e: React.ChangeEvent<HTMLInputElement>) => {
        checkForChange()
        setUserNameProfile(e.target.value)
        setUserName(e.target.value)
    }


    useEffect(() => {
        setProfileUser({
            about:About,
            fullName:Name,
            username:UserName,
        })
    },[])

    const checkForChange = () => {
        if(!isChanged) setisChangedProfile(true)
    }

    const changeAbout = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        checkForChange()
        setAboutProfile(e.target.value)
        setAbout(e.target.value)     
    }

    const debouncedChangeUserName = useMemo(() => debounce(changeUserName, 300), []);
    const debouncedChangeAbout = useMemo(() => debounce(changeAbout, 300), []);
    const debouncedChangeName = useMemo(() => debounce(changeName, 300), []);

    return {
        debouncedChangeUserName,
        debouncedChangeAbout,
        debouncedChangeName,
        changeUserNameRequesting,
        UserName,
        userNameError,
        changeNameRequesting,
        Name,
        NameError,
        About,
        aboutError,
        phone:User.phone,
    }
    
}

export default useSaveProfileHook


