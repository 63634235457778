import i18next, { t } from "i18next"
import { useEffect, useState } from "react"
import { UseActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { IProfileTranslateObj } from "../../page/Profile"
import UserService from "../../Services/UserService"
import useFilters from '../../hooks/useFilters'
function ChangeLanguage() {
    const language = useTypedSelector(state => state.UserReducer.User?.settings?.language)
    const region = useTypedSelector(state => state.UserReducer.User?.settings?.region)
    const {setFullEvents} = UseActions()
    const {setLists} = useFilters()
    const { setUserSettings,setFullTrendsEvents,setFullFavoutitesEvents } = UseActions()
    
    const changeLanguage = async (e: any) => {
        const ProfileNetworkObj: Partial<IProfileTranslateObj> = {
            language: e.target.value
        }
        const response = UserService.saveProfile(ProfileNetworkObj)
        setFullEvents([])
        setFullTrendsEvents([])
        setFullFavoutitesEvents([])
        i18next.changeLanguage(e.target.value)
        setLists()
        setUserSettings(ProfileNetworkObj)
    }
    const [isShow, setIsShow] = useState<boolean>(false)


    useEffect(() => {
        const notShowRegion = ['RU', 'KZ', 'BY']
        const notShowLanguage = ['ru']
       
        
        if (
            notShowRegion.find((showRegion) => showRegion === region) ||
            notShowLanguage.find((showRegion) => showRegion === language)
        )
            setIsShow(true)
        else
            setIsShow(false)    
    }, [language, region])


    return <>
    {
        isShow && <div className="form-group row">
            <label className="col-lg-2 col-form-label text-lg-right">
                {t('PROFILE_LANGUAGE')}
            </label>
            <div className="col-lg-6">
                <select onChange={changeLanguage} value={language} id="user-investment_app"
                    className="form-control form-control-lg form-control-solid"
                    name="User[investment_app]" style={{ appearance: "auto" }}>
                    <option value={'ru'} >Русский</option>
                    <option value={'en'} >English</option>
                </select>
            </div>
        </div>
    }
        
    </>
}
export default ChangeLanguage