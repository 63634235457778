import React, {useLayoutEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { UseActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import UserMainInfo from '../../page/UserMainInfo';
import UserMainInfoModal from '../../page/UserMainInfoModal';
import {Alert, themeAlertEnum} from "../Alerts";

interface IGiftModal{
    show: boolean,
    handleClose: () => void
}

function SaveUserModal({show,handleClose}: IGiftModal)  {
    const {saveProfile,setSuccess} = UseActions()
    const Profile = useTypedSelector(state => state.Profile)
    useLayoutEffect(() => {
        setSuccess(false)
    },[show])
    const {t} = useTranslation()

    return (
        <Modal show={show} onHide={handleClose}>
            
            <Modal.Header closeButton>
                <Modal.Title> {t('PROFILE_EDIT_HEADER')} </Modal.Title>
            </Modal.Header>

            <Modal.Body>
            <div className="row">
                <div className="card card-custom col-12 p-6">
                    <Alert
                        theme = {themeAlertEnum.Success}
                        close = {() => {setSuccess(false)}}
                        isOpen = {Profile.isSuccess}
                        text = {t('PROFILE_SAVED')}>
                        <span>{t('PROFILE_SAVED')}</span>
                    </Alert>

                    <UserMainInfoModal/>
                    <button
                            onClick = {() => {
                                saveProfile(Profile)
                            }}
                            type="button"
                            className="btn btn-primary font-weight-bolder font-size-h6 px-8 
                            py-2 my-2 mr-3 fav_next_button favoritesButton"
                        >
                            {t('PROFILE_SAVE_CHANGES')}
                    </button>
                </div>
            </div>
            </Modal.Body>
        </Modal>
    );
}

export default SaveUserModal;