import { t } from "i18next"

export function SubscriptionError(){
    return <>
        <div className="card card-custom col-lg-6 mb-lg-4 bg-danger text-white">
            <div className="card-body py-6 px-2"><p className="mb-2"><b>⚠️ {t('SUBSCRIPTION_AUTOPAY_FAILED')}</b></p>
                <p>
                    {t('SUBSCRIPTION_AUTOPAY_MANAGE')}
                </p>
            </div>
        </div>
    </>
}
export default SubscriptionError