import React, { useCallback, useEffect, useMemo } from 'react';
import useCompanyCard, { ICompanyCard } from '../../../hooks/useCompanyCard';
import { ORIGIN } from '../../../models/Domen';
import { getNumberSign, getPercent } from '../../../Utills/ClearFunctions';

interface IMarketInfo {
    capitalization: string
}

type DailyResultCardType = ICompanyCard & {
    marketInfo?: IMarketInfo
}
const DailyResultCard: React.FC<DailyResultCardType> = (props) => {
    const {
        handleTransitionToCompanyTicker,
        getPrice
    } = useCompanyCard()

    const priceOutput = useMemo(() => {
        return <div style={{ display: 'flex', alignItems: 'center' }}>
            <div className="w-lg-100px w-100px">
                <span className="font-size-h5-lg font-size-lg">
                    <div className="">
                        <span className="font-size-lg font-weight-bolder d-block text-right"> {getPrice(props.priceInfo.price, props.currency)} </span>
                    </div>
                </span>
            </div>
        </div>
    }, [props])

    const outputPercent = (percent: number | boolean) => {
        const prefix = getNumberSign(percent as number)
        return prefix + `${percent.toLocaleString()}%`
    }

    const getPercentJsx = useCallback((percent) => {
        return <div className = "w-lg-100px w-100px">
                <span className="font-size-lg font-weight-bolder d-block  text-right">{getPrice(props.priceInfo.price, props.currency)}</span>
                <span className="d-block font-size-sm text-right">{outputPercent(percent)}</span>
            </div>
    }, [props])
    const getPercentForJsx = () => {
        if (!props.priceInfo.oldPrice) return priceOutput
        const [percent, isUp] = getPercent(props.priceInfo.price, props.priceInfo.oldPrice)
        if (percent === 0) return priceOutput

        if (isUp) return <div className="text-success">
            {getPercentJsx(percent)}
            {/* <span className="font-weight-bolder d-block  text-right">{getPrice(props.priceInfo.price, props.currency)}</span>
            <span className="d-block font-size-sm text-right">{outputPercent(percent)}</span> */}
        </div>
        else return <div className="text-danger">
            {getPercentJsx(percent)}
            {/* <span className="font-weight-bolder d-block  text-right">{getPrice(props.priceInfo.price, props.currency)}</span>
            <span className="d-block font-size-sm text-right">{outputPercent(percent)}</span> */}
        </div>
    }

    const clickToStocks = (e: React.MouseEvent<HTMLAnchorElement> | React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        handleTransitionToCompanyTicker(props)
    }

    return <div onClick={clickToStocks} >
        <div className="d-flex align-items-center mb-1">
            <div className="mr-3 mr-lg-4 flex-shrink-0">
                <a href={`${ORIGIN + '/stocks/' + props.ticker}`} className="symbol symbol-40 symbol-circle" >
                    <div
                        className="symbol StockImage symbol-30 symbol-circle"
                        style={{ backgroundImage: `url(${props.imgSrc})`, backgroundSize: 'cover', backgroundColor: 'gray', width: '30px', height: '30px' }}>
                    </div>
                </a>
            </div>
            <div className="d-flex flex-column flex-grow-1">
                <a
                    href={`${ORIGIN + '/stocks/' + props.ticker}`}
                    className="text-dark text-hover-primary fs-6 font-weight-bolder">
                    {props.brand}
                </a>
                <span className="text-muted font-weight-bold font-size-md">{props.ticker}</span>
            </div>
            {
                props.marketInfo && <span className="d-block text-right font-size-sm mr-3">{props.marketInfo.capitalization}</span>
            }

            <div className="">
                <span className="font-size-h5-lg font-size-md">
                    {
                        getPercentForJsx()
                    }
                </span>
            </div>
        </div>
    </div>
}
export default DailyResultCard