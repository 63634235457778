import { t } from 'i18next';
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import {useNavigate} from "react-router-dom";
import {useTransition} from "../hooks/useTransition";
import { gettOrigin } from '../Utills/ClearFunctions';


function Support() {
    const {transitionToMain} = useTransition()
    const {t} = useTranslation()


    return (
        <div className='d-flex flex-column flex-root flex-center'>
            <div className='simple-container d-flex flex-column flex-lg-row flex-column-fluid'>
                <div className="d-flex flex-column-auto flex-column pt-10 w-100">
                    <a href={`${gettOrigin()}`} className="text-center mb-5" onClick={transitionToMain}>
                        <img src= {`${gettOrigin()}/img/ts-logo.svg`} alt="logo" className="h-35px"/>
                    </a>

                    <h4 className="logo-subtitle font-weight-bolder text-center font-size-h5">
                        
                        <Trans  i18nKey = 'WIZARD_LOGIN_HEADER'>
                            <br/>
                        </Trans>
                    </h4>
                    <div className="card card-custom card-shadowless bg-white mt-4">
                        <div className="card-body pt-0 pb-4">

                            <div className="text-center">
                                <h3 className="font-weight-bolder font-size-h3 font-size-h1-lg">{t('WIZARD_LOGIN_SUPPORT_HEADER')}</h3>
                                {t('WIZARD_LOGIN_SUPPORT_TEXT')} <a
                                href= {`mailto:support@${window.location.host}`} >{`support@${window.location.host}`}</a>
                                <hr className="mt-6 mb-6"/>
                                <a href="https://t.me/tradesense_support"
                                   className="d-flex align-items-center justify-content-center">
                                    <i className="fab fa-telegram fa-2x text-primary mr-3" aria-hidden="true"></i> 
                                    {t('PROFILE_FOOTER_TG_SUPPORT')}
                                </a>
                            </div>
                            <div className="mt-10">
                                <div className="mb-10 text-center">
                                    <a href={`${gettOrigin()}`} onClick={transitionToMain}>
                                        {t('WIZARD_LOGIN_BACK_LINK')}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;