import React, { useEffect, useRef } from 'react';
import '../../styles/CustomButton.css'
import { DeviseIsApplicationOnly } from '../../Utills/ClearFunctions';
import {ORIGIN} from "../../models/Domen";


interface IButon{
    text:string,
    active:boolean,
    fire:boolean,
    click:() => void,
    isLoading:boolean,
}

function Button({text,active,fire,click,isLoading}:IButon)  {
    const ButtonRef = useRef<HTMLLabelElement>({} as HTMLLabelElement)
    useEffect(() =>{
        if(DeviseIsApplicationOnly()){
            ButtonRef.current.classList.remove('btn-outline-light-primary')
            ButtonRef.current.classList.remove('btn-hover-light-primary')
        }
    })

    const makeUpdate = () => {
        if(!active) return
        click()
    }
    
    const clickHandler = () => {
        if(DeviseIsApplicationOnly()){
            if( window.navigator.userAgent.includes('Android') ){
                makeUpdate()
                return
            }
            else {
                document.location=`${ORIGIN}/taptic`
            }
        };
        makeUpdate()
    }


    return (
        <label ref = {ButtonRef} onClick={clickHandler}    className={(!active)?'js__filter-events radio btn btn-sm d-flex flex-shrink-1 align-items-center mr-1 btn-outline-light-primary btn-hover-light-primary':'js__filter-events radio btn btn-sm d-flex flex-shrink-1 align-items-center mr-1 btn-primary'}>
            {(fire) && <i className="fas fa-fire" aria-hidden="true"></i>}
            {text}
            {

                active &&
                <>
                {
                   isLoading 
                   ? <b className="update fas fa-spin"></b>
                   : <b  className="fas update"></b>
                }
                </>
                
            }
        </label>
    );
}

export default Button;