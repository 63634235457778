export interface IError{
    isError:boolean,
    textError:string
}


export enum EventTopicEnum{
    TREND = "TREND",
    FAVORITES = "FAVORITES",
    ALL_STOCKS = "ALL_STOCKS",
    TICKER_EVENTS = "TICKER_EVENTS",
    LAST_EVENT = "LAST_EVENT"
}

export enum ThirtyEventsTypes{
    FINTWEETS = 'finTweets',
    TWEETS = 'tweets',
    MARKETDATATWEETS ='marketDataTweets',
    MEDIAS = 'medias'
}


export interface  ILabel{
    name:string,
    active:boolean,
    key:number
}

export interface ITabContentImg{
    header:string
    text:string
    active:boolean
    imgSrc:string
}
