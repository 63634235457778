import { Chart } from "klinecharts";
import { circleEnum } from "../../../models/Chart";



export const createLine = (dataIndex:number,close:number) => {
    
    return {
        name: 'Line',
        points: [
            { dataIndex:  dataIndex, value: close },
            { dataIndex:  dataIndex + 5, value: close + 5  }, // no matters
        ],
        styles: {
        style:'stroke',

        stroke: {
            // 'solid' | 'dash'
            style: 'solid',
            size: 20,
            color: '#47c55',
            dashValue: [2, 2]
        },
        },
        lock: true,
        // mode: 'strong_magnet',
      }
    }

export const createCircle = (dataIndex:number,close:number, name:circleEnum):any => { 
    return {
        name: name,
        points: [
            { dataIndex:  dataIndex, value: close },
            { dataIndex:  dataIndex, value: close  }, // no matters
        ],
        styles: {
        style:'stroke',

        stroke: {
            // 'solid' | 'dash'
            style: 'solid',
            size: 20,
            color: '#47c55',
            dashValue: [2, 2]
        },
        },
        lock: true,
        mode: 'strong_magnet',
    }
}