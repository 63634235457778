import TimeLineTime from "../Time/TimeLIneTime"
import {useTransition} from "../../hooks/useTransition";
import {UseActions} from "../../hooks/useActions";
import {useLocation} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {FilterConstant} from "../../models/FilterEvent";
import CommentsModal from "../Modals/CommentsModal";
import {ICompanyInformationCard} from "../Cards/CompanyInformationCard";
import CommentCard from "../Cards/CommentCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

interface ITimeLineFluctuation{
    fromPrice:string,
    toPrice:string,
    isUp:boolean,
    time:string,
    percent:string
    isCommentOpen:boolean
    ticker:string,
    Comment:any,
    companyName:string
    CardInformation:ICompanyInformationCard
    id?:string,
    countComments?:number,
    fullDateTootip?:string
}

function TimeLineFluctuation(props:ITimeLineFluctuation){
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const refProps = useRef<any>(props)


    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.PRICE_FLUCTUATIONS,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }

    const {t} = useTranslation()


    const renderTooltip = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">
                    {/* { props.header } */}
                    {/* {'21 февр. 2022 г., 14:04:09' + props.time + refProps.current.dateFull }  */}
                    {/* {refProps.current.dateFull +', ' +  refProps.current.time }  */}
                    {refProps.current.fullDateTootip}
                    </div>
            </Tooltip>
        );


    return(
        <>
            {/* <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.PRICE_FLUCTUATIONS} data={{...{...props,isCommentOpen:false}}}/> */}
            <div style={{position:"static"}} className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated fluctuations">

            <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <div data-tip={'s'}
                         className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1">{props.time}
                    </div>
                </OverlayTrigger>

                {/* <div className="timeline-label font-weight-bolder text-muted font-size-lg">
                    <TimeLineTime time = {props.time} />
                </div> */}

                <div style={{transition: '2s'}} className="timeline-badge">

                    {
                        props.isUp
                        ?<i className="fas fa-chevron-circle-up text-success font-size-md"></i>
                        :<i className="fas fa-chevron-circle-down text-danger font-size-md"></i>
                    }

                </div>

                <div  className="font-size-md timeline-content pl-3">
                    <div className="">
                        <b>  {t('EVENT_PRICE_MOVEMENT')}  </b>
                        <span className="font-weight-bolder">{props.fromPrice}&nbsp;$ → <span className={props.isUp ?' text-success':"text-danger"} >{props.toPrice}&nbsp;$ ({props.isUp ? `+${props.percent}` : props.percent}%)</span>
                        </span>
                    </div>

                    <div   className="d-flex align-items-center">
                        
                        {
                            props.Comment
                        }
                        {/* <CommentCard countComments={props.countComments} isCommentOpen={isCommentOpen || props.isCommentOpen} callback={setShow} show={show} id={props.id} /> */}

                        {/*{*/}
                        {/*    (isCommentOpen || props.isCommentOpen) && <a*/}
                        {/*        onClick={showModal} className="ml-auto mt-1 font-size-sm text-primary"*/}
                        {/*        href="" data-toggle="modal"*/}
                        {/*        data-event-id="332244" data-pjax="0"><span*/}
                        {/*        className="far fa-comments"></span></a>*/}
                        {/*}*/}
                    </div>

                </div>
            </div>
        </>
    )
}

export default TimeLineFluctuation