import React, {useEffect} from 'react'; 
import {useInView} from 'react-intersection-observer'; 

interface IInfiniteScroll{
    listItems:any
    lastRowHandler:any
}

function InfiniteScroll({listItems, lastRowHandler}:IInfiniteScroll) {
    const [lastRowRef, lastRowInView] = useInView();

    useEffect(() => {
        lastRowInView && lastRowHandler();
    }, [lastRowInView]);
    
    if(listItems){
        const Elements = listItems.map( (listItem:any,i:number) => {
            const props = {key: i};
            //@ts-ignore
            (i === listItems.length - 1) && (props['ref'] = lastRowRef);
            return (
            <div {...props}>
                {listItem}
            </div>
            );
    });
    return (<>{Elements}</>);
    }
    return (<></>);
}

export default InfiniteScroll;                                         