import ListAvdventages from "./ListAvdventages";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Pricetab, { getNumber } from "./Pricetab";
import Footer from "./Footer";
import '../../styles/ProSubscriptions.css'
import PayButton from "../Buttons/PayButton";
import { deleteLoginStyle, getPlaneString } from '../../Utills/ClearFunctions'
import FreeAlert from "./FreeAlert";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { PlaneTypes } from "../../models/User";
import SubscriptionService from "../../Services/SubscriptionService";
import { useTranslation } from "react-i18next";
import DiscountAlert from './DiscountAlert'
import PromoCodeModal from '../Modals/PromoCodeModal'
import { IMountPrice } from "./MobileSubscription";
import DesctopSubscription from './DesctopSubscription'
import SpecialRuMobileWaitingPayment from "./SpecialRuMobileWaitingPayment";

enum DeviceType {
    MOBILE = 'MOBILE',
    ANDROID = 'ANDROID',
    APPLE = "APPLE",
    DESKTOP = 'DESKTOP',
    REVIEW = 'REVIEW',
    SPECIAL_USER = 'SPECIAL_USER',
}

export interface IDiscountInfo {
    discount: number,
    finishedAt?: null | number,
    promoCode?: string,
}

function DesktopSubscription() {
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const isAvailableTrial = useTypedSelector(state => state.UserReducer.User.subscriptionInfo.isAvailableTrial)
    const finishedAt = useTypedSelector(state => state.UserReducer.User.subscriptionInfo.finishedAt)
    const [isRetrivePriceInformation, setIsRetrivePriceInformation] = useState<boolean>(false)
    const { t } = useTranslation()
    const promoCode = useTypedSelector(state => state.UserReducer.User.promoCode)


    interface IPriceTab {
        time: string,
        price: string,
        checked: boolean,
        sku: string,
        initialCost: string,
        cost: number,
        discount: number
        currency: string
    }

    interface IContent {
        Plane: PlaneTypes,
        PriceTabs: Array<IPriceTab>
        current: boolean,
    }

    interface IButtonState {
        text: string
    }

    const getCurrency = (currentScu: string, price: number,) => {
        if (currentScu === 'ts_monthly_trial') {
            return String(price) + ' руб'
        }
        else {
            return String(price) + ' ' + t('SUBSCRIPTION_PLANS_RUB_MONTH')
        }
    }

    const [discountInfo, setDiscountInfo] = useState<IDiscountInfo | null>(
        null
    )
    const [mountPrice, setMountPrice] = useState<IMountPrice>({ liteMount: null, proMount: null })


    const getSubscriptionInfo = async () => {
        const response = await SubscriptionService.getDesctopTariffs()
        const responseTariffs = response.data?.tariffs
        setDiscountInfo(response.data?.discountInfo)
        responseTariffs.forEach((element: any) => {
            element.periods.forEach((periodsElement: any) => {
                if (periodsElement.sku === 'ts_monthly') {
                    setMountPrice((prev) => {
                        return { ...prev, proMount: periodsElement.cost }
                    })
                }
                if (periodsElement.sku === 'ts_monthly_lite') {
                    setMountPrice((prev) => {
                        return { ...prev, liteMount: periodsElement.cost }
                    })
                }
            });

        });

        const filledContent = content.map((InfoObj, index) => {
            for (let i = 0; i < responseTariffs.length; i++) {
                if (responseTariffs[i].id === InfoObj.Plane) {
                    const FilledPriceTabs = InfoObj.PriceTabs.map((PriceTab, tabIndex) => {
                        for (let j = 0; j < responseTariffs[i]?.periods.length; j++) {
                            if (PriceTab.sku === responseTariffs[i]?.periods[j].sku) {
                                const price = getCurrency(PriceTab.sku, responseTariffs[i].periods[j].cost)
                                const initialCost = getCurrency(PriceTab.sku, responseTariffs[i].periods[j].initialCost)
                                const discount = responseTariffs[i].periods[j].discount
                                const currency = responseTariffs[i].periods[j].currency
                                const cost = responseTariffs[i].periods[j].cost
                                return { ...PriceTab, price, initialCost, discount, currency, cost }
                            }
                        }
                        return { ...PriceTab }
                    })
                    return { ...InfoObj, PriceTabs: FilledPriceTabs }
                }
            }
            return { ...InfoObj }
        })


        const trialFiltred = filledContent.map((card) => {
            const newPriceTab = card.PriceTabs.filter((PriceTab) => {
                if (PriceTab.sku === 'ts_monthly_trial') {
                    if (isAvailableTrial) return true
                    else return false
                }
                return true
            })
            card.PriceTabs = newPriceTab
            return { ...card }
        })
        setContent(trialFiltred)

    }
    const [isPromocodeApply, setIsPromocodeApply] = useState<boolean>(false)
    useEffect(() => {
        deleteLoginStyle()
        getSubscriptionInfo()
            .then(
                () => {

                    if (Plane === PlaneTypes.FREE || finishedAt === null) {
                        let isMutate = false
                        // alert(Plane)
                        setContent((prev) => {
                            const after = prev.map((ContentObj) => {
                                const newPriceTabs = ContentObj.PriceTabs.map((PriceTab, index) => {
                                    if (index === 0)
                                        return { ...PriceTab, checked: true }
                                    return { ...PriceTab, checked: false }
                                })
                                ContentObj.PriceTabs = newPriceTabs
                                if (ContentObj.Plane === PlaneTypes.PRO) {
                                    isMutate = true
                                    return { ...ContentObj, current: true }
                                }

                                else return { ...ContentObj, current: false }
                            })
                            return after
                        })

                        setIsRetrivePriceInformation(true)
                        return
                    }

                    else if(Plane === PlaneTypes.LITE){
                        setContent((prev) => {
                            for (let i = 0; i < prev.length; i++) {
                                if (prev[i].Plane === Plane) {
                                    prev[i].PriceTabs[0].checked = true
                                    prev[i].current = true
                                    return [prev[i]]
                                }
                            }
                            return [...prev]
                        })
                    }

                    setContent((prev) => {
                        for (let i = 0; i < prev.length; i++) {
                            if (prev[i].Plane === Plane) {
                                prev[i].PriceTabs[0].checked = true
                                return [prev[i]]
                            }
                        }


                        return [...prev]
                    })
                    setIsRetrivePriceInformation(true)
                }
            )
    }, [isPromocodeApply])


    const [currentDeviceType, setDeviceType] = useState<DeviceType>(DeviceType.DESKTOP)
    const deviceType = useTypedSelector(state => state.EventReducer.userAgent)

    const [content, setContent] = useState<Array<IContent>>([
        {
            Plane: PlaneTypes.PRO,
            PriceTabs: [
                {
                    price: '1 руб',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_7D')}`,
                    checked: true,
                    sku: 'ts_monthly_trial',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB',
                    cost: 0
                },
                {
                    price: '1 790 руб/мес',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_1M')}`,
                    checked: false,
                    sku: 'ts_monthly',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB',
                    cost: 0
                },
                {
                    price: '1 499 руб/мес',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_3M')}`,
                    checked: false,
                    sku: 'ts_3months',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB',
                    cost: 0
                },
                {
                    price: '1 249 руб/мес',
                    time: `${t('SUBSCRIPTION_PLANS_PERIODS_1Y')}`,
                    checked: false,
                    sku: 'ts_yearly',
                    discount: 0,
                    initialCost: '0',
                    currency: 'RUB',
                    cost: 0
                },
            ],
            current: true,
        },
        {
            Plane: PlaneTypes.LITE,
            PriceTabs: [{
                price: '799 руб/мес',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_1M')}`,
                checked: true,
                sku: 'ts_monthly_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB',
                cost: 0
            },
            {
                price: '599 руб/мес',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_3M')}`,
                checked: false,
                sku: 'ts_3months_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB',
                cost: 0
            },
            {
                price: '599 руб/мес',
                time: `${t('SUBSCRIPTION_PLANS_PERIODS_1Y')}`,
                checked: false,
                sku: 'ts_yearly_lite',
                discount: 0,
                initialCost: '0',
                currency: 'RUB',
                cost: 0
            }
            ],
            current: false,
        },
        {
            Plane: PlaneTypes.FREE,
            PriceTabs: [],
            current: false,
        }
    ])

    const getCurrentTab = () => content.find((contentObj) => contentObj.current)?.PriceTabs.find((PriceTab) => PriceTab.checked)


    const [ButtonState, setButtonState] = useState<IButtonState>({
        text: 'начать пробный периуд'
    })

    const ChangePlane = (plane: PlaneTypes) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        const currentPlaneSatate = content.map((Content) => {
            if (Content.Plane === plane) return { ...Content, current: true }
            else return { ...Content, current: false }
        })

        setContent(currentPlaneSatate)
    }

    const changeChooseTabs = (time: string) => {
        const newContent = content.map((Content) => {
            let priceTab: Array<IPriceTab> = Content.PriceTabs
            if (Content.current) {
                priceTab = Content.PriceTabs.map((tab) => {
                    if (tab.time === time) return { ...tab, checked: true }
                    else return { ...tab, checked: false }
                })
            }
            return { ...Content, PriceTabs: priceTab }
        })
        setContent(newContent)
    }


    const tabElements = () => {
        return content.map((Content) => {
            if (Content.current) {
                return Content.PriceTabs.map(tab => {
                    return <Pricetab {...{ ...tab, cardPlane: Content.Plane, mountPrice: mountPrice, discountInfo, clickFunction: changeChooseTabs }} />
                })
            }
        })
    }

    const ButtonSubGroup = () => {
        return content.map((Content) => {
            return <li className="nav-item">
                <a onClick={ChangePlane(Content.Plane)}
                    className={Content.current ? "js__change-tariff nav-link px-4 py-2 active" : "js__change-tariff nav-link px-4 py-2"}
                    data-tariff="lite"
                    data-toggle="tab"
                    href="">
                    {
                        (Content.Plane === PlaneTypes.PRO) &&
                        <span className="nav-icon"><i className="flaticon2-rocket-1"></i></span>
                    }
                    {getPlaneString(Content.Plane)}
                </a>
            </li>
        })
    }

    const [isWaitingPayment, setIsWaitingPayment] = useState<boolean>(false)
    const [isAndroidPay, setIsAndroidPay] = useState<boolean>(false)

    const handlePayment = useCallback(async () => {
        const currentContent = getCurrentPlaneObj()
        const currentTab = currentContent?.PriceTabs.find((PriceTab) => PriceTab.checked)
        setIsWaitingPayment(false)
        if (window.navigator.userAgent.includes('Tradesense'))
            setIsAndroidPay(true)
        const response = await SubscriptionService.payDesctop(currentTab?.sku as string)
        setIsWaitingPayment(true)
        window.location.assign(response.data.redirectUrl)
    }, [content])

    const getCurrentPlaneObj = (): IContent | null => {
        for (let i = 0; i <= content.length; i++) {
            if (content[i].current === true) return content[i]
        }
        return null
    }

    const getCurrentPlane = useMemo(() => {
        for (let i = 0; i <= content.length; i++) {
            if (content[i].current === true) return content[i].Plane
        }
        return 0
    }, [content])

    const [showPromocodeModal, setShowPromocodeModal] = useState<boolean>(false)

    const closePromocode = () => {
        setShowPromocodeModal(false)
    }

    const sendInviteCodeModaHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setShowPromocodeModal(true)
    }

    const getCurrentButtonText = (): string => {
        for (let i = 0; i <= content.length - 1; i++) {
            for (let k = 0; k <= content[i].PriceTabs.length - 1; k++) {
                const tab = content[i].PriceTabs[k]
                if (tab.checked && getCurrentPlane !== PlaneTypes.LITE) {
                    if (tab.sku !== 'ts_monthly_trial') return `${t('SUBSCRIPTION_PLANS_PAY')}`
                    else return `${t('TRIAL_TRY_FOR_FREE')}`
                }
            }
        }
        return `${t('SUBSCRIPTION_PLANS_PAY')}`
    }

    const chooseRightFooter = useMemo(() => {
        if (getCurrentButtonText() === `${t('TRIAL_TRY_FOR_FREE')}`) {
            return <Footer buttonText={getCurrentButtonText()} text={'Оплата происходит через ООО НКО «ЮМани». Лицензия Банка России № 3510‑К. При оформлении' +
                ' пробного периода вы соглашаетесь с тем, что после окончания предоставленного периода подписка (с дальнейшим автопродлением)' +
                ' автоматически будет продлена на 1 месяц и по стоимости 1790 руб./месяц. Вы всегда можете отключить ' +
                'автоматическое продление подписки в настройках личного кабинета.'} />
        }

        else return <Footer buttonText={getCurrentButtonText()} text={'Оплата происходит через ООО НКО «ЮМани». Лицензия Банка России № 3510‑К. При оформлении' +
            ' подписки вы соглашаетесь с тем, что после окончания предоставленного периода подписка (с дальнейшим автопродлением) ' +
            'автоматически будет продлена на период и по стоимости согласно выбранному тарифу. Вы всегда можете отключить' +
            ' автоматическое продление подписки в настройках личного кабинета.'} />
    }, [getCurrentButtonText])

    const BacktoProfile = () => {
        window.location.href = '#closerefresh'
    }

    return (
        <>
            {
                !isAndroidPay
                    ?
                    <>
                        <PromoCodeModal handleClose={closePromocode} show={showPromocodeModal} setIsPromocodeApply={setIsPromocodeApply} />
                        <div className='row mt-n4 ml-n4 justify-content-md-center'>
                            <div
                                className="card card-custom col-12 col-lg-8 p-3 text-center">
                                <div className='row mt-n4 ml-n4 justify-content-md-center'>
                                    <span className="svg-icon svg-icon-primary svg-icon-4x mt-6 mb-2">
                                        <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="24px"
                                            height="24px" viewBox="0 0 24 24" version="1.1">
                                            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M12.3740377,19.9389434 L18.2226499,11.1660251 C18.4524142,10.8213786 18.3592838,10.3557266 18.0146373,10.1259623 C17.8914367,10.0438285 17.7466809,10 17.5986122,10 L13,10 L13,4.47708173 C13,4.06286817 12.6642136,3.72708173 12.25,3.72708173 C11.9992351,3.72708173 11.7650616,3.85240758 11.6259623,4.06105658 L5.7773501,12.8339749 C5.54758575,13.1786214 5.64071616,13.6442734 5.98536267,13.8740377 C6.10856331,13.9561715 6.25331908,14 6.40138782,14 L11,14 L11,19.5229183 C11,19.9371318 11.3357864,20.2729183 11.75,20.2729183 C12.0007649,20.2729183 12.2349384,20.1475924 12.3740377,19.9389434 Z"
                                                    fill="#000000"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    <b className="mb-4 h3 font-weight-bolder">{t('SUBSCRIPTION_PLANS')} </b>
                                    <div className="d-flex justify-content-center mb-4">
                                        <ul className="nav nav-light-dark nav-pills">
                                            {isRetrivePriceInformation && ButtonSubGroup()}
                                        </ul>
                                    </div>
                                    <div className="tab-content">
                                        <div className="tab-pane fade active show" id="tab_1" role="tabpanel"
                                            aria-labelledby="tab_1">
                                        </div>
                                    </div>

                                </div>

                                <ListAvdventages plane={getCurrentPlane} />

                                {
                                    getCurrentPlane !== PlaneTypes.FREE && <b className='ChoosePlane'>{t('SUBSCRIPTION_PLANS_PERIODS')}</b>
                                }

                                {
                                    isRetrivePriceInformation && tabElements()
                                }

                                <DiscountAlert setIsPromocodeApply={setIsPromocodeApply} discountInfo={discountInfo} />

                                {
                                    !isAndroidPay ? <>
                                        {
                                            getCurrentPlane !== PlaneTypes.FREE
                                                ? <PayButton isWaiting={isWaitingPayment} text={getCurrentButtonText()} handlePayment={handlePayment} />
                                                : <FreeAlert />
                                        }
                                    </>
                                        : <>
                                            <div className="row justify-content-md-center mb-9">
                                                <div className="col-lg-6 col-12 text-center">
                                                    <button onClick={BacktoProfile}
                                                        className={`btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 mr-2`}>
                                                        <span className="js__subscription-selected">
                                                            Вернуться в профиль
                                                        </span>
                                                    </button>

                                                </div>
                                            </div>
                                        </>
                                }

                                {
                                    !promoCode && <>
                                        {
                                            !discountInfo && <a href='' onClick={sendInviteCodeModaHandler}> {t('SUBSCRIPTION_PLANS_APPLY_PROMO')} </a>
                                        }
                                    </>
                                }

                                <DesctopSubscription sku={getCurrentTab()?.sku} buttonText={getCurrentButtonText()} />
                            </div>
                        </div>
                    </>
                    : <>
                        <SpecialRuMobileWaitingPayment getCurrentTab={getCurrentTab} />
                    </>

            }


        </>
    )
}

export default DesktopSubscription