import { UseActions } from '../../hooks/useActions';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import DropDownTrendButton from './DropDownTrendButton'
import React from 'react';
function DropDownTrendButtonWrapper(){
    const isOpen = useTypedSelector(state => state.EventReducer.isOpen)
    const { setIsOpen } = UseActions()
    return <>
        <DropDownTrendButton  setIsOpen={setIsOpen} isOpen={isOpen}/>
    </>
}
export default DropDownTrendButtonWrapper