import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { PlaneTypes } from "../../models/User";

interface IBenefitsList {
    Plane: string,
}

interface ListAdvantages {
    plane: PlaneTypes
}

function ListAdvantages({ plane }: ListAdvantages) {

    const { t } = useTranslation()
    const isCrypto = useTypedSelector((state) => state.UserReducer.User.isCrypto);

    const proBenefits = <div className="d-flex text-left justify-content-md-center">
        <div className="col-lg-6 p-2">
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1">✓</span>
                {
                    !isCrypto
                        ? <> {t('SUBSCRIPTION_PRO_FEATURE_1')} </>
                        : <> {t('SUBSCRIPTION_PRO_FEATURE_CRYPTO_1')} </>
                }

            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1">✓</span>
                <Trans i18nKey='SUBSCRIPTION_PRO_FEATURE_2'>
                    <span>
                        <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                        <i className="ml-1 fas fa-mobile-alt text-primary fa-1x mr-1" aria-hidden="true"></i>
                    </span>
                </Trans>


            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1">✓</span>
                {t('SUBSCRIPTION_PRO_FEATURE_3')}
            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1">✓</span>
                {
                    !isCrypto
                        ? <>{t('SUBSCRIPTION_PRO_FEATURE_4')}</>
                        : <>{t('SUBSCRIPTION_PRO_FEATURE_CRYPTO_4')}</>
                }

            </div>
        </div>
    </div>

    const liteBenefits = <div className="d-flex text-left justify-content-md-center">
        <div className="col-lg-6 p-2">
            <div className="mb-2">
                <span className="text-muted font-weight-bolder mr-1">✓</span>
                {
                    !isCrypto
                        ? <> {t('SUBSCRIPTION_LITE_FEATURE_1')} </>
                        : <> {t('SUBSCRIPTION_LITE_FEATURE_CRYPTO_1')} </>
                }

            </div>
            <div className="mb-2">
                <span className="text-muted font-weight-bolder mr-1">✓</span>

                <Trans i18nKey='SUBSCRIPTION_LITE_FEATURE_2'>
                    <span>
                        <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                        <i className="ml-1 fas fa-mobile-alt text-primary fa-1x mr-1" aria-hidden="true"></i>
                    </span>
                </Trans>

                {/* Уведомления в
                <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                <i className="mr-1 fas fa-mobile-alt text-primary fa-1x" aria-hidden="true"></i>
                с задержкой 15 секунд */}
            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1"><i
                    className="far fa-times-circle fa-1x text-danger" aria-hidden="true"></i></span>
                {t('SUBSCRIPTION_LITE_FEATURE_3')}
            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1">✓</span>
                {
                    !isCrypto
                        ? <>{t('SUBSCRIPTION_LITE_FEATURE_4')}</>
                        : <>{t('SUBSCRIPTION_LITE_FEATURE_CRYPTO_4')}</>
                }
            </div>
        </div>
    </div>

    const freeBenefits = <div className="d-flex text-left justify-content-md-center">
        <div className="col-lg-6 p-2">
            <div className="mb-2">
                <span className="text-muted font-weight-bolder mr-1">✓</span>

                {
                    !isCrypto
                        ? <> {t('SUBSCRIPTION_FREE_FEATURE_1')} </>
                        : <> {t('SUBSCRIPTION_FREE_FEATURE_CRYPTO_1')} </>
                }

            </div>
            <div className="mb-2">
                <span className="text-muted font-weight-bolder mr-1">✓</span>

                <Trans i18nKey='SUBSCRIPTION_FREE_FEATURE_2'>
                    <span>
                        <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                        <i className="ml-1 fas fa-mobile-alt text-primary fa-1x mr-1" aria-hidden="true"></i>
                    </span>
                </Trans>

                {/* Уведомления в
                <i className="ml-1 mr-1 fab fa-telegram text-primary fa-1x mr-1" aria-hidden="true"></i>
                <i className="mr-1 fas fa-mobile-alt text-primary fa-1x" aria-hidden="true"></i>
                с задержкой 1 час */}
            </div>
            <div className="mb-2">
                <span className="text-primary font-weight-bolder mr-1"><i
                    className="far fa-times-circle fa-1x text-danger" aria-hidden="true"></i></span>
                {t('SUBSCRIPTION_FREE_FEATURE_3')}
            </div>
            <div className="mb-2">
                <span className=""><i className="far fa-times-circle fa-1x text-danger mr-1"
                    aria-hidden="true"></i></span>
                {
                    !isCrypto 
                        ? <> {t('SUBSCRIPTION_FREE_FEATURE_4')} </> 
                        : <> {t('SUBSCRIPTION_FREE_FEATURE_CRYPTO_4')} </>
                }
            </div>
        </div>
    </div>

    const chooseBenefits = () => {
        switch (plane) {
            case PlaneTypes.PRO:
                return proBenefits
            case PlaneTypes.LITE:
                return liteBenefits
            case PlaneTypes.FREE:
                return freeBenefits
        }
    }
    return (
        <>
            {chooseBenefits()}
        </>
    )



}
export default React.memo(ListAdvantages)