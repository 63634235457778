import React, {useEffect, useRef, useState} from 'react';
import '../../styles/headerStyles.css'
import GiftModal from "../Modals/GiftModal";
import {useNavigate, useLocation, useParams} from "react-router-dom";
import {UseActions} from "../../hooks/useActions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {closeSearchView, isCloseAnchor} from '../../Utills/ClearFunctions'
import SideMenu from "./SideMenu";
import {useTransition} from "../../hooks/useTransition";
import {ContinueButton} from "./Header/SelectFavoritesHeader";
import BasicHeaderElements from "./Header/BasicHeaderElements";
import {useCheckForPage} from "../../hooks/useCheckForPage";
import HeaderWaitinPage from './Header/HeaderWaitinPage';
import HeaderStockPage from './Header/HeaderStockPage';
import MobileNotStockHeader from './Header/MobileNotStockHeader';
import { StatusTypes } from '../../models/User';
import SaveUserProfileError from './Header/SaveUserProfileError';

interface IProps {
    isOpenSideMenu: boolean,
    setIsOpenSideMenu: (flag: boolean) => void,
    searchIsOpen: boolean,
    setSearchIsOpen: (flag: boolean) => void,
    profileIsOpen: boolean,
    setProfileIsOpen: (flag: boolean) => void,
    SelectIsFull?: boolean,
    setLessNotify?: Function,
    setNotifyMore?: Function,
    NotifyMoreClick?: boolean,
    WaitingPageDropDown?: boolean,
    setWaitingPage?: Function
}

function Header({
                    profileIsOpen,
                    setProfileIsOpen,
                    isOpenSideMenu,
                    setIsOpenSideMenu,
                    searchIsOpen,
                    setSearchIsOpen,
                    setLessNotify,
                    SelectIsFull,
                    NotifyMoreClick,
                    setNotifyMore,
                    WaitingPageDropDown,
                    setWaitingPage
                }: IProps) {
    const {setIsOpen,setDebugInformation} = UseActions()
    const {
        eventTransition,
        transitionToAnchor,
        transitionToMainPageNotAuthUser,
        transitionToInvites
    } = useTransition()

    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const User = useTypedSelector(state => state.UserReducer.User)

    const [searchPosition, setSearchPosition] = useState<number>(0)
    const location = useLocation()
    const sideMenuHandler = useRef<HTMLDivElement>({} as HTMLDivElement)
    const navigate = useNavigate()
    const [show, setShow] = useState(false);
    const [isStockPage, setIsStockPage] = useState(false)
    const [isProfilePage, setIsProfilePage] = useState(false)
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [isSelectFavorites, setIsSelectFavorites] = useState<boolean>(false)
    const [isWaitingPage, setIsWaitingPage] = useState<boolean>(false)
    const [isMobileMainNotStockPage, setIsMobileMainNotStockPage] = useState<boolean>(false)
    const {checkAuth } = UseActions()

    const hanlderContinue =async (e: React.MouseEvent<HTMLButtonElement>) => {
        if (SelectIsFull){
            await checkAuth()
            window.scrollTo(0, 0)
            transitionToMainPageNotAuthUser()
        }
        else {
            //@ts-ignore
            if (NotifyMoreClick) {
                //@ts-ignore
                setNotifyMore(true)
            } else {
                //@ts-ignore
                setLessNotify(true)
            }
        }
    }

    const {id} = useParams()
    const {
        checkForUserProfilePage,
        checkForFavoritesPage,
        checkForInvitesPage,
        checkForComments,
        checkForProfilePage,
        checkForStockPage,
        checkForSubscriptionPage,
        checkForWaitingPage
    } = useCheckForPage()

    const [userProfile, setUserProfile] = useState<boolean>(false)

    const checkUrl = () => {
        setIsStockPage(checkForStockPage())
        setIsSelectFavorites(checkForFavoritesPage() ||  (!User.favouritesSelected && !(User.status === StatusTypes.PENDING)))
        setIsWaitingPage(checkForWaitingPage() || !(User.status === StatusTypes.OPEN))
        setUserProfile(checkForUserProfilePage())

        const isMoversPage = window.location.href.includes('movers')
        const isMarketMapPage = window.location.href.includes('market-map')
        
        if(
            checkForInvitesPage() || 
            checkForSubscriptionPage() || 
            checkForComments() || 
            checkForProfilePage() || 
            isMoversPage  || 
            isMarketMapPage) {
                setIsProfilePage(true)
        }
        else
            setIsProfilePage(false)

        if(checkForStockPage())
            setIsMobileMainNotStockPage(false)
        else setIsMobileMainNotStockPage(true)
    }

    useEffect(() => {
        document.addEventListener('keyup', function(e) {
            if (e.key === 'Escape') {
                setSearchIsOpen(false)
                closeSearchView()
            }
        });
    },[])

    useEffect(() => {
        checkUrl()
    }, [location])


    useEffect(() => {
        (!isOpenSideMenu)
            ? sideMenuHandler.current.classList.remove('header-menu-wrapper-on')
            : sideMenuHandler.current.classList.add('header-menu-wrapper-on')
    }, [isOpenSideMenu])

    const handleBackProfile = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        
        if ( isCloseAnchor(userAgent, location.pathname, isApplication) ) {
            transitionToAnchor()
            return
        }
        navigate(-1)
    }

    const handlerMenuClick = () => {
        setIsOpen(false)
        setSearchIsOpen(false)
        closeSearchView()
        setProfileIsOpen(false)
        setIsOpenSideMenu(!isOpenSideMenu)
    }
    const [isOpenSearchBar,setOpenSearchBar] = useState<boolean>(false)

    const removeOrAddViewOfSearch = () => {
        const search = document.querySelector('.SearchContainer')
        searchRef.current.value = ''
        if (!searchIsOpen) search!.classList.remove('SearchContainer_disabled')
        else search!.classList.add('SearchContainer_disabled')
        const visibleBar = document.querySelector('#kt_quick_search_dropdown')
        setOpenSearchBar(false)
    }

    const handlerProfileClick = () => {
        setIsOpen(false)
        setSearchIsOpen(false)
        if (!isWaitingPage) {
            closeSearchView()
        }
        setProfileIsOpen(!profileIsOpen)
    }

    const handlerProfileClickWaitingPage = () => {
        //@ts-ignore
        setWaitingPage(!WaitingPageDropDown)
    }

    const handlerSearchClick = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpenSideMenu(false)
        removeOrAddViewOfSearch()
        searchRef.current.focus()
        setSearchPosition(Number(e.currentTarget.getBoundingClientRect().left))
        setIsOpen(false)
        setProfileIsOpen(false)
        setSearchIsOpen(false)
        searchRef.current.focus()
    }

    const handlerGiftClick = () => {
        setIsOpenSideMenu(false)
        if (window.navigator.userAgent.includes('Tradesense')) {
            transitionToInvites()
            return
        }
        setIsOpen(false)
        setSearchIsOpen(false)
        closeSearchView()
        setProfileIsOpen(false)
        handleShow()
    }

    const handleCloseMenuItemsInMobileSideBar = (e: React.MouseEvent<HTMLDivElement>) => {
        setIsOpenSideMenu(false);
        setSearchIsOpen(false);
        setProfileIsOpen(false);
        setIsOpen(false);
        e.stopPropagation()
        closeSearchView()
    }

    const closeAllMenuItems = () => {
        closeSearchView()
        setIsOpen(false);
        setIsOpenSideMenu(false);
        setSearchIsOpen(false);
        setProfileIsOpen(false)
    }


    const handleBackButton = (e?:any) => {

        setDebugInformation(`open handleBackButton ${Math.random()}`)

        if(e)
            e.preventDefault()
        try {
            const arrayOfPage = location.pathname.split('/')
            const currentPage = location.pathname.split('/')[arrayOfPage.length - 1]
            const numberOFPage = arrayOfPage.length
            const arrayOfMatch = ['company', 'events']

            if (isApplication) {
                setDebugInformation(`transition to #close  ${Math.random()}`)
                transitionToAnchor()
                return
            }
            window.history.back()
        } catch(e:any){
            setDebugInformation('Ошибка')
        }
    }

    const searchRef = useRef<any>('')

    const changeRef = () => {
        searchRef.current.value = '' 
    }

    return (
        <div className="container" onClick={() => {
            setIsOpenSideMenu(false);
            setIsOpen(false);
            setSearchIsOpen(false);
        }}>

            <SideMenu setIsOpenSideMenu={setIsOpenSideMenu}
                      isProfilePage={isProfilePage}
                      handleBackButton={handleBackButton}
                      closeAllMenuItems={closeAllMenuItems}
                      ref={sideMenuHandler}
                      isSelectFavorites={isSelectFavorites}
                      isWaitingPage={isWaitingPage}
            />
            
            <div className={!isStockPage ? "topbar" : 'topbarStockPage'} onClick={(e) => {
                handleCloseMenuItemsInMobileSideBar(e)
            }}>
               
            {
                isWaitingPage && <> <HeaderWaitinPage 
                    WaitingPageDropDown ={WaitingPageDropDown}
                    isOpenSideMenu = {isOpenSideMenu}
                    handlerProfileClickWaitingPage = {handlerProfileClickWaitingPage}
                    isStockPage = {isStockPage}
                    setIsOpenSideMenu = {setIsOpenSideMenu} 
                 /> </>
            }

            {  isStockPage && <> 
                <HeaderStockPage handleBackButton={handleBackButton} id = {id}
                />
            </>
            }

            {
                (isMobileMainNotStockPage) && <>
                    <MobileNotStockHeader handleBackProfile={handleBackProfile} isUserProfile = {userProfile} isShowBackButton = {isProfilePage} />
            </>
            }

            {
                (!userProfile && !isSelectFavorites && !isWaitingPage) && <BasicHeaderElements
                    ref={searchRef}
                    searchIsOpen={searchIsOpen}
                    searchPosition={searchPosition}
                    handlerGiftClick={handlerGiftClick}
                    handlerMenuClick={handlerMenuClick}
                    handlerProfileClick={handlerProfileClick}
                    profileIsOpen={profileIsOpen}
                    handlerSearchClick={handlerSearchClick}
                    isStockPage={isStockPage}
                    changeRef = {changeRef}
                    isOpenSearchBar = {isOpenSearchBar}
                    setOpenSearchBar = {setOpenSearchBar}
                />

            }
        
            {
                isSelectFavorites && <ContinueButton handlerContinue={hanlderContinue}/>
            }
            
            {
                userProfile && <SaveUserProfileError />
            }
        
            </div>

            <GiftModal show={show} handleClose={handleClose}/>
        </div>
    );
}

export default React.memo(Header);