import React, {useState} from 'react';
import Budget from "../Text/Budget";
import {useTransition} from "../../hooks/useTransition";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { UseActions } from '../../hooks/useActions';
import { gettOrigin } from '../../Utills/ClearFunctions';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import { t } from "i18next";


interface ITimeLineCard {
    TimeLineDate: any
    isUp: boolean
    price:string
    badgeText:string
    imgSrc:string
    companyName:string
    ticker:string,
    oldPrice?:string | null,
    currency?:string 
    brand ?: string
}

function TimeLineCard({TimeLineDate,currency, isUp,price,badgeText,imgSrc,companyName,ticker,oldPrice, brand}: ITimeLineCard) {

    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)

    const {transitionToTicketTest} = useTransition()
    const [show, setShow] = useState(false);
    const {setCurrentCompanyEnum} = UseActions()
    const {getPrice} = useFormatCurrency()

    const timeLineElements = TimeLineDate.map((value: any) => {
        return value
    })

    const handleTransitionToCompanyTicker = () => {
        setCurrentCompanyEnum(
            {
                cardName:companyName,
                ticket:ticker,
                src:imgSrc,
                //@ts-ignore
                price:Number(price),
                percent:2,
                oldPrice:oldPrice,
                currency:currency,
                brand:brand
            }
        )
        transitionToTicketTest(ticker)
    }

    const clickToStocks = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleTransitionToCompanyTicker()
    }



    return (
        <div className="card card-custom card-shadow card-stock mb-6">
            {/*<CommentsModal show={show} handleClose={handleClose} data={[...TimeLineDate]} />*/}

            <div className="card-header flex-nowrap border-0 pl-2 pr-2">
                <div className="card-title align-items-start d-flex align-items-center">
                    <a href = {`${gettOrigin()}/stocks/${ticker}`} onClick={clickToStocks}>
                        <div className="symbol symbol-35 symbol-circle mx-3">
                            <img alt="logo" src={imgSrc}/>
                        </div>
                    </a>
                    <div className="d-flex flex-column">
                        <div>
                            <span className="card-label font-weight-bolder font-size-h5">

                            <a href={`${gettOrigin()}/stocks/${ticker}`} onClick={clickToStocks}
                                   className="text-dark line-height-sm text-hover-primary">
                                   {companyName} {
                                   (isUp)
                                       ? <span className="font-weight-bolder font-size-h3 text-success">↑</span>
                                       : <span className="font-weight-bolder font-size-h3 text-danger">↓</span>
                               }
                            </a>

                            </span>
                        </div>
                        <div className="text-muted font-weight-bolder font-size-lg"><a
                            href={`${gettOrigin()}/stocks/${ticker}`}

                            onClick={clickToStocks} className="text-muted text-hover-primary">${ticker}</a>
                        </div>
                    </div>
                </div>
                <div className="card-toolbar flex-column align-items-end mr-2">

                    <div className="font-weight-bolder font-size-h5">
                                           
                    {getPrice(Number(price),currency)}                       
                    </div>

                    {
                        isUp
                            ? <Budget text={badgeText} type={'badge-success'}/>
                            :  <Budget text={badgeText} type={' badge-danger'}/>
                    }


                </div>
            </div>
            <div className="card-body pt-0 pb-4">
                <div className="d-flex mb-1">
                    <div className="timeline w-100 timeline-6 mt-3">
                        {timeLineElements}
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <a className="font-size-sm text-primary" data-pjax="0" href={`${gettOrigin}/stocks/${ticker}`} onClick={clickToStocks}>
                        {t('EVENTS_SHOW_ALL')}</a>
                </div>
            </div>
        </div>
    );
}

export default React.memo(TimeLineCard);