import { t } from 'i18next';
import React from 'react';
import DropDownWrapperContntent from './DropDownWrapperContntent'

function DropDownWrapper() {

    return (
        <>
            <div className={'dropDown'}>
                <div className={'ContainerFilterNone'}>
                    <span className="mr-6 js__filter-types-text filtersNone">{t('FEED_FILTER_FILTERS_APPLIED')}</span>
                     <DropDownWrapperContntent/>
                </div>
            </div>
        </>
    );

}
export default React.memo(DropDownWrapper)