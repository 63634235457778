import {
    AuthEnum,
    IActionError,
    IActionLoading,
    IActionLogin,
    IAvataSaveError,
    ISaveProfile,
    ISetAvatarError,
    ISetHasInvites,
    ISetProfileUser,
    ISetTelegram,
    ISetUserProfile,
    ISetUserSettings,
    SetPlane,
    SetUserIsAuth
} from './types'
import {IProfile, ISetting, IUser, PlaneTypes, StatusTypes} from "../../../models/User";
import {AppDispatch} from "../../index";
import AuthService from "../../../Services/AuthService";
import UserService from "../../../Services/UserService";
import {IProfileTranslateObj} from "../../../page/Profile";
import {checkForSendYEvent, gettOrigin, urlToFile} from "../../../Utills/ClearFunctions";
import axios from "axios";
import {ORIGIN} from '../../../models/Domen';
import {IUSerInformation} from "./index";
import AllEventsReducer from '../allEvents';

export const AuthGlobalActionCreators = {
    setLoading: (boolVal: boolean): IActionLoading => ({type: AuthEnum.SET_LOADING, payload: boolVal}),
    setUser: (user: IUser): IActionLogin => ({type: AuthEnum.SET_USER, payload: user}),
    setError: (message: string): IActionError => {
        return {type: AuthEnum.SET_ERROR, payload: message}

    },
    setType: (type: any) => {
        return {
            type: AuthEnum.SET_SEND_TYPE,
            payload: type
        }
    },
    setUserProflie:(profileObj:Partial<IProfile>):ISetUserProfile => {
        return {
            type:AuthEnum.SET_USER_PROFILE,
            payload:profileObj
        }
    },
    setUserSettings:(settingObj:Partial<ISetting>):ISetUserSettings => {
        return {
            type:AuthEnum.SET_USER_SETTINGS,
            payload:settingObj
        }
    },
    setAvatarError:(errorMessage:IAvataSaveError):ISetAvatarError => {
        return{
            type:AuthEnum.SET_AVATAR_ERROR,
            payload:errorMessage
        }
    },
    setSettingsProfile:() => {
        
    },
    setTelegram:(boolVal:boolean):ISetTelegram => {
      return {
          type:AuthEnum.SET_TELEGRAM,
          payload:boolVal,
      }
    },
    SetGlobalUserAuth: (isAuth: boolean): SetUserIsAuth => {
        return {
            type: AuthEnum.SET_GLOBAL_ISAUTH,
            payload: isAuth,
        }
    },
    SaveProfile: (TranslateObj:IProfileTranslateObj): ISaveProfile => {
        return {
            type: AuthEnum.SET_PROFILE,
            payload: TranslateObj
        }
    },
    SetPlane: (Plane: PlaneTypes): SetPlane => {
        return {
            type: AuthEnum.SET_PLANE,
            payload: Plane
        }
    },
    SetHasInvites: (hasInvites: boolean): ISetHasInvites => {
        return {
            type: AuthEnum.SET_HAS_INVITES,
            payload: hasInvites
        }
    },
    setProfileUser:(userSettingObj:IUSerInformation):ISetProfileUser => {
        return {
            type:AuthEnum.SET_PROFILE_USER_INFORMATION,
            payload:userSettingObj,
        }
    },
    //refactoring
    saveProfileRequest: (ProfileTranslateObj: IProfileTranslateObj) =>
        async (dispatch: AppDispatch) => {
            //@ts-ignore
            if (!ProfileTranslateObj?.avatar?.includes('image/png')) {
                ProfileTranslateObj.avatar = ProfileTranslateObj.avatar as string
                const response = UserService.saveProfile(ProfileTranslateObj)
                dispatch(AuthGlobalActionCreators.SaveProfile(ProfileTranslateObj))
                return
            }

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            const img = document.createElement('img')
            img.src = ProfileTranslateObj.avatar as string
            // document.body.appendChild(img)
            // Actual resizing
            ctx!.drawImage(img, 0, 0, 300, 300);
            var dataurl = canvas.toDataURL('image/png');
            const file = await urlToFile(ProfileTranslateObj.avatar as string, 'img.jpg', 'image/png')
            //@ts-ignore
            ProfileTranslateObj.avatar = ProfileTranslateObj.avatar as string
            let translateEvents = '0'
            if (JSON.stringify(ProfileTranslateObj.translateEvents) === 'true') translateEvents = '1'
            const bodyFormData = new FormData();

            const JSONAvatar = JSON.stringify( {...ProfileTranslateObj} )

            bodyFormData.append('avatar', file);
            axios({
                method: "post",
                url: `${gettOrigin()}/api/user/save`,
                withCredentials: true,
                data: bodyFormData,
                headers: {"Content-Type": "multipart/form-data"},
            })
                .then(function (response: any) {
                    if(response.data.status === "failed"){
                        dispatch(AuthGlobalActionCreators.setAvatarError({
                            isError:true,
                            TextError:response.data.error.message
                        }))
                    }else{
                        dispatch(AuthGlobalActionCreators.setAvatarError( {isError:false,TextError:''} ))
                        dispatch(AuthGlobalActionCreators.SaveProfile(ProfileTranslateObj))
                    }
                })
        },
    verificationIOS: (phone: string) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setError(''))
                dispatch(AuthGlobalActionCreators.setLoading(true))
                const response = await AuthService.verificationIos(phone);

                if (response.data.status === 'failed') {
                    dispatch(AuthGlobalActionCreators.setError(response?.data?.error?.message))
                } else {
                    //@ts-ignore
                    dispatch(AuthGlobalActionCreators.setType(response.data.sendType as number))
                }
            } catch (e: any) {
                dispatch(AuthGlobalActionCreators.setError(e.response?.data?.message))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        },

    verification: (phone: string, reCaptcha: string) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setError(''))
                dispatch(AuthGlobalActionCreators.setLoading(true))
                const response = await AuthService.verification(phone, reCaptcha);

                if (response.data.status === 'failed') {
                    dispatch(AuthGlobalActionCreators.setError(response?.data?.error?.message))
                } else {
                     //@ts-ignore
                    dispatch(AuthGlobalActionCreators.setType(response.data.sendType as number))
                }
            } catch (e: any) {
                dispatch(AuthGlobalActionCreators.setError(e.response?.data?.message))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        },
    login: (phone: string, code: string, isCryptoPage:boolean) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setError(''))
                dispatch(AuthGlobalActionCreators.setLoading(true))
                const response = await AuthService.login(phone, code, isCryptoPage);
                if (response.data.status === 'failed') {
                    dispatch(AuthGlobalActionCreators.setError(response?.data?.error?.message))
                } else {
                    const User = response?.data?.user
                    dispatch(AuthGlobalActionCreators.setUser(User))
                    dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(true))
                    
                    if(User.status !== StatusTypes.PENDING &&  User.favouritesSelected !== false){
                        if(window.navigator.userAgent.includes('Tradesense')){
                            window.location.replace(ORIGIN + '/events')
                        }
                       }
                }

            } catch (e: any) {

                dispatch(AuthGlobalActionCreators.setError(e.response?.data?.error?.message))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        },

    sendInviteCode: (code: string) =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setError(''))
                dispatch(AuthGlobalActionCreators.setLoading(true))

                const response = await UserService.checkInvite(code)

                if (response.data.status === 'failed') {
                    dispatch(AuthGlobalActionCreators.setError(response?.data?.error?.message))
                } else {
                    const User = response?.data?.user
                    checkForSendYEvent("registered")
                    dispatch(AuthGlobalActionCreators.setUser(User))
                    dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(true))

                }

            } catch (e: any) {
                dispatch(AuthGlobalActionCreators.setError(e.response?.data?.error?.message))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        },

    logout: () =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setLoading(true))
                const response = await AuthService.logout();
                dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(false));
                dispatch(AuthGlobalActionCreators.setUser({} as IUser))

                if(window.navigator.userAgent.includes('Tradesense'))
                    window.location.replace(ORIGIN + '/wizard')
            
            } catch (e: any) {
                dispatch(AuthGlobalActionCreators.setError(e.response?.data?.error?.message))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }

        },
    checkAuthWithSharedData:() =>
        async (dispatch:AppDispatch ) => {
            try {
                //@ts-ignore
                const phone = window._sharedData?.user?.phone

                if(!phone){
                    dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(false));
                    dispatch(AuthGlobalActionCreators.setUser({} as IUser))
                    dispatch(AuthGlobalActionCreators.setLoading(false))
                    return
                }

                // @ts-ignore
                dispatch(AuthGlobalActionCreators.setUser(window._sharedData?.user))
                dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(true))
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
            catch (e:any) {
                dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(false));
                dispatch(AuthGlobalActionCreators.setUser({} as IUser))
            }
            finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        }
    ,
    checkUser:() => async (dispatch: AppDispatch) => {
        const response = await AuthService.checkAuth();
        const User = response?.data?.user
        dispatch(AuthGlobalActionCreators.setUser(User))
    },
    checkAuth: () =>
        async (dispatch: AppDispatch) => {
            try {
                dispatch(AuthGlobalActionCreators.setError(''))
                dispatch(AuthGlobalActionCreators.setLoading(true))
                const response = await AuthService.checkAuth();
                if (response.data.status === 'failed') {
                    dispatch(AuthGlobalActionCreators.setError(response?.data?.error?.message))
                } else {
                    const User = response?.data?.user
                    dispatch(AuthGlobalActionCreators.setUser(User))
                    dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(true))
                }

            } catch (e: any) {
                dispatch(AuthGlobalActionCreators.SetGlobalUserAuth(false));
                dispatch(AuthGlobalActionCreators.setUser({} as IUser))
            } finally {
                dispatch(AuthGlobalActionCreators.setLoading(false))
            }
        },


}