import React, {useCallback, useEffect, useState} from "react";
import '../../styles/Sbscriptionvalid.css'
import GiftModal from "../Modals/GiftModal";
import {useTransition} from "../../hooks/useTransition";
import {DeviceIsMobile, getPlaneString, isFreePlane, SubscriptionIsOver} from "../../Utills/ClearFunctions";
import ProfileFreeDaysAlert from "../Free/ProfileFreeDaysAlert";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import useDate from "../../hooks/useDate";
import SubscriptionExpirationProfileAlert from "../Subscription/SubscriptionExpirationProfileAlert";
import SubscriptionButtonTransition from "../Subscription/SubscriptionButtonTransition";
import SubscriptionError from "../Subscription/SubscriptionError";
import { Trans, useTranslation } from "react-i18next";

function SubscriptionValid() {
    const {transitionToSubscriptionPage,transitionToInvites} = useTransition()
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const User = useTypedSelector(state => state.UserReducer.User)
    const {getValidSubscriptionDate} = useDate()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [amountOfDay, setAmountOfDay] = useState<any>('')
    const [percent, setPercent] = useState<any>('')
    const [dateStr,setDateStr] = useState<string>('')
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const { t } = useTranslation();
    const {getTimeIsOver} = useDate()

    useEffect(() => {
        const {diffDay,percent,dateStr} = getValidSubscriptionDate(User.subscriptionInfo.finishedAt)
        setAmountOfDay(diffDay)
        setPercent(percent)
        setDateStr(dateStr)

    },[])


    const hadleGiftClick = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if(isApplication)
            transitionToInvites()
        else{
            setShow(true)
        }       
    }



    const getIcon = () => {
        const {numberOfDay,timeIsCloseOver,timeIsOver} = getTimeIsOver(User?.subscriptionInfo?.finishedAt)
        if(timeIsCloseOver) return <span>⚠️ </span>
        else return <i className="fas fa-bolt fa-1x text-primary mr-2"></i>

    }

    const chooseSubscriptionAlert = useCallback ( () => {
        const isStoreAccepted = User?.subscriptionInfo?.isActiveAppleSubscription || User?.subscriptionInfo?.isActiveAppleSubscription
            if(isStoreAccepted && !User.subscriptionInfo.finishedAt ){
                return <div className="card card-custom mb-lg-4 validContainer">
                    <div className="card-body py-6 px-2">
                        <p className="mb-0">
                            <SubscriptionError/>
                        </p>
                        <div className="progress mt-2 mb-4" style={{height: '5px'}}>
                            <div className="progress-bar bg-primary" role="progressbar" style={{width: `${percent}%`}}></div>
                        </div>
                        <SubscriptionButtonTransition/>
                    </div>
                </div>
            }

        else return <div className ={
            (User?.subscriptionInfo?.isAvailableTrial && !User?.subscriptionInfo?.finishedAt || !SubscriptionIsOver(User?.subscriptionInfo?.finishedAt)) ?
                `card card-custom mb-lg-4  validContainer`
                : 'card card-custom col-lg-6 mb-lg-4 bg-danger text-white'}>
            {
                // card card-custom col-lg-6 mb-lg-4 bg-danger text-white
                (User?.subscriptionInfo?.isAvailableTrial && !User?.subscriptionInfo?.finishedAt)
                    ? <> <ProfileFreeDaysAlert/> </>
                    : <>
                        {
                            // <SubscriptionExpirationProfileAlert/>
                            (SubscriptionIsOver(User?.subscriptionInfo?.finishedAt))
                                ? <SubscriptionExpirationProfileAlert/>
                                : <div className="card-body py-6 px-2">
                                    <p className="mb-0">

                                        {getIcon()}
                                        


                                         <Trans count={amountOfDay} i18nKey = 'PROFILE_SUBSCRIPTION_EXPIRES'>
                                            {{plane:getPlaneString(Plane)}}
                                            <b></b> 
                                         </Trans>
                                            &nbsp;(<span className="text-nowrap">{dateStr}</span>).
                                         
                                    </p>
                                    <div className="progress mt-2 mb-4" style={{height: '5px'}}>
                                        <div className="progress-bar bg-primary" role="progressbar" style={{width: `${percent}%`}}></div>
                                    </div>
                                    <SubscriptionButtonTransition/>
                                </div>
                        }
                    </>
            }
        </div>
    },[User, amountOfDay, dateStr,Plane])

    // const {t} = useTranslation() 


    return (
        <>
            <GiftModal show={show} handleClose={handleClose}/>
           
            { chooseSubscriptionAlert()}
            
                <div className="col-lg-6 mb-2 validContainer">
                    <div className="py-3 px-10 text-center">
                        <div className="mb-2"><i className="fas fa-gift text-warning fa-2x"></i></div>
                        
                        <div className="mb-2">
                            <Trans  i18nKey = 'PROFILE_INVITE_FRIENDS'>
                                <b></b>
                            </Trans>
                        </div>

                        <a className="btn btn-sm btn-light-primary font-weight-bolder mt-2" onClick={hadleGiftClick}
                        data-target="#invites-modal">
                            {t('PROFILE_OPEN_INVITES')}
                        </a>
                    </div>
                </div>
            

            
        </>
    )
}

export default React.memo(SubscriptionValid)