import { FC, useRef } from "react";
import { useTranslation } from "react-i18next";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { UseActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

const SaveUserProfileError:FC = () => {
    const {saveProfile} = UseActions()
    const Profile = useTypedSelector(state => state.Profile)
    const {t} = useTranslation()
    
    const handleSaveClick = () => {
        saveProfile(Profile)
        setTimeout(() => {
            window.location.href = '#closerefresh'
        },1000)
    }

    return <>
        <button
            type="button"
            onClick={handleSaveClick}
            className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-2 my-2 mr-3 fav_next_button favoritesButton"
        >
             {t('PROFILE_SAVE_CHANGES')}
    </button>
    </>
}

export default SaveUserProfileError