import React, {useContext, useState} from "react";
import ReportModals from "../Modals/ReportModals";
import {useNavigate, useParams} from "react-router-dom";
import {DeviseIsApplicationOnly} from "../../Utills/ClearFunctions";
import {CompanyContext} from "../../Context";
import WidgetLoaders from "../loaders/WidgetLoaders";
import {ORIGIN} from "../../models/Domen";
import { useTranslation } from "react-i18next";

function ReportBody(){
    const {ReportingElements,isLoading} = useContext(CompanyContext)
    const {id} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {t} = useTranslation()

    const handlerModalOpen = () => {
        if(DeviseIsApplicationOnly()){
            window.location.replace(`${ORIGIN}/stocks/${id}/reports`)
            return
        }
        handleShow()
    }

    return(
        <>
            {
                isLoading
                    ? <>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                    </>
                    : <>

                        {
                            ReportingElements.length === 0
                                ? <div className="p-4 font-size-lg text-muted">
                                    <i className="far fa-money-bill-alt text-muted mr-2" aria-hidden="true"></i> {t('STOCK_REPORTS_EMPTY')}
                                </div>
                                :<>
                                    {ReportingElements}
                                    <ReportModals show={show} handleClose={handleClose}/>

                                    <div className="d-flex align-items-center mt-4">
                                        <a style = {{cursor:"pointer"}}className="js__filter-events-by-type" data-pjax="0" onClick={handlerModalOpen}>{t('STOCK_SHOW_MORE')}</a>
                                    </div>
                                </>
                        }

                    </>
            }
        </>
    )
}

export default ReportBody