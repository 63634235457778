import {useTransition} from "../../hooks/useTransition";
import {useLocation} from "react-router-dom";
import {UseActions} from "../../hooks/useActions";
import React, {useEffect, useRef, useState} from "react";
import {FilterConstant} from "../../models/FilterEvent";
import CommentsModal from "../Modals/CommentsModal";
import {ICompanyInformationCard} from "../Cards/CompanyInformationCard";
import CommentCard from "../Cards/CommentCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import useDate from "../../hooks/useDate";

interface ITimeLineEarly {
    from:string,
    to:string
    isCommentOpen: boolean
    CardInformation:ICompanyInformationCard
    fromTimestamp:number,
    toTimestamp:number,
    id?:string
    countComments?:number,
    time?:string
    fullDateTootip?:string,
}

function StopEventTimeLine(props: ITimeLineEarly) {
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent} = UseActions()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const refProps = useRef<any>(props)
    const {getshortTime} = useDate() 

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type: FilterConstant.YEARLY_MINIMUM_MAXIMUM,
                    data: {...props, isCommentOpen: false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }


    const renderTooltip = (props: any) =>
    (
        <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
            <div className="overlay">
                { props.header }
                {/* {'21 февр. 2022 г., 14:04:09' + props.time + refProps.current.dateFull }  */}
                {/* {refProps.current.dateFull +', ' +  refProps.current.time }  */}
                {refProps.current.fullDateTootip}
                </div>
        </Tooltip>
    );


    const {t} =useTranslation()


    return (
        <>
            <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.STOP_EVENT}
                           data={{...{...props, isCommentOpen: false}}}/>
            <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">
                
                
                
                <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <div data-tip={'s'}
                         className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1">{props.time}
                    </div>
                </OverlayTrigger>
                
                
                {/* <div className="timeline-label font-weight-bolder text-muted font-size-lg">
                    <span title="" data-toggle="tooltip" data-original-title="24 февр. 2022 г., 07:30:34">07:30</span>
                </div> */}

                <div className="timeline-badge"> <i className="fas fa-ban text-danger mr-2"></i> </div>


                <div className="font-size-md timeline-content pl-3">

                     <span className="font-weight-bolder mb-2">
                        {t('EVENT_SPB_HALT')}
                    </span> <span className="py-2">
                        <Trans i18nKey={'EVENT_SPB_HALT_TIMELINE_TIME'}>
                            {{from:getshortTime(props.fromTimestamp)}} {{to:getshortTime(props.toTimestamp)}}
                        </Trans>
                        {/* c  {props.from} до  {props.to} */}
                    </span>

                    <div className="d-flex align-items-center">
                        <CommentCard countComments={props.countComments} isCommentOpen={isCommentOpen || props.isCommentOpen} callback={setShow} show={show} id={props.id} />

                        {/*{*/}
                        {/*    (isCommentOpen || props.isCommentOpen) && <a*/}
                        {/*        onClick={showModal} className="ml-auto mt-1 font-size-sm text-primary"*/}
                        {/*        href="" data-toggle="modal"*/}
                        {/*        data-event-id="332244" data-pjax="0"><span*/}
                        {/*        className="far fa-comments"></span></a>*/}
                        {/*}*/}


                    </div>
                </div>
            </div>
        </>
    )
}

export default StopEventTimeLine