import React from 'react';

function FavouritesEvent(){

    return (
        <div>
            {/*<h1> Избранное </h1>*/}
        </div>
    );
}

export default FavouritesEvent;