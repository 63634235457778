import React, {useEffect, useState} from "react";
import {IListNotification} from "../../models/Notifications";
import NotificationSwitch from "../Notifications/NotificationSwitch";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FilterConstant} from "../../models/FilterEvent";
import UserService from "../../Services/UserService";
import useTelegram from "../../hooks/useTelegram";
import { BlueAlert } from "../Alerts";
import { Trans, useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";


export const setSwitchRowClass = (isMobileSwitchesNull:boolean,isTelgramSwitchesNull:boolean) => {
    if(!isMobileSwitchesNull && !isTelgramSwitchesNull)return 'col-6'
    else if(isMobileSwitchesNull) return 'col-8'
    else if(isTelgramSwitchesNull) return 'col-5'
}


export const setSwitchRowClassHeader = (isNull:boolean) => {
    return isNull ? 'col-8' : 'col-6'
}

function NotificationBody() {
    // const showTelegram = useTypedSelector(state => state.EventReducer.showTelegram)

    useEffect(() => {
        let styles = `
                    .tooltip-inner {
                        max-width: 350px;
                    }
                    .tooltip > div.tooltip-inner {
                                background-color: #181C32;
                                color: white !important;
                                 
                            }
                    .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
                    top: -1px;
                    border-width: 0.4rem 0.4rem 0;
                    border-top-color: #181C32 !important;
                   
                    }
                `
        let styleSheet = document.createElement("style")
        styleSheet.innerText = styles

        const TooltipId = 'TooltipStyle'
        styleSheet.id = TooltipId
        document.head.appendChild(styleSheet)
        return () => {
            const sheets = document.getElementById(TooltipId)
            sheets?.remove()
        }

    }, [])

    const {t} =useTranslation()




    const [listNotification, setListNotification] = useState<Array<IListNotification>>([
        {
            name: `${t('NOTIFICATIONS_PRICE_MOVEMENT')}`,
            icon: 'fa fa-chart-line',
            tooltip: `${t('NOTIFICATIONS_PRICE_MOVEMENT_TEXT')}`, 
             checkTelegram: false,
            checkApp: false,
            id: FilterConstant.PRICE_FLUCTUATIONS
        },
        {
            name: `${t('NOTIFICATIONS_PRICE_52HIGHTLOW')}`,
            icon: 'fas fa-exchange-alt',
            tooltip: `${t('NOTIFICATIONS_PRICE_52HIGHTLOW_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.YEARLY_MINIMUM_MAXIMUM
        },
        {
            name: `${t('NOTIFICATIONS_PRESS_RELEASE')}`,
            icon: 'far fa-newspaper',
            tooltip: `${t('NOTIFICATIONS_PRESS_RELEASE_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.PRESS_RELEASES
        },
        {
            name: `${t('NOTIFICATIONS_FINTWEET')}`,
            icon: 'fas fa-volume-up',
            tooltip:`${t('NOTIFICATIONS_FINTWEET_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.FINANCIAL_TWEETS
        },
        {
            name: `${t('NOTIFICATIONS_FDA')}`,
            icon: 'fas fa-pills',
            tooltip:  `${t('NOTIFICATIONS_FDA_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.FDA
        },
        {
            name:  `${t('NOTIFICATIONS_FAA')}`,
            icon: 'fas fa-rocket',
            tooltip:  `${t('NOTIFICATIONS_FAA_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.FAA
        },
        {
            name: `${t('NOTIFICATIONS_ACQUISITION')}`,
            icon: 'fas fa-bolt',
            tooltip:`${t('NOTIFICATIONS_ACQUISITION_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.ABSORPTION
        },
        {
            name: `${t('NOTIFICATIONS_REPORT')}`,
            icon: 'far fa-money-bill-alt',
            tooltip: `${t('NOTIFICATIONS_REPORT_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.REPORTING
        },
        {
            name: `${t('NOTIFICATIONS_SEC')}`,
            icon: 'fab fa-wpforms',
            tooltip: `${t('NOTIFICATIONS_SEC_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.SEC
        },
        {
            name: `${t('NOTIFICATIONS_INSIDER')}`,
            icon: 'fas fa-handshake',
            tooltip: `${t('NOTIFICATIONS_INSIDER_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.INSIDER_CLOSES
        },
        {
            name: `${t('NOTIFICATIONS_FORECAST')}`,
            icon: 'fas fa-info-circle',
            tooltip: `${t('NOTIFICATIONS_FORECAST_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.ANALYST_FORECASTS
        },
        {
            name: `${t('NOTIFICATIONS_PRICE_TARGET')}`,
            icon: 'far fa-dot-circle',
            tooltip: `${t('NOTIFICATIONS_PRICE_TARGET_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.TARGET_PRICE
        },
        {
            name: `${t('NOTIFICATIONS_MARKET_DATA')}`,
            icon: 'fas fa-search',
            tooltip: `${t('NOTIFICATIONS_MARKET_DATA_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.MARKET_DATA
        },
        {
            name: `${t('NOTIFICATIONS_INFLUENCER_TWEET')}`,
            icon: 'fab fa-twitter',
            tooltip: `${t('NOTIFICATIONS_INFLUENCER_TWEET_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.TWEETS_OF_POPULAR_PEOPLE
        },
        {
            name: `${t('NOTIFICATIONS_MEDIA_MENTION')}`,
            icon: 'far fa-newspaper',
            tooltip: `${t('NOTIFICATIONS_MEDIA_MENTION_TEXT')}`,
            checkTelegram: false,
            checkApp: false,
            id: FilterConstant.MEDIA_PUBLICATIONS
        },


    ])


    const [thirtySwitch,setThirtySwitch] = useState<any>(
        {
            id:FilterConstant.MINUTES_EVENT_SUMMARY,
            checkTelegram: false,
            checkApp: false,
        }
    )

    const [fireSwitch,setFireSwitch] = useState<any>(
        {
            id:FilterConstant.ALL_EVENTS,
            checkTelegram: false,
            checkApp: false,
        }
    )



    const setNotification = (telegramNotifications: any,AppNotification:any) => {
        const NotificationState = new Map()
        let AppNotificationState = new Map()

        let Thirty = {
            id:FilterConstant.MINUTES_EVENT_SUMMARY,
            checkApp:'',
            checkTelegram:'',
        }

        let Fire = {
            id:FilterConstant.ALL_EVENTS,
            checkApp:'',
            checkTelegram:'',
        }
        if(telegramNotifications){
            telegramNotifications.commonTypes.forEach((NotificationObj: any) => {
                NotificationState.set(NotificationObj.type, NotificationObj.on)
            })
    
            telegramNotifications.hyperSenseModeTypes.forEach((NotificationObj: any) => {
                NotificationState.set(NotificationObj.type, NotificationObj.on)
            })
        }
        
        

        if(AppNotification){
            AppNotification.commonTypes.forEach((NotificationObj: any) => {
                AppNotificationState.set(NotificationObj.type, NotificationObj.on)
            })
    
            AppNotification.hyperSenseModeTypes.forEach((NotificationObj: any) => {
                AppNotificationState.set(NotificationObj.type, NotificationObj.on)
                // Thirty.checkApp = NotificationObj.on
                // Fire.checkApp = NotificationObj.on
            }) 
        }
       

        Thirty.checkTelegram = NotificationState.get(FilterConstant.MINUTES_EVENT_SUMMARY)
        Fire.checkTelegram = NotificationState.get(FilterConstant.ALL_EVENTS)

        Thirty.checkApp = AppNotificationState.get(FilterConstant.MINUTES_EVENT_SUMMARY)
        Fire.checkApp = AppNotificationState.get(FilterConstant.ALL_EVENTS)

        const newListNotification = listNotification.map((listElement) => {
            return {
                ...listElement,
                checkTelegram:NotificationState.get(listElement.id),
                checkApp:AppNotificationState.get(listElement.id)
            }
        })

        setListNotification(newListNotification)
        setFireSwitch({...Fire})
        setThirtySwitch({...Thirty})
    }
    
    const [isMobileSwitchesNull, setIsMobileSwitchesNull] = useState<boolean>(false)
    const [isTelgramSwitchesNull, setIsTelgramSwitchesNull] = useState<boolean>(false)



    const getNotification = async () => {
        const response = await UserService.getNotification()
        
        if(!response.data.telegramNotifications){
            setIsTelgramSwitchesNull(true)
        }
        if(!response.data.mobileNotifications) {
            setIsMobileSwitchesNull(true)
        }

        setNotification(response.data.telegramNotifications,response.data.mobileNotifications)
    }

    useEffect(() => {
        getNotification()
    }, [])


    const renderTooltipSummary = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">{t('NOTIFICATIONS_SUMMARY_30M_TEXT')}
                </div>
            </Tooltip>
        );

    const renderTooltipFire = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">
                    {t('NOTIFICATIONS_EVENTS_FIRE_TEXT')}
                </div>
            </Tooltip>
        );

    const replaceSwitchTelegram = (name: string) => {
        setListNotification(
            listNotification.map((switchElement) => {
                if (switchElement.name === name) {
                    saveNotification(1,switchElement.id,!switchElement.checkTelegram)
                    return {...switchElement, checkTelegram: !switchElement.checkTelegram}
                }
                else return {...switchElement}
            })
        )
    }

    const saveNotification = (source:number, type:FilterConstant, on:boolean) => {
        UserService.saveNotification({
            source,
            type,
            on
        })
    }

    const replaceSwitchApp = (name: string) => {
        setListNotification(
            listNotification.map((switchElement) => {
                if (switchElement.name === name){
                    saveNotification(2,switchElement.id,!switchElement.checkApp)
                    return {...switchElement, checkApp: !switchElement.checkApp}
                }
                else return {...switchElement}
            })
        )
    }

    const thirtySwitchTelegramHandler = () => {
        saveNotification(1,thirtySwitch.id,!thirtySwitch.checkTelegram)
        setThirtySwitch((prev:any) => {
            return {
                ...prev,
                checkTelegram:!thirtySwitch.checkTelegram
            }
        })
    }

    const thirtySwitchAppHandler = () => {
        saveNotification(2,thirtySwitch.id,!thirtySwitch.checkApp)
        setThirtySwitch((prev:any) => {
            return {
                ...prev,
                checkApp:!thirtySwitch.checkApp
            }
        })
    }

    const fireSwitchTelegramHandler = () => {
        saveNotification(1,fireSwitch.id,!fireSwitch.checkTelegram)
        setFireSwitch((prev:any) => {
            return {
                ...prev,
                checkTelegram:!fireSwitch.checkTelegram
            }
        })
    }

    const fireSwitchAppHandler = () => {
        saveNotification(2,fireSwitch.id,!fireSwitch.checkApp)
        setFireSwitch((prev:any) => {
            return {
                ...prev,
                checkApp:!fireSwitch.checkApp
            }
        })
    }

    const NotificationElements = listNotification.map((switchElement) => {
        return <NotificationSwitch
         {
            ...{
                ...switchElement, 
                replaceSwitchApp: replaceSwitchApp, 
                replaceSwitchTelegram,
                isTelgramSwitchesNull,
                isMobileSwitchesNull,
                
            }}/>
    })

    const  {installTelegram} = useTelegram()

    return (
        <>
            <BlueAlert isOpen = {isTelgramSwitchesNull}>
                <div className="alert-icon"><i className="fab fa-telegram fa-2x mr-2"></i></div>
                    <div className="alert-text">
                        <Trans i18nKey = 'NOTIFICATIONS_APP_NO_TELEGRAM'>
                            <a href =''  onClick={installTelegram} className="font-weight-bolder"></a> 
                        </Trans>
                </div>
            </BlueAlert>

            <BlueAlert isOpen = {isMobileSwitchesNull}>
                <div className="alert-icon"><i className="fas fa-mobile-alt text-primary fa-2x mb-2" aria-hidden="true"></i></div>
                    <div className="alert-text">
                        {t('NOTIFICATIONS_APP_NO_PUSHES')}
                </div>
            </BlueAlert>

            <div className="row py-6">
                <div className={` ${setSwitchRowClassHeader(isMobileSwitchesNull || isTelgramSwitchesNull)}` } ></div>

                {
                    !isTelgramSwitchesNull && <div className="col-3 text-center font-size-sm"><i
                        className="fab fa-telegram text-primary fa-2x mb-2" aria-hidden="true"></i> <br/>
                        <div>{t('NOTIFICATIONS_TG')}</div>
                    </div>
                }
                
                {
                    !isMobileSwitchesNull && <div className="col-3 text-center font-size-sm">
                        <i className="fas fa-mobile-alt text-primary fa-2x mb-2" aria-hidden="true"></i> <br/>
                        {t('NOTIFICATIONS_APP')}
                </div>
                }
                
            </div>

            {NotificationElements}

            <hr/>


            <div className="font-weight-bolder font-size-h6 mb-4">{t('NOTIFICATIONS_EVENTS_EXPLAINS')}</div>

            <div className="row py-2">
                <div className={`${setSwitchRowClass(isMobileSwitchesNull,isTelgramSwitchesNull )} px-2`}>

                    <i className="fas fa-fire text-danger fa-fw mr-0" aria-hidden="true"></i>
                    {t('NOTIFICATIONS_SUMMARY_30M')}
                    &nbsp;
                    <OverlayTrigger
                        delay={{show: 1, hide: 1}}
                        overlay={renderTooltipSummary}
                        placement={'top'}
                    ><i className="fas fa-info-circle fa-1x text-muted"
                        data-toggle="tooltip" data-theme="dark" title=""
                        data-original-title= {`${t('NOTIFICATIONS_SUMMARY_30M_TEXT')}`}
                        aria-hidden="true"></i>
                    </OverlayTrigger>
                </div>

                <div className="col-3 text-center d-flex justify-content-center">
                {
                    !isTelgramSwitchesNull && <span className="switch switch-sm">
                                <label><input onChange={thirtySwitchTelegramHandler} checked={thirtySwitch.checkTelegram} className="js__notification-types" type="checkbox" data-source="1" data-type="4"
                                            name=""/><span></span></label>
                            </span>
                }
                    
                </div>
                <div className="col-3 text-center d-flex justify-content-center">
                


                {
                    !isMobileSwitchesNull && <span className="switch switch-sm">
                                <label><input  onChange={thirtySwitchAppHandler} checked={thirtySwitch.checkApp}className="js__notification-types" type="checkbox" data-source="2" data-type="4"
                                            name=""/><span></span></label>
                            </span>
                }
                
                
                
                
                </div>
            </div>


            <div className="row py-2">
                <div className={`${setSwitchRowClass(isMobileSwitchesNull, isTelgramSwitchesNull)} px-2`} >
                    <i className="fas fa-fire text-danger fa-fw mr-0" aria-hidden="true"></i>
                    {t('NOTIFICATIONS_EVENTS_FIRE')}
                    &nbsp;
                    <OverlayTrigger
                        delay={{show: 1, hide: 1}}
                        overlay={renderTooltipFire}
                        placement={'top'}
                    >
                        <i className="fas fa-info-circle fa-1x text-muted"></i>
                    </OverlayTrigger>

                </div>

                <div className="col-3 text-center d-flex justify-content-center">
                   {
                    !isTelgramSwitchesNull && <span className="switch switch-sm">
                        <label><input onChange={fireSwitchTelegramHandler} checked={fireSwitch.checkTelegram} className="js__notification-types" type="checkbox" data-source="1" data-type="20"
                                    name=""/><span></span></label>
                    </span>
                   }
                   
                    
                </div>

                <div className="col-3 text-center d-flex justify-content-center">
                    
                {
                    !isMobileSwitchesNull && <span className="switch switch-sm">
                        <label><input onChange={fireSwitchAppHandler} checked={fireSwitch.checkApp} className="js__notification-types" type="checkbox" data-source="2" data-type="20"
                                  name=""/><span></span></label>
                    </span>
                }
                    
                    
                </div>
            </div>

        </>
    )
}

export default React.memo(NotificationBody)