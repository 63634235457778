import {useState} from "react"
import {UseActions} from "../../hooks/useActions"
import {useTypedSelector} from "../../hooks/useTypedSelector"
import UserService from "../../Services/UserService"
import useEvent from "./useEvent"
import {EventTopicEnum} from "../../models/EventContentModel";
import { IListStruct } from "../../models/FilterEvent"
import { t } from "i18next"

function useEventAllEventQuery(setHiddenCounts:(counts:number) => void){
    const filter = useTypedSelector(state => state.Filter.filters)
    const [lastAllEventId,setLastAllEventId] = useState<number| null>(null)
    const {setAllEventsMutation,setFullEvents,setAllEventPrevFilter,setLoadingAllEvents} = UseActions()
    const allEvents = useTypedSelector(state => state.AllEventsReducer.Elements)
    const [allEventIsLoading, setAllEventIsLoading] = useState<boolean>(false)
    const [allButtonSpinnerIsSpin, setAllButtonSpinnerIsSpin] = useState<boolean>(true)
    const {replaceDataEvent,getPeriod} = useEvent()

    const lastRowHandler = async () => {
        if(allEventIsLoading) return
        const response = await UserService.getMoreEventsAll(filter,lastAllEventId as number)
        if(response.data.events.length === 0) return
        setLastIdHandler(response.data)
        const JsxData = await replaceDataEvent(response.data)
        setFullEvents([...allEvents,...JsxData])
    }

    const setLastIdHandler = (data:any) => {
        setLastAllEventId(data.lastId)
    }
    
    const getAllEvents = async (filterParams?:IListStruct []) => {
        if(filterParams){
            const response = await UserService.getAllEvents(filterParams)
            return response.data
        }    
        else{
            const response = await UserService.getAllEvents(filter)
            return response.data
        }
    }

    const setAllEvents = async (nfilter:Array<any>) => {
        
        const filter = JSON.parse( 
            window.localStorage.getItem('eventTypes') as string
        )

        setAllEventPrevFilter(filter)
        setAllEventIsLoading(true)
        setAllButtonSpinnerIsSpin(true)
        setLoadingAllEvents(true)
        const data = await getAllEvents(filter)
        setLoadingAllEvents(false)

        if(data.events.length === 0) {
            setAllEventIsLoading(false)
            setAllButtonSpinnerIsSpin(false)
            setFullEvents([<span>{t('FEED_FILTER_NO_EVENTS')}</span>])
            return
        }

        setLastIdHandler(data)
        getPeriod(data, EventTopicEnum.ALL_STOCKS)
        const JsxData = await replaceDataEvent(data)
        setFullEvents(JsxData)
        setAllEventIsLoading(false)
        setAllButtonSpinnerIsSpin(false)
        setHiddenCounts(data.hiddenEventsCount)
    }

    const setMutableAllEvents = async (filter:Array<any>) => {
        setAllButtonSpinnerIsSpin(true)
        
        setLoadingAllEvents(true)
        const data = await getAllEvents()
        setHiddenCounts(data.hiddenEventsCount)
        setLastIdHandler(data)
        const JsxData = await replaceDataEvent(data)
        setLoadingAllEvents(false)

        setAllEventsMutation(JsxData)
        setAllButtonSpinnerIsSpin(false)
    }

    const preloadAllEvent = async (filter:Array<any>) => {
        
        setAllEventPrevFilter(filter)
        setMutableAllEvents(filter)
    }

    return {setAllEvents, lastRowHandler, preloadAllEvent, allEventIsLoading, allButtonSpinnerIsSpin}
}
export default useEventAllEventQuery