import {allEventEnum, ISetFavouritesPeriod, ISetFullEvent, ISetNumberNewEvent, ISetTrends, ISetfavouritesElementIsLoading} from './types'
import {IPeriod} from "../UserEvent/types";

export const FavouritesActionCreators = {
    setfavouritesElementIsLoading:(payload:boolean):ISetfavouritesElementIsLoading => {
        return {
            type:allEventEnum.SET_FAVOURITES_ELEMENT_IS_LOADING,
            payload:payload,
        }
    },
    setFavouritesPeriod:(period:IPeriod):ISetFavouritesPeriod => {
        return {
            type:allEventEnum.SET_PERIOD,
            payload:period,
        }
    },
    deleteFavourites:(key:string) => {
        return{
            type:allEventEnum.DELETE_EVENT_FAVOURITES,
            payload:key
        }
    },
    setFavouritesEventPrevFilter:(newFilter:Array<any>) => {
        return{
            type:allEventEnum.SET_FAVOURITES_RPEV_FILTER,
            payload:newFilter
        }
    },
    setFavoutitesEventsMutation:(TrendsElements:any):ISetTrends => {
        return{
            type:allEventEnum.SET_FAVOURITES_EVENTS,
            payload:TrendsElements
        }
    },
    setFavoutitesNumberNewEvent:(countNewEvents:number):ISetNumberNewEvent => {
        return{
            type:allEventEnum.SET_FAVOURITES_NUMBER_NEW_EVENT,
            payload:countNewEvents
        }
    },
    setFavoutitesNewEvent:() => {
        return {
            type:allEventEnum.SET_FAVOURITES_NEW_ELEMENTS
        }
    },
    setFullFavoutitesEvents:(TrendsElements:any):ISetFullEvent => {
        return {
            type:allEventEnum.SET_FAVOURITES_FULL_EVENTS,
            payload:TrendsElements,
        }
    },
    
}