import {Modal} from "react-bootstrap";
import React, {useRef, useState} from "react";
import '../../styles/Cropp.css'
import "cropperjs/dist/cropper.css";
import { t } from "i18next";

interface CommentsModal{
    show: boolean,
    handleClose: () => void,
    imgUrl: null | string | ArrayBuffer,
    children:JSX.Element,
    acceptCrop: () => void
}

function CropModal({show,handleClose,imgUrl,children,acceptCrop}: CommentsModal){

    const applyCrop = () => {
        handleClose()
    }

    return(
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
            </Modal.Header>

            <Modal.Body>
                {children}

            </Modal.Body>

            <Modal.Footer>
                <button onClick={applyCrop} type="button" className="btn btn-secondary" data-dismiss="modal">{t('COMMON_BUTTON_CANCEL')}</button>
                <button onClick={acceptCrop} type="button" className="btn btn-primary" id="crop">{t('COMMON_BUTTON_APPLY')}</button>
            </Modal.Footer>
        </Modal>
    )
}

export default CropModal