import React, { useEffect } from "react"
import { IImage } from "./DescriptionBarLargeImage"

function FavouritesImage({src}:IImage){

    return <div
        className="symbol symbol-40 symbol-lg-40 symbol-circle"
         style = {{
            backgroundImage:`url(${src})`,
            backgroundSize: 'cover',
            width:'40px',
            height: '40px', 
            backgroundColor:'gray'
            }}>
    </div>
    
}
export default FavouritesImage