import {useTransition} from "../../hooks/useTransition";
import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { gettOrigin, isFreePlane } from "../../Utills/ClearFunctions";
import { useTranslation } from "react-i18next";
import { count } from "console";

interface ISingleEventFree{
    hiddenCounts:number
}
function SingleEventFree( {hiddenCounts}:ISingleEventFree) {
    const {transitionToSubscriptionPage} = useTransition()
    const [isShow,setIsShow] = useState<boolean>(false)
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const User = useTypedSelector(state => state.UserReducer.User)
    const {t} = useTranslation()
    useEffect(() => {
        if(hiddenCounts === 0) {setIsShow(false); return}
        setIsShow( isFreePlane(Plane,User.subscriptionInfo.finishedAt) )
        const isFree = isFreePlane(Plane,User.subscriptionInfo.finishedAt)
    },[hiddenCounts])

    return (
        <>
        {
            isShow && 
            <>
            <div
                    className="event-wrapper event-wrapper-translated d-flex flex-lg-fill card card-custom card-shadowless p-5 bg-gray-100 text-dark mb-4"
                    style={{filter: 'blur(4px)', pointerEvents: 'none'}}>
            <span className="font-weight-bolder mb-2">
                <i className="far fa-newspaper text-success mr-2"></i>  {t('EVENT_MEDIA_MENTION')} → YahooFinance (02:05:42)
            </span>
                    <span className="py-2 event--original-text">
                <span className="font-weight-bolder">
                    The Ownership Structure of Palantir Technologies (NYSE:PLTR) is Double Edged Sword for the Stock
                </span>
                Palantir Technologies Inc. (NYSE:PLTR) has become a market favorite amongst retail investors, while institutional investors remain more cautious. This could lead to some big price moves if either group is proved right or wrong on the company.
            </span>
                    <span className="py-2 event--translated-text">
                <span className="font-weight-bolder">{t('LOCKED_EVENT_HEADER')}</span>
                    {
                        t('LOCKED_EVENT_TEXT')
                    }
            </span>
                    <span className="mt-1 font-size-sm">
                <a target="_blank" href="">
                    {t('STOCK_PRESS_RELEASES_SOURCE')}
                </a>
                |
                <a className="js__translate-event event--original-text" href="">{t('EVENT_SHOW_TRANSLATE')}</a>
                <a className="js__original-event event--translated-text" href="">{t('EVENT_SHOW_ORIGINAL')}</a>
            </span>
        </div>

            <div className="timeline-item align-items-start mb-4">
                <div className="timeline-label font-weight-bolder text-muted font-size-lg"></div>
                <div className="font-size-md timeline-content">
                    <div className="alert alert-light">
                        <i className="fas fa-info-circle font-size-sm"></i>
                        <b>
                            &nbsp;{ t("LOCKED_EVENTS_HIDDEN", {count:hiddenCounts, countString:hiddenCounts.toLocaleString() }) }
                        </b>
                        <p>
                            {t('LOCKED_EVENTS_24H')}
                        </p>
                        <a data-pjax="0" href={`${gettOrigin()}/finance/subscription`}
                           onClick={transitionToSubscriptionPage}
                           className="btn btn-primary btn-sm font-weight-bolder">
                            {t('LOCKED_EVENTS_BUTTON')}
                        </a>
                    </div>
                </div>
            </div>

            
            
            </>
        }
        

        </>
    )
}

export default React.memo(SingleEventFree)