import React from "react"
import { useTranslation } from "react-i18next";
import MarketMap from '../../Widgets/MarketMap/MarketMapWidget';
import '../../../styles/ListCompany.css'

const MarketMapView = () => {
    const { t } = useTranslation()

    return <>
        <div className='marketMapContainer'>
            <div>
                <h3 className="font-weight-bolder">{t('STOCKS_MARKETMAP')}</h3>
                <MarketMap />
                <div className="mb-6">
                    <div>
                        <small>
                            <span className="badge mr-1" style={{ background: '#ff6464' }}>
                                -3%
                            </span>
                            <span className="badge mr-1" style={{ background: "#f4a1a1" }}>
                                -2%
                            </span>
                            <span className="badge mr-1 text-danger"
                                style={{ background: '#ffd0d0' }}>-1%
                            </span>
                            <span className="badge mr-1 text-dark"
                                style={{ background: "#d8d8d8" }}>0%</span>
                            <span className="badge mr-1 text-success"
                                style={{ background: '#def5d5' }}>
                                +1%
                            </span>
                            <span className="badge mr-1" style={{ background: "#99c986" }}>
                                +2%
                            </span>
                            <span className="badge mr-1" style={{ background: '#53ae2e' }}>
                                +3%
                            </span>
                        </small>
                    </div>
                    <small>
                        <i className="fa fa-info-circle fa-1x" aria-hidden="true"></i>
                        &nbsp;
                        {t('STOCKS_MARKETMAP_HELP')}
                    </small>
                </div>
            </div>
        </div>
    </>
}
export default MarketMapView