import {Modal} from "react-bootstrap";
import React from "react";
import useTelegram from "../../hooks/useTelegram";
import { t } from "i18next";
import { Trans } from "react-i18next";

interface IMutedStocks {
    show: boolean,
    handleClose: () => void
}

function TelegramModal({show, handleClose}: IMutedStocks){
    const {SetShowTelegram, installTelegram} = useTelegram()

    return(
        <Modal show={show} onHide={() => {SetShowTelegram(false);handleClose()}}>
            <Modal.Header closeButton>
                <h5 className="modal-title font-weight-bolder">{t('TG_BANNER_BOT')}</h5>
            </Modal.Header>

            <div className="modal-body">

                <div className="mb-5 text-center card card-custom shadow-sm p-10 bg-gray-800 text-white">
                    <h2 className="font-weight-bolder mb-4">{t('TG_BANNER_BOT')}</h2>
                    <a target="_blank" onClick={installTelegram} className="btn btn-primary font-size-lg font-weight-bolder px-8 py-5 shadow-lg"
                    >
                        <i className="fab fa-telegram" aria-hidden="true"></i> {t('TG_BANNER_BOT_TELEGRAM')}
                    </a>
                </div>

                <hr className="my-6"/>
                    <h3 className="font-weight-bolder">{t('TG_BANNER_LINE_1')}</h3>
                    <p className="text-dark-75">{t('TG_BANNER_LINE_2')}</p>
                    <div className="d-flex align-items-start flex-grow-1">
                        <div className="d-flex flex-column flex-grow-1">
                            <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_3')}</h4>
                            <p className="text-muted">{t('TG_BANNER_LINE_4')}</p>
                            <div
                                className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                <Trans i18nKey={'TG_BANNER_LINE_5'}>
                                    <div  className="font-weight-bolder">
                                    </div>
                                    <div></div>
                                </Trans>
                                {/* {t('TG_BANNER_LINE_5')} */}
                                {/* <span className="font-weight-bolder">
                                    🟢 $CRWD +2.27%
                                </span>$237.8 → $243.19
                                <br/> (CrowdStrike Holdings) */}
                            </div>
                        </div>
                    </div>
                    <div className="d-flex align-items-start flex-grow-1 mt-4">
                        <div className="d-flex flex-column flex-grow-1">
                            <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_6')}</h4>
                            <p className="text-muted">{t('TG_BANNER_LINE_7')}</p>
                            <div
                                className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                
                                <Trans i18nKey={'TG_BANNER_LINE_8'}>
                                    <div className="font-weight-bolder">
                                    </div>
                                    <div></div>
                                </Trans>
                                
                                
                                {/* <span className="font-weight-bolder">📣 $UBER (Uber Technologies) → @sbwcws</span>
                                Uber купит оставшиеся 47% акций стартапа по доставке продуктов Cornershop */}
                            </div>
                        </div>
                    </div>
                    <hr className="my-6"/>
                        <div className="d-flex align-items-start flex-grow-1">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_9')}</h4>
                                <p className="text-muted">{t('TG_BANNER_LINE_10')}</p>
                                <div
                                    className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                     <Trans i18nKey={'TG_BANNER_LINE_11'}>
                                        <div className="font-weight-bolder">
                                        </div>
                                        <div></div>
                                    </Trans>
                                    
                                    
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-grow-1 mt-4">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_12')}</h4>
                                <p className="text-muted">{t('TG_BANNER_LINE_13')}</p>
                                <div
                                    className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                    <Trans i18nKey={'TG_BANNER_LINE_14'}>
                                        <div className="font-weight-bolder">
                                        </div>
                                        <div></div>
                                    </Trans>
                                    
                                    {/* <span
                                        className="font-weight-bolder">🐦 $TSLA (Tesla) → Твит популярного человека</span>
                                    <b>Elon Musk | 57M (01:20:02)</b>
                                    В общем, всем, кто заинтересован в работе над проблемами искусственного интеллекта
                                    физического слова, следует подумать о присоединении к Tesla. Самый быстрый путь к
                                    воплощению ваших идей в жизнь. */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-grow-1 mt-4">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_15')}</h4>
                                <p className="text-muted">{t('TG_BANNER_LINE_16')}</p>
                                <div
                                    className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                     <Trans i18nKey={'TG_BANNER_LINE_17'}>
                                     <div className="font-weight-bolder">
                                        </div>
                                        <div></div>
                                    </Trans>
                                    {/* <span className="font-weight-bolder">🤝 $PLTR (Palantir Technologies) → Инсайдерская торговля</span>
                                    Бакли Джеффри: Продал 61 обыкновенную акцию класса A по средней цене 18,61 доллара
                                    США 13 мая 2021 года, владение уменьшилось на 0% до 572081 акции */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-grow-1 mt-4">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_18')}</h4>
                                <p className="text-muted">{t('TG_BANNER_LINE_19')}</p>
                                <div
                                    className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                    <Trans i18nKey={'TG_BANNER_LINE_20'}>
                                    <div className="font-weight-bolder">
                                        </div>
                                        <div></div>
                                    </Trans>
                                    {/* <span className="font-weight-bolder">💵 $ADBE (Adobe) → Отчётность</span>
                                    Прибыль на акцию за 2 квартал 3,03 доллара США, консенсус-прогноз 2,81 доллара США */}
                                </div>
                            </div>
                        </div>
                        <div className="d-flex align-items-start flex-grow-1 mt-4">
                            <div className="d-flex flex-column flex-grow-1">
                                <h4 className="font-weight-bolder">{t('TG_BANNER_LINE_21')}</h4>
                                <p className="text-muted">{t('TG_BANNER_LINE_22')}</p>
                                <div
                                    className="d-flex flex-lg-fill card card-custom shadow-sm p-5 bg-primary text-white font-size-lg">
                                    <Trans i18nKey={'TG_BANNER_LINE_23'}>
                                        <div className="font-weight-bolder">
                                        </div>
                                        <div></div>
                                    </Trans>
                                    
                                    {/* <span className="font-weight-bolder">🎯 $DOCU (DocuSign) → Целевая цена</span>
                                    Аналитик Wedbush Дэниел Айвс поддерживает DocuSign с опережающей динамикой и
                                    повышает целевую цену с 260 до 290 долларов. */}

                                </div>
                            </div>
                        </div>
            </div>


        </Modal>
    )
}
export default TelegramModal