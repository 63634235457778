import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Alert, themeAlertEnum } from "../components/Alerts";
import UserInfo from "../components/Profile/UserInfo";
import { UseActions } from "../hooks/useActions";
import useSaveProfileHook from "../hooks/useSaveProfileHook";
import { useTypedSelector } from "../hooks/useTypedSelector";
import '../styles/UserMainInfoModal.css'
const UserMainInfoModal:FC = () => {
    const {
        About,
        Name,
        NameError,
        UserName,
        aboutError,
        changeNameRequesting,
        changeUserNameRequesting,
        debouncedChangeAbout,
        debouncedChangeName,
        debouncedChangeUserName,
        userNameError,
        phone
    } = useSaveProfileHook()
    const error = useTypedSelector(state => state.Profile.error)
    const {setErrorProfile} = UseActions()

    const removeError = () => {
        setErrorProfile(
            {
               ...error,
               isError:false 
            })
    }

    const {t} =useTranslation()

    return <>
            <Alert
                theme = {themeAlertEnum.Danger} 
                close = {removeError} 
                isOpen = {error.isError} 
                text = {error.textError}>
                    <span>{error.textError}</span>
            </Alert>





        <UserInfo
            userNameElement={(
                <form onSubmit={(e) => {e.preventDefault()}} >
                    <div className="form-group modal__formGroup">
                        <label className="col-lg-4 col-form-label text-lg-right"> {t('PROFILE_USERNAME')} </label>
                        <div  className={`${changeUserNameRequesting ? 'spinner spinner-primary spinner-right': ''} modal__inputWrapper` }>
                                <input type="text" id="user-username"
                                    className={`form-control form-control-lg form-control-solid ${ userNameError.isError && 'is-invalid'} `}
                                    name="User[username]" defaultValue={UserName}  onChange={debouncedChangeUserName}/>
                                    {
                                            userNameError.isError && <div className="invalid-feedback"> {userNameError.TextError} </div>
                                    }
                        {/* </div>                                                     */}
                                    
                        </div>
                    </div>
                </form>
            )}

            nameElement={(
                <div className="form-group modal__formGroup">
                    <label className="col-lg-4 col-form-label text-lg-right">{t('PROFILE_FULLNAME')}</label>
                    {/* <div className="col-lg-6"> */}
                        <div className={`${changeNameRequesting && 'spinner spinner-primary spinner-right'} modal__inputWrapper`}>
                            <input defaultValue={Name} 
                                    className={`form-control form-control-lg form-control-solid ${ NameError.isError && 'is-invalid'} `}
                                type="text" onChange={debouncedChangeName}/>
                                {
                                    NameError.isError && <div className="invalid-feedback"> {NameError.TextError} </div>

                                }
                        </div>
                    </div>
                // </div>
            )}

            aboutElement={
                <>
                <div className="form-group modal__formGroup">
                    <label className="col-lg-4 col-form-label text-lg-right">{t('PROFILE_ABOUT')}</label>
                    {/* <div className="col-lg-6 ">                                         */}
                        <textarea id="user-about"
                                className={`form-control form-control-lg form-control-solid  ${ aboutError.isError && 'is-invalid'} modal__inputWrapper`}
                                name="User[about]" defaultValue = {About} onChange={debouncedChangeAbout}
                                rows={6}/>

                                {
                                    aboutError.isError && <div className="invalid-feedback"> {aboutError.TextError} </div>

                                }
                    </div>
                    
                {/* </div> */}
                </>
            }
            phone={phone}
        />

    
    </>
}

export default UserMainInfoModal