import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import '../styles/ListCompany.css'
import { v4 } from "uuid";
import useSelectCards from "../hooks/useSelectCards";
import Groups from '../components/Stocks/Groups';
import { GroupEnum } from '../models/Search';
import debounce from 'lodash.debounce';
import CompanyListItemLoader from "../components/loaders/CompanyListItemLoader";
import { Alert, themeAlertEnum } from "../components/Alerts";
import NotFoundCompany from '../components/Stocks/NotFoundCompany';
import { useTranslation } from 'react-i18next';
import { useTypedSelector } from '../hooks/useTypedSelector';
import DailyResult from '../components/Widgets/DailyResult/DailyResultWidget';
import MarketMap from '../components/Widgets/MarketMap/MarketMapWidget';
import MarketMapView from '../components/Widgets/MarketMap/MarketMapView';

interface IStock {
    addToFavorites?: (ticket: string, src?: string) => void
    isAuth?: boolean
}

export interface IGroups {
    text: string
    id: number
    active: boolean
}

function Stock({ addToFavorites, isAuth }: IStock) {
    const [isMobile, setIsMobile] = useState<boolean>(window.innerWidth < 991)

    const { Cards, requestGroup, isLoading, isCardError, closeCardError, GroupsData, setGroupsData } = useSelectCards(
        {
            addToFavorites: addToFavorites,
            isAuth: isAuth,
            IsFavorites: false,
            isStockPage: true,
        }
    )

    const resizeWindowsHandler = useCallback((e: any) => {
        if (window.innerWidth < 991) {
            setIsMobile(true)
        }
        else setIsMobile(false)
    }, [isMobile])

    useEffect(() => {
        window.addEventListener("resize", resizeWindowsHandler);
        return () => {
            window.removeEventListener("resize", resizeWindowsHandler)
        }
    }, [isMobile])


    useEffect(() => {
        const sheet = document.getElementById('loginStyles') as HTMLLinkElement;
        if (sheet) {
            sheet.disabled = true;
            sheet.parentNode?.removeChild(sheet);
        }
    }, [])

    const [currentGroup, setCurrentGroup] = useState<GroupEnum>(GroupEnum.POPULAR)
    const { t } = useTranslation()
    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto)
    // const isCrypto = true

    const loaderList = useMemo(() => (
        [
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
            <CompanyListItemLoader />,
        ]
    ), [])

    const [isLoadingGroup, setIsLoadingGroup] = useState<boolean>(false)
    const clickHadler = (id: GroupEnum) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()

        if (isLoadingGroup) return
        setIsLoadingGroup(true)

        setGroupsData(GroupsData.map((GroupObj) => {
            if (GroupObj.id === id) {
                requestGroup(GroupObj.id, '').then(() => {
                    setIsLoadingGroup(false)
                })
                setCurrentGroup(GroupObj.id)
                return { ...GroupObj, active: (GroupObj.active) ? GroupObj.active : true }
            }
            return { ...GroupObj, active: false }
        })
        )
    }
    const changeInputValue = async (e: React.ChangeEvent<HTMLInputElement>) => {
        requestGroup(currentGroup, e.target.value)
    }

    const debouncedHandleQuery = useMemo(
        () => debounce(changeInputValue, 350)
        , [currentGroup]);

    const elements = GroupsData.map((element) => {
        return <Groups clickHadler={clickHadler} active={element.active} key={v4()} id={element.id}
            text={element.text} />
    })

    const renderCards = () => {
        if (Cards.length === 0) return <NotFoundCompany />
        return <>{Cards}</>
    }
    const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement)

    return (
        <div className = { !addToFavorites && !isCrypto ?'stockWidgetsContainer' : ''} >

            <div className={`align-items-center justify-content-between ${isCrypto ? 'mx-auto' : ''}`} style={{ maxWidth: '800px' }}>
                {
                    !addToFavorites && !isCrypto && <>
                        <DailyResult isFull={false} />
                        {
                            isMobile && <MarketMapView/>
                        }
                    </>
                }

                <Alert theme={themeAlertEnum.Danger} close={closeCardError} isOpen={isCardError.isError} text={isCardError.textError}>
                    <span>{isCardError.textError}</span>
                </Alert>

                <div className="row mb-6 card card-custom card-stretch gutter-b">
                    <div className="card-header border-0 p-4 flex-column">
                        <h3 className="card-title align-items-start">

                            <span className="card-label font-weight-bolder font-size-h4 text-dark-75">
                                {
                                    !isCrypto || !isCrypto
                                        ? <> {t('WIZARD_FAVORITES_TYPE_TICKER')} </>
                                        : <> {t('WIZARD_FAVORITES_CRYPTO_TYPE_TICKER')} </>
                                }
                            </span>
                        </h3>
                        <input
                            ref={inputRef}
                            className="form-control form-control h-auto p-4 rounded-lg mt-2 font-weight-bolder"
                            type="search"
                            id="search_tickers"
                            name="query"
                            autoComplete="off"
                            onChange={debouncedHandleQuery}
                        />
                    </div>

                    <div className='card-body pt-2 px-4'>

                        {
                            inputRef.current.value === '' && !isCrypto &&
                            <ul className="nav nav-pills nav-pills-sm nav-dark listOfTopics"> {elements} </ul>
                        }

                        {
                            isLoading
                                ? <>{loaderList}</>
                                : <>{renderCards()}</>
                        }
                    </div>
                </div>
            </div >

            {
                !isCrypto && !addToFavorites && !isMobile && <MarketMapView/>
            }
        </div>
    );
}

export default React.memo(Stock);