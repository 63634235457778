import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import CustomButton from "../Buttons/CustomButton";
import "../../styles/eventContentStyles.css"
import TrendLine from "../Time/TrendLine";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { UseActions } from '../../hooks/useActions'
import useCardGenerator from "../../hooks/useCardGenerator";
import { v4 as uuidv4 } from 'uuid';
// @ts-ignore
import { EventTopicEnum } from '../../models/EventContentModel'
import FreeDaysAlert from "../Free/FreeDaysAlert";
import {
    filterIsSelected,
    isFreePlane,
    staticScrollHandler,
    stickyScrollHandler
} from "../../Utills/ClearFunctions";
import NewEventAlert from './NewEventAlert';
import SubscriptionExpirationEventPageAlert from "../Free/SubscriptionExpirationEventPageAlert";
import { useTranslation } from 'react-i18next';
import DropDownWrapperContntent from '../Buttons/DropDownWrapperContntent'
import DropDownTrendButtonWrapper from '../Buttons/DropDownTrendButtonWrapper'
import TelegramAlert from '../Alerts/TelegramAlert'
import { enumImportant } from '../../hooks/useImportantGenetrator';
import TelegramUsers from '../../json_data.json'
import useTelegram from '../../hooks/useTelegram';
function EventContent() {
    const currentTopic = useTypedSelector(state => state.EventReducer.currentTopic)
    const { setCurrentTopic, setIsFirstCurrentReducer } = UseActions()
    const User = useTypedSelector(state => state.UserReducer.User)
    const {isShowTelegramAlert} = useTelegram()
    const { elements,
        setAllEvents,
        setFavouriteEvent,
        setTrends,
        trendsIsLoading,
        allButtonSpinnerIsSpin,
        favouritesButtonSpinnerIsSpin } = useCardGenerator({ CardContainerName: currentTopic })

    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const Filter = useTypedSelector(state => state.Filter.filters)
    const trends = useTypedSelector(state => state.EventReducer.trendsElements)
    const isFirst = useTypedSelector(state => state.EventReducer.isFirstCurrentTopic)

    const AllEventsReducer = useTypedSelector(state => state.AllEventsReducer)
    const FavouritesReducer = useTypedSelector(state => state.FavouritesReducer)
    const TrendReducer = useTypedSelector(state => state.TrendsReducer)
    const { t } = useTranslation()

    const [ButtonState, setButtons] = useState([{
        id: EventTopicEnum.TREND,
        text: t('EVENTS_TAB_TRENDS'),
        active: false,
        fire: true,
        click: () => setTrends(trends),
        isLoading: trendsIsLoading,
    }, {
        id: EventTopicEnum.FAVORITES,
        text: t('EVENTS_TAB_FAV'),
        active: false,
        fire: false,
        click: () => setFavouriteEvent(Filter),
        isLoading: favouritesButtonSpinnerIsSpin,
    }, {
        id: EventTopicEnum.ALL_STOCKS,
        text: `${!User.isCrypto ? t('EVENTS_TAB_ALL') : t('EVENTS_TAB_ALL_CRYPTO') }` ,
        active: false,
        fire: false,
        click: () => setAllEvents(Filter),
        isLoading: allButtonSpinnerIsSpin
    }
    ])

    

    const replaceActiveButton = (id: string) => {
        setButtons((prev) => {
            return ButtonState.map((value) => {
                const topic = value.id
                if (topic === id) return { ...value, active: true }
                else return { ...value, active: false }
            })
        })
    }

    useEffect(() => {
        if (isFirst) {
            setIsFirstCurrentReducer(false)
            return
        }
        replaceActiveButton(currentTopic)
    }, [currentTopic])

    let initialScrollY: number = 0

    function scrollHandler() {
        let target: any = 0
        try {
            target = document.querySelector('.dropDown') as HTMLDivElement;
        }
        catch (e) {
        }
        if (!target) 
            return
        
        let filter: any = 0
        try {
            filter = JSON.parse(localStorage.getItem('eventTypes') as string)
        }
        catch (e) {
            console.log(e)
        }

        if (!filter) 
            filter = Filter
        
        if (filterIsSelected(filter)) {
            target.style.position = 'static'
            staticScrollHandler(target)
            return
        }
        else {
            stickyScrollHandler(target)
        }
        if (initialScrollY === 0) {
            if (target.getBoundingClientRect().y < 0) {
                initialScrollY = window.scrollY + target.getBoundingClientRect().y
                return
            }
            initialScrollY = target.getBoundingClientRect().y
        }

        if (window.scrollY >= initialScrollY + target.getBoundingClientRect().height) {
            target.style.position = 'sticky'
            stickyScrollHandler(target)
        }
        else {
            target.style.position = 'static'
            staticScrollHandler(target)
        }
    }

    const changeDropDownClass = useCallback(scrollHandler, [])

    const getButtons = (prev:any) => {
        if(User.isCrypto){
            return prev.filter((prevObj:any) => {
                return prevObj.id !== EventTopicEnum.TREND
            })
        }
        return [...prev]            
    }

    useEffect(() => {
        const localStorageTopic = JSON.parse(localStorage.getItem('currentTopic') as string)
        
        setButtons( prev => {
            const filterdButtons = getButtons(prev)
            return filterdButtons
        })

        if (localStorageTopic) {
            if (User.isCrypto) {
                if (localStorageTopic === EventTopicEnum.TREND) {
                    setCurrentTopic(EventTopicEnum.FAVORITES)
                    return
                }
            }
            setCurrentTopic(localStorageTopic)
        }
        else {
            if (!User.isCrypto)
                setCurrentTopic(EventTopicEnum.TREND)
            else
                setCurrentTopic(EventTopicEnum.FAVORITES)
        }

    }, [User.isCrypto])

    useEffect(() => {
        let target = document.querySelector('.dropDown') as HTMLDivElement;

        if (!target) {
            document.addEventListener('scroll', changeDropDownClass)
            target = document.querySelector('.trendButton') as HTMLDivElement;
        }

        else {
            initialScrollY = target.getBoundingClientRect().y
            const y = target.getBoundingClientRect().y
            document.addEventListener('scroll', changeDropDownClass)
        }

        return () => {
            document.removeEventListener('scroll', changeDropDownClass)
        }
    }, [])


    useEffect(() => {
        setButtons(prev => {
            prev[0] = {
                ...prev[0],
                isLoading: trendsIsLoading,
            }
            return [...prev]
        })
    }, [trendsIsLoading])

    useEffect(() => {
        //@ts-ignore
        setButtons(prev => {
            return  prev.map((ButtonStateObject) => {
                if(ButtonStateObject.id === EventTopicEnum.FAVORITES){
                    return {
                        ...ButtonStateObject,
                        isLoading:favouritesButtonSpinnerIsSpin
                    }
                }
                else return ButtonStateObject
                
            })
        })
    }, [favouritesButtonSpinnerIsSpin])

    useEffect(() => {
        //@ts-ignore
        setButtons(prev => {
            const test =  prev.map((ButtonStateObject) => {
                if(ButtonStateObject.id === EventTopicEnum.ALL_STOCKS){
                    return {
                        ...ButtonStateObject,
                        isLoading:allButtonSpinnerIsSpin
                    }
                }
                else return ButtonStateObject
            })
            return test
        })
    }, [allButtonSpinnerIsSpin])

    interface IDropDown {
        [index: string]: React.ReactElement
    }

    const getDropDownStateButton = (): IDropDown => {
        if (!User.isCrypto)
            return {
                [EventTopicEnum.TREND]: <DropDownTrendButtonWrapper />,
                [EventTopicEnum.FAVORITES]: <DropDownWrapperContntent />,
                [EventTopicEnum.ALL_STOCKS]: <DropDownWrapperContntent />,
            }
        else
            return {
                [EventTopicEnum.FAVORITES]: <DropDownWrapperContntent />,
                [EventTopicEnum.ALL_STOCKS]: <DropDownWrapperContntent />,
            }
    }

    const renderDropDown = useMemo(() => {
        const obj = getDropDownStateButton()

        for (let i = 0; i < ButtonState.length; i++) {

            if (ButtonState[i].active) {
                if (ButtonState[i].id === EventTopicEnum.TREND)
                    return <>
                        <div className={'trendContainer'}>
                            <div className={'trendButton'}> {obj[ButtonState[i].id]}  </div>
                        </div>
                    </>
                else
                    return <>
                        <div className={'dropDown'}>
                            <div className={'ContainerFilterNone'}>
                                <span className="mr-6 js__filter-types-text filtersNone"> {t('FEED_FILTER_FILTERS_APPLIED')}</span>
                                {obj[ButtonState[i].id]}
                            </div>
                        </div>
                    </>
            }
        }
    }, [ButtonState, User.isCrypto])

    const changeTopicStage = (topic: EventTopicEnum) => (e: React.MouseEvent<HTMLDivElement>) => {
        setCurrentTopic(topic)
    }

    const ButtonsGroup = ButtonState.map((value, index) => {
        return <div key={uuidv4()} onClick={changeTopicStage(value.id)}>
            <CustomButton isLoading={value.isLoading} click={value.click} text={value.text} active={value.active} fire={value.fire} />
        </div>
    })
    const { setNewEvent, setFavoutitesNewEvent } = UseActions()
    const NewEventAlertRender = useMemo(() => {
        if (currentTopic === EventTopicEnum.ALL_STOCKS) {
            if (AllEventsReducer.numberNewEvent !== 0)
                return <NewEventAlert clickHandler={setNewEvent} numberNewEvent={AllEventsReducer.numberNewEvent} currentTopic={currentTopic} />
        }

        if (currentTopic === EventTopicEnum.FAVORITES) {
            if (FavouritesReducer.numberNewFavouritesEvent !== 0)
                return <NewEventAlert clickHandler={setFavoutitesNewEvent} numberNewEvent={FavouritesReducer.numberNewFavouritesEvent} currentTopic={currentTopic} />
        }

        if (currentTopic === EventTopicEnum.TREND) {
            return <></>
        }
    }, [FavouritesReducer, AllEventsReducer, AllEventsReducer.numberNewEvent, FavouritesReducer.numberNewFavouritesEvent, TrendReducer])

    


    // const isShowTelegramAlert = useMemo(() => {
    //     //@ts-ignore
    //     const curentUserId = User.id

    //     const isRigthUser = TelegramUsers.find((value) => value === curentUserId)
    //     const telegramConnected = !User.settings.telegramConnected
    //     return telegramConnected && isRigthUser
    // },[])

    return (
        <div className='contentEvent'>
            <div className="d-flex flex-grow-1 buttonGroup">
                {ButtonsGroup}
            </div>

            {
                isShowTelegramAlert &&  <TelegramAlert/>
            }

            {
                (isFreePlane(Plane, User?.subscriptionInfo?.finishedAt) && User?.subscriptionInfo?.isAvailableTrial) && <FreeDaysAlert />
            }
            <div className='cardContainer'>
                <SubscriptionExpirationEventPageAlert />
                <div className='lineEvent'>
                    <TrendLine currentTopic={currentTopic} />
                </div>

                {renderDropDown}

                <div style={{ position: 'relative' }} className={'eventContainer'}>
                    {NewEventAlertRender}

                    {currentTopic === EventTopicEnum.TREND
                        ? <hr className='' style={{ marginTop: '1px' }} />
                        : <hr className='' style={{ marginTop: '5px' }} />
                    }

                    {elements}
                </div>
            </div>
        </div>
    );
}

export default React.memo(EventContent);