import {Modal} from "react-bootstrap";
import React from "react";
import useTelegram from "../../hooks/useTelegram";
import { t } from "i18next";
import AlertModalBody from "./AlertModalBody";

interface IMutedStocks {
    show: boolean,
    handleClose: () => void
}

function TelegramModal({show, handleClose}: IMutedStocks){
    const {SetShowTelegram, installTelegram} = useTelegram()

    return(
        <Modal show={show} onHide={() => {SetShowTelegram(false);handleClose()}}>
            <Modal.Header closeButton>
                <h5 className="modal-title font-weight-bolder">{t('TG_BANNER_BOT')}</h5>
            </Modal.Header>
            <AlertModalBody/>
        </Modal>
    )
}
export default TelegramModal