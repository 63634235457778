import {
    CurrentCompanyEnum,
    setCompanyDescriptionInformation,
} from './types'


export const CompanyInformationActionCreators = {
    setCompanyDescriptionInformation:(capitalization:string, shareVolume:string,logo:string,ticker:string,brand:string):setCompanyDescriptionInformation => {
        return{
            type:CurrentCompanyEnum.SET_CAPITALIZATION_AND_SHARED_VOLUME,
            payload:{capitalization, shareVolume, logo, ticker, brand}
        }
    }

}