import FreeDaysBody from './FreeDaysBody'
import widgetPng from '../../image/widget-bg-1.png'

function FreeDaysAlert() {
    return <div className="alert alert-custom alert-light fade mt-4 show font-size-sm p-6" role="alert"
        style={{
            backgroundColor: "#3E97FF",
            backgroundImage: `url('${widgetPng}')`,
            backgroundSize: "cover"
        }}>
        <div className="alert-text text-white">
                <FreeDaysBody />
        </div>
    </div>
}

export default FreeDaysAlert