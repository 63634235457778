import React, {useRef} from "react";
import {UseActions} from "./useActions";
import {t} from "i18next";
import {gettOrigin} from "../Utills/ClearFunctions";
import useEvent from "../Strategy/Events/useEvent";
import {useTransition} from "./useTransition";
import {useTypedSelector} from "./useTypedSelector";

interface CommentsModal {
    handleClose: () => void,
    events: Array<any>
}
function useBackDropEvents({handleClose, events}: CommentsModal){
    const { replaceDataEvent } = useEvent()
    const { transitionToSubscriptionPage } = useTransition()
    const BackDropisOpen = useTypedSelector(state => state.BackDropReducer.BackDropisOpen)
    const eventsRef = useRef<Array<any>>([])
    const {setChildren, setBackDropIsOpen, setBackDropHeader} = UseActions()

    const elements = () => {
        if (!eventsRef.current[0]) return <>
            <div className="modal-body pt-2">
                <p className="pt-4">{t('LOCKED_EVENTS_24H')}</p>
                <a href={`${gettOrigin()}/finance/subscription`}
                   className="btn btn-sm btn-primary font-weight-bolder" onClick={transitionToSubscriptionPage}>
                    {t('PROFILE_SUBSCRIPTION_PROLONG')}
                </a>
            </div>
        </>
        return replaceDataEvent({ events:eventsRef.current }, true)
    }

    const openBackDrop = (eventsArray:Array<any>) => {
        eventsRef.current = eventsArray
        setBackDropIsOpen(true)
        setChildren(<>  {elements()} </>)
        setBackDropHeader(t('STOCK_CHART_MORE_EVENTS'))
    }
    return {openBackDrop}
}
export default useBackDropEvents