import { AreaPeriodType, ChartGroupTypes, typeGraphEnum } from "../../models/Chart"
import {Chart} from "klinecharts";
import HeaderSwtch from "./textLogic/HeaderSwitch";
import { TextIndicatorNetwork } from "./utils/renderData";
import { t } from "i18next";

export interface IKLineDataElements{
    width: number,
    height: number,
    top: number,
    left: number,
    textList:Array<string>,
    headerArray:Array<string>,
    events:any,
}

export interface INewTexIndicator{
    isEmpty:boolean
    concatArray:Array<any>
    lasIndex?: number
}

function paintPin(ctx:any, coordinate:any, text:string ){
    ctx.font = 'bold 13px Open Sans'
    const centerX = coordinate.x
    const centerY = coordinate.y - 20
    const radius = 11;

    ctx.beginPath();
    ctx.arc(centerX, centerY, radius, 0, 2 * Math.PI, false);
    ctx.fillStyle = '#FFE8B3';
    ctx.fill();
    ctx.lineWidth = 3;
    ctx.strokeStyle = '#F4A828';
    ctx.stroke();

    const startX = coordinate.x
    let startY = centerY + radius + 7
    ctx.beginPath()
    ctx.moveTo(startX, startY)
    startY -= 8
    ctx.lineTo(startX - 4, startY)
    ctx.lineTo(startX + 4, startY)
    ctx.closePath()
    ctx.fillStyle = '#F4A828';
    ctx.fill();

    ctx.fillStyle = '#100B00'
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'center'
    ctx.fillText(text, centerX, centerY)

    const sizeInformation  = {
        rectX:centerX - radius,
        rectY:centerY - radius,
        rectWidth:2 *radius,
        rectHeight:2* radius,}
    return sizeInformation
}

function tooltipDrawExtend (ctx:any, coordinate:any, text:any):{rectX:number, rectY:number, rectWidth:number, rectHeight:number} {
    return paintPin(ctx, coordinate, text)
}

function getTextWithHtmlEntity(inputText:string):string{
    const textContainer = document.createElement('span'); // Use a span to render the html entities
    textContainer.innerHTML = inputText; // Set as innerhtml to make sure entities are converted
    return textContainer.textContent as string;
}


const fruits = [
    '1', '2', '3', '4', '5', '6',
    '7', '8', '9', '10', '11', '12',
    '13', '14', '15', '16', '17', '18'
]

interface IRenderFruits{
    ctx:any,
    dataSource:any,
    viewport:any,
    xAxis:any,
    yAxis:any,
}

const getPinDate = (kLineDataList:any, eventPointsArray:Array<any>) => {
    const result:any = []
    kLineDataList.forEach((kLineData:any, index:number) => {

        const filteredPoint = eventPointsArray.filter((eventPoint, indexPoint) => {
            if(eventPoint.y ===  kLineData.close && eventPoint.x * 1000 ===  kLineData.timestamp){
                return true
            }
            else{
                return false
            }
        })
    
        if(filteredPoint.length === 0)
            result.push( {isEmpty:true, emoji: kLineData.close,  text: fruits[Math.floor(Math.random() * 17)],x:0 })
        else
            result.push( {isEmpty:false, emoji: filteredPoint[0].y, filteredPoint:filteredPoint, text: filteredPoint.length,x:filteredPoint[0].x * 1000 })
    })
    return result

}

export function emojiTechnicalIndicatorGenerator(
    setElements:(elements:Array<IKLineDataElements>) => void,
    graphtype:typeGraphEnum,
    setPriceHtmlInformation:(type:typeGraphEnum,kLineData:any) => void,
    chartObj: Chart | null,
    eventPointsArray:Array<any>,
    timezone:string,
    language:string,
    ActiveAreaPeriod:AreaPeriodType,
    ChartCandleActiveGroup: ChartGroupTypes,
    ticker:string,
    getHistoryData:(timestamp:number) => void,
    checkTextForTranslation:(text:string) => void

){

    const getTextFromEvent = (filteredPointObject:any) => {
        if(!filteredPointObject.event)
            return filteredPointObject.alert
        const boolVal = checkTextForTranslation(filteredPointObject.event.data.translatedText)
        //@ts-ignore
        if(boolVal){
            return filteredPointObject?.event?.data?.translatedText
        }
        else{
            return filteredPointObject?.event?.data?.text
        }

    }

    return {
        name: ' ',
        plots: [
            { key: 'emoji' }
        ],
        calcTechnicalIndicator: (kLineDataList:any,{params,plots}:any) => {
            return getPinDate(kLineDataList,eventPointsArray)
        },
        render: ({
                     ctx,
                     dataSource,
                     viewport,
                     xAxis,
                     yAxis,
                 }:IRenderFruits) => {

            ctx.font = `${20}px Helvetica Neue`
            ctx.textAlign = 'center'
            let tempElements:IKLineDataElements[] = []

            const fromKDate = dataSource.kLineDataList[dataSource.from]
            const toKDate = dataSource.kLineDataList[dataSource.to - 1 ]
            if(!fromKDate || !toKDate) return

            const diffTimeStamp:number = toKDate.timestamp - fromKDate.timestamp
            const isLarge:boolean = (diffTimeStamp / 1000 / 60) > 100

            const newTexIndicator:Array<INewTexIndicator> = []
            const diff = 25  
            for(let i = dataSource.from ; i < dataSource.to ;i++){
                const netWorkState = TextIndicatorNetwork.getObj()

                if(i === 0 && !netWorkState.getEmptyResponse()){
                    if( !netWorkState.getPendig() ) {
                    netWorkState.setPending(true)
                    const lastTimestamp = dataSource.kLineDataList[i].timestamp
                    getHistoryData(lastTimestamp)
                    }
                }

                newTexIndicator[i] = { concatArray:[], isEmpty:false }
                const curretData = dataSource.technicalIndicatorDataList[i]
                if(curretData.isEmpty){
                    newTexIndicator[i] = { concatArray:[], isEmpty:true }
                    continue
                }
                const currentX = xAxis.convertToPixel(i)
                const currentY = yAxis.convertToPixel(curretData.emoji);
                let isAded = false
                newTexIndicator[i].concatArray.push(dataSource.technicalIndicatorDataList[i])
                let lasIndex = 0

                for(let j = i+1; j < dataSource.to; j++){
                    const nextData = dataSource.technicalIndicatorDataList[j]
                    if(nextData.isEmpty) continue

                    const nextX = xAxis.convertToPixel(j)
                    const nextY = yAxis.convertToPixel(nextData.emoji);
                    const diffX = Math.abs(currentX - nextX)
                    const diffY = Math.abs(currentY - nextY)

                    if(diffX <= diff && diffY < diff){
                        isAded = true
                        if(!newTexIndicator[i]){
                            continue
                        } 

                        lasIndex = j
                        if(lasIndex === 109){
                        }
                        
                        newTexIndicator[i].concatArray.push(nextData)
                    }
                }

                if(!isAded){
                    continue
                }
                newTexIndicator[i].lasIndex = lasIndex
                i +=  lasIndex - i
            }

            for (let i = dataSource.from; i < dataSource.to; i++){
                if(newTexIndicator[i].isEmpty) continue

                const lastIndex = newTexIndicator[i].concatArray.length
                const targetX = newTexIndicator[i].concatArray[lastIndex - 1].x
                let lastDataIndex = 0
                for(let j = i; j < dataSource.to; j++){
                    const data = dataSource.kLineDataList[j]
                    if(data.timestamp === targetX ){
                        lastDataIndex = j
                        break
                    }
                }
                let diffX = 0

                if(lastDataIndex !== 0){
                    diffX = Math.floor( (lastDataIndex - i) / 2 )
                }

                const index = Math.floor( newTexIndicator[i].concatArray.length / 2 )
                const data = newTexIndicator[i].concatArray[index]
                const x = xAxis.convertToPixel(i + diffX)
                const y = yAxis.convertToPixel( dataSource.kLineDataList[i + diffX].close);

                //fix
                if(!newTexIndicator[i].isEmpty){
                    const numberOfEvent = newTexIndicator[i].concatArray.reduce((acc,indicatorObj) => {
                        return String( Number(acc) + Number(indicatorObj.text) )
                    }, '0')
                    var {rectY, rectWidth, rectX, rectHeight} = tooltipDrawExtend(ctx, {x, y}, numberOfEvent)
                }
                let textList = []
                let headerArray = []
                const currentData =  newTexIndicator[i].concatArray

                const events = []
                if(currentData.length > 1){

                    for(let i = 0 ; i < currentData.length; i++){
                        for(let j = 0; j < currentData[i].filteredPoint.length; j++){
                            textList.push(getTextWithHtmlEntity(getTextFromEvent(currentData[i].filteredPoint[j])),)
                            events.push(currentData[i].filteredPoint[j].event)
                        }
                    }

                    const headers:Array<string> = []
                    for(let i = 0 ; i < currentData.length; i++){
                        for(let j = 0; j < currentData[i].filteredPoint.length; j++){
                            if(currentData[i].filteredPoint[j].event) {
                                const type = currentData[i].filteredPoint[j].event.type
                                const date = currentData[i].filteredPoint[j].event.createdAt * 1000
                                headers.push(HeaderSwtch(type, date, timezone,language))
                            }
                            else{
                                headers.push(`${t('LOCKED_CHART_TOOLTIP')}`)
                            }

                        }
                    }
                    headerArray = [...headers]
                }
                else {
                    const filteredPoints = currentData[0].filteredPoint
                    if(filteredPoints.length > 1){
                        const headers:Array<string> = []
                        for(let i = 0 ; i < currentData[0].filteredPoint.length; i++){
                            if(currentData[0].filteredPoint[i].event) {
                                const type = currentData[0].filteredPoint[i].event.type
                                const data = currentData[0].filteredPoint[i].event.createdAt * 1000
                                headers.push(HeaderSwtch(type, data, timezone,language))
                            }
                            else{
                                headers.push(`${t('LOCKED_CHART_TOOLTIP')}`)
                            }

                        }
                        headerArray = [...headers]

                        for(let i = 0 ; i < currentData[0].filteredPoint.length; i++){
                            textList.push(getTextWithHtmlEntity(getTextFromEvent(filteredPoints[i])))
                            events.push(filteredPoints[i].event)

                        }
                    }
                    else{
                        const headers: Array<string> = []

                        if(currentData[0].filteredPoint[0].event) {
                            const type = currentData[0].filteredPoint[0].event.type
                            const data = currentData[0].filteredPoint[0].event.createdAt * 1000
                            headers.push(HeaderSwtch(type, data, timezone,language) )
                        }
                        else{
                            headers.push(`${t('LOCKED_CHART_TOOLTIP')}`)
                        }
                        textList = [
                            getTextWithHtmlEntity(getTextFromEvent(currentData[0].filteredPoint[0]))
                        ]

                        events.push(currentData[0].filteredPoint[0].event)
                        headerArray = [...headers]
                    }
                }
                tempElements.push(
                    {
                        height:rectHeight,
                        left:rectX,
                        top:rectY,
                        width:rectWidth,
                        textList:textList,
                        headerArray:headerArray,
                        events:events
                    }
                )
                if(newTexIndicator[i].isEmpty) continue
                if(newTexIndicator[i].concatArray.length === 1) continue
                //@ts-ignore
                i +=  newTexIndicator[i]!.lasIndex - i
            }
           
            const chartData = dataSource.kLineDataList[dataSource.to - 1 ]
            setElements(tempElements)
        }
    }
}