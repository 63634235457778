import 'moment/locale/ru';
// @ts-ignore
import { EventTopicEnum } from '../models/EventContentModel'
import React, { useEffect, useMemo, useRef, useState } from "react";
import { isFreePlane } from "../Utills/ClearFunctions";
import FreeEventTimeLine from "../components/Free/FreeEventTimeLine";
import { useTypedSelector } from "./useTypedSelector";
import SingleEventFree from "../components/Free/SingleEventFree";
import InfiniteScroll from "../Utills/VirtualScroll";
import useEventTrendsQuery from "../Strategy/Events/useEventTrendsQuery";
import useEventTickerQuery from "../Strategy/Events/useEventTickerQuery";
import useEventFavouritesQuery from "../Strategy/Events/useEventFavouritesQuery";
import useEventAllEventQuery from "../Strategy/Events/useEventAllEventQuery";
import useLastEventQuery from "../Strategy/Events/useLastEventQuery";
import EventLoader from '../components/loaders/EventLoader';
import EmptyTrends from "../components/Cards/EmptyTrends";
import TimeLineLoaders from "../components/loaders/TimeLineLoaders";

interface IUseCardGenerator {
    CardContainerName: string
    ticker?: string
}

function useCardGenerator({ CardContainerName, ticker }: IUseCardGenerator) {
    const trendsElements = useTypedSelector(state => state.EventReducer.trendsElements)
    const filter = useTypedSelector(state => state.Filter.filters)
    const User = useTypedSelector(state => state.UserReducer.User)
    const allEvents = useTypedSelector(state => state.AllEventsReducer.Elements)
    const AllEventsReducer = useTypedSelector(state => state.AllEventsReducer)

    const FavoutiresElements = useTypedSelector(state => state.FavouritesReducer.FavoutiresElements)
    const FavouritesReducer = useTypedSelector(state => state.FavouritesReducer)

    const TrendsEvents = useTypedSelector(state => state.TrendsReducer.Elements)
    const TrendsReducer = useTypedSelector(state => state.TrendsReducer)
    const [hiddenCounts, setHiddenCounts] = useState<number>(0)
    const [trendHiddenCounts, setTrendHiddenCounts] = useState<number>(0)

    const {
        preloadTrendEvents,
        setTrends,
        trendsIsLoading,
        showEmptyList } = useEventTrendsQuery(setTrendHiddenCounts)

    const {
        TickerEvents,
        lastRowTickerEventHandler,
        setTickerEvents,
        tickerEventIsLoading,
    } = useEventTickerQuery(setHiddenCounts)

    const {
        lastRowFavouritesHandler,
        setFavouriteEvent,
        preloadFavouritesEvent,
        favouritesIsLoading,
        favouritesButtonSpinnerIsSpin
    } = useEventFavouritesQuery(setHiddenCounts)

    const {
        lastRowHandler,
        setAllEvents,
        preloadAllEvent,
        allEventIsLoading,
        allButtonSpinnerIsSpin
    } = useEventAllEventQuery(setHiddenCounts)

    const {
        LastEvent,
        lastRowLastEventHandler,
        setLastEvents,
        lastEventIsLoading,
        isLoadingLasRow
    } = useLastEventQuery(setHiddenCounts)
    const trends = useTypedSelector(state => state.EventReducer.trendsElements)


    const filterIsSame = (prevFilter: Array<any>) => {
        if (prevFilter.length === 0) return false

        const flag = prevFilter.every((value: any, index: number) => {
            return value.check === filter[index].check
        })
        return flag
    }
    const eventIsSame = (prevEvent: Array<any>) => {
        if (prevEvent.length === 0) return false
        const flag = prevEvent.every((value: any, index: number) => {
            return value.active === trends[index].active
        })
        return flag
    }

    useEffect(() => {
        if (CardContainerName === EventTopicEnum.TICKER_EVENTS) {
            setTickerEvents((ticker as string))
        }
        if (CardContainerName === EventTopicEnum.TREND)
            if (TrendsEvents.length === 0 || !eventIsSame(TrendsReducer.prevEventsFilter)) {
                setTrends(trends)
            } else
                preloadTrendEvents(trends)
        if (CardContainerName === EventTopicEnum.ALL_STOCKS) {
            if (allEvents.length === 0 || !filterIsSame(AllEventsReducer.prevFilter)) {
                setAllEvents(filter)
            } else
                preloadAllEvent(filter)
        }
        if (CardContainerName === EventTopicEnum.FAVORITES)
            if (FavoutiresElements.length === 0 || !filterIsSame(FavouritesReducer.prevFilter)) {
                setFavouriteEvent(filter)
            } else
                preloadFavouritesEvent(filter)
    }, [CardContainerName, trendsElements, ticker, filter, User?.settings?.language])

    const getnerateLastEvent = (ticker: string) => {
        if (CardContainerName === EventTopicEnum.LAST_EVENT) {
            if (ticker === '') return
            setLastEvents((ticker as string))
        }
    }

    useEffect(() => {
        if (CardContainerName === EventTopicEnum.LAST_EVENT) {
            if (ticker === '') return
            setLastEvents((ticker as string))
        }
    }, [ticker, filter])

    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const isEmptytrends = useRef<boolean>(true)

    const loaders = useMemo(() => {
        return [
            <EventLoader />,
            <EventLoader />,
            <EventLoader />,
            <EventLoader />,
            <EventLoader />,
            <EventLoader />,
        ]
    }, [])

    const companyLoaders = useMemo(() => {
        return [
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
            <TimeLineLoaders />,
        ]
    }, [])

    const checkForEmptyList = useMemo ( () => {
        if (TrendsEvents) {
            if (trendsIsLoading) return <> {loaders} </>
            if (TrendsEvents.length !== 0) {

                isEmptytrends.current = false
                return <>{TrendsEvents}</>
            }
            else if (TrendsEvents.length === 0 && trendsIsLoading === false && showEmptyList === true) {
                isEmptytrends.current = true
                return <><EmptyTrends />  </>
            }
        }
        return <> {loaders} </>
    },[trendsIsLoading, TrendsEvents, showEmptyList])

    const getFreeTimeLine = () => {
        return <>
            {
                (isFreePlane(Plane, User?.subscriptionInfo?.finishedAt)
                    && !isEmptytrends.current
                    && trendHiddenCounts !== 0) && <div className={'mt-7'}>
                    <FreeEventTimeLine trendHiddenCounts={trendHiddenCounts} />
                </div>
            }
        </>             

    }
    const allEventJsx = useMemo(() => {
        return <>
            {
                allEventIsLoading
                    ? <> {loaders} </>
                    : <InfiniteScroll listItems={allEvents} lastRowHandler={lastRowHandler} />

            }

        </>
    }, [allEventIsLoading, allEvents])


    const favouritesEventJsx = useMemo(() => {
        return <>
            {
                favouritesIsLoading
                    ? <> {loaders} </>
                    : <InfiniteScroll listItems={FavoutiresElements}
                        lastRowHandler={lastRowFavouritesHandler} />
            }
        </>
    }, [favouritesIsLoading, FavoutiresElements])

    const tickerEventsJSX = useMemo(() => {
        return <>
            {
                tickerEventIsLoading
                    ? <> {loaders} </>
                    : <InfiniteScroll listItems={TickerEvents} lastRowHandler={lastRowTickerEventHandler} />
            }

        </>
    }, [tickerEventIsLoading, TickerEvents])


    const lastEventsJSX =() => {
        return <>
            {
                lastEventIsLoading
                    ? <> {companyLoaders} </>
                    : <InfiniteScroll listItems={LastEvent} lastRowHandler={lastRowLastEventHandler} />
            }

        </>
    }

    const getGard = () => {
        switch (CardContainerName) {
            case (EventTopicEnum.LAST_EVENT):
                return (
                    <>
                        {
                            lastEventsJSX()
                        }
                    </>
                )

            case (EventTopicEnum.TICKER_EVENTS):
                return (<>
                    {tickerEventsJSX}
                </>
                )

            case (EventTopicEnum.TREND):
                return (<>
                    {checkForEmptyList}
                    {
                        getFreeTimeLine()
                    }
                </>
                )
            case (EventTopicEnum.FAVORITES):
                return (<>
                    {
                        (isFreePlane(Plane, User?.subscriptionInfo?.finishedAt)) && <div className={'mt-7'}>
                            <SingleEventFree hiddenCounts={hiddenCounts} />
                        </div>
                    }
                    {favouritesEventJsx}
                </>
                )
            case (EventTopicEnum.ALL_STOCKS):
                return (<>
                    {
                        (isFreePlane(Plane, User?.subscriptionInfo?.finishedAt)) && <div className={'mt-7'}>
                            <SingleEventFree hiddenCounts={hiddenCounts} />
                        </div>
                    }
                    {allEventJsx}
                </>
                )
        }
    }

    return {
        elements: (
            <>
                {getGard()}
            </>
        ),
        setFavouriteEvent: setFavouriteEvent,
        setAllEvents: setAllEvents,
        setTrends: setTrends,
        favouritesIsLoading: favouritesIsLoading,
        allEventIsLoading: allEventIsLoading,
        trendsIsLoading: trendsIsLoading,
        allButtonSpinnerIsSpin: allButtonSpinnerIsSpin,
        favouritesButtonSpinnerIsSpin: favouritesButtonSpinnerIsSpin,
        hiddenCounts: hiddenCounts,
        getnerateLastEvent: getnerateLastEvent,
        isLoadingLasRow,
        CardContainerName,
    }
}


export default useCardGenerator