const AILogo = () => <svg
  xmlns="http://www.w3.org/2000/svg"
  width={22}
  height={22}
  viewBox="0 0 20 20"
>
  <path
    style={{
      opacity: 1,
      vectorEffect: "none",
      fill: "currentColor",
      fillOpacity: 1,
      fillRule: "evenodd",
      stroke: "none",
      strokeWidth: ".62069714px",
      strokeLinecap: "butt",
      strokeLinejoin: "miter",
      strokeMiterlimit: 4,
      strokeDasharray: "none",
      strokeDashoffset: 0,
      strokeOpacity: 1,
      marker: "none",
    }}
    d = "M35.412 115.592a6.289 6.289 0 0 0-5.68 3.575c-.053-.005-.104-.017-.158-.017-.942 0-1.7.759-1.7 1.7v2.102c0 .942.758 1.7 1.7 1.7.054 0 .105-.012.157-.016.068.14.138.28.217.417.31.551 1.134.079.816-.467a5.369 5.369 0 0 1 1.2-6.802 5.363 5.363 0 0 1 6.902 0 5.369 5.369 0 0 1 1.2 6.802l-.014.023-.01.024s-.633 1.402-1.878 1.402l-1.575-.014a.807.807 0 0 0-.667-.366h-1.015a.82.82 0 0 0-.822.823c0 .28.178.494.586.494h3.493c1.868 0 2.675-1.833 2.712-1.918v-.005c.078-.136.147-.275.215-.415.055.005.108.017.165.017.941 0 1.7-.759 1.7-1.7v-2.102c0-.942-.759-1.7-1.7-1.7-.055 0-.106.009-.16.016a6.268 6.268 0 0 0-1.626-2.1 6.305 6.305 0 0 0-4.058-1.475zm-2.049 2.987c-.366-.024-.7.053-.992.361a4.56 4.56 0 0 0-1.26 3.125c0 .981.327 2.08.88 2.12.786.057 2.027-.46 3.424-.46 1.48 0 2.787.58 3.561.443.47-.083.744-1.205.744-2.103a4.561 4.561 0 0 0-1.261-3.125c-.78-.82-1.855 0-3.044 0-.743 0-1.442-.321-2.052-.361zm-.174 2.021a.787.787 0 1 1 0 1.574.787.787 0 0 1 0-1.574zm4.452 0a.787.787 0 1 1 0 1.574.787.787 0 0 1 0-1.574z"
    transform="translate(-25.415 -111.281)"
  />
</svg>

export default AILogo