import $api from "../http";
import {AxiosResponse} from 'axios';

export default class AuthService {
    static async login(phone: string, code: string, isCryptoPage:boolean): Promise<AxiosResponse<any>> {
        if(isCryptoPage)
            return $api.post<any>('/auth/login', { phone, code, crypto:'1'})
        return $api.post<any>('/auth/login', {phone, code})
    }

    static async verification(phone: string,reCaptcha:string): Promise<AxiosResponse<any>> {
        return $api.post<any>('/auth/verification-code', {phone, reCaptcha} )
    }

    static async verificationIos(phone: string): Promise<AxiosResponse<any>> {
        return $api.post<any>('/auth/verification-code', {phone} )
    }

    static async logout(): Promise<AxiosResponse<any>> {
        return $api.post('/auth/logout')
    }

    static async checkAuth(): Promise<AxiosResponse<any>> {
        return $api.post('/auth/check')
    }



}