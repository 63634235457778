import React, { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import CompanyButtonGroup from "../Buttons/CompanyButtonGroup";
import DescriptionTable from "./DescriptionTable";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import '../../styles/DescriptionBar.css'
import InvestApp from "../DescriptionBar/InvestApp";
import { TickerContext } from "../../Context";
import { DeviseIsApplicationOnly, getPercent } from "../../Utills/ClearFunctions";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import useDate from "../../hooks/useDate";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import Countdown from "react-countdown";
import DescriptionBarLargeImage from '../Image/DescriptionBarLargeImage'
import FavouritesButton from '../Buttons/FavouritesButton'
import MutedSideBarWrapper from './MutedSideBarWrapper'
import { outputPercent } from "../Cards/CompanyInformationCard";
import BeforeTradingTimer from "./BeforeTradingTimer";
import DescriptionPrice from "./DescriptionPrice"

function DescriptionBar() {
    const [DescriptionIsShow, setDescriptionIsShow] = useState<boolean>(false)
    const [percent, setPercent] = useState<number | null>(null)
    const [isUp, setIsUp] = useState<boolean>(false)
    const [diffPrice, setDiffPrice] = useState<string>('0')
    const { getPrice } = useFormatCurrency()
    const { diffTimeFromNow } = useDate()
    const CurrentTicker = useTypedSelector(state => state.TickerReducer)
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    const {
        currentTickerState,
        companyInformation,
        tradingInfo,
        setTradingInfo,
        isCoinsPage,
        additionalPriceInfo
    } = useContext(TickerContext)

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            starRef.current.classList.add('disableStar')
        }
        let styles = `       
            .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
            top: -1px;
            border-width: 0.4rem 0.4rem 0;
            border-top-color: white !important;
        }
        `
        let styleSheet = document.createElement("style")
        styleSheet.innerText = styles
        const TooltipId = 'TooltipStyle'
        styleSheet.id = TooltipId
        document.head.appendChild(styleSheet)
        return () => {
            const sheets = document.getElementById(TooltipId)
            sheets?.remove()
        }
    }, [])
    useEffect(() => {
        if (!currentTickerState.oldPrice) return
        const [percent, isUp] = getPercent(currentTickerState.price as number, Number(currentTickerState.oldPrice))
        setIsUp(isUp as boolean)
        setPercent(percent as number)
        const diff = Number(currentTickerState.price) - Number(currentTickerState.oldPrice)
        setDiffPrice(diff.toFixed(2))
    }, [currentTickerState.ticket, currentTickerState.price])

    const { t } = useTranslation()

    const getFormatedDate = (obj: any) => {
        if (tradingInfo.status === 0) {
            if (obj.hours)
                return <>
                    <span className="label label-inline">
                        {obj.hours}ч&nbsp;{obj.minutes}м&nbsp;до&nbsp;начала&nbsp;торогов
                    </span>
                </>
            else if (obj.minutes)
                return <>
                    <span className="label label-inline">
                        {obj.minutes}м&nbsp;{obj.seconds}с&nbsp;до&nbsp;начала&nbsp;торогов
                    </span>
                </>
            else return <>
                <span className="label label-inline">
                    {obj.seconds}с&nbsp;до&nbsp;начала&nbsp;торогов
                </span>
            </>
        }
        if (tradingInfo.status === 2) {
            if (obj.hours)
                return <>
                    <span className="label label-inline">
                        {obj.hours}ч&nbsp;{obj.minutes}м&nbsp;до&nbsp;начала&nbsp;торогов
                    </span>
                </>
            else if (obj.minutes)
                return <>
                    <span className="label label-inline">
                        СПБ&nbsp;стоп&nbsp;торги,&nbsp;осталось&nbsp;{obj.minutes}м&nbsp;{obj.seconds}с
                    </span>
                </>
            else {
                return <span className="label label-light-danger label-pill label-inline">
                    СПБ&nbsp;стоп&nbsp;торги,&nbsp;осталось&nbsp;${obj.seconds}с
                </span>
            }
        } else return <></>
    }
    const renderCountDown = (Timeobj: any) => {
        if (Timeobj.completed) {
            setTradingInfo({
                status: 1,
                startAt: null
            })
            return <></>;
        } else {
            return <>
                {
                    !isCoinsPage.isCrypto && <span className={'ml-1'}>
                        {getFormatedDate(Timeobj)}
                    </span>
                }

            </>
        }
    }

    const CountDownRef = useRef<React.ReactElement | null>(null)

    useLayoutEffect(() => {
        if (!tradingInfo) return
        if (!tradingInfo.startAt) return
        CountDownRef.current =
            <Countdown date={tradingInfo.startAt as number * 1000 as number} renderer={renderCountDown} />
    }, [currentTickerState.ticket, tradingInfo])

    const insertTradingInfo = useCallback(() => {
        if (!tradingInfo) return <></>
        if (tradingInfo.status === null) return <></>
        if (tradingInfo.status === 1) return <></>
        let { hours, minutes, seconds } = diffTimeFromNow(tradingInfo.startAt as number)
        if (tradingInfo.status === 0) {
            if (hours !== 0)
                return <span className="label label-inline">
                    {hours}ч&nbsp;{minutes}м&nbsp;до&nbsp;начала&nbsp;торогов
                </span>
            else {
                minutes = (minutes === 0) ? 1 : minutes
                return <span className="label label-inline">
                    {minutes}м&nbsp;до&nbsp;начала&nbsp;торогов
                </span>
            }
        }
        if (tradingInfo.status === 2) {
            if (minutes !== 0)
                <span className="label label-light-danger label-pill label-inline">
                    СПБ&nbsp;стоп&nbsp;торги,&nbsp;осталось&nbsp;{minutes}м&nbsp;{seconds}с
                </span>
            else {
                <span className="label label-light-danger label-pill label-inline">
                    СПБ&nbsp;стоп&nbsp;торги,&nbsp;осталось&nbsp;${seconds}с
                </span>
            }
        }
    }, [tradingInfo])

    const overideTitle = useMemo(() => {
        if (companyInformation?.brand) {
            return <Helmet>
                <title> {currentTickerState.ticket} | {companyInformation?.brand} </title>
            </Helmet>
        } else {
            if (CurrentTicker.brand)
                return <Helmet>
                    <title>{CurrentTicker.ticket} | {CurrentTicker?.brand}</title>
                </Helmet>
            else {
                return <> </>
            }
        }
    }, [companyInformation?.brand, CurrentTicker.brand])


    const CompanyGroupButtonJSX = useMemo(() => {
        return <CompanyButtonGroup />
    }, [])

    const FavouritesButtonJSX = useMemo(() => {
        return <FavouritesButton />
    }, [])
    const InvestAppJSX = useMemo(() => {
        return <InvestApp />
    }, [])

    const MutedSideBarWrapperJSX = useMemo(() => {
        return <MutedSideBarWrapper />
    }, [])


    return (
        <div>
            {overideTitle}
            <div className="card card-custom mb-4 px-2 px-md-4">
                <div className="card-body px-2 pt-2 pb-0">
                    <div className="d-flex">
                        <div className="flex-grow-1">
                            <div className="d-flex align-items-start justify-content-between flex-wrap mt-2">
                                <div className="mr-3">
                                    <div className="d-none d-lg-block">
                                        <div className="d-flex align-items-center mb-5">
                                            <div className="symbol symbol-50 symbol-circle mr-5">
                                                <DescriptionBarLargeImage src={currentTickerState.src} />
                                            </div>
                                            <div className="d-flex flex-glow-1 flex-column">
                                                <h2 className="font-weight-bolder">{currentTickerState.cardName}</h2>
                                                <span
                                                    className="text-muted font-weight-bolder font-size-h5">${currentTickerState.ticket}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <>

                                    <DescriptionPrice/>

                                        {/* {
                                            currentTickerState.price ? <div className="flex-row-fluid">
                                                <span
                                                    className="font-weight-bolder display-4">
                                                    {
                                                        getPrice(currentTickerState.price, currentTickerState.currency)
                                                    }
                                                    &nbsp;
                                                    {
                                                        percent && <span
                                                            className={`font-weight-bolder font-size-h5 ${isUp ? 'text-success' : 'text-danger'}`}>
                                                            {`${!isUp ? '' : '+'}`}{getPrice(Number(diffPrice), currentTickerState.currency)} {outputPercent(percent)}
                                                        </span>
                                                    }
                                                </span>
                                            </div>
                                                : <></>
                                        }
                                        {CountDownRef.current} */}
                                    </>

                                    <div className="flex-row-fluid" onClick={(e) => e.stopPropagation()}>
                                        <span
                                            className="font-size-xs">{t('STOCK_MARKETDATA_CAP_SHORT')} {companyInformation?.marketInfo?.capitalization} | {t('STOCK_MARKETDATA_VOLUME_SHORT')} {companyInformation?.marketInfo?.volume}</span>
                                        {
                                            !isCoinsPage.isCrypto &&
                                            <a ref={starRef} onClick={() => {
                                                setDescriptionIsShow(!DescriptionIsShow)
                                            }} style={{ cursor: "pointer" }}
                                                className="btn btn-icon btn-xs btn-white font-weight-bolder">
                                                {
                                                    (!isCoinsPage.isCrypto) && <>
                                                        {
                                                            !DescriptionIsShow
                                                                ? <i className="fas fa-chevron-circle-right text-primary p-0"
                                                                    aria-hidden="true" />
                                                                : <i className="fas fa-chevron-circle-down text-primary p-0"
                                                                    aria-hidden="true" />
                                                        }
                                                    </>
                                                }
                                            </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        {MutedSideBarWrapperJSX}
                        {InvestAppJSX}
                        {FavouritesButtonJSX}
                    </div>


                    {
                        DescriptionIsShow && <DescriptionTable companyInformation={companyInformation?.marketInfo} />
                    }

                    {CompanyGroupButtonJSX}
                </div>
            </div>
        </div>
    )
}

export default React.memo(DescriptionBar)

