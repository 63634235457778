import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import './App.css';
import { Routes, Route } from "react-router-dom";
import { Navigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/template/bundle.css"
import './styles/template/global/plugins.bundle.css'
import { useTypedSelector } from "./hooks/useTypedSelector";
import { UseActions } from "./hooks/useActions";
import { IRoute, privateRouts, PublicRouts, SelectFavouritesRouts, WaitingRouts } from "./Routs";
import { StatusTypes } from "./models/User";
import './styles/template/site.css'
import './styles/ButtonDisableHover.css'
import './i18n';
import {
    checkForCIS,
    deleteScript, deviceIsMobile, DeviceIsMobileBrowser, DeviseIsApplicationOnly,
    getCookie,
    loadScript
} from "./Utills/ClearFunctions";
import Interseptors from "./http/Interseptors";
import { store } from './redux'
import { SITE_KEY } from "./models/reCaptcha";
import useMetaTag from './hooks/useMetaTag';
import { useTranslation } from 'react-i18next';
import useFilters from './hooks/useFilters';
import { ORIGIN } from './models/Domen';
import 'animate.css';
import { checkForRU, sharedCheckForCIS } from './hooks/useSelectSubscriptions';

function App() {
    const isAut = useTypedSelector(state => state.UserReducer.isAuth)
    const User = useTypedSelector(state => state.UserReducer.User)
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const { setDeviceType, checkAuth, checkAuthWithSharedData } = UseActions()
    const FavouritesCards = useTypedSelector(state => state.FavouritesCard.FavouritesCards)
    const { setFavouritesCards } = UseActions()
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)

    const { getMetaTags, getTittle } = useMetaTag()
    const { t, i18n } = useTranslation()
    const [changeLanguage, setChangeLanguage] = useState<boolean>(false)
    const { setLists } = useFilters()

    document.addEventListener('DOMContentLoaded', () => {
        if (DeviseIsApplicationOnly()) {
            //@ts-ignore
            if (window.location.pathname.includes('login') || window.location.pathname.includes('wizard') || !window._sharedData.user) return;
                document.location = `${ORIGIN}/page_loaded`
        }
    })

    const disableHover = () => {
        const hoveredElementsText = document.querySelectorAll('.text-hover-primary')
        hoveredElementsText.forEach((hoveredElement) => {
            hoveredElement.classList.remove('text-hover-primary')
            hoveredElement.classList.remove('text-hover-primary')
        })
    }

    const showInstallApplicationAlert = () => {
        if (DeviceIsMobileBrowser(userAgent)) {
            if (getCookie(encodeURIComponent('hideInstallModal'))) return false
            else return true
        }
        return false
    }

    const checkMethodForAuth = () => {
        if (window.location.origin.includes('localhost') || window.location.origin.includes('react')) {
            checkAuth()
            return
        }
        checkAuthWithSharedData()
    }
    const getLanguage = () => {
        // debugger
        //@ts-ignore
        if (window._sharedData.user) {
            //@ts-ignore
            i18n.changeLanguage(window._sharedData.user.settings.language)
        }
        else {
            //@ts-ignore  
            i18n.changeLanguage(window._sharedData?.config?.locale?.language)
        }
        setChangeLanguage(true)
    }
    useEffect(() => {
        if (!changeLanguage) return
        setLists()
    }, [changeLanguage])

    // const getFavourites = async () => {
    //     if(isAut && User.status !== StatusTypes.PENDING){
    //         const data = await FavouritesQuery.getData(FavouritesCards)
    //         setFavouritesCards(data)
    //     }
    // }

    useEffect(() => {
        // getFavourites()
        setDeviceType()
        checkMethodForAuth()
        showInstallApplicationAlert()

        if (DeviseIsApplicationOnly() && !sharedCheckForCIS()) {
            const isRedirectToPayment = window.localStorage.getItem('isRedirectToPayment')
            if (isRedirectToPayment === 'true') {
                setTimeout(() => {
                    window.localStorage.setItem('isRedirectToPayment', 'false')
                    window.localStorage.setItem('isFirstSubscriptionLook', 'true')
                    window.location.assign(ORIGIN + "/finance/subscription")
                }, 1500);
            }
        }

        if (deviceIsMobile() || window.navigator.userAgent.includes('iPad')) {
            document.body.classList.add('noselect')
        }
        return () => {
            document.body.classList.remove('noselect')
        }

    }, [])

    const createCopyObject = () => {
        //@ts-ignore
        window.Clipboard = (function (window, document, navigator) {
            var textArea: any,
                copy: any;

            function isOS() {
                return navigator.userAgent.match(/ipad|iphone/i);
            }

            function createTextArea(text: any) {
                textArea = document.createElement('textArea');
                textArea.value = text;
                document.body.appendChild(textArea);
            }

            function selectText() {
                var range,
                    selection;

                if (isOS()) {
                    range = document.createRange();
                    range.selectNodeContents(textArea);
                    selection = window.getSelection();
                    selection!.removeAllRanges();
                    selection!.addRange(range);
                    textArea.setSelectionRange(0, 999999);
                } else {
                    textArea.select();
                }
            }

            function copyToClipboard() {
                document.execCommand('copy');
                document.body.removeChild(textArea);
            }

            copy = function (text: any) {
                createTextArea(text);
                selectText();
                copyToClipboard();
            };

            return {
                copy: copy
            };
        })(window, document, navigator);
    }

    useEffect(() => {
        createCopyObject()
        getLanguage()
        if (isAut) {
            Interseptors(store)
            deleteScript('Captha')
        } else if (isAut === false) {
            loadScript(`https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, 'Captha')
        }
    }, [isAut])

    const getRoutsJsx = (listOfRouts: Array<IRoute>): Array<ReactElement> => {
        const newJsx = listOfRouts.map((RouteObj) => {
            if (RouteObj.subPaths.length > 0) {
                const newRouts = getRoutsJsx(RouteObj.subPaths)
                return <Route path={RouteObj.path} element={<RouteObj.element />}>
                    {newRouts.map((val: any) => val)}
                </Route>

            } else {
                if (RouteObj.index)
                    return <Route index element={<RouteObj.element />} />
                else
                    return <Route path={RouteObj.path} element={<RouteObj.element />} />
            }
        })
        return newJsx
    }

    const getPublicRouts = () => getRoutsJsx(PublicRouts).map((route: ReactElement) => route)
    const getPrivateRouts = () => getRoutsJsx(privateRouts).map((route: ReactElement) => route)
    const getWaitingRouts = () => getRoutsJsx(WaitingRouts).map((route: ReactElement) => route)
    const getSelectRouts = () => getRoutsJsx(SelectFavouritesRouts).map((route: ReactElement) => route)

    const selectRouts = useMemo(() => {
        let testFlag = true

        if (User.status === StatusTypes.PENDING)
            return (<Routes>
                {getWaitingRouts()}
                <Route path="*" element={<Navigate to="/waiting" replace />} />
            </Routes>
            )
        else if (User.favouritesSelected === false)
            return (<Routes>
                {getSelectRouts()}
                <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>)
        else {

            if (isAut === true) {
                if (window.navigator.userAgent.includes('Tradesense')) {
                    return (
                        <Routes>
                            {getPrivateRouts()}
                        </Routes>
                    )
                } else
                    return (<>
                        <Routes>
                            {getPrivateRouts()}
                            <Route path="*" element={<Navigate to="/events" replace />} />
                        </Routes>
                    </>)
            } else if (isAut === false)
                return (<>
                    <Routes>
                        {getPublicRouts()}
                        <Route path="*" element={<Navigate to="/wizard" replace />} />
                        {
                            !window.navigator.userAgent.includes('Tradesense') && <Route path="*" element={<Navigate to="/wizard" replace />} />
                        }
                    </Routes>
                </>)
        }
    }, [isAut, User])

    return (
        <>
            {getMetaTags()}
            {getTittle()}
            <>
                {
                    selectRouts
                }
            </>
        </>
    );
}

export default App;
