import {OverlayTrigger} from "react-bootstrap";
import {Tooltip} from "react-bootstrap";
import ReactTooltip from 'react-tooltip';
import '../../styles/CustomBadget.css'
import {useEffect, useState} from "react";

interface ITooltip{
    nameTooltip:string;
    text:string
}

function CustomBadget({nameTooltip,text}:ITooltip){
    const [TooltipId,setTooltipId] = useState<string>('TooltipStyle')

    const renderTooltip = (props:any) =>{
        return (
            <Tooltip id="button-tooltip" {...props}>
                <div className="overlay">{text}</div>
            </Tooltip>
        );
    }
        
    return (
        <>

            <OverlayTrigger
                delay={{show: 1, hide: 1}}
                overlay={renderTooltip}
                placement={'top'}
            >
                <span data-tip= {text}  className="js__index-tooltip label label-secondary font-size-lg label-pill label-inline font-weight-bolder mr-2">{nameTooltip}</span>

            </OverlayTrigger>
        </>
    )
}

export default CustomBadget
