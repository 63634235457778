import { useTranslation } from "react-i18next"

function Captha(){
    const {t} = useTranslation()

    return(
            <div className="text-muted font-size-sm text-center mt-6">
               {t('WIZARD_LOGIN_SIGNIN_CAPTCHA')} <a href="https://policies.google.com/privacy" target="_blank">{t('WIZARD_LOGIN_SIGNIN_PRIVACY')}</a> {t('WIZARD_LOGIN_SIGNIN_AND')} <a href="https://policies.google.com/terms" target="_blank">{t('WIZARD_LOGIN_SIGNIN_CAPTCHA_TERMS')}</a>.
            </div>
        )
}

export default Captha