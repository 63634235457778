import React, {useEffect, useState} from "react";
import UserService from "../Services/UserService";
import { UseActions } from "./useActions";

export interface IUser{
    id:number,
    avatar:string,
    username:string,
    fullname:string,
}

export enum invitesStatusEnum {
    OVER = 3,
    WAITING= 2,
    READY= 1,
}

export interface IInvite {
    id:number,
    status:invitesStatusEnum,
    code: string,
    phone:any,
    invitedUser?: IUser | null
}

function useInvites() {
    const [invites, setInvites] = useState<Array<IInvite>>([])
    const [clickedInvent,setClickedInvent] = useState<number>(0)
    const {setCountInvites,SetHasInvites} = UseActions()

    const getInvets = async () => {
        const response:any = await UserService.getInvites()
        return response.data.invites
    }
    useEffect(() => {
        getInvets().then((invitesResponse) => {
            setCountInvites(invitesResponse.filter((invite:any) => invite.status === 1 ).length)
            setInvites(invitesResponse)
        })
    },[])

    const setInvitsWrapper = (invitesarray:Array<IInvite>) => {
        setInvites(invitesarray)
        const hasIvents = invitesarray.every((invitesElement) => {
            return invitesElement.status !== invitesStatusEnum.READY
        })
        SetHasInvites(!hasIvents)
    }

    return {
        invites: invites,
        setInvites:setInvitsWrapper 
    }


}

export default useInvites