import {IPeriod} from "../UserEvent/types";

export interface IAllEvent{
    numberNewFavouritesEvent:number,
    FavoutiresElements:Array<JSX.Element>
    NewFavouritesElements:Array<JSX.Element>
    prevFilter:Array<any>
    period:IPeriod,
    favouritesElementIsLoading:boolean,
}

export enum allEventEnum{
    SET_FAVOURITES_EVENTS = "SET_FAVOURITES_EVENTS",
    SET_FAVOURITES_NUMBER_NEW_EVENT = "SET_FAVOURITES_NUMBER_NEW_EVENT",
    SET_FAVOURITES_NEW_ELEMENTS = "SET_FAVOURITES_NEW_ELEMENTS",
    SET_FAVOURITES_FULL_EVENTS = "SET_FAVOURITES_FULL_EVENTS",
    SET_FAVOURITES_RPEV_FILTER = "SET_FAVOURITES_RPEV_FILTER",
    DELETE_EVENT_FAVOURITES = "DELETE_EVENT_FAVOURITES",
    SET_PERIOD = "SET_FAVOURITES_PERIOD",
    SET_FAVOURITES_ELEMENT_IS_LOADING = "SET_FAVOURITES_ELEMENT_IS_LOADING"
}
export interface ISetfavouritesElementIsLoading{
    type:allEventEnum.SET_FAVOURITES_ELEMENT_IS_LOADING,
    payload:boolean,
}

export interface ISetFavouritesPeriod {
    type:allEventEnum.SET_PERIOD,
    payload:IPeriod,
}

export interface IDeleteEventFavourites {
    type:allEventEnum.DELETE_EVENT_FAVOURITES,
    payload:string,
}

export interface ISetPrevFilter{
    type:allEventEnum.SET_FAVOURITES_RPEV_FILTER
    payload:Array<any>
}

export interface ISetFullEvent{
    type: allEventEnum.SET_FAVOURITES_FULL_EVENTS
    payload: Array<JSX.Element>
}

export interface  ISetTrends{
    type: allEventEnum.SET_FAVOURITES_EVENTS,
    payload: Array<JSX.Element>
}

export interface ISetNumberNewEvent{
    type: allEventEnum.SET_FAVOURITES_NUMBER_NEW_EVENT,
    payload: number,
}

export interface ISetNewEvent{
    type:allEventEnum.SET_FAVOURITES_NEW_ELEMENTS,
}


export type actionType = ISetfavouritesElementIsLoading | ISetTrends | ISetFavouritesPeriod | IDeleteEventFavourites | ISetPrevFilter | ISetNumberNewEvent | ISetNewEvent | ISetFullEvent