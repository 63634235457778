import { FC } from "react";
import TimerClosedAlert from "./TimerClosedAlert";
import { t } from "i18next";

interface ISuccessPurchaise{
    isTimerClosed:boolean,
    closeInterval:() => void
}
const SuccessPurchaise:FC<ISuccessPurchaise> = ({closeInterval,isTimerClosed}) => {
    return <>
        <div>
            <div className = 'WaitingPurchaise__text'> {t('SUBSCRIPTION_IN_PROGRESS')}</div> 
            {
                isTimerClosed &&  <TimerClosedAlert closeInterval = {closeInterval}/>
            }
            
        </div>
    </>
}
export default SuccessPurchaise