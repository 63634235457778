import UserService from "../../Services/UserService"
import { CardArrayStrategy } from "./CardStrategy";
import {IQuery} from "./types"
import {IFavouriteCard} from '../../redux/reducers/FavouritesCard/types'
export class FavouritesQuery{
    static replaceResponse = (stockResponse:any) => {
        const objFavorites = {
            star: false,
            fill: false,
        }
        const rawState = CardArrayStrategy.transformDataCard(stockResponse)
        const favourites = rawState.map((cardObj:any) => {
                return {...objFavorites,...cardObj} 
            }    
        )
        return favourites
    }

    static request = () => {
        return UserService.getFavourites()
    }

    static getData = async (favouaritesCard?:IFavouriteCard[],isForceUpdate?:boolean) => {
        if(favouaritesCard && !isForceUpdate){
            if(favouaritesCard[0]?.cardName !== 'Default'){
                return favouaritesCard
            }
        }
        const stockResponse =  await this.request()
        return this.replaceResponse(stockResponse)
    }

} 