import {AppDispatch,} from '../..'
import {
    allEventEnum,
    IDeleteEvent,
    ISetFullEvent,
    ISetNumberNewEvent,
    ISetPeriod,
    ISetPrevFilter,
    ISetTrends,
    ISetLoadingALLEvents
} from './types'
import {IPeriod} from "../UserEvent/types";

export const allEventsActionCreators = {
    setLoadingAllEvents:(isLoading:boolean):ISetLoadingALLEvents => {
        return {
            type:allEventEnum.SET_LOADING_ALL_EVENTS,
            payload:isLoading
        }
    },
    setAllPeriod:(period:IPeriod):ISetPeriod => {
        return {
            type:allEventEnum.SET_ALL_PERIOD,
            payload:period
        }
    },
    setAllEventPrevFilter:(newFilter:any):ISetPrevFilter => {
        return {
            type:allEventEnum.SET_ALL_RPEV_FILTER,
            payload:newFilter
        } 
    },
    deleteEvent:(key:string):IDeleteEvent => {

           return {
               type:allEventEnum.DELETE_EVENT,
               payload:key,
           }
    },
    setAllEventsMutation:(TrendsElements:any):ISetTrends => {
        return{
            type:allEventEnum.SET_ALL_EVENTS,
            payload:TrendsElements
        }
    },
    setNumberNewEvent:(countNewEvents:number):ISetNumberNewEvent => {
        return{
            type:allEventEnum.SET_NUMBER_NEW_EVENT,
            payload:countNewEvents
        }
    },
    setNewEvent:() => {
        return {
            type:allEventEnum.SET_NEW_ELEMENTS
        }
    },
    setFullEvents:(TrendsElements:any):ISetFullEvent => {
        return {
            type:allEventEnum.SET_FULL_EVENTS,
            payload:TrendsElements,
        }
    },
    updateNewState:() => async (dispath:AppDispatch) => {
        
    } 

    
}