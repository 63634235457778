import React, {useEffect, useRef, useState} from "react";
import {ILabel} from '../../models/EventContentModel'
import {useNavigate} from "react-router-dom";
import {useTransition} from "../../hooks/useTransition";
import MutedStocksModal from "../Modals/MutedStocksModal";
import {DeviceIsMobile, gettOrigin} from "../../Utills/ClearFunctions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";

interface IMutedSideBar {
    isOpenMenuSideBar: boolean,
    labelState: Array<ILabel>,
    changeActive: (name: string) => void,
    closeAllListItem?:boolean,
    closeDropDown:() => void,
}

function MutedSideBar({isOpenMenuSideBar, labelState, changeActive, closeAllListItem, closeDropDown}: IMutedSideBar) {
    const listRef = useRef<any>()
    const navigate = useNavigate()
    const [diffX, setDiffX] = useState(0)
    const {transitionToMutedPage,transitionToMutedPageWithoutEvent} = useTransition()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)

    const [showMuted, setShowMuted] = useState(false);
    const handleCloseMuted = () => setShowMuted(false);
    const handleShowMuted = () => setShowMuted(true);

    const labelClick = (name: string) => (e: React.MouseEvent<HTMLLabelElement>) => {
        changeActive(name)
        closeDropDown()
    }

    useEffect(() => {
        if(!listRef.current) return
        setDiffX(listRef.current.getBoundingClientRect().width)
        listRef.current.style.display = 'block'
        listRef.current.style.transform = `translate3d(${-listRef.current.getBoundingClientRect().width + 40}px, 33px, 0px)`
        

    }, [isOpenMenuSideBar])


    const listAllEventHandler = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        closeDropDown()
        if (DeviceIsMobile(userAgent))
            transitionToMutedPageWithoutEvent()
        else
            handleShowMuted()
        e.preventDefault()
    }
    return (<div onClick={(e) => e.stopPropagation()}>
        {
            !closeAllListItem && <MutedStocksModal show={showMuted} handleClose={handleCloseMuted}/>
        }

        { isOpenMenuSideBar &&<>

            <div ref={listRef} id="filter-types-menu" className="dropdown-menu dropdown-menu-right mt-2 show"
                 style={{
                     display: 'none',
                     zIndex: '100',
                     position: 'absolute',
                     willChange: 'transform',
                     top: '0px',
                     left: '0px'
                 }}
                 x-placement="bottom-end">
                {
                    labelState.map((label) => {

                        if (label.name === 'power') {
                            return <label data-ticker="VLDR" data-status="0"
                                          className={label.active
                                              ? 'js__change-mute-status dropdown-item align-items-center cursor-pointer active'
                                              : 'js__change-mute-status dropdown-item align-items-center cursor-pointer'}
                                          onClick={labelClick(label.name)}>
                                <i className="fa-fw fas fa-volume-up text-primary"></i>&nbsp;&nbsp;
                                    {t('STOCK_MUTE_ENABLE')}
                            </label>
                        }

                        if (label.name === 'muted') {
                            return (<label data-ticker="VLDR" data-status="1"
                                           className={label.active
                                               ? 'js__change-mute-status dropdown-item align-items-center cursor-pointer active'
                                               : 'js__change-mute-status dropdown-item align-items-center cursor-pointer'}
                                           onClick={labelClick(label.name)}>
                <span className="text-muted">
                    <svg className="fa-custom-icon" xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 640 512"><path fill="currentColor"
                                                     d="M633.82 458.1l-69-53.33C592.42 360.8 608 309.68 608 256c0-95.33-47.73-183.58-127.65-236.03-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 42.78-11.96 83.59-33.22 119.06l-38.12-29.46C503.49 318.68 512 288.06 512 256c0-63.09-32.06-122.09-85.77-156.16-11.19-7.09-26.03-3.8-33.12 7.41-7.09 11.2-3.78 26.03 7.41 33.13C440.27 165.59 464 209.44 464 256c0 21.21-5.03 41.57-14.2 59.88l-39.56-30.58c3.38-9.35 5.76-19.07 5.76-29.3 0-31.88-17.53-61.33-45.77-76.88-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61 11.76 6.46 19.12 18.18 20.4 31.06L288 190.82V88.02c0-21.46-25.96-31.98-40.97-16.97l-49.71 49.7L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM32 184v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V352.6L43.76 163.84C36.86 168.05 32 175.32 32 184z"
                                                     className=""></path>
                    </svg>
                </span>&nbsp;&nbsp;
                {t('STOCK_MUTE_TODAY')}</label>)
                        }
                        if (label.name === 'allTimeMuted') {
                            return (
                                <label data-ticker="VLDR" data-status="2"
                                       className={label.active
                                           ? 'js__change-mute-status dropdown-item align-items-center cursor-pointer active'
                                           : 'js__change-mute-status dropdown-item align-items-center cursor-pointer'}
                                       onClick={labelClick(label.name)}>
                <span className="text-muted"><svg className="fa-custom-icon" xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 640 512"><path fill="currentColor"
                                                                              d="M633.82 458.1l-69-53.33C592.42 360.8 608 309.68 608 256c0-95.33-47.73-183.58-127.65-236.03-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 42.78-11.96 83.59-33.22 119.06l-38.12-29.46C503.49 318.68 512 288.06 512 256c0-63.09-32.06-122.09-85.77-156.16-11.19-7.09-26.03-3.8-33.12 7.41-7.09 11.2-3.78 26.03 7.41 33.13C440.27 165.59 464 209.44 464 256c0 21.21-5.03 41.57-14.2 59.88l-39.56-30.58c3.38-9.35 5.76-19.07 5.76-29.3 0-31.88-17.53-61.33-45.77-76.88-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61 11.76 6.46 19.12 18.18 20.4 31.06L288 190.82V88.02c0-21.46-25.96-31.98-40.97-16.97l-49.71 49.7L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM32 184v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V352.6L43.76 163.84C36.86 168.05 32 175.32 32 184z"
                                                                              className=""></path></svg></span>&nbsp;&nbsp;
                            {t('STOCK_MUTE_FOREVER')}</label>
                            )
                        }
                    })
                }

                {
                    !closeAllListItem && <>
                        <li className="dropdown-divider"></li>
                        <a className="text-primary dropdown-item" href={`${gettOrigin()}/profile/muted-stocks`}
                           onClick={listAllEventHandler}>
                            {t('STOCK_MUTE_LIST')}
                        </a>
                    </>
                }

            </div>
            </>
        }
    </div>)
}

export default MutedSideBar