import $api from "./index";
import {AuthGlobalActionCreators} from "../redux/reducers/User/actions";

function useCreateInterseptions(store:any){

    $api.interceptors.response.use((config) => {
        return config;
    },async (error) => {
        const originalRequest = error.config;
        if (error.response.status == 401 && error.config && !error.config._isRetry) {
            originalRequest._isRetry = true;
            store.dispatch(AuthGlobalActionCreators.logout()) 
        }      
        else
            throw error;  
    })
}

export default useCreateInterseptions