import { useState } from "react";
import moment, { Moment } from "moment-timezone";
import { useTypedSelector } from "./useTypedSelector";
import 'moment/locale/ru'
import 'moment/locale/es'
import { t } from "i18next";


function useDate() {
    const User = useTypedSelector(state => state.UserReducer.User)
    const language = useTypedSelector(state => state.UserReducer.User?.settings?.language)
    const region = useTypedSelector(state => state.UserReducer.User?.settings?.region)

    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const [timeZone, setTimeZone] = useState<string>(User?.settings?.timezone)
    const IFrameChartLanguage  = 'en'
    const IFrameChartRegion  = 'US'

    const getTimeAgo = (timeStamp: number | undefined): string => {
        if (!timeStamp) return ''
        //@ts-ignore
        moment.locale(language)
        const time = moment.tz(timeStamp * 1000, timeZone)
        if (isTodayMoment(time)) {
            const timeAgo = moment(timeStamp * 1000).fromNow()
            return timeAgo
        }
        else {
            return ''
        }
    }

    const getCurrentDate = (): number => {
        const a = moment.tz(Date.now(), timeZone).format('DD')
        return Number(a)
    }
    const getLocalTimeZone = () => {
        return Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    const diffTimeFromNow = (timeStamp: number) => {
        const now = moment(new Date()); //todays date
        const end = moment(timeStamp * 1000); // another date
        const duration = moment.duration(end.diff(now));
        const hours = duration.asHours();

        let minutes = duration.asMinutes();
        const fixedHourse = Math.trunc(hours)
        const fixedMinutes = Math.trunc(minutes - fixedHourse * 60)
        const seconds = duration.asSeconds();
        const fixedSeconds = Math.trunc(seconds - fixedMinutes * 60)
        return { hours: fixedHourse, minutes: fixedMinutes, seconds: fixedSeconds }
    }

    function isTodayMoment(momentDate: any) {
        const REFERENCE = moment.tz(Date.now(), timeZone)
        const TODAY = REFERENCE.clone().startOf('day');
        return momentDate.isSame(TODAY, 'd');
    }
    function isToday(timeStamp: number) {
        const time = moment(timeStamp * 1000);
        return isTodayMoment(time)
    }
    function isYesterday(momentDate: any) {
        const REFERENCE = moment.tz(Date.now(), timeZone)
        const YESTERDAY = REFERENCE.clone().subtract(1, 'days').startOf('day');
        return momentDate.isSame(YESTERDAY, 'd');
    }
    const getValidSubscriptionDate = (timeStamp: number | null) => {
        if (!timeStamp) return {} as any
        const dateEnd = moment.tz(timeStamp * 1000, timeZone);
        const dateNow = moment.tz(Date.now(), timeZone)
        const diffDay = dateEnd.diff(dateNow, 'days')

        const startDate = dateEnd.clone().subtract(3, 'month')
        const AmountOfDays = dateEnd.diff(startDate, 'days')
        const percent = Math.round(diffDay / (AmountOfDays) * 100)


        const year = dateEnd.format('YYYY')
        const day = dateEnd.format('DD')
        const nameOfMonth = dateEnd.format('MMM')

        // const dateStr = `${day} ${nameOfMonth} ${year} г.`

        const fullDatepOptions = {
            dateStyle: 'medium',
            timeZone: timeZone,
            // timeStyle: 'short'
        }
        //@ts-ignore
        const dateStr = new Intl.DateTimeFormat(language + '-' + region, fullDatepOptions).format(timeStamp * 1000)


        return { diffDay, percent, dateStr }
    }

    const getShortDate = (timeStamp: number) => {
        const shortOptions = {
            dateStyle: 'short',
            timeZone: timeZone,
        }
        //@ts-ignore
        const testTime = new Intl.DateTimeFormat(language + '-' + region, shortOptions).format(timeStamp * 1000)
        return testTime
    }

    const getOptionalDataValue = (timeStamp: Moment) => {
        if (isTodayMoment(timeStamp))
            return `${t('EVENTS_DATE_TODAY')}`
        else if (isYesterday(timeStamp))
            return `${t('EVENTS_DATE_YESTERDAY')}`
        else return null
    }

    const isDaysAgo = (timeStamp: number, daysAgo: number) => {
        const REFERENCE = moment.tz(timeStamp, timeZone)
        const daysAgoDate = REFERENCE.clone().subtract(daysAgo, 'days').startOf('day');
        const nowDate = moment.tz(Date.now(), timeZone)
        return nowDate.isSame(daysAgoDate, 'd');
    }

    const twoDaysAgo = (timeStamp: number) => {
        const REFERENCE = moment.tz(timeStamp, timeZone)
        const daysAgo = REFERENCE.clone().subtract(2, 'days').startOf('day');
        const nowDate = moment.tz(Date.now(), timeZone)
        return nowDate.isSame(daysAgo, 'd');
    }


    interface ITimeIsOver {
        timeIsOver: boolean,
        timeIsCloseOver: boolean
        numberOfDay: string
    }

    const getTimeIsOver = (timeStamp: number | null): ITimeIsOver => {
        if (!User.subscriptionInfo.isAvailableTrial && !timeStamp) return { timeIsOver: true, timeIsCloseOver: true, numberOfDay: '0' }
        //@ts-ignore 
        const dateEnd = moment.tz(timeStamp * 1000, timeZone);
        const dateNow = moment.tz(Date.now(), timeZone)
        const diffDay = dateEnd.diff(dateNow, 'days')


        if (diffDay > 5 || diffDay < 0) {
            return { timeIsOver: false, timeIsCloseOver: false, numberOfDay: '' }
        }
        else {
            return { timeIsOver: false, timeIsCloseOver: true, numberOfDay: String(diffDay) }
        }
    }

    const getChartDate = (timeStamp: number) => {
        const date = moment.tz(timeStamp, timeZone);
        const day = date.format('DD')
        const mount = date.format('MM')
        const minuts = date.format('mm')
        const hours = date.format('HH')
        const shortTime = hours + ":" + minuts

        const fullDateTootipOptions = {
            dateStyle: 'short',
            timeZone: timeZone,
            timeStyle: 'short',
            hour12: false,
        }
        //@ts-ignore
        const fullDateTootip = new Intl.DateTimeFormat(language + '-' + region, fullDateTootipOptions).format(timeStamp)
        return fullDateTootip
    }
    const getIframeChartDate = (timeStamp: number) => {
        const timeZone = getLocalTimeZone()
        const date = moment.tz(timeStamp, timeZone);
        const day = date.format('DD')
        const mount = date.format('MM')
        const minuts = date.format('mm')
        const hours = date.format('HH')
        const shortTime = hours + ":" + minuts

        const fullDateTootipOptions = {
            dateStyle: 'short',
            timeZone: timeZone,
            timeStyle: 'short',
            hour12: false,
        }
        //@ts-ignore
        const fullDateTootip = new Intl.DateTimeFormat( IFrameChartLanguage + '-' + IFrameChartRegion, fullDateTootipOptions).format(timeStamp)
        return fullDateTootip
    }
    const getTimeZone = () => {
        return timeZone
    }

    const getChartLineDate = (timeStamp: number) => {
        return moment.tz(timeStamp, timeZone).valueOf();
    }

    const getshortTime = (timeStamp: number) => {
        const shortDatepOptions = {
            timeZone: timeZone,
            timeStyle: 'short'
        }
        //@ts-ignore
        const shortTime = new Intl.DateTimeFormat(language + '-' + region, shortDatepOptions).format(timeStamp * 1000)
        return shortTime
    }



    const getLongTime = (timeStamp: number) => {
        const longDatepOptions = {
            month: 'long', day: 'numeric',
            // hour: 'numeric', minute: 'numeric', second: 'numeric',
            hour: 'numeric', minute: 'numeric',
            timeZone: timeZone,
            timeZoneName: 'short'
        }
        //@ts-ignore
        const longTime = new Intl.DateTimeFormat(language + '-' + region, longDatepOptions).format(timeStamp * 1000)
        return longTime
    }
    const getNowTime = () => {
        const shortTimeOptions = {
            hour: 'numeric', minute: 'numeric',
            timeZone: timeZone,
            timeZoneName: 'short'
        }
        //@ts-ignore
        const shortTime = new Intl.DateTimeFormat(language + '-' + region, shortTimeOptions).format(Date.now())
        return shortTime
    }

    const getCommentDate = (timeStamp: number) => {
        const date = moment.tz(timeStamp * 1000, timeZone);
        let optionalDataValue: string | null = getOptionalDataValue(date)
        const fullDatepOptions = {
            dateStyle: 'medium',
            timeZone: timeZone,
            timeStyle: 'short'
        }
        //@ts-ignore
        const fullDate = new Intl.DateTimeFormat(language + '-' + region, fullDatepOptions).format(timeStamp * 1000)

        const shortDatepOptions = {
            timeZone: timeZone,
            timeStyle: 'short'
        }
        //@ts-ignore
        const shortTime = new Intl.DateTimeFormat(language + '-' + region, shortDatepOptions).format(timeStamp * 1000)
        return { fullDate, optionalDataValue, shortTime }
    }

    const getMediumDateabdShortTime = (timeStamp: number) => {
        const shortDateOptions = {
            dateStyle: 'short',
            timeStyle: 'short',
            timeZone: timeZone,
        }
        //@ts-ignore
        const shortDateTime = new Intl.DateTimeFormat(language + '-' + region, shortDateOptions).format(timeStamp * 1000)
        const mediumTimeOptions = {
            timeStyle: 'medium',
            timeZone: timeZone,
        }
        //@ts-ignore
        const mediumTime = new Intl.DateTimeFormat(language + '-' + region, mediumTimeOptions).format(timeStamp * 1000)
        return { shortDateTime, mediumTime }
    }



    const getNameOfTheMount = (timeStamp: number) => {
        const mounthOptions = {
            month: 'short',
            timeZone: timeZone,
        }
        //@ts-ignore
        const str = new Intl.DateTimeFormat(language, mounthOptions).format(timeStamp * 1000)
        return (str.length <= 3) ? str : str.substr(0, 3) + '.'
    }

    const getMediumTime = (timeStamp: number) => {
        const mediumTimeOptions = {
            // timeZone: timeZone,
            timeStyle: 'medium',
            timeZone: timeZone,
        }
        //@ts-ignore
        return new Intl.DateTimeFormat(language + '-' + region, mediumTimeOptions).format(timeStamp * 1000)
    }



    const getMomentDate = (timeStamp: number) => {
        const date = moment.tz(timeStamp * 1000, timeZone);
        let optionalDataValue: string | null = getOptionalDataValue(date)
        //fix
        const currentDate = date.format('DD')
        const day = date.format('DD')
        const mount = date.format('MM')
        const year = date.format('YYYY')
        const minuts = date.format('mm')
        const hours = date.format('HH')

        // const options = {
        //     // year: 'numeric', month: 'numeric', day: 'numeric',
        //     hour: 'numeric', minute: 'numeric', second: 'numeric',
        //     hour12: false,
        //     timeZone: 'America/Los_Angeles',
        //     dateStyle: 'full',
        //     timeStyle: 'long'
        //   };
        const shortOptions = {
            // dateStyle: 'short',
            timeStyle: 'short',
            timeZone: timeZone,
        }

        const options = {
            dateStyle: 'medium',
            timeZone: timeZone,
            // timeStyle: 'short'
        }


        const testOptions = {
            // timeZone: timeZone,
            timeStyle: 'medium',
            timeZone: timeZone,
        }
        //@ts-ignore
        const testTime = new Intl.DateTimeFormat(language + '-' + region, testOptions).format(timeStamp * 1000)

        const shortDateOptions = {
            dateStyle: 'short',
            // timeZone: timeZone,
            timeStyle: 'short',
            timeZone: timeZone,
        }
        //@ts-ignore
        const shortDateTime = new Intl.DateTimeFormat(language + '-' + region, shortDateOptions).format(timeStamp * 1000)
        const mediumTimeOptions = {
            // timeZone: timeZone,
            timeStyle: 'medium',
            timeZone: timeZone,
        }
        //@ts-ignore
        const mediumTime = new Intl.DateTimeFormat(language + '-' + region, mediumTimeOptions).format(timeStamp * 1000)
        //@ts-ignore
        const shortTime = new Intl.DateTimeFormat(language + '-' + region, shortOptions).format(timeStamp * 1000)
        //@ts-ignore
        let fullDate = `${new Intl.DateTimeFormat(language + '-' + region, options).format(timeStamp * 1000)} , ${shortTime}`
        fullDate = fullDate.replace('г.', '')
        //@ts-ignore
        const nameOfMounth = getNameOfTheMount(timeStamp)
        const fullDateTootipOptions = {
            dateStyle: 'medium',
            timeZone: timeZone,
            timeStyle: 'medium'
        }
        //@ts-ignore
        const fullDateTootip = new Intl.DateTimeFormat(language + '-' + region, fullDateTootipOptions).format(timeStamp * 1000)
        //fix
        return [
            shortTime,
            Number(day),
            nameOfMounth,
            Number(currentDate),
            optionalDataValue,
            fullDate,
            year,
            fullDateTootip,
            shortDateTime,
            mediumTime
        ]
    }

    return {
        getshortTime,
        getMediumTime,
        isToday,
        getShortDate,
        getTimeAgo,
        diffTimeFromNow,
        getCurrentDate,
        getTimeIsOver,
        getTimeZone,
        getMomentDate,
        getValidSubscriptionDate,
        getCommentDate,
        getChartDate,
        getChartLineDate,
        getMediumDateabdShortTime,
        getLongTime,
        getNowTime,
        getIframeChartDate,
        getLocalTimeZone,
        IFrameChartLanguage,
        IFrameChartRegion
    }
}

export default useDate