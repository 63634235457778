import {useTransition} from "../hooks/useTransition";
import React, { useState } from "react";
import UserService from "../Services/UserService";
import { Alert, themeAlertEnum } from "../components/Alerts";
import { UseActions } from "../hooks/useActions";
import { gettOrigin } from "../Utills/ClearFunctions";
import { t } from "i18next";

function CancelAutoPayment(){
    const {transitionCancelToProfile,profileTransition} = useTransition()
    const [error,setError] = useState<{isError:boolean,text:string}>({isError:false,text:''})
    const {setDeviceType, checkAuth, setListStruct, SetTrends, checkAuthWithSharedData} = UseActions()

    const handleCancelAutoPayment = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        try{
            const response = await UserService.cancelAutoPayment()
            await checkAuth()
            transitionCancelToProfile(e)
        }
        catch(e){
            setError({isError:true,text:`${t('SUBSCRIPTION_AUTOPAY_CANCEL_ERROR')}`})
        }
    }

    const cancelErrorUI = () => {
       setError({isError:false,text:''}) 
    }

    return(
        <div className="row justify-content-md-center">
            <Alert theme = {themeAlertEnum.Danger} close = {cancelErrorUI} isOpen = {error.isError} text = {error.text}>
                <span>{error.text}</span>
            </Alert>

            <div className="text-center">
                <div className="card card-custom p-4 mb-4 text-center">
                    <h3 className="text-center font-weight-bolder mb-6">{t('SUBSCRIPTION_AUTOPAY_CANCEL_HEADER')}</h3>
                    <p>
                        <b>{t('SUBSCRIPTION_AUTOPAY_CANCEL_SUBHEADER')}</b>
                    </p>
                    <div className="d-flex flex-center pb-4 align-items-baseline">
                        <i className="fas fa-1x fa-times font-size-xs text-danger mr-2"></i> {t('SUBSCRIPTION_AUTOPAY_CANCEL_AFTER1')}
                    </div>
                    <div className="d-flex flex-center pb-4 align-items-baseline">
                        <i className="fas fa-1x fa-times font-size-xs text-danger mr-2"></i> {t('SUBSCRIPTION_AUTOPAY_CANCEL_AFTER2')}
                    </div>
                    <hr/>
                        <p>{t('SUBSCRIPTION_AUTOPAY_CANCEL_QUESTION')}</p>
                        <p>
                            <a 
                                className="btn btn-sm w-100px btn-primary mr-6"
                                href= {`${gettOrigin()}`} 
                                onClick={
                                    profileTransition
                                    // profileTransition
                                    }>{t('SUBSCRIPTION_AUTOPAY_CANCEL_YES')}</a>
                            <a 
                                onClick={handleCancelAutoPayment}
                                className="btn btn-link btn-link-primary"
                                href="">{t('SUBSCRIPTION_AUTOPAY_CANCEL_NO')}</a>
                        </p>
                </div>
            </div>
        </div>
    )
}

export default CancelAutoPayment

