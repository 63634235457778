import React, { useEffect, useMemo, useState } from "react";
import useSelectCard from "./useSelectCard";
import { v4 as uuidv4 } from "uuid";
import UserService from "../Services/UserService";
import { GroupEnum } from "../models/Search";
import { FavouritesQuery } from "../Strategy/Card/FavoritesQuery";
import { GroupQuery } from "../Strategy/Card/GroupQuery";
import { useTypedSelector } from "./useTypedSelector";
import { IError } from "../models/EventContentModel";
import { PlaneTypes, StatusTypes } from "../models/User";
import { gettOrigin } from "../Utills/ClearFunctions";
import { UseActions } from "./useActions";
import { IFavouriteCard } from "../redux/reducers/FavouritesCard/types";
import { IGroups } from "../page/Stoсk";
import { LIMIT_NUMBER_OF_TICKERS } from "../page/SelectFavourites";

interface ISelect {
    addToFavorites?: (ticket: string, src?: string) => void
    IsFavorites: boolean
    isAuth?: boolean
    isSearch?: boolean
    isStockPage?: boolean
    isSelect?: boolean
    isPage?: boolean
    postTickerClick?: (ticket: string) => void
}

interface ICardTickers {
    src: string
}

export type IUserSelectedFavourites = { src: string } | number

function useSelectCards({ isSelect, isStockPage, isSearch, addToFavorites, IsFavorites, isAuth, isPage, postTickerClick }: ISelect) {
    const Card = useSelectCard(IsFavorites)
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isShowStar, setIsShowStar] = useState<boolean>(false)
    const [favouriesCards, setFavouriesCards] = useState<Array<IUserSelectedFavourites>>([])
    const User = useTypedSelector(state => state.UserReducer.User)
    const FavouritesCards = useTypedSelector(state => state.FavouritesCard.FavouritesCards)
    const { setFavouritesCards } = UseActions()

    const [state, setState] = useState([
        {
            src: ``,
            cardName: 'Default',
            percent: 0,
            ticket: '',
            price: 0,
            star: true,
            fill: false,
        },
    ])
    const [isCardError, setIsCardError] = useState<IError>(
        {
            isError: false,
            textError: ''
        })

    const [cardTickers, setCardTickers] = useState<Array<ICardTickers>>([
        { src: '' },
        { src: '' },
        { src: '' },
        // { src: '' },
        // { src: '' },
    ])

    useEffect(() => {
        if (User.status === StatusTypes.PENDING) return
        // debugger
        if (isStockPage)
            StockPageUpdate()
        if (IsFavorites)
            FavouritesPageUpdate()
        if (isSelect)
            isSelcectUpdate()
    }, [])

    const isSelcectUpdate = async () => {
        const data = await FavouritesQuery.getData()
        const favoutitesState = data.map((element: any) => {
            return { src: element.src }
        })

        setFavouriesCards(favoutitesState)
        if (favoutitesState.length <= 3) {
            let minorState = favoutitesState
            for (let i = favoutitesState.length; i < LIMIT_NUMBER_OF_TICKERS; i++) {
                minorState[i] = {
                    src: ''
                }
            }
            setCardTickers(minorState)
        }
        else {
            setCardTickers(favoutitesState)
        }
    }

    const FavouritesPageUpdate = async (isForceUpdate?: boolean) => {
        const currentWidth = window.innerWidth
        if (!isPage && currentWidth < 991) {
            return
        }

        if (state[0].cardName === 'Default')
            setIsLoading(true)
        const data = await FavouritesQuery.getData(FavouritesCards, isForceUpdate)
        
        setState(data)
        setFavouritesCards(data)
        setIsLoading(false)
    }
    const [GroupsData, setGroupsData] = useState<Array<IGroups>>([])

    const makeQuery = async (query: string) => {
        setIsLoading(true)
        const GroupData = await GroupQuery.getData(
            {
                group: '',
                query,
                setFavouriesCards,
                favouaritesCard: FavouritesCards,
                setGroupsData,
            }
        )
      
        setState(GroupData)
        setIsLoading(false)
    }
    const StockPageUpdate = async () => {
        setIsLoading(true)
        const GroupData = await GroupQuery.getData(
            {
                group: GroupEnum.POPULAR,
                query: '',
                setFavouriesCards,
                setGroupsData,

            }
        )
        setState(GroupData)
        setIsLoading(false)
    }

    const requestGroup = async (group: GroupEnum, query: string) => {
        setIsLoading(true)
        const GroupData = await GroupQuery.getData(
                {
                    group, 
                    query, 
                    setFavouriesCards
                }
            )
        setState(GroupData)
        setIsLoading(false)
    }

    const closeCardError = () => {
        setIsCardError({ isError: false, textError: '' })
    }

    const showCardError = (text: string) => {
        setIsCardError({ isError: true, textError: text })
    }

    const handleCardError = (response: any) => {
        if (response.data.status === "failed") {
            showCardError(response.data.error.message)
            return true
        }
        else {
            closeCardError()
            return false
        }
    }

    const removeOrAdd = async (element: any) => {
        if (!element.fill) {
            const response: any = await UserService.addFavourite(element.ticket)
            return handleCardError(response)
        }
        else {
            const response: any = await UserService.removeFavourite(element.ticket)
            return handleCardError(response)
        }
    }

    const deleleteLastElement = (favouriesCards: Array<any>) => {
        return favouriesCards.filter((card, index) => {
            return favouriesCards.length - 1 !== index
        })
    }
    const updateFavourites = (element: IFavouriteCard) => {
        if (isShowStar) {
            setFavouritesCards(FavouritesCards.map((FavouritesElement) => {
                if (FavouritesElement.ticket === element.ticket) return { ...element, fill: !element.fill }
                else return { ...FavouritesElement }
            }))
            return
        }
        if (!element.fill) { // add
            FavouritesCards.push({ ...element, fill: true, star: false })
            setFavouritesCards(FavouritesCards)
        }
        else { //remove
            setFavouritesCards(
                FavouritesCards.filter((FavouritesElement) => FavouritesElement.ticket !== element.ticket)
            )
        }
    }

    const changeFillIterableAction = (element: IFavouriteCard, ticket: string, newState: Array<IFavouriteCard>) => {
        const currentTicket = element.ticket
        if (currentTicket === ticket) {
            if (Plane === PlaneTypes.PRO && User.subscriptionInfo.finishedAt) {
                newState.push({ ...element, fill: !element.fill })
                updateFavourites(element)
                removeOrAdd(element)
            }
            else {
                if (!User.subscriptionInfo.finishedAt || Plane === PlaneTypes.FREE) {
                    if (favouriesCards.length < 5 || element.fill) {
                        newState.push({ ...element, fill: !element.fill })
                        updateFavourites(element)
                        if (element.fill) {
                            setFavouriesCards(deleleteLastElement(favouriesCards))
                        }
                        else {
                            favouriesCards.push(1)
                            setFavouriesCards([...favouriesCards])
                        }
                    }

                    else {
                        newState.push({ ...element })
                    }
                }
                else {
                    if (favouriesCards.length < 15 || element.fill) {
                        newState.push({ ...element, fill: !element.fill })
                        updateFavourites(element)
                        if (element.fill) {
                            setFavouriesCards(deleleteLastElement(favouriesCards))
                        }
                        else {
                            favouriesCards.push(1)
                            setFavouriesCards([...favouriesCards])
                        }
                    }
                    else {
                        newState.push({ ...element })
                    }
                }
                removeOrAdd(element)
            }
        }
        else newState.push({ ...element })
    }
    const changeFill = async (ticket: string, src?: string) => {
        if (addToFavorites) {
            addToFavorites(ticket, src)
        }
        const newState: Array<IFavouriteCard> = []
        const FavoaritesnewState: Array<IFavouriteCard> = []

        for (let i = 0; i < FavouritesCards.length; i++) {
            const element = FavouritesCards[i]
            changeFillIterableAction(element, ticket, FavoaritesnewState)
        }

        for (let i = 0; i < state.length; i++) {
            const element = state[i]
            changeFillIterableAction(element, ticket, newState)
        }
        setState(newState)
    }
    const [isTransitionToCompanyWithoutClick, setIsTransitionToCompanyWithoutClick] = useState<boolean>(false)

    const Cards = useMemo(() => {
        return state.map((value) => {
            const obj = { ...value, key: uuidv4(), changeFill: changeFill, isAuth: isAuth, brand: value.cardName, isTransitionToCompanyWithoutClick, setIsTransitionToCompanyWithoutClick, postTickerClick }
            return <Card {...obj} />
        })
    }, [state, isTransitionToCompanyWithoutClick])

    const FavouritesCardsJSx = FavouritesCards.map((value) => {
            const obj = { ...value, key: uuidv4(), changeFill: changeFill, isAuth: isAuth, brand: value.cardName, isTransitionToCompanyWithoutClick, setIsTransitionToCompanyWithoutClick }
            return <Card {...obj} />
        })

    const selectFavorites = () => {
        setIsShowStar(true)
        setState(state.map((val) => {
            return { ...val, star: true, fill: true }
        }))
        setFavouritesCards(FavouritesCards.map((val) => {
            return { ...val, star: true, fill: true }
        }))
    }

    const unSelectFavorites = () => {
        setIsShowStar(false)
        FavouritesPageUpdate(true)
    }

    return {GroupsData, setGroupsData, cardTickers, setCardTickers, FavouritesCardsJSx, Cards, selectFavorites, unSelectFavorites, isShowStar, makeQuery, requestGroup, isLoading, isCardError, closeCardError, setIsTransitionToCompanyWithoutClick }
}
export default useSelectCards