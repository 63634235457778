import { FC } from "react";
import { Trans } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";

const NotFoundCompany:FC = () => {
    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto)
    return <>
        <div className="mt-5">
            <Trans i18nKey= {`${isCrypto ? 'WIZARD_FAVORITES_CRYPTO_EMPTY_RESULT' : 'WIZARD_FAVORITES_EMPTY_RESULT'}`} >
                <b className=""></b>
                &nbsp; 
                <ul className="mt-2">
                    <li>Ошибка в написании</li>
                    <li>Данная акция не торгуется на СПБ Бирже</li>
                </ul> 
            </Trans>
         </div>          
    </>
}

export default NotFoundCompany
