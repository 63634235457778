import '../../styles/Tooltip.css'
import CustomBadget from "../Buttons/CustomBadget";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import useGetCompany from "../../hooks/useGetCompany";
import React, {useContext, useEffect, useLayoutEffect, useMemo} from "react";
import {CompanyContext} from "../../Context";
import { useTranslation } from 'react-i18next';
import DescriptionBarLargeImage from '../Image/DescriptionBarLargeImage';






function CompanyDescription(){
    const CurrentCompany = useTypedSelector(state => state.CompanyReducer)
    const {companyInformation} = useContext(CompanyContext)
    const {t} = useTranslation()

    useEffect(() => {
        let styles = `
        .tooltip-inner {
            max-width: 350px;
        }
        .tooltip > div.tooltip-inner {
                    background-color: #181C32 !important;
                    color: white !important;
                     
                }
        .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
        top: -1px;
        border-width: 0.4rem 0.4rem 0;
        border-top-color: #181C32 !important;
        //background-color: #181C32 !important;
        }
    `
        let styleSheet = document.createElement("style")
        styleSheet.innerText = styles
        const TooltipId = 'TooltipStyleBlack'

        styleSheet.id = TooltipId
        document.head.appendChild(styleSheet)
        return () => {

            const sheets = document.getElementById(TooltipId)
            sheets?.remove()
        }
    },[])

    const getIndicesText = (name:string) => {
        const indices = [
            {
                name:"Nasdaq 100",
                text:t('STOCK_PROFILE_INDEX_NASDAQ')
            },
            {
                name:'S&P 500',
                text:t('STOCK_PROFILE_INDEX_SP500')
            },
            {
                name:'Dow Jones Industrial Average',
                text:t('STOCK_PROFILE_INDEX_DOW')
            },
            {
                name:'Russell 2000',
                text:t('STOCK_PROFILE_INDEX_RUSSEL')   
            }
        ]
        return indices.find((indice) => { return indice.name === name })?.text
 
    }
    const indicesElement:Array<any> = useMemo( () => companyInformation?.indices.map((indices:any) => 
        <CustomBadget nameTooltip={indices} text={`${getIndicesText(indices)}`}/>
    ),[companyInformation])


    const renderIndexElement = useMemo (() => {
        if(indicesElement){
            if(indicesElement.length !== 0){
                return <> <b>{t('STOCK_PROFILE_INDEXES')}:</b> { indicesElement } </>
            }
            else {
                return <></>
            }
        }
        else return <></>

    },[indicesElement])

    return (
        <div className="card card-custom p-4">
            <div className="d-lg-none d-flex align-items-center mb-5">
                <div className="symbol symbol-50 symbol-circle mr-5">
                <DescriptionBarLargeImage src = {CurrentCompany.logo} />
                    {/* <img alt="logo" src={CurrentCompany.logo}/> */}
                </div>
                <div className="d-flex flex-glow-1 flex-column">
                    <h2 className="font-weight-bolder">{companyInformation?.fullName}</h2>
                    <div className="flex-glow-1"><i className="fa fa-map-marker-alt" aria-hidden="true"></i> {companyInformation?.country}
                    </div>
                </div>
            </div>
            <a target="_blank" href={`${companyInformation?.site}`} className="mb-2">
                <i className="fa fa-globe-americas mr-2" aria-hidden="true"></i> {companyInformation?.site} </a>
            <a target="_blank" href={`${companyInformation?.siteForInvestors}` } className="mb-2">
                <i className="fa fa-handshake  mr-2"
                   aria-hidden="true"></i> {companyInformation?.siteForInvestors} </a>
            <hr/>
                <div><b>{t('STOCK_PROFILE_INDUSTRY')}:</b> {companyInformation?.industry} </div>
                <div><b>{t('STOCK_PROFILE_SECTOR')}:</b> {companyInformation?.sector} </div>
                <div>
                    { renderIndexElement }
                </div>
                <hr/>
                    <div><b>{t('STOCK_PROFILE_MARKET_CAP')}:</b> {CurrentCompany?.capitalization}</div>
                    <div><b>{t('STOCK_PROFILE_AVG_VOLUME')}</b> {CurrentCompany?.shareVolume}</div>
                    <hr/>
                        <p className="">{companyInformation?.description}</p>
        </div>
    )
}

export default CompanyDescription