import $api from "../http";
import {AxiosResponse} from 'axios';
import { GroupEnum } from "../models/Search";
import {IListStruct} from "../redux/reducers/filter/types";
import { FilterConstant } from "../models/FilterEvent";
import {IProfileTranslateObj} from "../page/Profile";
// https://tradesense.ru/api/v1/events?filter=0&types[]=0
const getListTypes = (filter:Array<IListStruct>) =>{
    const filteredArray = filter.filter(ListElement => ListElement.check)
    if(filteredArray.length === 0) return 0
    return filteredArray.map(checkedElement => checkedElement.id)
}

export default class UserService {

    static async checkPromo(inviteCode:string){
        return $api.post('/subscription/check-promo',
            {
                "promoCode": inviteCode
            }
        )
    }
    static async getMarketData(sector:number | null = null){
        if(sector)
            return $api.get(`/stocks/market-map-list`,{ params: { sector:sector} })
        return $api.get(`/stocks/market-map`)
    }

    static async getMoversData(type:string, period:string, isFull:boolean = false){
        if (isFull)
            return $api.get(`/stocks/movers?type=${type}&period=${period}&full `)
        else
            return $api.get(`/stocks/movers?type=${type}&period=${period}`)
    }

    static async deleteUser(){
        return $api.post('/user/delete')
    }

    static async sendInvites(phone:string, reCaptcha:string){
        return $api.post('/invites/send',
            {
                phone,
                reCaptcha
            }
        )
    }

    static async sendInviteWithoutToken(phone:string){
        return $api.post('/invites/send',
            {
                phone
            }
        )
    }

    static async cancelInvates(inviteId:number){
        return $api.post('/invites/cancel',
            {
                inviteId
            }
        )
    }

    static async copyEvents(inviteId:number){
        return $api.post('/invites/copy',
            {
                inviteId
            }
        )
    }
    static async getInvites(){
        return $api.get('/invites')
    }
    static async deleteEvents(eventId:string){
       return $api.post('/events/delete', {eventId} )
    }

    static async getFavourites() {
        const FavouritesResponse = await  $api.get<any>('/favourites')
        const stocks = FavouritesResponse?.data?.stocks
        return stocks
    }

    static async removeFavourite(ticker:string){
        const response = await $api.post('/favourites/remove', {ticker} )
        return response
    }

    static async addFavourite(ticker:string){
        const response = await $api.post('/favourites/add',{ticker} )
       return response
    }

    static async makeQuery(query:string):Promise<AxiosResponse<any>>{
        return $api.get(`stocks?query=${query}`)
    }

    static async makeQueryWithGroup(group:GroupEnum | '', query:string | ''){
        return $api.get(`/stocks?group=${group}&query=${query}`)
    }

    static async checkInvite(inviteCode:string){
        return $api.post('/user/check-invite',{inviteCode} )
    }
    static async getCompanyInformation(ticker:string){
        return $api.get(`/stocks/view?ticker=${ticker}`)
    }
    static async getCompanyDescription(ticker:string){
        return $api.get(`/stocks/company?ticker=${ticker}`)
    }
    static async getEvents(trendsElementIndex:number){
        return $api.get(`/events/trends`,{ params: { filter:trendsElementIndex} })
    }
    static async getAllEvents(filter: Array<IListStruct>){
        return $api.get(`/events`,{ params: { filter:0,types:getListTypes(filter)} })
    }
    static  async getFavouritesEvent(filter: Array<IListStruct>){
        //d
        return $api.get(`/events`,{ params: { filter:1,types:getListTypes(filter)} })
    }
    static  async getMoreEventsAll(filter: Array<IListStruct>, lastId:number){
        return $api.get(`/events`,{ params: { filter:0,types:getListTypes(filter),lastId:lastId } })
    }
    static  async getMoreFavouritesEvent(filter: Array<IListStruct>, lastId:number){
        return $api.get(`/events`,{ params: { filter:1,types:getListTypes(filter),lastId:lastId } })
    }

    static  async getTikerEventsMore(filter: Array<IListStruct>, ticker:string, lastId:number){
        return $api.get(`/events`,{ params: { filter:0, types:getListTypes(filter), ticker:ticker, lastId:lastId } })
    }

    static  async getTikerEvents(filter: Array<IListStruct>, ticker:string){
        return $api.get(`/events`,{ params: { filter:0, types:getListTypes(filter), ticker:ticker } })
    }


    static async getMessageData(eventId:number){
        return $api.get(`/events/read-news`,{ params: {eventId:eventId} })
    }


    static  async saveProfile(profileObj:Partial<IProfileTranslateObj>){
        const obj = {
            username:profileObj.username,
            fullName:profileObj.fullName,
            investmentApp:profileObj.investmentApp,
            about:profileObj.about,
            timezone:profileObj.timezone,
            translateEvents:profileObj.translateEvents,
            notificationsMode:profileObj.notificationsMode,
        }

        return $api.post('/user/save',profileObj)
    }

    static async  SaveProfileFormDate(){
        // return $api.
    }


    static async getNotification(){
        return $api.get(`/user/notifications`)
    }

    static async saveNotification(obj:any){
        return $api.post(`/user/save-notification`,{...obj})
    }


    static  async getTikerEventsPressReleases(ticker:string){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.PRESS_RELEASES], ticker:ticker } })
    }

    static  async getTikerEventsReport(ticker:string){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.REPORTING], ticker:ticker } })
    }

    static  async getTikerEventAnalitic(ticker:string){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.TARGET_PRICE, FilterConstant.ANALYST_FORECASTS], ticker:ticker } })
    }

    static  async getTikerEventsPressReleasesMore(ticker:string,lastId:number){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.PRESS_RELEASES], ticker:ticker,lastId:lastId } })
    }

    static  async getTikerEventsReportMore(ticker:string,lastId:number){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.REPORTING], ticker:ticker,lastId:lastId } })
    }

    static  async getTikerEventAnaliticMore(ticker:string,lastId:number){
        return $api.get(`/events`,{ params: { filter:0, types:[FilterConstant.TARGET_PRICE, FilterConstant.ANALYST_FORECASTS], ticker:ticker,lastId:lastId } })
    }

    static async saveMutedTicker(obj:any) {
        $api.post(`stocks/change-notification-status`,{...obj})
    }

    static async getMuted(){
        return $api.get(`/stocks/muted`)
    }

    static async cancelAutoPayment(): Promise<AxiosResponse<any>> {
        return $api.post('/subscription/cancel-auto-payment')
    }
    
    static async disconnectTelegram(){
        return $api.post(`/user/disconnect-telegram`)
    }

    static async getTelegramKey(){
        return $api.get(`/user/telegram-auth-key`)
    }

    static  async getLastEventsMore(filter: Array<IListStruct>, ticker:string, lastId:number){
        return $api.get(`/events`,{ params: { filter:0, types:getListTypes(filter), exceptTypes:[4], ticker:ticker, lastId:lastId } })
    }

    static  async getLastEvents(filter: Array<IListStruct>, ticker:string){
        return $api.get(`/events`,{ params: { filter:0, types:getListTypes(filter), exceptTypes:[4], ticker:ticker } })
    }

    static async getImportantEvent(ticker:string){
        return $api.get(`/events/important`, {params:{ticker:ticker} })
    }

}
