import React from "react";
import PressReleasesBody from "./PressReleasesBody";

function PressReleasesWidget() {
    return (
        <div>
            <div className="card card-custom p-4 mb-8">
                <PressReleasesBody/>
            </div>
        </div>
    )
}

export default React.memo(PressReleasesWidget)