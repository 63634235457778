import { t } from "i18next"
import React, {useEffect, useRef, useState} from "react"
import '../../styles/TimeLineMore.css'

interface ITimeLineMore{
    count:number
    data:any
}

function MoreEvent({count,data}:ITimeLineMore){
    const accordion = useRef<any>()
    const [isOpen,setIsOpen] = useState<boolean>(false)
    const timeLineElements = data.map((val:any) => val )

    const handlerClick = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.stopPropagation()
        e.preventDefault()
        setIsOpen(!isOpen)
    }

    const calculateDiff = () => {
            let diff = document.querySelector('.fas.fa-chevron-circle-down.text-danger')
            if(!diff)
                diff = document.querySelector('.fas.fa-chevron-circle-up.text-success')
            if(!diff) return 0

            return diff!.parentElement!.parentElement!.getBoundingClientRect()!.height  + 16 + 13
    }
    useEffect(() => {
        //@ts-ignore
        const diff = calculateDiff()
        let timeLabelDiff = document.querySelector('.text-primary.font-weight-bolder.text-nowrap')?.getBoundingClientRect().height 
        if(timeLabelDiff){
            timeLabelDiff += 14
        }
        const timeArray = accordion.current.querySelectorAll('.text-primary.font-weight-bolder.text-nowrap')
        
        let diffTime = 0
        if(timeArray[0]) {
            diffTime = timeArray[0].getBoundingClientRect().height + 13 + 15
        }
        if(isOpen){
            accordion.current.style.height = `${( (count) * diff ) + (timeArray.length * diffTime)+ (timeLabelDiff || 19.5  * count)}px`
        }
        else{
            accordion.current.style.height = '0'
        }
    },[isOpen, count])

    return(
        <>
            <div  style = {{display:'flex',justifyContent:'center'}} className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">
                <div className="timeline-label font-weight-bolder text-muted font-size-lg"></div>
                <div className="timeline-badge py-2"></div>
                
                <div className="font-size-md timeline-content pl-3">

                    <div className="">
                        <a onClick = {handlerClick} className="js__toggle-hidden-events font-weight-bolder font-size-sm" href="#">
                            {t('EVENTS_SHOW_MORE',{count:count})}

                            {
                                isOpen
                                    ?<i className="fas fa-chevron-down fa-1x ml-1 text-primary"></i>
                                    :<i className="fas fa-1x ml-1 text-primary fa-chevron-up"></i>
                            }

                        </a>
                    </div>
                </div>
            </div>

            <div ref = {accordion} className={'timeLineContainer'} >
                {timeLineElements}
            </div>

        </>
    )
}

export default MoreEvent