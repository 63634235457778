import React, { useEffect, useRef } from "react";
import { UseActions } from "../../hooks/useActions";
import useCompanyCard from "../../hooks/useCompanyCard";
import useFormatCurrency from "../../hooks/useFormatCurrency";
import {useTransition} from "../../hooks/useTransition";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import {ORIGIN} from "../../models/Domen";
import { DeviseIsApplicationOnly, getPercent } from "../../Utills/ClearFunctions";
import CompanyInformationImage from "../Image/CompanyInformationImage"
export interface ICompanyInformationCard {
    ticker: string,
    name: string,
    imgSrc:string,
    price?:string,
    oldPrice?:string,
    currency?:string,
    brand?:string,
    isNotShowPriceDynamics?:boolean
}
export const outputPercent = (percent:number | boolean) => {
    return `(${percent.toLocaleString()}%)`
}

export const outputSpecialPercent = (percent:number | boolean) => {
    if(percent >= 1 || percent <= -1) return `(${percent.toLocaleString()}%)`
}

function CompanyInformationCard(props :any) {
    const { 
        handleTransitionToCompanyTicker,
        getPrice
    } = useCompanyCard()

    const clickToStocks = (e:React.MouseEvent<HTMLAnchorElement> |   React.MouseEvent<HTMLDivElement> ) => {
        e.preventDefault()
        handleTransitionToCompanyTicker(props)
    }
    const linkRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    useEffect(() => {
        if(DeviseIsApplicationOnly()){
            linkRef.current.classList.remove('text-hover-primary')
        }
    },[])

    const getPercentForJsx = () => {
        const [percent,isUp] = getPercent(props.currentPrice, props.price)
        if(isUp) return  <div><span className="font-weight-bolder pr-2">{ getPrice(Number(props.price),props.currency) }  </span>→<br/><span className="text-success font-weight-bolder pr-2">{ getPrice(Number(props.currentPrice),props.currency) } {outputSpecialPercent(percent)}</span></div>
        else return <div><span className="font-weight-bolder pr-2">{ getPrice(Number(props.price),props.currency) }  </span>→<br/><span className="text-danger font-weight-bolder pr-2">{ getPrice(Number(props.currentPrice),props.currency) } {outputSpecialPercent(percent)}</span></div>
    }

    return (
        <div onClick={clickToStocks} className="card card-custom bg-white p-2 mt-2">
            <div className="d-flex align-items-center" onClick={ e => e.stopPropagation() }>
                
                
                <a href = {`${ORIGIN}/${props.ticker}`} onClick =  {clickToStocks}  className="mr-3">
                    <div className="symbol symbol-20 symbol-lg-20 symbol-circle">
                         <CompanyInformationImage src = {props.imgSrc}/>
                    </div>
                </a>

                <div className="d-flex flex-column flex-grow-1">
                    <a href={`${ORIGIN}/stocks/${props.ticker}`}
                       onClick={clickToStocks}
                       ref = {linkRef}
                       className="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder">
                        {props.name}<br/>
                        <span className="text-muted">${props.ticker}</span>
                    </a>
                </div>
                {
                    (props.price) &&
                     <div>
                         
                         {
                            props.currentPrice && !props.isNotShowPriceDynamics ? <> {getPercentForJsx()} </>
                            :  <span className="font-weight-bolder pr-2"> { getPrice(Number(props.price),props.currency) }</span>
                         }
                         
                    </div>
                }
            </div>
        </div>
    )
}

export default React.memo(CompanyInformationCard)