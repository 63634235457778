import {FilterConstant} from "../models/FilterEvent";
import Fluctuation from "../components/Cards/Fluctuation";
import MinMaxCardEvent from "../components/Cards/MinMaxCardEvent";
import TwitterEvent from "../components/Cards/TwitterEvent";
import SingleInformationEventCard from "../components/Cards/SingleInformationEventCard";
import ThirtyEvents from "../components/Cards/ThirtyEvents";
import Publication from "../components/Cards/Publication";
import ReportCard from "../components/Cards/ReportCard";
import StopEvent from "../components/Cards/StopEvent";
import NewLinkCard from "../components/Cards/NewLinkCard";
import FAAEvent from "../components/Cards/FAAEvent";

function useGetEvents(type: number | undefined, data?: any) {
    switch (type) {
        case FilterConstant.NEW_LINK:
            return (<NewLinkCard {...data}/>)
        case FilterConstant.STOP_EVENT:
            return (<StopEvent {...data}/>)
        case FilterConstant.PRICE_FLUCTUATIONS:
            return (<Fluctuation {...data}/>)
        case FilterConstant.MINUTES_EVENT_SUMMARY:
            return(<ThirtyEvents {...data}/>)
        case FilterConstant.YEARLY_MINIMUM_MAXIMUM:
            return (<MinMaxCardEvent {...data}/>)
        case FilterConstant.PRESS_RELEASES:
            return (<ReportCard {...data}/>)
        case FilterConstant.TWEETS_OF_POPULAR_PEOPLE:
            return (<TwitterEvent {...data}/>)
        case FilterConstant.MEDIA_PUBLICATIONS:
            return <Publication {...data}/>
        case FilterConstant.FAA:
            return <FAAEvent {...data}/>
        case FilterConstant.FINANCIAL_TWEETS:
        case FilterConstant.FDA:
        case FilterConstant.ABSORPTION:
        case FilterConstant.REPORTING:
        case FilterConstant.SEC:
        case FilterConstant.INSIDER_CLOSES:
        case FilterConstant.ANALYST_FORECASTS:
        case FilterConstant.TARGET_PRICE:
        case FilterConstant.MARKET_DATA:
            return <SingleInformationEventCard {...data}/>
        case FilterConstant.ALL_EVENTS:
        case FilterConstant.DELETE_ALL:

        default:
            return []

    }
}

export default useGetEvents