import { FC } from "react";
import { useTranslation } from "react-i18next";

const LiteAndroidButton:FC = () => {
    const {t} =useTranslation()

    return<>
        <div className="row justify-content-md-center mb-9">
            <div className="col-lg-6 col-12 text-center">
                <div className="form-group mt-4 alert alert-secondary font-size-h6 font-weight-bolder text-dark-50">
                    <b>
                    {t('SUBSCRIPTION_PLANS_LITE_ANDROID')}
                    </b>
                </div>
            </div>
        </div>
    </>
}

export default LiteAndroidButton