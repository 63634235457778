export const PaintRoundRectangle = (ctx:any,x:number,y:number,w:number,h:number,r:number,color:string) => {

    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;

    if (w < 2 * r) r = w / 2;
    if (h < 2 * r) r = h / 2;

    ctx.fillStyle = color
    ctx.beginPath();
    ctx.moveTo(x+r, y);
    ctx.arcTo(x+w, y,   x+w, y+h, r);
    ctx.arcTo(x+w, y+h, x,   y+h, r);
    ctx.arcTo(x,   y+h, x,   y,   r);
    ctx.arcTo(x,   y,   x+w, y,   r);

    ctx.closePath();
    ctx.fill()
}

export const paintText = (ctx:any,x:number, y:number, text:string, font:string, color:string) => {

    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;


    ctx.font = font
    ctx.fillStyle = color
    ctx.textBaseline = 'middle'
    ctx.textAlign = 'left'
    ctx.fillText(text, x, y)
}