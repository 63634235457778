import React, {ChangeEvent, useEffect, useRef, useState} from 'react';
import Header from "../components/Layout/Header";
import '../styles/WaitingPage.css'
import {useTransition} from "../hooks/useTransition";
import Footer from '../components/Layout/Footer';
import {UseActions} from "../hooks/useActions";
import {useTypedSelector} from "../hooks/useTypedSelector";
import {checkForCIS, deleteLoginStyle, DeviceIsMobile} from "../Utills/ClearFunctions";
import {useLocation} from "react-router-dom";
import {CustomErrorInput} from "../components/Errors";
import { useTranslation } from 'react-i18next';

function Waiting() {
    const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement)
    const [isOpen,setIsOpen] = useState<boolean>(false)
    const [localCdhecPoolLoader,setLocalCdhecPoolLoader] = useState<boolean>(false)
    const {checkAuth,sendInviteCode} = UseActions()
    const {transitionToWaiting} = useTransition()
    const location = useLocation()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)

    const Loading = useTypedSelector(state => state.UserReducer.Loading)
    const Error = useTypedSelector(state => state.UserReducer.Error)
    const User = useTypedSelector(state => state.UserReducer.User)

    useEffect(() => {
        inputRef.current.focus()
    },[])

    useEffect(() => {
        if(!location.pathname.includes('waiting') )
            transitionToWaiting()
        deleteLoginStyle()
    }, [])

    const sendForm = (e: ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        sendInviteCode(inputRef.current.value)
    }

    const buttonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        sendInviteCode(inputRef.current.value)
    }
    const [WaitingPageDropDown,setWaitingPageDropDown] = useState<boolean>(false)

    const closeAllMenu = () => {
        setWaitingPageDropDown(false)
        setIsOpen(false)
    }

    const checkPool = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        setLocalCdhecPoolLoader(true)
        e.preventDefault()
        await checkAuth()
        setLocalCdhecPoolLoader(false)
    }
    const {t} = useTranslation()
    const clickToTelegram = (e:React.MouseEvent<HTMLAnchorElement>) => {
        //@ts-ignore
        const region = window._sharedData?.config?.locale?.region
        if(!checkForCIS(region)){
            e.preventDefault()
            inputRef.current.value = 'TRADE'
        }   
    }

    return (
        <div style ={{width:'100vw', height:'100vh',}} onClick={() => {
                closeAllMenu()
            }} >
            <div id="kt_header" className="header header-fixed border-bottomm h-auto"  onClick={() => {
                closeAllMenu()
            }} >
                <Header
                WaitingPageDropDown = {WaitingPageDropDown}
                setWaitingPage = {setWaitingPageDropDown}
                isOpenSideMenu={isOpen}
                setIsOpenSideMenu={setIsOpen}
                searchIsOpen={false}
                setSearchIsOpen={() => {}}
                profileIsOpen={false}
                setProfileIsOpen={() => {}}
                />

            </div>


            <div className='container align-items-center justify-content-between waitingContainer'
            onClick={() => {
                closeAllMenu()
            }} 
                 style={{maxWidth: '500px'}}>


                <form className="form fv-plugins-bootstrap fv-plugins-framework" onSubmit={sendForm}>

                    <div className="pb-8 pt-2">
                        <div className="display-2 mb-4">🙋‍</div>
                        <h3 className="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">
                            {t('WIZARD_INVITE_HEADER')}
                        </h3>
                            {t('WIZARD_INVITE_TEXT')}
                    </div>


                    <div className="form-group fv-plugins-icon-container">

                        <div className="d-flex mt-n5">
                            <label className="font-size-h6 font-weight-bolder text-dark pt-5">{t('WIZARD_INVITE_PROMOCODE')}</label>
                        </div>

                        <input
                            ref={inputRef}
                            className="form-control form-control-solid h-auto p-4 rounded-lg input-sms"
                            type="text"
                            placeholder="XXXXXX"
                            name="text"
                            autoComplete="off"
                        />
                        {(Error !== '') && <CustomErrorInput text={Error}/> }
                    </div>

                    <button onClick={buttonClick} type="button" id="kt_login_signin_submit"
                            className= {(Loading && !localCdhecPoolLoader)?"btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6 spinner spinner-white spinner-right":"btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6"}>
                            {t('WIZARD_INVITE_APPLY')}
                    </button>

                    <hr/>

                    <div className="pb-lg-0 pb-5">
                        <h4 className="mb-4">{t('WIZARD_INVITE_NO_PROMOCODE')}</h4>
                        
                        <p>{t('WIZARD_INVITE_QUEUE_ADDED')} 
                            <span className="text-primary font-weight-bolder"> {t('WIZARD_INVITE_QUEUE_PEOPLE',{count:User.queuePosition})}</span>. {t('WIZARD_INVITE_QUEUE_NOTIFY')}
                        </p>
                            <a href="#" onClick={checkPool} className="btn btn-light-primary btn-sm btn-block font-size-h6 px-8 py-3 my-3 mr-3 d-flex align-items-center justify-content-center">
                                <i className={localCdhecPoolLoader?"fas fa-sync-alt fa-1x pr-0 fa-spin":"fas fa-sync-alt fa-1x pr-0"}></i> 
                                <span className="ml-2">{t('WIZARD_INVITE_QUEUE_CHECK')} </span>
                            </a>
                    </div>

                    <div className="d-flex align-items-center">
                        <i className="fab fa-telegram fa-2x text-primary mr-3" aria-hidden="true"></i>
                        <a onClick={clickToTelegram} target="_blank" href="https://t.me/tradesense_support"> {t('WIZARD_INVITE_QUEUE_TIERD')}</a>
                    </div>    
                </form>       
            </div>
            <div className='mt-5'>
                { !(DeviceIsMobile(userAgent))  &&  <Footer/> }
            </div>
        </div>
    );
}

export default Waiting;