import {AppDispatch} from '../..'
import {allEventEnum, ISetFullEvent, ISetNumberNewEvent, ISetPeriod, ISetPrevEventFilter, ISetTrends} from './types'
import {IPeriod} from "../UserEvent/types";

export const TrendsActionCreators = {
    setTrendPeriod: (period:IPeriod) : ISetPeriod => {
        return {
            type:allEventEnum.SET_TREND_PERIOD,
            payload:period
        }
    },
    setPrevEventTrends:(TrendsElements:Array<any>):ISetPrevEventFilter => {
        return{
            type:allEventEnum.SET_PREV_EVENT_FILTER,
            payload:TrendsElements
        }
    },
    setTrendsEventsMutation:(TrendsElements:any):ISetTrends => {
        return{
            type:allEventEnum.SET_TREND_ALL_EVENTS,
            payload:TrendsElements
        }
    },
    setTrendsNumberNewEvent:(countNewEvents:number):ISetNumberNewEvent => {
        return{
            type:allEventEnum.SET_TREND_NUMBER_NEW_EVENT,
            payload:countNewEvents
        }
    },
    setTrendsNewEvent:() => {
        return {
            type:allEventEnum.SET_TREND_NEW_ELEMENTS
        }
    },
    setFullTrendsEvents:(TrendsElements:any):ISetFullEvent => {
        return {
            type:allEventEnum.SET_TREND_FULL_EVENTS,
            payload:TrendsElements,
        }
    },
    updateNewState:() => async (dispath:AppDispatch) => {
        
    } 

    
}