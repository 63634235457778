import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {ErrorInput} from "../Errors";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {UseActions} from "../../hooks/useActions";
import {RedAlert} from "../Alerts";

interface ICodeInput {
    CodeValue: string
    changeCodeValue: (value: string) => void
    isValidCodeInput:boolean
    setIsValidCodeInput:(flag:boolean) => void
}

function CodeInput({changeCodeValue, CodeValue,isValidCodeInput,setIsValidCodeInput}: ICodeInput) {
    const inputRef = useRef<HTMLInputElement>({} as HTMLInputElement)
    type classValidType = 'is-valid' | 'is-invalid' | ''
    const [classValid, setClassValid] = useState<classValidType>('')
    const [isValid, setIsValid] = useState<boolean>(true)
    const [notValidCode,setNotValidCode] = useState<boolean>(false)
    const {setError} = UseActions()

    const handlerInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        changeCodeValue(e.target.value)
    }
    const firstRender = useRef<boolean>(true)

    const Error = useTypedSelector(state => state.UserReducer.Error)
    useEffect(() => {
        inputRef.current.focus()
        let flag = true

        if(isValidCodeInput){
            setIsValidCodeInput(false)
            setClassValid('')
            setError('')
            return
        }

        if(firstRender.current === true) {
            firstRender.current = false
            setClassValid('')
            return
        }

        if(Error !== ''){
            setNotValidCode(true)
            changeCodeValue('')
            setClassValid('is-invalid')
            return
        }

        if (CodeValue.length === 6 && Error ==='') {
            setClassValid('is-valid')
            setIsValid(true)
        } else {
            if(Error ===''){
                setClassValid('')
                setIsValid(true)
                return
            }
            setClassValid('is-invalid')
            setIsValid(false)
        }
    }, [Error,isValidCodeInput])

    const onblur = () => {
        if (CodeValue.length === 6 && Error ==='') {
            setClassValid('is-valid')
            setIsValid(true)
        } else {
            setClassValid('is-invalid')
            setIsValid(false)
        }
    }

    const errorHandling = () => {
        if(Error !=='') {
            return <ErrorInput text={Error}/>
        }
    }

    const removeRedAlert = () => {
        setNotValidCode(false)
    }

    return (
        <>
            <input
                value={CodeValue}
                onBlur={onblur}
                type="text" id="verification-code-input"
                onChange={handlerInputChange}
                ref={inputRef}
                className={"form-control form-control-solid h-auto p-4 text-center font-size-h1 sms-input " + classValid}
                name="code"
                maxLength={6}
                placeholder="______"
                autoComplete="off"
                aria-required="true"
                aria-invalid="true"
            />

            {errorHandling()}

        </>
    )
}

export default CodeInput