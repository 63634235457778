import Publication from "../components/Cards/Publication";
import ReportCard from "../components/Cards/ReportCard";
import StopEvent from "../components/Cards/StopEvent";
import { FilterConstant } from "../models/FilterEvent";
import Fluctuation from "../components/Cards/Fluctuation";
import ThirtyEvents from "../components/Cards/ThirtyEvents";
import MinMaxCardEvent from "../components/Cards/MinMaxCardEvent";
import TwitterEvent from "../components/Cards/TwitterEvent";
import SingleInformationEventCard from "../components/Cards/SingleInformationEventCard";
import { getPercent, nFormatter } from "../Utills/ClearFunctions";
import React from "react";
import EventTime from "../components/Time/EventTime";
import Event from "../components/Cards/Event";
import CommentWrappper from "../components/Comments/CommentWrapper";
import { ThirtyEventsTypes } from "../models/EventContentModel";
import NewLinkCard from "../components/Cards/NewLinkCard";
import FAAEvent from "../components/Cards/FAAEvent";
import i18next, { t } from "i18next";

//need to be refactored
function EventSwitch(
  type: number | undefined,
  networkData: any,
  priceInfo: any,
  stock: any,
  time: string,
  currentDay: number,
  prevDay: number,
  nameOfMounth: string,
  optionalDataValue: string | null,
  fullDate: string,
  key: string,
  canEditEvents: boolean,
  setDeleteElement?: any,
  countComments?: number,
  shortDateTime?: string | number | null,
  mediumTime?: string | number | null,
  getMediumDateabdShortTime?: (timeStamp: number) => {
    shortDateTime: string | number | null;
    mediumTime: string | number | null;
  },
  fullDateTootip?: string,
  createdAtEvent?: number,
  createdAt?: number,
  explanation?: null | { text: string }
  // priceInfo?:any
) {
  const CardInformationObj = {
    ticker: stock.ticker,
    name: stock.brand,
    imgSrc: stock.logo,
    ...stock,
    ...priceInfo, //fix
  };
  let data: any;

  const handleSingleInformationData = (data: {
    header: string;
    classText: string;
  }) => {
    const propsData = {
      ...data,
      text: networkData.text,
      time: time,
      sourceLink: networkData.url,
      sourceName: networkData.username,
      isCommentOpen: true,
      translatedText: networkData.translatedText,
      CardInformation: { ...CardInformationObj },
      id: key,
      countComments: countComments,
      mediumTime,
    };
    return insertDateComments(SingleInformationEventCard, time, propsData);
  };

  const insertDateComments = (Component: any, time: string, data: any) => {

    return (
      <div key={key}>
        <>
          <EventTime time={time} fullDateTootip={fullDateTootip} />
          <Event
            eventId={key}
            canEditEvents={canEditEvents}
            setDeleteElement={setDeleteElement}
          >
            {
              <Component
                Comment={
                  <CommentWrappper
                    countComments={countComments}
                    data={data}
                    isCommentOpen={true}
                    type={type}
                    id={key}
                  />
                }
                {...data}
              />
            }
          </Event>
        </>
      </div>
    );
  };

  const insertDate = (component: JSX.Element, time: string) => {
    return (
      <div key={key}>
        <>
          <EventTime time={time} fullDateTootip={fullDateTootip} />
          <Event
            eventId={key}
            canEditEvents={canEditEvents}
            setDeleteElement={setDeleteElement}
          >
            {component}
          </Event>
        </>
      </div>
    );
  };

  switch (type) {
    case FilterConstant.NEW_LINK:
      data = {
        text: networkData.translatedDescription,
        title: networkData.translatedTitle,
        translatedText: networkData.description,
        translatedTitle: networkData.title,
        id: key,
        CardInformation: { ...CardInformationObj },
        countComments: countComments,
        isCommentOpen: true,
        source: networkData.url,
        mediumTime,
      };
      return insertDateComments(NewLinkCard, time, data);
    case FilterConstant.STOP_EVENT:
      data = {
        from: networkData.from,
        to: networkData.to,
        isCommentOpen: true,
        id: key,
        CardInformation: { ...CardInformationObj },
        countComments: countComments,
        mediumTime,
        fromTimestamp: networkData.fromTimestamp,
        toTimestamp: networkData.toTimestamp,
      };
      return insertDateComments(StopEvent, time, data);
    case FilterConstant.PRICE_FLUCTUATIONS:
      const [percent, isUp] = getPercent(
        networkData.price,
        networkData.oldPrice
      );
      data = {
        ticker: stock.ticker,
        id: key,
        companyName: stock.brand,
        percent: (percent as number).toString(),
        fromPrice: networkData.oldPrice,
        time: time,
        toPrice: networkData.price,
        isUp: isUp as boolean,
        isCommentOpen: true,
        CardInformation: { ...CardInformationObj },
        countComments: countComments,
      };
      return insertDateComments(Fluctuation, time, data);
    case FilterConstant.MINUTES_EVENT_SUMMARY:
      data = [];
      if (networkData.finTweets.length !== 0) {
        let lastDay = "";
        data.push(
          ...networkData.finTweets.map((FinElement: any) => {
            //@ts-ignore
            const { mediumTime, shortDateTime } = getMediumDateabdShortTime(
              FinElement.createdAt
            );
            const currentDay = (mediumTime as string).split(".")[0];
            let rightDate = "";
            if (currentDay === lastDay) {
              rightDate = shortDateTime as string;
            } else {
              lastDay = rightDate;
              rightDate = mediumTime as string;
            }

            return {
              type: ThirtyEventsTypes.FINTWEETS,
              header: `${i18next.t("EVENT_30M_SUMMARY_FINTWEET")}`,
              classText: "fas fa-volume-up text-warning",
              text: FinElement.translatedText,
              source: "@" + FinElement.username,
              date: "(" + rightDate + ")",
              translatedText: FinElement.text,
              translatedHeader: "Translated header",
              sourceLink: FinElement.url,
              displayName: FinElement.displayName,
              shortDateTime,
            };
          })
        );
      }

      if (networkData.tweets.length !== 0) {
        let lastDay = "";
        data.push(
          ...networkData.tweets.map((TweetEvent: any) => {
            //@ts-ignore
            const { mediumTime, shortDateTime } = getMediumDateabdShortTime(
              TweetEvent.createdAt
            );
            const currentDay = (mediumTime as string).split(".")[0];
            let rightDate = "";
            if (currentDay === lastDay) {
              rightDate = shortDateTime as string;
            } else {
              lastDay = rightDate;
              rightDate = mediumTime as string;
            }
            return {
              type: ThirtyEventsTypes.TWEETS,
              header: `${i18next.t("EVENT_30M_SUMMARY_INFLUENCER_TWEET")}`,
              classText: "fab fa-twitter text-primary ",
              text: TweetEvent.translatedText,
              source: TweetEvent.displayName,
              date: "(" + rightDate + ")",
              subscribers: nFormatter(TweetEvent.followersCount),
              translatedText: TweetEvent.text,
              translatedHeader: "Translated header",
              sourceLink: TweetEvent.url,
              displayName: TweetEvent.displayName,
              shortDateTime,
            };
          })
        );
      }
      if (networkData.marketDataTweets.length !== 0) {
        let lastDay = "";
        data.push(
          ...networkData.marketDataTweets.map((marketDataEvent: any) => {
            //@ts-ignore
            const { mediumTime, shortDateTime } = getMediumDateabdShortTime(
              marketDataEvent.createdAt
            );
            const currentDay = (mediumTime as string).split(".")[0];
            let rightDate = "";
            if (currentDay === lastDay) {
              rightDate = shortDateTime as string;
            } else {
              lastDay = rightDate;
              rightDate = mediumTime as string;
            }
            return {
              type: ThirtyEventsTypes.MARKETDATATWEETS,
              header: `${i18next.t("EVENT_30M_SUMMARY_MARKET_DATA")}`,
              classText: "fas fa-search text-warning",
              text: marketDataEvent.translatedText,
              source: marketDataEvent.username,
              date: "(" + rightDate + ")",
              translatedText: marketDataEvent.text,
              translatedHeader: "Translated header",
              sourceLink: marketDataEvent.url,
              shortDateTime,
            };
          })
        );
      }
      if (networkData.medias.length !== 0) {
        let lastDay = "";
        data.push(
          ...networkData.medias.map((mediaEvent: any) => {
            //@ts-ignore
            const { mediumTime, shortDateTime } = getMediumDateabdShortTime(
              mediaEvent.publishedAt
            );
            const currentDay = (mediumTime as string).split(".")[0];
            let rightDate = "";

            if (currentDay === lastDay) {
              rightDate = shortDateTime as string;
            } else {
              lastDay = rightDate;
              rightDate = mediumTime as string;
            }
            //publishedAt
            return {
              type: ThirtyEventsTypes.MEDIAS,
              header: t("EVENT_30M_SUMMARY_MEDIA_MENTION"),
              classText: "far fa-newspaper text-success mr-2",
              text: mediaEvent.translatedTitle,
              source: mediaEvent.name,
              date: "(" + rightDate + ")",
              translatedText: mediaEvent.title,
              translatedHeader: "Translated header",
              sourceLink: mediaEvent.url,
              shortDateTime,
            };
          })
        );
      }
      return insertDate(
        <ThirtyEvents
          time={time as string}
          isCommentOpen={true}
          id={key}
          data={[...data]}
          CardInformation={{ ...CardInformationObj }}
          countComments={countComments}
        />,
        time
      );

    case FilterConstant.YEARLY_MINIMUM_MAXIMUM:
      let indicator: boolean = false;
      if (networkData.indicator === "high") indicator = true;
      else indicator = false;
      data = {
        price: networkData.price,
        time: time,
        isUp: indicator,
        isCommentOpen: true,
        id: key,
        CardInformation: { ...CardInformationObj },
        countComments: countComments,
        mediumTime,
      };

      return insertDateComments(MinMaxCardEvent, time, data);
    case FilterConstant.PRESS_RELEASES:
      data = {
        date: fullDate,
        time: time,
        title: networkData.title,
        text: networkData.description,
        source: networkData.url,
        isCommentOpen: true,
        CompanyName: stock.brand,
        dateFull: fullDate,
        sourceName: networkData.url.split("/")[2],
        translatedText: networkData.translatedDescription,
        translatedTitle: networkData.translatedTitle,
        CardInformation: { ...CardInformationObj },
        id: key,
        countComments: countComments,
        mediumTime,
        createdAtEvent,
        createdAt,
        explanation:explanation
      };
      return insertDateComments(ReportCard, time, data);
    case FilterConstant.TWEETS_OF_POPULAR_PEOPLE:
      data = {
        name: networkData.displayName,
        subscribers: nFormatter(networkData.followersCount),
        translatedText: networkData.translatedText,
        date: fullDate,
        time: time,
        sourceName: networkData.username,
        sourceLink: networkData.url,
        isCommentOpen: true,
        text: networkData.text,
        CardInformation: { ...CardInformationObj },
        id: key,
        countComments: countComments,
        mediumTime,
        createdAtEvent,
      };
      return insertDateComments(TwitterEvent, time, data);
    case FilterConstant.MEDIA_PUBLICATIONS:
      data = {
        time: time,
        fullDate: `(${fullDate})`,
        text: networkData.description,
        title: networkData.title,
        source: networkData.name,
        sourceLink: networkData.url,
        isCommentOpen: true,
        translatedText: networkData.translatedDescription,
        CardInformation: { ...CardInformationObj },
        translatedTitle: networkData.translatedTitle,
        id: key,
        countComments: countComments,
        mediumTime,
        createdAtEvent,
      };
      return insertDateComments(Publication, time, data);
    case FilterConstant.FINANCIAL_TWEETS:
      data = {
        header: i18next.t("EVENT_FINTWEET"),
        classText: "fas fa-volume-up text-warning",
      };
      return handleSingleInformationData(data);
    case FilterConstant.FDA:
      data = {
        header: i18next.t("EVENT_FDA"),
        classText: "fas fa-pills text-warning mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.FAA:
      data = {
        ...networkData,
        isCommentOpen: true,
        CardInformation: { ...CardInformationObj },
        id: key,
        countComments: countComments,
      };
      return insertDateComments(FAAEvent, time, data);
    case FilterConstant.ABSORPTION:
      data = {
        header: i18next.t("EVENT_ACQUISITION"),
        classText: "fas fa-bolt text-warning mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.REPORTING:
      data = {
        header: i18next.t("EVENT_REPORT"),
        classText: "far fa-money-bill-alt text-warning mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.SEC:
      data = {
        header: i18next.t("SEC"),
        classText: "fab fa-wpforms text-warning mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.INSIDER_CLOSES:
      data = {
        header: i18next.t("EVENT_INSIDER"),
        classText: "fas fa-handshake text-warning mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.ANALYST_FORECASTS:
      data = {
        header: i18next.t("EVENT_FORECAST"),
        classText: "fas fa-pen-alt text-warning",
      };
      return handleSingleInformationData(data);
    case FilterConstant.TARGET_PRICE:
      data = {
        header: i18next.t("EVENT_PRICE_TARGET"),
        classText: "far fa-dot-circle text-danger mr-2",
      };
      return handleSingleInformationData(data);
    case FilterConstant.MARKET_DATA:
      data = {
        header: i18next.t("EVENT_MARKET_DATA"),
        classText: "fas fa-search text-warning",
      };
      return handleSingleInformationData(data);
    case FilterConstant.ALL_EVENTS:
    case FilterConstant.DELETE_ALL:
    default:
      return [];
  }
}

export default EventSwitch;
