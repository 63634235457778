import { t } from "i18next";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from 'uuid';
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { isFreePlane } from "../../Utills/ClearFunctions";
import NotificationFreeText from "../Free/NotificationFreeText";

interface INotificationSwitches {
    notificationsMode: number,
    changeNotificationMode: (id: number) => void
}

function NotificationSwitches({ changeNotificationMode, notificationsMode }: INotificationSwitches) {
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)
    const User = useTypedSelector(state => state.UserReducer.User)

    interface ISwitches {
        id: number,
        header: string,
        text: string,
        checked: boolean,
        classNameHeader: string
        style: { borderLeft: string }
    }

    interface IFreeSwitches {
        header: string,
        text: string,
        checked: boolean,
        classNameHeader: string
        style: { borderLeft: string }
        headerStyle: string
    }

    const [Switches, setSwitches] = useState<Array<ISwitches>>([
        {
            id: 0,
            header: `${t('PROFILE_FAVORITES_ONLY')}`,
            text: `${ !User.isCrypto ? t('PROFILE_FAVORITES_ONLY_TEXT') : t('PROFILE_FAVORITES_CRYPTO_ONLY_TEXT') }`,
            checked: false,
            classNameHeader: 'text-success',
            style: { borderLeft: '3px solid #2ba637' },
        },
        {
            id: 1,
            header: `${t('PROFILE_FAV_AND_OTHERS')}`,
            text: `${t('PROFILE_FAV_AND_OTHERS_TEXT')}`,
            checked: false,
            classNameHeader: 'text-warning',
            style: { borderLeft: '3px solid #ffab19' },
        },
        {
            id: 2,
            header: `${t('PROFILE_ALL_STOCKS')}`,
            text: `${ !User.isCrypto ? t('PROFILE_ALL_STOCKS_TEXT') : t('PROFILE_ALL_CRYPTO_STOCKS_TEXT') }`,
            checked: false,
            classNameHeader: 'text-dark',
            style: { borderLeft: '3px solid #000000' },
        },
    ])
    const [FreeSwitches, setFreeSwitches] = useState<Array<ISwitches>>([
        {
            id: 0,
            header: t('PROFILE_FAVORITES_ONLY'),
            text: `${ !User.isCrypto ? t('PROFILE_FAVORITES_ONLY_TEXT') : t('PROFILE_FAVORITES_CRYPTO_ONLY_TEXT') }`,
            checked: true,
            classNameHeader: 'text-success',
            style: { borderLeft: '3px solid #2ba637' },
        },
        {
            id: 1,
            header: t('PROFILE_FAV_AND_OTHERS'),
            text: t('PROFILE_FAV_AND_OTHERS_TEXT'),
            checked: false,
            classNameHeader: 'text-muted',
            style: { borderLeft: '3px solid #ffab19' },
        },
        {
            id: 2,
            header: t('PROFILE_ALL_STOCKS'),
            text: `${ !User.isCrypto ? t('PROFILE_ALL_STOCKS_TEXT') : t('PROFILE_ALL_CRYPTO_STOCKS_TEXT') }`,
            checked: false,
            classNameHeader: 'text-muted',
            style: { borderLeft: '3px solid #000000' },
        },
    ])
    type LanguageSwitchesType = Omit<ISwitches, 'id' | 'checked' | 'classNameHeader' |  'style'> 
    useEffect(() => {
        const LanguageSwitchesMap = new Map<number, LanguageSwitchesType>([
            [0, {header:t('PROFILE_FAVORITES_ONLY'),text: `${ !User.isCrypto ? t('PROFILE_FAVORITES_ONLY_TEXT') : t('PROFILE_FAVORITES_CRYPTO_ONLY_TEXT') }`}],
            [1, {header:t('PROFILE_FAV_AND_OTHERS'),text: t('PROFILE_FAV_AND_OTHERS_TEXT')}],
            [2, {header:t('PROFILE_ALL_STOCKS'),text:`${ !User.isCrypto ? t('PROFILE_ALL_STOCKS_TEXT') : t('PROFILE_ALL_CRYPTO_STOCKS_TEXT') }`}],
          ]) 

        setFreeSwitches( FreeSwitches.map((Switches) => {
            const header = LanguageSwitchesMap.get(Switches.id)?.header as string
            const text = LanguageSwitchesMap.get(Switches.id)?.text as string
            return {...Switches,header:header,text:text}
        }).filter((Switches) => Switches.id !== 1 ||  !User.isCrypto ) )

        setSwitches( Switches.map((Switches) => {
            const header = LanguageSwitchesMap.get(Switches.id)?.header as string
            const text = LanguageSwitchesMap.get(Switches.id)?.text as string
            return {...Switches,header:header,text:text}
        }).filter((Switches) => Switches.id !== 1 ||  !User.isCrypto ) )

    },[User.settings.language])

    useEffect(() => {
        setSwitches(Switches.map((Switch) => {
            if (Switch.id === notificationsMode) return { ...Switch, checked: true }
            else return { ...Switch, checked: false }
        }))
    }, [notificationsMode])

    const replaceSwitch = (header: string) => {
        Switches.forEach((Switch) => {
            if (Switch.header === header)
                changeNotificationMode(Switch.id)
        })
    }

    const change = (header: string) => (e: any) => {
        replaceSwitch(header)
    }

    const getSwitches = () => {
        if (!isFreePlane(Plane, User?.subscriptionInfo?.finishedAt)) {
            return SwitchesElements
        }
        else return FreeSwitchesElements
    }

    const FreeSwitchesElements = FreeSwitches.map((Switch) => {

        return <div key={uuidv4()}
            style={{ cursor: "pointer" }}
            className="d-flex flex-center pb-6">
            <div className="d-flex flex-column flex-grow-1 pl-4"
                style={Switch.style}
            >
                <span
                    className={Switch.classNameHeader + " font-weight-bolder font-size-lg mb-1"}>{Switch.header}</span>
                <span className="text-muted font-weight-bold">{Switch.text} </span>

                {
                    Switch.id !== 0 && <NotificationFreeText />
                }

            </div>

            <div className="ml-4">
                <label className="radio">
                    <input type="radio" checked={Switch.checked} name="notify_radio" />
                    <span></span>
                </label>
            </div>
        </div>
    })

    const SwitchesElements = Switches.map((Switch) => {
        return <div key={uuidv4()}
            onClick={change(Switch.header)}
            style={{ cursor: "pointer" }}
            className="d-flex flex-center pb-6">
            <div className="d-flex flex-column flex-grow-1 pl-4"
                style={Switch.style}
            >
                <span
                    className={Switch.classNameHeader + " font-weight-bolder font-size-lg mb-1"}>{Switch.header}</span>
                <span className="text-muted font-weight-bold">{Switch.text} </span>
            </div>
            <div className="ml-4">
                <label className="radio">
                    <input type="radio" checked={Switch.checked} onChange={change(Switch.header)} name="notify_radio" />
                    <span></span>
                </label>
            </div>
        </div>
    })

    return (
        <div className="col-lg-6 col-xl-6">
            {
                getSwitches()
            }
        </div>
    )
}

export default React.memo(NotificationSwitches)