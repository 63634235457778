import React from "react";
import { useTranslation } from "react-i18next";



interface IContinueButton{
    handlerContinue:(e:React.MouseEvent<HTMLButtonElement>) => void
}


export const ContinueButton = React.memo(({handlerContinue}:IContinueButton) => {
    const {t} = useTranslation()

    return                                 <button
        type="button"
        onClick={handlerContinue}
        className="btn btn-primary font-weight-bolder font-size-h6 px-8 py-2 my-2 mr-3 fav_next_button favoritesButton"
    >
        {t('WIZARD_FAVORITES_NEXT')}
    </button>
})