import {actionType, IListStruct, SetFilterList, FilterListEnum, IFilters} from './types'
import {FilterConstant} from "../../../models/FilterEvent";
import i18next from 'i18next';
import { clearAllBodyScrollLocks } from 'body-scroll-lock';

const initialState: IFilters =
    {
        filters:[
            {
                text: i18next.t('FEED_FILTER_PRICE_MOVEMENT'),
                id: FilterConstant.PRICE_FLUCTUATIONS,
                check: true,
                classElement: 'fa fa-chart-line'
            },
            {
                text: ' Годовой минимум/максимум ',
                id: FilterConstant.YEARLY_MINIMUM_MAXIMUM,
                check: true,
                classElement: 'fas fa-exchange-alt fa-rotate-90 fa-fw mr-3'
            },
            {
                text: ' Пресс-релизы ',
                id: FilterConstant.PRESS_RELEASES,
                check: true,
                classElement: 'far fa-newspaper'
            },
            {
                text: ' Финансовые твиты ',
                id: FilterConstant.FINANCIAL_TWEETS,
                check: true,
                classElement: 'fas fa-volume-up'
            },
            {
                text: ' FDA ',
                id: FilterConstant.FDA,
                check: true,
                classElement: 'fas fa-pills'
            },
            {
                text: ' FAA  ',
                id: FilterConstant.FAA,
                check: true,
                classElement: 'fas fa-rocket'
            },
            {
                text: ' Поглощения ',
                id: FilterConstant.ABSORPTION,
                check: true,
                classElement: 'fas fa-bolt'
            },
            {
                text: ' SEC ',
                id: FilterConstant.SEC,
                check: true,
                classElement: 'fab fa-wpforms'
            },
            {
                text: ' Инсайдерские сделки ',
                id: FilterConstant.INSIDER_CLOSES,
                check: true,
                classElement: 'fas fa-handshake'
            },
            {
                text: ' Прогнозы аналитиков ',
                id: FilterConstant.ANALYST_FORECASTS,
                check: true,
                classElement: 'fas fa-pen-alt'
            },
            {
                text: ' Целевая цена ',
                id: FilterConstant.TARGET_PRICE,
                check: true,
                classElement: 'far fa-dot-circle'
            },
            {
                text: ' Рыночные данные ',
                id: FilterConstant.MARKET_DATA,
                check: true,
                classElement: 'fas fa-search'
            },
            {
                text: ' Твиты популярных людей ',
                id: FilterConstant.TWEETS_OF_POPULAR_PEOPLE,
                check: true,
                classElement: 'fab fa-twitter'
            },
            {
                text: ' Публикации в СМИ ',
                id: FilterConstant.MEDIA_PUBLICATIONS,
                check: true,
                classElement: 'far fa-newspaper'
            },
        {
            text: ' Очистить ',
            id: FilterConstant.DELETE_ALL,
            check: false,
            classElement: 'fa fa-times'
        }
        ]
}

export default function FilterReducer(state: IFilters = initialState, action: actionType): IFilters {
    switch (action.type) {
        case FilterListEnum.SET_FILTER_LIST:
            return {...state,filters: [...action.payload]}    
        case FilterListEnum.SET_ACTIVE_FILTER_LIST_ELEMENTS:{
            const filters = action.payload.map((ListElement,index) => {
                return {...state.filters[index],check:ListElement.check}
            })
            return {...state, filters:filters}
        }    
        default:
            return state
    }
}


