import { ShapeTemplate } from "klinecharts";

export function generateTemplateLine(): ShapeTemplate {
  return {
        name:'Line',
        totalStep: 3,
        checkEventCoordinateOnShape: ({ dataSource, eventCoordinate }: {dataSource:any, eventCoordinate:any} ) => {
          const xDis = Math.abs(dataSource.x - eventCoordinate.x)
          const yDis = Math.abs(dataSource.y - eventCoordinate.y)
          const r = Math.sqrt(xDis * xDis + yDis * yDis)
          return Math.abs(r - dataSource.radius) < 2;
        },
        createShapeDataSource: ({ step, points, coordinates }: {step:any,points:any,coordinates:any}) => {
            if (coordinates.length === 2) {
            const xDis = Math.abs(coordinates[0].x - coordinates[1].x)
            const yDis = Math.abs(coordinates[0].y - coordinates[1].y)
            const radius = 5
            return [
                {
                    type: 'line',
                    isDraw: true,
                    isCheck: true,
                    styles: { style: 'fill' },
                    dataSource: [
                      [{ x:coordinates[0].x,y:0 }, { x: coordinates[0].x, y: 370 }],
                    ]
                  },
            ]
          }
          return []
        }
      }
}