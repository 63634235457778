import {FilterConstant} from "../models/FilterEvent";
import TwitterEvent from "../components/Cards/TwitterEvent";
import ThirtyEvents from "../components/Cards/ThirtyEvents";
import Publication from "../components/Cards/Publication";
import TimeLineFluctuation from "../components/TimeLine/TimeLineFluctuation";
import TimeLineMore from "../components/TimeLine/TimeLineMore";
import TimeLineRelease from "../components/TimeLine/TimeLineRelease";
import TimeLineEarly from "../components/TimeLine/TimeLineEarly";
import TimeLineElement from "../components/TimeLine/TimeLineElement";
import TimeLineDay from "../components/Time/TimeLineTimeDay";
import CommentWrappper from "../components/Comments/CommentWrapper";
import i18next from "i18next";

export function trendSwitch(
        type: number | undefined,
        currentDay:number,
        prevDay:number,
        timeLineElement: any,
        date:string,
        nameOfMounth:string,
        dateFullPressRelize?:string,
        id?:number,
        countComments?:number,
        fullDateTootip?:string,
        explanation?: null | { text: string }
) {
    const timeLineData = timeLineElement.data
    const timeLineStock = timeLineElement.stock

    let data:any

    const cardInformaionObj = {
        ticker: timeLineStock.ticker,
        name: timeLineStock.brand,
        imgSrc: timeLineStock.logo,
        brand:timeLineStock.brand,
        ...timeLineStock.priceInfo,
    }

    const insertTimeLineElementDate = (data: {header:string,classText:string}) => {
        const dataProps:any= {
            ...data,
            text:timeLineData.text,
            time: date,
            id:id,
            sourceLink: timeLineData.url,
            sourceName: timeLineData.username,
            isCommentOpen: true,
            fullDateTootip:fullDateTootip,
            translatedText:timeLineData.translatedText,  
            CardInformation: {
               ...cardInformaionObj
            }
        }
        return insertDate(TimeLineElement, dataProps)
    }

    const insertDate = (Component:any, data:any) => {
        if(prevDay !== currentDay)
            return(
                <>
                    <TimeLineDay date={`${currentDay +' ' + nameOfMounth}`}/>
                    {
                        <Component 
                            Comment = {
                                    <CommentWrappper 
                                        countComments={countComments}
                                        data = {data}
                                        isCommentOpen = {true}
                                        type = {type}
                                        id = {String(id)}
                                    />
                                } 
                            {...data}
                        />
                    }
                </>
            )
        else
            return <> 
                <Component 
                    Comment = {
                            <CommentWrappper 
                                countComments={countComments}
                                data = {data}
                                isCommentOpen = {true}
                                type = {type}
                                id = {String(id)}
                            />
                        } 
                    {...data}
                />
            </>
    }

    switch (type) {
        case FilterConstant.PRICE_FLUCTUATIONS:
            data = {
                ticker:timeLineStock.ticker,
                companyName:timeLineStock.brand,
                fromPrice:timeLineData.oldPrice,
                toPrice:timeLineData.price,
                isUp:false,
                percent:'2',
                fullDateTootip:fullDateTootip,
                time:date,
                id:id,
                isCommentOpen:true,
                CardInformation: {
                   ...cardInformaionObj
                }

            }
                return insertDate(TimeLineFluctuation, data)
        case FilterConstant.TIMELINE_MORE:
            return (<TimeLineMore {...data} />)
        case FilterConstant.MINUTES_EVENT_SUMMARY:
            return(<ThirtyEvents {...data}/>)
        case FilterConstant.YEARLY_MINIMUM_MAXIMUM:
            return (<TimeLineEarly {...data}/>)
        case FilterConstant.PRESS_RELEASES:
            data = {
                sourceName: timeLineData.url.split('/')[2],
                CompanyName: timeLineStock.brand,
                time: date,
                dateFull: dateFullPressRelize,
                date: date,
                fullDateTootip:fullDateTootip,
                isCommentOpen: true,
                source: timeLineData.url,
                title:timeLineData.title,
                translatedTitle: timeLineData.translatedTitle,
                text:timeLineData.description,
                translatedText: timeLineData.translatedDescription,
                CardInformation: {
                   ...cardInformaionObj
                },
                id:id,
                explanation:explanation
            }
            return insertDate(TimeLineRelease, data)
        case FilterConstant.TWEETS_OF_POPULAR_PEOPLE:
            return (<TwitterEvent {...data}/>)
        case FilterConstant.MEDIA_PUBLICATIONS:
            return <Publication {...data}/>
        case FilterConstant.FINANCIAL_TWEETS:
            data = {
                classText: "fas fa-volume-up text-warning",
                header: i18next.t('EVENT_FINTWEET'),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.FDA:
            data = {
                classText: "fas fa-pills text-warning",
                header: i18next.t('EVENT_FDA'),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.FAA:
            data = {
                classText: "fas fa-rocket text-warning mr-2",
                header: i18next.t('EVENT_FAA'),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.ABSORPTION:
            data = {
                classText: "fas fa-bolt text-warning",
                header: i18next.t("EVENT_ACQUISITION"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.REPORTING:
            data = {
                classText: "far fa-money-bill-alt text-warning",
                header: i18next.t("EVENT_REPORT"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.SEC:
            data = {
                classText: "fab fa-wpforms text-warning",
                header:i18next.t("SEC"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.INSIDER_CLOSES:
            data = {
                classText: "fas fa-handshake text-warning",
                header:i18next.t("EVENT_INSIDER"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.ANALYST_FORECASTS:
            data = {
                classText: "fas fa-pen-alt text-warning",
                header:i18next.t("EVENT_FORECAST"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.TARGET_PRICE:
            data = {
                classText: "far fa-dot-circle text-danger",
                header:i18next.t("EVENT_PRICE_TARGET"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.MARKET_DATA:
            data = {
                classText: "fas fa-search text-warning",
                header:i18next.t("EVENT_MARKET_DATA"),
            }
            return insertTimeLineElementDate(data)
        case FilterConstant.ALL_EVENTS:
        case FilterConstant.DELETE_ALL:
        default:
            return []

    }
}

