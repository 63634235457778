import {IPeriod} from "../UserEvent/types";

export interface IAllEvent{
    numberNewEvent:number,
    Elements:Array<JSX.Element>
    NewElements:Array<JSX.Element>,
    prevFilter:Array<any>,
    period:IPeriod,
    loadingAllEvents:boolean,
}
export enum allEventEnum{
    SET_ALL_EVENTS = "SET_ALL_EVENTS",
    SET_NUMBER_NEW_EVENT = "SET_NUMBER_NEW_EVENT",
    SET_NEW_ELEMENTS = "SET_NEW_ELEMENTS",
    SET_FULL_EVENTS = "SET_FULL_EVENTS",
    SET_ALL_RPEV_FILTER = "SET_ALL_RPEV_FILTER",
    DELETE_EVENT = "DELETE_EVENT",
    SET_ALL_PERIOD = "SET_ALL_PERIOD",
    SET_LOADING_ALL_EVENTS = "SET_LOADING_ALL_EVENTS",
}
export interface ISetLoadingALLEvents{
    type:allEventEnum.SET_LOADING_ALL_EVENTS,
    payload:boolean,
}
export interface ISetPeriod{
    type:allEventEnum.SET_ALL_PERIOD,
    payload:IPeriod
}
export interface IDeleteEvent {
    type:allEventEnum.DELETE_EVENT,
    payload:string
}

export interface ISetPrevFilter{
    type:allEventEnum.SET_ALL_RPEV_FILTER
    payload:Array<any>
}
export interface ISetFullEvent{
    type: allEventEnum.SET_FULL_EVENTS
    payload: Array<JSX.Element>
}

export interface  ISetTrends{
    type: allEventEnum.SET_ALL_EVENTS,
    payload: Array<JSX.Element>
}

export interface ISetNumberNewEvent{
    type: allEventEnum.SET_NUMBER_NEW_EVENT,
    payload: number,
}

export interface ISetNewEvent{
    type:allEventEnum.SET_NEW_ELEMENTS,
    
}
export type actionType = ISetLoadingALLEvents | ISetTrends | ISetPeriod | IDeleteEvent | ISetPrevFilter | ISetNumberNewEvent | ISetNewEvent | ISetFullEvent