import { FC } from "react";
import UserMainInfo from "./UserMainInfo";

const UserMainInfoWrapper:FC = () => {
    return <>
        <div className="row">
            <div className="card card-custom col-12 p-6">
                <UserMainInfo/>
            </div>
        </div> 
    </>
}
export default UserMainInfoWrapper