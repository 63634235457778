import React, {useEffect, useState} from "react";
import MutedSideBar from "../Descriptions/MutedSideBar";
import {ILabel} from "../../models/EventContentModel";
import UserService from "../../Services/UserService";
import {useTransition} from "../../hooks/useTransition";
import {UseActions} from "../../hooks/useActions";



interface IMutedStockCard{
    brand: string,
    ticker: string,
    isOpenSideMenu: boolean,
    logo: '',
    notificationStatus: 0 | 1 | 2,
    price:number,
    clickMutedHandler:(ticker:string) => (e:React.MouseEvent<HTMLAnchorElement>) => void,
}

function MutedStockCard({isOpenSideMenu,brand,logo,notificationStatus,clickMutedHandler,ticker,price}:IMutedStockCard) {
    const {transitionToTicket,transitionToTicketTest, transitionToTicketApplication} = useTransition()
    const {setCurrentCompanyEnum} = UseActions()

    const [labelSate, setLabelSate] = useState<Array<ILabel>>([
        {
            name: 'power',
            active: false,
            key: 0,
        },

        {
            name: 'muted',
            active: false,
            key: 1,
        },

        {
            name: 'allTimeMuted',
            active: false,
            key: 2,
        }

    ])

    useEffect(() => {

        const newState = labelSate.map((Label) => {
            if (Label.key === notificationStatus) {
                return {...Label, active: true}
            } else return {...Label, active: false}
        })
        setLabelSate(newState)
    }, [brand])


    const changeActive = (name: string) => {

        setLabelSate(labelSate.map((value) => {
            if (value.name === name) {
                UserService.saveMutedTicker({
                    ticker: ticker,
                    status: value.key
                })
                return {...value, active: true}
            } else {
                return {...value, active: false}
            }
        }))
    }

    const checkForMute = () => {
        for (let i = 1; i < labelSate.length; i++) {
            if (labelSate[i].active) return true
        }
        return false
    }


    const closeDropDown = () => {
        clickMutedHandler(ticker)
    }


    const [isOpenMutedMenu, setIsOpenMutedMenu] = useState<boolean>(false)

    return (
        <>
            <div className="d-flex align-items-center mb-7 all_tickers ticker_aapl company_apple">
                <a href=""
                   onClick={(e) => {
                       setCurrentCompanyEnum(
                           {
                               cardName:brand,
                               ticket:ticker,
                               src:logo,
                               //@ts-ignore
                               price:Number(price),
                               percent:2,
                           }
                       )
                       e.preventDefault()
                       transitionToTicketTest(ticker)
                   }}
                   className="mr-3 mr-lg-4">
                    <div className="symbol symbol-30 symbol-lg-30 symbol-circle mr-3 mr-lg-4">
                        <img alt="logo" src={logo}/>
                    </div>
                </a>
                <div className="d-flex flex-column flex-grow-1">
                    <a href=""
                       onClick={(e) => {
                           e.preventDefault()
                           setCurrentCompanyEnum(
                               {
                                   cardName:brand,
                                   ticket:ticker,
                                   src:logo,
                                   //@ts-ignore
                                   price:Number(price),
                                   percent:2,
                               }
                           )

                           transitionToTicketTest(ticker)
                       }}
                       className="text-dark text-hover-primary font-size-md font-weight-bolder">
                        {brand} </a>
                    <span onClick={() => {
                        setCurrentCompanyEnum(
                            {
                                cardName:brand,
                                ticket:ticker,
                                src:logo,
                                //@ts-ignore
                                price:Number(price),
                                percent:2,
                            }
                        )
                        transitionToTicketTest(ticker)
                    }} className="text-muted font-weight-bold font-size-md">${ticker} </span>
                </div>


                <div className="dropdown pl-lg-4">
                    <a
                        href="#"
                        data-toggle="dropdown"
                        onClick={clickMutedHandler(ticker)}
                        className="btn btn-icon btn-sm btn-white font-weight-bolder js__mute-status"
                        data-dropdown = 'true'
                    >
                        {
                            (!checkForMute())
                                ? <i data-dropdown = 'true' className="fa-fw fas fa-volume-up text-primary"></i>
                                : <span data-dropdown = 'true' className="text-muted">
                                    <svg data-dropdown = 'true' className="fa-custom-icon" xmlns="http://www.w3.org/2000/svg"
                                         viewBox="0 0 640 512">
                                        <path data-dropdown = 'true' fill="currentColor"
                                              d="M633.82 458.1l-69-53.33C592.42 360.8 608 309.68 608 256c0-95.33-47.73-183.58-127.65-236.03-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 42.78-11.96 83.59-33.22 119.06l-38.12-29.46C503.49 318.68 512 288.06 512 256c0-63.09-32.06-122.09-85.77-156.16-11.19-7.09-26.03-3.8-33.12 7.41-7.09 11.2-3.78 26.03 7.41 33.13C440.27 165.59 464 209.44 464 256c0 21.21-5.03 41.57-14.2 59.88l-39.56-30.58c3.38-9.35 5.76-19.07 5.76-29.3 0-31.88-17.53-61.33-45.77-76.88-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61 11.76 6.46 19.12 18.18 20.4 31.06L288 190.82V88.02c0-21.46-25.96-31.98-40.97-16.97l-49.71 49.7L45.47 3.37C38.49-2.05 28.43-.8 23.01 6.18L3.37 31.45C-2.05 38.42-.8 48.47 6.18 53.9l588.36 454.73c6.98 5.43 17.03 4.17 22.46-2.81l19.64-25.27c5.41-6.97 4.16-17.02-2.82-22.45zM32 184v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V352.6L43.76 163.84C36.86 168.05 32 175.32 32 184z"
                                              className=""></path>
                                    </svg>
                                </span>
                        }
                    </a>


                         <MutedSideBar isOpenMenuSideBar={isOpenSideMenu}
                                       labelState={labelSate}
                                       changeActive={changeActive}
                                       closeAllListItem={true}
                                       closeDropDown={closeDropDown}
                        />

                </div>



            </div>
        </>
    )
}

export default MutedStockCard