import CompanyInformationCard, {
  ICompanyInformationCard,
} from "./CompanyInformationCard";
import React, { useEffect, useMemo, useState } from "react";
import { useTransition } from "../../hooks/useTransition";
import { useLocation } from "react-router-dom";
import MessageModal from "../Modals/MessageModal";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import useTranslate from "../../hooks/useTranslate";
import useDate from "../../hooks/useDate";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AILogo from "../../image/expalnationLogo";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface IReportCard {
  date: string;
  time: string;
  title: string;
  text: string;
  source: string;
  Comment: any;
  isCommentOpen?: boolean;
  CompanyName?: string;
  dateFull?: string;
  sourceName?: string;
  translatedText?: string;
  translatedTitle?: string;
  key?: number;
  id?: number;
  CardInformation: ICompanyInformationCard;
  countComments?: number;
  mediumTime?: string | number | null;
  createdAtEvent?: number;
  createdAt?: number;
  explanation?: null | { text: string };
}

function ReportCard(props: IReportCard) {
  const location = useLocation();
  const { checkForStockPage } = useCheckForPage();
  const [isCommentOpen, setCommentOpen] = useState<boolean>(true);
  const { transitionToMessagePage } = useTransition();
  const [showRelease, setShowRelease] = useState(false);
  const handleCloseRelease = () => setShowRelease(false);
  const handleShowRelease = () => setShowRelease(true);
  const { t } = useTranslation();
  const { showEventBody, showTranslateLink } = useTranslate(props.title);
  const { getTimeAgo } = useDate();
  const translateEvents = useTypedSelector(
    (state) => state.UserReducer.User.settings.translateEvents
  );
  const [isClickToAI, setIsClickToAI] = useState<boolean>(false);

  useEffect(() => {
    showTransition(translateEvents);
    if (!props.isCommentOpen) setCommentOpen(false);
    if (location.pathname.includes("comments")) setCommentOpen(false);
  }, []);

  const transitionToMessage = (
    e: React.MouseEvent<HTMLAnchorElement>,
    isClickToUIApplication?: boolean
  ) => {
    e.preventDefault();
    transitionToMessagePage(handleShowRelease, props, isClickToUIApplication);
  };

  const renderAITooltip = (props: any) => {
    return (

        <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
          <div className="overlay">{t("EVENT_AI_BUTTON")}</div>
        </Tooltip>
    
    );
  };

  const explanationUI = useMemo(() => {
    return (
      <OverlayTrigger
      delay={{ show: 1, hide: 1 }}
      overlay={renderAITooltip}
      placement={"top"}
    >
      <a
        onClick={(e) => {
          setIsClickToAI(true);
          transitionToMessage(e, true);
        }}
        className="btn btn-primary btn-icon btn-xs ml-2"
      >
        <AILogo />
      </a>
      </OverlayTrigger>
    );
  }, [props]);

  const [isShowTransition, showTransition] = useState<boolean>(false);

  const translateText = () => {
    showTransition(true);
  };
  const backToRussia = () => {
    showTransition(false);
  };

  const getTime = () => {
    const timeAgo = getTimeAgo(props.createdAt);
    if (timeAgo === "") {
      return props.dateFull;
    }
    return `${timeAgo}, ${props.mediumTime}`;
  };

  const showTransitionHandler =
    (boolvalue: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      showTransition(boolvalue);
    };

  return (
    <>
      <MessageModal
        show={showRelease}
        handleClose={handleCloseRelease}
        isClickToAI={isClickToAI}
        explanation={props.explanation}
      />

      <span className="font-weight-bolder mb-2">
        <i className="far fa-newspaper text-success mr-2"></i>{" "}
        {t("EVENT_PRESS_RELEASE")} ({getTime()})
      </span>
      <span className="p-2 event--translated-text">
        <span className="font-weight-bolder">
          {showEventBody(props.translatedTitle, props.title, isShowTransition)}
        </span>
        &nbsp;
        {showEventBody(props.translatedText, props.text, isShowTransition)}
        <a
          className="mt-1 font-size-sm text-primary pl-2 text-nowrap"
          href="#read-news-modal"
          onClick={(e) => {
            setIsClickToAI(false);
            transitionToMessage(e);
          }}
          data-toggle="modal"
          data-event-id="410088"
          data-pjax="0"
        >
          <span className="far fa-newspaper"></span> {t("EVENT_PRESS_READ")}
        </a>
        {props.explanation && explanationUI}
      </span>

      <div className="d-flex align-items-center">
        <span className="mt-1 font-size-sm">
          <a target="_blank" href={props.source}>
            {t("EVENT_SOURCE")}
          </a>
          <span>
            {showTranslateLink(
              isShowTransition,
              props.translatedText,
              props.text,
              showTransitionHandler
            )}
          </span>
        </span>

        {props.Comment}
      </div>

      {!checkForStockPage() && (
        <CompanyInformationCard {...props.CardInformation} />
      )}
    </>
  );
}

export default ReportCard;
