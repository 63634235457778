import { useTranslation } from "react-i18next";
import {useTransition} from "../../hooks/useTransition";
import { gettOrigin } from "../../Utills/ClearFunctions";
import React from "react"
import { useTypedSelector } from "../../hooks/useTypedSelector";

function ProfileFreeDaysAlert(){
    const {transitionToSubscriptionPage} = useTransition()
    const {t} =useTranslation()
    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto) 
    const trialFeatureSttring = isCrypto ? '_CRYPTO' : '' 

    return(
        <>
            <div 
            className="card-body py-6 px-2 p-5 pt-7 pb-7">
                <i className="fas fa-bolt fa-1x text-primary mr-2"></i>
                <b>
                {t('TRIAL_BANNER_HEADER')}
                </b>
                <div className="mt-2">
                    <span className="text-success font-weight-bolder mr-1">✓</span>
                    {t('TRIAL_FEATURE' + trialFeatureSttring + '_1')}
                </div>
                <div className="mt-2">
                    <span className="text-success font-weight-bolder mr-1">✓</span>
                    {t('TRIAL_FEATURE_2')}
                </div>
                <div className="mt-2 mb-1">
                    <span className="text-success font-weight-bolder mr-1">✓</span>
                    {t('TRIAL_FEATURE' + trialFeatureSttring + '_3')}
                </div>
                <a href={`${gettOrigin()}/finance/subscription`} data-pjax="0"
                   onClick={transitionToSubscriptionPage}
                   className="btn btn-primary btn-sm mt-2">
                    <b>{t('TRIAL_TRY_FOR_FREE')}</b>
                </a>
            </div>
        </>
    )
}

export default ProfileFreeDaysAlert