import React, {useState} from 'react';
import {Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {IListNotification} from "../../models/Notifications";
import NotificationSwitch from "../Notifications/NotificationSwitch";
import NotificationBody from "./NotificationBody";

interface CommentsModal {
    show: boolean,
    handleClose: () => void
}

function NotificationModal({show, handleClose}: CommentsModal) {

    const {t} =useTranslation()

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">{t('NOTIFICATIONS_EVENTS_TYPES')}</h5>
            </Modal.Header>

            <div className={'modal-body pt-2'}>
                <NotificationBody/>
            </div>
        </Modal>
    );
}

export default NotificationModal;