import React, { useContext, useEffect } from 'react';
import {Modal} from "react-bootstrap";
import '../../styles/cardLinks.css'
import {useParams} from "react-router-dom";
import useLocalEventTicker from '../../hooks/useLocalEventTicker';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import InfiniteScroll from '../../Utills/VirtualScroll';
import { CompanyContext } from '../../Context';
import { t } from 'i18next';

interface CommentsModal{
    show: boolean,
    handleClose: () => void
}

function ReportModals({show,handleClose}: CommentsModal)  {
    const {id} = useParams()
    const companyObj = useTypedSelector(state => state.CompanyReducer)
    // const {TickerEvents,lastRowTickerEventHandler,setTickerEvents} = useLocalEventTicker()
    const {setTickerEvents,lastRowTickerEventHandler,TickerEvents} = useContext(CompanyContext)

    useEffect( () => {
        setTickerEvents(companyObj.ticker,'REPORT')
    },[show])


    return (
        <Modal size ='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">{id}: {t('STOCK_REPORTS_HEADER')}</h5>
            </Modal.Header>

            <Modal.Body>
                
                { <InfiniteScroll listItems={TickerEvents} lastRowHandler ={lastRowTickerEventHandler}  /> }
                
            </Modal.Body>


        </Modal>
    );
}

export default ReportModals;