import { t } from 'i18next';
import React, {useEffect, useState} from 'react';
import {useTransition} from "../../hooks/useTransition";
import { gettOrigin } from '../../Utills/ClearFunctions';
import {MemoDesktopSideBarLinkOnMainPage, MemoProfileBackButton} from "./SideBar";
import MobileSideBarListElements from './SideBar/MobileSideBarListElements';

interface ISideBar {
    setIsOpenSideMenu: (flag: boolean) => void
    isProfilePage: boolean
    handleBackButton: () => void
    closeAllMenuItems: () => void
    isSelectFavorites?: boolean,
    isWaitingPage?: boolean
}

const SideMenu = React.forwardRef<HTMLDivElement, ISideBar>(({
                                                                 setIsOpenSideMenu,
                                                                 isProfilePage,
                                                                 handleBackButton,
                                                                 closeAllMenuItems,
                                                                 isSelectFavorites,
                                                                 isWaitingPage
                                                             }: ISideBar, sideMenuHandler) => {
    const {
        eventTransition,
        logOutTransition
    } = useTransition()


    const [isMobileResponse,setIsMobileResponse] = useState<boolean>(false)

    const transitionToMainPage = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        eventTransition(e)
        closeAllMenuItems();
    }

    const chooseListItem = () => {
        if(isMobileResponse) return <MobileSideBarListElements/>
    }

    useEffect(() => {

        window.addEventListener('resize', function(event) {
            //@ts-ignore
            if(event.currentTarget.innerWidth > 992) setIsMobileResponse(false)
            else setIsMobileResponse(true)
        }, true);
//@ts-ignore
        if(window.innerWidth > 992) setIsMobileResponse(false)
        else setIsMobileResponse(true)
    },[])
    return (

        <div ref={sideMenuHandler} className="header-menu-wrapper header-menu-wrapper-left"
             id="kt_header_menu_wrapper" onClick={() => setIsOpenSideMenu(false)}>

            <div id="kt_header_menu"
                 className="header-menu header-menu-left header-menu-mobile header-menu-layout-default" onClick={() => {
                setIsOpenSideMenu(false)
            }}>


                <div className="header-logo mr-10 d-flex align-items-center pl-5 h-55px pl-lg-0 h-lg-auto">

                    {
                        (isProfilePage) && <MemoProfileBackButton handleBackButton={handleBackButton}/>
                    }


                    <a href="" onClick={transitionToMainPage} className="logo mt-n3">


                        <img src={`${gettOrigin()}/img/ts-logo.svg`} alt="logo" className="h-20px"/>


                    </a>
                </div>
                <ul className="menu-nav pt-0">

                    {
                        (!isWaitingPage) &&
                        <>
                            {
                                (!isSelectFavorites && !isWaitingPage) && <>
                                    <MemoDesktopSideBarLinkOnMainPage closeAllMenuItems={closeAllMenuItems}/>
                                </>
                            }

                            {
                               chooseListItem()
                            }


                        </>
                    }

                    <li className="menu-item d-lg-none" data-menu-toggle="click" aria-haspopup="true">
                        <a href={`${gettOrigin}/site/logout`}
                           data-method="post"
                           className="menu-link"
                           onClick={(e) => {
                               e.preventDefault();
                               logOutTransition()
                           }}
                        >
                            <span className="menu-text">{t('MENU_SIGNOUT')}</span>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
})

export default SideMenu;