import React, { useEffect, useLayoutEffect } from 'react';
import { Outlet, useNavigate, useParams } from "react-router-dom";
import DescriptionBar from "../components/Descriptions/DescriptionBar";
import { deleteLoginStyle, DeviceIsIOSMobileBrowser } from '../Utills/ClearFunctions'
import CompanyProvider from "../components/Providers/CompanyProvider";
import TickerProvider from "../components/Providers/tickerProvider";
import { SUBSCRIBE } from '../models/Subscriptions';

function Ticker() {
    const { id } = useParams()
    useLayoutEffect(() => {
        if (id === SUBSCRIBE) {
            window.location.href = '/finance/subscription'
            return
        }
        if (DeviceIsIOSMobileBrowser()) {
            try {
                window.location.replace(window.location.href.replace(/^http(s)?/, 'tsapp'))
            }
            catch (e: any) {
                console.log(e)
            }
        }
        window.scrollTo(0, 0);
        deleteLoginStyle()
    }, [])


    return (
        <TickerProvider>
            <CompanyProvider>
                <DescriptionBar />
                <Outlet />
            </CompanyProvider>
        </TickerProvider>
    );
}

export default Ticker