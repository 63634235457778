import {useState} from "react"
import {UseActions} from "../../hooks/useActions"
import useDate from "../../hooks/useDate"
import {useTypedSelector} from "../../hooks/useTypedSelector"
import UserService from "../../Services/UserService"
import useEvent from "./useEvent"
import {EventTopicEnum} from "../../models/EventContentModel";
import { t } from "i18next"

function useEventFavouritesQuery(setHiddenCounts:(counts:number) => void){

    // const {setPeriod} = UseActions()
    const {getCurrentDate,getMomentDate} = useDate()
    const filter = useTypedSelector(state => state.Filter.filters)
    const [testFavouritesEvents, setTestFavouritesEvents] = useState<any>()
    const [lastAllEventId,setLastAllEventId] = useState<number| null>(null)
    const [favouritesButtonSpinnerIsSpin, setFavouritesButtonSpinnerIsSpin] = useState<boolean>(true)
    const {replaceDataEvent,getFavouritesEvents,getPeriod} = useEvent()
    const {setPeriod,setFavoutitesEventsMutation,setFullFavoutitesEvents,setFavouritesEventPrevFilter, setfavouritesElementIsLoading} = UseActions()
    const allEvents = useTypedSelector(state => state.FavouritesReducer.FavoutiresElements)

    const lastRowFavouritesHandler = async () => {
        const response = await UserService.getMoreFavouritesEvent(filter,lastAllEventId as number)
        if(response.data.events.length === 0) return
        setLastIdHandler(response.data)
        const JsxData = await replaceDataEvent(response.data)
        setFullFavoutitesEvents( [...allEvents,...JsxData] )
    }

    const setMutableFavouritesEvents = async () => {
        setFavouritesButtonSpinnerIsSpin(true)

        setfavouritesElementIsLoading(true)
        const data = await getFavouritesEvents()
        setfavouritesElementIsLoading(false)

        setLastIdHandler(data)
        const JsxData = await replaceDataEvent(data)
        setFavoutitesEventsMutation(JsxData)
        setFavouritesButtonSpinnerIsSpin(false)
        setHiddenCounts(data.hiddenEventsCount)

    }

    const preloadFavouritesEvent = async (prevFilter:Array<any>) => {
        setFavouritesEventPrevFilter(prevFilter)
        setMutableFavouritesEvents()
    }


    const setLastIdHandler = (data:any) => {
        setLastAllEventId(data.lastId)
    }

    const [favouritesIsLoading, setFavouritesIsLoading] = useState<boolean>(false)

    const setFavouriteEvent = async (prevFilter:Array<any>) => {
        setFavouritesEventPrevFilter(prevFilter)

        setFavouritesIsLoading(true)
        setFavouritesButtonSpinnerIsSpin(true)

        setfavouritesElementIsLoading(true)
        const data = await getFavouritesEvents()
        setfavouritesElementIsLoading(false)
        
        setHiddenCounts(data.hiddenEventsCount)
        if(!(data.events.length !== 0)) {
            setFavouritesIsLoading(false)
            setFavouritesButtonSpinnerIsSpin(false)
            setFullFavoutitesEvents([<span>{t('FEED_FILTER_NO_EVENTS')}</span>])
            return
        }
        setLastIdHandler(data)


        getPeriod(data,EventTopicEnum.FAVORITES)
        const JsxData = await replaceDataEvent(data)
        setFullFavoutitesEvents(JsxData)

        setFavouritesIsLoading(false)
        setFavouritesButtonSpinnerIsSpin(false)
    }
    
    return {
        setFavouriteEvent,
        lastRowFavouritesHandler,
        preloadFavouritesEvent,
        favouritesIsLoading,
        favouritesButtonSpinnerIsSpin}
}
export default useEventFavouritesQuery