import React from 'react';
import { useTranslation } from 'react-i18next';

function Trends(){
    const {t} =useTranslation()
    return (
        <div>
            {t("EVENTS_TAB_TRENDS")} 
            <h1> Тренды </h1>
        </div>
    );
}

export default Trends;