import React from "react"
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { UseActions } from '../../hooks/useActions';
import DropDownFavouritesAllStocks from './DropDownFavouritesAllStocks';

function DropDownWrapperContntent(){
    const isOpen = useTypedSelector(state => state.EventReducer.isOpen)
    const { setIsOpen } = UseActions()
    
    return <>
        <DropDownFavouritesAllStocks setIsOpen={setIsOpen} isOpen={isOpen} />
    </>
}
export default DropDownWrapperContntent