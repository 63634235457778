import React, {useMemo} from "react";
import {ORIGIN} from "../../models/Domen";
import useDate from "../../hooks/useDate";
import CommentService from "../../Services/CommentService";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useTranslation } from "react-i18next";



interface ICommentator{
    id:number,
    avatar:string,
    username:string
    fullName:string
}
export interface IComment{
    id:string,
    createdAt:number,
    countLikes:number,
    text:string,
    commentator:ICommentator,
    initialCountLikes?:number,
    isLiked?:boolean
}

type CommentComponent = IComment & {
    deleteComment:(id:string) => void,
    likeComment:(id:string) => void,
    replyComment:(id:string) => void
}
function Comment({commentator, countLikes, text, createdAt,id, deleteComment,likeComment,replyComment,initialCountLikes,isLiked}:CommentComponent ){
    const MySwal = withReactContent(Swal)

    const {getCommentDate} = useDate()
    const User = useTypedSelector(state => state.UserReducer.User)
    //useMemo
    const getDate = () => {
       const {fullDate,optionalDataValue,shortTime} = getCommentDate(createdAt)
        if(optionalDataValue) return <span> {`${optionalDataValue}, ${shortTime}`} </span>
        else return <span>{fullDate}</span>
    }

    const {t} =useTranslation()

    const likeIsMuted = useMemo(() => {
        return countLikes === initialCountLikes
    },[countLikes])

    const deleteCommentClickHandler = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        MySwal.fire({
            title: <p>{t('COMMENTS_DELETE_PROMPT')}</p>,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: t('COMMENTS_DELETE_PROMPT_YES'),
            cancelButtonText: t('PROFILE_TELEGRAM_BOT_REMOVE_NO')
        }).then((result) => {
            if(result.isDenied){
                deleteComment(id)
            }
        })

    }


    const likeCommentClickHandler = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        likeComment(id)
        e.preventDefault()
    }

    const replyClickHandler = (e:React.MouseEvent<HTMLAnchorElement>) => {

        replyComment(id)
        const input = document.getElementById('comment-text')
        input!.focus()
        e.preventDefault()
    }


    return<>

        <div className="d-flex align-items-start py-3">
            <div className="d-flex align-items-center">
                <div className="d-flex align-items-center flex-wrap mr-3" data-toggle="view">
            <span className="symbol symbol-35 symbol-circle mr-3">
                <span className="symbol-label"
                    style={{backgroundImage: `url(${ORIGIN + commentator.avatar})`}}
                ></span>
            </span>
                </div>
            </div>
            <div className="flex-grow-1 mr-2" data-toggle="view">
                <div className="card card-custom card-shadowless bg-gray-100 p-3">
                    <span className="font-weight-bolder mr-2">{commentator.username}</span>
                    <span className="text-dark">{text}</span>
                </div>
                <div className="mt-2 font-size-sm text-dark-50">

                <span className=" mr-1">
                    {getDate()}
                </span>
                    |

                    <a onClick={likeCommentClickHandler} href="#" data-comment-id="53" data-status="1"
                       className= {!isLiked ? "js__toggle-comment-like mx-1 text-muted" : "js__toggle-comment-like mx-1"} >

                        <span className="far fa-thumbs-up fa-1x" aria-hidden="true"> {countLikes} </span> </a>
                        {/*<span className="far fa-thumbs-down fa-1x" aria-hidden="true"> {countLikes} </span>*/}
                    |

                    {
                        //@ts-ignore
                        User.id === commentator.id
                            ? <a onClick={deleteCommentClickHandler} href="#" data-id="53" className="js__delete-comment ml-1">
                                <i className="far fa-trash-alt fa-1x"></i>
                            </a>
                            : <a onClick={replyClickHandler} href="#" data-comment-id="53" data-status="1"
                                 className="js__toggle-comment-like mx-1 text-muted">
                                <i className="fas fa-reply fa-1x"></i>
                                {/*<span className="far fa-thumbs-down fa-1x" aria-hidden="true">  </span>*/}
                            </a>
                    }

                </div>
            </div>
        </div>


    </>
}

export default  Comment