import { t } from "i18next"
import { FilterConstant, IListStruct } from "../models/FilterEvent"
import { getCookie } from "../Utills/ClearFunctions"
import { UseActions } from "./useActions"
import { useTypedSelector } from "./useTypedSelector"

export default function useFilters(){
    const {setListStruct,setTrendActiveElements, setActiveFilterElements, SetTrends} = UseActions()
    const Filter = useTypedSelector(state => state.Filter.filters)

    const setLists = () => {
        const trendsElementsInit = [
            {
                text: t('EVENTS_TRENDS_FILTER_ALL'),
                classElement:'fa-fw fas fa-fire-alt text-primary mr-2',
                active:true
            },
            {
                text:t('EVENTS_TRENDS_FILTER_GAINERS'), 
                classElement:'fa-fw fas fa-long-arrow-alt-up text-success mr-2',
                active:false
            },
            {
                text:t('EVENTS_TRENDS_FILTER_LOSERS'),
                classElement:'fa-fw fas fa-long-arrow-alt-down text-danger mr-2',
                active:false
            },
        ]

        const initialStateFilter: Array<IListStruct> =[
            {
                text: t('FEED_FILTER_PRICE_MOVEMENT'),
                id: FilterConstant.PRICE_FLUCTUATIONS,
                check: true,
                classElement: 'fa fa-chart-line'
            },
            {
                text: t('FEED_FILTER_PRICE_52HIGHTLOW'),
                id: FilterConstant.YEARLY_MINIMUM_MAXIMUM,
                check: true,
                classElement: 'fas fa-exchange-alt fa-rotate-90 fa-fw mr-3'
            },
            {
                text: t('FEED_FILTER_PRESS_RELEASE'),
                id: FilterConstant.PRESS_RELEASES,
                check: true,
                classElement: 'far fa-newspaper'
            },
            {
                text: t('FEED_FILTER_FINTWEET'),
                id: FilterConstant.FINANCIAL_TWEETS,
                check: true,
                classElement: 'fas fa-volume-up'
            },
            {
                text: t('FEED_FILTER_FDA'),
                id: FilterConstant.FDA,
                check: true,
                classElement: 'fas fa-pills'
            },
            {
                text: t('FEED_FILTER_FAA'),
                id: FilterConstant.FAA,
                check: true,
                classElement: 'fas fa-rocket'
            },
            {
                text: t('FEED_FILTER_ACQUISITION'),
                id: FilterConstant.ABSORPTION,
                check: true,
                classElement: 'fas fa-bolt'
            },
            {
                text: t('FEED_FILTER_REPORT'),
                id: FilterConstant.REPORTING,
                check: true,
                classElement: 'far fa-money-bill-alt'
            },
            {
                text: t('FEED_FILTER_SEC'),
                id: FilterConstant.SEC,
                check: true,
                classElement: 'fab fa-wpforms'
            },
            {
                text: t('FEED_FILTER_INSIDER'),
                id: FilterConstant.INSIDER_CLOSES,
                check: true,
                classElement: 'fas fa-handshake'
            },
            {
                text: t('FEED_FILTER_FORECAST'),
                id: FilterConstant.ANALYST_FORECASTS,
                check: true,
                classElement: 'fas fa-pen-alt'
            },
            {
                text: t('FEED_FILTER_PRICE_TARGET'),
                id: FilterConstant.TARGET_PRICE,
                check: true,
                classElement: 'far fa-dot-circle'
            },
            {
                text: t('FEED_FILTER_MARKET_DATA'),
                id: FilterConstant.MARKET_DATA,
                check: true,
                classElement: 'fas fa-search'
            },
            {
                text: t('FEED_FILTER_INFLUENCER_TWEET'),
                id: FilterConstant.TWEETS_OF_POPULAR_PEOPLE,
                check: true,
                classElement: 'fab fa-twitter'
            },
            {
                text: t('FEED_FILTER_MEDIA_MENTION'),
                id: FilterConstant.MEDIA_PUBLICATIONS,
                check: true,
                classElement: 'far fa-newspaper'
            },
        {
            text: t('FEED_FILTER_CLEAR'),
            id: FilterConstant.DELETE_ALL,
            check: false,
            classElement: 'fa fa-times'
        }
    ]

        try {
            const eventTypes = localStorage.getItem('eventTypes')

            if (eventTypes) {
                setListStruct(initialStateFilter)
                setActiveFilterElements(JSON.parse(eventTypes))
            } else {
                window.localStorage.setItem('eventTypes', JSON.stringify(Filter))
                setListStruct(initialStateFilter)
            }

            if (getCookie('trends')) {
                SetTrends(trendsElementsInit )
                setTrendActiveElements(JSON.parse(decodeURIComponent(getCookie('trends') as string)))
            } else {
                document.cookie = `trends=${encodeURIComponent(JSON.stringify(trendsElementsInit))};path=/`
                SetTrends(trendsElementsInit)
            }
        } catch (e) {
            throw e
        }
    }

    return {setLists}
}