import React, {useEffect, useState,useRef} from 'react';
import '../../styles/EventButton.css'
import drop from '../../image/downSvg.svg'
import TrendElement from "./TrendElement";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {UseActions} from "../../hooks/useActions";
import { useTranslation } from 'react-i18next';

interface IEventButton {
    isOpen: boolean,
    setIsOpen: (flag: boolean) => void,
}

interface ITrendElement{
    text:string
    classElement:string
    active:boolean
}

function EventButton({isOpen, setIsOpen}: IEventButton) {
    const buttonRef = useRef<any>()
    const [refWidth,setRefWidth] = useState<number>(0)

    const { t, i18n } = useTranslation()


    const selectButton = () => {
        if (!isOpen) return button
        else return dropDown
    }
    const changeButtonState = () => {
        setIsOpen(!isOpen)
    }
    const trends = useTypedSelector(state => state.EventReducer.trendsElements)
    const {SetTrends} = UseActions()

    const [currentActiveTrend,setCurrentActiveTrend] = useState<ITrendElement>({} as ITrendElement)

    const getActiveTrend = () => {
        for(let i = 0; i < trends.length; i++){
            if(trends[i].active) {
                setCurrentActiveTrend({...trends[i]})
                return
            }
        }
    }

    const getActiveText = () => {
        for(let i = 0; i < trends.length; i++){
            if(trends[i].active) {
                return trends[i].text
            }
        }
    }

    const getActiveClass = () => {
        for(let i = 0; i < trends.length; i++){
            if(trends[i].active) {
                return trends[i].classElement.replace('text-primary','')
            }
        }
    }

    useEffect(() => {
        setRefWidth(buttonRef.current.getBoundingClientRect().width)
        getActiveTrend()
    },[])

    const clickHandler = (text:string) => (e:any) => {
        SetTrends(trends.map(trend => {
            if(trend.text === text) {
                setCurrentActiveTrend({...trend})
                return {...trend, active: true}
            }
            else return {...trend, active: false}
        }))
    }

    const getTranslateXValue = () => {
        const width = window.innerWidth
        if(width < 991) return 50
        else return 40
    }

    

    const elements = trends.map(trend =>  <TrendElement {...{...trend, clickHandler}}/>)

    const dropDown = (<div  
        id="filter-types-menu" onClick={(e) => e.stopPropagation()} 
        className="dropdown-menu dropdown-menu-right mt-2 show"
        style={{
            width:'auto',
            zIndex: '999900',
            position: 'absolute',
            transform: `translate3d(-${ (getActiveText() === t('EVENTS_TRENDS_FILTER_ALL')) ? getTranslateXValue() : 15}px, 36px, 0px)`,
            top: '0px',
            left: '0px',
            willChange: 'transform',
           
        }}
    >
        {elements}
    </div>)


    const button = (
        <div id="filter-types-menu" className="dropdown-menu dropdown-menu-right mt-2">

        </div>
    )

    return (
        <div  onClick={(e: any) => {
            e.stopPropagation()
        }}>

            <div  ref = {buttonRef} className="  dropdown pl-lg-4 eventButton" onClick={changeButtonState}>
                <button  id="filter-trending-filter-button"
                   className="btn btn-sm dropdown-toggle btn-outline-primary"
                   type="button" data-toggle="dropdown" aria-expanded="false">
                    <b className={getActiveClass()}></b>{getActiveText()}
                </button>

                {
                    selectButton()
                }

            </div>

        </div>
    );
}

export default EventButton