import React from "react";

interface ITrendElement{
    text:string
    classElement:string
    active:boolean
    clickHandler:Function
}
function TrendElement({text,classElement,clickHandler}:ITrendElement){
    return(
        <label onClick={clickHandler(text)} className="js__filter-events-trend-filter dropdown-item radio">
            <input data-label="<b class=&quot;fa-fw fas fa-fire-alt text-primary mr-2&quot;></b> Все"
                   type="radio" name="trendFilter" value="1"/>
            <b className={classElement}></b>{text}
        </label>
    )
}
export default TrendElement