import React, { useCallback, useEffect, useState } from "react";
import TimeLineDay from "../../components/Time/TimeLineTimeDay";
import EventSwitch from "../../hooks/EventSwitch";
import { UseActions } from "../../hooks/useActions";
import useDate from "../../hooks/useDate";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import UserService from "../../Services/UserService";
import { FilterConstant } from "../../models/FilterEvent";
import MoreEvent from "../../components/Events/MoreEvents";

function useEventTickerQuery(setHiddenCounts: (counts: number) => void) {
  const [TickerEvents, setTickerEvent] = useState<any>(null);
  const [currentTicker, setCurrentTicker] = useState<string>("");
  const { setTickerElementsIsLoading } = UseActions();
  const { getCurrentDate, getMomentDate, getMediumDateabdShortTime } =
    useDate();
  const filter = useTypedSelector((state) => state.Filter.filters);
  const [lastAllEventId, setLastAllEventId] = useState<number | null>(null);
  const { setPeriod } = UseActions();
  const [tickerEventIsLoading, setTickerEventIsLoading] =
    useState<boolean>(false);
  const User = useTypedSelector((selector) => selector.UserReducer.User);
  const [deleteElement, setDeleteElement] = useState<string | null>(null);

  const DeleteEventTicker = useCallback(
    (eventId: string) => {
      setTickerEvent(
        TickerEvents.filter((Event: any) => {
          if (Event.key == eventId) {
          }
          return Event.key != eventId;
        })
      );
    },
    [TickerEvents]
  );

  useEffect(() => {
    if (!deleteElement) return;
    DeleteEventTicker(deleteElement);
  }, [deleteElement]);

  const getEventJSX = (event: any, EventsJSX: any, prevDay: any) => {
    const [
      shortTime,
      currentDay,
      nameOfMounth,
      currentDate,
      optionalDataValue,
      fullDate,
      year,
      fullDateTootip,
      shortDateTime,
      mediumTime,
    ] = getMomentDate(event.createdAt);
    const explanation = event.explanation;

    const EventJsx = EventSwitch(
      event.type,
      event.data,
      event.priceInfo,
      event.stock,
      shortTime as string,
      currentDay as number,
      prevDay,
      nameOfMounth as string,
      optionalDataValue as string | null,
      fullDate as string,
      event.id,
      User.canEditEvents,
      setDeleteElement,
      event.countComments,
      shortDateTime,
      mediumTime,
      getMediumDateabdShortTime,
      fullDateTootip as string,
      event.data.publishedAt,
      event.createdAt,
      explanation
    );
    if (prevDay !== currentDay) {
      EventsJSX.push([
        <div>
          {!optionalDataValue ? (
            <>
              <TimeLineDay date={`${currentDay + " " + nameOfMounth}`} />
              <hr />
            </>
          ) : (
            <TimeLineDay
              date={`${
                optionalDataValue + ", " + currentDay + " " + nameOfMounth
              }`}
            />
          )}
        </div>,
        EventJsx,
      ]);
    } else {
      EventsJSX.push(EventJsx);
    }
  };

  const replaceDataEvent = async (data: any) => {
    const lastid = data.lastId;
    setLastAllEventId(data.lastId);
    let prevDay = getCurrentDate();
    let isAccordion: boolean = false;
    const EventsJSX: any = [];
    let accordion: any = [];
    let countAccordions = 0;
    let isMore = false;
    for (let i = 0; i < data.events.length; i++) {
      const event = data.events[i];
      const [
        shortTime,
        currentDay,
        nameOfMounth,
        currentDate,
        optionalDataValue,
        fullDate,
        year,
        fullDateTootip,
        shortDateTime,
        mediumTime,
      ] = getMomentDate(event.createdAt);
      if (i === 0) {
        const periodObj = {
          reduction: `${currentDay}`,
          full: `${currentDay} ${nameOfMounth}`,
        };
        if (optionalDataValue)
          periodObj.full = `${
            optionalDataValue + ", "
          } ${currentDay} ${nameOfMounth}`;
        setPeriod(periodObj);
        prevDay = currentDay as number;
      }

      if (!isAccordion && event.type === FilterConstant.PRICE_FLUCTUATIONS) {
        if (i === data.events.length - 1) {
          isAccordion = false;
        } else {
          if (data.events[i + 1].type === FilterConstant.PRICE_FLUCTUATIONS) {
            countAccordions++;
            isAccordion = true;
            getEventJSX(event, EventsJSX, prevDay);
            prevDay = currentDay as number;
            continue;
          } else isAccordion = false;
        }
      }

      if (isAccordion) {
        getEventJSX(event, accordion, prevDay);
        prevDay = currentDay as number;

        if (data.events[i + 1]) {
          if (data.events[i + 1].type === FilterConstant.PRICE_FLUCTUATIONS)
            continue;
        }

        const TimeLineJsx = (
          <MoreEvent data={accordion} count={accordion.length} />
        );
        EventsJSX.push(TimeLineJsx);
        prevDay = currentDay as number;
        if (accordion.length >= 19) {
          isMore = true;
        }
        accordion = [];
        isAccordion = false;
        continue;
      }

      getEventJSX(event, EventsJSX, prevDay);
      prevDay = currentDay as number;
    }

    return { JsxData: EventsJSX.flat(), isMore: isMore, lastid: lastid };
  };

  const lastRowTickerEventHandler = async (lastId: number | null) => {
    const response = await UserService.getTikerEventsMore(
      filter,
      currentTicker,
      (!lastId ? lastAllEventId : lastId) as number
    );
    if (response.data.events.length === 0) return;
    const { JsxData, isMore, lastid } = await replaceDataEvent(response.data);

    setTickerEvent((prev: any) => {
      return [...prev, ...JsxData];
    });

    if (isMore) {
      lastRowTickerEventHandler(lastid);
    }
  };

  const getTikerEvents = async (ticker: string) => {
    setTickerElementsIsLoading(true);
    const response = await UserService.getTikerEvents(filter, ticker);
    setTickerElementsIsLoading(false);
    return response.data;
  };

  const setTickerEvents = async (ticker: string) => {
    setTickerEventIsLoading(true);
    setCurrentTicker(ticker);

    const data = await getTikerEvents(ticker);
    const { JsxData, isMore } = await replaceDataEvent(data);
    setTickerEvent(JsxData);
    setTickerEventIsLoading(false);
    setHiddenCounts(data.hiddenEventsCount);
  };

  return {
    TickerEvents,
    setTickerEvents,
    lastRowTickerEventHandler,
    tickerEventIsLoading,
  };
}
export default useEventTickerQuery;
