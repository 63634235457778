import { GroupEnum } from "../../models/Search"
import { DeviseIsApplicationOnly, gettOrigin } from "../../Utills/ClearFunctions"
import { useEffect, useRef } from "react"
interface IGroups {
    text: string
    id: GroupEnum
    active: boolean
    clickHadler: (id: GroupEnum) => (e: React.MouseEvent<HTMLAnchorElement>) => void
}

function Groups({ text, id, active, clickHadler }: IGroups) {
    const linkRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)
    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            linkRef.current.classList.add('disableNavItemHover')
        }
    }, [])

    return (
        <li className="nav-item">
            <a
                onClick={clickHadler(id)}
                href={`${gettOrigin()}/stocks?useLinks=1&amp;group=0`}
                ref={linkRef}
                className={active ? "nav-link py-2 px-4 font-weight-bolder active" : "nav-link py-2 px-4 font-weight-bolder"}
                data-group-id="1"
            >
                {text}
            </a>
        </li>
    )
}
export default Groups