import {useTypedSelector} from "./useTypedSelector";

function useFormatCurrency(){
    const language = useTypedSelector(state => state.UserReducer.User.settings.language)

    const getPrice = (price:number, currency:string | undefined = 'USD' ) => {
       return new Intl.NumberFormat(language, { 
            minimumFractionDigits: 1,
            maximumFractionDigits: 8,
            minimumSignificantDigits: 1,
            maximumSignificantDigits: 8,
            style: 'currency', 
            currency: currency 
    }).format(price)
    }

    return {getPrice}          
}

export default useFormatCurrency