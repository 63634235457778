
import { AxiosResponse } from "axios";
import $api from "../http";
import { AreaPeriodType, ChartGroupTypes, IDataChart,IChartResponse } from "../models/Chart";

export default class SubscriptionService{

    static getDesctopTariffs(): Promise< AxiosResponse<any>> {
        return $api.get('/subscription/tariffs')
    }

    static payDesctop(sku:string): Promise< AxiosResponse<any>>{
        return  $api.get('/subscription/pay',{params:{sku}})
    }

    static checkOrderAndroid(orderId:string): Promise<AxiosResponse<any>>{
        return $api.get('/subscription/check-google-order',{params:{orderId}})
    }

    static checkOrderIOS(transactionId:string): Promise<AxiosResponse<any>>{
        return $api.get('/subscription/check-apple-transaction',{params:{transactionId}})
    }

} 