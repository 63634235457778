import { useTranslation } from "react-i18next"

function EmptyTrends(){
    const {t} =useTranslation()
    return(
        <div style= {{display:'flex',justifyContent:'center', alignItems:'center'}}>
        <div className="w-400px text-center mt-8">
                                <span className="svg-icon svg-icon-primary svg-icon-4x">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path d="M14,7 C13.6666667,10.3333333 12.6666667,12.1167764 11,12.3503292 C11,12.3503292 12.5,6.5 10.5,3.5 C10.5,3.5 10.287918,6.71444735 8.14498739,10.5717225 C7.14049032,12.3798172 6,13.5986793 6,16 C6,19.428689 9.51143904,21.2006583 12.0057195,21.2006583 C14.5,21.2006583 18,20.0006172 18,15.8004732 C18,14.0733981 16.6666667,11.1399071 14,7 Z" fill="#000000"></path>
                                    </g>
                                    </svg>
                                </span>
                                <h5 className="mt-3">{t('EVENTS_TRENDS_NO_EVENTS_HEADER')}</h5>
                                <p>{t('EVENTS_TRENDS_NO_EVENTS_SUBHEADER')}</p>
        </div>
        </div>
    )
}
export default EmptyTrends