import React, { useEffect, useRef } from 'react';
import '../../styles/CompanyCardStyles.css'
import ColorText from "../Text/ColorText";
import { useTransition } from "../../hooks/useTransition";
import { UseActions } from "../../hooks/useActions";
import { ORIGIN } from "../../models/Domen";
import StockImage from '../Image/StockImage'
import { outputCardPercent } from './FavvoritesCard';
import useFormatCurrency from '../../hooks/useFormatCurrency';
import { DeviseIsApplicationOnly } from '../../Utills/ClearFunctions';
import { ICard } from '../../hooks/useSelectCard';

function CompanyCard({ changeFill, brand, star, fill, src, price, percent, cardName, ticket, isAuth, isUp, oldPrice, currency }: ICard) {
    const { transitionToTicketTest } = useTransition()
    const { setCurrentCompanyEnum } = UseActions()
    const { getPrice } = useFormatCurrency()

    const handleTransitionToCompanyTicker = () => {
        setCurrentCompanyEnum(
            {
                cardName: cardName,
                ticket: ticket,
                src: src,
                price: price,
                percent: percent,
                oldPrice: oldPrice,
                brand: brand
            }
        )
        transitionToTicketTest(ticket)
    }

    const clickToStocks = (ticket: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (isAuth) changeFill(ticket)
        else handleTransitionToCompanyTicker()
    }
    const linkRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            linkRef.current.classList.remove('text-hover-primary')
            starRef.current.classList.add('disableStar')
        }
    }, [])

    return (
        <div className="d-flex align-items-center mb-7">
            <div className="mr-3 mr-lg-4 flex-shrink-0">
                <a href={`${ORIGIN}/stocks/${ticket}`} onClick={clickToStocks(ticket)}
                    className="symbol symbol-40 symbol-lg-50 symbol-circle">
                    <StockImage src={src} />
                </a>
            </div>
            <div className="d-flex flex-column flex-grow-1">
                <a href={`${ORIGIN}/stocks/${ticket}`} onClick={clickToStocks(ticket)}
                    ref={linkRef}
                    className="text-dark text-hover-primary mb-1 font-size-h5 font-weight-bolder">
                    {cardName} </a>
                <span className="text-muted font-weight-bold font-size-lg">{ticket}</span>
            </div>
            {
                (price && percent)
                    ? <div className="favourites-price-info"><span className='font-size-h5-lg font-size-lg pr-6'>

                        <ColorText type={isUp ? 'text-success' : 'text-danger'}>
                            <span className="font-weight-bolder d-block">{getPrice(price, currency)}</span>
                            <span className="d-block font-size-sm text-right">{outputCardPercent(percent)}</span>
                        </ColorText>

                    </span>
                    </div>
                    : <span className="font-size-h5-lg font-size-lg pr-6">
                        <div className="">
                            {price && <span className="font-weight-bolder d-block"> {getPrice(price, currency)}</span>}
                        </div>
                    </span>
            }
            <div className={(!star) ? "favourites-button d-none" : "favourites-button"}
                onClick={(e: any) => e.stopPropagation()}>

                <a data-ticker="MRNA" data-status="1" onClick={() => {
                    changeFill(ticket, src)
                }}
                    ref={starRef}
                    className="btn btn-icon btn-sm btn-white font-weight-bolder js__toggle-favourites">
                    <i className={(fill) ? "fas fa-star text-primary p-0" : "far fa-star text-primary p-0"}></i>
                </a>
            </div>
        </div>
    );
}

export default React.memo(CompanyCard);