import React, {useCallback, useState} from "react";
import { useTranslation } from "react-i18next";
import HowToReadCompanyInformationModal from "./HowToReadCompanyInformationModal";

interface IDescriptionTable{
    companyInformation:any
}
function DescriptionTable({companyInformation}:IDescriptionTable){

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const transitionToHowToRead = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        handleShow()
    }
    const checkForUp = useCallback((price:string) =>{ 

        if(price.includes('+')) return true
        else return false

    },[])
    const {t} = useTranslation()


    return(
        <div className="flex-row-fluid mt-4" id="stock-price-details">
            <HowToReadCompanyInformationModal show={show} handleClose={handleClose}/>

            <table className="table">
                <tbody>
                <tr>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_CAP')}</div>
                        <div>{companyInformation?.capitalization}</div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_VOLUME_1D')}</div>
                        <div>{companyInformation?.shareVolume}</div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_VOLUME_1D_USD')}</div>
                        <div>{companyInformation?.volume}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_CLOSE')}</div>
                        <div>{companyInformation?.lastSalePrice}</div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_VOLUME_3M')}</div>
                        <div>{companyInformation?.averageShareVolume}</div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_VOLUME_3M_USD')}</div>
                        <div>{companyInformation?.averageVolume}</div>
                    </td>
                </tr>
                <tr>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_CLOSE_PERCENT')}</div>
                        <div>{companyInformation?.lastSalePriceChange} <span 
                            className= {`font-weight-bolder
                             ${checkForUp(companyInformation?.lastSalePriceChange) ? 'text-success' : 'text-danger'}`} >
                            {
                                checkForUp(companyInformation?.lastSalePriceChange) 
                                    ? <>↑</>
                                    :<>↓</>
                            }
                            </span></div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_52W_MIN')}</div>
                        <div>{companyInformation?.fiftyTwoWeekLow}</div>
                    </td>
                    <td>
                        <div className="font-size-sm text-muted">{t('STOCK_MARKETDATA_52W_MAX')}</div>
                        <div>{companyInformation?.fiftyTwoWeekHigh}</div>
                    </td>
                </tr>
                <tr>
                    <td colSpan={3}>
                        <a href="" onClick={transitionToHowToRead} data-toggle="modal" data-target="#stocksDetailsModal"
                           className="text-muted font-size-sm"><i
                            className="fas fa-info-circle fa-1x text-primary" aria-hidden="true"></i> 
                            <span>&nbsp;</span>{t('STOCK_MARKETDATA_FAQ')}
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}

export default DescriptionTable