import { useTypedSelector } from "./useTypedSelector";

const useTradingView = () => {
    const User = useTypedSelector((state) => state.UserReducer.User);

    const getTradingViewWidth = () => {
        if (window.innerWidth < 991) return 400
        const chartSizeObj = document.querySelector('#tradingview')?.getBoundingClientRect()
        const footerSizeObj = document.querySelector('.footer')?.getBoundingClientRect()
        if (!chartSizeObj || !footerSizeObj) return 0
        const TradingViewWidth = (chartSizeObj.y + 400 > footerSizeObj.y) ? 200 : 400
        return TradingViewWidth
    }

    const addTraidingViewWidgetChart = (id:string,isCoinsPage:boolean) => {        
        setTimeout(() => {
            const height = getTradingViewWidth()
            if (!height) return
            //@ts-ignore
            new TradingView.MediumWidget(
                {
                    "symbols": [[`${id}${isCoinsPage ? 'USD' : '|1M'}`]],
                    "chartOnly": true,
                    "width": "100%",
                    "height": `${height}px`,
                    "locale": `${User.settings.language}`,
                    "colorTheme": "light",
                    "showFloatingTooltip": true,
                    "gridLineColor": "rgba(255,255,255, 1)",
                    "trendLineColor": "rgba(71,197,83, 1)",
                    "fontColor": "#787B86",
                    "underLineColor": "rgba(242,249,239, 0.5)",
                    "underLineBottomColor": "rgba(242,249,239, 0.5)",
                    "isTransparent": true,
                    "autosize": true,
                    "container_id": "tradingview"
                }
            );
        }, 300)
    }
    return {
        addTraidingViewWidgetChart
    }
}
export default useTradingView