import '../../styles/ProSubscriptions.css'
import {Button} from "react-bootstrap";
import {useEffect, useState} from "react";
import useSelectSubscriptions from "../../hooks/useSelectSubscriptions";

function ProSubscription() {
    const [isReView,setReView] = useState<boolean>(false)
    const [textButton,setTextButton] = useState<string>('Apple Review')
    const SubscriptionPage = useSelectSubscriptions()

    useEffect(() => {
        window.scrollTo(0,0)
    })

    return (
        <> {SubscriptionPage} </>
    )
}

export default ProSubscription