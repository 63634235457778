import React, { useContext } from 'react';
import {Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import { CompanyContext } from '../../Context';
import MarketMap from '../../page/MarketMap';

interface CommentsModal{
    show: boolean,
    handleClose: () => void
    id:number,
    header:string
}

function MarketMapModal({show,handleClose,id,header}: CommentsModal)  {

    const {t} = useTranslation()

    const {setTickerEvents,lastRowTickerEventHandler,TickerEvents} = useContext(CompanyContext)

    return (
        <Modal size ='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">
                    {header}            
                </h5>
            </Modal.Header>
            <Modal.Body>    
                <MarketMap propsId = {id}/>    
            </Modal.Body>

        </Modal>
    );
}

export default MarketMapModal;