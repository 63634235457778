import React, { useEffect } from 'react';
import eventStyles from '../../styles/eventsStyles.module.scss'
import {Outlet} from "react-router-dom";
import EventContent from "./EventContent";
import FavouritesWidget from "../Widgets/FavouritesWidget";
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { DOMEN, ORIGIN } from '../../models/Domen';

function Events() {
    const isAuth = useTypedSelector(state => state.UserReducer.isAuth)
    useEffect(() => {        
        if(!isAuth)
            window.location.replace(ORIGIN + '/wizard')
    },[])
    return (
        <div>
            <div className={eventStyles.eventTransitionGroup}>
                <EventContent/>
                <FavouritesWidget isPage={false}/>
            </div>
            <Outlet/>
        </div>
    );
}

export default React.memo(Events);