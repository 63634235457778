import { Trans } from "react-i18next"
import { gettOrigin } from "../../Utills/ClearFunctions"

interface IFooterMobile{
    device:string
}

//iTunes
//Google

function FooterMobile({device}:IFooterMobile){
    return(
        <div className="text-muted font-size-xs mt-2">
           
           <Trans i18nKey={'SUBSCRIPTION_RULES'}>
                <p className="mb-0">
                    {{device:device}}
                </p>
           </Trans>
           
            <p>
                <Trans i18nKey={'SUBSCRIPTION_RULES_LINKS'}>
                    <a href={`${gettOrigin()}/law/terms`} target="_blank"> </a>
                    <a href={`${gettOrigin()}/law/privacy`} target="_blank"></a>
                    <a href={`${gettOrigin()}/law/rules`} target="_blank"></a>
                </Trans>
           </p>
        </div>
    )
}
export default FooterMobile