import React, { useEffect } from "react"
import { IDiscountInfo } from "./DesktopSubscription"
import Countdown from "react-countdown";
import { t } from "i18next";
import { Trans } from "react-i18next";

interface IDiscountAlert{
    discountInfo:IDiscountInfo | null,
    setIsPromocodeApply: (boolValue:boolean) => void
}

function DiscountAlert(props:IDiscountAlert){
    const getFormatedDate = (obj:any) => {
        if(obj.days)
            return<>
                    {t('SUBSCRIPTION_PROMO_DAYS',{count:obj.days})} {t('SUBSCRIPTION_PROMO_HOURS',{count:obj.hours})}
            </>
        else if(obj.hours)    
            return <>
                    {t('SUBSCRIPTION_PROMO_HOURS',{count:obj.hours})} {t('SUBSCRIPTION_PROMO_MINUTES',{count:obj.minutes})}
            </>
        else if(obj.minutes)    
            return <>
                    {t('SUBSCRIPTION_PROMO_MINUTES',{count:obj.minutes})} {t('SUBSCRIPTION_PROMO_SECONDS',{count:obj.seconds})}
            </>
        else return <>
                    {t('SUBSCRIPTION_PROMO_SECONDS',{count:obj.seconds})}
         </>   
    }
    // <b>Скидка {props.discountInfo?.discount}% </b> действует в течении {obj.days}
    // const renderer = ({ hours, minutes, seconds, completed,days, months }:any) => {
    const renderer = (Timeobj:any) => {
        if (Timeobj.completed) {
            props.setIsPromocodeApply(false)
          return <></>;
        } else {
          return (
            <div>
                <Trans i18nKey={'SUBSCRIPTION_PROMO_UNTIL'}>
                    <b></b>&nbsp; 
                    {{discount:props.discountInfo?.discount}}
                </Trans>
                &nbsp;
                  <span>
                    {getFormatedDate(Timeobj)}
                  </span>
            </div>
          );
        }
      };

    const getInformation = () => {
        const finishedAt = props?.discountInfo?.finishedAt
        if(!props.discountInfo) return
        if(props.discountInfo.finishedAt === null) {
            if(props.discountInfo.promoCode)
                return <div className="alert alert-custom alert-light-warning p-4 font-size-sm">
                    <Trans i18nKey={'SUBSCRIPTION_PROMO_WITH_CODE'}>
                        <b></b>&nbsp;{{discount:props.discountInfo.discount}}{{code:props.discountInfo.promoCode}}
                    </Trans>
                </div>  
            else return  <div className="alert alert-custom alert-light-warning p-4 font-size-sm">
                <Trans i18nKey={'SUBSCRIPTION_PROMO_DISCOUNT'}>
                    <b></b>&nbsp; 
                    {{discount:props.discountInfo.discount}}
                </Trans>
        </div>                                                                    
        }
        else{
            return  <div className="alert alert-custom alert-light-warning p-4 font-size-sm">
                <Countdown date={ finishedAt as number  * 1000 } renderer={renderer} />
        </div>     
        }
    }

    return <>
        {
            props.discountInfo &&
            <div className="row text-left justify-content-md-center">
                <div className="col-lg-6">
                    {getInformation()}
                </div>
                <div>
                </div>
            </div>
        }
    </>
}

export default DiscountAlert