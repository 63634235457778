import React, { useEffect, useRef } from 'react';
import { useTransition } from "../../hooks/useTransition";
import { UseActions } from "../../hooks/useActions";
import { closeSearchView, DeviseIsApplicationOnly, getNumberSign } from "../../Utills/ClearFunctions";
import ColorText from '../Text/ColorText';
import { ORIGIN } from "../../models/Domen";
import useFormatCurrency from '../../hooks/useFormatCurrency';
import FavouritesImage from '../Image/FavouritesImage'
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ICard } from '../../hooks/useSelectCard';

export const outputCardPercent = (percent: number | boolean) => {
    const prefix = getNumberSign(percent as number)
    return prefix + `${percent.toLocaleString()}%`
}

function FavouritesCard({
    changeFill,
    star,
    fill,
    src,
    price,
    percent,
    cardName,
    ticket,
    isAuth,
    isUp,
    oldPrice,
    currency,
    brand,
    isTransitionToCompanyWithoutClick,
    setIsTransitionToCompanyWithoutClick,
    postTickerClick
}: ICard) {
    const { transitionToTicket, transitionToTicketTest } = useTransition()
    const { setCurrentCompanyEnum } = UseActions()
    const { getPrice } = useFormatCurrency()
    const { setSearchIsOpen } = UseActions()
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const handleTransitionToCompanyTicker = () => {
        if (postTickerClick) {
            postTickerClick(ticket)
            return
        }
        if (!isApplication)
            setCurrentCompanyEnum(
                {
                    cardName: cardName,
                    ticket: ticket,
                    src: src,
                    price: price,
                    currency: currency,
                    percent: percent,
                    oldPrice: oldPrice,
                    brand: brand
                }
            )
        if (setIsTransitionToCompanyWithoutClick) {
            closeSearchView()
            setSearchIsOpen(false)
            setIsTransitionToCompanyWithoutClick(false)

        }
        transitionToTicketTest(ticket)
    }

    useEffect(() => {
        if (!isTransitionToCompanyWithoutClick) return
        handleTransitionToCompanyTicker()
    }, [isTransitionToCompanyWithoutClick])

    const handleTickerClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        closeSearchView()
        handleTransitionToCompanyTicker()
    }

    const linkRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            linkRef.current.classList.remove('text-hover-primary')
            starRef.current.classList.add('disableStar')
        }
    }, [])
    return (
        <div className="d-flex align-items-center mb-7 js__favourites-item">
            <a href={`${ORIGIN}/stocks/${ticket}`} onClick={handleTickerClick} className="mr-3 flex-shrink-0">
                <FavouritesImage src={src} />
            </a>
            <div className="d-flex flex-column flex-grow-1">
                <a
                    href={`${ORIGIN}/stocks/${ticket}`}
                    onClick={handleTickerClick}
                    ref={linkRef}
                    className="text-dark text-hover-primary mb-1 font-size-lg font-weight-bolder">
                    {cardName}
                </a>
                <span className="text-muted font-weight-bold font-size-md">${ticket}</span>
            </div>

            {(!star) &&
                <>
                    {
                        (price && percent)
                            ?
                            <ColorText type={isUp ? 'text-success' : 'text-danger'}>
                                <span className="font-weight-bolder d-block">
                                    {getPrice(Number(price), currency)}&nbsp;
                                </span>
                                <span className="d-block font-size-sm text-right">{outputCardPercent(percent)}</span>
                            </ColorText>

                            : <span className="">
                                <div className="">
                                    {
                                        price && <span className="font-weight-bolder d-block">{
                                            getPrice(price, currency)
                                        }</span>
                                    }
                                </div>
                            </span>
                    }
                </>
            }
            <div className={(!star) ? "favourites-button d-none" : "favourites-button"} onClick={() => {
                changeFill(ticket, src)
            }}>
                <a data-ticker="MRNA" data-status="1"
                    ref={starRef}
                    className="btn btn-icon btn-sm btn-white font-weight-bolder js__toggle-favourites">
                    <i className={(fill) ? "fas fa-star text-primary p-0" : "far fa-star text-primary p-0"}></i>
                </a>
            </div>
        </div>
    );
}

export default React.memo(FavouritesCard);