import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import InviteTextTest from "./inviteTextTest";
import useInvites, { IInvite, invitesStatusEnum } from "../../hooks/useInvites";
import {Alert, SuccessAlertWithDelete, themeAlertEnum} from "../Alerts";
import Waiting from "./Invites/Waiting";
import Over from "./Invites/Over";
import Ready from "./Invites/Ready";
import { useTranslation } from "react-i18next";

function InviteElement(props:IInvite & {numberEvent:number,setInvites:(Invite:Array<IInvite>) => void} ) {
    
    const [isOpenAlert,setIsOpenAlert] = useState<boolean>(false)

    const removeAlertHandler = () => {
        setIsOpenAlert(false)
    }

    const showAlertHandler = () => {
        setIsOpenAlert(true)
    }
    const {t} =useTranslation()

    const selectJsxForInvites = useMemo(() => {
        if(props.status === invitesStatusEnum.WAITING){ 
            return <Waiting {...{...props,numberEvent:props.numberEvent}} />
        }
        if(props.status === invitesStatusEnum.OVER){ 
            return <Over {...{...props,numberEvent:props.numberEvent}} />
        }
        if(props.status === invitesStatusEnum.READY){ 
            return <Ready {...{...props,numberEvent:props.numberEvent,showAlertHandler}} />
        }

    },[props.status,props.code,props.id,props.numberEvent])


    return (
        <>
            <Alert theme={themeAlertEnum.Success} close={removeAlertHandler} isOpen={isOpenAlert} text={t('INVITES_LINK_COPIED')}>
                <span>{t('INVITES_LINK_COPIED')}</span>
            </Alert>

        {selectJsxForInvites}
        </>
    )
}

export default InviteElement

