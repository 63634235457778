import React, { useEffect, useRef, useState } from 'react';
import TabContentImg, { WizarPictucturesEnum } from "../NotAuth/TabContentImg";
import { useTransition } from "../hooks/useTransition";
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from "react-router-dom";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { getLoginStyle, gettOrigin } from '../Utills/ClearFunctions';
import { Link } from 'react-router-dom';
import { ORIGIN } from "../models/Domen";
import { Trans, useTranslation } from 'react-i18next';

interface ITabContentImg {
    header: string
    text: string
    active: boolean
    key: number
    imgSrc: string
}

export enum WizardStepEnum {
    WIZARDSTEP2 = "wizardStep2",
    WIZARDSTEP1 = "wizardStep1",
    WIZARDSTEP3 = "wizardStep3",
}

function Wizard() {
    const { transitionToLogIn, transitionToSupport } = useTransition()
    const [step, setStep] = useState<number>(1)
    const fadeRef = useRef<any>()
    const location = useLocation()
    const navigate = useNavigate()
    const isAut = useTypedSelector(state => state.UserReducer.isAuth)
    const isCryptoPage = useTypedSelector(state => state.PageReducer.isCryptoPage)

    useEffect(() => {
        getLoginStyle()
        if (isAut) 
            navigate('/')
    }, [])
    const { t } = useTranslation()

    const getSrc = (imgName: string) => {
        //@ts-ignore
        const language = window._sharedData.config.locale.language
        const languagePrefix = language === 'ru' ? 'RU_' : 'EN_'
        const cryptoPrefix = isCryptoPage ? 'crypto_' :''
        const prefix = languagePrefix + cryptoPrefix
        
        switch (imgName) {
            case  WizardStepEnum.WIZARDSTEP2:
                return prefix +'wizardStep2'
            case WizardStepEnum.WIZARDSTEP1:
                return cryptoPrefix + 'wizardStep1'
            case WizardStepEnum.WIZARDSTEP3:
                return prefix + 'wizardStep3'    
        }
    }

    const [imgState, setImgState] = useState<Array<ITabContentImg>>(
        [
            {
                text: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE1_TEXT')}` : `${t('WIZARD_LOGIN_SLIDE1_TEXT')}`,
                header: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE1_HEADER')}` : `${t('WIZARD_LOGIN_SLIDE1_HEADER')}`,
                active: true,
                key: 1,
                imgSrc: `${getSrc('wizardStep1')}`
            },
            {
                text: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE2_TEXT')}` : `${t('WIZARD_LOGIN_SLIDE2_TEXT')}`,
                header: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE2_HEADER')}` : `${t('WIZARD_LOGIN_SLIDE2_HEADER')}`,
                active: false,
                key: 2,
                imgSrc: `${getSrc('wizardStep2')}`
            },
            {
                text: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE3_TEXT')}` : `${t('WIZARD_LOGIN_SLIDE3_TEXT')}`,
                header: isCryptoPage ? `${t('WIZARD_LOGIN_CRYPTO_SLIDE3_HEADER')}` : `${t('WIZARD_LOGIN_SLIDE3_HEADER')}`,
                active: false,
                key: 3,
                imgSrc: `${getSrc('wizardStep3')}`
            }
        ]
    )

    const transitionToStep = (key: number) => {
        setImgState(
            //@ts-ignore
            imgState.map((img) => {
                if (img.key === key) return { ...img, active: true }
                //@ts-ignore
                else return { ...img, active: false }
            })
        )
        setStep(key)
    }

    const handlerLinClick = (key: number) => {
        return () => {
            transitionToStep(key)
        }
    }

    const listLink = () => {
        return imgState.map((img) => {
            return < li key={uuidv4()} className="nav-item ml-0" onClick={handlerLinClick(img.key)}>
                <a className={
                    (img.active)
                        ? "nav-link py-1 px-2 font-weight-bolder font-size-sm active"
                        : "nav-link py-1 px-2 font-weight-bolder font-size-sm"
                }
                    data-toggle="tab" id="link_step_1" href="#ts_step_1" data-step="1"></a>
            </li>
        })
    }

    const imgElements = () => {
        for (let i = 0; i <= 3; i++) {
            if (imgState[i].active) {
                const element = <div style={{ minHeight: '412px' }}>
                    <div key={uuidv4()} ref={fadeRef} style={{ opacity: '0' }} className='fade'><TabContentImg
                        header={imgState[i].header} text={imgState[i].text}
                        active={imgState[i].active} imgSrc={imgState[i].imgSrc} />
                    </div>
                </div>

                setTimeout(() => {
                    fadeRef.current.style.opacity = '1'
                    fadeRef.current = null
                }, 200)
                return element
            }
        }
    }

    const jumpToNextStep = () => {
        if (step + 1 === 4)
            transitionToLogIn()
        else transitionToStep((step + 1) % 4)
    }

    const style = `.login-form {
        display: none;
    }
.login-wrapper.code-sent .login-form {
        display: block;
    }
.login-wrapper.code-sent .send-verification-code-form {
        display: none;
    }
.send-verification-code-form .iti {
        display: block;
    }
.sms-input {
        letter-spacing: 0.7rem;
    }
.send-code-again {
        display: none;
    }
.login-wrapper.resend-code .send-code-again {
        display: block;
    }
.login-wrapper.resend-code .send-code-after {
        display: none;
    }
.nav-pills .nav-link.active {
        background: #989898!important;
    }
.nav-pills .nav-link.active:hover {
        background: #989898!important;
    }
.nav-pills .nav-link:not(.active) {
        background: #eaeaea!important;
    }
.nav-pills .nav-link:not(.active):hover {
        background: #989898!important;
    }`

    return (
        <div className="d-flex flex-column flex-root flex-center">
            <style>
                {style}
            </style>
            <link rel="preload" href={`${ORIGIN}/img/wizard/wizard-step1.png`} as="image" />
            <link rel="preload" href={`${ORIGIN}/img/wizard/wizard-step2.png`} as="image" />
            <link rel="preload" href={`${ORIGIN}/img/wizard/wizard-step3-2.png`} as="image" />
            {/* <link rel="preload" href={SubChart} as="image" />
            <link rel="preload" href={sub_push_1} as="image" />
            <link rel="preload" href={sub_push_2} as="image" /> */}

            <div className="simple-container d-flex flex-column flex-lg-row flex-column-fluid">
                <div className="d-flex flex-column-auto flex-column pt-10 w-100">
                    <a href={`${gettOrigin()}`} className="text-center mb-5">
                        <img src={`${gettOrigin()}/img/ts-logo.svg`} alt="logo" className="h-35px" />
                    </a>
                    <h4 className="logo-subtitle font-weight-bolder text-center font-size-h5">

                        {
                            isCryptoPage ? <Trans i18nKey='WIZARD_LOGIN_CRYPTO_HEADER'> <br /> </Trans>
                                : <Trans i18nKey='WIZARD_LOGIN_HEADER'> <br /> </Trans>
                        }

                    </h4>
                    <div className="card card-custom card-shadowless bg-white mt-4">
                        <div className="card-body pt-0 pb-4">
                            {imgElements()}
                        </div>
                        <div id="tab-nav">
                            <div className="pt-2 pb-7 d-flex justify-content-center">
                                <ul className="nav nav-pills nav-pills-sm nav-dark">
                                    {listLink()}
                                </ul>
                            </div>
                            <div className="text-center px-4 ">
                                <button onClick={jumpToNextStep} type="button" id="next-button"
                                    className="btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-3">
                                    {t('WIZARD_LOGIN_NEXT')}
                                </button>
                            </div>
                            <div className="mb-10 text-center">
                                <span className="text-muted">{t('WIZARD_LOGIN_HAVE_ACCOUNT')} </span>
                                {
                                    isCryptoPage ?<Link to={'../crypto/login'}>{t('WIZARD_LOGIN_SIGNIN_LINK')} </Link>
                                        : <Link to={'../login'}>{t('WIZARD_LOGIN_SIGNIN_LINK')} </Link>

                                }
                            </div>
                        </div>
                        <div className="text-center mt-10 mb-10">
                            <a href={`${ORIGIN}/support`} onClick={transitionToSupport}>{t('WIZARD_LOGIN_SUPPORT')}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Wizard;