import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import UserService from "../../Services/UserService";
import { useParams } from "react-router-dom";
import useDate from "../../hooks/useDate";
import { CompanyContext, MessageContext } from "../../Context";
import { UseActions } from "../../hooks/useActions";
import "../../styles/MessageBody.css";
import { gettOrigin } from "../../Utills/ClearFunctions";
import "../../styles/TimeLine.css";
import { t } from "i18next";
import useTranslate from "../../hooks/useTranslate";
import { useTranslation } from "react-i18next";
import AILogo from "../../image/expalnationLogo";

function MessageBody({
  explanation,
  isClickToAI,
}: {
  explanation?: null | { text: string };
  isClickToAI?: boolean;
}) {
  const currentMessage = useTypedSelector(
    (state) => state.EventReducer.currentMessage
  );
  const [isShowTransition, showTransition] = useState<boolean>(false);
  const { id } = useParams();
  const translateEvents = useTypedSelector(
    (state) => state.UserReducer.User.settings.translateEvents
  );
  const { getCurrentDate, getMomentDate } = useDate();
  const { setApplicationMessageData } = UseActions();
  const { setCompanyName } = useContext(MessageContext);
  const [applicationData, setApplicationData] = useState<any>(null);
  const [translatedContent, setTranslatedContent] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [IsOpenAIText, setIsOpenAIText] = useState<boolean>(false);
  const { t, i18n } = useTranslation();

  useLayoutEffect(() => {
    const isShowAI = window.location.href.match("isShowAI");
    if (isClickToAI || isShowAI) setIsOpenAIText(true);
    else setIsOpenAIText(false);
  }, []);

  const replaceDate = (data: any) => {
    if (data.translatedContent) {
      const translatedContentStr = data.translatedContent.reduce(
        (acc: string, currentObj: { node: string; text: string }) => {
          return (
            acc +
            `<${currentObj.node}> ${currentObj.text} </${currentObj.node} >`
          );
        },
        ""
      );
      setTranslatedContent(translatedContentStr);
    } else setTranslatedContent("");
    if (data.content) {
      const ContentStr = data.content.reduce(
        (acc: string, currentObj: { node: string; text: string }) => {
          return (
            acc +
            `<${currentObj.node}> ${currentObj.text} </${currentObj.node} >`
          );
        },
        ""
      );
      setContent(ContentStr);
    } else setContent("");
  };

  useEffect(() => {
    document.body.classList.remove("noselect");
    const tables = document.querySelectorAll("table");
    tables.forEach((table) => {
      const typeTabled = table as HTMLTableElement;
      typeTabled.parentElement!.style.overflowX = "scroll";
    });
    return () => {
      document.body.classList.add("noselect");
    };
  }, [content, translatedContent, showTransition]);
  const [AIExplanation, setAIExplanation] = useState<{ text: string } | null>(
    null
  );

  const getApplicationData = () => {
    //@ts-ignore
    const event = window._sharedData.event;
    setAIExplanation(event.explanation);
    const time = event.createdAt;
    const title = event.data.title;
    const translatedTitle = event.data.translatedTitle;
    const url = event.data.url;
    const sourceName = url.split("/")[2];
    const [
      shortTime,
      currentDay,
      nameOfMounth,
      currentDate,
      optionalDataValue,
      fullDate,
    ] = getMomentDate(time);

    const data = {
      fullDate,
      title,
      url,
      translatedTitle,
      sourceName,
      description: event.data.description,
      translatedDescription: event.data.translatedDescription,
    };
    setCompanyName(event.stock.brand);
    setApplicationMessageData({
      url: event.stock.logo,
      ticker: event.stock.ticker,
      companyName: event.stock.brand,
    });
    setApplicationData(data);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getMessage = async () => {
    let response: any = null;
    setIsLoading(true);
    if (!currentMessage.title && !currentMessage.translatedTitle) {
      getApplicationData();
      response = await UserService.getMessageData(Number(id) as number);
    } else {
      response = await UserService.getMessageData(currentMessage.key as number);
    }
    replaceDate(response.data.news.data);
    setIsLoading(false);
  };

  useEffect(() => {
    showTransition(translateEvents);
    getMessage();
  }, [currentMessage]);

  const translateText = () => {
    showTransition(true);
  };

  const backToRussia = () => {
    showTransition(false);
  };

  const googleTranslateUILogo = useMemo(() => {
    return (
      <a href="https://cloud.google.com/translate" target="_blank">
        <img
          alt=""
          src={`${gettOrigin()}/img/powered-by-google-translate.svg`}
          className="ml-2"
          style={{ height: "15px" }}
        />
      </a>
    );
  }, []);

  const explanationButton = useMemo(() => {
    if (IsOpenAIText)
      return (
        <a
          onClick={(e) => {
            e.preventDefault();
            // setPieceOfText('')
            setIsOpenAIText(false);
          }}
          href=""
          target="_blank"
          className="btn btn-sm btn-light-primary "
        >
          {" "}
          {t("EVENT_AI_PRESSRELEASE_BACK")}
        </a>
      );
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          setIsOpenAIText(true);
        }}
        href=""
        target="_blank"
        className="btn btn-sm btn-light-primary py-1"
      >
        <AILogo/>
        {" "}
        {t("EVENT_AI_BUTTON")} 
      </a>
    );
  }, [IsOpenAIText]);

  const getExplanationInfo = () => {
    // @ts-ignore
    if (explanation || window._sharedData?.event?.explanation) {
      return (
        <>
          <>{explanationButton}</>
        </>
      );
    } else return <>{/* <> {getButton()} </> */}</>;
  };

  const getButton = () => {
    if (currentMessage.ticket !== "") {
      if (!(currentMessage?.text && currentMessage?.translatedText)) {
        return <>{getExplanationInfo()} </>;
      }
    }

    if (applicationData) {
      if (
        !(
          applicationData?.description && applicationData?.translatedDescription
        )
      ) {
        return <>{getExplanationInfo()}</>;
      }
    }

    if (isShowTransition) {
      return (
        <>
          {" "}
          <a
            onClick={backToRussia}
            className="js__translate-event event"
            href="javascript:;"
          >
            {t("EVENT_SHOW_ORIGINAL")}
          </a>{" "}
          | <> {getExplanationInfo()} </>
        </>
      );
    } else {
      return (
        <>
          {" "}
          <a
            onClick={translateText}
            className="js__original-event event"
            href="javascript:;"
          >
            {t("EVENT_SHOW_TRANSLATE")}
          </a>{" "}
          | <> {getExplanationInfo()} </>
        </>
      );
    }
  };

  const getTittle = () => {
    if (!isShowTransition) {
      return (
        <h1
          className="font-size-h1-md font-size-h2 font-weight-bolder event--translated-text"
          lang="ru-x-mtfrom-en"
          dangerouslySetInnerHTML={{
            __html: `${
              currentMessage.title ||
              currentMessage?.translatedTitle ||
              applicationData?.title ||
              applicationData?.translatedTitle
            } `,
          }}
        />
      );
    } else {
      return (
        <h1
          className="font-size-h1-md font-size-h2 font-weight-bolder event--translated-text"
          lang="ru-x-mtfrom-en"
          dangerouslySetInnerHTML={{
            __html: `${
              currentMessage.translatedTitle ||
              currentMessage?.title ||
              applicationData?.translatedTitle ||
              applicationData?.title
            } `,
          }}
        />
      );
    }
  };

  const getBody = () => {
    if (!isShowTransition) {
      return (
        <span
          style={{ wordBreak: "break-all" }}
          className="event--translated-text"
          lang="ru-x-mtfrom-en"
          dangerouslySetInnerHTML={{
            __html: `${content || translatedContent}`,
          }}
        />
      );
    } else {
      return (
        <span
          style={{ wordBreak: "break-all" }}
          className="event--translated-text"
          lang="ru-x-mtfrom-en"
          dangerouslySetInnerHTML={{
            __html: `${translatedContent || content}`,
          }}
        />
      );
    }
  };

  const [pieceOfText, setPieceOfText] = useState<string>("");

  const changeAIWord = (word: string) => {
    if (word) {
      if (word.includes("\n")) {
        const filtredWord = word.split('').map((letter) => {
          if(letter == '\n') return '<br/>'
          return letter
        }).join('')

        setPieceOfText((prev) => prev + " " + filtredWord);
      } else {
        setPieceOfText((prev) => prev + " " + word);
      }
    }
  };

  const setTimerText = (explanationWords: string[] | undefined) => {
    let i = 0;
    const timer = setInterval(() => {
      if (explanationWords) {
        if (i >= explanationWords.length) {
          if (timer) clearInterval(timer);
        }
        let word = explanationWords[i];
        if (i === 0) {
          word =
            `<div style="margin-top:15px; margin-bottom:5px"><b class="text-primary font-weight-bolder">Tradesense AI: </b> ${t(
              "EVENT_AI_PRESSRELEASE_REPLY2"
            )} <br/></div>` + word;
        }
        changeAIWord(word);
        i++;
      }
    }, 100);
    return timer;
  };

  const isOpenAIOne = useRef<boolean>(false);

  useEffect(() => {
    let timer: null | NodeJS.Timer = null;
    if (!isOpenAIOne.current && IsOpenAIText) {
      let explanationWords = AIExplanation
        ? AIExplanation?.text.split(" ")
        : explanation?.text.split(" ");
      timer = setTimerText(explanationWords);
    }

    if (IsOpenAIText) {
      if (!isOpenAIOne.current) {
        isOpenAIOne.current = true;
      }
    }
  }, [IsOpenAIText]);

  const getAItext = () => {
    return (
      <>
        <div
          className="card card-custom bg-gray-800 text-white font-size-lg d-none d-block"
          id="chatAI"
        >
          <div className="card-body p-5">
            <b className="text-primary font-weight-bolder">Tradesense AI: </b>
            <span>{t("EVENT_AI_PRESSRELEASE_REPLY1")}</span>
            <br />
            {/* <span>{pieceOfText}</span> */}
            <span dangerouslySetInnerHTML={{ __html: pieceOfText }} />{" "}
            <span className="carret" style={{ fontWeight: "300" }}>
              █
            </span>
          </div>
        </div>
        <div className="text-muted mt-2 font-size-sm">
          Created with ChatGPT (OpenAI.com) Technology
        </div>
      </>
    );
  };

  return (
    <>
      {getTittle()}
      <div className="mt-2 mb-4">
        <i
          className="fas fa-calendar-week fa-1x text-muted mr-1"
          aria-hidden="true"
        ></i>{" "}
        {currentMessage.dateFull || applicationData?.fullDate}{" "}
        {/* @ts-ignore */}
        {/* {getExplanationInfo()} */}
      </div>
      {getButton()}
      {isLoading && (
        <>
          <div style={{ height: "20px" }} />
          <div className="spinnerWrapper">
            <div className="spinner spinner-lg spinner-primary mr-15"></div>
          </div>
          <div style={{ height: "10px" }} />
        </>
      )}
      <hr />
      {IsOpenAIText ? getAItext() : <>{getBody()}</>}

      <div className={"mt-5"}>
        <a target="_blank" href={currentMessage.source || applicationData?.url}>
          {t("STOCK_PRESS_RELEASES_SOURCE")}&nbsp;
          {currentMessage.sourceName || applicationData?.sourceName}
        </a>
      </div>
    </>
  );
}

export default MessageBody;
