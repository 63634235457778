import React, {useCallback, useEffect, useRef, useState} from "react";
import MutedStockCard from "./MutedStockCard";
import useGetMutedStocks from "../../hooks/useGetMutedStocks";
import { useTranslation } from "react-i18next";

interface IMutedStockCard{
    companyName:string,
    ticker:string,
    isOpenSideMenu:boolean
}

interface IMutedStockBody{
    mutedElements:Array<IMutedStockCard>,
    clickMutedHandler:(ticker:string) => (e:React.MouseEvent<HTMLAnchorElement>) => void,
}

function MutedStockBody() {
    const {mutedElements,clickMutedHandler,closeAllMenu} = useGetMutedStocks()
    const isFirst = useRef<boolean>(false)
    const isAddEventListener = useRef<boolean>(false)
    const deleteAllMuteMenu = (e:any) => {
        const htmlElement = e.target as HTMLElement
        if(!htmlElement.dataset.dropdown) {
            closeAllMenu()
        }
    }

    useEffect(() => {
        if(!isFirst.current ){
            isFirst.current = true
            return
        }
        if(isAddEventListener.current){
            return
        }
        const rootElement = document.getElementById('root')
        const body = document.querySelector('body')

        rootElement?.addEventListener('click', deleteAllMuteMenu )
        body?.addEventListener('click', deleteAllMuteMenu )
        isAddEventListener.current = true
        return () => {
            rootElement?.removeEventListener('click', deleteAllMuteMenu)
        }



    },[mutedElements] )
    const MutedElementsJsx =  mutedElements.map((Element) => <MutedStockCard {...{...Element,clickMutedHandler}}/> )
    const {t} = useTranslation()

    return (
        <>
            <p className="mb-4">
                {/* {
                    t('PROFILE_MUTED_STOCKS_LIST')
                } */}
                {t('PROFILE_MUTED_STOCKS_TEXT')}
                {/* Список акций от которых вы временно или навсегда выключили все уведомления.
                Чтобы остановить уведомления от конкретной акции, откройте её страницу и найдите иконку */}
                &nbsp;<i className="fa-fw fas fa-volume-up text-primary"></i></p>
                <div onClick={e => e.stopPropagation()} >
                    {MutedElementsJsx}
                </div>
                {
                    MutedElementsJsx.length === 0 && <span> {t('PROFILE_MUTED_STOCKS_LIST_EMPTY')} </span>
                }
        </>
    )
}

export default MutedStockBody