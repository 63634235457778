import {useTransition} from "../../hooks/useTransition";
import {useLocation} from "react-router-dom";
import {UseActions} from "../../hooks/useActions";
import React, {useEffect, useRef, useState} from "react";
import {FilterConstant} from "../../models/FilterEvent";
import CommentsModal from "../Modals/CommentsModal";
import {ICompanyInformationCard} from "../Cards/CompanyInformationCard";
import CommentCard from "../Cards/CommentCard";
import { Tooltip,OverlayTrigger } from "react-bootstrap";
import { t } from "i18next";
import { Trans } from "react-i18next";

interface ITimeLineEarly {
    isUp: boolean,
    price: number
    isCommentOpen: boolean
    CardInformation:ICompanyInformationCard
    id?:string,
    countComments?:number,
    fullDateTootip?:string,
    time:string,
}

function TimeLineEarly(props: ITimeLineEarly) {
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent} = UseActions()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const refProps = useRef<any>(props)

    useEffect(() => {
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])

    const renderTooltip = (props: any) =>
    (
        <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
            <div className="overlay">
                { props.header }
                {/* {'21 февр. 2022 г., 14:04:09' + props.time + refProps.current.dateFull }  */}
                {/* {refProps.current.dateFull +', ' +  refProps.current.time }  */}
                {refProps.current.fullDateTootip}
                </div>
        </Tooltip>
    );
    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type: FilterConstant.YEARLY_MINIMUM_MAXIMUM,
                    data: {...props, isCommentOpen: false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }


    return (
        <>
            <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.YEARLY_MINIMUM_MAXIMUM}
                           data={{...{...props, isCommentOpen: false}}}/>
            <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">

                <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <div data-tip={'s'}
                         className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1">{props.time}
                    </div>
                </OverlayTrigger>

                {/* <div className="timeline-label font-weight-bolder text-muted font-size-lg">
                    <span title="" data-toggle="tooltip" data-original-title="24 февр. 2022 г., 07:30:34">07:30</span>
                </div> */}
                <div className="timeline-badge">

                    {
                        props.isUp
                            ? <i className="fas fa-angle-double-up text-success mr-1"></i>
                            : <i className="fas fa-angle-double-down text-danger"></i>
                    }


                </div>
                <div className="font-size-md timeline-content pl-3">
                    <div className="">
                        {
                            props.isUp
                                ? <>
                                    <b>{props.price}&nbsp;$ </b>
                                    
                                    <Trans i18nKey={'EVENT_PRICE_52HIGH'}>
                                        <b></b>
                                    </Trans>
                                   
                                    
                                </>
                                : <>
                                    <b>{props.price}&nbsp;$ </b>
                                    
                                    <Trans i18nKey={'EVENT_PRICE_52LOW'}>
                                        <b></b>
                                    </Trans>
                                    
                                </>
                        }
                    </div>
                    <div className="d-flex align-items-center">
                        <CommentCard countComments={props.countComments} isCommentOpen={isCommentOpen || props.isCommentOpen} callback={setShow} show={show} id={props.id} />

                        {/*{*/}
                        {/*    (isCommentOpen || props.isCommentOpen) && <a*/}
                        {/*        onClick={showModal} className="ml-auto mt-1 font-size-sm text-primary"*/}
                        {/*        href="" data-toggle="modal"*/}
                        {/*        data-event-id="332244" data-pjax="0"><span*/}
                        {/*        className="far fa-comments"></span></a>*/}
                        {/*}*/}


                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeLineEarly