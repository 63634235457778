import {
    currentCompany,
    CurrentCompanyEnum,
    SetCurrentCompanyEnum,
    SetEmptyFlag
} from './types'


export const TickerInformationActionCreators = {
    setCurrentCompanyEnum: (CurrentCompany:currentCompany): SetCurrentCompanyEnum => {
        localStorage.setItem('CurrentCompany', JSON.stringify(CurrentCompany) )
        return {
                type: CurrentCompanyEnum.SET_CURRENT_COMPANY,
                payload:CurrentCompany,
            }
    },
    setEmptyFlag:(flag:boolean):SetEmptyFlag => {
        return { type:CurrentCompanyEnum.SET_EMPTY_FLAG, payload:flag}
    },


}