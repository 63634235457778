import { IUserSelectedFavourites } from "../../hooks/useSelectCards";
import { GroupEnum } from "../../models/Search";
import { IGroups } from "../../page/Stoсk";
import { IFavouriteCard } from "../../redux/reducers/FavouritesCard/types";
import UserService from "../../Services/UserService";
import { CardArrayStrategy } from "./CardStrategy";
import { FavouritesQuery } from "./FavoritesQuery"

interface IDatarequestProps {
    group: GroupEnum | '',
    query: string | '',
    setFavouriesCards: (param:Array<IUserSelectedFavourites>) => void,
    favouaritesCard?: IFavouriteCard[],
    setGroupsData?: (value: Array<IGroups>) => void,
}

export class GroupQuery {

    static async replaceResponse(stockResponse: any, setFavouriesCards: any, favouaritesCard?: IFavouriteCard[]) {
        const objFavorites = {
            star: true,
            fill: false,
        }
        const favourites = await FavouritesQuery.getData(favouaritesCard)
        if (setFavouriesCards) {
            const favoutitesState = favourites.map((element: any) => {
                return { src: element.src }
            })
            setFavouriesCards(favoutitesState)
        }
        const rawState = CardArrayStrategy.transformDataCard(stockResponse)
        const queryData = rawState.map((cardObj: any, index: number) => {
            // debugger
            if(cardObj.isCrypto){
                if(cardObj.price < 0.000001)
                    cardObj.price = null
            }
            else{
                if(cardObj.price < 0.1)
                    cardObj.price = null
            }
            const resultArray = favourites.filter((favouritesObj: any) => {
                return cardObj.ticket === favouritesObj.ticket && favouritesObj.price !== 0
            })

            if (resultArray.length !== 0)
                return { ...{ star: true, fill: true }, ...cardObj }

            return { ...objFavorites, ...cardObj }
        }
        )
        return queryData
    }

    static query(group: GroupEnum | '', query: string | '') {
        return UserService.makeQueryWithGroup(group, query)
    }

    static async getData(
        {
            group,
            query,
            setFavouriesCards,
            favouaritesCard,
            setGroupsData,
        }: IDatarequestProps
    ) {
        const getGroupDataFromServer = (response:any) => {
            return response?.data?.groups.map((serverGroupObject: any, index:any) => {
                const isActive = index === 0 && true 
                return {
                    text: serverGroupObject.name,
                    id:serverGroupObject.index,
                    active:isActive
                }
            })
        }
        const response = await this.query(group, query)
        if (setGroupsData)
            setGroupsData(getGroupDataFromServer(response))
        return this.replaceResponse(response?.data?.stocks, setFavouriesCards, favouaritesCard)
    }
}