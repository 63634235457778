import React, { useContext, useEffect, useRef } from "react";
import { TickerContext } from "../../Context";
import { DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";

interface InvestApp {
    investApp: string,
}

function InvestApp() {
    const { investApp } = useContext(TickerContext)
    const starRef = useRef<HTMLAnchorElement | null>(null)
    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            if(investApp){
                if(!starRef.current) return
                starRef.current.classList.add('disableStar')
            }
        }
    }, [investApp])

    return (
        <>
            {
                investApp && <a href={investApp as string}
                    ref={starRef}
                    target="_blank"
                    className="btn btn-icon btn-sm btn-white font-weight-bolder" rel="noreferrer">
                    <i className="fas fa-university text-primary p-0"></i>
                </a>
            }
        </>

    )
}

export default InvestApp