import React from "react";
import {useTransition} from "../../../hooks/useTransition";
import {useCheckForPage} from "../../../hooks/useCheckForPage";
import { gettOrigin } from "../../../Utills/ClearFunctions";
import { t } from "i18next";
import { useTypedSelector } from "../../../hooks/useTypedSelector";

interface IDesktopSideBarLink{
    closeAllMenuItems: () => void,
}
function DesktopSideBarLinkOnMainPage({closeAllMenuItems}:IDesktopSideBarLink) {
    const {
        companyTransition,
        eventTransitionFromSideBar
    } = useTransition()

    const {
        checkForEventPage,
        checkForStockPageSideBar,
    } = useCheckForPage()

    const isCrypto = useTypedSelector(state => state.UserReducer.User.isCrypto)
    
    return (
        <>
            <li
                className={  checkForEventPage()  ? "menu-item menu-item-active" : "menu-item"}
                aria-haspopup="true">
                <a href={`${gettOrigin()}/events/favourites`} onClick={(e) => {
                    closeAllMenuItems();
                    eventTransitionFromSideBar(e)
                }} className="menu-link btn btn-link">
                    <span className="menu-text">{t('MENU_EVENTS')}</span>
                </a>
            </li>
            <li className={ checkForStockPageSideBar()  ? "menu-item menu-item-active" : "menu-item"}
                data-menu-toggle="click" aria-haspopup="true">
                <a href={`${gettOrigin()}/stocks`} onClick={(e) => {
                    closeAllMenuItems();
                    companyTransition(e)
                }} className="menu-link btn btn-link">
                    <span className="menu-text">

                        {
                            !isCrypto
                                ? <> {t('MENU_STOCKS')} </>
                                : <> { t('MENU_CRYPTO') } </> 
                        }
                        
                    </span>
                </a>
            </li>
        </>
    )
}

export const MemoDesktopSideBarLinkOnMainPage = React.memo(DesktopSideBarLinkOnMainPage)
interface IProfileBackButton{
    handleBackButton:() => void
}

function  ProfileBackButton({handleBackButton}:IProfileBackButton) {
    return <div style={{cursor: "pointer"}} onClick={handleBackButton} className='svgBackContainer'>
        <i className="fas fa-chevron-left text-primary mr-4 d-block h-auto svgBack"></i>
    </div>
}

export const MemoProfileBackButton = React.memo(ProfileBackButton)

