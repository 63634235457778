import React, {useCallback, useContext, useEffect, useRef, useState} from 'react';
import "../../styles/eventCompamyVidget.css"
import TrendLine from "../Time/TrendLine";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import { scrollHandlerDropDown, SubscriptionIsOver,} from "../../Utills/ClearFunctions";
import SingleEventFree from "../Free/SingleEventFree";
import useCardGenerator from '../../hooks/useCardGenerator';
import {EventTopicEnum} from '../../models/EventContentModel';
import SubscriptionExpirationEventPageAlert from "../Free/SubscriptionExpirationEventPageAlert";
import DropDownWrapper from '../Buttons/DropDownWrapper'

function CompanyEventContent() {
    const companyObj = useTypedSelector(state => state.CompanyReducer)
    const {elements,hiddenCounts} = useCardGenerator({CardContainerName:EventTopicEnum.TICKER_EVENTS,ticker:companyObj.ticker})
    let initialScrollY: number = 0
    const prevInitialScroll = useRef<null | number>(null)

    function scrollHandler() {
        let target = document.querySelector('.dropDown') as HTMLDivElement;
        if (!target) {
            target = document.querySelector('.trendButton') as HTMLDivElement;
        }
        if(!prevInitialScroll.current){
            if(prevInitialScroll.current !== target.getBoundingClientRect().y){
                initialScrollY = target.getBoundingClientRect().y
                prevInitialScroll.current = initialScrollY
            }
        }
        if(initialScrollY === 0){
            initialScrollY = target.getBoundingClientRect().y
            prevInitialScroll.current = initialScrollY
        }
        scrollHandlerDropDown(initialScrollY)
    }
    const changeDropDownClass = useCallback(scrollHandler, [])

    useEffect(() => {
        window.scrollTo(0, 0)
        let target = document.querySelector('.dropDown') as HTMLDivElement;
        if (!target) {
            target = document.querySelector('.trendButton') as HTMLDivElement;
        }

        document.addEventListener('scroll', changeDropDownClass)
        return () => {
            document.removeEventListener('scroll', changeDropDownClass)
        }
    }, [])

    return (
        <div className='contentEvent'>
            <div className='cardContainer'>
                <div className='card card-custom p-4'>
                    <div className='cardContainerCompany'>
                        <SubscriptionExpirationEventPageAlert/>
                        <div className='lineEvent'>
                            <TrendLine currentTopic={EventTopicEnum.TICKER_EVENTS}/>
                        </div>
                        <DropDownWrapper/>
                        <hr/>
                        <SingleEventFree hiddenCounts={hiddenCounts}/>
                        {elements}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default React.memo(CompanyEventContent)