import React, { useMemo } from "react"
import Footer from "./Footer"

interface IDesctopSubscription{
    sku:string | undefined,
    buttonText?:string
}
function DesctopSubscription({sku,buttonText}:IDesctopSubscription){
    const chooseRightFooter = useMemo(() => {
        if (sku === 'ts_monthly_trial') {
            return <Footer buttonText={buttonText || 'Оплатить'} text={'Оплата происходит через ООО НКО «ЮМани». Лицензия Банка России № 3510‑К. При оформлении' +
                ' пробного периода вы соглашаетесь с тем, что после окончания предоставленного периода подписка (с дальнейшим автопродлением)' +
                ' автоматически будет продлена на 1 месяц и по стоимости 1790 руб./месяц. Вы всегда можете отключить ' +
                'автоматическое продление подписки в настройках личного кабинета.'} />
        }

        else return <Footer buttonText={buttonText || 'Оплатить'} text={'Оплата происходит через ООО НКО «ЮМани». Лицензия Банка России № 3510‑К. При оформлении' +
            ' подписки вы соглашаетесь с тем, что после окончания предоставленного периода подписка (с дальнейшим автопродлением) ' +
            'автоматически будет продлена на период и по стоимости согласно выбранному тарифу. Вы всегда можете отключить' +
            ' автоматическое продление подписки в настройках личного кабинета.'} />
    }, [buttonText, sku])

    return <>
        {chooseRightFooter}
    </>
}
export default DesctopSubscription