import Wizard from "../page/Wizard";
import Login from "../page/Login";
import Support from "../page/Support";
import Waiting from "../page/Waiting";
import SelectFavourites from "../page/SelectFavourites";
import Layout from "../page/Layout";
import Events from "../components/Events/Events";
import ProSubscription from "../components/Subscription/Subscriptions";
import Trends from "../components/Trends";
import AllStocks from "../components/AllStocks";
import FavouritesEvent from "../components/Events/FavouritesEvent";
import StocK from "../page/Stoсk";
import Ticker from "../page/Ticker";
import TickerInformation from "../page/TickerInformation";
import CompanyEvents from "../components/Events/CompanyEvents";
import Company from "../page/Company";
import LongerSubscription from "../page/LongerSubscription";
import CancelAutoPayment from "../page/CancelAutoPayment";
import MenageSubscription from "../page/MenageSubscription";
import UserMainInfoWrapper from "../page/UserMainInfoWrapper";
import React from "react";
import Profile from "../page/Profile";

const  Notifications = React.lazy(() =>  import('../page/Notifications'))  
const  Favourites = React.lazy(() =>  import('../page/ContentFavourites'))
const  Reports = React.lazy(() =>  import('../page/Reports'))  
const  Forecasts = React.lazy(() =>  import('../page/Forecasts'))  
const  PressReleases = React.lazy(() =>  import('../page/PressReleases'))  
const  MessagePage = React.lazy(() =>  import('../page/MessagePage'))  
const  Comments = React.lazy(() =>  import('../page/Comment'))  
const  Invites = React.lazy(() =>  import('../page/Invites'))  
const  MutedStocks = React.lazy(() =>  import('../page/mutedStocks'))
const  Post = React.lazy(() =>  import('../page/Post'))
const  Movers = React.lazy(() =>  import('../page/Movers'))
const  MarketMap = React.lazy(() =>  import('../page/MarketMap'))
const IFrameChart = React.lazy(() => import('../page/IFrameChart')) 

export interface IRoute {
    path?: string,
    index: boolean,
    element: React.ComponentType,
    subPaths: Array<IRoute>
}

export const privateRouts: Array<IRoute> = [
    {
        path: '/',
        element: Layout,
        index: false,
        subPaths: [
            {
                element: Events,
                index: true,
                subPaths: [],
            },
            {
                path: 'profile/user',
                element:UserMainInfoWrapper,
                index:false,
                subPaths:[]
            },
            {
                path: 'profile',
                element:Profile,
                index:false,
                subPaths:[],    
            },
            {
                path: 'profile/notifications',
                element:Notifications ,
                index:false,
                subPaths:[],
            },
            {
                path: 'profile/muted-stocks',
                element:MutedStocks ,
                index:false,
                subPaths:[],
            },
            {
                path: 'finance/subscription',
                element:ProSubscription ,
                index:false,
                subPaths:[],
            },
            {
                path: 'favourites',
                element:Favourites ,
                index:false,
                subPaths:[],
            },
            {
                path: 'events/comments',
                element:Comments,
                index:false,
                subPaths:[],
            },
            {
                path: 'events',
                element:Events,
                index:false,
                subPaths:[
                    {
                        path: 'trends',
                        element:Trends,
                        index:false,
                        subPaths:[]
                    },
                    {
                        path: 'all',
                        element:AllStocks,
                        index:false,
                        subPaths:[]
                    },
                    {
                        path: 'favourites',
                        element: FavouritesEvent,
                        index: false,
                        subPaths: []
                    }
                    ,
                    {
                        path: 'comments',
                        element:Comments,
                        index:false,
                        subPaths:[]
                    }
                ],

            },
            {
                path: 'events/comments',
                element:Comments,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks',
                // @ts-ignore
                element:StocK,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/market-map/:id',
                // @ts-ignore
                element:MarketMap,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/movers',
                // @ts-ignore
                element:Movers,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/:id',
                // @ts-ignore
                element:Ticker,
                index:false,
                subPaths:[
                    {
                        element:TickerInformation,
                        index:true,
                        subPaths:[],
                    },
                    {
                        path: 'events',
                        element:CompanyEvents,
                        index:false,
                        subPaths:[],
                    },
                    {
                        path: 'company',
                        element:Company,
                        index:false,
                        subPaths:[],
                    }
                ],
            },
            {
                path: 'stocks/:id/press-releases',
                element:PressReleases,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/:id/reports',
                element:Reports,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/:id/forecasts',
                element:Forecasts,
                index:false,
                subPaths:[],
            },
            {
                path: 'stocks/SAVE/read-news/:id',
                element:MessagePage,
                index:false,
                subPaths:[],
            },
            {
                path: 'coins',
                // @ts-ignore
                element:StocK,
                index:false,
                subPaths:[],
            },
            {
                path: 'coins/:id',
                // @ts-ignore
                element:Ticker,
                index:false,
                subPaths:[
                    {
                        element:TickerInformation,
                        index:true,
                        subPaths:[],
                    },
                    {
                        path: 'events',
                        element:CompanyEvents,
                        index:false,
                        subPaths:[],
                    },
                    {
                        path: 'company',
                        element:Company,
                        index:false,
                        subPaths:[],
                    }
                ],
            },
            {
                path: 'coins/:id/press-releases',
                element:PressReleases,
                index:false,
                subPaths:[],
            },
            {
                path: 'coins/:id/reports',
                element:Reports,
                index:false,
                subPaths:[],
            },
            {
                path: 'coins/:id/forecasts',
                element:Forecasts,
                index:false,
                subPaths:[],
            },
            {
                path: 'coins/SAVE/read-news/:id',
                element:MessagePage,
                index:false,
                subPaths:[],
            },
            {
                path: 'posts',
                element:Post,
                index:false,
                subPaths:[],
            },
            {
                path: 'finance/prolonged-subscription',
                element:LongerSubscription,
                index:false,
                subPaths:[],
            },
            {
                path: 'finance/cancel-auto-payment',
                element:CancelAutoPayment,
                index:false,
                subPaths:[],
            },
            {
                path: 'profile/subscription',
                element:MenageSubscription,
                index:false,
                subPaths:[],
            },
            {
                path: 'profile/invites',
                element:Invites,
                index:false,
                subPaths:[],
            }
        ]
    },
    {
        path: 'select-favourites',
        index: false,
        element: SelectFavourites,
        subPaths: []
    },
    {
        path: 'wizard',
        index: false,
        element: Wizard,
        subPaths: []
    },
]

export const WaitingRouts:Array<IRoute> = [
    {
        path: '/',
        // @ts-ignore
        element: Waiting,
        index: false,
        subPaths: [
            {
                element: Waiting,
                index: true,
                subPaths: [],
            },
            {
                path: '/waiting',
                element:Waiting,
                index:false,
                subPaths:[],
            },
        ]
    }
]

export const SelectFavouritesRouts:Array<IRoute> = [
    {
        path: '/',
        // @ts-ignore
        element: SelectFavourites,
        index: false,
        subPaths: [
            {
                element: SelectFavourites,
                index: true,
                subPaths: [],
            },
            {
                path: 'select-favourites',
                element:SelectFavourites,
                index:false,
                subPaths:[],
            },
        ]
    }
]

export const PublicRouts: Array<IRoute> = [
            {
                path: 'events',
                element:Events,
                index:false,
                subPaths:[
                    {
                        path: 'trends',
                        element:Trends,
                        index:false,
                        subPaths:[]
                    },
                    {
                        path: 'all',
                        element:AllStocks,
                        index:false,
                        subPaths:[]
                    },
                    {
                        path: 'favourites',
                        element:Favourites ,
                        index:false,
                        subPaths:[],
                    },
                    {
                        path: 'comments',
                        element:Comments,
                        index:false,
                        subPaths:[]
                    }
                ],

            },
            {
                path: 'chart/:id/:time',
                element:IFrameChart,
                index:false,
                subPaths:[]
            },
            {
                path: 'profile',
                element:Profile ,
                index:false,
                subPaths:[],
            },
            {
                path:'/crypto',
                element:Wizard,
                index:false,
                subPaths:[],
            },
            {
                path: '/',
                index: false,
                element: Wizard,
                subPaths: []
            },

            {
                path: 'wizard',
                index: false,
                element: Wizard,
                subPaths: []
            },
            {
                path: '/crypto/login',
                index: false,
                element: Login,
                subPaths: []
            },
            {
                path: 'login',
                index: false,
                element: Login,
                subPaths: []
            },
            {
                path: 'support',
                index: false,
                element: Support,
                subPaths: []
            },
]
