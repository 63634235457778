import {useTransition} from "../../../hooks/useTransition"
import {useTypedSelector} from "../../../hooks/useTypedSelector";
import {DeviceIsMobile, gettOrigin, isCloseAnchor} from "../../../Utills/ClearFunctions";
import {useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import {UseActions} from "../../../hooks/useActions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {t} from "i18next";

interface IMobileNotStockHeader {
    isShowBackButton: boolean,
    handleBackProfile: (e: React.MouseEvent<HTMLAnchorElement>) => void,
    isUserProfile: boolean
}

function MobileNotStockHeader({isShowBackButton, handleBackProfile, isUserProfile}: IMobileNotStockHeader) {
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const speciaProfileBackCase = useTypedSelector(state => state.EventReducer.speciaProfileBackCase)
    const BackDropisOpen = useTypedSelector(state => state.BackDropReducer.BackDropisOpen)
    const {setBackDropIsOpen} = UseActions()
    const MySwal = withReactContent(Swal)
    const {setSpecialProfileBackCase} = UseActions()
    const {
        eventTransition,
    } = useTransition()

    const closeBackDrop = () => {
        setBackDropIsOpen(false)
    }
    const handleBackToComment = (e: React.MouseEvent<HTMLDivElement>) => {
        setSpecialProfileBackCase(false)
        window.history.back()
    }
    const isChanged = useTypedSelector(state => state.Profile.isChanged)

    const handleProfileUserBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (isChanged) {
            MySwal.fire({
                title: <p>{t('PROFILE_SAVE_PROMPT')}</p>,
                showConfirmButton: false,
                showDenyButton: true,
                showCancelButton: true,
                denyButtonText: `Да`,
                cancelButtonText: `Нет`
            }).then((result) => {
                if (result.isDenied) {
                    window.location.href = '#close'
                }
            })
        } else {
            window.location.href = '#close'
        }
    }

    const chooseClickHandler = (): (e: React.MouseEvent<HTMLAnchorElement>) => void => {
        if (isShowBackButton) return handleBackProfile
        if (isUserProfile) return handleProfileUserBack
        else return eventTransition
    }

    return (
        <div className="topbar-item header__logoMobileContainer">
            <a href={`${gettOrigin()}`} onClick={chooseClickHandler()}
               className={isShowBackButton || isUserProfile ? 'logo  mobileBrend' : 'logo mt-n2 mobileBrend'}>
                {
                    (isShowBackButton || isUserProfile && DeviceIsMobile(userAgent))
                        ? <div style={{cursor: "pointer"}} className='svgBackContainer'>
                            <i className="fas fa-chevron-left text-primary mr-4 d-block h-auto svgBack"></i>
                        </div>
                        : <></>
                }

                {
                    speciaProfileBackCase ? <>
                            <div onClick={handleBackToComment} style={{cursor: "pointer"}} className='svgBackContainer'>
                                <i className="fas fa-chevron-left text-primary mr-4 d-block h-auto svgBack"></i>
                            </div>
                            <img
                                style={{
                                    position: 'relative',
                                    top: '2px'
                                }}
                                src={`${gettOrigin()}/img/ts-logo.svg`}
                                alt="logo" className="h-20px"
                            />
                        </>
                        : <>
                            <img
                                src={`${gettOrigin()}/img/ts-logo.svg`}
                                alt="logo" className="h-20px"
                            />
                        </>
                }
            </a>
        </div>
    )
}

export default MobileNotStockHeader