import {ReactNode} from "react";

export interface IBackDropState{
    BackDropisOpen:boolean
    header:string
    children?: ReactNode | undefined
}

export enum BackDropStateEnum{
    SET_OPEN= "SET_OPEN",
    SET_CHILDREN = "SET_CHILDREN",
    SET_HEADER = "SET_HEADER",
}

export interface ISetBackDropHeader{
    type:BackDropStateEnum.SET_HEADER,
    payload:string
}

export interface setOpen{
    type: BackDropStateEnum.SET_OPEN,
    payload: boolean
}

export interface ISetChildren{
    type: BackDropStateEnum.SET_CHILDREN,
    payload: ReactNode | undefined,
}

export type actionType = setOpen | ISetChildren | ISetBackDropHeader