import React from "react"
import {useTypedSelector} from "./useTypedSelector"
import {DeviceTypeEnum} from "../models/Subscriptions"
import RU_AppStore from '../image/RU_AppStore.png'
import EN_AppStore from '../image/EN_AppStore.png'
import EN_GooglePlay from '../image/EN_GooglePlay.png'
import RU_GooglePlay from '../image/RU_GooglePlay.png'
import '../styles/InstalModal.css'

interface IuseGetStoreImg{
    isModalBrowser:boolean
}
function useGetStoreImg({isModalBrowser}:IuseGetStoreImg) {
    const language = useTypedSelector(state => state.UserReducer.User.settings.language)
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)

    const getWebBanner = (androidHref?:string) => {
        if(androidHref) return 'web_banner_plans'
        if(isModalBrowser){
            return 'web_banner'
        }
        else{
            return 'web_banner_footer'
        }
    }

    const getImg = (androidHref?:string, appleHref?:string) => {

        if(language === 'ru'){
            if(userAgent === DeviceTypeEnum.ANDROID) return <>
                <a  href={`https://play.google.com/store/apps/details?id=com.Tradesense.TS&utm_campaign=${getWebBanner()}`}
                    target="_blank">
                    <img className="instalModalImage" src={RU_GooglePlay}/>
                </a>
            </>
            if(userAgent === DeviceTypeEnum.APPLE || userAgent === DeviceTypeEnum.IPAD) return <>
             <a  href={`https://apps.apple.com/app/apple-store/id1583932120?ct=${getWebBanner()}`}
                    target="_blank">
                    <img className="instalModalImage" src={RU_AppStore}/>
                </a>
            </>
            if(userAgent === DeviceTypeEnum.DESKTOP) return <>
                <a  
                href={ `https://play.google.com/store/apps/details?id=com.Tradesense.TS&utm_campaign=${getWebBanner(androidHref)}`}
                    target="_blank">
                    <img className="instalModalImage" src={RU_GooglePlay}/>
                </a>

                <a  href={`https://apps.apple.com/app/apple-store/id1583932120?ct=${getWebBanner(androidHref)}`}
                    target="_blank">
                    <img className="instalModalImage" src={RU_AppStore}/>
                </a>
            </>
        }
        else{
            if(userAgent === DeviceTypeEnum.ANDROID) return <>
                <a  href={`https://play.google.com/store/apps/details?id=com.Tradesense.TS&utm_campaign=${getWebBanner()}`}
                        target="_blank">
                        <img className="instalModalImage" src={EN_GooglePlay}/>
                    </a>
                </>
            if(userAgent === DeviceTypeEnum.APPLE || userAgent === DeviceTypeEnum.IPAD) return <>
             <a  href={`https://apps.apple.com/app/apple-store/id1583932120?ct=${getWebBanner()}`}
                        target="_blank">
                        <img className="instalModalImage" src={EN_AppStore}/>
                    </a>
            </>

            if(userAgent === DeviceTypeEnum.DESKTOP) return <>
                <a  href={
                    `https://play.google.com/store/apps/details?id=com.Tradesense.TS&utm_campaign=${getWebBanner(androidHref)}`}
                    target="_blank">
                    <img className="instalModalImage" src={EN_GooglePlay}/>
                </a>

                <a  href={`https://apps.apple.com/app/apple-store/id1583932120?ct=${getWebBanner(androidHref)}`}
                    target="_blank">
                    <img className="instalModalImage" src={EN_AppStore}/>
                </a>
            </>
        }        
    }
    return {getImg}
}


export default useGetStoreImg