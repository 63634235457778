import { t } from 'i18next';
import React from 'react';
import useGetStoreImg from '../../hooks/useGetStoreImg';
import { ORIGIN } from '../../models/Domen';
import { gettOrigin } from '../../Utills/ClearFunctions';


function Footer()  {
    const {getImg} = useGetStoreImg({isModalBrowser:false})


    return (
        
        <div className="footer bg-white py-4 d-flex flex-lg-column border-top" id="kt_footer">
            <div
                className="container d-flex flex-column-reverse flex-md-row align-items-center justify-content-between">
                <div className="d-flex flex-column mt-3 mt-lg-0">
                    © LLC TradeSense 2022
                </div>

                <div className="d-flex gap-2 flex-column flex-lg-row align-items-center">
                    <div className="font-size-xs text-muted mb-2 mb-lg-0">
                        {t('FOOTER_MOBILE_APP')} Tradesense
                    </div>
                   
                    { getImg() }
                    {/* <a href="https://itunes.apple.com/app/apple-store/id1583932120?mt=8&amp;ct=web_banner_footer"
                       target="_blank" className="ml-lg-3 mb-2 mb-lg-0">
                        <img src={`${gettOrigin()}/img/download-on-the-app-store-ru.svg`} className="w-100px"/>
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.Tradesense.TS&amp;utm_campaign=web_banner_footer"
                       target="_blank" className="ml-lg-3"><img src={`${gettOrigin()}/img/google-play-badge.png`}
                                                                className="w-130px"/>
                    </a> */}
                </div>
            </div>
        </div>
    );
}

export default Footer