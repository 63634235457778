import {
    BackDropStateEnum,
    setOpen,
    ISetChildren,
    ISetBackDropHeader
} from './types'
import {ReactNode} from "react";

export const BackDropActionCreators = {
    setBackDropIsOpen:(payload:boolean):setOpen => {
        return{
            type:BackDropStateEnum.SET_OPEN,
            payload:payload
        }
    },
    setChildren:(payload: ReactNode | undefined):ISetChildren => {
        return{
            type:BackDropStateEnum.SET_CHILDREN,
            payload:payload
        }
    },
    setBackDropHeader:(payload: string):ISetBackDropHeader => {
        return{
            type:BackDropStateEnum.SET_HEADER,
            payload:payload
        }
    }
}