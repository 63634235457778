import { MutableRefObject } from "react";
import { IspecialTextBounding, specialTextBoundingType } from "../../components/Kline";
import { DeviceIsDesctop, deviceIsMobile } from "../../Utills/ClearFunctions";
import {PaintRoundRectangle, paintText} from "./paintRoundRectangle";
import i18n from 'i18next';

export const drawTooltip = (
        ctx:any,
        element:any,
        isApple:boolean,
        specialTextBounding?:MutableRefObject<specialTextBoundingType>) => {
    const textArrayTest = element.textList
    const headerArray = element.headerArray
    let tempWidth = 0
    let countRows = 0
    let headerSizeFont = 13
    let textSizeFont = 13
    let headerColor = '#C6C6C6'
    const linkColor ='#44a1f8'
    let textColor = '#BEBEBE'
    let font = 'Open Sans'
    let gap = 4
    let paddingX = 20
    let headerGap = 5
    let w = 250
    ctx.font = `${headerSizeFont}px ${font}`
    w = ctx.measureText(headerArray[0]).width + 2 * paddingX
    let startY = element.top - 5

    const getSpecialText = () => {
        if(element.events.length > 1)
            return `${i18n.t('STOCK_CHART_TOOLTIP_MORE',{count:element.events.length })}`
        else return ''
    }

    let specilalText = ''
    let isUserSeeMessage = false
    if(DeviceIsDesctop()){
        if(localStorage.getItem('isUserSeeMessage')){
            specilalText = getSpecialText()
        }
        else{
            specilalText = `${i18n.t('STOCK_CHART_TOOLTIP_CLICK_PIN')}`
            isUserSeeMessage = true
        }
    }
    else{
        specilalText = getSpecialText()
    }

    let isMobileDivice = false
    const canvasWidth = ctx.canvas.clientWidth
    const canvasHeight = ctx.canvas.clientHeight
    if(textArrayTest.join(' ').split(' ').length >= 10 || isUserSeeMessage ) {
        //fix
        if(window.navigator.userAgent.includes('Android') || window.navigator.userAgent.includes('iPhone')){
            w =  canvasWidth - 30
        }
        else{
            w =  canvasWidth - 30
        }
    }
    if(window.navigator.userAgent.includes('Android') || window.navigator.userAgent.includes('iPhone')){
         isMobileDivice = true
         headerSizeFont = 13
         textSizeFont = 12
         headerColor = '#C6C6C6'
         textColor = '#BEBEBE'
         font = 'Open Sans'
         gap = 2
         paddingX = 10
         headerGap = 5
         ctx.font = `${headerSizeFont}px ${font}`

         if(textArrayTest.join(' ').split(' ').length >= 10)
            w =  canvasWidth - 30
         else
            w = ctx.measureText(headerArray[0]).width + 2 * paddingX + 2 * paddingX
    } 
    let arrayRows:Array<any> = []
    const comparableWidth = w
    let tempWord = ''
    textArrayTest.forEach((text:any) => {
        const textArray:Array<string> = text.split(' ')
        const rowList:any = []
        const rowWidth:Array<number> = []
        ctx.font = `${textSizeFont}px ${font}`
        let textWidth = ctx.measureText(text).width
        if(textWidth + 2 * paddingX >= w  - 5 ) {
            let row = ''
            textArray.forEach((word:any, index:number) => {
                if(tempWord !==''){
                    row += ' ' + tempWord + ' ' + word
                    tempWord = ''
                }

                else{
                    row += ' ' + word
                }
            
                if(ctx.measureText(row).width + 2 * paddingX >= comparableWidth - 5 ){
                    tempWord = row.split(' ')[row.split(' ').length - 1]
                    row = row.split(' ').filter((word, index) =>  index !==row.split(' ').length -1).join(' ')
                    rowList.push(row)
                    rowWidth.push(ctx.measureText(row).width)
                    if(index === textArray.length - 1) {
                        if(tempWord !== ''){
                            row = ' ' + tempWord
                            rowList.push(row)
                            rowWidth.push(ctx.measureText(row).width)
                        }
                    }
                    row = ''
                }
                if(index === textArray.length - 1){
                    rowList.push(row)
                }
            })
            tempWidth = Math.max(...rowWidth) + 2 * paddingX
        }
        else{
            rowList.push(' '+text)
        }
        let h = 0
        if(!isMobileDivice){
            paddingX = 10
        if(arrayRows.length === 1){
           
            if(arrayRows[0].length > 1){
                h = countRows * textSizeFont + countRows-1 * gap + arrayRows.length * (headerSizeFont + textSizeFont + headerGap) + paddingX + 4
            }
            else{
                h = countRows * textSizeFont + countRows-1 * gap + arrayRows.length * (headerSizeFont + textSizeFont + headerGap) + paddingX 
            }
        }
        else{
            h = countRows * textSizeFont + countRows-1 * gap + arrayRows.length * (headerSizeFont + textSizeFont + headerGap) + paddingX + gap
        }
        if(arrayRows.length !== 1){

        let paddingNumber = 0
        for(let i = 0 ; i < arrayRows.length;i++){
            if(arrayRows[i].length === 1){
                paddingNumber++
            }
        }
        h -= paddingNumber * 6
    }
        }
        else{
            h = countRows * textSizeFont +
                countRows-1 * gap +
                (arrayRows.length + 1) * (headerSizeFont + textSizeFont) + 
                paddingX + 1 
        }
        if(startY - h < 110 )
            if(startY + h >= canvasHeight  - 110 ) return
        if(startY + h >= canvasHeight  - 110)
            if(startY - h < 110 ) return

        countRows += rowList.length
        arrayRows.push(rowList)
        tempWord = ''
        if(tempWidth > w) w = tempWidth
    })
    arrayRows = arrayRows.map((arrayRow) => {
        return arrayRow.filter((word:string) =>
        {
            if(word === '') countRows--
            return word !== ''
        })
    })
    let h = 0

    if(!isMobileDivice){
        h += countRows * textSizeFont
        h += arrayRows.length * headerSizeFont
        h += (arrayRows.length -1)* 3
        h += 2 * paddingX
        h += (countRows - 1) * gap - arrayRows.length 
        h += (arrayRows.length) * headerGap
        h -=4
        if(specilalText){
            h += 20
            if(arrayRows.length > 4){
                h -= 2
            }
        }
    }
    else{
        h += countRows * textSizeFont
        h += arrayRows.length * headerSizeFont
        h += (arrayRows.length -1)* 3
        h += 2 * paddingX
        h += (countRows - 1) * gap - arrayRows.length 
        h += (arrayRows.length) * headerGap
        h -=1
        if(specilalText){
            h += 21
            if(arrayRows.length > 4){
                h -= 1
            } 
        }
    }
    ctx.webkitImageSmoothingEnabled = false;
    ctx.mozImageSmoothingEnabled = false;
    ctx.imageSmoothingEnabled = false;

    let r = 5
    let startX = element.left + 12
    let x = startX - w / 2
    ctx.filter = 'blur(0px)';
    let blackColor = '#100B00'

    let z = startY - h - 8
    if( z  < 0){
        ctx.beginPath()
        ctx.moveTo(startX, startY + 32)
        const startYC = startY + 42
        startY -= 8

        ctx.lineTo(startX - 4, startYC)
        ctx.lineTo(startX + 4, startYC)
        ctx.closePath()
        ctx.fillStyle = blackColor
        ctx.fill();
    }
    else{
        ctx.beginPath()
        ctx.moveTo(startX, startY)
        startY -= 8
        ctx.lineTo(startX - 4, startY)
        ctx.lineTo(startX + 4, startY)
        ctx.closePath()
        ctx.fillStyle = blackColor
        ctx.fill();
    }
    let y = startY - h
    if( startX < w / 2 ){
        x = x + (w/2 - startX)
        if(startX < x){
            x -= x - startX + 7
        }
    }

    if( startX +  w / 2 > canvasWidth ){
        x = x - ( (startX +  w / 2) - canvasWidth)
    
        if(x+w) {
            x -= (x + w) - canvasWidth
        }
    }

    let flag = false
    if(startY + y > canvasHeight - 110){
        flag = true
    }
    if(y < 0 && !flag){
        y = startY + 50
    }
    PaintRoundRectangle(ctx, x, y, w, h, r, blackColor)
    for (let i = 0 ; i <  arrayRows.length ; i ++){
        let gapRow = y + 5 + paddingX
        paintText(ctx,x + paddingX, gapRow, headerArray[i], `bold ${headerSizeFont}px ${font}`, headerColor)
        gapRow += textSizeFont + headerGap
    
        arrayRows[i].forEach( function (row:string,index:number){
            paintText(ctx,x + paddingX - 3, gapRow, row, `${textSizeFont}px ${font}`, textColor)
            if(index === arrayRows[i].length - 1) return
            gapRow += textSizeFont + gap
        })
        y = gapRow + 3

    }
    ctx.font = `bold ${textSizeFont}px ${font}`
    if(specialTextBounding)
        specialTextBounding.current = {
            xStart:x,
            xEnd:x + paddingX + ctx.measureText(specilalText).width,
            yStart:y+14 - 3,
            yEnd:y+14 + textSizeFont + 3,
            element:element
        }
    if(specilalText)    
        paintText(ctx,x + paddingX, y + 17,specilalText, `bold ${textSizeFont}px ${font}`, linkColor)
}