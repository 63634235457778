import React, { useContext, useEffect } from 'react';
import {Modal} from "react-bootstrap";
import SingleInformationCompanyCard from "../Cards/SingleInformationCompanyCard";
import {useParams} from "react-router-dom";
import {v4 as uuidv4, v4} from "uuid";
import SingleInformationEventCard from "../Cards/SingleInformationEventCard";
//@ts-ignore
import useLocalEventTicker from '../../hooks/useLocalEventTicker';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import InfiniteScroll from '../../Utills/VirtualScroll';
import { CompanyContext } from '../../Context';
import { t } from 'i18next';

interface CommentsModal{
    show: boolean,
    handleClose: () => void
}

function PressReleasesModal({show,handleClose}: CommentsModal)  {
    const {id} = useParams()

    const companyObj = useTypedSelector(state => state.CompanyReducer)

    const {setTickerEvents,lastRowTickerEventHandler,TickerEvents} = useContext(CompanyContext)
    
    useEffect( () => {
        setTickerEvents(companyObj.ticker, 'PRESS_RELEAS')
    },[show])

    return (
        <Modal size ='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">{id}: {t('STOCK_PRESS_RELEASES_HEADER')}</h5>
            </Modal.Header>

            <Modal.Body>
                { <InfiniteScroll listItems={TickerEvents} lastRowHandler ={lastRowTickerEventHandler}  /> }
            </Modal.Body>


        </Modal>
    );
}

export default PressReleasesModal;