import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import NotificationModal from "../Modals/NotificationModal";
import MutedStocksModal from "../Modals/MutedStocksModal";
import TelegramModal from "../Modals/TelegramModal";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import '../../styles/NotificationContainer.css'
import { UseActions } from "../../hooks/useActions";
import { DeviceIsMobile, DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";
import { useTransition } from "../../hooks/useTransition";
import useTelegramBackDrop from "../../hooks/useTelegramBackDrop";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import UserService from "../../Services/UserService";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { TELEGRAM_BOT_URL } from "../../models/LInks";

interface INotificationsContainer {
    autoTranslateElement: JSX.Element,
    investAppElement: JSX.Element
}

function NotificationsContainer({ autoTranslateElement, investAppElement }: INotificationsContainer) {
    const navigate = useNavigate()
    const telegramIsConnecting = useTypedSelector(state => state.EventReducer.telegramIsConnecting)
    const { SetTelegramIsConnect, setTelegram } = UseActions()
    const { transitionToNotification, transitionToMutedPageWithoutEvent } = useTransition()
    const User = useTypedSelector(state => state.UserReducer.User)
    const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)
    const { openBackDrop } = useTelegramBackDrop()

    useEffect(() => {
        const targetElementSelect = document.querySelector('html') as HTMLElement
        setTargetElement(targetElementSelect)
    }, [])
    const showTargetElement = () => {
        if (!targetElement) return
        disableBodyScroll(targetElement);
    };

    const hideTargetElement = () => {
        if (!targetElement) return
        enableBodyScroll(targetElement);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => {
        setShow(true);
    }


    const [showMuted, setShowMuted] = useState(false);
    const handleCloseMuted = () => setShowMuted(false);
    const handleShowMuted = () => setShowMuted(true);

    const [showTelegramModal, setShowTelegramModal] = useState<boolean>(false)
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)

    const handleCloseTelegram = () => setShowTelegramModal(false);
    const handleShowTelegram = () => setShowTelegramModal(true);
    
    const { t } = useTranslation()

    const NotificationClickHandler = (e: React.MouseEvent<HTMLDivElement>) => {
        if (DeviceIsMobile(userAgent))
            transitionToNotification()
        else {
            handleShow()
        }
        e.preventDefault()
    }

    const MutedClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {

        if (DeviceIsMobile(userAgent))
            transitionToMutedPageWithoutEvent()
        else
            handleShowMuted()
        e.preventDefault()
    }

    const handleTelegramClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (telegramIsConnecting === false) {

            if (DeviseIsApplicationOnly()) openBackDrop()
            else handleShowTelegram()
        }
    }

    const MySwal = withReactContent(Swal)
    const handleCloseTelegramClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        MySwal.fire({
            title: <p>{t('PROFILE_TELEGRAM_BOT_REMOVE')}</p>,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: ` ${t('PROFILE_TELEGRAM_BOT_REMOVE_YES')} `,
            cancelButtonText: `${t('PROFILE_TELEGRAM_BOT_REMOVE_NO')}`
        }).then((result) => {
            if (result.isDenied) {
                UserService.disconnectTelegram().then((result) => {
                    setTelegram(false)
                })
            }
        })
    }

    return (
        <>
            <TelegramModal show={showTelegramModal} handleClose={handleCloseTelegram} />
            {
                !User.isCrypto && <div className="form-group row align-items-center" onClick={(e) => e.stopPropagation()}>
                <NotificationModal show={show} handleClose={handleClose} />
                <MutedStocksModal show={showMuted} handleClose={handleCloseMuted} />
                <label className="col-lg-2 col-form-label text-lg-right">
                    {t('PROFILE_NOTIFICATION_SETTINGS')}
                </label>
                <div className="col-lg-6" onClick={(e) => { e.stopPropagation() }}>
                    <div className="btn btn-light-primary"
                        onClick={NotificationClickHandler}> <i
                            className="fab fa-twitter"
                        ></i> <i
                            className="far fa-newspaper" aria-hidden="true"></i> <i
                                className="fas fa-fire" aria-hidden="true">
                        </i> {t('PROFILE_NOTIFICATION_EVENTS')}
                    </div>
                </div>
            </div>
            }
            
            {
                !User.isCrypto && <div className="form-group row align-items-center">
                    <label className="col-lg-2 col-form-label text-lg-right">
                        {t('PROFILE_MUTED_STOCKS')}
                    </label>
                    <div className="col-lg-6">
                        <a href="#" className="btn btn-light-primary" onClick={MutedClickHandler}
                            data-toggle="modal"
                            data-target="#muteStocksModal">
                            <span className="svg-icon svg-icon-light-dark">
                                <svg xmlns="http://www.w3.org/2000/svg"
                                    width="24px"
                                    height="24px" viewBox="0 0 24 24" version="1.1">
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <rect x="0" y="0" width="24" height="24"></rect>
                                        <path
                                            d="M16.3155516,16.1481997 C15.9540268,16.3503696 15.4970619,16.2211868 15.294892,15.859662 C15.0927222,15.4981371 15.2219049,15.0411723 15.5834298,14.8390024 C16.6045379,14.2679841 17.25,13.1909329 17.25,12 C17.25,10.8178416 16.614096,9.74756859 15.6048775,9.17309861 C15.2448979,8.96819005 15.1191879,8.51025767 15.3240965,8.15027801 C15.529005,7.79029835 15.9869374,7.66458838 16.3469171,7.86949694 C17.8200934,8.70806221 18.75,10.2731632 18.75,12 C18.75,13.7396897 17.8061594,15.3146305 16.3155516,16.1481997 Z M16.788778,19.8892305 C16.4155074,20.068791 15.9673493,19.9117581 15.7877887,19.5384876 C15.6082282,19.165217 15.7652611,18.7170589 16.1385317,18.5374983 C18.6312327,17.3383928 20.25,14.815239 20.25,12 C20.25,9.21171818 18.6622363,6.70862302 16.2061077,5.49544344 C15.8347279,5.31200421 15.682372,4.86223455 15.8658113,4.49085479 C16.0492505,4.11947504 16.4990201,3.96711914 16.8703999,4.15055837 C19.8335314,5.61416684 21.75,8.63546229 21.75,12 C21.75,15.3971108 19.7961591,18.4425397 16.788778,19.8892305 Z"
                                            fill="#000000" fillRule="nonzero" opacity="0.3"></path>
                                        <path
                                            d="M7,16 L3.60776773,15.3215535 C2.67291934,15.1345839 2,14.3137542 2,13.3603922 L2,10.6396078 C2,9.68624577 2.67291934,8.86541613 3.60776773,8.67844645 L7,8 L10.2928932,4.70710678 C10.6834175,4.31658249 11.3165825,4.31658249 11.7071068,4.70710678 C11.8946432,4.89464316 12,5.14899707 12,5.41421356 L12,18.5857864 C12,19.1380712 11.5522847,19.5857864 11,19.5857864 C10.7347835,19.5857864 10.4804296,19.4804296 10.2928932,19.2928932 L7,16 Z"
                                            fill="#000000"></path>
                                    </g>
                                    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                        <g transform="translate(12.000000, 12.000000) rotate(40.000000) translate(-12.000000, -12.000000) translate(0.000000, 4.000000)"
                                            fill="#ffffff">
                                            <rect x="0" y="7" width="22" height="2" rx="1"></rect>
                                        </g>
                                    </g>
                                </svg>
                            </span>
                            {t('PROFILE_MUTED_STOCKS_LIST')}
                        </a>
                    </div>
                </div>
            }
            {autoTranslateElement}
            <div className="form-group row align-items-center">
                <label className="col-lg-2 col-form-label text-lg-right">
                    {t('PROFILE_TELEGRAM')}
                </label>
                <div className="col-lg-6 d-flex align-items-center">
                    {
                        (User?.settings?.telegramConnected)
                            ? <div className="js__telegram-bot-container">
                                <a target="_blank" href = {TELEGRAM_BOT_URL}>
                                    <div className="btn btn-light-primary">
                                        <span className="d-flex align-items-center">
                                            <i className="fab fa-telegram-plane mr-2"></i>
                                            t.me/tradesense_feed_bot</span>
                                    </div>
                                </a>

                                <a href="#" onClick={handleCloseTelegramClick} className="js__remove-telegram-bot btn btn-default btn-hover-light-danger">
                                    <i className="fas fa-times pr-0"></i>
                                </a>

                            </div>
                            : <a href={TELEGRAM_BOT_URL} onClick={handleTelegramClick}>
                                <div className="btn btn-light-primary">
                                    <span className="d-flex align-items-center">
                                        <i className="fab fa-telegram-plane mr-2"></i>
                                        {t('PROFILE_TELEGRAM_BOT')}
                                    </span>
                                </div>
                            </a>
                    }
                </div>
            </div>
            <div className="form-group row">
                <label className="col-lg-2 col-form-label text-lg-right">
                    {
                        !User.isCrypto 
                            ? <> {t('PROFILE_BROKER_APP')} </>
                            : <> {t('PROFILE_BROKER_APP_CRYPTO')} </>
                    }
                    
                </label>
                <div className="col-lg-6">
                    {investAppElement}
                    <span className="form-text text-muted">
                        {t('PROFILE_BROKER_APP_CHOOSE')}
                    </span>
                </div>
            </div>
        </>
    )
}

export default React.memo(NotificationsContainer)