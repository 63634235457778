import React, { useEffect, useRef, useState } from 'react'
import { Prev } from 'react-bootstrap/esm/PageItem'
import { AreaPeriodType, ChartGroupTypes, typeGraphEnum } from '../../models/Chart'
import '../../styles/GraphControls.css'
import { DeviseIsApplicationOnly } from '../../Utills/ClearFunctions'
import { Element, IElementGraphControl, IElemetnGraphControlState } from './GraphControls/Element'

interface IGraphControls {
    setGraphtype: (type: typeGraphEnum) => void,
    graphtype: typeGraphEnum,
    candleGroupState: Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>,
    areaPeriodState: Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>,
    setAreaPeriodState: (value: Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>) => void
    setCandleGroupState: (value: Array<Omit<IElementGraphControl, 'setCallback' | 'graphType'>>) => void
}

interface IButtonState {
    area: string,
    candle: string
}

function GraphControls({ setGraphtype, graphtype, areaPeriodState, candleGroupState, setAreaPeriodState, setCandleGroupState }: IGraphControls) {
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)
    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            starRef.current.classList.add('disableStar')
        }
    }, [])

    const [buttonState, setButtonState] = useState<IButtonState>({
        area: 'btn-dark',
        candle: 'btn-white'
    })

    const handleArea = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setGraphtype(typeGraphEnum.AREA)
        setButtonState({
            candle: 'btn-white',
            area: 'btn-dark'
        })
    }

    const handleCandle = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setGraphtype(typeGraphEnum.CANDLE)
        setButtonState({
            candle: 'btn-dark',
            area: 'btn-white'
        })
    }

    const hanldeCandleButtons = async (id: ChartGroupTypes | AreaPeriodType) => {
        const newState = candleGroupState.map((candleObj) => {
            if (candleObj.id === id) {
                return { ...candleObj, active: true }
            }
            return { ...candleObj, active: false }
        })
        setCandleGroupState(newState)
    }

    const hanldeAreaButtons = async (id: ChartGroupTypes | AreaPeriodType) => {

        const newState = areaPeriodState.map((areaObj) => {
            if (areaObj.id === id) return { ...areaObj, active: true }
            return { ...areaObj, active: false }
        })
        setAreaPeriodState(newState)
    }

    return (
        <div className='graph_controls'>

            <div>
                <a ref = {starRef} onClick={handleArea} href=""
                    className={`btn btn-sm js__chart-type ${buttonState.area} p-2`}>
                    <i className="fa fa-chart-area m-0 p-0"></i>
                </a>
                <a ref = {starRef} onClick={handleCandle} href=""
                    className={`btn btn-sm js__chart-type ${buttonState.candle} p-2`}>
                    <i className="far fa-chart-bar m-0 p-0"></i>
                </a>
            </div>

            {
                graphtype === typeGraphEnum.AREA
                    ? <div>
                        {
                            areaPeriodState.map((AreaObj) => {
                                return <Element {...AreaObj} setCallback={hanldeAreaButtons} />
                            })
                        }
                    </div>
                    : <div>
                        {
                            candleGroupState.map((CandleObj) => {
                                return <Element {...CandleObj} setCallback={hanldeCandleButtons} />
                            })
                        }
                    </div>
            }

        </div>
    )
}

export default React.memo(GraphControls)