import {useTypedSelector} from "../../hooks/useTypedSelector";
import UserService from "../../Services/UserService";
import {getPeriodObj} from "../../Utills/ClearFunctions";
import {UseActions} from "../../hooks/useActions";
import TimeLineDay from "../../components/Time/TimeLineTimeDay";
import {useState} from "react";
import useEvent from "./useEvent";
import {EventTopicEnum} from "../../models/EventContentModel";

function useEventTrendsQuery(setTrendHiddenCounts:(counts:number) => void){
    const trendsElements = useTypedSelector(state => state.EventReducer.trendsElements)
    const {setPeriod,setFullTrendsEvents,setTrendsEventsMutation,setPrevEventTrends} = UseActions()
    const [showEmptyList,setShowEmptyList] = useState<boolean>(false)

    const {replaceDataTrends,getPeriod} = useEvent()

    const getCards = async (trendsElementIndex:number) => {
        const response = await UserService.getEvents(trendsElementIndex)
        setTrendHiddenCounts(response?.data?.hiddenEventsCount)
        const periodObj = getPeriodObj(response?.data?.groups[0]?.period)
        getPeriod(response?.data, EventTopicEnum.TREND)
        return [response?.data?.groups, periodObj]
    }
    const [trendsIsLoading,setTrendsIsLoading] = useState<boolean>(false)

    const setTrends = (trends:Array<any>) => {

        setPrevEventTrends(trends)
        setTrendsIsLoading(true)
        let trendsElementIndex:number = 1
        trendsElements.forEach((trendsElement,index) => {
            if(trendsElement.active){
                trendsElementIndex = index+1
            }
        })

        const getData = async () => {
            const [groups, periodObj] = await getCards(trendsElementIndex)
    
            const jsxTrends = groups.map((group:any, index:number) => {
                const trendStocks = group.trendStocks
                const periodObj = getPeriodObj(group.period)
    
                const replaceTrade = replaceDataTrends(trendStocks, periodObj)
                if(index === 0)
                    return replaceTrade
                else
                    return <>
                        <TimeLineDay date={`${periodObj.full}`}/>
                        <hr/>
                        {replaceTrade}
                    </> 
            })
                 
            setFullTrendsEvents(jsxTrends)
            if(jsxTrends.length === 0) setPeriod({full:'',reduction:''})
        }

        getData().then(() => {
            setTrendsIsLoading(false)    
        })

        setShowEmptyList(true)
    }

    const preloadTrendEvents = async (trends:Array<any>) => {
        setPrevEventTrends(trends)
        let trendsElementIndex:number = 1
        trendsElements.forEach((trendsElement,index) => {
            if(trendsElement.active){
                trendsElementIndex = index+1
            }
        })

        const getData = async () => {
            const [groups, periodObj] = await getCards(trendsElementIndex)
    
            const jsxTrends = groups.map((group:any, index:number) => {
                const trendStocks = group.trendStocks
                const periodObj = getPeriodObj(group.period)
    
                const replaceTrade = replaceDataTrends(trendStocks, periodObj)
                if(index === 0)
                    return replaceTrade
                else
                    return <>
                        <TimeLineDay date={`${periodObj.full}`}/>
                        <hr/>
                        {replaceTrade}
                    </> 
            })
            
            
            setTrendsEventsMutation(jsxTrends)
            if(jsxTrends.length === 0) setPeriod({full:'',reduction:''})
        }


        getData()
    }
    return {setTrends, preloadTrendEvents, trendsIsLoading,showEmptyList}
}

export default useEventTrendsQuery