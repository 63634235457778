export function dataHandler(timeStamp:number, timezone:string, language:string){
    const shortDateOptions = {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: timezone,
        hour12: false,
    }
    //@ts-ignore
    return new Intl.DateTimeFormat(language, shortDateOptions).format(timeStamp )
}
