export enum GroupEnum{
    POPULAR = 0,
    VALUE_LEADERS = 1,
    IT = 2,
    MEDICINE = 3,
    CONSUMER = 4,
    MATERIALS = 5,
    FINANCE = 6,
    COMMUNICATION = 7,
    INDUSTRY = 8,
    ENERGY = 9,
    ESTATE = 10,
    CRYPTO = 11
}