import React, { FC, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { IPriceAreainfornation, IPriceCandleinfornation } from "../../../models/Chart"

export const CandleInformation: FC<IPriceCandleinfornation> = React.memo(({ price, close, date, high, low, open, percent, color }) => {
    const { t } = useTranslation()

    return <>
        <div style={{ height: '42px' }} className="d-flex align-items-center justify-content-between flex-nowrap mt-3 px-3">
            <div className="mb-2 font-size-sm d-flex">
                <div className="text-right border-right border-right-light-dark">
                    <span className="text-muted mr-1"> {t('STOCK_CHART_OPEN')} </span><br />
                    <span className="text-muted mr-1">{t('STOCK_CHART_CLOSE')}</span>
                </div>
                <div className="text-left">
                    <span className="ml-1 js__candle-open">{open}&nbsp;$</span><br />
                    <span className="ml-1 js__candle-close">{close}&nbsp;$</span>
                </div>
            </div>
            <div className="mb-2 font-size-sm text-center ">
                <span className="text-muted js__candle-range">{date}</span><br />

                {
                    color === 'red'
                        ? <b className="js__candle-current">{price}&nbsp;$<span className="text-danger  ml-1">↓ {percent}%</span></b>
                        : <b className="js__candle-current"> {price}&nbsp;$ <span className=" text-success  ml-1">↑ {percent}%</span> </b>
                }
            </div>
            <div className="mb-2 font-size-sm d-flex">
                <div className="text-right border-right border-right-light-dark">
                    <span className="text-muted mr-1">{t('STOCK_CHART_MAX')}</span><br />
                    <span className="text-muted mr-1">{t('STOCK_CHART_MIN')}</span>
                </div>
                <div className="text-left">
                    <span className="ml-1 js__candle-high">{high}&nbsp;$</span><br />
                    <span className="ml-1 js__candle-low">{low}&nbsp;$</span>
                </div>
            </div>
        </div>
    </>
})


export const AreaInformation: FC<IPriceAreainfornation> = React.memo(({ price, date }) => {
    return <>
        <div style={{ height: '42px' }} className="font-size-sm text-center w-100 mt-3">
            <span className="text-muted js__candle-range">{date}</span>
            <br />
            <b className="js__candle-current">{price}&nbsp;$</b>
        </div>
    </>
})


