import React, {useState} from "react";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import {UseActions} from "../../hooks/useActions";
import UserService from "../../Services/UserService";
import {ClearDeviceIsMobile, DeviceIsMobile} from "../../Utills/ClearFunctions";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import '../../styles/deleteEvent.css'
import { t } from "i18next";

interface IEvent {
    children: JSX.Element
    eventId: string,
    canEditEvents: boolean,
    setDeleteElement?: any
}

function Event({setDeleteElement, children, eventId, canEditEvents}: IEvent) {
    const MySwal = withReactContent(Swal)
    const {deleteEvent, deleteFavourites} = UseActions()
    const deleteHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        MySwal.fire({
            title: <p>Вы дейстивтельно хотите удалить данное событие?</p>,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `${t('COMMENTS_DELETE_PROMPT_YES')}`,
            cancelButtonText:  `${t('COMMENTS_DELETE_PROMPT_NO')}`,
        }).then((result) => {
            //refactor
            if (result.isDenied) {
                UserService.deleteEvents(eventId).then(() => {
                    deleteEvent(eventId)
                    deleteFavourites(eventId)
                    if (setDeleteElement) {
                        setDeleteElement(eventId)
                    }
                })
            }
        })
    }

    const [showDeleteIcon,setShowDeleteIcon] = useState<boolean>(false)
    const eventClickHandler = (e:React.MouseEvent<HTMLDivElement>) => {
        if(showDeleteIcon) return
        setShowDeleteIcon(true)
    }

    return (
        <>
            {
                ClearDeviceIsMobile()
                    ? <div
                        onClick={eventClickHandler}
                        className="js__event-card event-wrapper event-wrapper-translated d-flex flex-lg-fill card card-custom card-shadowless p-5 bg-gray-100 text-dark mb-4">
                        {
                            (showDeleteIcon && canEditEvents) && <a onClick={deleteHandler} href="#"
                                                className=" deleteEvent deleteEvent js__remove-event text-hover-danger remove-event-button">
                                <i className="position-absolute right-0 top-0 fas fa-trash-alt mt-5 mr-5"></i>
                            </a>
                        }
                        {children}
                    </div>
                    : <div
                        className="js__event-card event-wrapper event-wrapper-translated d-flex flex-lg-fill card card-custom card-shadowless p-5 bg-gray-100 text-dark mb-4">
                        {
                            (canEditEvents) &&    <a onClick={deleteHandler} href="#"
                                                className=" deleteEvent js__remove-event text-hover-danger remove-event-button">
                                <i className="position-absolute right-0 top-0 fas fa-trash-alt mt-5 mr-5"></i>
                            </a>
                        }
                        {children}
                    </div>
            }


        </>
    )
}

export default React.memo(Event)