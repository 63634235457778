import {actionType, allEventEnum, IAllEvent} from './types'
import {IPeriod} from "../UserEvent/types";

const initialState: IAllEvent = {
    FavoutiresElements:[],
    numberNewFavouritesEvent:0,
    NewFavouritesElements:[],
    prevFilter:[],
    period:{} as IPeriod,
    favouritesElementIsLoading:false
}

export default function FavouritesReducer( state = initialState, action:actionType ) :IAllEvent {
    
    switch (action.type){
        case allEventEnum.SET_FAVOURITES_ELEMENT_IS_LOADING:
            return {...state, favouritesElementIsLoading:action.payload}
        case allEventEnum.SET_PERIOD:
            return {...state, period:action.payload}
        case allEventEnum.DELETE_EVENT_FAVOURITES:
            const deletedElements = state.FavoutiresElements.filter((Element) => {

                return action.payload != Element.key
            })
            return {
                ...state,
                FavoutiresElements:deletedElements,
            }
        case allEventEnum.SET_FAVOURITES_RPEV_FILTER:
            return {
                ...state,
                prevFilter:action.payload
            }
        case allEventEnum.SET_FAVOURITES_FULL_EVENTS:
            return {
                ...state,
                FavoutiresElements:[...action.payload],
                NewFavouritesElements:[],
                numberNewFavouritesEvent:0,
            }
        case allEventEnum.SET_FAVOURITES_EVENTS:
            const prevKey = state.FavoutiresElements.map(Element => Element.key)
            const newElements = action.payload.filter( (newElement) => {
                return !prevKey.includes(newElement.key)                
            })

            if(newElements.length === 0 ) {
                state.numberNewFavouritesEvent = 0
                return state
            }

            state.NewFavouritesElements = [...newElements]
            state.numberNewFavouritesEvent = newElements.length
            return state
        case allEventEnum.SET_FAVOURITES_NUMBER_NEW_EVENT:
            return {
                ...state, 
                numberNewFavouritesEvent: action.payload
            }
        case allEventEnum.SET_FAVOURITES_NEW_ELEMENTS:
            return { ...state,FavoutiresElements:[...state.NewFavouritesElements,...state.FavoutiresElements],NewFavouritesElements:[], numberNewFavouritesEvent:0}    
        default: return state
    }

}
