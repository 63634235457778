import {useParams} from "react-router-dom";
import React, {useContext, useState} from "react";
import PressReleasesModal from "../Modals/PressReleasesModal";
import {DeviseIsApplicationOnly} from "../../Utills/ClearFunctions";
import {CompanyContext} from "../../Context";
import WidgetLoaders from "../loaders/WidgetLoaders";
import {ORIGIN} from "../../models/Domen";
import { useTranslation } from "react-i18next";

function PressReleasesBody() {
    const {PressReleaseElements,isLoading} = useContext(CompanyContext)
    const {id} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShowModal = () => setShow(true);

    const handleShow = () => {
        if (DeviseIsApplicationOnly() ) {
            window.location.replace(`${ORIGIN}/stocks/${id}/press-releases`)
            return
        }
        handleShowModal()
    }
    const {t} = useTranslation()
    
    return (
        <>
            {
                isLoading
                    ? <>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                        <WidgetLoaders/>
                    </>
                    : <>
                        <PressReleasesModal show={show} handleClose={handleClose}/>

                        {
                            PressReleaseElements.length === 0
                                ? <div className="p-4 font-size-lg text-muted">
                                    <i className="far fa-newspaper text-muted mr-2" aria-hidden="true"></i> {t('STOCK_PRESS_RELEASES_EMPTY')}
                                </div>
                                : <>
                                    {PressReleaseElements}

                                    <div className="d-flex align-items-center mt-4">
                                        <a style={{cursor: 'pointer'}} className="js__filter-events-by-type" data-pjax="0"
                                           onClick={() => handleShow()}
                                        >{t('STOCK_SHOW_MORE')}</a>
                                    </div>
                                </>
                        }
                    </>
            }

        </>
    )
}

export default React.memo(PressReleasesBody)