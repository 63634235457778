import React, {FC, useEffect, useState} from "react";

interface IAlert {
    text: string,
    removeAlert: React.MouseEventHandler<HTMLButtonElement>
}

export enum themeAlertEnum {
    Danger = "Danger",
    Success = "Success"
}

interface Alert{
    close: () => void
    isOpen: boolean
    text: string
    theme:themeAlertEnum,
    children:JSX.Element
}

type TBlueAlert = Omit<Alert, 'text'| 'theme' | 'children' | 'close'>

export const BlueAlert:FC<TBlueAlert> = ( {children,isOpen} ) => {
    return <> {
        isOpen && <div className="alert alert-custom alert-light-primary mt-4 font-size-sm">
            {children}
        </div>
        }
    </>
}

export function RedAlert({text, removeAlert}: IAlert) {
    return (
        <div data-notify="container"
             className="alert alert-custom alert-light-danger animate__animated animate__fadeInDown"
             role="alert" data-notify-position="top-center"
             style={{
                 display: 'inline-block',
                 margin: '0px auto',
                 position: 'fixed',
                 transition: 'all 0.5s ease-in-out 0s',
                 zIndex: '1052',
                 top: '55px',
                 left: '0px',
                 right: '0px',
                 animationIterationCount: '1'
             }}>
            <button onClick={removeAlert} type="button" aria-hidden="true" className="close" data-notify="dismiss"
                    style={{position: 'absolute', right: '10px', top: '5px', zIndex: '1054'}}>
            </button>
            <span data-notify="icon"></span><span data-notify="title"></span><span
            data-notify="message">
                        {text}
                        </span>
            <a href="#" target="_blank" data-notify="url"></a>
        </div>
    )
}





export function Alert({text, close, isOpen,theme, children}: Alert) {
    const [prevTimerId,setPrevTimerId] = useState< NodeJS.Timeout | null>(null)
    useEffect(() => {
        if(!isOpen) return

        if(prevTimerId)
            clearInterval(prevTimerId)

        const timerId = setTimeout(() => {
            close()
        }, 2600)

        setPrevTimerId(timerId)
    }, [isOpen])


    return (
        <>
            {
                isOpen &&
                <>
                    {
                        theme === themeAlertEnum.Success
                            ? <div data-notify="container"
                                  className="alert alert-custom alert-light-success animate__animated animate__fadeInDown"
                                  role="alert" data-notify-position="top-center"
                                  style={{
                                      display: 'inline-block',
                                      margin: '0px auto',
                                      position: 'fixed',
                                      transition: 'all 0.5s ease-in-out 0s',
                                      zIndex: '1052',
                                      top: '55px',
                                      left: '0px',
                                      right: '0px',
                                      animationIterationCount: '1'
                                  }}>
                                <button onClick={close} type="button" aria-hidden="true" className="close"
                                        data-notify="dismiss"
                                        style={{position: 'absolute', right: '10px', top: '5px', zIndex: '1054'}}>
                                </button>
                                <span data-notify="icon"></span><span data-notify="title"></span><span
                                data-notify="message">
                        {text}
                        </span>
                                <a href="#" target="_blank" data-notify="url"></a>
                            </div>
                            :<div data-notify="container"
                                  className="alert alert-custom alert-light-danger animate__animated animate__fadeInDown"
                                  role="alert" data-notify-position="top-center"
                                  style={{
                                      display: 'inline-block',
                                      margin: '0px auto',
                                      position: 'fixed',
                                      transition: 'all 0.5s ease-in-out 0s',
                                      zIndex: '1052',
                                      top: '55px',
                                      left: '0px',
                                      right: '0px',
                                      animationIterationCount: '1'
                                  }}>
                                <button onClick={close} type="button" aria-hidden="true" className="close" data-notify="dismiss"
                                        style={{position: 'absolute', right: '10px', top: '5px', zIndex: '1054'}}>
                                </button>
                                <span data-notify="icon"></span><span data-notify="title"></span><span
                                data-notify="message">
                            {children}
                        </span>
                                <a href="#" target="_blank" data-notify="url"></a>
                            </div>
                    }
                </>
            }
        </>
    )
}


interface IAlertwithF {
    text: string,
    removeAlert: React.MouseEventHandler<HTMLButtonElement>
}

export function SuccessAlertWithDelete({text, removeAlert}: IAlert) {
    return (
        <div data-notify="container"
             className="alert alert-custom alert-light-success animate__animated animate__fadeInDown"
             role="alert" data-notify-position="top-center"
             style={{
                 display: 'inline-block',
                 margin: '0px auto',
                 position: 'fixed',
                 transition: 'all 0.5s ease-in-out 0s',
                 zIndex: '1052',
                 top: '55px',
                 left: '0px',
                 right: '0px',
                 animationIterationCount: '1'
             }}>
            <button onClick={removeAlert} type="button" aria-hidden="true" className="close" data-notify="dismiss"
                    style={{position: 'absolute', right: '10px', top: '5px', zIndex: '1054'}}>
            </button>
            <span data-notify="icon"></span><span data-notify="title"></span><span
            data-notify="message">
                        {text}
                        </span>
            <a href="#" target="_blank" data-notify="url"></a>
        </div>
    )
}
