import axios from 'axios';
import { ORIGIN } from '../models/Domen';


const getURL = () => {
    if( ORIGIN.includes('localhost') || ORIGIN.includes('react') )
        return `https://tradesense.me/api/v1`
    else
        return `${ORIGIN}/api/v1`
}

export const API_URL = getURL()

const $api = axios.create({
    withCredentials: true,
    baseURL: API_URL
})


export default $api;