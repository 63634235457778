import React, { useContext, useEffect, useRef, useState } from "react"
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { UseActions } from "../../hooks/useActions";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { t } from "i18next";
import { TickerContext } from "../../Context";
import { ILabel } from "../../models/EventContentModel";
import CustomIcon from "./CustomIcon";
import MutedSideBar from "./MutedSideBar";
import UserService from "../../Services/UserService";
import { DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";

function MutedSideBarWrapper() {
    const isOpenMutedMenu = useTypedSelector(state => state.EventReducer.isOpenMutedMenu)
    const { showStatusTooltip, setShowStatusTooltip, companyInformation} = useContext(TickerContext)
    const [currentMuted, setCurrentMuted] = useState<string>('power')
    const CompanyReducer = useTypedSelector(state => state.CompanyReducer)
    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    useEffect(() => {
        if (DeviseIsApplicationOnly()) {
            starRef.current.classList.add('disableStar')
        }
    }, [])

    const [labelSate, setLabelSate] = useState<Array<ILabel>>([
        {
            name: 'power',
            active: true,
            key: 0,
        },
        {
            name: 'muted',
            active: false,
            key: 1,
        },
        {
            name: 'allTimeMuted',
            active: false,
            key: 2,
        }
    ])
    useEffect(() => {
        const notificationStatus = companyInformation?.statusInfo?.notificationStatus

        const newState = labelSate.map((Label) => {
            if (Label.key === notificationStatus) {
                return { ...Label, active: true }
            } else return { ...Label, active: false }
        })
        setLabelSate(newState)
    }, [companyInformation?.statusInfo])

    const { setIsOpenSideMenu } = UseActions()
    const closeHandleTooltip = () => {
        if (showStatusTooltip)
            setShowStatusTooltip(false)
    }

    const handleMutedSideBar = (e: React.MouseEvent<HTMLAnchorElement>) => {
        closeHandleTooltip()
        e.preventDefault()
        setIsOpenSideMenu(!isOpenMutedMenu)
    }

    const checkForMute = () => {
        for (let i = 1; i < labelSate.length; i++) {
            if (labelSate[i].active) return true
        }
        return false
    }
    const closeDropDown = () => {
        setIsOpenSideMenu(!isOpenMutedMenu)
    }

    const renderTooltip = (props: any) =>
    (
        <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
            <div style={{ width: '250px' }} className="overlay">
                {t('STOCK_MUTE_TOOLTIP')}
            </div>
        </Tooltip>
    );

    const changeActive = (name: string) => {
        setLabelSate(labelSate.map((value) => {
            if (value.name === name) {
                setCurrentMuted(value.name)
                UserService.saveMutedTicker({
                    ticker: CompanyReducer.ticker,
                    status: value.key
                })
                return { ...value, active: true }
            } else {
                return { ...value, active: false }
            }
        }))
    }
    // showStatusTooltip
    return <>
        <div className="">
            <div className="dropdown pl-lg-2 show" onClick={(e) => {
                e.stopPropagation()
                setIsOpenSideMenu(!isOpenMutedMenu)
            }}>
                <OverlayTrigger
                    delay={{ show: 1, hide: 1 }}
                    overlay={renderTooltip}
                    placement={'top'}
                    show={showStatusTooltip}
                >
                    <a ref = {starRef} href="#" data-toggle="dropdown" onClick={handleMutedSideBar}
                        className={"btn btn-icon btn-sm btn-white font-weight-bolder js__mute-status"}>
                        {
                            (!checkForMute())
                                ? <>  <i data-dropdown='true' className="fa-fw fas fa-volume-up text-primary"></i> </>
                                : <> <CustomIcon /> </>
                        }
                    </a>
                </OverlayTrigger>
                <MutedSideBar isOpenMenuSideBar={isOpenMutedMenu}
                    labelState={labelSate}
                    changeActive={changeActive}
                    closeDropDown={closeDropDown}
                />
            </div>
        </div>
    </>
}
export default MutedSideBarWrapper