import React from "react"
import { gettOrigin } from "../../Utills/ClearFunctions"


interface IFooter{
    text:string
    buttonText:string
}

function Footer( {text,buttonText}:IFooter ){
    return(
        <>
            <div className="text-muted font-size-xs mt-2">
                {text}
            </div>

            <div className="row mt-4">
                <div className="col-lg-12 text-center text-muted font-size-xs">
                    Доступные способы оплаты:<br/>
                    <img alt="" src={`${gettOrigin()}/img/card-visa.svg`}/>
                    <img alt="" src={`${gettOrigin()}/img/card-mastercard.svg`}/>
                    <img alt="" src={`${gettOrigin()}/img/card-mir.svg`}/>
                    <br/><br/>
                    Нажимая кнопку {`${buttonText}`} вы подтверждаете свое ознакомление и согласие с&nbsp;
                    <a href={`${gettOrigin()}/law/terms`} target="_blank">
                        Пользовательским соглашением
                    </a>,&nbsp;
                    <a href={`${gettOrigin()}/law/privacy`} target="_blank">Политикой
                        конфиденциальности
                    </a>&nbsp;и&nbsp;
                    <a href={`${gettOrigin()}/law/rules`} target="_blank">
                        Лицензионным
                        соглашением о предоставлении права использования программного продукта на возмездной
                        основе
                    </a>.
                </div>
            </div>
        </>
    )
}

export default React.memo(Footer)