import React, {useContext} from "react";
import ReportBody from "./ReportBody";
import {CompanyContext} from "../../Context";


function ReportWidget() {


    return(
        <div>
            <div className="card card-custom p-4 mb-8">
                <ReportBody/>
            </div>
        </div>
    )
}

export default  React.memo(ReportWidget)