import {actionType, AuthEnum, IAuth} from './types'
import {IProfile, ISetting, ISubscriptionInfo, PlaneTypes, SendTypeEnum, StatusTypes} from "../../../models/User";

const initialState: IAuth = {
    isAuth:'',
    User:{
        sendType:SendTypeEnum.MESSAGE,
        tariff:PlaneTypes.FREE,
        status:StatusTypes.OPEN,
        favouritesSelected:true,
        queuePosition:0,
        canEditEvents:false,
        avatar:'',
        subscriptionInfo:{} as ISubscriptionInfo,
        profile:{} as IProfile,
        settings:{} as ISetting,
        hasInvites:false,
        phone:'',
        promoCode:null,
        isCrypto:false
    },
    Loading:true,
    Error:'',
    avatarSaveError:{isError:false,TextError:''}
}

export interface IUSerInformation{
    about:string,
    username:string,
    fullName:string,
}

export default  function UserReducer(state:IAuth = initialState, action:actionType):IAuth{
    switch (action.type){
        case AuthEnum.SET_PROFILE_USER_INFORMATION:
            return {
                ...state,
                User:{
                    ...state.User,
                    profile: {
                        ...state.User.profile,
                        ...action.payload
                    }
                }
            }
        case AuthEnum.SET_HAS_INVITES:
            return{
                ...state,
                User:{
                    ...state.User,
                    hasInvites:action.payload
                }
            }
        case AuthEnum.SET_AVATAR_ERROR:
            return{
                ...state,
                avatarSaveError:action.payload
            }
        case AuthEnum.SET_TELEGRAM:
            return {
                ...state,
                User:{
                    ...state.User,
                    settings:{
                        ...state.User.settings,
                        telegramConnected:action.payload
                    }
                }
            }
            
        case AuthEnum.SET_USER_PROFILE:
            return{
                ...state,
                User:{
                    ...state.User,
                    profile:{
                        ...state.User.profile,
                        ...action.payload
                    }
                }
            }
        case AuthEnum.SET_USER_SETTINGS:
            return{
                ...state,
                User:{
                    ...state.User,
                    settings:{
                        ...state.User.settings,
                        ...action.payload
                    }
                }
            }        
        case AuthEnum.SET_PROFILE:
            return {
                ...state,
                User:{
                    ...state.User,
                    avatar:action.payload.avatar as string,
                    profile:{
                        ...state.User.profile,
                        about:action.payload.about,
                        fullName:action.payload.fullName,
                        username:action.payload.username
                    },
                    settings:{
                        ...state.User.settings,
                        investmentApp:action.payload.investmentApp,
                        timezone:action.payload.timezone,
                        translateEvents:action.payload.translateEvents,
                        notificationsMode:action.payload.notificationsMode,
                    }
                }
            }
            
        case AuthEnum.SET_LOADING:
            return {...state, Loading:action.payload}
        case AuthEnum.SET_ERROR:
            return {...state,Error:action.payload}
        case AuthEnum.SET_USER:
            return {...state,User:action.payload}
        case AuthEnum.SET_GLOBAL_ISAUTH:
            return {...state, isAuth:action.payload}
        case AuthEnum.SET_PLANE:
            return {...state, User:{...state.User,tariff:action.payload}}
        case AuthEnum.SET_SEND_TYPE:
            return {...state,User:{...state.User,sendType:action.payload}}
        default: return state
    }
}


