import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {Provider} from "react-redux";
import {store} from './redux'
import {BrowserRouter} from "react-router-dom";
import { YMInitializer } from 'react-yandex-metrika';

ReactDOM.render(
    <Provider store={store}>
        <BrowserRouter>
        <React.StrictMode>
            <Suspense fallback=" ">
                 <YMInitializer accounts={[89358397]}  options={{webvisor: true}} version="2" />
                <App/>
            </Suspense>
        </React.StrictMode>
        </BrowserRouter>
    </Provider>,
    document.getElementById('root')
);

reportWebVitals();
