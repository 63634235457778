import i18next from "i18next"
import { useTranslation } from "react-i18next"
import { gettOrigin } from "../../Utills/ClearFunctions"
import React from "react"
function FreeEventTimeLine({trendHiddenCounts}:{trendHiddenCounts:number}){
    const {t} = useTranslation()
    return(
        <>
        <div className="card card-custom card-shadow card-stock mb-6"
             style={{filter: 'blur(4px)', pointerEvents: 'none'}}>
            <div className="card-header flex-nowrap border-0 pl-2 pr-2">
                <div className="card-title align-items-start d-flex align-items-center">
                    <a href={`${gettOrigin()}/stocks/WW`}>
                        <div className="symbol symbol-35 symbol-circle mx-3">
                            <img alt="logo" src="/img/stocks/WW.png"/>
                        </div>
                    </a>
                    <div className="d-flex flex-column">
                        <div>
                    <span className="card-label font-weight-bolder font-size-h5">
                        <a href={`${gettOrigin()}/stocks/WW`}
                           className="text-dark line-height-sm text-hover-primary">
                            WW International <span className="font-weight-bolder font-size-h3 text-danger">↓</span>
                        </a>
                    </span>
                        </div>
                        <div className="text-muted font-weight-bolder font-size-lg">
                        <a href={`${gettOrigin()}/stocks/WW`} className="text-muted text-hover-primary">$WW</a>
                        </div>
                    </div>
                </div>
                <div className="card-toolbar flex-column align-items-end mr-2">
                    <div className="font-weight-bolder font-size-h5">
                        18,47&nbsp;$
                    </div>
                    <div className="badge badge-danger font-weight-bolder font-size-md py-1 mt-1">
                        -13.33%
                    </div>
                </div>
            </div>
            <div className="card-body pt-0 pb-4">
                <div className="d-flex mb-1">
                    <div className="timeline timeline-6 mt-3">
                        <div className="timeline-item align-items-start mb-4">
                            <div className="timeline-label">
                            </div>
                            <div className="timeline-badge text-primary font-weight-bolder text-nowrap">
                                18 нояб.
                            </div>
                            <div className="timeline-content"></div>
                        </div>
                        <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">
                            <div className="timeline-label font-weight-bolder text-muted font-size-lg">22:35</div>
                            <div className="timeline-badge"><i className="fas fa-volume-up text-warning"></i></div>
                            <div className="font-size-md timeline-content pl-3">
                                <div className="event--original-text"><b>`${i18next.t('EVENT_FINTWEET')}`: </b>WW to hold a virtual
                                    investor event
                                </div>
                                <div className="event--translated-text"><b>`${i18next.t('EVENT_FINTWEET')}`: </b>WW проведет виртуальное
                                    мероприятие для инвесторов
                                </div>
                                <div className="d-flex align-items-center">
                                    <div className="mt-1 font-size-sm">
                                        <a target="_blank"
                                           href="https://twitter.com/algobotnews/status/1461357296471056405">@algobotnews</a> | <a
                                        className="event--original-text" href="">{t('EVENT_TRANSLATE')}
                                        </a><a
                                        className="event--translated-text" href=""> {t('EVENT_ORIGINAL')}</a>
                                    </div>
                                    <a className="ml-auto mt-1 font-size-sm text-primary" href=""
                                       data-toggle="modal" data-event-id="235548" data-pjax="0"><span
                                        className="far fa-comments"></span> </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-between mt-2">
                    <a className="font-size-sm text-primary" data-pjax="0" href={`${gettOrigin()}/stocks/WW`}>Все
                        события</a>
                </div>
            </div>
        </div>
            <div className="timeline-item align-items-start mb-4 mt-5">
                <div className="timeline-label font-weight-bolder text-muted font-size-lg"></div>
                <div className="font-size-md timeline-content">
                    <div className="alert alert-light">
                        <i className="fas fa-info-circle font-size-sm"></i><b> 
                            &nbsp;{t("LOCKED_TRENDS_COMPANIES",{count:trendHiddenCounts})}
                             </b>
                        <p>{t('LOCKED_TRENDS_COMPANIES_TEXT')}</p>
                        <a data-pjax="0" href={`${gettOrigin()}/finance/subscription`}
                           className="btn btn-primary btn-sm font-weight-bolder">
                            {t('LOCKED_TRENDS_COMPANIES_BUTTON')}
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FreeEventTimeLine
