import {t} from 'i18next';
import React, {useCallback, useMemo} from 'react';
import {Modal} from "react-bootstrap";
import useChartModal from "./useChartModal";

interface CommentsModal {
    show: boolean,
    handleClose: () => void,
    events: Array<any>
}

function ChartEventsModal({show, handleClose, events}: CommentsModal) {
    const {elements} = useChartModal({show, handleClose, events})
    return (<>
        {
           <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title">{t('STOCK_CHART_MORE_EVENTS')}</h5>
                    </Modal.Header>
                    <div className={'modal-body pt-2'}>
                        {elements}
                    </div>
                    <Modal.Footer></Modal.Footer>
           </Modal>
        }
    </>);
}

export default React.memo(ChartEventsModal)