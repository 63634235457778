import React from "react";
import {useTransition} from "../../../hooks/useTransition";
import { gettOrigin } from "../../../Utills/ClearFunctions";

export const WaitingOutButton = () => {
    const {logOutTransition} = useTransition()
    return <div className="dropdown-menu dropdown-menu-right show"
                style={{
                    position: 'absolute',
                    transform: 'translate3d(-95px, 45px, 0px)',
                    top: '0px',
                    left: '0px',
                    willChange: 'transform'
                }}
                x-placement="bottom-end">

        <a className="dropdown-item" data-method="post" onClick={(e) => {
            e.preventDefault();
            logOutTransition()
        }}
           href={`${gettOrigin()}/site/logout`} >Выйти</a>
    </div>
}


interface IWaitingPageHeader {
    handlerProfileClickWaitingPage: () => void,
    WaitingPageDropDown: boolean | undefined
}

export const WaitingDropDownMenu = React.memo(({
                                                   handlerProfileClickWaitingPage,
                                                   WaitingPageDropDown
                                               }: IWaitingPageHeader) => {
    return <div onClick={(e) => e.stopPropagation()}>
        <div className="dropdown dropdown-inline">
            <div data-toggle="dropdown"
                 onClick={() => {
                     handlerProfileClickWaitingPage()
                 }}
                 className="btn p-1" id="avatar-wrapper">
                <img alt="avatar" 
                src={`${gettOrigin()}/img/default-avatar.jpg`}
                     className="rounded-circle avatar"
                     style={{width: '36px', height: '36px'}}
                />
            </div>

            {
                WaitingPageDropDown && <WaitingOutButton/>
            }

        </div>
    </div>
})

interface IWaitingToggleMenu {
    isStockPage: boolean,
    setIsOpenSideMenu: (flag: boolean) => void,
    isOpenSideMenu: boolean
}

export const WaitingToggleMenu = React.memo(({setIsOpenSideMenu, isStockPage, isOpenSideMenu}: IWaitingToggleMenu) => {
    return <div className='topbarStockItems' onClick={(e) => e.stopPropagation()}>
                                            <span style={{cursor: 'pointer'}}
                                                  className={(!isStockPage) ? "svg-icon svg-icon-xl sideMenuStockButton" : "svg-icon svg-icon-xl sideMenuButton"}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px"
                                                     viewBox="0 0 24 24"
                                                     className='menuToggle' onClick={(e) => {
                                                    setIsOpenSideMenu(!isOpenSideMenu)
                                                }}>


                                                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                                                <rect x="0" y="0" width="24" height="24"></rect>
                                                <path
                                                    d="M2 11.5C2 12.3284 2.67157 13 3.5 13H20.5C21.3284 13 22 12.3284 22 11.5V11.5C22 10.6716 21.3284 10 20.5 10H3.5C2.67157 10 2 10.6716 2 11.5V11.5Z"
                                                    fill="black"></path>
                                                <path opacity="0.5" fillRule="evenodd" clipRule="evenodd"
                                                      d="M9.5 20C8.67157 20 8 19.3284 8 18.5C8 17.6716 8.67157 17 9.5 17H20.5C21.3284 17 22 17.6716 22 18.5C22 19.3284 21.3284 20 20.5 20H9.5ZM15.5 6C14.6716 6 14 5.32843 14 4.5C14 3.67157 14.6716 3 15.5 3H20.5C21.3284 3 22 3.67157 22 4.5C22 5.32843 21.3284 6 20.5 6H15.5Z"
                                                      fill="black"></path>
                                                </g>
                                                </svg>
                                            </span>
    </div>
})





