import {ValueIsInArray} from "../Utills/ClearFunctions";
import {StatusTypes} from "../models/User";
import {useTypedSelector} from "./useTypedSelector";
import {useLocation} from "react-router-dom";

export function  useCheckForPage() {
    const User = useTypedSelector(state => state.UserReducer.User)
    const location = useLocation()

    const checkForFavoritesPage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const MatchSelectFavoritesUrls = ['select-favourites']
        if (MatchSelectFavoritesUrls.includes(lastPath)) return true
        return false
    }

    const checkForStockPage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const matchProfileUrls = ['notifications', 'muted-stocks','movers','market-map']
        const isInArray = !ValueIsInArray(location.pathname, matchProfileUrls)

        if ( (location.pathname.includes('stocks') || location.pathname.includes('coins')) && locationArray.length > 2 && isInArray) {
            return true
        } else return false
    }
    const checkForStocksPage = () => {
        const locationArray = location.pathname.split('/')

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        const isInArray = !ValueIsInArray(location.pathname, matchProfileUrls)
        if ( (location.pathname.includes('stocks') || location.pathname.includes('coins')) && isInArray && locationArray.length <= 2) {
            return true
        } else return false
    }

    const checkForStockPageSideBar = () => {

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        const isInArray = !ValueIsInArray(location.pathname, matchProfileUrls)
        if (( (location.pathname.includes('stocks') || location.pathname.includes('coins'))|| location.pathname.includes('coins')) && isInArray) {
            return true
        } else return false
    }

    const checkForProfilePage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        if (matchProfileUrls.includes(lastPath)) return true
        else return false
    }

    const checkForNotProfilePage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        if (!matchProfileUrls.includes(lastPath)) return true
        else return false
    }

    const checkForProfilePageSideBar = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        if (!matchProfileUrls.includes(lastPath) && lastPath === 'profile') return true
        else return false
    }

    const checkForModalPages = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const matchProfileUrls = ['notifications', 'muted-stocks', 'read-news']
        if (matchProfileUrls.includes(lastPath)) return true
        else return false
    }


    const checkForWaitingPage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const MatchWaitingUrls = ['waiting']
        if(User.status === StatusTypes.PENDING) return true
        if (MatchWaitingUrls.includes(lastPath)) return true
        return false
    }

    const checkForInvitesPage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const MatchWaitingUrls = ['invites']
        if (MatchWaitingUrls.includes(lastPath)) {
            return true
        }
        return false
    }

    const checkForSubscriptionPage= () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]
        const MatchWaitingUrls = ['subscription']
        if (MatchWaitingUrls.includes(lastPath)) {
            return true
        }
        return false
    }

    const checkForUserProfilePage = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]
        return lastPath.includes('user')
    }

    const checkForComments = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]
        const MatchWaitingUrls = ['comments']
        if (MatchWaitingUrls.includes(lastPath)) {
            return true
        }
        return false
    }
    const checkForFavorites = () => {
        const locationArray = location.pathname.split('/')
        const lastPath = locationArray[locationArray.length - 1]

        const MatchWaitingUrls = ['favourites']
        if (MatchWaitingUrls.includes(lastPath)) {

            return true
        }
        return false
    }

    const checkForEventPage = () => {
        return !checkForFavorites() &&  !checkForProfilePageSideBar() && !checkForStockPageSideBar() && !checkForModalPages()
    }

    return {
        checkForUserProfilePage,
        checkForFavoritesPage,
        checkForComments,
        checkForInvitesPage,
        checkForSubscriptionPage,
        checkForWaitingPage,
        checkForProfilePage,
        checkForStockPage,
        checkForFavorites,
        checkForEventPage,
        checkForStockPageSideBar,
        checkForProfilePageSideBar,
        checkForNotProfilePage,
        checkForStocksPage
    }
}


