import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import {useTransition} from "../../hooks/useTransition";
import {EventTopicEnum} from "../../models/EventContentModel";


interface ICommentCard {
    isCommentOpen: boolean | undefined,
    callback: (show: boolean) => void,
    show: boolean,
    id: string | number | undefined,
    isTimeLineElement?: boolean,
    countComments?: number
}

function CommentCard({isCommentOpen, id, callback, show, countComments}: ICommentCard) {
    const {commentTransition} = useTransition()
    const {t} =useTranslation()
    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        commentTransition(callback, show, id)
    }

    const flagForComments = window.location.pathname.includes('stocks') && window.location.pathname.split('/').length === 3
    const flagForCommentsPartTwo = JSON.parse(localStorage.getItem('currentTopic') as string) === EventTopicEnum.TREND && (window.location.pathname === '/'
        || window.location.pathname ==='/events'
        || window.location.pathname === '/page_loaded'
        || window.location.pathname === '/taptic')
    //fixme it is awful
    return <>
        {
            (isCommentOpen && !window.location.pathname.includes('events/comments')) &&
            <a
                onClick={showModal} className="ml-auto mt-1 font-size-sm text-primary"
                href="" data-toggle="modal"
                data-event-id="332244" data-pjax="0">
                <span className="far fa-comments"></span>
                {
                    (flagForComments || flagForCommentsPartTwo)
                        ? <>
                            {
                                (countComments !== 0 && countComments  ) && <span> {countComments} </span>
                            }
                        </>
                        : <>&nbsp;
                            {t("EVENT_DISCUSS",{count:countComments})}
                        </>
                }
            </a>
        }
    </>
}

export default React.memo(CommentCard)