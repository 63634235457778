import { PlaneTypes } from "../../models/User";
import { DeviceTypeEnum } from "../../models/Subscriptions";
import { FilterConstant, IListStruct } from "../../models/FilterEvent";
import TimeLineFluctuation from "../../components/TimeLine/TimeLineFluctuation";
import { IPeriod } from "../../redux/reducers/UserEvent/types";
import { ORIGIN } from "../../models/Domen";
import i18next from "i18next";
import ym from 'react-yandex-metrika';

export function debounce(f: any, ms: number) {
  let isCooldown = false;
  return function () {
    if (isCooldown) return;
    //@ts-ignore
    f.apply(this, arguments);

    isCooldown = true;
    setTimeout(() => (isCooldown = false), ms);
  };
}

export const checkForSendYEvent = (goal: string) => {
  if (!window.localStorage.getItem(goal)) {
    ym("reachGoal", goal);
    window.localStorage.setItem(goal, "true");
  }
};

export const getCookie = (name: string) => {
  let matches = document.cookie.match(
    new RegExp(
      "(?:^|; )" +
        name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") +
        "=([^;]*)"
    )
  );
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const getNumberSign = (value: number) => {
  return value > 0 ? "+" : "";
};

export const getTickerFromSharedData = () => {
  //@ts-ignore
  return window._sharedData.stock.ticker;
};

export const castToNull = (value: string): any => {
  if (value === "null") return null;
  return value;
};

export const SubscriptionIsOver = (finishedAt: number | null) => {
  if (!finishedAt) return true;
  const dateNow = Date.now();
  return dateNow - finishedAt * 1000 > 0;
};

export function nFormatter(num: number, digits: number = 0) {
  const lookup = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "K" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var item = lookup
    .slice()
    .reverse()
    .find(function (item) {
      return num >= item.value;
    });
  return item
    ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
    : "0";
}

export const showInstallApplicationAlert = (userAgent: string) => {
  if (DeviceIsMobileBrowser(userAgent)) {
    if (getCookie("hideInstallModal")) return false;
    else return true;
  }
  return false;
};

export const createLInkElement = (href: string, id: string) => {
  const link = document.createElement("link");
  link.rel = "stylesheet";
  link.type = "text/css";
  link.href = href;
  link.id = id;
  document.head.append(link);
};

export const deviceIsIphoneApplication = () => {
  if (
    window.navigator.userAgent.includes("Tradesense") &&
    (window.navigator.userAgent.includes("iPhone") ||
      window.navigator.userAgent.includes("iPad"))
  )
    return true;
  else return false;
};

export const deleteLinkElement = (id: string) => {
  const link = document.getElementById(id);
  if (!link) return;
  link?.remove();
};

export const loadScript = (src: string, id: string) => {
  const script = document.createElement("script");
  script.src = src;
  script.id = id;
  document.head.append(script);
};

export const deleteRecapthaIframe = () => {
  const recapthaContainer = document.querySelector(".grecaptcha-logo");
  if (!recapthaContainer) return;
  recapthaContainer.remove();
};
export const deleteScript = (id: string) => {
  deleteRecapthaIframe();
  const capthaScript = document.getElementById(id);
  capthaScript?.remove();
};

export const getPlaneString = (plane: PlaneTypes) => {
  switch (plane) {
    case PlaneTypes.FREE:
      return "Free";
    case PlaneTypes.LITE:
      return "Lite";
    case PlaneTypes.PRO:
      return "Pro";
  }
};

export const isFreePlane = (Plane: PlaneTypes, finishedAt: number | null) => {
  if (Plane === PlaneTypes.FREE || !finishedAt) return true;
  else return false;
};

export const isFirstTime = () => {
  if (!localStorage.getItem("isFirstTime")) {
    localStorage.setItem("isFirstTime", "false");
    return true;
  } else return false;
};

export const deviceIsMobile = () => {
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("Android")
  )
    return true;
};

export const gettOrigin = () => {
  if (ORIGIN.includes("localhost") || ORIGIN.includes("react"))
    return "https://tradesense.ru";
  else return ORIGIN;
};

export const DeviceIsIOSMobileBrowser = () => {
  if (window.navigator.userAgent.includes("Tradesense")) return false;
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("iPad")
  )
    return true;
  else return false;
};
export const checkForCIS = (region: string): boolean => {
  const CISmap = new Map([
    ["RU", "RU"],
    ["KZ", "KZ"],
    ["BY", "BY"],
    ["UA", "UA"],
  ]);
  if (CISmap.get(region)) return true;
  return false;
};
export const DeviceIsMobileBrowser = (currentUserAgent: string = "") => {
  if (window.navigator.userAgent.includes("Tradesense")) return false;
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("Android") ||
    window.navigator.userAgent.includes("iPad")
  )
    return true;
  else return false;
};

export const DeviceIsDesctop = () => {
  if (window.navigator.userAgent.includes("Tradesense")) return false;
  if (
    !window.navigator.userAgent.includes("iPhone") &&
    !window.navigator.userAgent.includes("Android") &&
    !window.navigator.userAgent.includes("iPad")
  )
    return true;
  return false;
};

//DeviseIsApplicationOnly

export const DeviseIsAndroidOnly = () => {
  if (window.navigator.userAgent.includes("Tradesense")) {
    if (window.navigator.userAgent.includes("Android")) return true;
    else return false;
  } else return false;
};

export const DeviseIsApplicationOnly = () => {
  if (
    !window.navigator.userAgent.includes("iPhone") &&
    !window.navigator.userAgent.includes("Android") &&
    !window.navigator.userAgent.includes("iPad")
  )
    return false;
  if (window.navigator.userAgent.includes("Tradesense")) return true;
  else return false;
};

export const deviceIsAppleApplicationOnly = () => {
  if (!window.navigator.userAgent.includes("Tradesense")) return false;
  if (
    !window.navigator.userAgent.includes("iPhone") &&
    !window.navigator.userAgent.includes("Android")
  )
    return false;
  if (window.navigator.userAgent.includes("Android")) return false;
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("iPad")
  )
    return true;
};

export function urlToFile(url: string, filename: string, mimeType: string) {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    });
}
export const ClearDeviceIsMobile = () => {
  if (
    window.navigator.userAgent.includes("iPhone") ||
    window.navigator.userAgent.includes("Android") ||
    window.navigator.userAgent.includes("iPad")
  )
    return true;
  if (window.navigator.userAgent.includes("Tradesense")) return true;
  else return false;
};
export const DeviceIsMobile = (currentUserAgent: string) => {
  if (
    currentUserAgent === DeviceTypeEnum.TRADE_SENSE ||
    currentUserAgent === DeviceTypeEnum.APPLE ||
    currentUserAgent === DeviceTypeEnum.ANDROID
  ) {
    return true;
  } else {
    return false;
  }
};

export const filterIsSelected = (filterList: Array<IListStruct>) =>
  filterList.every((listElement) => {
    if (
      listElement.id === FilterConstant.DELETE_ALL ||
      listElement.id === FilterConstant.ALL_EVENTS
    )
      return true;
    return listElement.check;
  });

export const deleteLoginStyle = () => {
  const sheet = document.getElementById("loginStyles") as HTMLLinkElement;
  if (sheet) {
    sheet.disabled = true;
  }
};

export const getLoginStyle = () => {
  const sheet = document.getElementById("loginStyles") as HTMLLinkElement;
  if (sheet) {
    sheet.disabled = false;
  }
};

export const staticScrollHandler = (target: any) => {
  target.classList.add("dropDownScroll");
  const FilterNode = target.children[0];
  FilterNode.classList.add("ContainerFilterNone");
  FilterNode.classList.remove("ContainerFilter");
  target.classList.remove("dropDownScroll");
  const text = FilterNode.children[0];
  text.classList.add("filtersNone");
};

export const stickyScrollHandler = (target: any) => {
  target.classList.add("dropDownScroll");
  const FilterNode = target.children[0];
  FilterNode.classList.remove("ContainerFilterNone");
  FilterNode.classList.add("ContainerFilter");
  const text = FilterNode.children[0];
  text.classList.remove("filtersNone");
};

export const scrollHandlerDropDown = (initialScrollY: number) => {
  let target = document.querySelector(".dropDown") as HTMLDivElement;

  if (!target) {
    return;
  }
  // const filter = JSON.parse(decodeURIComponent(getCookie('eventTypes') as string))
  const filter = JSON.parse(localStorage.getItem("eventTypes") as string);

  if (filterIsSelected(filter)) {
    target.style.position = "static";
    staticScrollHandler(target);
    return;
  } else {
    if (window.scrollY >= initialScrollY + 45) {
      target.style.position = "sticky";
      stickyScrollHandler(target);
    } else {
      target.style.position = "static";
      staticScrollHandler(target);
    }
  }
};

export const isCloseAnchor = (
  userAgent: string,
  pathname: string,
  isApplication: boolean
): boolean => {
  if (!isApplication) return false;
  const checkForSpecialStockPageCase = () => {
    const url = window.location.host + pathname;
    return (
      url.includes("stocks") &&
      (url.split("/").length === 3 ||
        url.includes("events") ||
        url.includes("company"))
    );
  };

  if (DeviceIsMobile(userAgent)) {
    const MatchCloseName = [
      "/stocks",
      "/events/comments",
      "/finance/subscription",
      "/profile/notifications",
      "/profile/invites",
      "/profile/muted-stocks",
    ];

    if (checkForSpecialStockPageCase()) return true;
    const MatchCloseURL = MatchCloseName.map(
      (name) => window.location.host + name
    );
    if (window.location.href.includes("market-map")) return true;
    for (let i = 0; i < MatchCloseURL.length; i++) {
      if (window.location.host + pathname === MatchCloseURL[i]) {
        return true;
      }
    }
  }
  return false;
};

export const closeSearchView = () => {
  const search = document.querySelector(".SearchContainer");
  if (!search) return;
  if (!search!.classList.contains("SearchContainer_disabled")) {
    search!.classList.add("SearchContainer_disabled");
  }
};

export const openSearchView = () => {
  const search = document.querySelector(".SearchContainer");
  if (!search) return;
  if (search!.classList.contains("SearchContainer_disabled")) {
    search!.classList.remove("SearchContainer_disabled");
  }
  //@ts-ignore
  const searchInput = document.querySelector("#search-company");
  //@ts-ignore
  searchInput.focus();
};

export const getPeriodObj = (idPeriod: number) => {
  let periodStr: IPeriod = {
    reduction: "",
    full: "",
  };
  switch (idPeriod) {
    case 1:
      periodStr = {
        full: i18next.t("EVENTS_TRENDS_GROUP_TITLE_5M"),
        reduction: i18next.t("EVENTS_TRENDS_TIME_5M"),
      };
      break;
    case 2:
      periodStr = {
        full: i18next.t("EVENTS_TRENDS_GROUP_TITLE_1H"),
        reduction: i18next.t("EVENTS_TRENDS_TIME_1H"),
      };
      break;
    case 3:
      periodStr = {
        full: i18next.t("EVENTS_TRENDS_GROUP_TITLE_1D"),
        reduction: i18next.t("EVENTS_TRENDS_TIME_1D"),
      };
      break;
  }
  return periodStr;
};

export const getPercent = (price: number, oldPrice: number) => {
  const diff = price - oldPrice;
  let isUp = false;
  if (diff > 0) isUp = true;
  else isUp = false;

  const percentFullNumber = (diff / oldPrice) * 100;
  const percent = Math.floor(percentFullNumber * 100) / 100;
  return [percent, isUp];
};

export const ValueIsInArray = (
  value: string,
  array: Array<string>
): boolean => {
  const newArray = array.filter((ArrayElement) => {
    return value.includes(ArrayElement);
  });
  return newArray.length > 0;
};
