import React, { useMemo } from "react"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { REVIEW } from "../../models/Review"
import { deviceIsAppleApplicationOnly } from "../../Utills/ClearFunctions"
interface IIPhoneAlert {
    payHandler: (e: React.MouseEvent<HTMLAnchorElement>) => void
}
function IphoneAlert({ payHandler }: IIPhoneAlert) {
    const region = useTypedSelector(state => state.UserReducer.User.settings.region)
    const IphoneAlertJsx = () => {
        const isReView = window.navigator.userAgent.includes(REVIEW) && window.navigator.userAgent.includes('iOS')
        //@ts-ignore
        const isShow = deviceIsAppleApplicationOnly() && region === 'RU' && window._sharedData.user.phone.includes('+7') && !isReView
        if (isShow)
            return <div className="row mt-4">
                <div className="col-lg-12 text-center mb-6">
                    <div className="alert bg-light-warning font-size-md text-default">
                        Если вы испытываете трудности с оплатой средствами Apple, то можете оформить подписку через платежную систему ЮMoney, где доступны следующие способы оплаты
                        <img alt="" src="/img/card-visa.svg" />
                        <img alt="" src="/img/card-mastercard.svg" />
                        <img alt="" src="/img/card-mir.svg" />
                        <a onClick={payHandler} className="btn btn-primary btn-md btn-block font-weight-bolder mt-2">
                            <i className="fa fa-globe"></i> Оплатить по карте </a>
                    </div>
                </div>
            </div>
    }

    return <>
        {IphoneAlertJsx()}
    </>
}
export default React.memo(IphoneAlert)

