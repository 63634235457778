import React from "react";
import AnalyticBody from "./AnalyticBody";


function AnalyticsWidget() {


    return(
        <div>
            <div className="card card-custom p-4 mb-8">
                <AnalyticBody/>
            </div>
        </div>
    )
}

export default  React.memo(AnalyticsWidget)