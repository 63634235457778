import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {useEffect, useState} from "react";
import EventTime from "../Time/EventTime";
import CommentsModal from "../Modals/CommentsModal";
import {useTransition} from "../../hooks/useTransition";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import { useTranslation } from "react-i18next";


interface IFluctuation {
    fromPrice: string,
    companyName: string
    toPrice: string,
    ticker: string,
    isUp: boolean,
    percent: string,
    time:string,
    Comment:any,
    isCommentOpen?:boolean
    CardInformation:ICompanyInformationCard
    id?:string,
    countComments?:number
    mediumTime?:string | number | null

}


function Fluctuation(props: IFluctuation) {
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {checkForStockPage} = useCheckForPage()
    const {t} =useTranslation()

    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.PRICE_FLUCTUATIONS,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }


    return (
        <>
            <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.PRICE_FLUCTUATIONS} data={{...{...props,isCommentOpen:false}}}/>

            {/*<EventTime time={props.time}/>*/}
            {/*<div id="event-400264"*/}
            {/*     className="js__event-card event-wrapper event-wrapper-translated d-flex flex-lg-fill card card-custom card-shadowless p-5 bg-gray-100 text-dark mb-4">*/}

                {/*<a data-id="465252" href="#" className="js__remove-event text-hover-danger remove-event-button">*/}
                {/*    <i className="position-absolute right-0 top-0 fas fa-trash-alt mt-5 mr-5"></i>*/}
                {/*</a>*/}

                <span className="font-weight-bolder mb-2">
                {
                    (props.isUp)
                        ? <i className="fas fa-chevron-circle-up text-success mr-2"></i>
                        : <i className="fas fa-chevron-circle-down text-danger mr-2"></i>
                }


                    {t('EVENT_PRICE_MOVEMENT')}</span>
                <span className="p-2">
                <span className="font-weight-bolder">{props.fromPrice}&nbsp;$ →
                    {
                        (props.isUp)
                            ? <>&nbsp;<span className="text-success">{props.toPrice}&nbsp;$ ({props.percent}%)</span></>
                            : <>&nbsp;<span className="text-danger">{props.toPrice}&nbsp;$ ({props.percent}%)</span></>
                    }
                </span>
            </span>


            {
                props.Comment
            }


                { !checkForStockPage() &&  <CompanyInformationCard {...{...props.CardInformation,isNotShowPriceDynamics:true}} /> }
               

        </>
    )
}

export default Fluctuation