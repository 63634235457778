import React, {useRef, useState} from "react";
import CropModal from "../Modals/CropModal";
import Cropper from "react-cropper";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import SaveUserModal from "../Modals/SaveUserModal";
import { ORIGIN } from "../../models/Domen";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {UseActions} from "../../hooks/useActions";
import '../../styles/HeaderProfile.css'
import { useTranslation } from "react-i18next";

interface IHeaderProfile{
    imgUrl:null | string | ArrayBuffer,
    setImgUrl:(value:any) => void
}

function HeaderProfile({setImgUrl,imgUrl} :IHeaderProfile) {
    const User = useTypedSelector(state => state.UserReducer.User)
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const [PrevImg,setPrevImg] = useState<string | ArrayBuffer | null>(imgUrl)
    const [showCropModal, setShowCropModal] = useState<boolean>(false);
    const handleCloseCropModal = () => {
        setShowCropModal(false)
    };

    const handleShowCropModal = () => setShowCropModal(true);

    const changeFile = (e: any) => {
        const reader = new FileReader();

        reader.onloadend = function () {
            setPrevImg(reader.result)
            const dt = new DataTransfer();
            const file_list = dt.files;
            handleShowCropModal()
            e.target.files = file_list
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const cropperRef = useRef<HTMLImageElement>(null);
    const [cropData, setCropData] = useState("#");
    const [cropper, setCropper] = useState<any>();

    const onCrop = () => {
        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
    };

    const getCropData = () => {
        if (typeof cropper !== "undefined") {
            setCropData(cropper.getCroppedCanvas(  {  width: 360,
                height: 360}).toDataURL());
            setImgUrl(cropper.getCroppedCanvas({  width: 360,
                height: 360}).toDataURL())
        }
    };

    const acceptCrop = () => {
        getCropData()
        setShowCropModal(false)
    }
    const [showUserModal,setShowUserModal] = useState<boolean>(false)
    const isChanged = useTypedSelector(state => state.Profile.isChanged)
    const MySwal = withReactContent(Swal)
    const {setSaveProfile} = UseActions()
    const {t} =useTranslation()

    const closeUserModal = () => {
        if(isChanged){
            MySwal.fire({
                title: <p> {t('PROFILE_SAVE_PROMPT')}</p>,
                showConfirmButton: false,
                showDenyButton: true,
                showCancelButton: true,
                denyButtonText: `Да`,
                cancelButtonText: `Нет`
            }).then((result) => {
                if(result.isDenied){
                    setShowUserModal(false)
                    setSaveProfile()
                }
            })
        }
        else{
            setShowUserModal(false)
        }
    }
    const editClick = () => {
        if(isApplication){
            window.location.replace(ORIGIN + '/profile/user')
        }
        else{
            setShowUserModal(true)
        }
    }

    const getProfileInformation = React.useMemo(() => {
        if(User?.profile?.fullName || User?.profile?.about)
            return <>
                <h2 className="text-break font-weight-bolder display-4">
                    {User.profile.fullName}
                </h2>

                <div className="font-weight-bolder font-size-md text-dark-50 mb-2">@{User.profile.username}</div>

                <div className="text-break">
                    {User.profile.about}
                </div>
            </>
        else
            return <>
                <div className={'text=break'}>{t('PROFILE_SETUP_MESSAGE')}</div>
            </>
    },[User])


    const getProfileClass = () => {
        if(User?.profile?.fullName || User?.profile?.about) return 'd-none'
        else return ''
    }

    return (
        <div className="card card-custom col-lg-12 mb-4">

            <CropModal acceptCrop = {acceptCrop} imgUrl={PrevImg } show={showCropModal} handleClose={handleCloseCropModal}>
                <Cropper
                    src={PrevImg as string}
                    // style={{ height: '300px', width: "360px" }}
                    zoomTo={0.2}
                    zoomable={false}
                    initialAspectRatio={1}
                    aspectRatio = {1}
                    preview=".img-preview"
                    viewMode={1}
                    background={false}
                    responsive={false}
                    autoCropArea={1}
                    checkOrientation={false}
                    ref={cropperRef}
                    minCropBoxWidth = {360}
                    minCropBoxHeight = {360}
                    minCanvasWidth = {50}  
                    
                    data ={
                        {width: 240,
                        height:  90,}
                      }
                    onInitialized={(instance) => {
                        instance.setCropBoxData({ height: 350, width: 350  }) //set data
                        setCropper(instance);
                    }}
                />
            </CropModal>
            <SaveUserModal show = {showUserModal} handleClose = {closeUserModal}/>
            <div className="card-body px-2 pb-0">
                <div className="d-flex">
                    <div className="flex-grow-1">
                        <div
                            className="d-flex align-items-start justify-content-between flex-wrap mt-2">
                            <div className="mr-3">
                                <div className="">
                                    <div className="d-flex mb-5">
                                        <div className="image-input mr-7">

                                            <div
                                                className='image-input-wrapper symbol symbol-130 symbol-circle'
                                                style={{backgroundImage: `url(${imgUrl}`}}
                                            >
                                                <label
                                                    className="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                                                    data-action="change" data-toggle="tooltip"
                                                    title=""
                                                    data-original-title= {`${t('PROFILE_CHANGE_AVATAR')}`} >
                                                    <i className="fa fa-pen icon-sm text-muted"
                                                       aria-hidden="true"></i>

                                                    <input onChange={changeFile} type="file" name="profile_avatar"
                                                           accept=".png, .jpg, .jpeg"/>
                                                </label>

                                            </div>

                                            <div className={`d-flex flex-glow-1 flex-column ${getProfileClass()}`} >

                                                {
                                                    getProfileInformation
                                                }

                                                <div className="mt-2">
                                                    <span
                                                     onClick={editClick} 
                                                     className="btn btn-sm btn-light-primary mt-2 editButton">
                                                        <div className = "editWraper">
                                                            <i className="fa fa-pen fa-1x" aria-hidden="true"></i>
                                                            <span>
                                                                {t('PROFILE_EDIT')}
                                                            </span>
                                                        </div>
                                                        
                                                    </span>
                                                </div>
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)}

export default React.memo(HeaderProfile)