import {AuthActionCreators} from "./auth/actions";
import {EventActionCreators} from "./UserEvent/actions";
import {AuthGlobalActionCreators} from './User/actions'
import {FilterActionCreators} from "./filter/actions";
import EventReducer from "./UserEvent";
import UserReducer from './User'
import Filter from './filter'
import TickerReducer from "./Tiker";
import AllEventsReducer from "./allEvents"
import CompanyReducer from "./company";
import FavouritesReducer from "./favouritesEvent"
import TrendsReducer from "./trendsEvent"
import Profile from "./Profile"
import FavouritesCard from "./FavouritesCard"
import BackDropReducer from "./backDrop"
import PageReducer from "./pageInformation";

import {TickerInformationActionCreators} from "./Tiker/action";
import {CompanyInformationActionCreators} from "./company/action";
import {allEventsActionCreators} from "./allEvents/action";
import {FavouritesActionCreators} from "./favouritesEvent/action"
import {TrendsActionCreators} from "./trendsEvent/action"
import {userProfileActionCreator} from "./Profile/action"
import {FavouritesCardsActionCreators} from './FavouritesCard/action'
import {BackDropActionCreators} from "./backDrop/action";
import {PageActionCreators} from "./pageInformation/action";

export default {
    EventReducer,
    UserReducer,
    Filter,
    TickerReducer,
    CompanyReducer,
    TrendsReducer,
    FavouritesReducer,
    AllEventsReducer,
    Profile,
    FavouritesCard,
    BackDropReducer,
    PageReducer
}

export const allActionCreators = {
    ...AuthActionCreators,
    ...EventActionCreators,
    ...AuthGlobalActionCreators,
    ...FilterActionCreators,
    ...TickerInformationActionCreators,
    ...CompanyInformationActionCreators,
    ...allEventsActionCreators,
    ...FavouritesActionCreators,
    ...TrendsActionCreators,
    ...userProfileActionCreator,
    ...FavouritesCardsActionCreators,
    ...BackDropActionCreators,
    ...PageActionCreators
}


