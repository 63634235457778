import { getPercent } from "../Utills/ClearFunctions"
import { UseActions } from "./useActions"
import useFormatCurrency from "./useFormatCurrency"
import { useTransition } from "./useTransition"

export interface IPriceInfo{
    price:number,
    oldPrice:number,
    currency:number,
}
export interface ICompanyCard {
    ticker: string,
    name: string,
    imgSrc:string,
    price?:string,
    oldPrice?:string,
    currency?:string,
    brand?:string,
    isNotShowPriceDynamics?:boolean
    priceInfo: IPriceInfo
}

function useCompanyCard(){
    const {transitionToTicketTest} = useTransition()
    const {setCurrentCompanyEnum} = UseActions()
    const {getPrice} = useFormatCurrency()
    const handleTransitionToCompanyTicker = (props:ICompanyCard) => {
        const [percent, isUp] = getPercent(props.priceInfo.price, props.priceInfo.oldPrice)
        setCurrentCompanyEnum(
            {
                //@ts-ignore
                cardName:props.name || props.brand,
                ticket:props.ticker,
                src:props.imgSrc,
                price:Number(props.priceInfo.price),
                percent:percent as number,
                //@ts-ignore
                oldPrice:props.priceInfo.oldPrice as string,
                currency:props.currency,
                brand:props.brand,
            }
        )
        transitionToTicketTest(props.ticker)
    }
    return {
        handleTransitionToCompanyTicker,
        getPrice,
    }

}
export default useCompanyCard