import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {useEffect, useState} from "react";
import EventTime from "../Time/EventTime";
import {useTransition} from "../../hooks/useTransition";
import {useLocation, useNavigate} from "react-router-dom";
import {UseActions} from "../../hooks/useActions";
import {FilterConstant} from "../../models/FilterEvent";
import CommentsModal from "../Modals/CommentsModal";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import MessageModal from "../Modals/MessageModal";
import { deviceIsMobile } from "../../Utills/ClearFunctions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import {ORIGIN} from "../../models/Domen";
import CommentCard from "./CommentCard";
import useTranslate from "../../hooks/useTranslate";
import { t } from "i18next";

export interface INewLinkCard{
    // date:string,
    // time:string,
    title:string,
    text:string,
    source:string,
    Comment:any,
    isCommentOpen?:boolean,
    // CompanyName?:string,
    // dateFull?:string,
    // sourceName?:string
    translatedText?: string
    translatedTitle?: string
    key?:number,
    id?:number,
    CardInformation:ICompanyInformationCard,
    countComments?:number
    mediumTime?:string | number | null

}


function NewLinkCard(props:INewLinkCard){
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent,setCurrentMessage} = UseActions()
    const {checkForStockPage} = useCheckForPage()

    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const navigate = useNavigate()
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const {transitionToMessagePage,commentTransition} = useTransition()

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    
    const [showRelease, setShowRelease] = useState(false);
    const handleCloseRelease = () => setShowRelease(false);
    const handleShowRelease = () => setShowRelease(true);


    const {isTranslate} = useTranslate(props.title)

    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])

    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        commentTransition(setShow,show, props.id)
    }

    // const transitionToMessage = (e: React.MouseEvent<HTMLAnchorElement>) => {
    //     e.preventDefault()
    //     transitionToMessagePage(handleShowRelease,props)
    // }



    const [isShowTransition, showTransition] = useState<boolean>(false)

    const translateText = () => {
        showTransition(true)
    }
    const backToRussia = () => {
        showTransition(false)
    }

    return(
        <>
            <MessageModal  show={showRelease} handleClose={handleCloseRelease}/>

            <span className="font-weight-bolder mb-2">
                <i className="fas fa-link text-success mr-2"></i> {t('EVENT_NEW_LINK')}</span>
            <span className="p-2 event--translated-text">

            {
                props.translatedTitle || props.translatedText
                    && <>

                        {
                            (!isShowTransition && isTranslate )
                                ? <strong dangerouslySetInnerHTML = {{__html: `${ props.title || ''  }` }} />
                                :(<strong dangerouslySetInnerHTML = {{__html: `${ props.translatedTitle || ''  }` }} /> )
                        }
                        <div>
                            {
                                (!isShowTransition && isTranslate )
                                    ? <span dangerouslySetInnerHTML = {{__html: `${ props.text || ''  }` }} />
                                    :(<span dangerouslySetInnerHTML = {{__html: `${ props.translatedText || ''  }` }} /> )
                            }
                        </div>

                    </>
            }

            <>
                <a target="_blank" href={props.source}>  {props.source.split('/')[2]} </a>
            </>

            </span>



            <div className="d-flex align-items-center">
                <span className="mt-1 font-size-sm">

                    <a target="_blank" href={props.source}>
                       {t('EVENT_SOURCE')}
                    </a>
                    <span>
                        {
                            isTranslate && <> |
                                {
                                    (!isShowTransition )
                                        ?<> <a  onClick={translateText} className="js__original-event event" href="javascript:;">
                                             {t('EVENT_ORIGINAL')}
                                        </a>
                                        </>
                                        : <> <a onClick={backToRussia} className="js__translate-event event" href="javascript:;">
                                            {t('EVENT_TRANSLATE')}
                                        </a>
                                        </>
                                }
                            </>
                        }
                    </span>


                </span>

                {
                    props.Comment
                }

            </div>

            { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }


            {/*</div>*/}
        </>
    )
}

export default NewLinkCard