import React from "react"
import { Trans } from "react-i18next"
import { UseActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import { openSearchView } from "../../Utills/ClearFunctions"
function EmptyFavourites(){
    const {setSearchIsOpen} = UseActions()
    const openSearch = (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        openSearchView()
        setSearchIsOpen(true)
    }

    return (
    <div className="text-center">
        <div>
            <i className="far fa-star fa-2x text-primary p-0 mb-4"></i>
        </div>
        <div>
            <Trans i18nKey={'FAVORITES_EMPTY'}>
                <p>
                    <a href =''onClick={openSearch} className="font-weight-bolder"></a>
                    <i className="fas fa-1x fa-list text-primary"></i>
                </p>

            </Trans>
           
        </div>
    </div>
    )
}

export default EmptyFavourites