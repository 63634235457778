import {IPeriod} from "../UserEvent/types";

export interface ITrendEvent{
    numberNewEvent:number,
    Elements:Array<JSX.Element>
    NewElements:Array<JSX.Element>
    prevEventsFilter:Array<any>
    period:IPeriod
}

export enum allEventEnum{
    SET_TREND_ALL_EVENTS = "SET_TREND_ALL_EVENTS",
    SET_TREND_NUMBER_NEW_EVENT = "SET_TREND_NUMBER_NEW_EVENT",
    SET_TREND_NEW_ELEMENTS = "SET_TREND_NEW_ELEMENTS",
    SET_TREND_FULL_EVENTS = "SET_TREND_FULL_EVENTS",
    SET_PREV_EVENT_FILTER = "SET_PREV_EVENT_FILTER",
    SET_TREND_PERIOD = "SET_TREND_PERIOD",
}

export interface ISetPeriod{
    type:allEventEnum.SET_TREND_PERIOD,
    payload:IPeriod
}

export interface ISetPrevEventFilter {
    type: allEventEnum.SET_PREV_EVENT_FILTER
    payload: Array<any>
}
export interface ISetFullEvent{
    type: allEventEnum.SET_TREND_FULL_EVENTS
    payload: Array<JSX.Element>
}

export interface  ISetTrends{
    type: allEventEnum.SET_TREND_ALL_EVENTS,
    payload: Array<JSX.Element>
}

export interface ISetNumberNewEvent{
    type: allEventEnum.SET_TREND_NUMBER_NEW_EVENT,
    payload: number,
}

export interface ISetNewEvent{
    type:allEventEnum.SET_TREND_NEW_ELEMENTS,
}


export type actionType = ISetTrends | ISetPeriod | ISetPrevEventFilter | ISetNumberNewEvent | ISetNewEvent | ISetFullEvent