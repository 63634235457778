import useDate from "../../../hooks/useDate"
import { FilterConstant } from "../../../models/FilterEvent"
import { dataHandler } from "./DataHandler"
import i18n from "i18next"
function HeaderSwtch(type:FilterConstant, createdAt:number,timezone:string,language:string ){
    const fullDate = dataHandler(createdAt,timezone,language)

    switch (type) {
        case FilterConstant.PRICE_FLUCTUATIONS:
            return `${i18n.t('NOTIFICATIONS_PRICE_MOVEMENT')} (${fullDate})`
        case FilterConstant.STOP_EVENT:
            return `${i18n.t('EVENT_SPB_HALT')} (${fullDate})`
        case FilterConstant.YEARLY_MINIMUM_MAXIMUM:
            return `${i18n.t('EVENT_PRICE_52HIGHTLOW')} (${fullDate})`
        case FilterConstant.PRESS_RELEASES:
            return `${i18n.t('EVENT_PRESS_RELEASE')} (${fullDate})`
        case FilterConstant.TWEETS_OF_POPULAR_PEOPLE:
            return `${i18n.t('EVENT_INFLUENCER_TWEET')} (${fullDate})`
        case FilterConstant.MEDIA_PUBLICATIONS:
            return `${i18n.t('NOTIFICATIONS_MEDIA_MENTION')} (${fullDate})`
        case FilterConstant.FINANCIAL_TWEETS:
            return `${i18n.t('EVENT_FINTWEET')} (${fullDate})`
        case FilterConstant.FDA:
            return `${i18n.t('EVENT_FDA')} (${fullDate})`
        case FilterConstant.FAA:
            return `${i18n.t('EVENT_FAA')} (${fullDate})`
        case FilterConstant.ABSORPTION:
            return `${i18n.t('EVENT_ACQUISITION')} (${fullDate})`
        case FilterConstant.REPORTING:
            return `${i18n.t('NOTIFICATIONS_REPORT')} (${fullDate})`
        case FilterConstant.SEC:
            return `${i18n.t('EVENT_SEC')}SEC (${fullDate})`
        case FilterConstant.INSIDER_CLOSES:
            return `${i18n.t('EVENT_INSIDER')} (${fullDate})`
        case FilterConstant.ANALYST_FORECASTS:
            return `${i18n.t('EVENT_FORECAST')} (${fullDate})`
        case FilterConstant.TARGET_PRICE:
            return `${i18n.t('EVENT_PRICE_TARGET')} (${fullDate})`
        case FilterConstant.ALL_EVENTS:
        case FilterConstant.DELETE_ALL:
            return ''
        default:
            return ''

    }
}

export default HeaderSwtch
// export default LastEventSwitch


// Фильтрация ленты

// Колебания цен

// Годовой минимум/максимум

// Пресс-релизы

// Финансовые твиты

// FDA

// FAA

// Поглощения

// Отчётности

// SEC

// Инсайдерские сделки

// Прогнозы аналитиков

// Целевая цена

// Рыночные данные

// Твиты популярных людей

// Публикации в СМИ
// Очистить