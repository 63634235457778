export const SUBSCRIBE = 'SUBSCRIBE'
export enum DeviceTypeEnum {
    MOBILE = 'MOBILE',
    ANDROID = 'Android',
    APPLE = "iPhone",
    IPAD = 'iPad',
    DESKTOP = 'DESKTOP',
    REVIEW = 'REVIEW',
    SPECIAL_USER = 'SPECIAL_USER',
    TRADE_SENSE = 'Tradesense'
}