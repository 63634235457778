import React, {useContext, useState} from "react";
import AnalyticsModal from "../Modals/AnalyticsModal";
import {useNavigate, useParams} from "react-router-dom";
import {DeviseIsApplicationOnly} from "../../Utills/ClearFunctions";
import {CompanyContext} from "../../Context";
import WidgetLoaders from "../loaders/WidgetLoaders";
import {ORIGIN} from "../../models/Domen";
import { useTranslation } from "react-i18next";

function AnalyticBody(){
    const {ForecastElements,isLoading} = useContext(CompanyContext)
    const navigate = useNavigate()
    const {id} = useParams()
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {t} = useTranslation()

    const handlerModalOpen = () => {
        if(DeviseIsApplicationOnly()){
            window.location.replace(`${ORIGIN}/stocks/${id}/forecasts`)
            return
        }
        handleShow()
    }

    return(
        <>

        {
            isLoading
            ? <>
                <WidgetLoaders/>
                <WidgetLoaders/>
                <WidgetLoaders/>
            </>
            :<>
                    {
                        ForecastElements.length === 0
                            ? <div className="p-4 font-size-lg text-muted">
                                <i className="fas fa-pen-alt text-muted mr-2" aria-hidden="true"></i> {t('STOCK_FORECASTS_EMPTY')}
                            </div>
                            : <>
                                {ForecastElements}
                                <AnalyticsModal show={show} handleClose={handleClose}/>

                                <div className="d-flex align-items-center mt-4">
                                    <a className="js__filter-events-by-type" data-pjax="0"
                                       style={{cursor:"pointer"}} onClick={handlerModalOpen} >
                                        {t('STOCK_SHOW_MORE')}</a>
                                </div>
                            </>
                    }

                </>
        }

        </>


    )
}

export default AnalyticBody