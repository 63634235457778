import { CompanyName, StockPageBackButton, StockPageLogo } from "./StockPageHeader"
import {useEffect} from "react";
import {useTypedSelector} from "../../../hooks/useTypedSelector";


interface IHeaderStockPage{
    handleBackButton: () => void,
    id:string | undefined
}



function HeaderStockPage({handleBackButton, id}:IHeaderStockPage){
    const CurrentCompany = useTypedSelector(state => state.CompanyReducer)

    return (
        <>

        <div className="topbar-item header__logoMobileContainer">
                            <StockPageBackButton handleBackButton={handleBackButton}/>
                            <StockPageLogo handleBackButton={handleBackButton}/>
                        </div>

                        <CompanyName id={ id || CurrentCompany.ticker }/>

                    </>
    )
}
export default HeaderStockPage