import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { UseActions } from "../../../hooks/useActions"
import { IInvite } from "../../../hooks/useInvites"
import UserService from "../../../Services/UserService"
import { Alert, themeAlertEnum } from "../../Alerts"
import {getAvailableInvites} from "./Ready";

function Waiting(props:Partial<IInvite> & {numberEvent:number,setInvites:(Invite:Array<IInvite>) => void}){
    const {setCountEvents} = UseActions()

    const cancelElement = async (e:React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault()
        const response:any = await UserService.cancelInvates( (props.id as number) )

        setCountEvents(getAvailableInvites(response.data.invites).length)
        props.setInvites(response.data.invites)
        setIsOpenAlert(true)
    }
    const [isOpenAlert,setIsOpenAlert] = useState<boolean>(false)

    const removeAlertHandler = () => {
        setIsOpenAlert(false)
    }

    const {t} =useTranslation()

    return (
        <>
        <Alert theme={themeAlertEnum.Success} close={removeAlertHandler} isOpen={isOpenAlert} text={'Профиль сохранен'}>
                <span>{t('INVITES_LINK_COPIED')}</span>
            </Alert>
        <div className="p-1 d-flex">

            

            <div className="d-flex flex-row-fluid">
                <div className="d-flex flex-column pr-5 flex-grow-1">
                    <span className="text-muted font-weight-bold">
                        <s>        {t('INVITES_INVITE_NUMBER',{count:props.numberEvent})} </s>                    </span>
                    <div className="text-dark mb-1 font-weight-bolder font-size-lg">
                        <div className="text-success mb-1 font-weight-bolder font-size-md">
                            {t('INVITES_WAITING_SIGNUP')} 
                            {
                                props.phone && <span> {`(${props.phone})`} </span>
                            }
                        </div>
                    </div>
                </div>
                <div className="d-flex align-items-center py-2">
                    <a href="#" onClick={cancelElement}
                       className="js__cancel-invite btn btn-light-danger font-weight-bolder btn-sm">
                        {t('INVITES_BUTTON_CANCEL')}
                    </a>
                </div>
            </div>
        </div>
        </>
    )
}
export default Waiting