import { WaitingDropDownMenu, WaitingToggleMenu } from "./WaitingPageHeader"

interface IHeaderWaitinPage{
    isStockPage:boolean,
    setIsOpenSideMenu:(flag:boolean) => void
    handlerProfileClickWaitingPage:() => void
    isOpenSideMenu:boolean
    WaitingPageDropDown:boolean | undefined
}

function HeaderWaitinPage({WaitingPageDropDown,
    handlerProfileClickWaitingPage,
    isOpenSideMenu,
    isStockPage,
    setIsOpenSideMenu
}:IHeaderWaitinPage){
    return(
        <>
        <WaitingToggleMenu isStockPage={isStockPage}
        setIsOpenSideMenu={setIsOpenSideMenu}
        isOpenSideMenu={isOpenSideMenu}/>

    <WaitingDropDownMenu
        handlerProfileClickWaitingPage={handlerProfileClickWaitingPage}
        WaitingPageDropDown={WaitingPageDropDown}/>
        </>
    )
}
export default HeaderWaitinPage