import React, {useRef} from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

interface IEventTime{
    time:string
    fullDateTootip?:string
}
function EventTime(props:IEventTime){
    const refProps = useRef<any>(props)

    const renderTooltip = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">
                    { props.fullDateTootip }
                    {refProps.current.fullDateTootip}
                </div>
            </Tooltip>
        );

    return(
        <>
            <OverlayTrigger
                delay={{show: 1, hide: 1}}
                overlay={renderTooltip}
                placement={'top'}
            >
                <div data-tip={props.time}
                     style = {{width:'100px'}}
                     className="timeline-label font-weight-bolder text-muted font-size-sm">{props.time}
                </div>
            </OverlayTrigger>
        </>)
}
export default EventTime