import React, { useEffect, useMemo, useState } from 'react';
import { useLocation } from "react-router-dom";
import '../../styles/cardLinks.css'
import { useTransition } from '../../hooks/useTransition';
import CompanyInformationCard from "./CompanyInformationCard";
import { FilterConstant } from "../../models/FilterEvent";
import { UseActions } from "../../hooks/useActions";
import { useCheckForPage } from '../../hooks/useCheckForPage';
import useTranslate from "../../hooks/useTranslate";
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface ISingleInformationCard {
    text: string
    header: string,
    time: string,
    sourceLink: string
    sourceName: string
    classText: string
    Comment: any,
    id?: string
    isCommentOpen?: boolean
    translatedText?: string
    CardInformation?: any
    countComments?: number
    mediumTime?: string | number | null
}

function SingleInformationEventCard(props: ISingleInformationCard) {
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const { transitionToComments } = useTransition()
    const { setCurrentEvent } = UseActions()
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)
    const [isShowTransition, showTransition] = useState<boolean>(false)
    const { showEventBody, showTranslateLink } = useTranslate(props.text)

    const showTransitionHandler = (boolvalue: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        showTransition(boolvalue)
    }

    useEffect(() => {
        showTransition(translateEvents)
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])
    const { checkForStockPage } = useCheckForPage()
    const [show, setShow] = useState(false);

    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type: FilterConstant.FDA,
                    data: { ...props, isCommentOpen: false }
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }

    const headerJsx = useMemo(() => {
        return <>
            <div className="font-weight-bolder mb-2">
                <i className={props.classText + ' ' + "mr-2"}></i>
                {props.header}
            </div>
        </>
    }, [props.header])


    return (
        <>
           {headerJsx}

            <span className='p-2'>
                {showEventBody(props.translatedText, props.text, isShowTransition)}
            </span>
            <div className="d-flex align-items-center">
                <span className="mt-1 font-size-sm">
                    <a target="_blank" href={props.sourceLink}>@{props.sourceName}</a>

                    <span>
                        {
                            showTranslateLink(isShowTransition, props.translatedText, props.text,
                                showTransitionHandler)
                        }
                    </span>
                </span>

                {
                    props.Comment
                }

            </div>
            {!checkForStockPage() && <CompanyInformationCard {...props.CardInformation} />}
        </>
    );
}

export default React.memo(SingleInformationEventCard)