import CommentsModal from "../Modals/CommentsModal";
import React, {useEffect, useRef, useState} from "react"
// @ts-ignore
import {useLocation, useNavigate} from "react-router-dom";
import {Tooltip} from "react-bootstrap";
import "../../styles/TimeLineElement.css"
import {OverlayTrigger} from "react-bootstrap";
import {useTransition} from "../../hooks/useTransition";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import CommentCard from "../Cards/CommentCard";
import useTranslate from "../../hooks/useTranslate";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface ITimeLimeElement {
    classText: string
    text: string
    header: string
    time: string
    Comment:any,
    sourceLink: string
    sourceName: string
    isCommentOpen?: boolean
    translatedText?: string
    isTwitterOfPopularEvent?:boolean
    id?:string,
    countComments?:number,
    fullDateTootip?:string,
}

function TimeLineElement(props: ITimeLimeElement) {
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const {transitionToComments} = useTransition()
    const {setCurrentEvent} = UseActions()
    const [show, setShow] = useState(false);
    const refProps = useRef<any>(props)
    const [isShowTransition, showTransition] = useState<boolean>(false)
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)
    const {showEventBody,showTranslateLink} = useTranslate(props.text)

    useEffect(() => {
        showTransition(translateEvents)
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])

    const showTransitionHandler = (boolvalue:boolean) => (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        showTransition(boolvalue)
    }

    const renderTooltip = (props: any) =>
        (
            <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
                <div className="overlay">
                    { props.header }
                    {refProps.current.fullDateTootip}
                    </div>
            </Tooltip>
        );
    return (
        <>
            <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated" >

                <OverlayTrigger
                    delay={{show: 1, hide: 1}}
                    overlay={renderTooltip}
                    placement={'top'}
                >
                    <div data-tip={props.header}
                         className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1">{props.time}
                    </div>
                </OverlayTrigger>

                <div className="timeline-badge "><i className={props.classText}></i></div>
                <div className="font-size-md timeline-content pl-3">

                    <div className="event">
                        <b>{props.header}</b> {showEventBody(props.translatedText,props.text,isShowTransition)}
                    </div>
                    <div className="d-flex align-items-center">

                        <div className="mt-1 font-size-sm"><a target="_blank"
                                                              href={props.sourceLink}> { props.isTwitterOfPopularEvent  && <> @</> }{props.sourceName}</a>

                    {
                        showTranslateLink(isShowTransition,props.translatedText, props.text,
                            showTransitionHandler)
                    }
                        </div>

                        {
                            props.Comment
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default TimeLineElement