import React, { useState } from 'react';
import { ICompanyCard } from '../../../hooks/useCompanyCard';
import { useTransition } from '../../../hooks/useTransition';
import MarketMapRow from './MarketMapRow'
import MarketMapModal from '../../Modals/MarketMapModal'
import { faPeopleArrows } from '@fortawesome/free-solid-svg-icons';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import DashedSeparator from '../../Separators/DashedSeparator';
import './marketMap.css'


interface IMarketMapTable {
    header: string,
    stocks: Array<ICompanyCard>,
    color: string,
    id: number,
    background: string
}

const MarketMapTable: React.FC<IMarketMapTable> = (props) => {
    const { transitionToMarketMapPage } = useTransition()
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const gruopTransitionHandler = (e: React.MouseEvent<HTMLSpanElement>) => {
        if (isApplication)
            transitionToMarketMapPage(props.id)
        else setShow(true)
    }

    return <>
        <div className='MarketMapTableContainer__table'>
            <MarketMapModal header={props.header} show={show} handleClose={handleClose} id={props.id} />
            <div
                onMouseEnter={
                    (e) => {
                        e.currentTarget.style.backgroundColor = props.background
                    }}
                onMouseLeave={
                    (e) => {
                        e.currentTarget.style.backgroundColor = 'transparent'
                    }}
                onClick={gruopTransitionHandler} className="card" data-theme="light" style={
                    { cursor: 'pointer', border: `3px solid ${props.color}` }}>
                <div className="card-body p-4">
                    {/* style = {{minHeight:'3em'}} */}
                    <div className={'d-flex'} >
                        <span className="font-weight-bolder font-size-sm d-flex flex-grow-1 marketMap__header" style={{ wordBreak: "break-all" }}>{props.header}</span>
                        {
                            !isApplication && <span onClick={gruopTransitionHandler} className="svg-icon svg-icon-5 ">
                                <span className="btn btn-sm btn-icon btn-hover-light-primary w-20px h-20px">
                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.4 11H3C2.4 11 2 11.4 2 12C2 12.6 2.4 13 3 13H14.4V11Z" fill="currentColor"></path>
                                        <path opacity="0.3" d="M14.4 20V4L21.7 11.3C22.1 11.7 22.1 12.3 21.7 12.7L14.4 20Z" fill="currentColor"></path>
                                    </svg>

                                </span>

                            </span>
                        }
                    </div>
                    <div className="mt-2">
                        {
                            props.stocks.map((stock, index) => {
                                return <>
                                    <MarketMapRow {...stock} />                    {
                                    }
                                </>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default MarketMapTable