export interface currentCompany{
    isEmpty?:boolean,
    src:string,
    ticket?: string,
    cardName: string,
    price: number | null | undefined,
    percent: number | null | undefined
    capitalization?: string
    shareVolume?:string
    oldPrice?:string | null
    currency?:string,
    brand?:string,
    finishedAt?:number
}

export enum CurrentCompanyEnum{
    SET_CURRENT_COMPANY= "SET_CURRENT_COMPANY",
    SET_EMPTY_FLAG = "SET_EMPTY_FLAG",
    SET_CAPITALIZATION_AND_SHARED_VOLUME = "SET_CAPITALIZATION_AND_SHARED_VOLUME"
}

export interface  SetCurrentCompanyEnum{
    type: CurrentCompanyEnum.SET_CURRENT_COMPANY,
    payload: currentCompany,
}

export interface  SetEmptyFlag{
    type: CurrentCompanyEnum.SET_EMPTY_FLAG,
    payload: boolean,
}

export interface  setCompanyDescriptionInformation{
    type: CurrentCompanyEnum.SET_CAPITALIZATION_AND_SHARED_VOLUME,
    payload: {
        capitalization: string,
        shareVolume:string
    },
}

export type actionType = SetCurrentCompanyEnum | SetEmptyFlag | setCompanyDescriptionInformation