import React, { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import '../../styles/CompanyButtonGroup.css'
import { useTransition } from "../../hooks/useTransition";
import { ORIGIN } from "../../models/Domen";
import { useTranslation } from "react-i18next";
import { DeviseIsApplicationOnly } from "../../Utills/ClearFunctions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { TickerContext } from "../../Context";

enum CompanyTopics {
    MAIN = "stocks",
    EVENTS = "events",
    COMPANY = "company",
}

interface IActiveButtons {
    [CompanyTopics.MAIN]: boolean,
    [CompanyTopics.EVENTS]: boolean,
    [CompanyTopics.COMPANY]: boolean,
}

function CompanyButtonGroup() {
    const location = useLocation()
    const { transitionToMainCompany, transitionToEvents, transitionToCompany } = useTransition()
    const { isCoinsPage } = useContext(TickerContext)
    
    const [activeButtons, setActive] = useState<IActiveButtons>({
        [CompanyTopics.MAIN]: false,
        [CompanyTopics.EVENTS]: false,
        [CompanyTopics.COMPANY]: false,
    })

    const starRef = useRef<HTMLAnchorElement>({} as HTMLAnchorElement)

    useEffect(() => {

        // if (DeviseIsApplicationOnly()) {
        //     starRef.current.classList.add('disableNavItemHover')
        // }
        const matchUrls = Object.values(CompanyTopics)
        const newActiveButtons = { ...activeButtons }
        for (let i = 0; i < matchUrls.length; i++) {
            const locationArray = location.pathname.split('/')
            if (locationArray[locationArray.length - 1].includes(matchUrls[i])) newActiveButtons[matchUrls[i] as keyof IActiveButtons] = true
            else newActiveButtons[matchUrls[i] as keyof IActiveButtons] = false
        }
        if (Object.values(newActiveButtons).every((path) => !path)) newActiveButtons[CompanyTopics.MAIN] = true
        setActive(newActiveButtons)
    }, [location.pathname])

    const { t } = useTranslation()

    return (
        <ul className="nav nav-pills nav-light-primary mt-4 mb-5">
            <li className="nav-item">
                <a ref = {starRef} href={`${ORIGIN}`} onClick={transitionToMainCompany} className={activeButtons[CompanyTopics.MAIN] ? "nav-link px-4 py-2 active" : "nav-link px-4 py-2"}>
                    <span className="nav-text">{t('STOCK_TAB_SUMMARY')}</span>
                </a>
            </li>
            
            {
                !isCoinsPage.isCrypto && <li className="nav-item">
                    <a ref = {starRef} onClick={transitionToEvents} className={activeButtons[CompanyTopics.EVENTS] ? "nav-link px-4 py-2 active" : "nav-link px-4 py-2"}>
                        <span className="nav-text">{t('STOCK_TAB_EVENTS')}</span>
                    </a>
                </li>
            }
            
            <li className="nav-item">
                <a ref = {starRef}  onClick={transitionToCompany} className={activeButtons[CompanyTopics.COMPANY] ? "nav-link px-4 py-2 active" : "nav-link px-4 py-2"}>
                    <span className="nav-text">{t('STOCK_TAB_PROFILE')}</span>
                </a>
            </li>
        </ul>
    )
}
export default CompanyButtonGroup

