import React from 'react';
import '../../styles/ReportWidget.css'
import PressReleasesBody from "./PressReleasesBody";


function PressReleasesWidget() {
    return(
        <div>
            <div className="reportWidget__card">
                <PressReleasesBody  />
            </div>
        </div>
    )
}
export default React.memo(PressReleasesWidget)