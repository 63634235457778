import {actionType, ProfileUserEnum, ProfileUserState} from './types'

const initialState: ProfileUserState = {
    about:'',
    fullName:'',
    username:'',
    isChanged:false,
    error:{
        isError:false,
        textError:''
    },
    isSuccess:false,
}

export default  function TickerReducer(state:ProfileUserState = initialState, action:actionType):ProfileUserState{
    switch (action.type){
        case ProfileUserEnum.SET_SUCCESS:
            return {...state, isSuccess:action.payload}
        case ProfileUserEnum.SET_ABOUT:
            return {...state, about:action.payload}
        case ProfileUserEnum.SET_FULL_NAME:
            return {...state, fullName:action.payload}
        case ProfileUserEnum.SET_USER_NAME:
            return {...state, username:action.payload}
        case ProfileUserEnum.SET_IS_CHANGED:
            return {...state, isChanged:action.payload}               
        case ProfileUserEnum.SET_PROFILE_USER:
            return {...state, ...action.payload}
        case ProfileUserEnum.SET_ERROR:{
            return {...state, error:action.payload}
        }
        case ProfileUserEnum.SAVE:{
            return {...state, isChanged:false}
        }
      
        default: return state
    }
}
