import { t } from "i18next";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import useDate from "../hooks/useDate";
import {useTransition} from "../hooks/useTransition";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { getPlaneString, gettOrigin } from "../Utills/ClearFunctions";

function MenageSubscription(){
    const {profileTransition,cancelTransition,transitionCancelToProfile} = useTransition()
    const User = useTypedSelector(state => state.UserReducer.User)
    const {getShortDate} = useDate()
    const Plane = useTypedSelector(state => state.UserReducer.User.tariff)

    return(
        <div className="row justify-content-center">
            <div className="col-lg-8 col-md-12">
                <div className="card card-custom p-6 container">


                <h3 className="text-center font-weight-bolder mb-6">{t('PROFILE_MANAGE_SUBSCRIPTION')}</h3>
                    {
                        User.subscriptionInfo.autoPaymentInfo  && <> 
                            <p>
                                <Trans i18nKey = 'SUBSCRIPTION_REMINDER'>
                                    <b>{{date:getShortDate(User.subscriptionInfo.autoPaymentInfo?.nextTryAt)}}</b>
                                    <span> {{plane:getPlaneString(Plane)}}</span>
                                </Trans>
                                &nbsp;
                                {t('SUBSCRIPTION_REMINDER_MONTH',{count:User.subscriptionInfo.autoPaymentInfo?.months})}
                                <Trans i18nKey = 'SUBSCRIPTION_REMINDER_PRICE'>
                                    <span> {{price:User.subscriptionInfo.autoPaymentInfo?.amount}}</span>
                                </Trans>
                            </p>

                            <p>
                                <Trans i18nKey = 'SUBSCRIPTION_REMINDER_CARD'>
                                    <b>{ {card:User.subscriptionInfo.autoPaymentInfo.source} }</b>
                                    <a href= {`${gettOrigin()}/finance/cancel-auto-payment`} onClick={cancelTransition}></a>
                                </Trans>
                            </p>
                            <a href= {`${gettOrigin()}/profile`} className="btn btn-primary" onClick={profileTransition}>{t('SUBSCRIPTION_REMINDER_PROFILE')}</a>
                        </>
                    }

                    {/* <h3 className="text-center font-weight-bolder mb-6">{t('PROFILE_MANAGE_SUBSCRIPTION')}</h3>
                    {
                        User.subscriptionInfo.autoPaymentInfo && <> 
                            <p>
                                Напоминаем, что   {
                                getShortDate(User.subscriptionInfo.autoPaymentInfo?.nextTryAt)
                                } ваша подписка
                                на тариф PRO будет продлена на срок {User.subscriptionInfo.autoPaymentInfo?.months} месяц, стоимость подписки будет составлять {User.subscriptionInfo.autoPaymentInfo?.amount} рублей.
                            </p>

                            <p>
                            {/* SUBSCRIPTION_REMINDER_CARD */}
                            {/* <Trans i18nKey = 'SUBSCRIPTION_REMINDER_CARD'>
                                <b> {User.subscriptionInfo.autoPaymentInfo.source} </b>
                                <a href= {`${gettOrigin()}/finance/cancel-auto-payment`} onClick={cancelTransition}>отмените</a>
                            </Trans>
                            </p>
                            <a href= {`${gettOrigin()}/profile`} className="btn btn-primary" onClick={profileTransition}>Перейти в профиль</a>
                        </> */}
                    
                    
                    
                    
                    
                    {/* <p>
                        Напоминаем, что    16.08.2024 ваша подписка
                        на тариф PRO будет продлена на срок
                        1 месяц, стоимость подписки будет составлять 799.00 рублей.
                    </p>
                    <p>
                        Списание произойдет с карты <b>Visa *7722</b>,
                        если только вы заранее не <a
                        href="https://tradesense.ru/finance/cancel-auto-payment" onClick={cancelTransition}>отмените</a> автоплатеж.
                    </p>
                    <a href="https://tradesense.ru/profile" className="btn btn-primary" onClick={profileTransition}>Перейти в профиль</a> */}
                </div>
            </div>
        </div>
    )
}

export default MenageSubscription