export interface currentCompany{
    capitalization: string
    shareVolume:string
    logo:string,
    ticker:string,
    brand:string
}

export enum CurrentCompanyEnum{
    SET_CAPITALIZATION_AND_SHARED_VOLUME = "SET_CAPITALIZATION_AND_SHARED_VOLUME"
}

export interface  setCompanyDescriptionInformation{
    type: CurrentCompanyEnum.SET_CAPITALIZATION_AND_SHARED_VOLUME,
    payload: {
        capitalization: string,
        shareVolume:string,
        logo:string,
        ticker:string,
        brand:string,
    },

}

export type actionType = setCompanyDescriptionInformation