import React, { useCallback, useEffect, useRef, useState } from 'react';
import CompanyEventContent from "./CompanyEventContent";
import PressReleasesWidget from "../Widgets/pressReleasesWidget";
import '../../styles/eventCompamyVidget.css'
import ReportWidget from "../Widgets/ReportWidget";
import AnalyticsWidget from "../Widgets/AnalyticsWidget";
import MobileNavBar from "../Layout/MobileNavBar";
import { useTranslation } from 'react-i18next';

function CompanyEvents() {
    const [isMobile, setIsMobile] = useState<boolean>(false)
    
    const foo = useCallback((event) => {
        if (event.currentTarget.innerWidth > 992) {
            setIsMobile(false)
        }
        else {
            setIsMobile(true)
        }
    }, [isMobile])

    useEffect(() => {
        if (window.innerWidth > 992) {
            setIsMobile(false)
        }
        else {
            setIsMobile(true)
        }
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0);
        window.removeEventListener('resize', foo)
        window.addEventListener('resize', foo)

        return () => {
            window.removeEventListener('resize', foo)
        }
    }, [isMobile])

    const { t } = useTranslation()

    return (
        <div>
            <div className='mobileWidgetContainer'>
                <div className=' card card-custom p-4 mb-6'>
                    {
                        isMobile && <MobileNavBar />
                    }
                </div>
            </div>

            <div className="eventTransitionGroup">
                <CompanyEventContent />
                {
                    !isMobile && <div className='col-lg-4 d-none d-lg-block'>
                        <h4 className="font-weight-bolder mb-4 mt-4"> {t('STOCK_PRESS_RELEASES_HEADER')} </h4>
                        <PressReleasesWidget />
                        <h4 className="font-weight-bolder mb-4 mt-4"> {t('STOCK_REPORTS_HEADER')} </h4>
                        <ReportWidget />
                        <h4 className="font-weight-bolder mb-4 mt-4"> {t('STOCK_FORECASTS_HEADER')} </h4>
                        <AnalyticsWidget />
                    </div>
                }
            </div>

        </div>
    );
}

export default React.memo(CompanyEvents);