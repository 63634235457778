import React, { useContext, useEffect } from 'react';
import {Modal} from "react-bootstrap";
import { useTranslation } from 'react-i18next';
import {useParams} from "react-router-dom";
import { CompanyContext } from '../../Context';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import InfiniteScroll from '../../Utills/VirtualScroll';

interface CommentsModal{
    show: boolean,
    handleClose: () => void
}

function AnalyticsModal({show,handleClose}: CommentsModal)  {
    const {id} = useParams()

    const companyObj = useTypedSelector(state => state.CompanyReducer)
    const {t} = useTranslation()

    const {setTickerEvents,lastRowTickerEventHandler,TickerEvents} = useContext(CompanyContext)

    useEffect( () => {
        setTickerEvents(companyObj.ticker,'FORECAST')
    },[show])

    return (
        <Modal size ='lg' show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <h5 className="modal-title">{id}: {t('STOCK_FORECASTS_HEADER')}</h5>
            </Modal.Header>

            <Modal.Body>

                { <InfiniteScroll listItems={TickerEvents} lastRowHandler ={lastRowTickerEventHandler}  /> }
        
            </Modal.Body>

        </Modal>
    );
}

export default AnalyticsModal;