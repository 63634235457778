import React, {useCallback, useEffect, useRef, useState} from 'react';
import {Outlet} from "react-router-dom";
import Header from "../components/Layout/Header";
import '../styles/newLayout.css'
import {UseActions} from "../hooks/useActions";
import Footer from "../components/Layout/Footer";
import {
    closeSearchView, DeviseIsAndroidOnly,
    DeviseIsApplicationOnly,
    showInstallApplicationAlert
} from '../Utills/ClearFunctions'
import {useTypedSelector} from "../hooks/useTypedSelector";
import TelegramModal from '../components/Modals/TelegramModal';
import InstallMobileBrowserModal from "../components/Modals/InstallMobileBrowserModal";
import '../styles/NewEventAlert.css'
import {faSyncAlt} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import PullToRefresh from 'pulltorefreshjs';
import ReactDOMServer from "react-dom/server";
import BackDrop from "../components/BackDrop";
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';
import useTelegramBackDrop from '../hooks/useTelegramBackDrop';
import AlertModalBody from '../components/Modals/AlertModalBody';
import AlertModal from '../components/Modals/AlertModal';
import useTelegram from '../hooks/useTelegram';

function Layout() {
    const userAgent = useTypedSelector(state => state.EventReducer.userAgent)
    const [isOpen, SetIsOpenSideMenu] = useState<boolean>(false)
    const [profileIsOpen, setProfileIsOpen] = useState<boolean>(false)
    const {setIsOpen, setNumber, setIsOpenSideMenu, setPullRequest, setSearchIsOpen} = UseActions()
    const {SetShowTelegram, SetTelegramIsConnect} = UseActions()
    const telegramIsConnecting = useTypedSelector(state => state.EventReducer.telegramIsConnecting)
    const showTelegram = useTypedSelector(state => state.EventReducer.showTelegram)
    // const showTelegram = true
    // const telegramIsConnecting = true
    const searchIsOpen = useTypedSelector(state => state.EventReducer.searchIsOpen)
    const [isPull, setIsPull] = useState<boolean>(false)
    const scrollRef = useRef<HTMLDivElement | null>(null)
    const BackDropisOpen = useTypedSelector(state => state.BackDropReducer.BackDropisOpen)
    const {openBackDrop} = useTelegramBackDrop()
    const {isShowTelegramAlert} = useTelegram()

    const desableScroll = () => {
        if (!scrollRef.current) return
        disableBodyScroll(scrollRef.current)
    }

    const enableScroll = () => {
        if (!scrollRef.current) return
        enableBodyScroll(scrollRef.current)
    }

    useEffect(() => {
        if (isPull) {
            const pullTORequestObj = PullToRefresh.init({
                mainElement: 'pull',
                onRefresh() {
                    window.location.reload();
                },
                iconArrow: ReactDOMServer.renderToString(
                    <FontAwesomeIcon icon={faSyncAlt}/>
                ),
                iconRefreshing: ReactDOMServer.renderToString(
                    <FontAwesomeIcon icon={faSyncAlt} spin={true}/>
                ),
            });
            setPullRequest(pullTORequestObj)
        }
    }, [isPull])

    const handleCloseTelegram = () => {
        SetShowTelegram(false)
        SetTelegramIsConnect(false);
    }

    const closeAllMenu = () => {
        SetIsOpenSideMenu(false)
        setProfileIsOpen(false)
        closeSearchView()
        setSearchIsOpen(false)
        setNumber(false)
        setIsOpen(false)
        setIsOpenSideMenu(false)
    }

    const [showInstallModal, setShowInstallModal] = useState<boolean>(showInstallApplicationAlert(userAgent))
    const closeInstallModal = () => {
        setShowInstallModal(false)
        let date = new Date(Date.now() + 4 * 12 * 30 * 24 * 60 * 60 * 1000)
        const dateStr = date.toUTCString();

        let updatedCookie = encodeURIComponent('hideInstallModal') + "=" + encodeURIComponent(1);
        document.cookie = `${updatedCookie};path=/;expires=" + ${dateStr}`
    }

    useEffect(() => {
        if (DeviseIsAndroidOnly()) {
            if (BackDropisOpen) desableScroll()
            else enableScroll()
        }
    }, [BackDropisOpen])

    useEffect(() => {
        document.body.addEventListener('click', () => {
            closeAllMenu()
        })
        const searchCompany = document.querySelector('#search-company')
        // searchCompany!.addEventListener('blur',() => {
        //     setTimeout(() => {
        //         closeSearchView()
        //         setSearchIsOpen(false)
        //     },100)
        // })
    }, [])
    const ALERT_TELEGRAM_MESSAGE = 'ALERT_TELEGRAM_MESSAGE' 

    const getTelegramAlertMessage = useCallback(() => {
        const storageShowTelegramMessage = window.localStorage.getItem(ALERT_TELEGRAM_MESSAGE)
        if(showTelegram){
            window.localStorage.setItem(ALERT_TELEGRAM_MESSAGE, 'false')
            return false
        }
        else{
            if(!storageShowTelegramMessage && isShowTelegramAlert ){
                window.localStorage.setItem(ALERT_TELEGRAM_MESSAGE, 'false')
                return true
            }
            else{
                return false
            }
        }
    },[])

    const [isShowTelegramAlertMessage, setIsShowTelegramAlertMessage] = useState<boolean>(false)
    
    useEffect(() => {
        setIsShowTelegramAlertMessage(getTelegramAlertMessage())
    },[])

    useEffect(() => {
        if (showTelegram) {
            if (DeviseIsApplicationOnly() && telegramIsConnecting) {
                openBackDrop()
            }
        }
    }, [showTelegram, telegramIsConnecting, isShowTelegramAlertMessage])

    return (
        <>
            <>
                <InstallMobileBrowserModal show={showInstallModal} handleClose={closeInstallModal}/>
                
                <AlertModal show = {isShowTelegramAlertMessage} handleClose={() => {
                    setIsShowTelegramAlertMessage(false)
                }}/>

                {
                    !DeviseIsApplicationOnly() &&
                    <TelegramModal show={telegramIsConnecting} handleClose={handleCloseTelegram}/>
                }

                <div id="kt_header" className="header header-fixed border-bottomm h-auto " onClick={() => {
                    closeAllMenu()
                }}>

                    <Header
                        profileIsOpen={profileIsOpen}
                        setProfileIsOpen={setProfileIsOpen}
                        searchIsOpen={searchIsOpen}
                        setSearchIsOpen={setSearchIsOpen}
                        isOpenSideMenu={isOpen}
                        setIsOpenSideMenu={SetIsOpenSideMenu}
                    />
                </div>

                <BackDrop/>

                <div ref={scrollRef} id='pull' className="d-flex flex-column flex-root top" onClick={() => {
                    closeAllMenu()
                }}>
                    <div className='d-flex flex-column flex-row-fluid container fluid'
                         style={{padding: '0px !important'}}>
                        <div className='content d-flex flex-column flex-column-fluid pt-0 pt-lg-6'>
                            <div ref={() => {
                                if (window.navigator.userAgent.includes('iPhone') || window.navigator.userAgent.includes('iPad'))
                                    setIsPull(true)
                            }} className='container adaptiveContainer'>
                                <div>
                                    <div>
                                        <Outlet/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {!DeviseIsApplicationOnly() && <Footer/>}
                    </div>
                </div>
            </>
        </>
    );
}

export default Layout