import { gettOrigin } from "../Utills/ClearFunctions"

export interface IAutoPaymentInfo{
    nextTryAt:number,
    months:number,
    amount:number,
    source:string,
}

//https://tradesense.ru/img/default-avatar.jpg
export const DEFAUL_USER_IMG = `${gettOrigin()}/img/default-avatar.jpg`

export interface ISubscriptionInfo{
    finishedAt:number | null,
    isAvailableTrial:boolean,
    isActiveAppleSubscription:boolean,
    isActiveGoogleSubscription:boolean,
    autoPaymentInfo: null | IAutoPaymentInfo,

}

export interface IProfile{
    username:string,
    fullName:string,
    about:string,

}

export interface ISetting{
    timezone:string,
    notificationsMode:number,
    translateEvents:boolean,
    telegramConnected:boolean,
    investmentApp:number,
    language:string,
    region:string
}

export interface IUser{
    tariff:PlaneTypes
    status:StatusTypes
    canEditEvents:boolean
    favouritesSelected:boolean
    phone:string
    sendType:SendTypeEnum
    queuePosition:number
    avatar:string
    subscriptionInfo:ISubscriptionInfo
    profile:IProfile
    settings:ISetting
    hasInvites:boolean
    promoCode:string | null | undefined,
    isCrypto:boolean
}

export enum SendTypeEnum{
    TELEGRAM = 2,
    MESSAGE = 1
}

export enum PlaneTypes{
    PRO = 1,
    FREE = 0,
    LITE = 2
}

export enum StatusTypes{
    PENDING = 9,
    OPEN = 10
}
