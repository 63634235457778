import { useTranslation } from "react-i18next"
import { IInvite } from "../../../hooks/useInvites"
import { gettOrigin } from "../../../Utills/ClearFunctions"

function Over(props:Partial<IInvite> & {numberEvent:number}){
    
    const {t} =useTranslation()

    return (
        <div className="p-1 d-flex">
            <div className="d-flex flex-row-fluid">
                <div className="d-flex flex-column pr-5 flex-grow-1">
                    <span className="text-muted font-weight-bold">
                        <s> {t('INVITES_INVITE_NUMBER',{count:props.numberEvent})}</s> 
                    </span>
                    <div className="text-dark mb-1 font-weight-bolder font-size-lg">


                    {
                        !props.phone
                            ? <span>
                                <s>  {props.code} </s>                           
                            </span>
                            : <span>
                            <s>  {props.phone} </s>                           
                        </span>
                    }
                    

                </div>
                
                </div>
                                    <div className="d-flex align-items-center py-2" data-toggle="tooltip" title="" data-original-title="Ivan Ivanovfgf">
                        <div className="symbol symbol-30 symbol-circle">
                            <img alt="avatar" src={`${gettOrigin()}/${props.invitedUser?.avatar}`}/>
                        </div>
                    </div>
                            </div>
        </div>
    )
}
export default Over