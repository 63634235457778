import DailyResultCard from './DailyResultCard'
import DashedSeparator from '../../Separators/DashedSeparator'
import React, { useEffect, useState } from 'react'
import UserService from '../../../Services/UserService'
import CompanyInformationCard, { ICompanyInformationCard } from '../../Cards/CompanyInformationCard'
import { ICompanyCard } from '../../../hooks/useCompanyCard'
import { useTranslation } from "react-i18next";
import { useTransition } from '../../../hooks/useTransition'
import { useTypedSelector } from '../../../hooks/useTypedSelector'
import { ORIGIN } from '../../../models/Domen'
import MoversModal from '../../Modals/MoversModal'

interface IHeaders {
    name: string,
    apiName: string
    isActive: boolean,
    id: number
}

interface IDailyResule {
    isFull: boolean
}

const DailyResult: React.FC<IDailyResule> = ({ isFull }) => {
    const { t } = useTranslation()
    const { transitionToMoversPage } = useTransition()
    const isApplication = useTypedSelector(state => state.EventReducer.isApplication)
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [headers, setHeaders] = useState<Array<IHeaders>>([
        {
            name: t('STOCKS_MOVERS_GAINERS'),
            apiName: 'gainers',
            isActive: true,
            id: 1,
        },
        {
            name: t('STOCKS_MOVERS_LOSERS'),
            apiName: 'losers',
            isActive: false,
            id: 2
        }
    ])

    const [date, setDate] = useState<Array<IHeaders>>([
        {
            name: t('STOCKS_MOVERS_1HOUR'),
            apiName: 'hour',
            isActive: false,
            id: 1,
        },
        {
            name: t('STOCKS_MOVERS_1DAY'),
            apiName: 'day',
            isActive: true,
            id: 2
        }
    ])

    const [CompanyCards, setCompanyCards] = useState<Array<ICompanyCard> | null>(null)

    const replaceStockData = (stockList: Array<any>) => {
        const ComanyCardsList = stockList.map((stockObject) => {
            return {
                ...stockObject,
                imgSrc: stockObject.logo,
                price: stockObject.priceInfo.price,
                oldPrice: stockObject.priceInfo.oldPrice,
                currency: stockObject.priceInfo.currency
            }
        })
        setCompanyCards(ComanyCardsList)
    }

    const getCurrentTopic = (data: Array<IHeaders>) => {
        return data.find(obj => obj.isActive)?.apiName
    }

    const fetchData = async (headers: Array<IHeaders>, date: Array<IHeaders>) => {
        setIsLoading(true)
        const period = getCurrentTopic(date)
        const topic = getCurrentTopic(headers)
        if (!topic || !period) return
        const response = await UserService.getMoversData(topic, period, isFull)
        setIsLoading(false)
        replaceStockData(response.data.stocks)
    }

    useEffect(() => {
        fetchData(headers, date)
    }, [])

    const headerClick = (id: number) => (e: React.MouseEvent<HTMLDivElement>) => {
        setHeaders(prev => {
            const newHeaders = prev.map(headerObject => {
                const isActive = headerObject.id === id
                return {
                    ...headerObject,
                    isActive: isActive
                }
            })
            fetchData(newHeaders, date)
            return newHeaders
        })
    }

    const dateClick = (id: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {

        e.preventDefault()
        setDate(prev => {
            const newDate = prev.map(dateObject => {
                const isActive = dateObject.id === id
                return {
                    ...dateObject,
                    isActive: isActive
                }
            })
            fetchData(headers, newDate)
            return newDate
        })
    }



    const renderCompanyList = () => {
        if (!CompanyCards) return <></>
        if (CompanyCards.length === 0) return <div><i className="far fa-clock fa-1x"></i> {t('STOCKS_MOVERS_EMPTY')}</div>
        return <>
            {
                CompanyCards.map((CompanyCard, index) => {
                    return <>
                        <DailyResultCard {...CompanyCard} />
                        {
                            index !== CompanyCards.length - 1 && <DashedSeparator />
                        }
                    </>
                })
            }
        </>
    }

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const onShowAllClickHandler = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (isApplication)
            transitionToMoversPage()
        else
            handleShow()
    }

    return <>
        <div className="row mb-6">

            {
                !isFull && <MoversModal show={show} handleClose={handleClose} />
            }

            <div className="col-12">
                <div className="card card-custom h-lg-100">
                    <div className="card-header p-4">
                        <h3 className="card-title align-items-start flex-column">
                            <div className="card-title mb-0 gap-4 gap-lg-10 gap-xl-15 nav nav-tabs border-bottom-0" data-kt-table-widget-3="tabs_nav">
                                {
                                    headers.map(header => {
                                        return <div
                                            onClick={headerClick(header.id)}
                                            key={header.id}
                                            className={`fs-5 fw-bold pb-3 cursor-pointer 
                                                ${header.isActive ? 'border-bottom border-3 border-primary' : 'text-muted'} `}
                                            data-kt-table-widget-3="tab" data-kt-table-widget-3-value="Show All">{header.name}
                                        </div>
                                    })
                                }
                                {
                                    isLoading && <div className={'d-flex align-items-center justify-content-center mb-1'}>
                                        <div className="spinner spinner-sm spinner-primary mrb-3"></div>
                                    </div>
                                }

                            </div>



                        </h3>
                        <div className="card-toolbar">
                            <ul className="nav" role="tablist">
                                {/* {
                                    date.map((dateObj) => {
                                        return <li key={dateObj.id} className="nav-item" role="presentation">
                                            <a
                                                onClick={dateClick(dateObj.id)}
                                                className={`nav-link btn btn-sm btn-color-muted btn-active 
                                                    btn-active-light fw-bold px-4 me-1 ${dateObj.isActive ? 'active' : 'name'}`}
                                                data-kt-timeline-widget-1="tab"
                                                data-bs-toggle="tab"
                                                href="#kt_timeline_widget_1_tab_day"
                                                aria-selected="true"
                                                role="tab">
                                                {dateObj.name}
                                            </a>
                                        </li>
                                    })
                                } */}
                            </ul>
                        </div>
                    </div>
                    <div className="card-body p-4">
                        {renderCompanyList()}

                        {
                            !isFull && <div>
                                <div className="d-flex text-center mt-2">
                                    <div className="d-flex flex-column flex-grow-1">
                                        <a
                                            onClick={onShowAllClickHandler}
                                            href={`${ORIGIN + 'stocks/movers'}`}
                                            className="text-hover-primary font-size-sm">
                                            {t('STOCKS_MOVERS_SHOWALL')}
                                        </a>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
}
export default DailyResult