import { t } from "i18next"
import { useCheckForPage } from "../../../hooks/useCheckForPage"
import { useTransition } from "../../../hooks/useTransition"
import { gettOrigin } from "../../../Utills/ClearFunctions"

function MobileSideBarListElements(){
    const {
        checkForFavorites,
        checkForProfilePageSideBar
    } = useCheckForPage()

    const {
        profileTransition,
        transitionToFavorites,
    } = useTransition()

    return (
        <>
        <li className={ checkForFavorites() ? "menu-item menu-item-active" : "menu-item"}
                data-menu-toggle="click" aria-haspopup="true">

                <a href={`${gettOrigin()}/favourites`} onClick={transitionToFavorites}
                   className="menu-link">
                    <span className="menu-text">{t('EVENTS_TAB_FAV')}</span>
                </a>
            </li>
            
            <li className="d-lg-none">
                <hr/>
            </li>

            <li className={checkForProfilePageSideBar() ? "menu-item menu-item-active" : "menu-item"}
                data-menu-toggle="click" aria-haspopup="true">
                <a href={`${gettOrigin()}/profile`} onClick={profileTransition}
                   className="menu-link">
                    <span className="menu-text">{t('MENU_PROFILE')}</span>
                </a>
            </li>

            <li className="menu-item d-lg-none">
                <a target="_blank" href="https://t.me/tradesense_support" className="menu-link">
                        <span className="menu-text">{t('MENU_SUPPORT')}<i
                                        className="fab fa-telegram font-size-h3 text-primary ml-3"></i></span>
                </a>
            </li>
            <li className="d-lg-none">
                <hr/>
            </li>
        </>
    )
}

export default MobileSideBarListElements

