import $api from "../http";
import {AxiosResponse} from 'axios';

export default class CommentService {
    static async get(eventId:string){
        return $api.get('/comments',{params:{eventId:eventId}})
    }
    static async send(eventId:string, text:string){

        return $api.post('/comments/send',{eventId,text})
    }

    static async like(commentId:string){
        return $api.post('/comments/like',{commentId})
    }

    static async unlike(commentId:string){
        return $api.post('/comments/unlike',{commentId})
    }

    static async delete(commentId:string){
        return $api.post('/comments/delete',{commentId})
    }
}