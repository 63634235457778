import i18n from "i18next";
import {initReactI18next } from "react-i18next";
import enTrans from './ts-locale-en.json'
import ruTrans from './ts-locale-ru.json'

const resources = {
    ru: {
      translation: ruTrans
    },
    en:{
      translation:enTrans
    }

  };

i18n
  .use(initReactI18next)
  .init({
    resources,
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });