import CompanyInformationCard, { ICompanyInformationCard } from "./CompanyInformationCard";
import React, { FC, useEffect, useState } from "react";
import { useTransition } from "../../hooks/useTransition";
import { useLocation } from "react-router-dom";
import { FilterConstant } from "../../models/FilterEvent";
import { UseActions } from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import useTranslate from "../../hooks/useTranslate";
import { useTranslation } from "react-i18next";
import { useTypedSelector } from "../../hooks/useTypedSelector";

interface ITwitterEvent {
    name: string,
    subscribers: string,
    text: string,
    date: string
    time: string
    sourceName: string
    sourceLink: string
    Comment: any
    isCommentOpen?: boolean
    translatedText?: string
    CardInformation: ICompanyInformationCard
    id?: string
    countComments?: number
    mediumTime?: string | number | null
    createdAtEvent?: number,

}

const TwitterEvent: FC<ITwitterEvent> = (props) => {
    const location = useLocation()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)
    const { showEventBody, showTranslateLink } = useTranslate(props.text)
    const translateEvents = useTypedSelector(state => state.UserReducer.User.settings.translateEvents)
    const [isShowTransition, showTransition] = useState<boolean>(false)
    useEffect(() => {
        showTransition(translateEvents)
        if (!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])
    
    const showTransitionHandler = (boolvalue: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        showTransition(boolvalue)
    }
    const { checkForStockPage } = useCheckForPage()
    const { t } = useTranslation()

    return (
        <>
            <span className="font-weight-bolder mb-2">
                <i className="fab fa-twitter text-primary mr-2"></i>
                {t('EVENT_INFLUENCER_TWEET')}
            </span>
            <span className="p-2 event--translated-text">
                <span className="font-weight-bolder">
                    <a target="_blank" href={props.sourceLink}>{props.name}</a> | {props.subscribers} {'(' + props.mediumTime + ') '}
                </span>
                {showEventBody(props.translatedText, props.text, isShowTransition)}
            </span>
            <div className="d-flex align-items-center">
                <span className="mt-1 font-size-sm">
                    <a target="_blank"
                        href={props.sourceLink}>@{props.sourceName}
                    </a>
                    <span>
                        {
                            showTranslateLink(isShowTransition, props.translatedText, props.text,
                                showTransitionHandler)
                        }
                    </span>
                </span>
                {
                    props.Comment
                }
            </div>
            {!checkForStockPage() && <CompanyInformationCard {...props.CardInformation} />}
        </>
    )
}

export default React.memo(TwitterEvent)