import React, {useEffect, useRef, useState} from "react";
import UserService from "../Services/UserService";

interface IMutedStockCard {
    brand: string,
    ticker: string,
    isOpenSideMenu: boolean,
    logo: '',
    notificationStatus: 0 | 1 | 2
    price:number,
}

function useGetMutedStocks() {



    const [mutedElements, setMutedElements] = useState<Array<IMutedStockCard>>(
        []
    )


    const replaceDate = (stocks: Array<any>) => {
        setMutedElements(stocks.map((stock) => {
            return {
                brand: stock.brand,
                ticker: stock.ticker,
                isOpenSideMenu: false,
                logo: stock.logo,
                notificationStatus: stock.notificationStatus,
                price:stock.priceInfo.price,
            }
        }))
    }
    const getMuted = async () => {
        const response: any = await UserService.getMuted()
        replaceDate(response.data.stocks)
    }
    useEffect(() => {
        getMuted()
    }, [])

    const clickMutedHandler = (ticker: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setMutedElements(mutedElements.map((mutedElement) => {
            if (mutedElement.ticker === ticker) {
                return {...mutedElement, isOpenSideMenu: true}
            }
            return {...mutedElement, isOpenSideMenu: false}
        }))

    }


    const closeAllMenu = () => {
        setMutedElements(mutedElements.map((Element) => {
            return {...Element, isOpenSideMenu: false}
        }))
    }

    return {mutedElements, closeAllMenu, clickMutedHandler}
}

export default useGetMutedStocks