import React from "react";
import useImportantGenerator from "../../hooks/useImportantGenetrator";
import {useTypedSelector} from "../../hooks/useTypedSelector";
import {CompanyContext} from "../../Context";
import useGetCompany from "../../hooks/useGetCompany";
import useLocalEventTicker from "../../hooks/useLocalEventTicker";

interface AuxProps {
    children: React.ReactNode[];
}

function CompanyProvider({children}:AuxProps) { 
    const companyObj = useTypedSelector(state => state.CompanyReducer)
    const {ForecastElements, PressReleaseElements, ReportingElements, isLoading} = useImportantGenerator({ticker:companyObj.ticker})
    const {companyInformation} = useGetCompany()
    const {TickerEvents,lastRowTickerEventHandler,setTickerEvents} = useLocalEventTicker()
    
    return <>
        <CompanyContext.Provider value={
            {
                ForecastElements: ForecastElements,
                PressReleaseElements: PressReleaseElements,
                ReportingElements: ReportingElements,
                isLoading:isLoading,
                companyInformation: companyInformation,
                TickerEvents:TickerEvents,
                setTickerEvents:setTickerEvents,
                lastRowTickerEventHandler:lastRowTickerEventHandler,
            }
        }>
            {children}
        </CompanyContext.Provider>
    </>
}


export  default React.memo(CompanyProvider)