import { t } from 'i18next';
import React from 'react';
import { useTransition } from '../../hooks/useTransition';
import {ORIGIN} from "../../models/Domen";


function ProfileDropDown()  {
    const {profileTransition,logOutTransition} = useTransition()




    return (
            <div className="dropdown-menu dropdown-menu-right show"
                 style={{position: 'absolute', transform: 'translate3d(-125px, 45px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}
                 x-placement="bottom-end">
                <a className="dropdown-item" href = {`${ORIGIN}/profile`} onClick={profileTransition}>{t('MENU_PROFILE')}</a>
                <a target="_blank" className="dropdown-item " href="https://t.me/tradesense_support">
                    {t('MENU_SUPPORT')}<i className="fab fa-telegram font-size-h3 text-primary ml-3"></i>
                </a>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" data-method="post"
                   href={`${ORIGIN}/site/logout`} onClick={(e) => {
                    e.preventDefault()
                    logOutTransition()
                }} >{t('MENU_SIGNOUT')}</a>
            </div>
    );
}

export default ProfileDropDown;