import React, { useEffect } from "react"
import useTranslate from "../../hooks/useTranslate";

export interface IThirtyElement {
    header: string
    classText: string
    text: string,
    source: string,
    subscribers?: string
    date: string
    isShowHeader:boolean,
    isTranslateUserChoice?: boolean
    translatedText?: string
    translatedHeader?:string,
    sourceLink?:string,
    displayName?:string,
    isDrawHr?:boolean
    
}

function ThirtyElement({
                           header,
                           translatedText,
                           translatedHeader,
                           isTranslateUserChoice,
                           date,
                           classText,
                           text,
                           source,
                           subscribers,
                           sourceLink,
                           isShowHeader,
                           displayName,
                           isDrawHr
                       }: IThirtyElement) {

    const {isTranslate} = useTranslate(text)




    return (
        <>
            {
                (isTranslateUserChoice && isTranslate)
                    ?
                    <>

                        {
                            isShowHeader ? <span className=" ml-1 pb-0 pl-4 event--translated-text"
                                                style={{borderLeft: '3px solid #eee'}}>
                                                    {isDrawHr && <hr/> }
                                            <div>
                                                <b>
                                                    <i className={classText + ' ' + 'mr-2'}></i>
                                                        <span dangerouslySetInnerHTML = {{__html: `${ header || ''  }` }} />
                                                </b>
                                                <div className="mb-2"></div>
                                                <span className="font-weight-bolder">
                                                    <a target="_blank" href={sourceLink}>{source}</a>
                                                    {subscribers && ` | ${subscribers}`}
                                                    &nbsp;
                                                    {date}
                                                    &nbsp;
                                                </span>
                                                <span dangerouslySetInnerHTML = {{__html: `${ text || ''  }` }} />
                                            </div>
                                         </span>
                                    :  <span className="ml-1 pl-4 event--translated-text"
                                    style={{borderLeft: '3px solid #eee'}}>
                                <div>
                                    <div className="mb-2"></div>
                                    <span className="font-weight-bolder">
                                        <a target="_blank" href={sourceLink}>{source}</a>
                                        {subscribers && ` | ${subscribers}`}
                                        &nbsp;
                                        {date}
                                        &nbsp;
                                    </span>
                                    <span dangerouslySetInnerHTML = {{__html: `${ text || ''  }` }} />
                                </div>
                             </span>
                        }
                        
                    </>
                    : <>

                        
                        {
                            isShowHeader ?  <span className="ml-1 pb-0 pl-4 event--translated-text"
                            style={{borderLeft: '3px solid #eee'}}>
                                 {isDrawHr && <hr/> }
                                <div>
                                    <b>
                                        <i className={classText + ' ' + 'mr-2'}></i>
                                            { header }
                                    </b>
                                
                                    <div className="mb-2"></div>
                                    <span className="font-weight-bolder">
                                        <a target="_blank" href={sourceLink}>{source}</a>
                                        {subscribers && ` | ${subscribers}`}
                                        &nbsp;
                                        {date}
                                        &nbsp;
                                    </span>
                                    <span dangerouslySetInnerHTML = {{__html: `${ translatedText || ''  }` }} />
                                </div>
                               
                            </span>
                      : <>
                      <span className="ml-1 pl-4 event--translated-text"
                            style={{borderLeft: '3px solid #eee'}}>
                        <div className="mb-2"></div>
                        <span className="font-weight-bolder">
                            <a target="_blank" href={sourceLink}>{source}</a>
                            {subscribers && ` | ${subscribers}`}
                            &nbsp;
                            {date}
                            &nbsp;
                        </span>
                        <span dangerouslySetInnerHTML = {{__html: `${ translatedText || ''  }` }} />
                      
                    </span>
                    </>
                    }
                    </>
            }


        </>
    )
}

export default ThirtyElement