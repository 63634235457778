import React from "react";
import CompanyCard from "../components/Cards/CompanyCard";
import FavouritesCard from "../components/Cards/FavvoritesCard";

export interface ICard {
    cardName: string,
    ticket: string,
    price?: number | null,
    percent?: number | null,
    src: string,
    star: boolean,
    fill: boolean,
    changeFill: (ticker: string, src?:string) => void,
    isAuth?: boolean,
    isUp?: boolean,
    oldPrice?: string | null,
    currency?: string,
    brand?: string,
    isTransitionToCompanyWithoutClick?: boolean,
    setIsTransitionToCompanyWithoutClick?: (value: boolean) => void
    postTickerClick?: (ticket:string) => void
}

function useSelectCard(IsFavorites:boolean){
    function RenderCard(props:ICard){
        return(
            <div>
                {
                (!IsFavorites)
                    ? <div> <CompanyCard {...props}/> </div>
                    : <div>  <FavouritesCard {...props}/> </div>
                }
            </div>
        )
    }
    return React.memo(RenderCard)
}

export  default useSelectCard
