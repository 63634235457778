import '../../styles/ReportWidget.css'
import AnalyticBody from "./AnalyticBody";
interface IRessReleasesWidget{
    Elements?:Array<JSX.Element>
}

function AnaliticWidget() {

    return (
        <div>
            <div className="reportWidget__card">
                <AnalyticBody />
            </div>
        </div>
    )
}

export default AnaliticWidget