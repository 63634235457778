import {actionType,currentCompany,CurrentCompanyEnum} from './types'

const initialState: currentCompany = {
    isEmpty:true,
    src:'',
    ticket: '',
    cardName:'',
    price:null,
    percent:null,
    capitalization:'',
    shareVolume:'',
    oldPrice: null,
    currency:'USD'
}

export default  function TickerReducer(state:currentCompany = initialState, action:actionType):currentCompany{
    switch (action.type){
        case CurrentCompanyEnum.SET_CURRENT_COMPANY:
            return {...state, ...action.payload, isEmpty:false}
        case CurrentCompanyEnum.SET_EMPTY_FLAG:
            return  {...state,isEmpty:action.payload}
        default: return state
    }
}
