import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import '../../styles/TimeLine.css'
import { useTypedSelector } from "../../hooks/useTypedSelector";
import TimeLineFreeElement from "../Free/TimeLineFreeElement";
import {
    isFreePlane,
    scrollHandlerDropDown,
} from "../../Utills/ClearFunctions";
import "../../styles/eventCompamyVidget.css"
import useCardGenerator from "../../hooks/useCardGenerator";
import { EventTopicEnum } from "../../models/EventContentModel";
import { useTranslation } from "react-i18next";
import { useRef } from 'react';
import DropDownWrapperContntent from "../Buttons/DropDownWrapperContntent";
import { TickerContext } from "../../Context";

interface ITimeLineElement {
    IconClass: string,
    text: string,
    header: string
}

function Timeline() {
    const navigate = useNavigate()
    const Plane = useTypedSelector((state) => state.UserReducer.User.tariff)
    const User = useTypedSelector(state => state.UserReducer.User)
    const prevInitialScroll = useRef<null | number>(null)
    const {isCoinsPage} = useContext(TickerContext)

    let initialScrollY: number = 0

    function scrollHandler() {
        let target = document.querySelector('.dropDown') as HTMLDivElement;
        if (!target) {
            target = document.querySelector('.trendButton') as HTMLDivElement;
        }

        if(!prevInitialScroll.current){
            if(prevInitialScroll.current !== target.getBoundingClientRect().y){
                initialScrollY = target.getBoundingClientRect().y
                prevInitialScroll.current = initialScrollY
            }
        }

        if (initialScrollY === 0) {
            initialScrollY = target.getBoundingClientRect().y
            prevInitialScroll.current = initialScrollY
        }
        scrollHandlerDropDown(initialScrollY)
    }

    const changeDropDownClass = useCallback(scrollHandler, [])
    const scrollToUp = () => window.scrollTo(0, 0)
    useEffect(() => {
        let styles = `      
            .bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
            top: -1px;
            border-width: 0.4rem 0.4rem 0;
            border-top-color: white !important;
        }
        `
        let styleSheet = document.createElement("style")
        styleSheet.innerText = styles
        const TooltipId = 'TooltipStyle'
        styleSheet.id = TooltipId
        document.head.appendChild(styleSheet)
        scrollToUp()
        let target = document.querySelector('.dropDown') as HTMLDivElement;
        if (!target) {
            target = document.querySelector('.trendButton') as HTMLDivElement;
        }
        document.addEventListener('scroll', changeDropDownClass)
        return () => {
            document.removeEventListener('scroll', changeDropDownClass)
            const sheets = document.getElementById(TooltipId)
            sheets?.remove()
        }
    }, [])
    const { t } = useTranslation()
    const { elements, hiddenCounts, isLoadingLasRow } = useCardGenerator({ CardContainerName: EventTopicEnum.LAST_EVENT, ticker: window.location.pathname.split('/')[window.location.pathname.split('/').length - 1] })
    const loader = useMemo(() => {
        return <>
            {
                isLoadingLasRow && <>
                    <div style={{ height: '20px' }} />
                    <div className="spinnerWrapper">
                        <div className="spinner spinner-lg spinner-primary mr-15"></div>
                    </div>
                    <div style={{ height: '40px' }} />
                </>
            }
        </>
    }, [isLoadingLasRow])
    return (
        <>
            <div className="timelineWrapper card card-custom mt-2 h-auto  pl-4 pr-4">
                <div className={'cardContainerStockPage h-auto'}>
                    <div className={'lastEvent h-auto'}>
                        <h3>{t('STOCK_TIMELINE')}</h3>
                    </div>

                    <div className={'dropDown'}>
                        {
                            !isCoinsPage.isCrypto && 
                            <div className={'ContainerFilterNone'}>
                                <span className="mr-6 js__filter-types-text filtersNone "> {t('FEED_FILTER_FILTERS_APPLIED')} </span>
                                <DropDownWrapperContntent /> 
                            </div>
                        }
                    </div> 
                    
                    <div className="d-flex">
                        <div className="timeline w-100 timeline-6 text-break mt-3" id="last-events-container">
                            <div className="jscroll-inner">

                                {(isFreePlane(Plane, User?.subscriptionInfo?.finishedAt) && hiddenCounts !== 0) && <TimeLineFreeElement hiddenCounts={hiddenCounts} />}
                                {elements}

                            </div>
                        </div>
                    </div>
                    {loader}
                </div>
            </div>
        </>
    )
}

export default Timeline