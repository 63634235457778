import { IPageInformation } from "./types"
import {actionType,PageEnum} from"./types"

const initialState: IPageInformation = {
    isCryptoPage:window.location.pathname.includes('crypto') || window.location.pathname.includes('coins')
}

export default  function PageReducer(state:IPageInformation = initialState, action:actionType):IPageInformation{
    switch (action.type){
        case PageEnum.SET_IS_CRYPTO_PAGE:
            return {...state, isCryptoPage:action.payload}
        default: return state
    }
}


