
export interface IError{
    isError:boolean,
    textError:string
}


export interface ProfileUserState{
    username: string,
    fullName:string,
    about:string,
    isChanged:boolean;
    error:IError,
    isSuccess:boolean,
}


export type setProfileType = Omit<ProfileUserState, 'isChanged' | 'error' | 'isSuccess'>

export enum ProfileUserEnum{
    SET_USER_NAME= "SET_USER_NAME",
    SET_FULL_NAME = "SET_FULL_NAME",
    SET_ABOUT= "SET_ABOUT",
    SET_IS_CHANGED = "SET_IS_CHANGED",
    SET_PROFILE_USER = "SET_PROFILE_USER",
    SAVE = "SAVE",
    SET_ERROR = "SET_ERROR",
    SET_SUCCESS = "SET_SUCCESS",
}

export interface ISetSuccess{
    type:ProfileUserEnum.SET_SUCCESS
    payload:boolean
}

export interface IErrorProfile{
    type:ProfileUserEnum.SET_ERROR,
    payload:IError
    
}

export interface ISave{
    type: ProfileUserEnum.SAVE,
}

export interface ISetProfileUser{
    type: ProfileUserEnum.SET_PROFILE_USER,
    payload: setProfileType
}

export interface  ISetIsChanged{
    type: ProfileUserEnum.SET_IS_CHANGED,
    payload: boolean,
}

export interface  iSetUserName{
    type: ProfileUserEnum.SET_USER_NAME,
    payload: string,
}

export interface ISetFullName{
    type: ProfileUserEnum.SET_FULL_NAME,
    payload: string,
}
export interface ISetAbout{
    type: ProfileUserEnum.SET_ABOUT,
    payload: string,
}


export type actionType = ISetSuccess | IErrorProfile | ISave | ISetIsChanged | ISetProfileUser | iSetUserName | ISetFullName | ISetAbout