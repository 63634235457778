import React, { useEffect, useState } from 'react';
import MarketMapRow from './MarketMapRow'
import MarketMapTable from './MarketMapTable'
import './marketMap.css'
import UserService from '../../../Services/UserService';
import { ICompanyCard } from '../../../hooks/useCompanyCard';
import useDate from '../../../hooks/useDate';
import { useTranslation } from 'react-i18next';

export interface IMarketMapList {
    name: string,
    id: number,
    tickers: Array<string>,
    stocks: Array<ICompanyCard>,
    color: string,
    background: string,
}

const MarketMap: React.FC = () => {
    const [marketMapList, setMarketMapList] = useState<Array<IMarketMapList> | null>(null)
    const [finishedAt, setFinishedAt] = useState<null | number>(null)
    const { getLongTime } = useDate()
    const { t } = useTranslation()

    useEffect(() => {
        UserService.getMarketData().then((data) => {
            setFinishedAt(data.data.finishedAt)
            setMarketMapList(Object.values(data.data.sectors))
        })
    }, [])
    return <>

        {
            finishedAt && <div className='mb-1'><span className="font-size-xs ">
                <i className="far fa-clock font-size-sm" aria-hidden="true"></i>
                &nbsp;
                {t("STOCK_AT_CLOSE")}
                <span>   {getLongTime(finishedAt)} </span>
            </span>
            </div>

        }
        <div className='mb-2 MarketMapTableContainer'>

            {
                marketMapList && marketMapList.map((marketMapItem, index) => {

                    return <>
                        <MarketMapTable
                            id={marketMapItem.id}
                            header={marketMapItem.name}
                            color={marketMapItem.color}
                            stocks={marketMapItem.stocks}
                            background={marketMapItem.background}
                        />
                    </>
                })
            }
        </div>
    </>
}
export default MarketMap