import React from "react"

interface IPayButton{
    text:string
    handlePayment:() => void
    isWaiting:boolean
}

function PayButton({text,handlePayment,isWaiting}:IPayButton){
    return(
        <div className="row justify-content-md-center mb-9">
            <div className="col-lg-6 col-12 text-center">
                <button onClick={handlePayment} 
                className={`btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 mr-2`}>
                            {isWaiting && <span className="spinner spinner-white mr-9"></span>  }
                            <span className="js__subscription-selected">
                                {text}
                            </span>
                </button>

            </div>
        </div>
    )
}

export default React.memo(PayButton)