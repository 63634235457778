import React, {useCallback, useEffect, useRef, useState} from 'react';
import '../../styles/EventButton.css'
import ListDropDown from "../DropDown/ListDropDown";
import {DeviseIsApplicationOnly, filterIsSelected} from "../../Utills/ClearFunctions";
import {useTypedSelector} from "../../hooks/useTypedSelector";

interface IDropDownEvent{
    isOpen:boolean,
    setIsOpen:(flag:boolean) => void,
}

function DropDownFavouritesAllStocks({isOpen,setIsOpen}:IDropDownEvent){
    const listRef = useRef<HTMLDivElement>({} as HTMLDivElement)
    const buttonRef = useRef<any>()
    const DIFF = 56
    const filter = useTypedSelector(state => state.Filter.filters)

    const notOpenButton = useRef<HTMLButtonElement>({} as HTMLButtonElement)
    const ButtonIcon = useRef<HTMLButtonElement>({} as HTMLButtonElement)
    
    useEffect(() => {
        // if (DeviseIsApplicationOnly()) {
        //     if(!isOpen && filterIsSelected(filter)){
        //         notOpenButton.current.classList.add('disableButtonFiterHover')
        //         ButtonIcon.current.classList.add('disableButtonFiterHover')
        //     }
        // }
    }, [isOpen,filter])
    const setListPosition = (x:number,y:number) => {
        if(x+DIFF >= -10){
            listRef.current.style.transform = `translate3d(${-200}px, ${y}px, 0px)`
            return
        }
        listRef.current.style.transform = `translate3d(${x+DIFF}px, ${y}px, 0px)`
    }

    useEffect(()=>{
       if(!isOpen)
            document.removeEventListener('scroll',MemoSetPositionForDropDown)
        else
            document.addEventListener('scroll',MemoSetPositionForDropDown) 
    },[isOpen])

    const setPositionForDropDown = () => {
        if(!listRef.current) return
        const HeightOfDropDown = listRef.current.getBoundingClientRect().height + 40
        const DropDownElement = document.getElementById('filter-types-button')
        const deltaX = -listRef.current.getBoundingClientRect().width + 25
        
        
        if((DropDownElement!.getBoundingClientRect().top + HeightOfDropDown > window.innerHeight)  &&
        !(HeightOfDropDown  <= DropDownElement!.getBoundingClientRect().top) ){
            const deltaY = DropDownElement!.getBoundingClientRect().top + HeightOfDropDown - window.innerHeight
            setListPosition(-Math.abs(deltaX),-deltaY)
            return
        }
        if(HeightOfDropDown <= DropDownElement!.getBoundingClientRect().top) setListPosition(-Math.abs(deltaX),-HeightOfDropDown)
        else setListPosition(-Math.abs(deltaX),0)
    }
    const MemoSetPositionForDropDown = useCallback(setPositionForDropDown,[])
    const selectPosition = (e: HTMLDivElement) => {
        listRef.current = e
        if(!e) return
        setPositionForDropDown() 
    }
    function handleShowButton(e:any){
        buttonRef.current = e
    }
    const listItems = (<div className="dropdown show" onClick={() =>{setIsOpen(!isOpen)}}>

        <button ref ={handleShowButton} onClick={(e) => {setIsOpen(!isOpen)}} id="filter-types-button" className = {filterIsSelected(filter)?'btn btn-sm dropdown-toggle btn-outline-primary':"btn btn-sm btn-primary dropdown-toggle"}  type="button"
                data-toggle="dropdown" aria-expanded="false">
            <i className="fas fa-sliders-h"></i>
        </button>

        <div ref = {selectPosition} onClick={(e)=> e.stopPropagation()}  id="filter-types-menu" className="dropdown-menu dropdown-menu-right mt-2 show"
             x-placement="top-start">
            <ListDropDown/>
        </div>
    </div>)

    const dropDown = (<div className="dropdown" onClick={(e) =>{setIsOpen(!isOpen)}}>
        <button ref = {notOpenButton}  onClick={() => setIsOpen(!isOpen)} id="filter-types-button" className = {filterIsSelected(filter)?'btn btn-sm dropdown-toggle btn-outline-primary':"btn btn-sm btn-primary dropdown-toggle"}
                 type="button"
                data-toggle="dropdown" aria-expanded="false">
            <i ref= {ButtonIcon} className="fas fa-sliders-h"></i>
        </button>
    </div>)

    function selectButton(){
        if(isOpen) return listItems
        else return dropDown
    }

    return (
        <div onClick={(e) => e.stopPropagation()} className={''}>
            {selectButton()}
        </div>
    );

}
export default React.memo(DropDownFavouritesAllStocks)