import React from "react";
import {TickerContext} from "../../Context";
import useGetCurrentTicker from "../../hooks/useGetCurrentTicker";

interface AuxProps {
    children: React.ReactNode;
}

function CompanyProvider({children}:AuxProps) {
    const {
        setTradingInfo,
        currentTickerState,
        isCoinsPage, 
        companyInformation, 
        investApp, 
        SubsctribleHandler,
        tradingInfo,
        showStatusTooltip,
        setShowStatusTooltip,
        additionalPriceInfo,
        currentPrice
    } = useGetCurrentTicker()

    return <>
        <TickerContext.Provider value = {{
            currentTickerState:currentTickerState,
            companyInformation:companyInformation,
            investApp:investApp,
            tradingInfo:tradingInfo,
            SubsctribleHandler:SubsctribleHandler,
            showStatusTooltip:showStatusTooltip,
            setShowStatusTooltip:setShowStatusTooltip,
            setTradingInfo,
            isCoinsPage,
            additionalPriceInfo,
            currentPrice
        }}>
            {children}
        </TickerContext.Provider>
    </>
}
export  default CompanyProvider