import { useTransition } from "../../hooks/useTransition";
import { useLocation } from "react-router-dom";
import { UseActions } from "../../hooks/useActions";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import MessageModal from "../Modals/MessageModal";
import { ICompanyInformationCard } from "../Cards/CompanyInformationCard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useTranslate from "../../hooks/useTranslate";
import AILogo from "../../image/expalnationLogo";
interface ITimeLIneRelease {
  title: string;
  text: string;
  time: string;
  sourceName: string;
  source: string;
  CompanyName: string;
  dateFull: string;
  Comment: any;
  date: string;
  isCommentOpen: boolean;
  translatedText?: string;
  translatedTitle?: string;
  CardInformation: ICompanyInformationCard;
  id: number;
  countComments?: number;
  fullDateTootip?: string;
  explanation?: null | { text: string };
}

function TimeLineRelease(props: ITimeLIneRelease) {
  const location = useLocation();
  const translateEvents = useTypedSelector(
    (state) => state.UserReducer.User.settings.translateEvents
  );
  const { setCurrentMessage } = UseActions();
  const [isCommentOpen, setCommentOpen] = useState<boolean>(true);
  const [isShowTransition, showTransition] = useState<boolean>(false);
  const refProps = useRef<any>(props);
  const [showRelease, setShowRelease] = useState(false);
  const handleCloseRelease = () => setShowRelease(false);
  const handleShowRelease = () => setShowRelease(true);
  const { transitionToMessagePage } = useTransition();
  const { showEventBody, showTranslateLink } = useTranslate(props.text);
  const [isClickToAI, setIsClickToAI] = useState<boolean>(false);

  useEffect(() => {
    showTransition(translateEvents);
    if (!props.isCommentOpen) setCommentOpen(false);
    if (location.pathname.includes("comments")) setCommentOpen(false);
  }, []);

  const transitionToMessage = (e: React.MouseEvent<HTMLAnchorElement>, isClickToUIApplication?:boolean) => {
    // setIsClickToAI(!isClickToAI)
    transitionToMessagePage(handleShowRelease, props, isClickToUIApplication);
    e.preventDefault();
  };

  
  const { t } = useTranslation();
  
  const renderTooltip = (props: any) => (
    <Tooltip ref={(e) => console.log(e)} id="button-tooltip" {...props}>
      <div className="overlay">
        {props.header}
        {refProps.current.fullDateTootip}
      </div>
    </Tooltip>
  );
  // EVENT_AI_BUTTON
  const renderAITooltip = (props: any) => {
    return <Tooltip ref={(e) => console.log(e)}  id="button-tooltip" {...props} >
        <div className="overlay" >
          {t('EVENT_AI_BUTTON')}
        </div>
    </Tooltip>
  }
  const showTransitionHandler =
    (boolvalue: boolean) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      showTransition(boolvalue);
    };

  const explanationUI = useMemo(() => {
    return (
      <OverlayTrigger
          delay={{ show: 1, hide: 1 }}
          overlay={renderAITooltip}
          placement={"top"}
        >
      <a
        onClick={(e) => {
          setIsClickToAI(true)
          transitionToMessage(e, true)
        }}
        className="btn btn-primary btn-icon btn-xs ml-2"
      >
        <AILogo />
      </a>
      </OverlayTrigger>
    );
  }, [props]);

  return (
    <>
      <MessageModal
        isClickToAI = {isClickToAI}
        explanation={props.explanation}
        show={showRelease}
        handleClose={handleCloseRelease}
      />

      <div className="timeline-item align-items-start mb-4 event-wrapper event-wrapper-translated">
        <OverlayTrigger
          delay={{ show: 1, hide: 1 }}
          overlay={renderTooltip}
          placement={"top"}
        >
          <div
            data-tip={"s"}
            className="  timeline-label font-weight-bolder text-muted font-size-sm pr-1"
          >
            {props.time}
          </div>
        </OverlayTrigger>

        <div className="timeline-badge">
          <i className="far fa-newspaper text-success"></i>
        </div>
        <div className="font-size-md timeline-content pl-3">
          <div className="event--translated-text">
            <b>{t("EVENT_PRESS_RELEASE")}: </b>
            {showEventBody(
              props.translatedTitle,
              props.title,
              isShowTransition
            )}

            {props.explanation && <>{explanationUI}</>}
          </div>

          <div className="d-flex align-items-center">
            <div className="mt-1 font-size-sm">
              <a
                onClick={(e) => {setIsClickToAI(false);transitionToMessage(e)}}
                className="font-size-sm text-primary"
                href="#read-news-modal"
                data-toggle="modal"
                data-event-id="387952"
                data-pjax="0"
              >
                <span className="far fa-newspaper"></span>&nbsp;
                {t("STOCK_PRESS_RELEASES_READ_SHORT")}
              </a>{" "}
              |{" "}
              <a target="_blank" href={props.source}>
                {" "}
                {t("STOCK_PRESS_RELEASES_SOURCE")}
              </a>
              {showTranslateLink(
                isShowTransition,
                props.translatedText,
                props.text,
                showTransitionHandler
              )}
            </div>
            {props.Comment}
          </div>
        </div>
      </div>
    </>
  );
}

export default TimeLineRelease;
