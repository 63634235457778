import { ICard } from "../../hooks/useSelectCard";
import { GroupEnum } from "../../models/Search"
import UserService from "../../Services/UserService"
import {getPercent} from "../../Utills/ClearFunctions";


interface ICardArrayStrategy{
    getGroupQuery(group:GroupEnum, query:string):void; 
    getGroupData(response:any):any
}

export class CardArrayStrategy{
    
    static transformDataCard(stockResponse:any):Array<ICard>{
        const state = stockResponse.map((companyObj:any) => {
            if(!companyObj.priceInfo.oldPrice){
                return {
                    src:companyObj.logo,
                    cardName: companyObj.brand,
                    price:companyObj.priceInfo.price,
                    percent:null,
                    ticket:companyObj.ticker,
                    isUp:false,
                    oldPrice:companyObj.priceInfo.oldPrice,
                    currency:companyObj.priceInfo.currency,
                    isCrypto:companyObj.isCrypto
                }
            }

            const [percent,isUp] = getPercent(companyObj.priceInfo.price, companyObj.priceInfo.oldPrice)
            return {
                src:companyObj.logo,
                cardName: companyObj.brand,
                price:companyObj.priceInfo.price,
                percent:percent,
                ticket:companyObj.ticker,
                isUp:isUp,
                oldPrice:companyObj.priceInfo.oldPrice,
                currency:companyObj.priceInfo.currency,
                isCrypto:companyObj.isCrypto
            }
        })
        return state
    }

}   