import { useTranslation } from "react-i18next"
import { UseActions } from "./useActions"
import React from "react";
import TelegramModalBody from "../components/Modals/TelegramModalBody";
import AlertModal from "../components/Modals/AlertModal";
import AlertModalBody from "../components/Modals/AlertModalBody";

interface IUsetTelegramBackDrop{
    isShowTelegramAlertMessage?:string
}
function useTelegramBackDrop(){
    const {setChildren,setBackDropIsOpen,setBackDropHeader} = UseActions()
    const {t} =useTranslation()

    const openBackDrop = () => {
        setBackDropIsOpen(true)
        setBackDropHeader(t('TG_BANNER_BOT'))
        setChildren(<>  <TelegramModalBody/> </>)      
    }
    return {openBackDrop}
}
export default useTelegramBackDrop