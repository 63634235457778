import React, {useEffect, useRef} from "react";
import {ITabContentImg} from '../models/EventContentModel'
import EN_wizardStep3 from '../image/EN_wizardStep3.png'
import EN_wizardStep2 from '../image/EN_wizardStep2.png'
import wizardStep1 from '../image/wizardStep1.png'
import RU_wizardStep2 from '../image/RU_wizardStep2.png'
import RU_wizardStep3 from '../image/RU_wizardStep3.png'

import crypto_wizardStep1 from '../image/crypto/crypto_wizardStep1.png'
import EN_crypto_wizardStep2 from '../image/crypto/EN_crypto_wizardStep2.png'
import RU_crypto_wizardStep2 from '../image/crypto/RU_crypto_wizardStep2.png'
import RU_crypto_wizardStep3 from '../image/crypto/RU_crypto_wizardStep3.png'
import EN_crypto_wizardStep3 from '../image/crypto/EN_crypto_wizardStep3.png'

export enum WizarPictucturesEnum {
    EN_wizardStep3 = 'EN_wizardStep3',
    EN_wizardStep2 = 'EN_wizardStep2',
    RU_wizardStep2 = 'RU_wizardStep2',
    RU_wizardStep3 = 'RU_wizardStep3',
    EN_crypto_wizardStep2 = 'EN_crypto_wizardStep2',
    RU_crypto_wizardStep2 = 'RU_crypto_wizardStep2',
    RU_crypto_wizardStep3 = 'RU_crypto_wizardStep3',
    EN_crypto_wizardStep3 = 'EN_crypto_wizardStep3',
    wizardStep1 = 'wizardStep1',
    crypto_wizardStep1 = 'crypto_wizardStep1' 
}

function TabContentImg({header,text,active,imgSrc}:ITabContentImg){

    const getImg = (imgName:string) => {
        switch (imgName){
            case WizarPictucturesEnum.EN_wizardStep3:
                return <img style={{width:'320px'}} src={EN_wizardStep3} alt=""/>
            case WizarPictucturesEnum.EN_wizardStep2:
                return <img style={{width:'320px'}} src={EN_wizardStep2} alt=""/>
            case WizarPictucturesEnum.RU_wizardStep2:
                return <img style={{width:'320px'}} src={RU_wizardStep2} alt=""/>
            case WizarPictucturesEnum.wizardStep1:
                return <img style={{width:'320px'}} src={wizardStep1} alt=""/>
            case WizarPictucturesEnum.RU_wizardStep3:
                return <img style={{width:'320px'}} src={RU_wizardStep3} alt=""/>      
            case WizarPictucturesEnum.EN_crypto_wizardStep3:
                return <img style={{width:'320px'}} src={EN_crypto_wizardStep3} alt=""/>
            case WizarPictucturesEnum.EN_crypto_wizardStep2:
                return <img style={{width:'320px'}} src={EN_crypto_wizardStep2} alt=""/>
            case WizarPictucturesEnum.RU_crypto_wizardStep2:
                return <img style={{width:'320px'}} src={RU_crypto_wizardStep2} alt=""/>
            case WizarPictucturesEnum.crypto_wizardStep1:
                return <img style={{width:'320px'}} src={crypto_wizardStep1} alt=""/>
            case WizarPictucturesEnum.RU_crypto_wizardStep3:
                return <img style={{width:'320px'}} src={RU_crypto_wizardStep3} alt=""/>    
        }
    }

    return(
        <>
            <div  className={active ? 'tab-pane fade text-center active show' :'tab-pane fade text-center'} id="ts_step_1" role="tabpanel"
                 aria-labelledby="ts_step_1">
                {getImg(imgSrc)}
                <h3 className="mt-2 font-weight-bolder font-size-h4 font-size-h1-lg">{header}</h3>
                <p>{text}</p>
            </div>
        </>
    )
}

export default TabContentImg