import { FC } from "react";
import { ChartGroupTypes,AreaPeriodType, typeGraphEnum } from "../../../models/Chart";


export interface IElemetnGraphControlState{
    id:ChartGroupTypes | AreaPeriodType
    name:string,
    active:boolean,
}



export interface IElementGraphControl{
    id:ChartGroupTypes | AreaPeriodType
    name:string,
    active:boolean,
    setCallback: (id:ChartGroupTypes | AreaPeriodType) => void
}

export const Element:FC<IElementGraphControl> = ({name, id,active,setCallback}) => {

    const clickHandler = (id: ChartGroupTypes | AreaPeriodType) => (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setCallback(id)
    }

    return <>
        <a 
            href=""
            onClick={clickHandler(id)}
            className={`btn btn-sm ${(active)? "btn-primary": ""} font-size-sm font-weight-bolder p-2`}>
                {name}    
        </a>
    </>
}

