import { TELEGRAM_BOT_URL } from "../models/LInks"
import UserService from "../Services/UserService"
import { DeviceIsMobile, DeviseIsApplicationOnly } from "../Utills/ClearFunctions"
import { UseActions } from "./useActions"
import { useTypedSelector } from "./useTypedSelector"
import TelegramUsers from '../json_data.json'
import { useMemo } from "react"

function useTelegram() {
    const {SetTelegramIsConnect,SetShowTelegram,setTelegram} = UseActions()
    const User = useTypedSelector(state => state.UserReducer.User)

    const getKey = async () => {
        const response:any = await UserService.getTelegramKey()
        return response.data.key
    }
    const isShowTelegramAlert = useMemo(() => {
        //@ts-ignore
        const curentUserId = User.id
        const isRigthUser = TelegramUsers.find((value) => value === curentUserId)
        const telegramConnected = !User.settings.telegramConnected
        return telegramConnected && isRigthUser
    },[User])

    const installTelegram = async (e:React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        const key = await getKey()

        if(DeviseIsApplicationOnly())
            window.location.href = `${TELEGRAM_BOT_URL}?start=${key}`
        else window.open(`${TELEGRAM_BOT_URL}?start=${key}`)
        SetTelegramIsConnect(true)
    }
    return {isShowTelegramAlert, installTelegram,SetTelegramIsConnect,SetShowTelegram}
}

export default useTelegram