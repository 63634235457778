import {IPeriod} from "../UserEvent/types";
export interface IFavouriteCard{
    src:string,
    cardName:string,
    ticket:string,
    percent:number,
    price:number,
    star:boolean,
    fill:boolean,
}

export type IFavouriteCards = {
    FavouritesCards:Array<IFavouriteCard>,
    isAddedInFavourites:boolean
}

export enum allFavouriteCardstEnum{
    SET_FAVOURITES_CARD = "SET_FAVOURITES_CARD",
    SET_IS_ADDED_IN_FAVOURITES = "SET_IS_ADDED_IN_FAVOURITES",
}

export interface ISetAddedInFavourites{
    type:allFavouriteCardstEnum.SET_IS_ADDED_IN_FAVOURITES,
    payload:boolean,
}

export interface ISetFavouritesCards{
    type:allFavouriteCardstEnum.SET_FAVOURITES_CARD,
    payload:Array<IFavouriteCard>,
}

export type actionType = ISetFavouritesCards | ISetAddedInFavourites