import { useState } from "react"
import UserService from "../Services/UserService"
import useDate from "./useDate"
import { useTypedSelector } from "./useTypedSelector"
import useEvent from "../Strategy/Events/useEvent";


function useLocalEventTicker(){
    const [TickerEvents, setTickerEvent] = useState<any>()
    const [currentTicker,setCurrentTicker] = useState<string>('')
    const {getCurrentDate,getMomentDate} = useDate()
    const [lastAllEventId,setLastAllEventId] = useState<number| null>(null)
    const {replaceDataEvent} = useEvent()
    const [currentBlcok,setCurrentBlcok] = useState<any>()

    const lastRowTickerEventHandler = async (block:string) => {
        let response:any
        if(currentBlcok === 'PRESS_RELEAS')
            response = await UserService.getTikerEventsPressReleasesMore(currentTicker,lastAllEventId as number)
        if(currentBlcok === 'REPORT')
            response = await UserService.getTikerEventsReportMore(currentTicker,lastAllEventId as number)
        if(currentBlcok === 'FORECAST')
            response = await await UserService.getTikerEventAnaliticMore(currentTicker,lastAllEventId as number)
        if(response.data.events.length === 0) return
        setLastAllEventId(response.data.lastId)
        const JsxData = await replaceDataEvent(response.data)

        setTickerEvent((prev:any) => {
             return [...prev,...JsxData]
            }
        )
    }

    const getTikerEvents = async (ticker:string) => {        
        const response = await UserService.getTikerEventsPressReleases(ticker)
        return response.data
    }

    const getReportEvents = async (ticker:string) => {        
        const response = await UserService.getTikerEventsReport(ticker)
        return response.data
    }

    const getAnaliticEvents = async (ticker:string) => {        
        const response = await UserService.getTikerEventAnalitic(ticker)
        return response.data
    }

    const setTickerEvents = async (ticker:string,block:string) => {
        
        setCurrentTicker(ticker)
        let data:any 
        setCurrentBlcok(block)
        
        if(block ===  'PRESS_RELEAS' ){
            data = await getTikerEvents(ticker)
        }
        if(block === 'REPORT')
            data = await getReportEvents(ticker)
        if(block === 'FORECAST')
            data = await getAnaliticEvents(ticker)
        setLastAllEventId(data.lastId)
        const JsxData = await replaceDataEvent(data)
        setTickerEvent(JsxData)
    } 
    return {TickerEvents, setTickerEvents, lastRowTickerEventHandler}
}
export default useLocalEventTicker