import { useState } from "react"
import { UseActions } from "../../hooks/useActions"
import { useTypedSelector } from "../../hooks/useTypedSelector"
import UserService from "../../Services/UserService"
import useEvent from "./useEvent"

function useLastEventQuery(setHiddenCounts:(counts:number) => void){
    const [LastEvent, setLastEvent] = useState<any>()
    const [currentTicker,setCurrentTicker] = useState<string>('')
    const filter = useTypedSelector(state => state.Filter.filters)
    const [lastAllEventId,setLastAllEventId] = useState<number| null>(null)
    const {replaceDataTimeLine} = useEvent()
    const {setLastElementsIsLoading} = UseActions()
    const [lastEventIsLoading, setLastEventIsLoading] = useState<boolean>(false)
    const [isLoadingLasRow, setIsLoadingLasRow] = useState<boolean>(false)

    const lastRowLastEventHandler = async (lastId: number| null, countCall?:number) => {
        setIsLoadingLasRow(true)
        const response = await UserService.getLastEventsMore(filter,currentTicker,(!lastId? lastAllEventId: lastId) as number)
        const {JsxData, isMore} = await replaceDataTimeLine(response.data)
        setIsLoadingLasRow(false)

        if(JsxData.length === 0) return
        setLastAllEventId(response.data.lastId)
        setLastEvent((prev:any) => {
             return [...prev,...JsxData]
            }
        )
        if(isMore) {
            if(!countCall)
                lastRowLastEventHandler(response.data.lastId,1)
            else{
                if(countCall === 5) return
                lastRowLastEventHandler(response.data.lastId,++countCall)
            }
        }
    }

    const getTikerEvents = async (ticker:string) => {        
        setLastElementsIsLoading(true)
        const response = await UserService.getLastEvents(filter, ticker)
        setLastElementsIsLoading(false)
        return response.data
    }

    const setLastEvents = async (ticker:string) => {
        setLastEventIsLoading(true)
        setCurrentTicker(ticker)
        const data = await getTikerEvents(ticker)
        setLastAllEventId(data.lastId)
        const {JsxData,isMore} = await replaceDataTimeLine(data)
        setLastEvent(JsxData)
        setLastEventIsLoading(false)
        setHiddenCounts(data.hiddenEventsCount)
    }

    return {LastEvent, setLastEvents, lastRowLastEventHandler,lastEventIsLoading, isLoadingLasRow}

}
export default useLastEventQuery