import {IUser, PlaneTypes, SendTypeEnum, StatusTypes,ISetting,IProfile} from "../../../models/User";
import {IProfileTranslateObj} from "../../../page/Profile";
import {IUSerInformation} from "./index";

export interface IAvataSaveError{
    isError:boolean,
    TextError:string
}
export interface IAuth{
    isAuth:boolean | ''
    User:IUser
    Loading: boolean
    Error:string,
    avatarSaveError:IAvataSaveError
}

export enum AuthEnum{
    SET_GLOBAL_ISAUTH='SET_GLOBAL_IS_AUTH',
    SET_PLANE = "SET_PLANE",
    SET_USER = 'SET_USER',
    SET_ERROR = "SET_ERROR",
    SET_LOADING = "SET_LOADING",
    SET_SEND_TYPE = "SET_SEND_TYPE",
    SET_PROFILE = "SET_PROFILE",
    SET_TELEGRAM = "SET_TELEGRAM",
    SET_USER_PROFILE = "SET_USER_PROFILE",
    SET_USER_SETTINGS = "SET_USER_SETTINGS",
    SET_AVATAR_ERROR = "SET_AVATAR_ERROR",
    SET_HAS_INVITES = "SET_HAS_INVITES",
    SET_PROFILE_USER_INFORMATION = 'SET_PROFILE_USER_INFORMATION',
}

export interface ISetProfileUser{
    type:AuthEnum.SET_PROFILE_USER_INFORMATION
    payload:IUSerInformation
}


export interface ISetHasInvites{
    type:AuthEnum.SET_HAS_INVITES,
    payload:boolean
}

export interface ISetAvatarError{
    type:AuthEnum.SET_AVATAR_ERROR,
    payload:IAvataSaveError
} 

export interface ISetUserProfile{
    type:AuthEnum.SET_USER_PROFILE,
    payload:Partial<IProfile>
}

export interface ISetUserSettings{
    type:AuthEnum.SET_USER_SETTINGS,
    payload:Partial<ISetting>
}


export interface ISetTelegram{
    type:AuthEnum.SET_TELEGRAM,
    payload:boolean
}

export interface ISendType{
    type: AuthEnum.SET_SEND_TYPE,
    payload: SendTypeEnum,
}

export interface ISaveProfile{
    type:AuthEnum.SET_PROFILE,
    payload:IProfileTranslateObj
}


export interface IActionLogin{
    type: AuthEnum.SET_USER,
    payload: IUser,
}

export interface  IActionError{
    type:AuthEnum.SET_ERROR,
    payload:string
}

export interface IActionLoading {
    type:AuthEnum.SET_LOADING,
    payload:boolean
}

export interface  SetUserIsAuth{
    type: AuthEnum.SET_GLOBAL_ISAUTH,
    payload: boolean,
}

export interface SetPlane{
    type: AuthEnum.SET_PLANE,
    payload: PlaneTypes,
}

export type actionType = ISetProfileUser | ISetHasInvites | ISetAvatarError | ISetUserSettings | ISetUserProfile | ISetTelegram | SetUserIsAuth | ISaveProfile | ISendType | SetPlane | IActionError | IActionLogin | IActionLoading