import { useEffect, useRef, useState } from "react";
import Centrifuge from 'centrifuge'
import { ORIGIN } from "../../models/Domen";

interface IuseCentrifuge {
    setCurrentTickerState?: (obj: any) => void,
    setAdditionalPriceInfo: (obj: any) => void,
    isSocketOpen: boolean,
    tickerProps?: string,
    isAdditionalPriceOutput: boolean
    setCurrentPrice: (obj: any) => void
}

export function useCentrifuge({ setCurrentTickerState, tickerProps, isSocketOpen, setCurrentPrice, setAdditionalPriceInfo, isAdditionalPriceOutput }: IuseCentrifuge) {
    const centrifuge = useRef<any>()
    const tikcerSubscrible = useRef<any>()
    const [socketPrice, setSocketPrice] = useState<number | null>(null)
    const [ticker, setTicker] = useState<string | null>()
    const isStop = useRef<boolean>(false)

    useEffect(() => {
        if (ticker === tickerProps) return
        if (tickerProps = '') return
        setTicker(tickerProps)
    }, [tickerProps])

    const closeSubscible = () => {
        isStop.current = true
    }

    const continueSubscible = () => {
        isStop.current = false
    }

    const getOriginSocket = () => {
        if (ORIGIN.includes('localhost'))
            return 'tradesense.ru'
        else
            return window.location.hostname
    }

    useEffect(() => {
        const priceChangeCallback = !isAdditionalPriceOutput ? setCurrentTickerState : setAdditionalPriceInfo
        if (ticker === null) return
        if (!isSocketOpen) return

        centrifuge.current = new Centrifuge(`wss://${'tradesense.me'}:8000/connection/websocket`);
        tikcerSubscrible.current = centrifuge.current.subscribe(`prices.${ticker}`, (message: any) => {

            if (priceChangeCallback) {
                if (!isStop.current) {
                    priceChangeCallback((prev: any) => { return { ...prev, price: message.data.p } })
                    setCurrentPrice(message.data.p)
                }
                if (!isStop.current)
                    setSocketPrice(message.data.p)
            }
            if (tickerProps) {
                if (!isStop.current)
                    setSocketPrice(message.data.p)
            }

        });
        centrifuge.current.connect();

        return () => {
            tikcerSubscrible.current.unsubscribe()
        }
    }, [ticker, isSocketOpen])

    const changeTicker = (ticker: string) => {
        setTicker(ticker)
    }

    return { changeTicker, ticker, socketPrice, closeSubscible, continueSubscible }

}   