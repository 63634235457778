import { AxiosResponse } from "axios";
import $api from "../http";
import { AreaPeriodType, ChartGroupTypes, IDataChart,IChartResponse } from "../models/Chart";
import { ORIGIN } from "../models/Domen";

export default class ChartService{
    static getNotAuthData(ticker:string,t:number, ):  Promise< AxiosResponse<IChartResponse>> {
        // stock-news/chart/TSLA?t=123123123
        // https://tradesense.me/stock-news/chart/TSLA?t=123123123
        // return $api.get(`/stock-news/chart/${ticker}`,{params:{t:t}})
        // https://tradesense.me/api/v1/stocks/chart-guest?ticker=AAPL?t=12312123312
        return $api.get('stocks/chart-guest',{params:{ticker :ticker, t:t}})
    }
    static getAreaData(ticker:string,period:AreaPeriodType, ):  Promise< AxiosResponse<IChartResponse>> {
        return $api.get('/stocks/chart',{params:{ticker :ticker, period:period}})
    }

    static getCandleData(ticker:string,group:ChartGroupTypes):Promise< AxiosResponse<IChartResponse>> {
        return $api.get('/stocks/chart',{params:{ticker :ticker, group:group}})
    }

    static getHistotyCandleData(ticker:string,group:ChartGroupTypes,before:number):Promise< AxiosResponse<IChartResponse>>{
        return $api.get('/stocks/chart',{params:{ticker :ticker, group:group, before:before}})
    }

    static getHistotyAreaData(ticker:string,period:AreaPeriodType,before:number):Promise< AxiosResponse<IChartResponse>>{
        return $api.get('/stocks/chart',{params:{ticker :ticker, period:period, before:before}})
    }

} 