import CompanyInformationCard, {ICompanyInformationCard} from "./CompanyInformationCard";
import React, {useEffect, useState} from "react";
import EventTime from "../Time/EventTime";
import {useTransition} from "../../hooks/useTransition";
import CommentsModal from "../Modals/CommentsModal";
import {useLocation} from "react-router-dom";
import {FilterConstant} from "../../models/FilterEvent";
import {UseActions} from "../../hooks/useActions";
import { useCheckForPage } from "../../hooks/useCheckForPage";
import CommentCard from "./CommentCard";
import { Trans, useTranslation } from "react-i18next";

interface IMinMaxCardEvent {
    isUp: boolean,
    price:string,
    time:string,
    Comment:any
    isCommentOpen?:boolean
    CardInformation:ICompanyInformationCard
    id?:string
    countComments?:number
    mediumTime?:string | number | null

}

function MinMaxCardEvent(props: IMinMaxCardEvent) {
    const {transitionToComments} = useTransition()
    const location = useLocation()
    const {setCurrentEvent} = UseActions()
    const [isCommentOpen, setCommentOpen] = useState<boolean>(true)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const {checkForStockPage} = useCheckForPage()

    useEffect(() => {
        if(!props.isCommentOpen) setCommentOpen(false)
        if (location.pathname.includes('comments')) setCommentOpen(false)
    }, [])


    const showModal = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        if (document.body.clientWidth < 992) {
            setCurrentEvent(
                {
                    type:FilterConstant.YEARLY_MINIMUM_MAXIMUM,
                    data:{...props,isCommentOpen:false}
                }
            )
            transitionToComments(e)
        } else setShow(!show);
    }
    const {t} =useTranslation()

    return (
        <>
            {/*<EventTime time={props.time} />*/}
            {/* <CommentsModal show={show} handleClose={handleClose} type={FilterConstant.YEARLY_MINIMUM_MAXIMUM} data={{...{...props,isCommentOpen:false}}}/> */}

            {/*<div id="event-409626"*/}
            {/*     className="js__event-card event-wrapper event-wrapper-translated d-flex flex-lg-fill card card-custom card-shadowless p-5 bg-gray-100 text-dark mb-4">*/}

            <span className="font-weight-bolder mb-2">
                <i
                    className="fas fa-exchange-alt fa-rotate-90 text-primary mr-2"></i>
                {t('EVENT_PRICE_52HIGHTLOW')}
            </span>

                {
                    props.isUp
                        ? <span className="p-2">
                            <i className="fas fa-angle-double-up text-success mr-1"></i> <b>{props.price}&nbsp;$ </b>
                            <Trans i18nKey={'EVENT_PRICE_52HIGH'}><b></b></Trans>
                        {/* Цена достигла своего
                        <b> максимума </b>  
                        за год (52 недели) */}
                    </span>
                        : <span className="p-2">
                            <i className="fas fa-angle-double-down text-danger mr-1"></i> <b>{props.price}&nbsp;$ </b>
                        <Trans i18nKey={'EVENT_PRICE_52LOW'}><b></b></Trans>
                    </span>
                }

            {
                props.Comment
            }
            {/* <CommentCard countComments = {props.countComments} isCommentOpen={isCommentOpen || props.isCommentOpen} callback={setShow} show={show} id={props.id} /> */}


            {/*<div className="d-flex align-items-center">*/}
                {/*    {*/}
                {/*        (isCommentOpen || props.isCommentOpen) && <a*/}
                {/*            onClick={showModal} className="ml-auto mt-1 font-size-sm text-primary"*/}
                {/*            href="" data-toggle="modal"*/}
                {/*            data-event-id="332244" data-pjax="0"><span*/}
                {/*            className="far fa-comments"></span> Комментировать</a>*/}
                {/*    }*/}
                {/*</div>*/}
               
                { !checkForStockPage() &&  <CompanyInformationCard {...props.CardInformation} /> }
            {/*</div>*/}
        </>
    )
}

export default MinMaxCardEvent


