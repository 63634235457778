import { t } from "i18next"

function LongerSubscription(){
    return(
        <div className="align-items-center d-flex flex-column mt-10">
            <i className="far fa-check-circle text-success fa-3x mb-10"></i>
            <b className="mb-4 h3 font-weight-bolder"> {t('SUBSCRIPTION_SUBSCRIBED')}</b>
            <div className="row justify-content-md-center mt-10">
                <div className="text-center">
                    <a href="#closerefresh"
                       className="btn btn-primary btn-lg btn-block font-weight-bolder font-size-h6 mr-2">
                        Продолжить
                    </a>
                </div>
            </div>
        </div>
    )
}
export default LongerSubscription

