import { useEffect, useState } from "react"
import { IInvite, invitesStatusEnum } from "../../../hooks/useInvites"
import UserService from "../../../Services/UserService"
import { UseActions } from "../../../hooks/useActions";
import { useTranslation } from "react-i18next";

export const getAvailableInvites = (invites: IInvite[]) => {
    return invites.filter((invite: IInvite) => {
        return invite.status === invitesStatusEnum.READY
    })
}
function Ready(props: Partial<IInvite> & { numberEvent: number, showAlertHandler: () => void, setInvites: (Invite: Array<IInvite>) => void }) {
    const { setCountEvents } = UseActions()

    const copyToClipboard = (str: string) => {
        //@ts-ignore
        Clipboard.copy(str);
    };

    const copyElement = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        copyToClipboard(props.code as string)
        UserService.copyEvents((props.id as number)).then((response: any) => {
            const availableInvites = getAvailableInvites(response.data.invites)
            setCountEvents(availableInvites.length)
            props.setInvites(response.data.invites)
            props.showAlertHandler()
        })

    }
    const { t } = useTranslation()
    return (
        <div className="p-1 d-flex">
            <div className="d-flex flex-row-fluid">
                <div className="d-flex flex-column pr-5 flex-grow-1">
                    <span className="text-muted font-weight-bold">
                        {t('INVITES_INVITE_NUMBER', { count: props.numberEvent })}
                    </span>
                    <div className="text-dark mb-1 font-weight-bolder font-size-lg">
                        <span id="invite-link-22954">
                            {props.code}                                                                                         </span>
                    </div>
                </div>
                <div className="d-flex align-items-center py-2" data-toggle="tooltip" title=""
                    data-original-title="Копировать">
                    <a href="#" onClick={copyElement} data-invite-id="22954"
                        data-element-id="invite-link-22954"
                        className="js__copy-invite-link btn btn-icon btn-primary btn-sm">
                        <i className="fas fa-copy text-white" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default Ready