import { FC } from "react";
import { useTransition } from "../../hooks/useTransition";
import { t } from "i18next";



interface ITimerClosedAlert{
    closeInterval:() => void
}


const TimerClosedAlert:FC<ITimerClosedAlert> = ({closeInterval}) => {
    const {handleCloseSSubscrible} = useTransition()
    return <>
            <small className="text-center" id="waiting-toolong">
                
                <a className="WaitingPurchaise__text" onClick={ (e) => {
                        e.stopPropagation()
                        closeInterval()
                        handleCloseSSubscrible()
                    }        
                } 
                    href="javascript:void(0)">
                    {t('SUBSCRIPTION_CONTINUE')}
                </a>
            </small>
        </>
}

export default TimerClosedAlert