import React, {ChangeEvent, useState} from "react";


interface IUserInfo{
    userNameElement:JSX.Element,
    nameElement:JSX.Element,
    aboutElement:JSX.Element,
    phone:string
}
function UserInfo({userNameElement, nameElement, aboutElement, phone}:IUserInfo) {

    return (
        <>

                {userNameElement}
                {nameElement}
                {aboutElement}
        </>
    )
}

export default React.memo(UserInfo)