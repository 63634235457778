import { UseActions } from "../../hooks/useActions";
import { useTransition } from "../../hooks/useTransition";
import React, { useState } from "react";
import { checkForCIS, deviceIsIphoneApplication, gettOrigin } from "../../Utills/ClearFunctions";
import { ORIGIN } from "../../models/Domen";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import UserService from "../../Services/UserService";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { REVIEW } from "../../models/Review";

function ProfileFooter() {
    const { SetGlobalUserAuth } = UseActions()
    const { transitionToMain, logOutTransition } = useTransition()
    const [isSpin, setSpin] = useState<boolean>(false)
    const [resoredP, setRestoredP] = useState<boolean>(false)
    const MySwal = withReactContent(Swal)
    const region = useTypedSelector(state => state.UserReducer.User.settings.region)

    const Quite = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        logOutTransition()
    }

    const handleRestoreSubscription = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        setSpin(true)
        document.location = `${ORIGIN}/restore-subscription`
        setTimeout(() => {
            setSpin(false)
            setRestoredP(true)
        }, 5000)
    }
    const { t } = useTranslation()


    const handleDeleteUserClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        MySwal.fire({
            title: <p>{t('PROFILE_FOOTER_DELETE_PROMPT')}</p>,
            showConfirmButton: false,
            showDenyButton: true,
            showCancelButton: true,
            denyButtonText: `${t('PROFILE_FOOTER_DELETE_PROMPT_YES')}`,
            cancelButtonText: `${t('PROFILE_FOOTER_DELETE_PROMPT_NO')}`,
        }).then((result) => {
            if (result.isDenied) {
                UserService.deleteUser().then((response) => {

                    if (response.status === 200) {
                        logOutTransition()
                    }

                })
            }
        })
    }

    return (
        <div className="row">
            <div className="card card-custom col-lg-12 mb-4">
                <div className="card-body px-2">
                    <div className="mb-4">
                        <a href="https://t.me/tradesense_support" className="d-flex align-items-center">
                            <i className="fab fa-telegram fa-2x text-primary mr-3" aria-hidden="true"></i>
                            {t('PROFILE_FOOTER_TG_SUPPORT')}
                        </a>
                    </div>
                    <div className="mb-8 align-items-center">
                        <p className="text-muted font-size-sm">{t('PROFILE_FOOTER_LEGAL')} <a target="_blank"
                            href={`${gettOrigin()}/law/terms`} >
                            {t('PROFILE_FOOTER_TERMS')}
                        </a>, <a target="_blank"
                            href={`${gettOrigin()}/law/privacy`} >{t('PROFILE_FOOTER_PRIVACY')}</a>
                            {
                                checkForCIS(region) && <>
                                    , <a target="_blank" href={`${gettOrigin()}/law/rules`}> {t('PROFILE_FOOTER_LICENSE')} </a>
                                </>
                            }

                        </p>
                        {
                            deviceIsIphoneApplication() && <>

                                <div className="align-items-center d-flex mb-4" id="restoreLinkContainer">

                                    {
                                        resoredP
                                            ? <b className="text-primary"> {t('PROFILE_RESTORED_PURCHASES')} </b>
                                            : <a
                                                onClick={handleRestoreSubscription}
                                                href="/restore-subscription"
                                                className="text-primary">
                                                <span
                                                    className="text-primary"
                                                    id="restoreLink"> {t('PROFILE_RESTORE_PURCHASES')}
                                                </span>
                                            </a>
                                    }
                                    {
                                        isSpin && <div className="spinner spinner-sm spinner-primary ml-5"></div>
                                    }
                                </div>

                                <div className="align-items-center d-flex mb-4">

                                    <a href="/manage-subscription" className="text-primary">
                                        {t('PROFILE_MANAGE_SUBSCRIPTION')}</a>
                                </div>

                            </>
                        }

                    </div>
                    <div className="align-items-center">
                        <a data-method="post" href={`${gettOrigin()}/site/logout`} onClick={Quite}> {t('PROFILE_FOOTER_LOGOUT')}</a>
                    </div>

                    {
                        window.navigator.userAgent.includes(REVIEW) && window.navigator.userAgent.includes('iOS') && <a style={{ display: 'block' }} onClick={handleDeleteUserClick} className="text-danger mt-6" href=""> {t('PROFILE_FOOTER_DELETE')} </a>
                    }

                    {/* {
                        true && <a style={{ display: 'block' }} onClick={handleDeleteUserClick} className="text-danger mt-6" href=""> {t('PROFILE_FOOTER_DELETE')} </a>
                    } */}

                </div>
            </div>
        </div>
    )
}
export default React.memo(ProfileFooter)