import EventSwitch from "../../hooks/EventSwitch"
import { UseActions } from "../../hooks/useActions"
import useDate from "../../hooks/useDate"
import { v4 as uuidv4 } from 'uuid';
//@ts-ignore
import { IPeriod } from "../../redux/reducers/UserEvent/types";
import { trendSwitch } from "../../hooks/TrendSwitch";
import TimeLineCard from "../../components/Cards/TimeLineCard";
import LastEventSwitch from "../../hooks/LastEventSwitch";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import TimeLineDay from "../../components/Time/TimeLineTimeDay";
import React from "react";
import { EventTopicEnum } from "../../models/EventContentModel";
import UserService from "../../Services/UserService";
import { getPercent, getPeriodObj } from "../../Utills/ClearFunctions";
import { FilterConstant } from "../../models/FilterEvent";
import TimeLineMore from "../../components/TimeLine/TimeLineMore";

function useEvent() {
    const { setPeriod, setTrendPeriod, setFavouritesPeriod, setAllPeriod } = UseActions()
    const { getCurrentDate, getMomentDate, getMediumDateabdShortTime } = useDate()
    const User = useTypedSelector(selector => selector.UserReducer.User)
    const filter = useTypedSelector(state => state.Filter.filters)

    const getFavouritesEvents = async () => {
        const response = await UserService.getFavouritesEvent(filter)
        return response.data
    }

    const getAllEvents = async () => {
        const response = await UserService.getAllEvents(filter)
        return response.data
    }

    const dispatchPeriod = (periodObj: IPeriod, tab: EventTopicEnum) => {

        switch (tab) {
            case EventTopicEnum.ALL_STOCKS:
                setAllPeriod(periodObj)
                break
            case EventTopicEnum.FAVORITES:
                setFavouritesPeriod(periodObj)
                break
            case EventTopicEnum.TREND:
                setTrendPeriod(periodObj)
                break
        }
    }

    const getPeriod = (data: any, tab: EventTopicEnum) => {
        if (tab == EventTopicEnum.TREND) {
            const periodObj = getPeriodObj(data?.groups[0]?.period)
            setTrendPeriod(periodObj)
            return
        }
        const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate] = getMomentDate(data.events[0].createdAt)

        const periodObj: IPeriod = {
            reduction: `${currentDay}`,
            full: `${currentDay} ${nameOfMounth}`
        }
        if (optionalDataValue)
            periodObj.full = `${optionalDataValue + ', '} ${currentDay} ${nameOfMounth}`
        dispatchPeriod(periodObj, tab)
    }


    const getPeriodRequest = async (tab: EventTopicEnum) => {
        let data: any = ''
        if (tab === EventTopicEnum.ALL_STOCKS) {
            data = await getAllEvents()
        }
        else {
            data = await getFavouritesEvents()
        }

        getPeriod(data, tab)
    }

    const replaceDataTimeLine = async (data: any) => {
        let prevDay = getCurrentDate()
        let accordion = []
        let isAccordion = false
        let countAccordions = 0
        let isMore = false
        const JsxElements = []
        for (let i = 0; i < data.events.length; i++) {
            const event = data.events[i]
            const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate, year, fullDateTootip] = getMomentDate(event.createdAt)
            const dateFullPressRelize = `${currentDay} ${nameOfMounth} ${year} `
            if (!isAccordion && event.type === FilterConstant.PRICE_FLUCTUATIONS) {
                if (i === data.events.length - 1) {
                    isAccordion = false
                } else {
                    if (data.events[i + 1].type === FilterConstant.PRICE_FLUCTUATIONS) {
                        isAccordion = true
                        countAccordions++
                        const EventJsx = LastEventSwitch(
                            event.type,
                            event.data,
                            event.priceInfo,
                            event.stock,
                            shortTime as string,
                            currentDay as number,
                            prevDay,
                            nameOfMounth as string,
                            optionalDataValue as string | null,
                            dateFullPressRelize,
                            event.id,
                            event.countComments,
                            fullDateTootip as string
                        )
                        prevDay = currentDay as number
                        JsxElements.push(EventJsx)
                        continue

                    } else
                        isAccordion = false
                }
            }

            if (isAccordion) {
                const EventJsx = LastEventSwitch(
                    event.type,
                    event.data,
                    event.priceInfo,
                    event.stock,
                    shortTime as string,
                    currentDay as number,
                    prevDay,
                    nameOfMounth as string,
                    optionalDataValue as string | null,
                    dateFullPressRelize,
                    event.id,
                    event.countComments,
                    fullDateTootip as string
                )
                prevDay = currentDay as number
                accordion.push(EventJsx)

                if (data.events[i + 1]) {
                    if (data.events[i + 1].type === FilterConstant.PRICE_FLUCTUATIONS)
                        continue
                }
                const TimeLineJsx = <TimeLineMore data={accordion} count={accordion.length} />
                prevDay = currentDay as number
                JsxElements.push(TimeLineJsx)
                if (accordion.length >= 19) {
                    isMore = true
                }
                accordion = []
                isAccordion = false
                continue
            }

            const explanation = event.explanation

            const EventJsx = LastEventSwitch(
                event.type,
                event.data,
                event.priceInfo,
                event.stock,
                shortTime as string,
                currentDay as number,
                prevDay,
                nameOfMounth as string,
                optionalDataValue as string | null,
                dateFullPressRelize,
                event.id,
                event.countComments,
                fullDateTootip as string,
                explanation
            )
            prevDay = currentDay as number
            JsxElements.push(EventJsx)
        }
        return { JsxData: JsxElements, isMore: isMore }
    }

    const replaceDataTrends = (trendStocks: any, period: IPeriod) => {
        return trendStocks.map((trendElementData: any) => {
            const TimeLineDate: any = []
            const stock = trendElementData.stock
            const isUp = trendElementData.diff > 0
            const TrendData = {
                ticker: stock.ticker,
                companyName: stock.brand,
                imgSrc: stock.logo,
                price: stock.priceInfo.price,
                isUp: isUp,
                key: uuidv4(),
                oldPrice: stock.priceInfo.oldPrice,
                currency: stock.priceInfo.currency,
                badgeText: `${Math.floor(trendElementData.diff * 10) / 10}% / ${period.reduction}`,
                brand: stock.brand,
                ...trendElementData.priceInfo
            }
            let prevDay = getCurrentDate()

            trendElementData.events.map((timeLineElement: any, index: number) => {
                let object
                let type = 1
                type = timeLineElement.type
                const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate, year, fullDateTootip] = getMomentDate(timeLineElement.createdAt)
                //@ts-ignore
                const dateFullPressRelize = `${currentDay} ${nameOfMounth} ${year} `

                object = trendSwitch(
                    type,
                    currentDay as number,
                    prevDay,
                    timeLineElement,
                    shortTime as string,
                    nameOfMounth as string,
                    dateFullPressRelize,
                    timeLineElement.id,
                    timeLineElement.countComments,
                    fullDateTootip as string,
                    timeLineElement.explanation
                )
                prevDay = currentDay as number

                TimeLineDate.push(object)
            })
            return <TimeLineCard {...{ ...TrendData, TimeLineDate: [...TimeLineDate] }} />
        })
    }

    const getEventJSX = (event: any, rowJSX: any, prevDay: any) => {
        const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate, year,
            fullDateTootip,
            shortDateTime,
            mediumTime] = getMomentDate(event.createdAt)
        
        const explanation = event.explanation

        const EventJsx = EventSwitch(
            event.type,
            event.data,
            event.priceInfo,
            event.stock,
            shortTime as string,
            currentDay as number,
            prevDay,
            nameOfMounth as string,
            optionalDataValue as string | null,
            fullDate as string,
            event.id,
            User.canEditEvents,
            undefined,
            event.countComments,
            shortDateTime,
            mediumTime,
            getMediumDateabdShortTime,
            fullDateTootip as string,
            event.data.publishedAt,
            event.createdAt,
            event.explanation
        )

        if (prevDay !== currentDay) {
            rowJSX.push([<div>
                {
                    (!optionalDataValue)
                        ? <>
                            <TimeLineDay date={`${currentDay + ' ' + nameOfMounth}`} />
                            <hr />
                        </>
                        : <TimeLineDay date={`${optionalDataValue + ', ' + currentDay + ' ' + nameOfMounth}`} />
                }

            </div>, EventJsx])
        }
        else {
            rowJSX.push(EventJsx)
        }
    }

    const replaceDataEvent = (data: any, isOtputFirstDate?: boolean) => {
        let prevDay = getCurrentDate()
        let rowJsx: any = []
        for (let i = 0; i < data.events.length; i++) {
            const event = data.events[i]
            if (!event) continue
            const [shortTime, currentDay, nameOfMounth, currentDate, optionalDataValue, fullDate] = getMomentDate(event.createdAt)

            const handleFirstElement = () => {
                prevDay = Number(currentDate)
            }
            if (i === 0 && !isOtputFirstDate) {
                handleFirstElement()
            }

            getEventJSX(event, rowJsx, prevDay)
            prevDay = currentDay as number
        }
        return rowJsx.flat()
    }

    return { replaceDataEvent, replaceDataTrends, replaceDataTimeLine, getFavouritesEvents, getPeriodRequest, getPeriod }
}

export default useEvent

