import React, { useEffect, useRef, useState } from 'react';
import Phone from "../components/Inputs/Phone";
import CodeInput from "../components/Inputs/CodeInput";
import '../styles/Login.css'
import { useTransition } from "../hooks/useTransition";
import { CustomErrorInput, ErrorInput } from '../components/Errors';
import { UseActions } from "../hooks/useActions";
import { useTypedSelector } from "../hooks/useTypedSelector";
import { SendTypeEnum, StatusTypes } from "../models/User";
import Captha from './Login/Captha';
import { deviceIsIphoneApplication, getLoginStyle, gettOrigin } from '../Utills/ClearFunctions';
import { useLocation } from 'react-router-dom';
import { SITE_KEY } from "../models/reCaptcha";
import { Trans, useTranslation } from 'react-i18next';
import { TELEGRAM_BOT_URL } from '../models/LInks';

function Login() {
    const { transitionToWizard } = useTransition()
    const { transitionToWaiting, transitionToMainHandlers, transitionToEventMain } = useTransition()
    const [phoneNumber, setPhoneNumber] = useState<string>()
    const [timer, setTimer] = useState<number>(60)
    const timerRef = useRef<number>(timer)
    const [isStepOne, setIsStepOne] = useState<boolean>(true)
    const [timerIsClose, setTimerIsClose] = useState<boolean>(false)
    const [isValidPhoneInput, setIsValidPhoneInput] = useState<boolean>(false)
    const [codeValue, setCodeValue] = useState<string>('')
    const [codeError, setCodeError] = useState<boolean>(false)
    const { verification, login, verificationIOS } = UseActions()
    const { setError } = UseActions()
    const timerId = useRef<any>(0)
    const [isValidCodeInput, setIsValidCodeInput] = useState<boolean>(false)
    const [sendPhone, setSendPhone] = useState<boolean>(false)

    const Loading = useTypedSelector(state => state.UserReducer.Loading)
    const Error = useTypedSelector(state => state.UserReducer.Error)

    const location = useLocation()
    const User = useTypedSelector(state => state.UserReducer.User)
    const isAuth = useTypedSelector(state => state.UserReducer.isAuth)

    const isCryptoPage = useTypedSelector(state => state.PageReducer.isCryptoPage)

    useEffect(() => {
        getLoginStyle()
        if (sendPhone) {
            setSendPhone(false)
            if (Error === '') {
                setIsStepOne(false)
            }
        }
    }, [Error, sendPhone])

    useEffect(() => {
        const code = window.location.href.split('=')[1]
        const phoneNumberFromLocalstorage = JSON.parse(localStorage.getItem('phoneNumber') as string)
        if (code && phoneNumberFromLocalstorage) {
            setIsStepOne(false)
            changeCodeValue(code)
            setPhoneNumber(phoneNumberFromLocalstorage)
            localStorage.removeItem('phoneNumber')
            clearInterval(timerId.current)
            startTimer()
        }
    }, [location])

    const changeCodeValue = (value: string) => {
        setCodeValue(value)
    }

    const changePhoneValue = (value: string) => {
        setPhoneNumber(value)
    }

    const changeValidPhoneInput = (flag: boolean) => {
        setIsValidPhoneInput(flag)
    }

    const sendCode = async () => {
        //@ts-ignore
        grecaptcha.ready(function () {
            //@ts-ignore
            grecaptcha.execute(SITE_KEY, { action: 'verification' }).then(async (token: any) => {
                if (isValidPhoneInput) {
                    localStorage.setItem('phoneNumber', JSON.stringify(phoneNumber))
                    await verification(phoneNumber as string, token)
                    setSendPhone(true)
                    startTimer()
                }
            });
        });
    }

    useEffect(() => {
        if (isAuth) {
            transitionToMainHandlers()
        }
    }, [isAuth])

    const signInValidation = async () => {
        const response = await login(phoneNumber as string, codeValue, isCryptoPage)
        if (isAuth && User.status === StatusTypes.PENDING) transitionToWaiting()
    }

    const changePhoneBack = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        clearInterval(timerId.current)
        setPhoneNumber('')
        setIsStepOne(true)
    }

    const startTimer = () => {
        setTimer(60)
        timerRef.current = 60
        setTimerIsClose(false)
        timerId.current = setInterval(() => {
            if (timerRef.current === 0) {
                setTimerIsClose(true)
                clearInterval(timerId.current)
            }
            setTimer(timerRef.current - 1)
            timerRef.current -= 1
        }, 1000)
    }

    const sendCodeAgain = () => {
        setCodeValue('')
        setIsValidCodeInput(true)
        sendCode()

    }

    const signIn = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault()
        signInValidation()
    }

    useEffect(() => {
        if (!isStepOne) return
        setTimerIsClose(false)
        //@ts-ignore
        clearInterval(timerId)

    }, [isStepOne])


    const submitForm = (e: any) => {
        e.preventDefault()
        if (!isStepOne) signInValidation()
        else sendCode()
    }

    const style = `.login-form {
        display: none;
    }
.login-wrapper.code-sent .login-form {
        display: block;
    }
.login-wrapper.code-sent .send-verification-code-form {
        display: none;
    }
.send-verification-code-form .iti {
        display: block;
    }
.sms-input {
        letter-spacing: 0.7rem;
    }
.send-code-again {
        display: none;
    }
.login-wrapper.resend-code .send-code-again {
        display: block;
    }
.login-wrapper.resend-code .send-code-after {
        display: none;
    }
.nav-pills .nav-link.active {
        background: #989898!important;
    }
.nav-pills .nav-link.active:hover {
        background: #989898!important;
    }
.nav-pills .nav-link:not(.active) {
        background: #eaeaea!important;
    }
.nav-pills .nav-link:not(.active):hover {
        background: #989898!important;
    }`

    const getHrefWithOrigin = () => {
        if(isCryptoPage)
            return gettOrigin() + '/crypto'
        else return gettOrigin()
    }

    const { t } = useTranslation()

    return (

        <div className='d-flex flex-column flex-root flex-center'>
            <style>
                {style}
            </style>
            <div className='simple-container d-flex flex-column flex-lg-row flex-column-fluid'>
                <div className="d-flex flex-column-auto flex-column pt-10 w-100">
                    <a href={`${ getHrefWithOrigin() }`} className="text-center mb-5">
                        <img src={`${gettOrigin()}/img/ts-logo.svg`} alt="logo" className="h-35px" />
                    </a>
                    <h4 className="logo-subtitle font-weight-bolder text-center font-size-h5">
                        {
                            isCryptoPage ? <Trans i18nKey='WIZARD_LOGIN_CRYPTO_HEADER'> <br /> </Trans>
                                : <Trans i18nKey='WIZARD_LOGIN_HEADER'> <br /> </Trans>
                        }
                    </h4>
                    <div className="card card-custom card-shadowless bg-white mt-4">
                        <div className="card-body pt-0 pb-4">
                            <div className="login-wrapper  resend-code" id="login-wrapper">
                                <div className="pb-10 pt-10 text-center">
                                    <h3 className="font-weight-bolder text-dark font-size-h3">
                                        {t('WIZARD_LOGIN_SIGNIN_HEADER')} </h3>
                                    {(Error !== '') && <ErrorInput text={Error} />}
                                </div>
                                {
                                    (!isStepOne && User.sendType === SendTypeEnum.TELEGRAM) &&
                                    <div id="sent-by-telegram" className="alert-success rounded p-4">

                                        <Trans i18nKey='WIZARD_LOGIN_SIGNIN_CODE_SENT'>
                                            <a href={TELEGRAM_BOT_URL}></a>
                                        </Trans>
                                    </div>
                                }
                                {
                                    (isStepOne)
                                        ? <label className="font-size-h6 font-weight-bolder text-dark pt-5"
                                            htmlFor="send-verification-code-form-phone-input">
                                            {t('WIZARD_LOGIN_SIGNIN_PHONE')}</label>

                                        : <label className="d-flex justify-content-between pt-5"
                                            htmlFor="verification-code-input"><span
                                                className="text-dark font-size-h6 font-weight-bolder">{t('WIZARD_LOGIN_SIGNIN_CODE')}</span><a
                                                    href="#" id="change-phone"
                                                    className="font-size-h6 font-weight-bolder text-nowrap"
                                                    onClick={changePhoneBack}>
                                                {t('WIZARD_LOGIN_SIGNIN_CHANGE_PHONE')}</a></label>
                                }
                                <form onSubmit={submitForm}>
                                    {
                                        (isStepOne)
                                            ? <> <Phone
                                                phoneNumber={phoneNumber}
                                                changePhoneValue={changePhoneValue}
                                                changeValidPhoneInput={changeValidPhoneInput}
                                                isValidPhoneInput={isValidPhoneInput}
                                                isFocus={true}
                                            />
                                                {(Error !== '') && <CustomErrorInput text={Error} />}
                                            </>
                                            : <CodeInput isValidCodeInput={isValidCodeInput} setIsValidCodeInput={setIsValidCodeInput} CodeValue={codeValue} changeCodeValue={changeCodeValue} />

                                    }
                                </form>
                                {
                                    !isStepOne
                                        ? <div className="form-group mt-5">
                                            <a id="login-button"
                                                className={Loading ? "btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6 spinner spinner-white spinner-right" : "btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6"}
                                                onClick={signIn}>
                                                {t('WIZARD_LOGIN_SIGNIN_BUTTON')}
                                                {/* Войти */}
                                            </a>
                                        </div>
                                        : <div className="form-group mt-5">


                                            <a id="send-button"
                                                className={Loading ? "btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6 spinner spinner-white spinner-right" : "btn btn-primary btn-lg btn-block font-weight-bolder font-size-h4 p-6"}
                                                onClick={sendCode}><i
                                                    className="far fa-comment-dots"></i> {t('WIZARD_LOGIN_SIGNIN_SEND_CODE')}</a>
                                        </div>
                                }
                                <div className="pb-lg-0 pb-5 text-center loginText">

                                    {
                                        (!isStepOne)
                                            ? <>
                                                <span>
                                                    {t('WIZARD_LOGIN_SIGNIN_NO_CODE')}
                                                </span>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fab fa-telegram fa-2x text-primary mr-3"
                                                        aria-hidden="true"></i>
                                                    <a target="_blank" href="https://t.me/tradesense_support">
                                                        {t('WIZARD_LOGIN_SIGNIN_GET_CODE')}</a>
                                                </div>
                                                {
                                                    (timerIsClose === false) && <span> {t('WIZARD_LOGIN_SIGNIN_RETRY_AFTER')}
                                                        <span id="countdown"
                                                            className="text-primary font-weight-bolder"> 00:{(timer < 10) ? '0' + timer : timer}</span>
                                                    </span>
                                                }
                                                {
                                                    (timerIsClose) &&
                                                    <a href="#" onClick={sendCodeAgain} className="send-code-again"
                                                        id="resend-code">{t('WIZARD_LOGIN_SIGNIN_RETRY_SEND')}</a>

                                                }
                                                {
                                                    !deviceIsIphoneApplication() && <Captha />
                                                }
                                            </>
                                            : <>
                                                <div className="d-flex align-items-center justify-content-center">
                                                    <i className="fab fa-telegram fa-2x text-primary mr-3"
                                                        aria-hidden="true"></i>
                                                    <a target="_blank" href="https://t.me/tradesense_support">
                                                        {t('WIZARD_LOGIN_SUPPORT_TELEGRAM')}</a>
                                                </div>
                                                <p className="text-muted font-size-sm mt-10">
                                                    {t('WIZARD_LOGIN_SIGNIN_RULES_1')} <a target="_blank" href={`${gettOrigin()}/law/consent`}>
                                                        {t('WIZARD_LOGIN_SIGNIN_PERSONAL_DATA')}</a>{t('WIZARD_LOGIN_SIGNIN_RULES_2')} <a target="_blank"
                                                            href={`${gettOrigin()}/law/terms`}>
                                                        {t('WIZARD_LOGIN_SIGNIN_LICENSE')}
                                                    </a> {t('WIZARD_LOGIN_SIGNIN_AND')} <a target="_blank"
                                                        href={`${gettOrigin()}/law/privacy`}>
                                                        {t('WIZARD_LOGIN_SIGNIN_PRIVACY')}
                                                    </a>
                                                </p>
                                                {
                                                    !deviceIsIphoneApplication() && <Captha />
                                                }
                                            </>
                                    }
                                </div>
                                <div className="mt-10 text-center">
                                    <a href={`${gettOrigin()}`} onClick={transitionToWizard}>
                                        {t('WIZARD_LOGIN_BACK_LINK')}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Login;