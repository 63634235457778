interface IBudget{
    text:string,
    type:string
}

function Budget({text, type}:IBudget){
    return(
        <>
            <div className={"badge " +type+ " font-weight-bolder font-size-md py-1 mt-1"}>{text}</div>
        </>
    )
}

export default Budget