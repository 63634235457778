interface ITimeLineDay{
    date:string
}

function TimeLineDay({date}:ITimeLineDay){
    return(
        <div  className="timeline-item align-items-start mb-4">
            <div className="timeline-label"></div>
            {/* timeline-badge */}
            <div className="timeline-badge text-primary font-weight-bolder text-nowrap">
                {date}        </div>
            <div className="timeline-content"></div>
        </div>
    )
}

export default TimeLineDay